import { NavLink } from "react-router-dom";
import { HashLink } from "react-router-hash-link";

const Card = ({ title, img, description, link }) => {
  const content = (
    <div className="overflow-hidden bg-white rounded-xl">
      <div className="py-7 px-5">
        <div className="flex justify-center items-center">
          <img src={img} alt="Icon" />
        </div>
        <p className="mt-6 text-lg font-medium text-gray-600">{title}</p>
        <p className="mt-1 text-lg font-medium text-gray-400">{description}</p>
      </div>
    </div>
  );

  return link ? <HashLink to={link}>{content}</HashLink> : content;
};

export default Card;
