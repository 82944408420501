import { useContext } from "react";
import SommaireItem from "../sommaireItem";
import { SommaireContext } from "..";
import { Box } from "@mantine/core";
import { bareme } from "../data/bareme";

const Sommaire = () => {
  const {
    typeIndemnite,
    showResult,
    conjointRevenu,
    status,
    situationMatrimoniale,
    enfants,
    salaireBrut,
  } = useContext(SommaireContext);

  const getIpresRegimeCadre = (salaireBrut) => {
    if (status === "cadre") {
      if (salaireBrut >= 1296000) {
        return (1296000 * 2.4) / 100;
      } else {
        return (salaireBrut * 2.4) / 100;
      }
    } else {
      return 0;
    }
  };

  function getIpresRegimeGeneral(salaireBrut) {
    const threshold = 432000;
    const percentage = 5.6 / 100;

    if (salaireBrut >= threshold) {
      return threshold * percentage;
    } else {
      return salaireBrut * percentage;
    }
  }

  function formatAmount(amount = 0, locale = "fr-FR", currency = "XOF") {
    return new Intl.NumberFormat(locale, {
      style: "currency",
      currency,
    }).format(amount);
  }
  function getPart(situationMatrimoniale, conjointRevenu, enfants) {
    let part = 1;
    switch (situationMatrimoniale) {
      case "Célibataire":
        part += enfants * 0.5;
        break;
      case "Marié(e)":
        part = 1.5;
        part += conjointRevenu ? 0 : 0.5;
        part += enfants * 0.5;
        break;
      case "Divorcé(e)":
        part += enfants * 0.5;
        break;
      case "Veuf(ve) sans enfant":
        break;
      case "Veuf(ve) avec enfant(s)":
        if (enfants === 0) {
          part += 0.5 + 1 * 0.5;
        } else {
          part += 0.5 + enfants * 0.5;
        }

        break;
    }
    return Math.min(part, 5);
  }

  function generateImpotAndTrimf(salaireBrut, situationMatrimoniale) {
    let trimf = 0;
    let importSurRevenu = 0;

    const foundBareme = bareme.find(
      (item) =>
        salaireBrut - item["revenu brut"] >= 0 &&
        salaireBrut - item["revenu brut"] < 1_000
    );
    const part = getPart(situationMatrimoniale, conjointRevenu, enfants);

    if (foundBareme) {
      trimf =
        situationMatrimoniale === "Marié(e)" && !conjointRevenu
          ? 2 * foundBareme["trimf"]
          : foundBareme["trimf"];
      importSurRevenu = foundBareme[part];
    }
    trimf = trimf * 2;
    return {
      trimf,
      importSurRevenu,
    };
  }

  let salaireNet = 0;
  const IRCM = getIpresRegimeCadre(salaireBrut);
  const IRG = getIpresRegimeGeneral(salaireBrut);
  const TOTAL_IPRESS = IRCM + IRG;
  const ImpotAndTrimf = generateImpotAndTrimf(
    salaireBrut,
    situationMatrimoniale
  );
  console.log("enfants", enfants);
  const TotalRetenuesFiscales =
    ImpotAndTrimf?.importSurRevenu + ImpotAndTrimf?.trimf;

  const TOTAL_INDEMNITE =
    typeIndemnite === "Prime de transport (26.000 FCFA)" ? 26000 : 50000;
  const SALAIRE_NET =
    salaireBrut -
    ImpotAndTrimf?.importSurRevenu -
    TOTAL_IPRESS +
    TOTAL_INDEMNITE -
    ImpotAndTrimf?.trimf;

  const totalPart = getPart(situationMatrimoniale, conjointRevenu, enfants);

  return (
    <div className="col-span-full lg:col-span-5 md:col-span-12 sm:col-span-full">
      <div className="p-5 border-2 bg-white ">
        <Box>
          <SommaireItem
            title={"Salaire Brut"}
            value={`${formatAmount(salaireBrut) || 0} `}
          />

          {status && <SommaireItem title={"Status"} value={status} />}

          {situationMatrimoniale && (
            <SommaireItem
              title={"Situation Matrimoniale"}
              value={situationMatrimoniale}
            />
          )}
          {situationMatrimoniale && (
            <SommaireItem
              title={"Conjoint.e avec revenu"}
              value={conjointRevenu ? "Oui" : "Non"}
            />
          )}

          {situationMatrimoniale && (
            <SommaireItem
              title={"Nombre d'enfant en charge"}
              value={enfants || 0}
            />
          )}

          {typeIndemnite && (
            <SommaireItem
              title={"Indemenité/Prime"}
              value={`${typeIndemnite}`}
            />
          )}
        </Box>
        <div className="flex-grow  border-dotted border-b border-black my-5"></div>
        {showResult && (
          <>
            <Box>
              <SommaireItem
                title={"IPRES REGIME CADRE MENSUEL"}
                value={`${formatAmount(Math.round(IRCM))} `}
              />

              <SommaireItem
                title={"IPRES REGIME GENERAL MENSUEL"}
                value={`${formatAmount(Math.round(IRG))} `}
              />

              <SommaireItem
                title={"IMPOT SUR LE REVENU"}
                value={`${formatAmount(
                  Math.round(ImpotAndTrimf?.importSurRevenu)
                )}  `}
              />
              <SommaireItem
                title={"TRIMF MENSUEL"}
                value={`${formatAmount(Math.round(ImpotAndTrimf?.trimf))}  `}
              />
            </Box>
            <div className="flex-grow  border-dotted border-b border-black my-5"></div>
            <Box>
              <SommaireItem
                title={"Total retenues fiscales"}
                value={`${formatAmount(Math.round(TotalRetenuesFiscales))}  `}
              />
              <SommaireItem title={"Nombre de parts"} value={totalPart} />
              <SommaireItem
                title={"Total IPRES"}
                value={`${formatAmount(Math.round(TOTAL_IPRESS))}  `}
              />
              <SommaireItem
                title={"Salaire net mensuel"}
                value={`${
                  formatAmount(Math.round(Math.round(SALAIRE_NET))) || 0
                }  `}
              />
            </Box>
          </>
        )}
        <div className="text-sm flex items-center justify-center mt-6">
          © 2024 Afya RH.
        </div>
      </div>
    </div>
  );
};

export default Sommaire;
