const SelectField = ({ label, value, onChange, options }) => (
  <div className="sm:col-span-3">
    <label
      htmlFor={label}
      className="block text-sm font-medium leading-6 text-gray-900"
    >
      {label}
    </label>
    <div className="mt-2 relative">
      <select
        id={label}
        name={label}
        value={value}
        onChange={onChange}
        className="block w-full border py-1.5 pl-2 text-[#555555] shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-orange-600 sm:text-sm sm:leading-6 bg-transparent"
      >
        <option value="" disabled selected>
          Sélectionner
        </option>
        {options.map((option) => (
          <option key={option.value} value={option.value}>
            {option.label}
          </option>
        ))}
      </select>
    </div>
  </div>
);

export default SelectField;
