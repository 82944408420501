import { NavLink } from "react-router-dom";

const Hero = () => {
  return (
    <div className=" hero-bg bg-primary hero">
      <div className="mx-auto max-w-2xl py-16 ">
        <div className="text-center text-white">
          <h1 className="text-4xl font-bold tracking-tight text-white sm:text-6xl">
            Simulateur de Salaire
          </h1>
          <p className="mt-6 text-lg leading-8 text-white">
            Estimez votre salaire en quelques clics grâce à notre simulateur !
          </p>
          <div className="mt-10 flex items-center justify-center gap-x-6">
            <NavLink
              to="calculateur"
              className="btn bg-orange px-10 py-3 text-white hover:bg-opacaity-5 hover:border hover:border-white"
            >
              Calculer mon salaire
            </NavLink>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Hero;
