import React, { useContext, useState } from "react";
import { HiOutlineShoppingCart } from "react-icons/hi";
import { formatAmount } from "../../../utils/formattage";
import { PanierContext } from "../../../App";
import axios from "axios";
import { BaseUrl } from "../../../utils/http";
import EmptyCart from "../../../components/EmptyCart";

const PaymentPage = () => {
  const { cartItems, getCartTotal, removeFromCart } = useContext(PanierContext);

  const [loading, setLoading] = useState(false);

  const [userInfo, setUserInfo] = useState({
    email: "",
    tel: "",
    fullName: "",
    panier: cartItems,
    total: getCartTotal(),
  });

  const submit = async (e) => {
    setLoading(true);
    e.preventDefault();
    const response = await axios.post(`${BaseUrl}/paiments/`, userInfo);
    console.log(response.status);
    if (response.status === 200) {
      return window.location.replace(response.data.redirect_url);
    } else {
      alert("une erreur est surven");
    }
  };
  return (
    <div className="container mx-auto max-w-7xl py-16">
      {getCartTotal() === 0 ? (
        <EmptyCart />
      ) : (
        <div className="max-w-5xl  mx-auto flex flex-col md:flex-row ">
          {/* Left side - Contact Information */}
          <div className="w-full md:w-1/2 p-4 bg-white py-10 rounded-sm">
            <h2 className="text-2xl font-semibold mb-4 text-[#E94E1B]">
              PROCÉDER AU PAIEMENT
            </h2>
            <h3 className="text-lg mb-4">Mes informations de contact</h3>
            <form className="space-y-4" onSubmit={(e) => submit(e)}>
              <div>
                <label className="block text-sm font-medium text-gray-700">
                  Nom complet
                </label>
                <input
                  type="text"
                  className="mt-1 p-2 border border-gray-300 rounded w-full"
                  disabled={getCartTotal() === 0 && true}
                  required
                  onChange={(e) =>
                    setUserInfo({ ...userInfo, fullName: e.target.value })
                  }
                />
              </div>
              <div>
                <label className="block text-sm font-medium text-gray-700">
                  Numéro de téléphone
                </label>
                <input
                  type="tel"
                  className="mt-1 p-2 border border-gray-300 rounded w-full"
                  disabled={getCartTotal() === 0 && true}
                  required
                  onChange={(e) =>
                    setUserInfo({ ...userInfo, tel: e.target.value })
                  }
                />
              </div>
              <div>
                <label className="block text-sm font-medium text-gray-700">
                  E-mail
                </label>
                <input
                  type="email"
                  className="mt-1 p-2 border border-gray-300 rounded w-full"
                  disabled={getCartTotal() === 0 && true}
                  required
                  onChange={(e) =>
                    setUserInfo({ ...userInfo, email: e.target.value })
                  }
                />
              </div>
              {/* <div>
              <label className="block text-sm font-medium text-gray-700">
                Adresse
              </label>
              <input
                type="text"
                className="mt-1 p-2 border border-gray-300 rounded w-full"
              />
            </div> */}

              <button
                className="bg-red-500 text-white w-full mt-8 py-3 rounded text-lg disabled:opacity-70"
                disabled={getCartTotal() === 0 && true}
              >
                Payer {formatAmount(getCartTotal())}
              </button>
            </form>

            <p className="text-sm text-gray-600 mt-4">
              Une fois votre paiement effectué avec succès, vous recevrez
              automatiquement les fichiers nécessaires à l'adresse e-mail que
              vous avez fournie.
            </p>
          </div>

          {/* Right side - Order Summary */}
          <div className="w-full md:w-2/3 px-12 py-10 ">
            <h3 className="text-lg mb-4 font-bold">
              Récapitulatif du téléchargement
            </h3>
            <ul className="space-y-2 mb-7">
              {cartItems?.map((item) => (
                <li key={item.name} className="flex justify-between">
                  {/* <span>{item.name}</span> */}
                  {/* <span className="truncate w-full pr-2">{item.name}</span> */}
                  <span className="flex-1 break-words pr-4">{item.name}</span>
                  <span>{formatAmount(item.prix_unitaire)} </span>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth={1.5}
                    stroke="currentColor"
                    className="size-8 mx-2 -mt-1 text-red-600 cursor-pointer"
                    onClick={() => removeFromCart(item)}
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="m14.74 9-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 0 1-2.244 2.077H8.084a2.25 2.25 0 0 1-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 0 0-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 0 1 3.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 0 0-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 0 0-7.5 0"
                    />
                  </svg>
                </li>
              ))}
            </ul>
            <hr />
            <div className="flex justify-between mt-4">
              <span>Total</span>
              <span>{formatAmount(getCartTotal())}</span>
            </div>
            <div className="flex justify-between">
              <span>Expédition</span>
              <span>{formatAmount(0)}</span>
            </div>
            <hr className="my-4" />
            <div className="flex justify-between text-xl font-semibold">
              <span>Total</span>
              <span>{formatAmount(getCartTotal())}</span>
            </div>
            <p className="text-sm text-gray-600 mt-2">
              Incluant {formatAmount(0)} de taxes
            </p>
          </div>
        </div>
      )}
    </div>
  );
};

export default PaymentPage;
