import Card from "./Card";
import Divider from "./Divider";

const SectionBloc = ({ card_data, title, description }) => {
  return (
    <section className="py-10 bg-gray-100 sm:py-16 lg:py-24">
      <div className="px-4 mx-auto sm:px-6 lg:px-8 max-w-7xl">
        <div className="max-w-2xl mx-auto text-center">
          <h2 className="text-3xl font-bold leading-tight text-gray-900 sm:text-4xl lg:text-5xl">
            {title}
          </h2>
          <Divider />
          <p className="max-w-xl mx-auto mt-4 text-base leading-relaxed text-gray-500">
            {description}
          </p>
        </div>

        {card_data && (
          <div className="max-w-5xl mx-auto mt-12 sm:mt-16">
            <div className="grid grid-cols-1 gap-6 px-8 text-center md:px-0 md:grid-cols-3">
              {card_data?.map((item) => (
                <Card
                  img={item.img}
                  title={item.title}
                  description={item.description}
                  link={item?.to}
                />
              ))}
            </div>
          </div>
        )}
      </div>
    </section>
  );
};

export default SectionBloc;
