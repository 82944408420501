export const bareme = [
  {
    1: 0,
    2: 0,
    3: 0,
    4: 0,
    5: 0,
    "revenu brut": 50000,
    trimf: 300,
    1.5: 0,
    2.5: 0,
    3.5: 0,
    4.5: 0,
  },
  {
    1: 0,
    2: 0,
    3: 0,
    4: 0,
    5: 0,
    "revenu brut": 51_000,
    trimf: 300,
    1.5: 0,
    2.5: 0,
    3.5: 0,
    4.5: 0,
  },
  {
    1: 0,
    2: 0,
    3: 0,
    4: 0,
    5: 0,
    "revenu brut": 52000,
    trimf: 300,
    1.5: 0,
    2.5: 0,
    3.5: 0,
    4.5: 0,
  },
  {
    1: 0,
    2: 0,
    3: 0,
    4: 0,
    5: 0,
    "revenu brut": 53000,
    trimf: 300,
    1.5: 0,
    2.5: 0,
    3.5: 0,
    4.5: 0,
  },
  {
    1: 0,
    2: 0,
    3: 0,
    4: 0,
    5: 0,
    "revenu brut": 54000,
    trimf: 300,
    1.5: 0,
    2.5: 0,
    3.5: 0,
    4.5: 0,
  },
  {
    1: 0,
    2: 0,
    3: 0,
    4: 0,
    5: 0,
    "revenu brut": 55000,
    trimf: 300,
    1.5: 0,
    2.5: 0,
    3.5: 0,
    4.5: 0,
  },
  {
    1: 0,
    2: 0,
    3: 0,
    4: 0,
    5: 0,
    "revenu brut": 56000,
    trimf: 300,
    1.5: 0,
    2.5: 0,
    3.5: 0,
    4.5: 0,
  },
  {
    1: 0,
    2: 0,
    3: 0,
    4: 0,
    5: 0,
    "revenu brut": 57000,
    trimf: 300,
    1.5: 0,
    2.5: 0,
    3.5: 0,
    4.5: 0,
  },
  {
    1: 0,
    2: 0,
    3: 0,
    4: 0,
    5: 0,
    "revenu brut": 58000,
    trimf: 300,
    1.5: 0,
    2.5: 0,
    3.5: 0,
    4.5: 0,
  },
  {
    1: 0,
    2: 0,
    3: 0,
    4: 0,
    5: 0,
    "revenu brut": 59000,
    trimf: 300,
    1.5: 0,
    2.5: 0,
    3.5: 0,
    4.5: 0,
  },
  {
    1: 0,
    2: 0,
    3: 0,
    4: 0,
    5: 0,
    "revenu brut": 60000,
    trimf: 300,
    1.5: 0,
    2.5: 0,
    3.5: 0,
    4.5: 0,
  },
  {
    1: 0,
    2: 0,
    3: 0,
    4: 0,
    5: 0,
    "revenu brut": 61_000,
    trimf: 300,
    1.5: 0,
    2.5: 0,
    3.5: 0,
    4.5: 0,
  },
  {
    1: 0,
    2: 0,
    3: 0,
    4: 0,
    5: 0,
    "revenu brut": 62000,
    trimf: 300,
    1.5: 0,
    2.5: 0,
    3.5: 0,
    4.5: 0,
  },
  {
    1: 0,
    2: 0,
    3: 0,
    4: 0,
    5: 0,
    "revenu brut": 63000,
    trimf: 300,
    1.5: 0,
    2.5: 0,
    3.5: 0,
    4.5: 0,
  },
  {
    1: 0,
    2: 0,
    3: 0,
    4: 0,
    5: 0,
    "revenu brut": 64000,
    trimf: 300,
    1.5: 0,
    2.5: 0,
    3.5: 0,
    4.5: 0,
  },
  {
    1: 0,
    2: 0,
    3: 0,
    4: 0,
    5: 0,
    "revenu brut": 65000,
    trimf: 300,
    1.5: 0,
    2.5: 0,
    3.5: 0,
    4.5: 0,
  },
  {
    1: 0,
    2: 0,
    3: 0,
    4: 0,
    5: 0,
    "revenu brut": 66000,
    trimf: 300,
    1.5: 0,
    2.5: 0,
    3.5: 0,
    4.5: 0,
  },
  {
    1: 0,
    2: 0,
    3: 0,
    4: 0,
    5: 0,
    "revenu brut": 67000,
    trimf: 300,
    1.5: 0,
    2.5: 0,
    3.5: 0,
    4.5: 0,
  },
  {
    1: 0,
    2: 0,
    3: 0,
    4: 0,
    5: 0,
    "revenu brut": 68000,
    trimf: 300,
    1.5: 0,
    2.5: 0,
    3.5: 0,
    4.5: 0,
  },
  {
    1: 0,
    2: 0,
    3: 0,
    4: 0,
    5: 0,
    "revenu brut": 69000,
    trimf: 300,
    1.5: 0,
    2.5: 0,
    3.5: 0,
    4.5: 0,
  },
  {
    1: 0,
    2: 0,
    3: 0,
    4: 0,
    5: 0,
    "revenu brut": 70000,
    trimf: 300,
    1.5: 0,
    2.5: 0,
    3.5: 0,
    4.5: 0,
  },
  {
    1: 0,
    2: 0,
    3: 0,
    4: 0,
    5: 0,
    "revenu brut": 71_000,
    trimf: 300,
    1.5: 0,
    2.5: 0,
    3.5: 0,
    4.5: 0,
  },
  {
    1: 0,
    2: 0,
    3: 0,
    4: 0,
    5: 0,
    "revenu brut": 72000,
    trimf: 300,
    1.5: 0,
    2.5: 0,
    3.5: 0,
    4.5: 0,
  },
  {
    1: 0,
    2: 0,
    3: 0,
    4: 0,
    5: 0,
    "revenu brut": 73000,
    trimf: 300,
    1.5: 0,
    2.5: 0,
    3.5: 0,
    4.5: 0,
  },
  {
    1: 0,
    2: 0,
    3: 0,
    4: 0,
    5: 0,
    "revenu brut": 74000,
    trimf: 300,
    1.5: 0,
    2.5: 0,
    3.5: 0,
    4.5: 0,
  },
  {
    1: 0,
    2: 0,
    3: 0,
    4: 0,
    5: 0,
    "revenu brut": 75000,
    trimf: 300,
    1.5: 0,
    2.5: 0,
    3.5: 0,
    4.5: 0,
  },
  {
    1: 100,
    2: 0,
    3: 0,
    4: 0,
    5: 0,
    "revenu brut": 76000,
    trimf: 300,
    1.5: 0,
    2.5: 0,
    3.5: 0,
    4.5: 0,
  },
  {
    1: 100,
    2: 0,
    3: 0,
    4: 0,
    5: 0,
    "revenu brut": 77000,
    trimf: 300,
    1.5: 0,
    2.5: 0,
    3.5: 0,
    4.5: 0,
  },
  {
    1: 300,
    2: 0,
    3: 0,
    4: 0,
    5: 0,
    "revenu brut": 78000,
    trimf: 300,
    1.5: 0,
    2.5: 0,
    3.5: 0,
    4.5: 0,
  },
  {
    1: 500,
    2: 0,
    3: 0,
    4: 0,
    5: 0,
    "revenu brut": 79000,
    trimf: 300,
    1.5: 0,
    2.5: 0,
    3.5: 0,
    4.5: 0,
  },
  {
    1: 700,
    2: 0,
    3: 0,
    4: 0,
    5: 0,
    "revenu brut": 80000,
    trimf: 300,
    1.5: 0,
    2.5: 0,
    3.5: 0,
    4.5: 0,
  },
  {
    1: 700,
    2: 0,
    3: 0,
    4: 0,
    5: 0,
    "revenu brut": 81_000,
    trimf: 300,
    1.5: 0,
    2.5: 0,
    3.5: 0,
    4.5: 0,
  },
  {
    1: 900,
    2: 0,
    3: 0,
    4: 0,
    5: 0,
    "revenu brut": 82000,
    trimf: 300,
    1.5: 0,
    2.5: 0,
    3.5: 0,
    4.5: 0,
  },
  {
    1: 1100,
    2: 0,
    3: 0,
    4: 0,
    5: 0,
    "revenu brut": 83000,
    trimf: 300,
    1.5: 0,
    2.5: 0,
    3.5: 0,
    4.5: 0,
  },
  {
    1: 1100,
    2: 0,
    3: 0,
    4: 0,
    5: 0,
    "revenu brut": 84000,
    trimf: 400,
    1.5: 0,
    2.5: 0,
    3.5: 0,
    4.5: 0,
  },
  {
    1: 1300,
    2: 0,
    3: 0,
    4: 0,
    5: 0,
    "revenu brut": 85000,
    trimf: 400,
    1.5: 0,
    2.5: 0,
    3.5: 0,
    4.5: 0,
  },
  {
    1: 1500,
    2: 0,
    3: 0,
    4: 0,
    5: 0,
    "revenu brut": 86000,
    trimf: 400,
    1.5: 0,
    2.5: 0,
    3.5: 0,
    4.5: 0,
  },
  {
    1: 1500,
    2: 0,
    3: 0,
    4: 0,
    5: 0,
    "revenu brut": 87000,
    trimf: 400,
    1.5: 0,
    2.5: 0,
    3.5: 0,
    4.5: 0,
  },
  {
    1: 1700,
    2: 0,
    3: 0,
    4: 0,
    5: 0,
    "revenu brut": 88000,
    trimf: 400,
    1.5: 0,
    2.5: 0,
    3.5: 0,
    4.5: 0,
  },
  {
    1: 1900,
    2: 0,
    3: 0,
    4: 0,
    5: 0,
    "revenu brut": 89000,
    trimf: 400,
    1.5: 0,
    2.5: 0,
    3.5: 0,
    4.5: 0,
  },
  {
    1: 2100,
    2: 0,
    3: 0,
    4: 0,
    5: 0,
    "revenu brut": 90000,
    trimf: 400,
    1.5: 0,
    2.5: 0,
    3.5: 0,
    4.5: 0,
  },
  {
    1: 2100,
    2: 0,
    3: 0,
    4: 0,
    5: 0,
    "revenu brut": 91_000,
    trimf: 400,
    1.5: 0,
    2.5: 0,
    3.5: 0,
    4.5: 0,
  },
  {
    1: 2300,
    2: 0,
    3: 0,
    4: 0,
    5: 0,
    "revenu brut": 92000,
    trimf: 400,
    1.5: 0,
    2.5: 0,
    3.5: 0,
    4.5: 0,
  },
  {
    1: 2500,
    2: 0,
    3: 0,
    4: 0,
    5: 0,
    "revenu brut": 93000,
    trimf: 400,
    1.5: 0,
    2.5: 0,
    3.5: 0,
    4.5: 0,
  },
  {
    1: 2500,
    2: 0,
    3: 0,
    4: 0,
    5: 0,
    "revenu brut": 94000,
    trimf: 400,
    1.5: 0,
    2.5: 0,
    3.5: 0,
    4.5: 0,
  },
  {
    1: 2700,
    2: 0,
    3: 0,
    4: 0,
    5: 0,
    "revenu brut": 95000,
    trimf: 400,
    1.5: 0,
    2.5: 0,
    3.5: 0,
    4.5: 0,
  },
  {
    1: 2900,
    2: 0,
    3: 0,
    4: 0,
    5: 0,
    "revenu brut": 96000,
    trimf: 400,
    1.5: 0,
    2.5: 0,
    3.5: 0,
    4.5: 0,
  },
  {
    1: 2900,
    2: 0,
    3: 0,
    4: 0,
    5: 0,
    "revenu brut": 97000,
    trimf: 400,
    1.5: 0,
    2.5: 0,
    3.5: 0,
    4.5: 0,
  },
  {
    1: 3100,
    2: 0,
    3: 0,
    4: 0,
    5: 0,
    "revenu brut": 98000,
    trimf: 400,
    1.5: 0,
    2.5: 0,
    3.5: 0,
    4.5: 0,
  },
  {
    1: 3300,
    2: 0,
    3: 0,
    4: 0,
    5: 0,
    "revenu brut": 99000,
    trimf: 400,
    1.5: 0,
    2.5: 0,
    3.5: 0,
    4.5: 0,
  },
  {
    1: 3500,
    2: 0,
    3: 0,
    4: 0,
    5: 0,
    "revenu brut": 1_00000,
    trimf: 400,
    1.5: 0,
    2.5: 0,
    3.5: 0,
    4.5: 0,
  },
  {
    1: 3500,
    2: 0,
    3: 0,
    4: 0,
    5: 0,
    "revenu brut": 101_000,
    trimf: 400,
    1.5: 0,
    2.5: 0,
    3.5: 0,
    4.5: 0,
  },
  {
    1: 3700,
    2: 0,
    3: 0,
    4: 0,
    5: 0,
    "revenu brut": 102000,
    trimf: 400,
    1.5: 0,
    2.5: 0,
    3.5: 0,
    4.5: 0,
  },
  {
    1: 3900,
    2: 0,
    3: 0,
    4: 0,
    5: 0,
    "revenu brut": 103000,
    trimf: 400,
    1.5: 0,
    2.5: 0,
    3.5: 0,
    4.5: 0,
  },
  {
    1: 3900,
    2: 0,
    3: 0,
    4: 0,
    5: 0,
    "revenu brut": 104000,
    trimf: 400,
    1.5: 0,
    2.5: 0,
    3.5: 0,
    4.5: 0,
  },
  {
    1: 4100,
    2: 0,
    3: 0,
    4: 0,
    5: 0,
    "revenu brut": 105000,
    trimf: 400,
    1.5: 0,
    2.5: 0,
    3.5: 0,
    4.5: 0,
  },
  {
    1: 4300,
    2: 0,
    3: 0,
    4: 0,
    5: 0,
    "revenu brut": 106000,
    trimf: 400,
    1.5: 0,
    2.5: 0,
    3.5: 0,
    4.5: 0,
  },
  {
    1: 4300,
    2: 0,
    3: 0,
    4: 0,
    5: 0,
    "revenu brut": 107000,
    trimf: 400,
    1.5: 0,
    2.5: 0,
    3.5: 0,
    4.5: 0,
  },
  {
    1: 4500,
    2: 0,
    3: 0,
    4: 0,
    5: 0,
    "revenu brut": 108000,
    trimf: 400,
    1.5: 0,
    2.5: 0,
    3.5: 0,
    4.5: 0,
  },
  {
    1: 4700,
    2: 0,
    3: 0,
    4: 0,
    5: 0,
    "revenu brut": 109000,
    trimf: 400,
    1.5: 0,
    2.5: 0,
    3.5: 0,
    4.5: 0,
  },
  {
    1: 4900,
    2: 0,
    3: 0,
    4: 0,
    5: 0,
    "revenu brut": 11_0000,
    trimf: 400,
    1.5: 0,
    2.5: 0,
    3.5: 0,
    4.5: 0,
  },
  {
    1: 4900,
    2: 0,
    3: 0,
    4: 0,
    5: 0,
    "revenu brut": 111_000,
    trimf: 400,
    1.5: 0,
    2.5: 0,
    3.5: 0,
    4.5: 0,
  },
  {
    1: 5100,
    2: 0,
    3: 0,
    4: 0,
    5: 0,
    "revenu brut": 112000,
    trimf: 400,
    1.5: 0,
    2.5: 0,
    3.5: 0,
    4.5: 0,
  },
  {
    1: 5300,
    2: 0,
    3: 0,
    4: 0,
    5: 0,
    "revenu brut": 113000,
    trimf: 400,
    1.5: 0,
    2.5: 0,
    3.5: 0,
    4.5: 0,
  },
  {
    1: 5300,
    2: 0,
    3: 0,
    4: 0,
    5: 0,
    "revenu brut": 114000,
    trimf: 400,
    1.5: 0,
    2.5: 0,
    3.5: 0,
    4.5: 0,
  },
  {
    1: 5500,
    2: 0,
    3: 0,
    4: 0,
    5: 0,
    "revenu brut": 115000,
    trimf: 400,
    1.5: 0,
    2.5: 0,
    3.5: 0,
    4.5: 0,
  },
  {
    1: 5700,
    2: 0,
    3: 0,
    4: 0,
    5: 0,
    "revenu brut": 116000,
    trimf: 400,
    1.5: 0,
    2.5: 0,
    3.5: 0,
    4.5: 0,
  },
  {
    1: 5700,
    2: 0,
    3: 0,
    4: 0,
    5: 0,
    "revenu brut": 117000,
    trimf: 400,
    1.5: 0,
    2.5: 0,
    3.5: 0,
    4.5: 0,
  },
  {
    1: 5900,
    2: 0,
    3: 0,
    4: 0,
    5: 0,
    "revenu brut": 118000,
    trimf: 400,
    1.5: 0,
    2.5: 0,
    3.5: 0,
    4.5: 0,
  },
  {
    1: 6100,
    2: 0,
    3: 0,
    4: 0,
    5: 0,
    "revenu brut": 119000,
    trimf: 400,
    1.5: 0,
    2.5: 0,
    3.5: 0,
    4.5: 0,
  },
  {
    1: 6300,
    2: 0,
    3: 0,
    4: 0,
    5: 0,
    "revenu brut": 120000,
    trimf: 400,
    1.5: 0,
    2.5: 0,
    3.5: 0,
    4.5: 0,
  },
  {
    1: 6300,
    2: 0,
    3: 0,
    4: 0,
    5: 0,
    "revenu brut": 121_000,
    trimf: 400,
    1.5: 0,
    2.5: 0,
    3.5: 0,
    4.5: 0,
  },
  {
    1: 6500,
    2: 0,
    3: 0,
    4: 0,
    5: 0,
    "revenu brut": 122000,
    trimf: 400,
    1.5: 0,
    2.5: 0,
    3.5: 0,
    4.5: 0,
  },
  {
    1: 6700,
    2: 0,
    3: 0,
    4: 0,
    5: 0,
    "revenu brut": 123000,
    trimf: 400,
    1.5: 0,
    2.5: 0,
    3.5: 0,
    4.5: 0,
  },
  {
    1: 6700,
    2: 0,
    3: 0,
    4: 0,
    5: 0,
    "revenu brut": 124000,
    trimf: 400,
    1.5: 0,
    2.5: 0,
    3.5: 0,
    4.5: 0,
  },
  {
    1: 6900,
    2: 0,
    3: 0,
    4: 0,
    5: 0,
    "revenu brut": 125000,
    trimf: 400,
    1.5: 0,
    2.5: 0,
    3.5: 0,
    4.5: 0,
  },
  {
    1: 7100,
    2: 0,
    3: 0,
    4: 0,
    5: 0,
    "revenu brut": 126000,
    trimf: 400,
    1.5: 0,
    2.5: 0,
    3.5: 0,
    4.5: 0,
  },
  {
    1: 7100,
    2: 0,
    3: 0,
    4: 0,
    5: 0,
    "revenu brut": 127000,
    trimf: 400,
    1.5: 0,
    2.5: 0,
    3.5: 0,
    4.5: 0,
  },
  {
    1: 7300,
    2: 0,
    3: 0,
    4: 0,
    5: 0,
    "revenu brut": 128000,
    trimf: 400,
    1.5: 0,
    2.5: 0,
    3.5: 0,
    4.5: 0,
  },
  {
    1: 7500,
    2: 0,
    3: 0,
    4: 0,
    5: 0,
    "revenu brut": 129000,
    trimf: 400,
    1.5: 0,
    2.5: 0,
    3.5: 0,
    4.5: 0,
  },
  {
    1: 7700,
    2: 0,
    3: 0,
    4: 0,
    5: 0,
    "revenu brut": 130000,
    trimf: 400,
    1.5: 0,
    2.5: 0,
    3.5: 0,
    4.5: 0,
  },
  {
    1: 7700,
    2: 0,
    3: 0,
    4: 0,
    5: 0,
    "revenu brut": 131_000,
    trimf: 400,
    1.5: 0,
    2.5: 0,
    3.5: 0,
    4.5: 0,
  },
  {
    1: 7900,
    2: 0,
    3: 0,
    4: 0,
    5: 0,
    "revenu brut": 132000,
    trimf: 400,
    1.5: 0,
    2.5: 0,
    3.5: 0,
    4.5: 0,
  },
  {
    1: 8100,
    2: 0,
    3: 0,
    4: 0,
    5: 0,
    "revenu brut": 133000,
    trimf: 400,
    1.5: 0,
    2.5: 0,
    3.5: 0,
    4.5: 0,
  },
  {
    1: 8100,
    2: 0,
    3: 0,
    4: 0,
    5: 0,
    "revenu brut": 134000,
    trimf: 400,
    1.5: 0,
    2.5: 0,
    3.5: 0,
    4.5: 0,
  },
  {
    1: 8300,
    2: 0,
    3: 0,
    4: 0,
    5: 0,
    "revenu brut": 135000,
    trimf: 400,
    1.5: 0,
    2.5: 0,
    3.5: 0,
    4.5: 0,
  },
  {
    1: 8500,
    2: 0,
    3: 0,
    4: 0,
    5: 0,
    "revenu brut": 136000,
    trimf: 400,
    1.5: 166,
    2.5: 0,
    3.5: 0,
    4.5: 0,
  },
  {
    1: 8500,
    2: 0,
    3: 0,
    4: 0,
    5: 0,
    "revenu brut": 137000,
    trimf: 400,
    1.5: 166,
    2.5: 0,
    3.5: 0,
    4.5: 0,
  },
  {
    1: 8700,
    2: 0,
    3: 0,
    4: 0,
    5: 0,
    "revenu brut": 138000,
    trimf: 400,
    1.5: 366,
    2.5: 0,
    3.5: 0,
    4.5: 0,
  },
  {
    1: 8900,
    2: 0,
    3: 0,
    4: 0,
    5: 0,
    "revenu brut": 139000,
    trimf: 400,
    1.5: 566,
    2.5: 0,
    3.5: 0,
    4.5: 0,
  },
  {
    1: 9100,
    2: 0,
    3: 0,
    4: 0,
    5: 0,
    "revenu brut": 140000,
    trimf: 400,
    1.5: 766,
    2.5: 0,
    3.5: 0,
    4.5: 0,
  },
  {
    1: 9100,
    2: 0,
    3: 0,
    4: 0,
    5: 0,
    "revenu brut": 141_000,
    trimf: 400,
    1.5: 766,
    2.5: 0,
    3.5: 0,
    4.5: 0,
  },
  {
    1: 9300,
    2: 0,
    3: 0,
    4: 0,
    5: 0,
    "revenu brut": 142000,
    trimf: 400,
    1.5: 966,
    2.5: 0,
    3.5: 0,
    4.5: 0,
  },
  {
    1: 9500,
    2: 0,
    3: 0,
    4: 0,
    5: 0,
    "revenu brut": 143000,
    trimf: 400,
    1.5: 1166,
    2.5: 0,
    3.5: 0,
    4.5: 0,
  },
  {
    1: 9500,
    2: 0,
    3: 0,
    4: 0,
    5: 0,
    "revenu brut": 144000,
    trimf: 400,
    1.5: 1166,
    2.5: 0,
    3.5: 0,
    4.5: 0,
  },
  {
    1: 9700,
    2: 0,
    3: 0,
    4: 0,
    5: 0,
    "revenu brut": 145000,
    trimf: 400,
    1.5: 1366,
    2.5: 0,
    3.5: 0,
    4.5: 0,
  },
  {
    1: 9900,
    2: 0,
    3: 0,
    4: 0,
    5: 0,
    "revenu brut": 146000,
    trimf: 400,
    1.5: 1566,
    2.5: 0,
    3.5: 0,
    4.5: 0,
  },
  {
    1: 9900,
    2: 0,
    3: 0,
    4: 0,
    5: 0,
    "revenu brut": 147000,
    trimf: 400,
    1.5: 1566,
    2.5: 0,
    3.5: 0,
    4.5: 0,
  },
  {
    1: 10100,
    2: 0,
    3: 0,
    4: 0,
    5: 0,
    "revenu brut": 148000,
    trimf: 400,
    1.5: 1766,
    2.5: 0,
    3.5: 0,
    4.5: 0,
  },
  {
    1: 10300,
    2: 0,
    3: 0,
    4: 0,
    5: 0,
    "revenu brut": 149000,
    trimf: 400,
    1.5: 1966,
    2.5: 0,
    3.5: 0,
    4.5: 0,
  },
  {
    1: 10500,
    2: 0,
    3: 0,
    4: 0,
    5: 0,
    "revenu brut": 150000,
    trimf: 400,
    1.5: 2166,
    2.5: 0,
    3.5: 0,
    4.5: 0,
  },
  {
    1: 10500,
    2: 0,
    3: 0,
    4: 0,
    5: 0,
    "revenu brut": 151_000,
    trimf: 400,
    1.5: 2166,
    2.5: 0,
    3.5: 0,
    4.5: 0,
  },
  {
    1: 10700,
    2: 0,
    3: 0,
    4: 0,
    5: 0,
    "revenu brut": 152000,
    trimf: 400,
    1.5: 2366,
    2.5: 0,
    3.5: 0,
    4.5: 0,
  },
  {
    1: 10900,
    2: 0,
    3: 0,
    4: 0,
    5: 0,
    "revenu brut": 153000,
    trimf: 400,
    1.5: 2566,
    2.5: 0,
    3.5: 0,
    4.5: 0,
  },
  {
    1: 10900,
    2: 0,
    3: 0,
    4: 0,
    5: 0,
    "revenu brut": 154000,
    trimf: 400,
    1.5: 2566,
    2.5: 0,
    3.5: 0,
    4.5: 0,
  },
  {
    1: 11100,
    2: 0,
    3: 0,
    4: 0,
    5: 0,
    "revenu brut": 155000,
    trimf: 400,
    1.5: 2766,
    2.5: 0,
    3.5: 0,
    4.5: 0,
  },
  {
    1: 11300,
    2: 0,
    3: 0,
    4: 0,
    5: 0,
    "revenu brut": 156000,
    trimf: 400,
    1.5: 2966,
    2.5: 0,
    3.5: 0,
    4.5: 0,
  },
  {
    1: 11300,
    2: 0,
    3: 0,
    4: 0,
    5: 0,
    "revenu brut": 157000,
    trimf: 400,
    1.5: 2966,
    2.5: 0,
    3.5: 0,
    4.5: 0,
  },
  {
    1: 11500,
    2: 0,
    3: 0,
    4: 0,
    5: 0,
    "revenu brut": 158000,
    trimf: 400,
    1.5: 3166,
    2.5: 0,
    3.5: 0,
    4.5: 0,
  },
  {
    1: 11700,
    2: 0,
    3: 0,
    4: 0,
    5: 0,
    "revenu brut": 159000,
    trimf: 400,
    1.5: 3366,
    2.5: 0,
    3.5: 0,
    4.5: 0,
  },
  {
    1: 11900,
    2: 0,
    3: 0,
    4: 0,
    5: 0,
    "revenu brut": 160000,
    trimf: 400,
    1.5: 3566,
    2.5: 0,
    3.5: 0,
    4.5: 0,
  },
  {
    1: 11900,
    2: 0,
    3: 0,
    4: 0,
    5: 0,
    "revenu brut": 161_000,
    trimf: 400,
    1.5: 3566,
    2.5: 0,
    3.5: 0,
    4.5: 0,
  },
  {
    1: 12100,
    2: 0,
    3: 0,
    4: 0,
    5: 0,
    "revenu brut": 162000,
    trimf: 400,
    1.5: 3766,
    2.5: 0,
    3.5: 0,
    4.5: 0,
  },
  {
    1: 12300,
    2: 0,
    3: 0,
    4: 0,
    5: 0,
    "revenu brut": 163000,
    trimf: 400,
    1.5: 3966,
    2.5: 0,
    3.5: 0,
    4.5: 0,
  },
  {
    1: 12300,
    2: 0,
    3: 0,
    4: 0,
    5: 0,
    "revenu brut": 164000,
    trimf: 400,
    1.5: 3966,
    2.5: 0,
    3.5: 0,
    4.5: 0,
  },
  {
    1: 12500,
    2: 0,
    3: 0,
    4: 0,
    5: 0,
    "revenu brut": 165000,
    trimf: 400,
    1.5: 4166,
    2.5: 0,
    3.5: 0,
    4.5: 0,
  },
  {
    1: 12700,
    2: 0,
    3: 0,
    4: 0,
    5: 0,
    "revenu brut": 166000,
    trimf: 400,
    1.5: 4366,
    2.5: 0,
    3.5: 0,
    4.5: 0,
  },
  {
    1: 12700,
    2: 0,
    3: 0,
    4: 0,
    5: 0,
    "revenu brut": 167000,
    trimf: 500,
    1.5: 4366,
    2.5: 0,
    3.5: 0,
    4.5: 0,
  },
  {
    1: 12900,
    2: 0,
    3: 0,
    4: 0,
    5: 0,
    "revenu brut": 168000,
    trimf: 500,
    1.5: 4566,
    2.5: 0,
    3.5: 0,
    4.5: 0,
  },
  {
    1: 13100,
    2: 0,
    3: 0,
    4: 0,
    5: 0,
    "revenu brut": 169000,
    trimf: 500,
    1.5: 4766,
    2.5: 0,
    3.5: 0,
    4.5: 0,
  },
  {
    1: 13300,
    2: 0,
    3: 0,
    4: 0,
    5: 0,
    "revenu brut": 170000,
    trimf: 500,
    1.5: 4966,
    2.5: 0,
    3.5: 0,
    4.5: 0,
  },
  {
    1: 13300,
    2: 0,
    3: 0,
    4: 0,
    5: 0,
    "revenu brut": 171_000,
    trimf: 500,
    1.5: 4966,
    2.5: 0,
    3.5: 0,
    4.5: 0,
  },
  {
    1: 13500,
    2: 0,
    3: 0,
    4: 0,
    5: 0,
    "revenu brut": 172000,
    trimf: 500,
    1.5: 5166,
    2.5: 0,
    3.5: 0,
    4.5: 0,
  },
  {
    1: 13700,
    2: 0,
    3: 0,
    4: 0,
    5: 0,
    "revenu brut": 173000,
    trimf: 500,
    1.5: 5366,
    2.5: 0,
    3.5: 0,
    4.5: 0,
  },
  {
    1: 13700,
    2: 0,
    3: 0,
    4: 0,
    5: 0,
    "revenu brut": 174000,
    trimf: 500,
    1.5: 5366,
    2.5: 0,
    3.5: 0,
    4.5: 0,
  },
  {
    1: 13900,
    2: 0,
    3: 0,
    4: 0,
    5: 0,
    "revenu brut": 175000,
    trimf: 500,
    1.5: 5566,
    2.5: 0,
    3.5: 0,
    4.5: 0,
  },
  {
    1: 14100,
    2: 0,
    3: 0,
    4: 0,
    5: 0,
    "revenu brut": 176000,
    trimf: 500,
    1.5: 5766,
    2.5: 0,
    3.5: 0,
    4.5: 0,
  },
  {
    1: 14100,
    2: 0,
    3: 0,
    4: 0,
    5: 0,
    "revenu brut": 177000,
    trimf: 500,
    1.5: 5766,
    2.5: 0,
    3.5: 0,
    4.5: 0,
  },
  {
    1: 14300,
    2: 0,
    3: 0,
    4: 0,
    5: 0,
    "revenu brut": 178000,
    trimf: 500,
    1.5: 5966,
    2.5: 0,
    3.5: 0,
    4.5: 0,
  },
  {
    1: 14500,
    2: 0,
    3: 0,
    4: 0,
    5: 0,
    "revenu brut": 179000,
    trimf: 500,
    1.5: 6166,
    2.5: 0,
    3.5: 0,
    4.5: 0,
  },
  {
    1: 14800,
    2: 0,
    3: 0,
    4: 0,
    5: 0,
    "revenu brut": 180000,
    trimf: 500,
    1.5: 6466,
    2.5: 0,
    3.5: 0,
    4.5: 0,
  },
  {
    1: 14800,
    2: 0,
    3: 0,
    4: 0,
    5: 0,
    "revenu brut": 181_000,
    trimf: 500,
    1.5: 6466,
    2.5: 0,
    3.5: 0,
    4.5: 0,
  },
  {
    1: 15100,
    2: 0,
    3: 0,
    4: 0,
    5: 0,
    "revenu brut": 182000,
    trimf: 500,
    1.5: 6766,
    2.5: 0,
    3.5: 0,
    4.5: 0,
  },
  {
    1: 15400,
    2: 0,
    3: 0,
    4: 0,
    5: 0,
    "revenu brut": 183000,
    trimf: 500,
    1.5: 7066,
    2.5: 0,
    3.5: 0,
    4.5: 0,
  },
  {
    1: 15400,
    2: 0,
    3: 0,
    4: 0,
    5: 0,
    "revenu brut": 184000,
    trimf: 500,
    1.5: 7066,
    2.5: 0,
    3.5: 0,
    4.5: 0,
  },
  {
    1: 15700,
    2: 0,
    3: 0,
    4: 0,
    5: 0,
    "revenu brut": 185000,
    trimf: 500,
    1.5: 7366,
    2.5: 0,
    3.5: 0,
    4.5: 0,
  },
  {
    1: 16000,
    2: 0,
    3: 0,
    4: 0,
    5: 0,
    "revenu brut": 186000,
    trimf: 500,
    1.5: 7666,
    2.5: 0,
    3.5: 0,
    4.5: 0,
  },
  {
    1: 16000,
    2: 0,
    3: 0,
    4: 0,
    5: 0,
    "revenu brut": 187000,
    trimf: 500,
    1.5: 7666,
    2.5: 0,
    3.5: 0,
    4.5: 0,
  },
  {
    1: 16300,
    2: 0,
    3: 0,
    4: 0,
    5: 0,
    "revenu brut": 188000,
    trimf: 500,
    1.5: 7966,
    2.5: 0,
    3.5: 0,
    4.5: 0,
  },
  {
    1: 16600,
    2: 0,
    3: 0,
    4: 0,
    5: 0,
    "revenu brut": 189000,
    trimf: 500,
    1.5: 8266,
    2.5: 0,
    3.5: 0,
    4.5: 0,
  },
  {
    1: 16900,
    2: 233,
    3: 0,
    4: 0,
    5: 0,
    "revenu brut": 190000,
    trimf: 500,
    1.5: 8566,
    2.5: 0,
    3.5: 0,
    4.5: 0,
  },
  {
    1: 16900,
    2: 233,
    3: 0,
    4: 0,
    5: 0,
    "revenu brut": 191_000,
    trimf: 500,
    1.5: 8566,
    2.5: 0,
    3.5: 0,
    4.5: 0,
  },
  {
    1: 17200,
    2: 533,
    3: 0,
    4: 0,
    5: 0,
    "revenu brut": 192000,
    trimf: 500,
    1.5: 8866,
    2.5: 0,
    3.5: 0,
    4.5: 0,
  },
  {
    1: 17500,
    2: 833,
    3: 0,
    4: 0,
    5: 0,
    "revenu brut": 193000,
    trimf: 500,
    1.5: 9166,
    2.5: 0,
    3.5: 0,
    4.5: 0,
  },
  {
    1: 17500,
    2: 833,
    3: 0,
    4: 0,
    5: 0,
    "revenu brut": 194000,
    trimf: 500,
    1.5: 9166,
    2.5: 0,
    3.5: 0,
    4.5: 0,
  },
  {
    1: 17800,
    2: 1133,
    3: 0,
    4: 0,
    5: 0,
    "revenu brut": 195000,
    trimf: 500,
    1.5: 9466,
    2.5: 0,
    3.5: 0,
    4.5: 0,
  },
  {
    1: 18100,
    2: 1433,
    3: 0,
    4: 0,
    5: 0,
    "revenu brut": 196000,
    trimf: 500,
    1.5: 9766,
    2.5: 0,
    3.5: 0,
    4.5: 0,
  },
  {
    1: 18100,
    2: 1433,
    3: 0,
    4: 0,
    5: 0,
    "revenu brut": 197000,
    trimf: 500,
    1.5: 9766,
    2.5: 0,
    3.5: 0,
    4.5: 0,
  },
  {
    1: 18400,
    2: 1733,
    3: 0,
    4: 0,
    5: 0,
    "revenu brut": 198000,
    trimf: 500,
    1.5: 10066,
    2.5: 0,
    3.5: 0,
    4.5: 0,
  },
  {
    1: 18700,
    2: 2033,
    3: 0,
    4: 0,
    5: 0,
    "revenu brut": 199000,
    trimf: 500,
    1.5: 10366,
    2.5: 0,
    3.5: 0,
    4.5: 0,
  },
  {
    1: 19000,
    2: 2333,
    3: 0,
    4: 0,
    5: 0,
    "revenu brut": 200000,
    trimf: 500,
    1.5: 10666,
    2.5: 0,
    3.5: 0,
    4.5: 0,
  },
  {
    1: 19000,
    2: 2333,
    3: 0,
    4: 0,
    5: 0,
    "revenu brut": 201_000,
    trimf: 500,
    1.5: 10666,
    2.5: 0,
    3.5: 0,
    4.5: 0,
  },
  {
    1: 19300,
    2: 2633,
    3: 0,
    4: 0,
    5: 0,
    "revenu brut": 202000,
    trimf: 500,
    1.5: 10966,
    2.5: 0,
    3.5: 0,
    4.5: 0,
  },
  {
    1: 19600,
    2: 2933,
    3: 0,
    4: 0,
    5: 0,
    "revenu brut": 203000,
    trimf: 500,
    1.5: 11266,
    2.5: 0,
    3.5: 0,
    4.5: 0,
  },
  {
    1: 19600,
    2: 2933,
    3: 0,
    4: 0,
    5: 0,
    "revenu brut": 204000,
    trimf: 500,
    1.5: 11266,
    2.5: 0,
    3.5: 0,
    4.5: 0,
  },
  {
    1: 19900,
    2: 3233,
    3: 0,
    4: 0,
    5: 0,
    "revenu brut": 205000,
    trimf: 500,
    1.5: 11566,
    2.5: 0,
    3.5: 0,
    4.5: 0,
  },
  {
    1: 20200,
    2: 3533,
    3: 0,
    4: 0,
    5: 0,
    "revenu brut": 206000,
    trimf: 500,
    1.5: 11866,
    2.5: 0,
    3.5: 0,
    4.5: 0,
  },
  {
    1: 20200,
    2: 3533,
    3: 0,
    4: 0,
    5: 0,
    "revenu brut": 207000,
    trimf: 500,
    1.5: 11866,
    2.5: 0,
    3.5: 0,
    4.5: 0,
  },
  {
    1: 20500,
    2: 3833,
    3: 0,
    4: 0,
    5: 0,
    "revenu brut": 208000,
    trimf: 500,
    1.5: 12166,
    2.5: 0,
    3.5: 0,
    4.5: 0,
  },
  {
    1: 20800,
    2: 4133,
    3: 0,
    4: 0,
    5: 0,
    "revenu brut": 209000,
    trimf: 500,
    1.5: 12466,
    2.5: 0,
    3.5: 0,
    4.5: 0,
  },
  {
    1: 21100,
    2: 4433,
    3: 0,
    4: 0,
    5: 0,
    "revenu brut": 21_0000,
    trimf: 500,
    1.5: 12766,
    2.5: 0,
    3.5: 0,
    4.5: 0,
  },
  {
    1: 21100,
    2: 4433,
    3: 0,
    4: 0,
    5: 0,
    "revenu brut": 211_000,
    trimf: 500,
    1.5: 12766,
    2.5: 0,
    3.5: 0,
    4.5: 0,
  },
  {
    1: 21400,
    2: 4733,
    3: 0,
    4: 0,
    5: 0,
    "revenu brut": 212000,
    trimf: 500,
    1.5: 13066,
    2.5: 0,
    3.5: 0,
    4.5: 0,
  },
  {
    1: 21700,
    2: 5033,
    3: 0,
    4: 0,
    5: 0,
    "revenu brut": 213000,
    trimf: 500,
    1.5: 13366,
    2.5: 0,
    3.5: 0,
    4.5: 0,
  },
  {
    1: 21700,
    2: 5033,
    3: 0,
    4: 0,
    5: 0,
    "revenu brut": 214000,
    trimf: 500,
    1.5: 13366,
    2.5: 0,
    3.5: 0,
    4.5: 0,
  },
  {
    1: 22000,
    2: 5333,
    3: 0,
    4: 0,
    5: 0,
    "revenu brut": 215000,
    trimf: 500,
    1.5: 13666,
    2.5: 0,
    3.5: 0,
    4.5: 0,
  },
  {
    1: 22300,
    2: 5633,
    3: 0,
    4: 0,
    5: 0,
    "revenu brut": 216000,
    trimf: 500,
    1.5: 13966,
    2.5: 0,
    3.5: 0,
    4.5: 0,
  },
  {
    1: 22300,
    2: 5633,
    3: 0,
    4: 0,
    5: 0,
    "revenu brut": 217000,
    trimf: 500,
    1.5: 13966,
    2.5: 0,
    3.5: 0,
    4.5: 0,
  },
  {
    1: 22600,
    2: 5933,
    3: 0,
    4: 0,
    5: 0,
    "revenu brut": 218000,
    trimf: 500,
    1.5: 14266,
    2.5: 0,
    3.5: 0,
    4.5: 0,
  },
  {
    1: 22900,
    2: 6233,
    3: 0,
    4: 0,
    5: 0,
    "revenu brut": 219000,
    trimf: 500,
    1.5: 14566,
    2.5: 0,
    3.5: 0,
    4.5: 0,
  },
  {
    1: 23200,
    2: 6533,
    3: 0,
    4: 0,
    5: 0,
    "revenu brut": 220000,
    trimf: 500,
    1.5: 14866,
    2.5: 0,
    3.5: 0,
    4.5: 0,
  },
  {
    1: 23200,
    2: 6533,
    3: 0,
    4: 0,
    5: 0,
    "revenu brut": 221_000,
    trimf: 500,
    1.5: 14866,
    2.5: 0,
    3.5: 0,
    4.5: 0,
  },
  {
    1: 23500,
    2: 6833,
    3: 0,
    4: 0,
    5: 0,
    "revenu brut": 222000,
    trimf: 500,
    1.5: 15166,
    2.5: 0,
    3.5: 0,
    4.5: 0,
  },
  {
    1: 23800,
    2: 7133,
    3: 0,
    4: 0,
    5: 0,
    "revenu brut": 223000,
    trimf: 500,
    1.5: 15466,
    2.5: 0,
    3.5: 0,
    4.5: 0,
  },
  {
    1: 23800,
    2: 7133,
    3: 0,
    4: 0,
    5: 0,
    "revenu brut": 224000,
    trimf: 500,
    1.5: 15466,
    2.5: 0,
    3.5: 0,
    4.5: 0,
  },
  {
    1: 24100,
    2: 7433,
    3: 0,
    4: 0,
    5: 0,
    "revenu brut": 225000,
    trimf: 500,
    1.5: 15766,
    2.5: 0,
    3.5: 0,
    4.5: 0,
  },
  {
    1: 24400,
    2: 7733,
    3: 0,
    4: 0,
    5: 0,
    "revenu brut": 226000,
    trimf: 500,
    1.5: 16066,
    2.5: 0,
    3.5: 0,
    4.5: 0,
  },
  {
    1: 24400,
    2: 7733,
    3: 0,
    4: 0,
    5: 0,
    "revenu brut": 227000,
    trimf: 500,
    1.5: 16066,
    2.5: 0,
    3.5: 0,
    4.5: 0,
  },
  {
    1: 24700,
    2: 8033,
    3: 0,
    4: 0,
    5: 0,
    "revenu brut": 228000,
    trimf: 500,
    1.5: 16366,
    2.5: 0,
    3.5: 0,
    4.5: 0,
  },
  {
    1: 25000,
    2: 8333,
    3: 0,
    4: 0,
    5: 0,
    "revenu brut": 229000,
    trimf: 500,
    1.5: 16666,
    2.5: 0,
    3.5: 0,
    4.5: 0,
  },
  {
    1: 25300,
    2: 8633,
    3: 0,
    4: 0,
    5: 0,
    "revenu brut": 230000,
    trimf: 500,
    1.5: 16966,
    2.5: 300,
    3.5: 0,
    4.5: 0,
  },
  {
    1: 25300,
    2: 8633,
    3: 0,
    4: 0,
    5: 0,
    "revenu brut": 231_000,
    trimf: 500,
    1.5: 16966,
    2.5: 300,
    3.5: 0,
    4.5: 0,
  },
  {
    1: 25600,
    2: 8933,
    3: 0,
    4: 0,
    5: 0,
    "revenu brut": 232000,
    trimf: 500,
    1.5: 17266,
    2.5: 600,
    3.5: 0,
    4.5: 0,
  },
  {
    1: 25900,
    2: 9233,
    3: 0,
    4: 0,
    5: 0,
    "revenu brut": 233000,
    trimf: 500,
    1.5: 17566,
    2.5: 900,
    3.5: 0,
    4.5: 0,
  },
  {
    1: 25900,
    2: 9233,
    3: 0,
    4: 0,
    5: 0,
    "revenu brut": 234000,
    trimf: 500,
    1.5: 17566,
    2.5: 900,
    3.5: 0,
    4.5: 0,
  },
  {
    1: 26200,
    2: 9533,
    3: 0,
    4: 0,
    5: 0,
    "revenu brut": 235000,
    trimf: 500,
    1.5: 17866,
    2.5: 1200,
    3.5: 0,
    4.5: 0,
  },
  {
    1: 26500,
    2: 9833,
    3: 0,
    4: 0,
    5: 0,
    "revenu brut": 236000,
    trimf: 500,
    1.5: 18166,
    2.5: 1500,
    3.5: 0,
    4.5: 0,
  },
  {
    1: 26500,
    2: 9833,
    3: 0,
    4: 0,
    5: 0,
    "revenu brut": 237000,
    trimf: 500,
    1.5: 18166,
    2.5: 1500,
    3.5: 0,
    4.5: 0,
  },
  {
    1: 26800,
    2: 10133,
    3: 0,
    4: 0,
    5: 0,
    "revenu brut": 238000,
    trimf: 500,
    1.5: 18466,
    2.5: 1800,
    3.5: 0,
    4.5: 0,
  },
  {
    1: 27100,
    2: 10433,
    3: 0,
    4: 0,
    5: 0,
    "revenu brut": 239000,
    trimf: 500,
    1.5: 18766,
    2.5: 2100,
    3.5: 0,
    4.5: 0,
  },
  {
    1: 27400,
    2: 10733,
    3: 0,
    4: 0,
    5: 0,
    "revenu brut": 240000,
    trimf: 500,
    1.5: 19066,
    2.5: 2400,
    3.5: 0,
    4.5: 0,
  },
  {
    1: 27400,
    2: 10733,
    3: 0,
    4: 0,
    5: 0,
    "revenu brut": 241_000,
    trimf: 500,
    1.5: 19066,
    2.5: 2400,
    3.5: 0,
    4.5: 0,
  },
  {
    1: 27700,
    2: 11033,
    3: 0,
    4: 0,
    5: 0,
    "revenu brut": 242000,
    trimf: 500,
    1.5: 19366,
    2.5: 2700,
    3.5: 0,
    4.5: 0,
  },
  {
    1: 28000,
    2: 11333,
    3: 0,
    4: 0,
    5: 0,
    "revenu brut": 243000,
    trimf: 500,
    1.5: 19666,
    2.5: 3000,
    3.5: 0,
    4.5: 0,
  },
  {
    1: 28000,
    2: 11333,
    3: 0,
    4: 0,
    5: 0,
    "revenu brut": 244000,
    trimf: 500,
    1.5: 19666,
    2.5: 3000,
    3.5: 0,
    4.5: 0,
  },
  {
    1: 28300,
    2: 11633,
    3: 0,
    4: 0,
    5: 0,
    "revenu brut": 245000,
    trimf: 500,
    1.5: 19966,
    2.5: 3300,
    3.5: 0,
    4.5: 0,
  },
  {
    1: 28600,
    2: 11933,
    3: 0,
    4: 0,
    5: 0,
    "revenu brut": 246000,
    trimf: 500,
    1.5: 20266,
    2.5: 3600,
    3.5: 0,
    4.5: 0,
  },
  {
    1: 28600,
    2: 11933,
    3: 0,
    4: 0,
    5: 0,
    "revenu brut": 247000,
    trimf: 500,
    1.5: 20266,
    2.5: 3600,
    3.5: 0,
    4.5: 0,
  },
  {
    1: 28900,
    2: 12233,
    3: 0,
    4: 0,
    5: 0,
    "revenu brut": 248000,
    trimf: 500,
    1.5: 20566,
    2.5: 3900,
    3.5: 0,
    4.5: 0,
  },
  {
    1: 29200,
    2: 12533,
    3: 0,
    4: 0,
    5: 0,
    "revenu brut": 249000,
    trimf: 500,
    1.5: 20866,
    2.5: 4200,
    3.5: 0,
    4.5: 0,
  },
  {
    1: 29500,
    2: 12833,
    3: 0,
    4: 0,
    5: 0,
    "revenu brut": 250000,
    trimf: 500,
    1.5: 21166,
    2.5: 4500,
    3.5: 0,
    4.5: 0,
  },
  {
    1: 29800,
    2: 13133,
    3: 0,
    4: 0,
    5: 0,
    "revenu brut": 251_000,
    trimf: 500,
    1.5: 21466,
    2.5: 4800,
    3.5: 0,
    4.5: 0,
  },
  {
    1: 30100,
    2: 13433,
    3: 0,
    4: 0,
    5: 0,
    "revenu brut": 252000,
    trimf: 500,
    1.5: 21766,
    2.5: 5100,
    3.5: 0,
    4.5: 0,
  },
  {
    1: 30400,
    2: 13733,
    3: 0,
    4: 0,
    5: 0,
    "revenu brut": 253000,
    trimf: 500,
    1.5: 22066,
    2.5: 5400,
    3.5: 0,
    4.5: 0,
  },
  {
    1: 30700,
    2: 14033,
    3: 0,
    4: 0,
    5: 0,
    "revenu brut": 254000,
    trimf: 500,
    1.5: 22366,
    2.5: 5700,
    3.5: 0,
    4.5: 0,
  },
  {
    1: 31_000,
    2: 14333,
    3: 0,
    4: 0,
    5: 0,
    "revenu brut": 255000,
    trimf: 500,
    1.5: 22666,
    2.5: 6000,
    3.5: 0,
    4.5: 0,
  },
  {
    1: 31300,
    2: 14633,
    3: 0,
    4: 0,
    5: 0,
    "revenu brut": 256000,
    trimf: 500,
    1.5: 22966,
    2.5: 6300,
    3.5: 0,
    4.5: 0,
  },
  {
    1: 31600,
    2: 14933,
    3: 0,
    4: 0,
    5: 0,
    "revenu brut": 257000,
    trimf: 500,
    1.5: 23266,
    2.5: 6600,
    3.5: 0,
    4.5: 0,
  },
  {
    1: 31900,
    2: 15233,
    3: 0,
    4: 0,
    5: 0,
    "revenu brut": 258000,
    trimf: 500,
    1.5: 23566,
    2.5: 6900,
    3.5: 0,
    4.5: 0,
  },
  {
    1: 32200,
    2: 15533,
    3: 0,
    4: 0,
    5: 0,
    "revenu brut": 259000,
    trimf: 500,
    1.5: 23866,
    2.5: 7200,
    3.5: 0,
    4.5: 0,
  },
  {
    1: 32500,
    2: 15833,
    3: 0,
    4: 0,
    5: 0,
    "revenu brut": 260000,
    trimf: 500,
    1.5: 24166,
    2.5: 7500,
    3.5: 0,
    4.5: 0,
  },
  {
    1: 32800,
    2: 16133,
    3: 0,
    4: 0,
    5: 0,
    "revenu brut": 261_000,
    trimf: 500,
    1.5: 24466,
    2.5: 7800,
    3.5: 0,
    4.5: 0,
  },
  {
    1: 33100,
    2: 16433,
    3: 0,
    4: 0,
    5: 0,
    "revenu brut": 262000,
    trimf: 500,
    1.5: 24766,
    2.5: 8100,
    3.5: 0,
    4.5: 0,
  },
  {
    1: 33400,
    2: 16733,
    3: 66,
    4: 0,
    5: 0,
    "revenu brut": 263000,
    trimf: 500,
    1.5: 25066,
    2.5: 8400,
    3.5: 0,
    4.5: 0,
  },
  {
    1: 33700,
    2: 17033,
    3: 366,
    4: 0,
    5: 0,
    "revenu brut": 264000,
    trimf: 500,
    1.5: 25366,
    2.5: 8700,
    3.5: 0,
    4.5: 0,
  },
  {
    1: 34000,
    2: 17333,
    3: 666,
    4: 0,
    5: 0,
    "revenu brut": 265000,
    trimf: 500,
    1.5: 25666,
    2.5: 9000,
    3.5: 0,
    4.5: 0,
  },
  {
    1: 34300,
    2: 17633,
    3: 966,
    4: 0,
    5: 0,
    "revenu brut": 266000,
    trimf: 500,
    1.5: 25966,
    2.5: 9300,
    3.5: 0,
    4.5: 0,
  },
  {
    1: 34600,
    2: 17933,
    3: 1266,
    4: 0,
    5: 0,
    "revenu brut": 267000,
    trimf: 500,
    1.5: 26266,
    2.5: 9600,
    3.5: 0,
    4.5: 0,
  },
  {
    1: 34900,
    2: 18233,
    3: 1566,
    4: 0,
    5: 0,
    "revenu brut": 268000,
    trimf: 500,
    1.5: 26566,
    2.5: 9900,
    3.5: 0,
    4.5: 0,
  },
  {
    1: 35200,
    2: 18533,
    3: 1866,
    4: 0,
    5: 0,
    "revenu brut": 269000,
    trimf: 500,
    1.5: 26866,
    2.5: 10200,
    3.5: 0,
    4.5: 0,
  },
  {
    1: 35500,
    2: 18833,
    3: 2166,
    4: 0,
    5: 0,
    "revenu brut": 270000,
    trimf: 500,
    1.5: 27166,
    2.5: 10500,
    3.5: 0,
    4.5: 0,
  },
  {
    1: 35800,
    2: 19133,
    3: 2466,
    4: 0,
    5: 0,
    "revenu brut": 271_000,
    trimf: 500,
    1.5: 27466,
    2.5: 10800,
    3.5: 0,
    4.5: 0,
  },
  {
    1: 36100,
    2: 19433,
    3: 2766,
    4: 0,
    5: 0,
    "revenu brut": 272000,
    trimf: 500,
    1.5: 27766,
    2.5: 11100,
    3.5: 0,
    4.5: 0,
  },
  {
    1: 36400,
    2: 19733,
    3: 3066,
    4: 0,
    5: 0,
    "revenu brut": 273000,
    trimf: 500,
    1.5: 28066,
    2.5: 11400,
    3.5: 0,
    4.5: 0,
  },
  {
    1: 36700,
    2: 20033,
    3: 3366,
    4: 0,
    5: 0,
    "revenu brut": 274000,
    trimf: 500,
    1.5: 28366,
    2.5: 11700,
    3.5: 0,
    4.5: 0,
  },
  {
    1: 37000,
    2: 20333,
    3: 3666,
    4: 0,
    5: 0,
    "revenu brut": 275000,
    trimf: 500,
    1.5: 28666,
    2.5: 12000,
    3.5: 0,
    4.5: 0,
  },
  {
    1: 37300,
    2: 20633,
    3: 3966,
    4: 0,
    5: 0,
    "revenu brut": 276000,
    trimf: 500,
    1.5: 28966,
    2.5: 12300,
    3.5: 0,
    4.5: 0,
  },
  {
    1: 37600,
    2: 20933,
    3: 4266,
    4: 0,
    5: 0,
    "revenu brut": 277000,
    trimf: 500,
    1.5: 29266,
    2.5: 12600,
    3.5: 0,
    4.5: 0,
  },
  {
    1: 37900,
    2: 21233,
    3: 4566,
    4: 0,
    5: 0,
    "revenu brut": 278000,
    trimf: 500,
    1.5: 29566,
    2.5: 12900,
    3.5: 0,
    4.5: 0,
  },
  {
    1: 38200,
    2: 21533,
    3: 4866,
    4: 0,
    5: 0,
    "revenu brut": 279000,
    trimf: 500,
    1.5: 29866,
    2.5: 13200,
    3.5: 0,
    4.5: 0,
  },
  {
    1: 38500,
    2: 21833,
    3: 5166,
    4: 0,
    5: 0,
    "revenu brut": 280000,
    trimf: 500,
    1.5: 30166,
    2.5: 13500,
    3.5: 0,
    4.5: 0,
  },
  {
    1: 38800,
    2: 22133,
    3: 5466,
    4: 0,
    5: 0,
    "revenu brut": 281_000,
    trimf: 500,
    1.5: 30466,
    2.5: 13800,
    3.5: 0,
    4.5: 0,
  },
  {
    1: 39100,
    2: 22433,
    3: 5766,
    4: 0,
    5: 0,
    "revenu brut": 282000,
    trimf: 500,
    1.5: 30766,
    2.5: 14100,
    3.5: 0,
    4.5: 0,
  },
  {
    1: 39400,
    2: 22733,
    3: 6066,
    4: 0,
    5: 0,
    "revenu brut": 283000,
    trimf: 500,
    1.5: 31066,
    2.5: 14400,
    3.5: 0,
    4.5: 0,
  },
  {
    1: 39700,
    2: 23033,
    3: 6366,
    4: 0,
    5: 0,
    "revenu brut": 284000,
    trimf: 500,
    1.5: 31366,
    2.5: 14700,
    3.5: 0,
    4.5: 0,
  },
  {
    1: 40000,
    2: 23333,
    3: 6666,
    4: 0,
    5: 0,
    "revenu brut": 285000,
    trimf: 500,
    1.5: 31666,
    2.5: 15000,
    3.5: 0,
    4.5: 0,
  },
  {
    1: 40300,
    2: 23633,
    3: 6966,
    4: 0,
    5: 0,
    "revenu brut": 286000,
    trimf: 500,
    1.5: 31966,
    2.5: 15300,
    3.5: 0,
    4.5: 0,
  },
  {
    1: 40600,
    2: 23933,
    3: 7266,
    4: 0,
    5: 0,
    "revenu brut": 287000,
    trimf: 500,
    1.5: 32266,
    2.5: 15600,
    3.5: 0,
    4.5: 0,
  },
  {
    1: 40900,
    2: 24233,
    3: 7566,
    4: 0,
    5: 0,
    "revenu brut": 288000,
    trimf: 500,
    1.5: 32566,
    2.5: 15900,
    3.5: 0,
    4.5: 0,
  },
  {
    1: 41200,
    2: 24533,
    3: 7866,
    4: 0,
    5: 0,
    "revenu brut": 289000,
    trimf: 500,
    1.5: 32866,
    2.5: 16200,
    3.5: 0,
    4.5: 0,
  },
  {
    1: 41500,
    2: 24833,
    3: 8166,
    4: 0,
    5: 0,
    "revenu brut": 290000,
    trimf: 500,
    1.5: 33166,
    2.5: 16500,
    3.5: 0,
    4.5: 0,
  },
  {
    1: 41800,
    2: 25133,
    3: 8466,
    4: 0,
    5: 0,
    "revenu brut": 291_000,
    trimf: 500,
    1.5: 33466,
    2.5: 16800,
    3.5: 133,
    4.5: 0,
  },
  {
    1: 42100,
    2: 25433,
    3: 8766,
    4: 0,
    5: 0,
    "revenu brut": 292000,
    trimf: 500,
    1.5: 33766,
    2.5: 17100,
    3.5: 433,
    4.5: 0,
  },
  {
    1: 42400,
    2: 25733,
    3: 9066,
    4: 0,
    5: 0,
    "revenu brut": 293000,
    trimf: 500,
    1.5: 34066,
    2.5: 17400,
    3.5: 733,
    4.5: 0,
  },
  {
    1: 42700,
    2: 26033,
    3: 9366,
    4: 0,
    5: 0,
    "revenu brut": 294000,
    trimf: 500,
    1.5: 34366,
    2.5: 17700,
    3.5: 1033,
    4.5: 0,
  },
  {
    1: 43000,
    2: 26333,
    3: 9666,
    4: 0,
    5: 0,
    "revenu brut": 295000,
    trimf: 500,
    1.5: 34666,
    2.5: 18000,
    3.5: 1333,
    4.5: 0,
  },
  {
    1: 43300,
    2: 26633,
    3: 9966,
    4: 0,
    5: 0,
    "revenu brut": 296000,
    trimf: 500,
    1.5: 34966,
    2.5: 18300,
    3.5: 1633,
    4.5: 0,
  },
  {
    1: 43600,
    2: 26933,
    3: 10266,
    4: 0,
    5: 0,
    "revenu brut": 297000,
    trimf: 500,
    1.5: 35266,
    2.5: 18600,
    3.5: 1933,
    4.5: 0,
  },
  {
    1: 43900,
    2: 27233,
    3: 10566,
    4: 0,
    5: 0,
    "revenu brut": 298000,
    trimf: 500,
    1.5: 35566,
    2.5: 18900,
    3.5: 2233,
    4.5: 0,
  },
  {
    1: 44200,
    2: 27533,
    3: 10866,
    4: 0,
    5: 0,
    "revenu brut": 299000,
    trimf: 500,
    1.5: 35866,
    2.5: 19200,
    3.5: 2533,
    4.5: 0,
  },
  {
    1: 44500,
    2: 27833,
    3: 11166,
    4: 0,
    5: 0,
    "revenu brut": 300000,
    trimf: 500,
    1.5: 36166,
    2.5: 19500,
    3.5: 2833,
    4.5: 0,
  },
  {
    1: 44800,
    2: 28133,
    3: 11466,
    4: 0,
    5: 0,
    "revenu brut": 301_000,
    trimf: 500,
    1.5: 36466,
    2.5: 19800,
    3.5: 3133,
    4.5: 0,
  },
  {
    1: 45100,
    2: 28433,
    3: 11766,
    4: 0,
    5: 0,
    "revenu brut": 302000,
    trimf: 500,
    1.5: 36766,
    2.5: 20100,
    3.5: 3433,
    4.5: 0,
  },
  {
    1: 45400,
    2: 28733,
    3: 12066,
    4: 0,
    5: 0,
    "revenu brut": 303000,
    trimf: 500,
    1.5: 37066,
    2.5: 20400,
    3.5: 3733,
    4.5: 0,
  },
  {
    1: 45700,
    2: 29033,
    3: 12366,
    4: 0,
    5: 0,
    "revenu brut": 304000,
    trimf: 500,
    1.5: 37366,
    2.5: 20700,
    3.5: 4033,
    4.5: 0,
  },
  {
    1: 46000,
    2: 29333,
    3: 12666,
    4: 0,
    5: 0,
    "revenu brut": 305000,
    trimf: 500,
    1.5: 37666,
    2.5: 21_000,
    3.5: 4333,
    4.5: 0,
  },
  {
    1: 46300,
    2: 29633,
    3: 12966,
    4: 0,
    5: 0,
    "revenu brut": 306000,
    trimf: 500,
    1.5: 37966,
    2.5: 21300,
    3.5: 4633,
    4.5: 0,
  },
  {
    1: 46600,
    2: 29933,
    3: 13266,
    4: 0,
    5: 0,
    "revenu brut": 307000,
    trimf: 500,
    1.5: 38266,
    2.5: 21600,
    3.5: 4933,
    4.5: 0,
  },
  {
    1: 46900,
    2: 30233,
    3: 13566,
    4: 0,
    5: 0,
    "revenu brut": 308000,
    trimf: 500,
    1.5: 38566,
    2.5: 21900,
    3.5: 5233,
    4.5: 0,
  },
  {
    1: 47200,
    2: 30533,
    3: 13866,
    4: 0,
    5: 0,
    "revenu brut": 309000,
    trimf: 500,
    1.5: 38866,
    2.5: 22200,
    3.5: 5533,
    4.5: 0,
  },
  {
    1: 47500,
    2: 30833,
    3: 14166,
    4: 0,
    5: 0,
    "revenu brut": 31_0000,
    trimf: 500,
    1.5: 39166,
    2.5: 22500,
    3.5: 5833,
    4.5: 0,
  },
  {
    1: 47800,
    2: 31133,
    3: 14466,
    4: 0,
    5: 0,
    "revenu brut": 311_000,
    trimf: 500,
    1.5: 39466,
    2.5: 22800,
    3.5: 6133,
    4.5: 0,
  },
  {
    1: 48100,
    2: 31433,
    3: 14766,
    4: 0,
    5: 0,
    "revenu brut": 312000,
    trimf: 500,
    1.5: 39766,
    2.5: 23100,
    3.5: 6433,
    4.5: 0,
  },
  {
    1: 48400,
    2: 31733,
    3: 15066,
    4: 0,
    5: 0,
    "revenu brut": 313000,
    trimf: 500,
    1.5: 40066,
    2.5: 23400,
    3.5: 6733,
    4.5: 0,
  },
  {
    1: 48700,
    2: 32033,
    3: 15366,
    4: 0,
    5: 0,
    "revenu brut": 314000,
    trimf: 500,
    1.5: 40366,
    2.5: 23700,
    3.5: 7033,
    4.5: 0,
  },
  {
    1: 49000,
    2: 32333,
    3: 15666,
    4: 0,
    5: 0,
    "revenu brut": 315000,
    trimf: 500,
    1.5: 40666,
    2.5: 24000,
    3.5: 7333,
    4.5: 0,
  },
  {
    1: 49300,
    2: 32633,
    3: 15966,
    4: 0,
    5: 0,
    "revenu brut": 316000,
    trimf: 500,
    1.5: 40966,
    2.5: 24300,
    3.5: 7633,
    4.5: 0,
  },
  {
    1: 49600,
    2: 32933,
    3: 16266,
    4: 0,
    5: 0,
    "revenu brut": 317000,
    trimf: 500,
    1.5: 41266,
    2.5: 24600,
    3.5: 7933,
    4.5: 0,
  },
  {
    1: 49900,
    2: 33233,
    3: 16566,
    4: 0,
    5: 0,
    "revenu brut": 318000,
    trimf: 500,
    1.5: 41566,
    2.5: 24900,
    3.5: 8233,
    4.5: 0,
  },
  {
    1: 50200,
    2: 33533,
    3: 16866,
    4: 200,
    5: 0,
    "revenu brut": 319000,
    trimf: 500,
    1.5: 41866,
    2.5: 25200,
    3.5: 8533,
    4.5: 0,
  },
  {
    1: 50500,
    2: 33833,
    3: 17166,
    4: 500,
    5: 0,
    "revenu brut": 320000,
    trimf: 500,
    1.5: 42166,
    2.5: 25500,
    3.5: 8833,
    4.5: 0,
  },
  {
    1: 50800,
    2: 34133,
    3: 17466,
    4: 800,
    5: 0,
    "revenu brut": 321_000,
    trimf: 500,
    1.5: 42466,
    2.5: 25800,
    3.5: 9133,
    4.5: 0,
  },
  {
    1: 51100,
    2: 34433,
    3: 17766,
    4: 1100,
    5: 0,
    "revenu brut": 322000,
    trimf: 500,
    1.5: 42766,
    2.5: 26100,
    3.5: 9433,
    4.5: 0,
  },
  {
    1: 51400,
    2: 34733,
    3: 18066,
    4: 1400,
    5: 0,
    "revenu brut": 323000,
    trimf: 500,
    1.5: 43066,
    2.5: 26400,
    3.5: 9733,
    4.5: 0,
  },
  {
    1: 51700,
    2: 35033,
    3: 18366,
    4: 1700,
    5: 0,
    "revenu brut": 324000,
    trimf: 500,
    1.5: 43366,
    2.5: 26700,
    3.5: 10033,
    4.5: 0,
  },
  {
    1: 52000,
    2: 35333,
    3: 18666,
    4: 2000,
    5: 0,
    "revenu brut": 325000,
    trimf: 500,
    1.5: 43666,
    2.5: 27000,
    3.5: 10333,
    4.5: 0,
  },
  {
    1: 52300,
    2: 35633,
    3: 18966,
    4: 2300,
    5: 0,
    "revenu brut": 326000,
    trimf: 500,
    1.5: 43966,
    2.5: 27300,
    3.5: 10633,
    4.5: 0,
  },
  {
    1: 52600,
    2: 35933,
    3: 19266,
    4: 2600,
    5: 0,
    "revenu brut": 327000,
    trimf: 500,
    1.5: 44266,
    2.5: 27600,
    3.5: 10933,
    4.5: 0,
  },
  {
    1: 52900,
    2: 36233,
    3: 19566,
    4: 2900,
    5: 0,
    "revenu brut": 328000,
    trimf: 500,
    1.5: 44566,
    2.5: 27900,
    3.5: 11233,
    4.5: 0,
  },
  {
    1: 53200,
    2: 36533,
    3: 19866,
    4: 3200,
    5: 0,
    "revenu brut": 329000,
    trimf: 500,
    1.5: 44866,
    2.5: 28200,
    3.5: 11533,
    4.5: 0,
  },
  {
    1: 53500,
    2: 36833,
    3: 20166,
    4: 3500,
    5: 0,
    "revenu brut": 330000,
    trimf: 500,
    1.5: 45166,
    2.5: 28500,
    3.5: 11833,
    4.5: 0,
  },
  {
    1: 53800,
    2: 37133,
    3: 20466,
    4: 3800,
    5: 0,
    "revenu brut": 331_000,
    trimf: 500,
    1.5: 45466,
    2.5: 28800,
    3.5: 12133,
    4.5: 0,
  },
  {
    1: 54100,
    2: 37433,
    3: 20766,
    4: 4100,
    5: 0,
    "revenu brut": 332000,
    trimf: 500,
    1.5: 45766,
    2.5: 29100,
    3.5: 12433,
    4.5: 0,
  },
  {
    1: 54400,
    2: 37733,
    3: 21066,
    4: 4400,
    5: 0,
    "revenu brut": 333000,
    trimf: 500,
    1.5: 46066,
    2.5: 29400,
    3.5: 12733,
    4.5: 0,
  },
  {
    1: 54700,
    2: 38033,
    3: 21366,
    4: 4700,
    5: 0,
    "revenu brut": 334000,
    trimf: 500,
    1.5: 46366,
    2.5: 29700,
    3.5: 13033,
    4.5: 0,
  },
  {
    1: 55000,
    2: 38333,
    3: 21666,
    4: 5000,
    5: 0,
    "revenu brut": 335000,
    trimf: 500,
    1.5: 46666,
    2.5: 30000,
    3.5: 13333,
    4.5: 0,
  },
  {
    1: 55300,
    2: 38633,
    3: 21966,
    4: 5300,
    5: 0,
    "revenu brut": 336000,
    trimf: 500,
    1.5: 46966,
    2.5: 30300,
    3.5: 13633,
    4.5: 0,
  },
  {
    1: 55600,
    2: 38933,
    3: 22266,
    4: 5600,
    5: 0,
    "revenu brut": 337000,
    trimf: 500,
    1.5: 47266,
    2.5: 30600,
    3.5: 13933,
    4.5: 0,
  },
  {
    1: 55900,
    2: 39233,
    3: 22566,
    4: 5900,
    5: 0,
    "revenu brut": 338000,
    trimf: 500,
    1.5: 47566,
    2.5: 30900,
    3.5: 14233,
    4.5: 0,
  },
  {
    1: 56200,
    2: 39533,
    3: 22866,
    4: 6200,
    5: 0,
    "revenu brut": 339000,
    trimf: 500,
    1.5: 47866,
    2.5: 31200,
    3.5: 14533,
    4.5: 0,
  },
  {
    1: 56500,
    2: 39833,
    3: 23166,
    4: 6500,
    5: 0,
    "revenu brut": 340000,
    trimf: 500,
    1.5: 48166,
    2.5: 31500,
    3.5: 14833,
    4.5: 0,
  },
  {
    1: 56800,
    2: 40133,
    3: 23466,
    4: 6800,
    5: 0,
    "revenu brut": 341_000,
    trimf: 500,
    1.5: 48466,
    2.5: 31800,
    3.5: 15133,
    4.5: 0,
  },
  {
    1: 57100,
    2: 40433,
    3: 23766,
    4: 7100,
    5: 0,
    "revenu brut": 342000,
    trimf: 500,
    1.5: 48766,
    2.5: 32100,
    3.5: 15433,
    4.5: 0,
  },
  {
    1: 57400,
    2: 40733,
    3: 24066,
    4: 7400,
    5: 0,
    "revenu brut": 343000,
    trimf: 500,
    1.5: 49066,
    2.5: 32400,
    3.5: 15733,
    4.5: 0,
  },
  {
    1: 57700,
    2: 41033,
    3: 24366,
    4: 7700,
    5: 0,
    "revenu brut": 344000,
    trimf: 500,
    1.5: 49366,
    2.5: 32700,
    3.5: 16033,
    4.5: 0,
  },
  {
    1: 58000,
    2: 41333,
    3: 24666,
    4: 8000,
    5: 0,
    "revenu brut": 345000,
    trimf: 500,
    1.5: 49666,
    2.5: 33000,
    3.5: 16333,
    4.5: 0,
  },
  {
    1: 58300,
    2: 41633,
    3: 24966,
    4: 8300,
    5: 0,
    "revenu brut": 346000,
    trimf: 500,
    1.5: 49966,
    2.5: 33300,
    3.5: 16633,
    4.5: 0,
  },
  {
    1: 58600,
    2: 41933,
    3: 25266,
    4: 8600,
    5: 0,
    "revenu brut": 347000,
    trimf: 500,
    1.5: 50266,
    2.5: 33600,
    3.5: 16933,
    4.5: 266,
  },
  {
    1: 58900,
    2: 42233,
    3: 25566,
    4: 8900,
    5: 0,
    "revenu brut": 348000,
    trimf: 500,
    1.5: 50566,
    2.5: 33900,
    3.5: 17233,
    4.5: 566,
  },
  {
    1: 59200,
    2: 42533,
    3: 25866,
    4: 9200,
    5: 0,
    "revenu brut": 349000,
    trimf: 500,
    1.5: 50866,
    2.5: 34200,
    3.5: 17533,
    4.5: 866,
  },
  {
    1: 59500,
    2: 42833,
    3: 26166,
    4: 9500,
    5: 0,
    "revenu brut": 350000,
    trimf: 500,
    1.5: 51166,
    2.5: 34500,
    3.5: 17833,
    4.5: 1166,
  },
  {
    1: 59800,
    2: 43133,
    3: 26466,
    4: 9800,
    5: 0,
    "revenu brut": 351_000,
    trimf: 500,
    1.5: 51466,
    2.5: 34800,
    3.5: 18133,
    4.5: 1466,
  },
  {
    1: 60100,
    2: 43433,
    3: 26766,
    4: 10100,
    5: 0,
    "revenu brut": 352000,
    trimf: 500,
    1.5: 51766,
    2.5: 35100,
    3.5: 18433,
    4.5: 1766,
  },
  {
    1: 60400,
    2: 43733,
    3: 27066,
    4: 10400,
    5: 0,
    "revenu brut": 353000,
    trimf: 500,
    1.5: 52066,
    2.5: 35400,
    3.5: 18733,
    4.5: 2066,
  },
  {
    1: 60700,
    2: 44033,
    3: 27366,
    4: 10700,
    5: 0,
    "revenu brut": 354000,
    trimf: 500,
    1.5: 52366,
    2.5: 35700,
    3.5: 19033,
    4.5: 2366,
  },
  {
    1: 61_000,
    2: 44333,
    3: 27666,
    4: 11_000,
    5: 0,
    "revenu brut": 355000,
    trimf: 500,
    1.5: 52666,
    2.5: 36000,
    3.5: 19333,
    4.5: 2666,
  },
  {
    1: 61300,
    2: 44633,
    3: 27966,
    4: 11300,
    5: 0,
    "revenu brut": 356000,
    trimf: 500,
    1.5: 52966,
    2.5: 36300,
    3.5: 19633,
    4.5: 2966,
  },
  {
    1: 61600,
    2: 44933,
    3: 28266,
    4: 11600,
    5: 0,
    "revenu brut": 357000,
    trimf: 500,
    1.5: 53266,
    2.5: 36600,
    3.5: 19933,
    4.5: 3266,
  },
  {
    1: 61900,
    2: 45233,
    3: 28566,
    4: 11900,
    5: 0,
    "revenu brut": 358000,
    trimf: 500,
    1.5: 53566,
    2.5: 36900,
    3.5: 20233,
    4.5: 3566,
  },
  {
    1: 62200,
    2: 45533,
    3: 28866,
    4: 12200,
    5: 0,
    "revenu brut": 359000,
    trimf: 500,
    1.5: 53866,
    2.5: 37200,
    3.5: 20533,
    4.5: 3866,
  },
  {
    1: 62500,
    2: 45833,
    3: 29166,
    4: 12500,
    5: 0,
    "revenu brut": 360000,
    trimf: 500,
    1.5: 54166,
    2.5: 37500,
    3.5: 20833,
    4.5: 4166,
  },
  {
    1: 62800,
    2: 46133,
    3: 29466,
    4: 12800,
    5: 0,
    "revenu brut": 361_000,
    trimf: 500,
    1.5: 54466,
    2.5: 37800,
    3.5: 21133,
    4.5: 4466,
  },
  {
    1: 63100,
    2: 46433,
    3: 29766,
    4: 13100,
    5: 0,
    "revenu brut": 362000,
    trimf: 500,
    1.5: 54766,
    2.5: 38100,
    3.5: 21433,
    4.5: 4766,
  },
  {
    1: 63400,
    2: 46733,
    3: 30066,
    4: 13400,
    5: 0,
    "revenu brut": 363000,
    trimf: 500,
    1.5: 55066,
    2.5: 38400,
    3.5: 21733,
    4.5: 5066,
  },
  {
    1: 63700,
    2: 47033,
    3: 30366,
    4: 13700,
    5: 0,
    "revenu brut": 364000,
    trimf: 500,
    1.5: 55366,
    2.5: 38700,
    3.5: 22033,
    4.5: 5366,
  },
  {
    1: 64000,
    2: 47333,
    3: 30666,
    4: 14000,
    5: 0,
    "revenu brut": 365000,
    trimf: 500,
    1.5: 55666,
    2.5: 39000,
    3.5: 22333,
    4.5: 5666,
  },
  {
    1: 64300,
    2: 47633,
    3: 30966,
    4: 14300,
    5: 0,
    "revenu brut": 366000,
    trimf: 500,
    1.5: 55966,
    2.5: 39300,
    3.5: 22633,
    4.5: 5966,
  },
  {
    1: 64600,
    2: 47933,
    3: 31266,
    4: 14600,
    5: 0,
    "revenu brut": 367000,
    trimf: 500,
    1.5: 56266,
    2.5: 39600,
    3.5: 22933,
    4.5: 6266,
  },
  {
    1: 64900,
    2: 48233,
    3: 31566,
    4: 14900,
    5: 0,
    "revenu brut": 368000,
    trimf: 500,
    1.5: 56566,
    2.5: 39900,
    3.5: 23233,
    4.5: 6566,
  },
  {
    1: 65200,
    2: 48533,
    3: 31866,
    4: 15200,
    5: 0,
    "revenu brut": 369000,
    trimf: 500,
    1.5: 56866,
    2.5: 40200,
    3.5: 23533,
    4.5: 6866,
  },
  {
    1: 65500,
    2: 48833,
    3: 32166,
    4: 15500,
    5: 0,
    "revenu brut": 370000,
    trimf: 500,
    1.5: 57166,
    2.5: 40500,
    3.5: 23833,
    4.5: 7166,
  },
  {
    1: 65800,
    2: 49133,
    3: 32466,
    4: 15800,
    5: 0,
    "revenu brut": 371_000,
    trimf: 500,
    1.5: 57466,
    2.5: 40800,
    3.5: 24133,
    4.5: 7466,
  },
  {
    1: 66100,
    2: 49433,
    3: 32766,
    4: 16100,
    5: 0,
    "revenu brut": 372000,
    trimf: 500,
    1.5: 57766,
    2.5: 41100,
    3.5: 24433,
    4.5: 7766,
  },
  {
    1: 66400,
    2: 49733,
    3: 33066,
    4: 16400,
    5: 0,
    "revenu brut": 373000,
    trimf: 500,
    1.5: 58066,
    2.5: 41400,
    3.5: 24733,
    4.5: 8066,
  },
  {
    1: 66700,
    2: 50033,
    3: 33366,
    4: 16700,
    5: 33,
    "revenu brut": 374000,
    trimf: 500,
    1.5: 58366,
    2.5: 41700,
    3.5: 25033,
    4.5: 8366,
  },
  {
    1: 67000,
    2: 50333,
    3: 33666,
    4: 17000,
    5: 333,
    "revenu brut": 375000,
    trimf: 500,
    1.5: 58666,
    2.5: 42000,
    3.5: 25333,
    4.5: 8666,
  },
  {
    1: 67300,
    2: 50633,
    3: 33966,
    4: 17300,
    5: 633,
    "revenu brut": 376000,
    trimf: 500,
    1.5: 58966,
    2.5: 42300,
    3.5: 25633,
    4.5: 8966,
  },
  {
    1: 67600,
    2: 50933,
    3: 34266,
    4: 17600,
    5: 933,
    "revenu brut": 377000,
    trimf: 500,
    1.5: 59266,
    2.5: 42600,
    3.5: 25933,
    4.5: 9266,
  },
  {
    1: 67900,
    2: 51233,
    3: 34566,
    4: 17900,
    5: 1233,
    "revenu brut": 378000,
    trimf: 500,
    1.5: 59566,
    2.5: 42900,
    3.5: 26233,
    4.5: 9566,
  },
  {
    1: 68200,
    2: 51533,
    3: 34866,
    4: 18200,
    5: 1533,
    "revenu brut": 379000,
    trimf: 500,
    1.5: 59866,
    2.5: 43200,
    3.5: 26533,
    4.5: 9866,
  },
  {
    1: 68500,
    2: 51833,
    3: 35166,
    4: 18500,
    5: 1833,
    "revenu brut": 380000,
    trimf: 500,
    1.5: 60166,
    2.5: 43500,
    3.5: 26833,
    4.5: 10166,
  },
  {
    1: 68800,
    2: 52133,
    3: 35466,
    4: 18800,
    5: 2133,
    "revenu brut": 381_000,
    trimf: 500,
    1.5: 60466,
    2.5: 43800,
    3.5: 27133,
    4.5: 10466,
  },
  {
    1: 69100,
    2: 52433,
    3: 35766,
    4: 19100,
    5: 2433,
    "revenu brut": 382000,
    trimf: 500,
    1.5: 60766,
    2.5: 44100,
    3.5: 27433,
    4.5: 10766,
  },
  {
    1: 69400,
    2: 52733,
    3: 36066,
    4: 19400,
    5: 2733,
    "revenu brut": 383000,
    trimf: 500,
    1.5: 61066,
    2.5: 44400,
    3.5: 27733,
    4.5: 11066,
  },
  {
    1: 69700,
    2: 53033,
    3: 36366,
    4: 19700,
    5: 3033,
    "revenu brut": 384000,
    trimf: 500,
    1.5: 61366,
    2.5: 44700,
    3.5: 28033,
    4.5: 11366,
  },
  {
    1: 70000,
    2: 53333,
    3: 36666,
    4: 20000,
    5: 3333,
    "revenu brut": 385000,
    trimf: 500,
    1.5: 61666,
    2.5: 45000,
    3.5: 28333,
    4.5: 11666,
  },
  {
    1: 70300,
    2: 53633,
    3: 36966,
    4: 20300,
    5: 3633,
    "revenu brut": 386000,
    trimf: 500,
    1.5: 61966,
    2.5: 45300,
    3.5: 28633,
    4.5: 11966,
  },
  {
    1: 70600,
    2: 53933,
    3: 37266,
    4: 20600,
    5: 3933,
    "revenu brut": 387000,
    trimf: 500,
    1.5: 62266,
    2.5: 45600,
    3.5: 28933,
    4.5: 12266,
  },
  {
    1: 70900,
    2: 54233,
    3: 37566,
    4: 20900,
    5: 4233,
    "revenu brut": 388000,
    trimf: 500,
    1.5: 62566,
    2.5: 45900,
    3.5: 29233,
    4.5: 12566,
  },
  {
    1: 71200,
    2: 54533,
    3: 37866,
    4: 21200,
    5: 4533,
    "revenu brut": 389000,
    trimf: 500,
    1.5: 62866,
    2.5: 46200,
    3.5: 29533,
    4.5: 12866,
  },
  {
    1: 71500,
    2: 54833,
    3: 38166,
    4: 21500,
    5: 4833,
    "revenu brut": 390000,
    trimf: 500,
    1.5: 63166,
    2.5: 46500,
    3.5: 29833,
    4.5: 13166,
  },
  {
    1: 71800,
    2: 55133,
    3: 38466,
    4: 21800,
    5: 5133,
    "revenu brut": 391_000,
    trimf: 500,
    1.5: 63466,
    2.5: 46800,
    3.5: 30133,
    4.5: 13466,
  },
  {
    1: 72100,
    2: 55433,
    3: 38766,
    4: 22100,
    5: 5433,
    "revenu brut": 392000,
    trimf: 500,
    1.5: 63766,
    2.5: 47100,
    3.5: 30433,
    4.5: 13766,
  },
  {
    1: 72400,
    2: 55733,
    3: 39066,
    4: 22400,
    5: 5733,
    "revenu brut": 393000,
    trimf: 500,
    1.5: 64066,
    2.5: 47400,
    3.5: 30733,
    4.5: 14066,
  },
  {
    1: 72700,
    2: 56033,
    3: 39366,
    4: 22700,
    5: 6033,
    "revenu brut": 394000,
    trimf: 500,
    1.5: 64366,
    2.5: 47700,
    3.5: 31033,
    4.5: 14366,
  },
  {
    1: 73000,
    2: 56333,
    3: 39666,
    4: 23000,
    5: 6333,
    "revenu brut": 395000,
    trimf: 500,
    1.5: 64666,
    2.5: 48000,
    3.5: 31333,
    4.5: 14666,
  },
  {
    1: 73300,
    2: 56633,
    3: 39966,
    4: 23300,
    5: 6633,
    "revenu brut": 396000,
    trimf: 500,
    1.5: 64966,
    2.5: 48300,
    3.5: 31633,
    4.5: 14966,
  },
  {
    1: 73600,
    2: 56933,
    3: 40266,
    4: 23600,
    5: 6933,
    "revenu brut": 397000,
    trimf: 500,
    1.5: 65266,
    2.5: 48600,
    3.5: 31933,
    4.5: 15266,
  },
  {
    1: 73900,
    2: 57233,
    3: 40566,
    4: 23900,
    5: 7233,
    "revenu brut": 398000,
    trimf: 500,
    1.5: 65566,
    2.5: 48900,
    3.5: 32233,
    4.5: 15566,
  },
  {
    1: 74200,
    2: 57533,
    3: 40866,
    4: 24200,
    5: 7533,
    "revenu brut": 399000,
    trimf: 500,
    1.5: 65866,
    2.5: 49200,
    3.5: 32533,
    4.5: 15866,
  },
  {
    1: 74500,
    2: 57833,
    3: 41166,
    4: 24500,
    5: 7833,
    "revenu brut": 400000,
    trimf: 500,
    1.5: 66166,
    2.5: 49500,
    3.5: 32833,
    4.5: 16166,
  },
  {
    1: 74800,
    2: 58133,
    3: 41466,
    4: 24800,
    5: 8133,
    "revenu brut": 401_000,
    trimf: 500,
    1.5: 66466,
    2.5: 49800,
    3.5: 33133,
    4.5: 16466,
  },
  {
    1: 75100,
    2: 58433,
    3: 41766,
    4: 25100,
    5: 8433,
    "revenu brut": 402000,
    trimf: 500,
    1.5: 66766,
    2.5: 50100,
    3.5: 33433,
    4.5: 16766,
  },
  {
    1: 75400,
    2: 58733,
    3: 42066,
    4: 25400,
    5: 8733,
    "revenu brut": 403000,
    trimf: 500,
    1.5: 67066,
    2.5: 50400,
    3.5: 33733,
    4.5: 17066,
  },
  {
    1: 75700,
    2: 59033,
    3: 42366,
    4: 25700,
    5: 9033,
    "revenu brut": 404000,
    trimf: 500,
    1.5: 67366,
    2.5: 50700,
    3.5: 34033,
    4.5: 17366,
  },
  {
    1: 76000,
    2: 59333,
    3: 42666,
    4: 26000,
    5: 9333,
    "revenu brut": 405000,
    trimf: 500,
    1.5: 67666,
    2.5: 51_000,
    3.5: 34333,
    4.5: 17666,
  },
  {
    1: 76300,
    2: 59633,
    3: 42966,
    4: 26300,
    5: 9633,
    "revenu brut": 406000,
    trimf: 500,
    1.5: 67966,
    2.5: 51300,
    3.5: 34633,
    4.5: 17966,
  },
  {
    1: 76600,
    2: 59933,
    3: 43266,
    4: 26600,
    5: 9933,
    "revenu brut": 407000,
    trimf: 500,
    1.5: 68266,
    2.5: 51600,
    3.5: 34933,
    4.5: 18266,
  },
  {
    1: 76900,
    2: 60233,
    3: 43566,
    4: 26900,
    5: 10233,
    "revenu brut": 408000,
    trimf: 500,
    1.5: 68566,
    2.5: 51900,
    3.5: 35233,
    4.5: 18566,
  },
  {
    1: 77233,
    2: 60566,
    3: 43900,
    4: 27233,
    5: 10566,
    "revenu brut": 409000,
    trimf: 500,
    1.5: 68900,
    2.5: 52233,
    3.5: 35566,
    4.5: 18900,
  },
  {
    1: 77583,
    2: 60916,
    3: 44250,
    4: 27583,
    5: 10916,
    "revenu brut": 41_0000,
    trimf: 500,
    1.5: 69250,
    2.5: 52583,
    3.5: 35916,
    4.5: 19250,
  },
  {
    1: 77933,
    2: 61266,
    3: 44600,
    4: 27933,
    5: 11266,
    "revenu brut": 411_000,
    trimf: 500,
    1.5: 69600,
    2.5: 52933,
    3.5: 36266,
    4.5: 19600,
  },
  {
    1: 78283,
    2: 61616,
    3: 44950,
    4: 28283,
    5: 11616,
    "revenu brut": 412000,
    trimf: 500,
    1.5: 69950,
    2.5: 53283,
    3.5: 36616,
    4.5: 19950,
  },
  {
    1: 78633,
    2: 61966,
    3: 45300,
    4: 28633,
    5: 11966,
    "revenu brut": 413000,
    trimf: 500,
    1.5: 70300,
    2.5: 53633,
    3.5: 36966,
    4.5: 20300,
  },
  {
    1: 78983,
    2: 62316,
    3: 45650,
    4: 28983,
    5: 12316,
    "revenu brut": 414000,
    trimf: 500,
    1.5: 70650,
    2.5: 53983,
    3.5: 37316,
    4.5: 20650,
  },
  {
    1: 79333,
    2: 62666,
    3: 46000,
    4: 29333,
    5: 12666,
    "revenu brut": 415000,
    trimf: 500,
    1.5: 71_000,
    2.5: 54333,
    3.5: 37666,
    4.5: 21_000,
  },
  {
    1: 79683,
    2: 63016,
    3: 46350,
    4: 29683,
    5: 13016,
    "revenu brut": 416000,
    trimf: 500,
    1.5: 71350,
    2.5: 54683,
    3.5: 38016,
    4.5: 21350,
  },
  {
    1: 80033,
    2: 63366,
    3: 46700,
    4: 30033,
    5: 13366,
    "revenu brut": 417000,
    trimf: 500,
    1.5: 71700,
    2.5: 55033,
    3.5: 38366,
    4.5: 21700,
  },
  {
    1: 80383,
    2: 63716,
    3: 47050,
    4: 30383,
    5: 13716,
    "revenu brut": 418000,
    trimf: 500,
    1.5: 72050,
    2.5: 55383,
    3.5: 38716,
    4.5: 22050,
  },
  {
    1: 80733,
    2: 64066,
    3: 47400,
    4: 30733,
    5: 14066,
    "revenu brut": 419000,
    trimf: 500,
    1.5: 72400,
    2.5: 55733,
    3.5: 39066,
    4.5: 22400,
  },
  {
    1: 81083,
    2: 64416,
    3: 47750,
    4: 31083,
    5: 14416,
    "revenu brut": 420000,
    trimf: 500,
    1.5: 72750,
    2.5: 56083,
    3.5: 39416,
    4.5: 22750,
  },
  {
    1: 81433,
    2: 64766,
    3: 48100,
    4: 31433,
    5: 14766,
    "revenu brut": 421_000,
    trimf: 500,
    1.5: 73100,
    2.5: 56433,
    3.5: 39766,
    4.5: 23100,
  },
  {
    1: 81783,
    2: 65116,
    3: 48450,
    4: 31783,
    5: 15116,
    "revenu brut": 422000,
    trimf: 500,
    1.5: 73450,
    2.5: 56783,
    3.5: 40116,
    4.5: 23450,
  },
  {
    1: 82133,
    2: 65466,
    3: 48800,
    4: 32133,
    5: 15466,
    "revenu brut": 423000,
    trimf: 500,
    1.5: 73800,
    2.5: 57133,
    3.5: 40466,
    4.5: 23800,
  },
  {
    1: 82483,
    2: 65816,
    3: 49150,
    4: 32483,
    5: 15816,
    "revenu brut": 424000,
    trimf: 500,
    1.5: 74150,
    2.5: 57483,
    3.5: 40816,
    4.5: 24150,
  },
  {
    1: 82833,
    2: 66166,
    3: 49500,
    4: 32833,
    5: 16166,
    "revenu brut": 425000,
    trimf: 500,
    1.5: 74500,
    2.5: 57833,
    3.5: 41166,
    4.5: 24500,
  },
  {
    1: 83183,
    2: 66516,
    3: 49850,
    4: 33183,
    5: 16516,
    "revenu brut": 426000,
    trimf: 500,
    1.5: 74850,
    2.5: 58183,
    3.5: 41516,
    4.5: 24850,
  },
  {
    1: 83533,
    2: 66866,
    3: 50200,
    4: 33533,
    5: 16866,
    "revenu brut": 427000,
    trimf: 500,
    1.5: 75180,
    2.5: 58533,
    3.5: 41866,
    4.5: 25200,
  },
  {
    1: 83883,
    2: 67216,
    3: 50550,
    4: 33883,
    5: 17216,
    "revenu brut": 428000,
    trimf: 500,
    1.5: 75495,
    2.5: 58883,
    3.5: 42216,
    4.5: 25550,
  },
  {
    1: 84233,
    2: 67566,
    3: 50900,
    4: 34233,
    5: 17566,
    "revenu brut": 429000,
    trimf: 500,
    1.5: 75810,
    2.5: 59233,
    3.5: 42566,
    4.5: 25900,
  },
  {
    1: 84583,
    2: 67916,
    3: 51250,
    4: 34583,
    5: 17916,
    "revenu brut": 430000,
    trimf: 500,
    1.5: 76125,
    2.5: 59583,
    3.5: 42916,
    4.5: 26250,
  },
  {
    1: 84933,
    2: 68266,
    3: 51600,
    4: 34933,
    5: 18266,
    "revenu brut": 431_000,
    trimf: 500,
    1.5: 76440,
    2.5: 59933,
    3.5: 43266,
    4.5: 26600,
  },
  {
    1: 85283,
    2: 68616,
    3: 51950,
    4: 35283,
    5: 18616,
    "revenu brut": 432000,
    trimf: 500,
    1.5: 76755,
    2.5: 60283,
    3.5: 43616,
    4.5: 26950,
  },
  {
    1: 85633,
    2: 68966,
    3: 52300,
    4: 35633,
    5: 18966,
    "revenu brut": 433000,
    trimf: 500,
    1.5: 77070,
    2.5: 60633,
    3.5: 43966,
    4.5: 27300,
  },
  {
    1: 85983,
    2: 69316,
    3: 52650,
    4: 35983,
    5: 19316,
    "revenu brut": 434000,
    trimf: 500,
    1.5: 77385,
    2.5: 60983,
    3.5: 44316,
    4.5: 27650,
  },
  {
    1: 86333,
    2: 69666,
    3: 53000,
    4: 36333,
    5: 19666,
    "revenu brut": 435000,
    trimf: 500,
    1.5: 77700,
    2.5: 61333,
    3.5: 44666,
    4.5: 28000,
  },
  {
    1: 86683,
    2: 70016,
    3: 53350,
    4: 36683,
    5: 20016,
    "revenu brut": 436000,
    trimf: 500,
    1.5: 78015,
    2.5: 61683,
    3.5: 45016,
    4.5: 28350,
  },
  {
    1: 87033,
    2: 70366,
    3: 53700,
    4: 37033,
    5: 20366,
    "revenu brut": 437000,
    trimf: 500,
    1.5: 78330,
    2.5: 62033,
    3.5: 45366,
    4.5: 28700,
  },
  {
    1: 87383,
    2: 70716,
    3: 54050,
    4: 37383,
    5: 20716,
    "revenu brut": 438000,
    trimf: 500,
    1.5: 78645,
    2.5: 62383,
    3.5: 45716,
    4.5: 29050,
  },
  {
    1: 87733,
    2: 71066,
    3: 54400,
    4: 37733,
    5: 21066,
    "revenu brut": 439000,
    trimf: 500,
    1.5: 78960,
    2.5: 62733,
    3.5: 46066,
    4.5: 29400,
  },
  {
    1: 88083,
    2: 71416,
    3: 54750,
    4: 38083,
    5: 21416,
    "revenu brut": 440000,
    trimf: 500,
    1.5: 79275,
    2.5: 63083,
    3.5: 46416,
    4.5: 29750,
  },
  {
    1: 88433,
    2: 71766,
    3: 55100,
    4: 38433,
    5: 21766,
    "revenu brut": 441_000,
    trimf: 500,
    1.5: 79590,
    2.5: 63433,
    3.5: 46766,
    4.5: 30100,
  },
  {
    1: 88783,
    2: 72116,
    3: 55450,
    4: 38783,
    5: 22116,
    "revenu brut": 442000,
    trimf: 500,
    1.5: 79905,
    2.5: 63783,
    3.5: 47116,
    4.5: 30450,
  },
  {
    1: 89133,
    2: 72466,
    3: 55800,
    4: 39133,
    5: 22466,
    "revenu brut": 443000,
    trimf: 500,
    1.5: 80220,
    2.5: 64133,
    3.5: 47466,
    4.5: 30800,
  },
  {
    1: 89483,
    2: 72816,
    3: 56150,
    4: 39483,
    5: 22816,
    "revenu brut": 444000,
    trimf: 500,
    1.5: 80535,
    2.5: 64483,
    3.5: 47816,
    4.5: 31150,
  },
  {
    1: 89833,
    2: 73166,
    3: 56500,
    4: 39833,
    5: 23166,
    "revenu brut": 445000,
    trimf: 500,
    1.5: 80850,
    2.5: 64833,
    3.5: 48166,
    4.5: 31500,
  },
  {
    1: 90183,
    2: 73516,
    3: 56850,
    4: 40183,
    5: 23516,
    "revenu brut": 446000,
    trimf: 500,
    1.5: 81165,
    2.5: 65183,
    3.5: 48516,
    4.5: 31850,
  },
  {
    1: 90533,
    2: 73866,
    3: 57200,
    4: 40533,
    5: 23866,
    "revenu brut": 447000,
    trimf: 500,
    1.5: 81480,
    2.5: 65533,
    3.5: 48866,
    4.5: 32200,
  },
  {
    1: 90883,
    2: 74216,
    3: 57550,
    4: 40883,
    5: 24216,
    "revenu brut": 448000,
    trimf: 500,
    1.5: 81795,
    2.5: 65883,
    3.5: 49216,
    4.5: 32550,
  },
  {
    1: 91233,
    2: 74566,
    3: 57900,
    4: 41233,
    5: 24566,
    "revenu brut": 449000,
    trimf: 500,
    1.5: 82110,
    2.5: 66233,
    3.5: 49566,
    4.5: 32900,
  },
  {
    1: 91583,
    2: 74916,
    3: 58250,
    4: 41583,
    5: 24916,
    "revenu brut": 450000,
    trimf: 500,
    1.5: 82425,
    2.5: 66583,
    3.5: 49916,
    4.5: 33250,
  },
  {
    1: 91933,
    2: 75266,
    3: 58600,
    4: 41933,
    5: 25266,
    "revenu brut": 451_000,
    trimf: 500,
    1.5: 82740,
    2.5: 66933,
    3.5: 50266,
    4.5: 33600,
  },
  {
    1: 92283,
    2: 75616,
    3: 58950,
    4: 42283,
    5: 25616,
    "revenu brut": 452000,
    trimf: 500,
    1.5: 83055,
    2.5: 67283,
    3.5: 50616,
    4.5: 33950,
  },
  {
    1: 92633,
    2: 75966,
    3: 59300,
    4: 42633,
    5: 25966,
    "revenu brut": 453000,
    trimf: 500,
    1.5: 83370,
    2.5: 67633,
    3.5: 50966,
    4.5: 34300,
  },
  {
    1: 92983,
    2: 76316,
    3: 59650,
    4: 42983,
    5: 26316,
    "revenu brut": 454000,
    trimf: 500,
    1.5: 83685,
    2.5: 67983,
    3.5: 51316,
    4.5: 34650,
  },
  {
    1: 93333,
    2: 76666,
    3: 60000,
    4: 43333,
    5: 26666,
    "revenu brut": 455000,
    trimf: 500,
    1.5: 84000,
    2.5: 68333,
    3.5: 51666,
    4.5: 35000,
  },
  {
    1: 93683,
    2: 77016,
    3: 60350,
    4: 43683,
    5: 27016,
    "revenu brut": 456000,
    trimf: 500,
    1.5: 84315,
    2.5: 68683,
    3.5: 52016,
    4.5: 35350,
  },
  {
    1: 94033,
    2: 77366,
    3: 60700,
    4: 44033,
    5: 27366,
    "revenu brut": 457000,
    trimf: 500,
    1.5: 84630,
    2.5: 69033,
    3.5: 52366,
    4.5: 35700,
  },
  {
    1: 94383,
    2: 77716,
    3: 61050,
    4: 44383,
    5: 27716,
    "revenu brut": 458000,
    trimf: 500,
    1.5: 84945,
    2.5: 69383,
    3.5: 52716,
    4.5: 36050,
  },
  {
    1: 94733,
    2: 78066,
    3: 61400,
    4: 44733,
    5: 28066,
    "revenu brut": 459000,
    trimf: 500,
    1.5: 85260,
    2.5: 69733,
    3.5: 53066,
    4.5: 36400,
  },
  {
    1: 95083,
    2: 78416,
    3: 61750,
    4: 45083,
    5: 28416,
    "revenu brut": 460000,
    trimf: 500,
    1.5: 85575,
    2.5: 70083,
    3.5: 53416,
    4.5: 36750,
  },
  {
    1: 95433,
    2: 78766,
    3: 62100,
    4: 45433,
    5: 28766,
    "revenu brut": 461_000,
    trimf: 500,
    1.5: 85890,
    2.5: 70433,
    3.5: 53766,
    4.5: 37100,
  },
  {
    1: 95783,
    2: 79116,
    3: 62450,
    4: 45783,
    5: 29116,
    "revenu brut": 462000,
    trimf: 500,
    1.5: 86205,
    2.5: 70783,
    3.5: 54116,
    4.5: 37450,
  },
  {
    1: 96133,
    2: 79466,
    3: 62800,
    4: 46133,
    5: 29466,
    "revenu brut": 463000,
    trimf: 500,
    1.5: 86520,
    2.5: 71133,
    3.5: 54466,
    4.5: 37800,
  },
  {
    1: 96483,
    2: 79816,
    3: 63150,
    4: 46483,
    5: 29816,
    "revenu brut": 464000,
    trimf: 500,
    1.5: 86835,
    2.5: 71483,
    3.5: 54816,
    4.5: 38150,
  },
  {
    1: 96833,
    2: 80166,
    3: 63500,
    4: 46833,
    5: 30166,
    "revenu brut": 465000,
    trimf: 500,
    1.5: 87150,
    2.5: 71833,
    3.5: 55166,
    4.5: 38500,
  },
  {
    1: 97183,
    2: 80516,
    3: 63850,
    4: 47183,
    5: 30516,
    "revenu brut": 466000,
    trimf: 500,
    1.5: 87465,
    2.5: 72183,
    3.5: 55516,
    4.5: 38850,
  },
  {
    1: 97533,
    2: 80866,
    3: 64200,
    4: 47533,
    5: 30866,
    "revenu brut": 467000,
    trimf: 500,
    1.5: 87780,
    2.5: 72533,
    3.5: 55866,
    4.5: 39200,
  },
  {
    1: 97883,
    2: 81216,
    3: 64550,
    4: 47883,
    5: 31216,
    "revenu brut": 468000,
    trimf: 500,
    1.5: 88095,
    2.5: 72883,
    3.5: 56216,
    4.5: 39550,
  },
  {
    1: 98233,
    2: 81566,
    3: 64900,
    4: 48233,
    5: 31566,
    "revenu brut": 469000,
    trimf: 500,
    1.5: 88410,
    2.5: 73233,
    3.5: 56566,
    4.5: 39900,
  },
  {
    1: 98583,
    2: 81916,
    3: 65250,
    4: 48583,
    5: 31916,
    "revenu brut": 470000,
    trimf: 500,
    1.5: 88725,
    2.5: 73583,
    3.5: 56916,
    4.5: 40250,
  },
  {
    1: 98933,
    2: 82266,
    3: 65600,
    4: 48933,
    5: 32266,
    "revenu brut": 471_000,
    trimf: 500,
    1.5: 89040,
    2.5: 73933,
    3.5: 57266,
    4.5: 40600,
  },
  {
    1: 99283,
    2: 82616,
    3: 65950,
    4: 49283,
    5: 32616,
    "revenu brut": 472000,
    trimf: 500,
    1.5: 89355,
    2.5: 74283,
    3.5: 57616,
    4.5: 40950,
  },
  {
    1: 99633,
    2: 82966,
    3: 66300,
    4: 49633,
    5: 32966,
    "revenu brut": 473000,
    trimf: 500,
    1.5: 89670,
    2.5: 74633,
    3.5: 57966,
    4.5: 41300,
  },
  {
    1: 99983,
    2: 83316,
    3: 66650,
    4: 49983,
    5: 33316,
    "revenu brut": 474000,
    trimf: 500,
    1.5: 89985,
    2.5: 74983,
    3.5: 58316,
    4.5: 41650,
  },
  {
    1: 100333,
    2: 83666,
    3: 67000,
    4: 50333,
    5: 33666,
    "revenu brut": 475000,
    trimf: 500,
    1.5: 90300,
    2.5: 75333,
    3.5: 58666,
    4.5: 42000,
  },
  {
    1: 100683,
    2: 84016,
    3: 67350,
    4: 50683,
    5: 34016,
    "revenu brut": 476000,
    trimf: 500,
    1.5: 90615,
    2.5: 75683,
    3.5: 59016,
    4.5: 42350,
  },
  {
    1: 101033,
    2: 84366,
    3: 67700,
    4: 51033,
    5: 34366,
    "revenu brut": 477000,
    trimf: 500,
    1.5: 90930,
    2.5: 76033,
    3.5: 59366,
    4.5: 42700,
  },
  {
    1: 101383,
    2: 84716,
    3: 68050,
    4: 51383,
    5: 34716,
    "revenu brut": 478000,
    trimf: 500,
    1.5: 91245,
    2.5: 76383,
    3.5: 59716,
    4.5: 43050,
  },
  {
    1: 101733,
    2: 85066,
    3: 68400,
    4: 51733,
    5: 35066,
    "revenu brut": 479000,
    trimf: 500,
    1.5: 91560,
    2.5: 76733,
    3.5: 60066,
    4.5: 43400,
  },
  {
    1: 102083,
    2: 85416,
    3: 68750,
    4: 52083,
    5: 35416,
    "revenu brut": 480000,
    trimf: 500,
    1.5: 91875,
    2.5: 77083,
    3.5: 60416,
    4.5: 43750,
  },
  {
    1: 102433,
    2: 85766,
    3: 69100,
    4: 52433,
    5: 35766,
    "revenu brut": 481_000,
    trimf: 500,
    1.5: 92190,
    2.5: 77433,
    3.5: 60766,
    4.5: 44100,
  },
  {
    1: 102783,
    2: 86116,
    3: 69450,
    4: 52783,
    5: 36116,
    "revenu brut": 482000,
    trimf: 500,
    1.5: 92505,
    2.5: 77783,
    3.5: 61116,
    4.5: 44450,
  },
  {
    1: 103133,
    2: 86466,
    3: 69800,
    4: 53133,
    5: 36466,
    "revenu brut": 483000,
    trimf: 500,
    1.5: 92820,
    2.5: 78133,
    3.5: 61466,
    4.5: 44800,
  },
  {
    1: 103483,
    2: 86816,
    3: 70150,
    4: 53483,
    5: 36816,
    "revenu brut": 484000,
    trimf: 500,
    1.5: 93135,
    2.5: 78483,
    3.5: 61816,
    4.5: 45150,
  },
  {
    1: 103833,
    2: 87166,
    3: 70500,
    4: 53833,
    5: 37166,
    "revenu brut": 485000,
    trimf: 500,
    1.5: 93450,
    2.5: 78833,
    3.5: 62166,
    4.5: 45500,
  },
  {
    1: 104183,
    2: 87516,
    3: 70850,
    4: 54183,
    5: 37516,
    "revenu brut": 486000,
    trimf: 500,
    1.5: 93765,
    2.5: 79183,
    3.5: 62516,
    4.5: 45850,
  },
  {
    1: 104533,
    2: 87866,
    3: 71200,
    4: 54533,
    5: 37866,
    "revenu brut": 487000,
    trimf: 500,
    1.5: 94080,
    2.5: 79533,
    3.5: 62866,
    4.5: 46200,
  },
  {
    1: 104883,
    2: 88216,
    3: 71550,
    4: 54883,
    5: 38216,
    "revenu brut": 488000,
    trimf: 500,
    1.5: 94395,
    2.5: 79883,
    3.5: 63216,
    4.5: 46550,
  },
  {
    1: 105233,
    2: 88566,
    3: 71900,
    4: 55233,
    5: 38566,
    "revenu brut": 489000,
    trimf: 500,
    1.5: 94710,
    2.5: 80233,
    3.5: 63566,
    4.5: 46900,
  },
  {
    1: 105583,
    2: 88916,
    3: 72250,
    4: 55583,
    5: 38916,
    "revenu brut": 490000,
    trimf: 500,
    1.5: 95025,
    2.5: 80583,
    3.5: 63916,
    4.5: 47250,
  },
  {
    1: 105933,
    2: 89266,
    3: 72600,
    4: 55933,
    5: 39266,
    "revenu brut": 491_000,
    trimf: 500,
    1.5: 95340,
    2.5: 80933,
    3.5: 64266,
    4.5: 47600,
  },
  {
    1: 106283,
    2: 89616,
    3: 72950,
    4: 56283,
    5: 39616,
    "revenu brut": 492000,
    trimf: 500,
    1.5: 95655,
    2.5: 81283,
    3.5: 64616,
    4.5: 47950,
  },
  {
    1: 106633,
    2: 89966,
    3: 73300,
    4: 56633,
    5: 39966,
    "revenu brut": 493000,
    trimf: 500,
    1.5: 95970,
    2.5: 81633,
    3.5: 64966,
    4.5: 48300,
  },
  {
    1: 106983,
    2: 90316,
    3: 73650,
    4: 56983,
    5: 40316,
    "revenu brut": 494000,
    trimf: 500,
    1.5: 96285,
    2.5: 81983,
    3.5: 65316,
    4.5: 48650,
  },
  {
    1: 107333,
    2: 90666,
    3: 74000,
    4: 57333,
    5: 40666,
    "revenu brut": 495000,
    trimf: 500,
    1.5: 96600,
    2.5: 82333,
    3.5: 65666,
    4.5: 49000,
  },
  {
    1: 107683,
    2: 91016,
    3: 74350,
    4: 57683,
    5: 41016,
    "revenu brut": 496000,
    trimf: 500,
    1.5: 96915,
    2.5: 82683,
    3.5: 66016,
    4.5: 49350,
  },
  {
    1: 108033,
    2: 91366,
    3: 74700,
    4: 58033,
    5: 41366,
    "revenu brut": 497000,
    trimf: 500,
    1.5: 97230,
    2.5: 83033,
    3.5: 66366,
    4.5: 49700,
  },
  {
    1: 108383,
    2: 91716,
    3: 75050,
    4: 58383,
    5: 41716,
    "revenu brut": 498000,
    trimf: 500,
    1.5: 97545,
    2.5: 83383,
    3.5: 66716,
    4.5: 50050,
  },
  {
    1: 108733,
    2: 92066,
    3: 75400,
    4: 58733,
    5: 42066,
    "revenu brut": 499000,
    trimf: 500,
    1.5: 97860,
    2.5: 83733,
    3.5: 67066,
    4.5: 50400,
  },
  {
    1: 109083,
    2: 92416,
    3: 75750,
    4: 59083,
    5: 42416,
    "revenu brut": 500000,
    trimf: 500,
    1.5: 98175,
    2.5: 84083,
    3.5: 67416,
    4.5: 50750,
  },
  {
    1: 109433,
    2: 92766,
    3: 76100,
    4: 59433,
    5: 42766,
    "revenu brut": 501_000,
    trimf: 500,
    1.5: 98490,
    2.5: 84433,
    3.5: 67766,
    4.5: 51100,
  },
  {
    1: 109783,
    2: 93116,
    3: 76450,
    4: 59783,
    5: 43116,
    "revenu brut": 502000,
    trimf: 500,
    1.5: 98805,
    2.5: 84783,
    3.5: 68116,
    4.5: 51450,
  },
  {
    1: 110133,
    2: 93466,
    3: 76800,
    4: 60133,
    5: 43466,
    "revenu brut": 503000,
    trimf: 500,
    1.5: 99120,
    2.5: 85133,
    3.5: 68466,
    4.5: 51800,
  },
  {
    1: 110483,
    2: 93816,
    3: 77150,
    4: 60483,
    5: 43816,
    "revenu brut": 504000,
    trimf: 500,
    1.5: 99435,
    2.5: 85483,
    3.5: 68816,
    4.5: 52150,
  },
  {
    1: 110833,
    2: 94166,
    3: 77500,
    4: 60833,
    5: 44166,
    "revenu brut": 505000,
    trimf: 500,
    1.5: 99750,
    2.5: 85833,
    3.5: 69166,
    4.5: 52500,
  },
  {
    1: 111183,
    2: 94505,
    3: 77850,
    4: 61183,
    5: 44516,
    "revenu brut": 506000,
    trimf: 500,
    1.5: 1_00065,
    2.5: 86183,
    3.5: 69516,
    4.5: 52850,
  },
  {
    1: 111533,
    2: 94803,
    3: 78200,
    4: 61533,
    5: 44866,
    "revenu brut": 507000,
    trimf: 500,
    1.5: 100380,
    2.5: 86533,
    3.5: 69866,
    4.5: 53200,
  },
  {
    1: 111883,
    2: 95100,
    3: 78550,
    4: 61883,
    5: 45216,
    "revenu brut": 508000,
    trimf: 500,
    1.5: 100695,
    2.5: 86883,
    3.5: 70216,
    4.5: 53550,
  },
  {
    1: 112233,
    2: 95398,
    3: 78900,
    4: 62233,
    5: 45566,
    "revenu brut": 509000,
    trimf: 500,
    1.5: 101010,
    2.5: 87233,
    3.5: 70566,
    4.5: 53900,
  },
  {
    1: 112583,
    2: 95695,
    3: 79250,
    4: 62583,
    5: 45916,
    "revenu brut": 51_0000,
    trimf: 500,
    1.5: 101325,
    2.5: 87583,
    3.5: 70916,
    4.5: 54250,
  },
  {
    1: 112933,
    2: 95993,
    3: 79600,
    4: 62933,
    5: 46266,
    "revenu brut": 511_000,
    trimf: 500,
    1.5: 101640,
    2.5: 87933,
    3.5: 71266,
    4.5: 54600,
  },
  {
    1: 113283,
    2: 96290,
    3: 79950,
    4: 63283,
    5: 46616,
    "revenu brut": 512000,
    trimf: 500,
    1.5: 101955,
    2.5: 88283,
    3.5: 71616,
    4.5: 54950,
  },
  {
    1: 113633,
    2: 96588,
    3: 80300,
    4: 63633,
    5: 46966,
    "revenu brut": 513000,
    trimf: 500,
    1.5: 102270,
    2.5: 88633,
    3.5: 71966,
    4.5: 55300,
  },
  {
    1: 113983,
    2: 96885,
    3: 80650,
    4: 63983,
    5: 47316,
    "revenu brut": 514000,
    trimf: 500,
    1.5: 102585,
    2.5: 88983,
    3.5: 72316,
    4.5: 55650,
  },
  {
    1: 114333,
    2: 97183,
    3: 81_000,
    4: 64333,
    5: 47666,
    "revenu brut": 515000,
    trimf: 500,
    1.5: 102900,
    2.5: 89333,
    3.5: 72666,
    4.5: 56000,
  },
  {
    1: 114683,
    2: 97480,
    3: 81350,
    4: 64683,
    5: 48016,
    "revenu brut": 516000,
    trimf: 500,
    1.5: 103215,
    2.5: 89683,
    3.5: 73016,
    4.5: 56350,
  },
  {
    1: 115033,
    2: 97778,
    3: 81700,
    4: 65033,
    5: 48366,
    "revenu brut": 517000,
    trimf: 500,
    1.5: 103530,
    2.5: 90033,
    3.5: 73366,
    4.5: 56700,
  },
  {
    1: 115383,
    2: 98075,
    3: 82050,
    4: 65383,
    5: 48716,
    "revenu brut": 518000,
    trimf: 500,
    1.5: 103845,
    2.5: 90383,
    3.5: 73716,
    4.5: 57050,
  },
  {
    1: 115733,
    2: 98373,
    3: 82400,
    4: 65733,
    5: 49066,
    "revenu brut": 519000,
    trimf: 500,
    1.5: 104160,
    2.5: 90733,
    3.5: 74066,
    4.5: 57400,
  },
  {
    1: 116083,
    2: 98670,
    3: 82750,
    4: 66083,
    5: 49416,
    "revenu brut": 520000,
    trimf: 500,
    1.5: 104475,
    2.5: 91083,
    3.5: 74416,
    4.5: 57750,
  },
  {
    1: 116433,
    2: 98968,
    3: 83100,
    4: 66433,
    5: 49766,
    "revenu brut": 521_000,
    trimf: 500,
    1.5: 104790,
    2.5: 91433,
    3.5: 74766,
    4.5: 58100,
  },
  {
    1: 116783,
    2: 99265,
    3: 83450,
    4: 66783,
    5: 50116,
    "revenu brut": 522000,
    trimf: 500,
    1.5: 105105,
    2.5: 91783,
    3.5: 75116,
    4.5: 58450,
  },
  {
    1: 117133,
    2: 99563,
    3: 83800,
    4: 67133,
    5: 50466,
    "revenu brut": 523000,
    trimf: 500,
    1.5: 105420,
    2.5: 92133,
    3.5: 75466,
    4.5: 58800,
  },
  {
    1: 117483,
    2: 99860,
    3: 84150,
    4: 67483,
    5: 50816,
    "revenu brut": 524000,
    trimf: 500,
    1.5: 105735,
    2.5: 92483,
    3.5: 75816,
    4.5: 59150,
  },
  {
    1: 117833,
    2: 100158,
    3: 84500,
    4: 67833,
    5: 51166,
    "revenu brut": 525000,
    trimf: 500,
    1.5: 106050,
    2.5: 92833,
    3.5: 76166,
    4.5: 59500,
  },
  {
    1: 118183,
    2: 100455,
    3: 84850,
    4: 68183,
    5: 51516,
    "revenu brut": 526000,
    trimf: 500,
    1.5: 106365,
    2.5: 93183,
    3.5: 76516,
    4.5: 59850,
  },
  {
    1: 118533,
    2: 100753,
    3: 85200,
    4: 68533,
    5: 51866,
    "revenu brut": 527000,
    trimf: 500,
    1.5: 106680,
    2.5: 93533,
    3.5: 76866,
    4.5: 60200,
  },
  {
    1: 118883,
    2: 101050,
    3: 85550,
    4: 68883,
    5: 52216,
    "revenu brut": 528000,
    trimf: 500,
    1.5: 106995,
    2.5: 93883,
    3.5: 77216,
    4.5: 60550,
  },
  {
    1: 119233,
    2: 101348,
    3: 85900,
    4: 69233,
    5: 52566,
    "revenu brut": 529000,
    trimf: 500,
    1.5: 107310,
    2.5: 94233,
    3.5: 77566,
    4.5: 60900,
  },
  {
    1: 119583,
    2: 101645,
    3: 86250,
    4: 69583,
    5: 52916,
    "revenu brut": 530000,
    trimf: 500,
    1.5: 107625,
    2.5: 94583,
    3.5: 77916,
    4.5: 61250,
  },
  {
    1: 119933,
    2: 101943,
    3: 86600,
    4: 69933,
    5: 53266,
    "revenu brut": 531_000,
    trimf: 500,
    1.5: 107940,
    2.5: 94933,
    3.5: 78266,
    4.5: 61600,
  },
  {
    1: 120283,
    2: 102240,
    3: 86950,
    4: 70283,
    5: 53616,
    "revenu brut": 532000,
    trimf: 500,
    1.5: 108255,
    2.5: 95283,
    3.5: 78616,
    4.5: 61950,
  },
  {
    1: 120633,
    2: 102538,
    3: 87300,
    4: 70633,
    5: 53966,
    "revenu brut": 533000,
    trimf: 500,
    1.5: 108570,
    2.5: 95633,
    3.5: 78966,
    4.5: 62300,
  },
  {
    1: 120983,
    2: 102835,
    3: 87650,
    4: 70983,
    5: 54316,
    "revenu brut": 534000,
    trimf: 500,
    1.5: 108885,
    2.5: 95983,
    3.5: 79316,
    4.5: 62650,
  },
  {
    1: 121333,
    2: 103133,
    3: 88000,
    4: 71333,
    5: 54666,
    "revenu brut": 535000,
    trimf: 500,
    1.5: 109200,
    2.5: 96333,
    3.5: 79666,
    4.5: 63000,
  },
  {
    1: 121683,
    2: 103430,
    3: 88350,
    4: 71683,
    5: 55016,
    "revenu brut": 536000,
    trimf: 500,
    1.5: 109515,
    2.5: 96683,
    3.5: 80016,
    4.5: 63350,
  },
  {
    1: 122033,
    2: 103728,
    3: 88700,
    4: 72033,
    5: 55366,
    "revenu brut": 537000,
    trimf: 500,
    1.5: 109830,
    2.5: 97033,
    3.5: 80366,
    4.5: 63700,
  },
  {
    1: 122383,
    2: 104025,
    3: 89050,
    4: 72383,
    5: 55716,
    "revenu brut": 538000,
    trimf: 500,
    1.5: 110145,
    2.5: 97383,
    3.5: 80716,
    4.5: 64050,
  },
  {
    1: 122733,
    2: 104323,
    3: 89400,
    4: 72733,
    5: 56066,
    "revenu brut": 539000,
    trimf: 500,
    1.5: 110460,
    2.5: 97733,
    3.5: 81066,
    4.5: 64400,
  },
  {
    1: 123083,
    2: 104620,
    3: 89750,
    4: 73083,
    5: 56416,
    "revenu brut": 540000,
    trimf: 500,
    1.5: 110775,
    2.5: 98083,
    3.5: 81416,
    4.5: 64750,
  },
  {
    1: 123433,
    2: 104918,
    3: 90100,
    4: 73433,
    5: 56766,
    "revenu brut": 541_000,
    trimf: 500,
    1.5: 111090,
    2.5: 98433,
    3.5: 81766,
    4.5: 65100,
  },
  {
    1: 123783,
    2: 105215,
    3: 90450,
    4: 73783,
    5: 57116,
    "revenu brut": 542000,
    trimf: 500,
    1.5: 111405,
    2.5: 98783,
    3.5: 82116,
    4.5: 65450,
  },
  {
    1: 124133,
    2: 105513,
    3: 90800,
    4: 74133,
    5: 57466,
    "revenu brut": 543000,
    trimf: 500,
    1.5: 111720,
    2.5: 99133,
    3.5: 82466,
    4.5: 65800,
  },
  {
    1: 124483,
    2: 105810,
    3: 91150,
    4: 74483,
    5: 57816,
    "revenu brut": 544000,
    trimf: 500,
    1.5: 112035,
    2.5: 99483,
    3.5: 82816,
    4.5: 66150,
  },
  {
    1: 124833,
    2: 106108,
    3: 91500,
    4: 74833,
    5: 58166,
    "revenu brut": 545000,
    trimf: 500,
    1.5: 112350,
    2.5: 99833,
    3.5: 83166,
    4.5: 66500,
  },
  {
    1: 125183,
    2: 106405,
    3: 91850,
    4: 75183,
    5: 58516,
    "revenu brut": 546000,
    trimf: 500,
    1.5: 112665,
    2.5: 100146,
    3.5: 83516,
    4.5: 66850,
  },
  {
    1: 125533,
    2: 106703,
    3: 92200,
    4: 75533,
    5: 58866,
    "revenu brut": 547000,
    trimf: 500,
    1.5: 112980,
    2.5: 100426,
    3.5: 83866,
    4.5: 67200,
  },
  {
    1: 125883,
    2: 107000,
    3: 92550,
    4: 75883,
    5: 59216,
    "revenu brut": 548000,
    trimf: 500,
    1.5: 113295,
    2.5: 100706,
    3.5: 84216,
    4.5: 67550,
  },
  {
    1: 126233,
    2: 107298,
    3: 92900,
    4: 76233,
    5: 59566,
    "revenu brut": 549000,
    trimf: 500,
    1.5: 113610,
    2.5: 100986,
    3.5: 84566,
    4.5: 67900,
  },
  {
    1: 126583,
    2: 107595,
    3: 93250,
    4: 76583,
    5: 59916,
    "revenu brut": 550000,
    trimf: 500,
    1.5: 113925,
    2.5: 101266,
    3.5: 84916,
    4.5: 68250,
  },
  {
    1: 126933,
    2: 107893,
    3: 93600,
    4: 76933,
    5: 60266,
    "revenu brut": 551_000,
    trimf: 500,
    1.5: 114240,
    2.5: 101546,
    3.5: 85266,
    4.5: 68600,
  },
  {
    1: 127283,
    2: 108190,
    3: 93950,
    4: 77283,
    5: 60616,
    "revenu brut": 552000,
    trimf: 500,
    1.5: 114555,
    2.5: 101826,
    3.5: 85616,
    4.5: 68950,
  },
  {
    1: 127633,
    2: 108488,
    3: 94300,
    4: 77633,
    5: 60966,
    "revenu brut": 553000,
    trimf: 500,
    1.5: 114870,
    2.5: 102106,
    3.5: 85966,
    4.5: 69300,
  },
  {
    1: 127983,
    2: 108785,
    3: 94650,
    4: 77983,
    5: 61316,
    "revenu brut": 554000,
    trimf: 500,
    1.5: 115185,
    2.5: 102386,
    3.5: 86316,
    4.5: 69650,
  },
  {
    1: 128333,
    2: 109083,
    3: 95000,
    4: 78333,
    5: 61666,
    "revenu brut": 555000,
    trimf: 500,
    1.5: 115500,
    2.5: 102666,
    3.5: 86666,
    4.5: 70000,
  },
  {
    1: 128683,
    2: 109380,
    3: 95350,
    4: 78683,
    5: 62016,
    "revenu brut": 556000,
    trimf: 500,
    1.5: 115815,
    2.5: 102946,
    3.5: 87016,
    4.5: 70350,
  },
  {
    1: 129033,
    2: 109678,
    3: 95700,
    4: 79033,
    5: 62366,
    "revenu brut": 557000,
    trimf: 500,
    1.5: 116130,
    2.5: 103226,
    3.5: 87366,
    4.5: 70700,
  },
  {
    1: 129383,
    2: 109975,
    3: 96050,
    4: 79383,
    5: 62716,
    "revenu brut": 558000,
    trimf: 500,
    1.5: 116445,
    2.5: 103506,
    3.5: 87716,
    4.5: 71050,
  },
  {
    1: 129733,
    2: 110273,
    3: 96400,
    4: 79733,
    5: 63066,
    "revenu brut": 559000,
    trimf: 500,
    1.5: 116760,
    2.5: 103786,
    3.5: 88066,
    4.5: 71400,
  },
  {
    1: 130083,
    2: 110570,
    3: 96750,
    4: 80083,
    5: 63416,
    "revenu brut": 560000,
    trimf: 500,
    1.5: 117075,
    2.5: 104066,
    3.5: 88416,
    4.5: 71750,
  },
  {
    1: 130433,
    2: 110868,
    3: 97100,
    4: 80433,
    5: 63766,
    "revenu brut": 561_000,
    trimf: 500,
    1.5: 117390,
    2.5: 104346,
    3.5: 88766,
    4.5: 72100,
  },
  {
    1: 130783,
    2: 111165,
    3: 97450,
    4: 80783,
    5: 64116,
    "revenu brut": 562000,
    trimf: 500,
    1.5: 117705,
    2.5: 104626,
    3.5: 89116,
    4.5: 72450,
  },
  {
    1: 131133,
    2: 111463,
    3: 97800,
    4: 81133,
    5: 64466,
    "revenu brut": 563000,
    trimf: 500,
    1.5: 118020,
    2.5: 104906,
    3.5: 89466,
    4.5: 72800,
  },
  {
    1: 131483,
    2: 111760,
    3: 98150,
    4: 81483,
    5: 64816,
    "revenu brut": 564000,
    trimf: 500,
    1.5: 118335,
    2.5: 105186,
    3.5: 89816,
    4.5: 73150,
  },
  {
    1: 131833,
    2: 112058,
    3: 98500,
    4: 81833,
    5: 65166,
    "revenu brut": 565000,
    trimf: 500,
    1.5: 118650,
    2.5: 105466,
    3.5: 90166,
    4.5: 73500,
  },
  {
    1: 132183,
    2: 112355,
    3: 98850,
    4: 82183,
    5: 65516,
    "revenu brut": 566000,
    trimf: 500,
    1.5: 118965,
    2.5: 105746,
    3.5: 90516,
    4.5: 73850,
  },
  {
    1: 132533,
    2: 112653,
    3: 99200,
    4: 82533,
    5: 65866,
    "revenu brut": 567000,
    trimf: 500,
    1.5: 119280,
    2.5: 106026,
    3.5: 90866,
    4.5: 74200,
  },
  {
    1: 132883,
    2: 112950,
    3: 99550,
    4: 82883,
    5: 66216,
    "revenu brut": 568000,
    trimf: 500,
    1.5: 119595,
    2.5: 106306,
    3.5: 91216,
    4.5: 74550,
  },
  {
    1: 133233,
    2: 113248,
    3: 99900,
    4: 83233,
    5: 66566,
    "revenu brut": 569000,
    trimf: 500,
    1.5: 119910,
    2.5: 106586,
    3.5: 91566,
    4.5: 74900,
  },
  {
    1: 133583,
    2: 113545,
    3: 100187,
    4: 83583,
    5: 66916,
    "revenu brut": 570000,
    trimf: 500,
    1.5: 120225,
    2.5: 106866,
    3.5: 91916,
    4.5: 75250,
  },
  {
    1: 133933,
    2: 113843,
    3: 100450,
    4: 83933,
    5: 67266,
    "revenu brut": 571_000,
    trimf: 500,
    1.5: 120540,
    2.5: 107146,
    3.5: 92266,
    4.5: 75600,
  },
  {
    1: 134283,
    2: 114140,
    3: 100712,
    4: 84283,
    5: 67616,
    "revenu brut": 572000,
    trimf: 500,
    1.5: 120855,
    2.5: 107426,
    3.5: 92616,
    4.5: 75950,
  },
  {
    1: 134633,
    2: 114438,
    3: 100975,
    4: 84633,
    5: 67966,
    "revenu brut": 573000,
    trimf: 500,
    1.5: 121170,
    2.5: 107706,
    3.5: 92966,
    4.5: 76300,
  },
  {
    1: 134983,
    2: 114735,
    3: 101237,
    4: 84983,
    5: 68316,
    "revenu brut": 574000,
    trimf: 500,
    1.5: 121485,
    2.5: 107986,
    3.5: 93316,
    4.5: 76650,
  },
  {
    1: 135333,
    2: 115033,
    3: 101500,
    4: 85333,
    5: 68666,
    "revenu brut": 575000,
    trimf: 500,
    1.5: 121800,
    2.5: 108266,
    3.5: 93666,
    4.5: 77000,
  },
  {
    1: 135683,
    2: 115330,
    3: 101762,
    4: 85683,
    5: 69016,
    "revenu brut": 576000,
    trimf: 500,
    1.5: 122115,
    2.5: 108546,
    3.5: 94016,
    4.5: 77350,
  },
  {
    1: 136033,
    2: 115628,
    3: 102025,
    4: 86033,
    5: 69366,
    "revenu brut": 577000,
    trimf: 500,
    1.5: 122430,
    2.5: 108826,
    3.5: 94366,
    4.5: 77700,
  },
  {
    1: 136383,
    2: 115925,
    3: 102287,
    4: 86383,
    5: 69716,
    "revenu brut": 578000,
    trimf: 500,
    1.5: 122745,
    2.5: 109106,
    3.5: 94716,
    4.5: 78050,
  },
  {
    1: 136733,
    2: 116223,
    3: 102550,
    4: 86733,
    5: 70066,
    "revenu brut": 579000,
    trimf: 500,
    1.5: 123060,
    2.5: 109386,
    3.5: 95066,
    4.5: 78400,
  },
  {
    1: 137083,
    2: 116520,
    3: 102812,
    4: 87083,
    5: 70416,
    "revenu brut": 580000,
    trimf: 500,
    1.5: 123375,
    2.5: 109666,
    3.5: 95416,
    4.5: 78750,
  },
  {
    1: 137433,
    2: 116818,
    3: 103075,
    4: 87433,
    5: 70766,
    "revenu brut": 581_000,
    trimf: 500,
    1.5: 123690,
    2.5: 109946,
    3.5: 95766,
    4.5: 79100,
  },
  {
    1: 137783,
    2: 117115,
    3: 103337,
    4: 87783,
    5: 71116,
    "revenu brut": 582000,
    trimf: 500,
    1.5: 124005,
    2.5: 110226,
    3.5: 96116,
    4.5: 79450,
  },
  {
    1: 138133,
    2: 117413,
    3: 103600,
    4: 88133,
    5: 71466,
    "revenu brut": 583000,
    trimf: 500,
    1.5: 124320,
    2.5: 110506,
    3.5: 96466,
    4.5: 79800,
  },
  {
    1: 138483,
    2: 117710,
    3: 103862,
    4: 88483,
    5: 71816,
    "revenu brut": 584000,
    trimf: 500,
    1.5: 124635,
    2.5: 110786,
    3.5: 96816,
    4.5: 80150,
  },
  {
    1: 138833,
    2: 118008,
    3: 104125,
    4: 88833,
    5: 72166,
    "revenu brut": 585000,
    trimf: 500,
    1.5: 124950,
    2.5: 111066,
    3.5: 97166,
    4.5: 80500,
  },
  {
    1: 139183,
    2: 118305,
    3: 104387,
    4: 89183,
    5: 72516,
    "revenu brut": 586000,
    trimf: 500,
    1.5: 125265,
    2.5: 111346,
    3.5: 97428,
    4.5: 80850,
  },
  {
    1: 139533,
    2: 118603,
    3: 104650,
    4: 89533,
    5: 72866,
    "revenu brut": 587000,
    trimf: 500,
    1.5: 125580,
    2.5: 111626,
    3.5: 97673,
    4.5: 81200,
  },
  {
    1: 139883,
    2: 118900,
    3: 104912,
    4: 89883,
    5: 73216,
    "revenu brut": 588000,
    trimf: 500,
    1.5: 125895,
    2.5: 111906,
    3.5: 97918,
    4.5: 81550,
  },
  {
    1: 140233,
    2: 119198,
    3: 105175,
    4: 90233,
    5: 73566,
    "revenu brut": 589000,
    trimf: 500,
    1.5: 126210,
    2.5: 112186,
    3.5: 98163,
    4.5: 81900,
  },
  {
    1: 140583,
    2: 119495,
    3: 105437,
    4: 90583,
    5: 73916,
    "revenu brut": 590000,
    trimf: 500,
    1.5: 126525,
    2.5: 112466,
    3.5: 98408,
    4.5: 82250,
  },
  {
    1: 140933,
    2: 119793,
    3: 105700,
    4: 90933,
    5: 74266,
    "revenu brut": 591_000,
    trimf: 500,
    1.5: 126840,
    2.5: 112746,
    3.5: 98653,
    4.5: 82600,
  },
  {
    1: 141283,
    2: 120090,
    3: 105962,
    4: 91283,
    5: 74616,
    "revenu brut": 592000,
    trimf: 500,
    1.5: 127155,
    2.5: 113026,
    3.5: 98898,
    4.5: 82950,
  },
  {
    1: 141633,
    2: 120388,
    3: 106225,
    4: 91633,
    5: 74966,
    "revenu brut": 593000,
    trimf: 500,
    1.5: 127470,
    2.5: 113306,
    3.5: 99143,
    4.5: 83300,
  },
  {
    1: 141983,
    2: 120685,
    3: 106487,
    4: 91983,
    5: 75316,
    "revenu brut": 594000,
    trimf: 500,
    1.5: 127785,
    2.5: 113586,
    3.5: 99388,
    4.5: 83650,
  },
  {
    1: 142333,
    2: 120983,
    3: 106750,
    4: 92333,
    5: 75666,
    "revenu brut": 595000,
    trimf: 500,
    1.5: 128100,
    2.5: 113866,
    3.5: 99633,
    4.5: 84000,
  },
  {
    1: 142683,
    2: 121280,
    3: 107012,
    4: 92683,
    5: 76016,
    "revenu brut": 596000,
    trimf: 500,
    1.5: 128415,
    2.5: 114146,
    3.5: 99878,
    4.5: 84350,
  },
  {
    1: 143033,
    2: 121578,
    3: 107275,
    4: 92971,
    5: 76366,
    "revenu brut": 597000,
    trimf: 500,
    1.5: 128730,
    2.5: 114426,
    3.5: 100123,
    4.5: 84700,
  },
  {
    1: 143383,
    2: 121875,
    3: 107537,
    4: 93199,
    5: 76716,
    "revenu brut": 598000,
    trimf: 500,
    1.5: 129045,
    2.5: 114706,
    3.5: 100368,
    4.5: 85050,
  },
  {
    1: 143733,
    2: 122173,
    3: 107800,
    4: 93426,
    5: 77066,
    "revenu brut": 599000,
    trimf: 500,
    1.5: 129360,
    2.5: 114986,
    3.5: 100613,
    4.5: 85400,
  },
  {
    1: 144083,
    2: 122470,
    3: 108062,
    4: 93654,
    5: 77416,
    "revenu brut": 600000,
    trimf: 500,
    1.5: 129675,
    2.5: 115266,
    3.5: 100858,
    4.5: 85750,
  },
  {
    1: 144433,
    2: 122768,
    3: 108325,
    4: 93881,
    5: 77766,
    "revenu brut": 601_000,
    trimf: 500,
    1.5: 129990,
    2.5: 115546,
    3.5: 101103,
    4.5: 86100,
  },
  {
    1: 144783,
    2: 123065,
    3: 108587,
    4: 94109,
    5: 78116,
    "revenu brut": 602000,
    trimf: 500,
    1.5: 130305,
    2.5: 115826,
    3.5: 101348,
    4.5: 86450,
  },
  {
    1: 145133,
    2: 123363,
    3: 108850,
    4: 94336,
    5: 78466,
    "revenu brut": 603000,
    trimf: 500,
    1.5: 130620,
    2.5: 116106,
    3.5: 101593,
    4.5: 86800,
  },
  {
    1: 145483,
    2: 123660,
    3: 109112,
    4: 94564,
    5: 78816,
    "revenu brut": 604000,
    trimf: 500,
    1.5: 130935,
    2.5: 116386,
    3.5: 101838,
    4.5: 87150,
  },
  {
    1: 145833,
    2: 123958,
    3: 109375,
    4: 94791,
    5: 79166,
    "revenu brut": 605000,
    trimf: 500,
    1.5: 131250,
    2.5: 116666,
    3.5: 102083,
    4.5: 87500,
  },
  {
    1: 146183,
    2: 124255,
    3: 109637,
    4: 95019,
    5: 79516,
    "revenu brut": 606000,
    trimf: 500,
    1.5: 131565,
    2.5: 116946,
    3.5: 102328,
    4.5: 87710,
  },
  {
    1: 146533,
    2: 124553,
    3: 109900,
    4: 95246,
    5: 79866,
    "revenu brut": 607000,
    trimf: 500,
    1.5: 131880,
    2.5: 117226,
    3.5: 102573,
    4.5: 87920,
  },
  {
    1: 146883,
    2: 124850,
    3: 110162,
    4: 95474,
    5: 80216,
    "revenu brut": 608000,
    trimf: 500,
    1.5: 132195,
    2.5: 117506,
    3.5: 102818,
    4.5: 88130,
  },
  {
    1: 147233,
    2: 125148,
    3: 110425,
    4: 95701,
    5: 80566,
    "revenu brut": 609000,
    trimf: 500,
    1.5: 132510,
    2.5: 117786,
    3.5: 103063,
    4.5: 88340,
  },
  {
    1: 147583,
    2: 125445,
    3: 110687,
    4: 95929,
    5: 80916,
    "revenu brut": 61_0000,
    trimf: 500,
    1.5: 132825,
    2.5: 118066,
    3.5: 103308,
    4.5: 88550,
  },
  {
    1: 147933,
    2: 125743,
    3: 110950,
    4: 96156,
    5: 81266,
    "revenu brut": 611_000,
    trimf: 500,
    1.5: 133140,
    2.5: 118346,
    3.5: 103553,
    4.5: 88760,
  },
  {
    1: 148283,
    2: 126040,
    3: 111212,
    4: 96384,
    5: 81555,
    "revenu brut": 612000,
    trimf: 500,
    1.5: 133455,
    2.5: 118626,
    3.5: 103798,
    4.5: 88970,
  },
  {
    1: 148633,
    2: 126338,
    3: 111475,
    4: 96611,
    5: 81748,
    "revenu brut": 613000,
    trimf: 500,
    1.5: 133770,
    2.5: 118906,
    3.5: 104043,
    4.5: 89180,
  },
  {
    1: 148983,
    2: 126635,
    3: 111737,
    4: 96839,
    5: 81940,
    "revenu brut": 614000,
    trimf: 500,
    1.5: 134085,
    2.5: 119186,
    3.5: 104288,
    4.5: 89390,
  },
  {
    1: 149333,
    2: 126933,
    3: 112000,
    4: 97066,
    5: 82133,
    "revenu brut": 615000,
    trimf: 500,
    1.5: 134400,
    2.5: 119466,
    3.5: 104533,
    4.5: 89600,
  },
  {
    1: 149683,
    2: 127230,
    3: 112262,
    4: 97294,
    5: 82325,
    "revenu brut": 616000,
    trimf: 500,
    1.5: 134715,
    2.5: 119746,
    3.5: 104778,
    4.5: 89810,
  },
  {
    1: 150033,
    2: 127528,
    3: 112525,
    4: 97521,
    5: 82518,
    "revenu brut": 617000,
    trimf: 500,
    1.5: 135030,
    2.5: 120026,
    3.5: 105023,
    4.5: 90020,
  },
  {
    1: 150383,
    2: 127825,
    3: 112787,
    4: 97749,
    5: 82710,
    "revenu brut": 618000,
    trimf: 500,
    1.5: 135345,
    2.5: 120306,
    3.5: 105268,
    4.5: 90230,
  },
  {
    1: 150733,
    2: 128123,
    3: 113050,
    4: 97976,
    5: 82903,
    "revenu brut": 619000,
    trimf: 500,
    1.5: 135660,
    2.5: 120586,
    3.5: 105513,
    4.5: 90440,
  },
  {
    1: 151083,
    2: 128420,
    3: 113312,
    4: 98204,
    5: 83095,
    "revenu brut": 620000,
    trimf: 500,
    1.5: 135975,
    2.5: 120866,
    3.5: 105758,
    4.5: 90650,
  },
  {
    1: 151433,
    2: 128718,
    3: 113575,
    4: 98431,
    5: 83288,
    "revenu brut": 621_000,
    trimf: 500,
    1.5: 136290,
    2.5: 121146,
    3.5: 106003,
    4.5: 90860,
  },
  {
    1: 151783,
    2: 129015,
    3: 113837,
    4: 98659,
    5: 83480,
    "revenu brut": 622000,
    trimf: 500,
    1.5: 136605,
    2.5: 121426,
    3.5: 106248,
    4.5: 91070,
  },
  {
    1: 152133,
    2: 129313,
    3: 114100,
    4: 98886,
    5: 83673,
    "revenu brut": 623000,
    trimf: 500,
    1.5: 136920,
    2.5: 121706,
    3.5: 106493,
    4.5: 91280,
  },
  {
    1: 152483,
    2: 129610,
    3: 114362,
    4: 99114,
    5: 83865,
    "revenu brut": 624000,
    trimf: 500,
    1.5: 137235,
    2.5: 121986,
    3.5: 106738,
    4.5: 91490,
  },
  {
    1: 152833,
    2: 129908,
    3: 114625,
    4: 99341,
    5: 84058,
    "revenu brut": 625000,
    trimf: 500,
    1.5: 137550,
    2.5: 122266,
    3.5: 106983,
    4.5: 91700,
  },
  {
    1: 153183,
    2: 130205,
    3: 114887,
    4: 99569,
    5: 84250,
    "revenu brut": 626000,
    trimf: 500,
    1.5: 137865,
    2.5: 122546,
    3.5: 107228,
    4.5: 91910,
  },
  {
    1: 153533,
    2: 130503,
    3: 115150,
    4: 99796,
    5: 84443,
    "revenu brut": 627000,
    trimf: 500,
    1.5: 138180,
    2.5: 122826,
    3.5: 107473,
    4.5: 92120,
  },
  {
    1: 153883,
    2: 130800,
    3: 115412,
    4: 1_00024,
    5: 84635,
    "revenu brut": 628000,
    trimf: 500,
    1.5: 138495,
    2.5: 123106,
    3.5: 107718,
    4.5: 92330,
  },
  {
    1: 154233,
    2: 131098,
    3: 115675,
    4: 100251,
    5: 84828,
    "revenu brut": 629000,
    trimf: 500,
    1.5: 138810,
    2.5: 123386,
    3.5: 107963,
    4.5: 92540,
  },
  {
    1: 154583,
    2: 131395,
    3: 115937,
    4: 100479,
    5: 85020,
    "revenu brut": 630000,
    trimf: 500,
    1.5: 139125,
    2.5: 123666,
    3.5: 108208,
    4.5: 92750,
  },
  {
    1: 154933,
    2: 131693,
    3: 116200,
    4: 100706,
    5: 85213,
    "revenu brut": 631_000,
    trimf: 500,
    1.5: 139440,
    2.5: 123946,
    3.5: 108453,
    4.5: 92960,
  },
  {
    1: 155283,
    2: 131990,
    3: 116462,
    4: 100934,
    5: 85405,
    "revenu brut": 632000,
    trimf: 500,
    1.5: 139755,
    2.5: 124226,
    3.5: 108698,
    4.5: 93170,
  },
  {
    1: 155633,
    2: 132288,
    3: 116725,
    4: 101161,
    5: 85598,
    "revenu brut": 633000,
    trimf: 500,
    1.5: 140070,
    2.5: 124506,
    3.5: 108943,
    4.5: 93380,
  },
  {
    1: 155983,
    2: 132585,
    3: 116987,
    4: 101389,
    5: 85790,
    "revenu brut": 634000,
    trimf: 500,
    1.5: 140385,
    2.5: 124786,
    3.5: 109188,
    4.5: 93590,
  },
  {
    1: 156333,
    2: 132883,
    3: 117250,
    4: 101616,
    5: 85983,
    "revenu brut": 635000,
    trimf: 500,
    1.5: 140700,
    2.5: 125066,
    3.5: 109433,
    4.5: 93800,
  },
  {
    1: 156683,
    2: 133180,
    3: 117512,
    4: 101844,
    5: 86175,
    "revenu brut": 636000,
    trimf: 500,
    1.5: 141015,
    2.5: 125346,
    3.5: 109678,
    4.5: 94010,
  },
  {
    1: 157033,
    2: 133478,
    3: 117775,
    4: 102071,
    5: 86368,
    "revenu brut": 637000,
    trimf: 500,
    1.5: 141330,
    2.5: 125626,
    3.5: 109923,
    4.5: 94220,
  },
  {
    1: 157383,
    2: 133775,
    3: 118037,
    4: 102299,
    5: 86560,
    "revenu brut": 638000,
    trimf: 500,
    1.5: 141645,
    2.5: 125906,
    3.5: 110168,
    4.5: 94430,
  },
  {
    1: 157733,
    2: 134073,
    3: 118300,
    4: 102526,
    5: 86753,
    "revenu brut": 639000,
    trimf: 500,
    1.5: 141960,
    2.5: 126186,
    3.5: 110413,
    4.5: 94640,
  },
  {
    1: 158083,
    2: 134370,
    3: 118562,
    4: 102754,
    5: 86945,
    "revenu brut": 640000,
    trimf: 500,
    1.5: 142275,
    2.5: 126466,
    3.5: 110658,
    4.5: 94850,
  },
  {
    1: 158433,
    2: 134668,
    3: 118825,
    4: 102981,
    5: 87138,
    "revenu brut": 641_000,
    trimf: 500,
    1.5: 142590,
    2.5: 126746,
    3.5: 110903,
    4.5: 95060,
  },
  {
    1: 158783,
    2: 134965,
    3: 119087,
    4: 103209,
    5: 87330,
    "revenu brut": 642000,
    trimf: 500,
    1.5: 142905,
    2.5: 127026,
    3.5: 111148,
    4.5: 95270,
  },
  {
    1: 159133,
    2: 135263,
    3: 119350,
    4: 103436,
    5: 87523,
    "revenu brut": 643000,
    trimf: 500,
    1.5: 143220,
    2.5: 127306,
    3.5: 111393,
    4.5: 95480,
  },
  {
    1: 159483,
    2: 135560,
    3: 119612,
    4: 103664,
    5: 87715,
    "revenu brut": 644000,
    trimf: 500,
    1.5: 143535,
    2.5: 127586,
    3.5: 111638,
    4.5: 95690,
  },
  {
    1: 159833,
    2: 135858,
    3: 119875,
    4: 103891,
    5: 87908,
    "revenu brut": 645000,
    trimf: 500,
    1.5: 143850,
    2.5: 127866,
    3.5: 111883,
    4.5: 95900,
  },
  {
    1: 160183,
    2: 136155,
    3: 120137,
    4: 104119,
    5: 88100,
    "revenu brut": 646000,
    trimf: 500,
    1.5: 144165,
    2.5: 128146,
    3.5: 112128,
    4.5: 96110,
  },
  {
    1: 160533,
    2: 136453,
    3: 120400,
    4: 104346,
    5: 88293,
    "revenu brut": 647000,
    trimf: 500,
    1.5: 144480,
    2.5: 128426,
    3.5: 112373,
    4.5: 96320,
  },
  {
    1: 160883,
    2: 136750,
    3: 120662,
    4: 104574,
    5: 88485,
    "revenu brut": 648000,
    trimf: 500,
    1.5: 144795,
    2.5: 128706,
    3.5: 112618,
    4.5: 96530,
  },
  {
    1: 161233,
    2: 137048,
    3: 120925,
    4: 104801,
    5: 88678,
    "revenu brut": 649000,
    trimf: 500,
    1.5: 145110,
    2.5: 128986,
    3.5: 112863,
    4.5: 96740,
  },
  {
    1: 161583,
    2: 137345,
    3: 121187,
    4: 105029,
    5: 88870,
    "revenu brut": 650000,
    trimf: 500,
    1.5: 145425,
    2.5: 129266,
    3.5: 113108,
    4.5: 96950,
  },
  {
    1: 161933,
    2: 137643,
    3: 121450,
    4: 105256,
    5: 89063,
    "revenu brut": 651_000,
    trimf: 500,
    1.5: 145740,
    2.5: 129546,
    3.5: 113353,
    4.5: 97160,
  },
  {
    1: 162283,
    2: 137940,
    3: 121712,
    4: 105484,
    5: 89255,
    "revenu brut": 652000,
    trimf: 500,
    1.5: 146055,
    2.5: 129826,
    3.5: 113598,
    4.5: 97370,
  },
  {
    1: 162633,
    2: 138238,
    3: 121975,
    4: 105711,
    5: 89448,
    "revenu brut": 653000,
    trimf: 500,
    1.5: 146370,
    2.5: 130106,
    3.5: 113843,
    4.5: 97580,
  },
  {
    1: 162983,
    2: 138535,
    3: 122237,
    4: 105939,
    5: 89640,
    "revenu brut": 654000,
    trimf: 500,
    1.5: 146685,
    2.5: 130386,
    3.5: 114088,
    4.5: 97790,
  },
  {
    1: 163333,
    2: 138833,
    3: 122500,
    4: 106166,
    5: 89833,
    "revenu brut": 655000,
    trimf: 500,
    1.5: 147000,
    2.5: 130666,
    3.5: 114333,
    4.5: 98000,
  },
  {
    1: 163683,
    2: 139130,
    3: 122762,
    4: 106394,
    5: 90025,
    "revenu brut": 656000,
    trimf: 500,
    1.5: 147315,
    2.5: 130946,
    3.5: 114578,
    4.5: 98210,
  },
  {
    1: 164033,
    2: 139428,
    3: 123025,
    4: 106621,
    5: 90218,
    "revenu brut": 657000,
    trimf: 500,
    1.5: 147630,
    2.5: 131226,
    3.5: 114823,
    4.5: 98420,
  },
  {
    1: 164383,
    2: 139725,
    3: 123287,
    4: 106849,
    5: 90410,
    "revenu brut": 658000,
    trimf: 500,
    1.5: 147945,
    2.5: 131506,
    3.5: 115068,
    4.5: 98630,
  },
  {
    1: 164733,
    2: 140023,
    3: 123550,
    4: 107076,
    5: 90603,
    "revenu brut": 659000,
    trimf: 500,
    1.5: 148260,
    2.5: 131786,
    3.5: 115313,
    4.5: 98840,
  },
  {
    1: 165083,
    2: 140320,
    3: 123812,
    4: 107304,
    5: 90795,
    "revenu brut": 660000,
    trimf: 500,
    1.5: 148575,
    2.5: 132066,
    3.5: 115558,
    4.5: 99050,
  },
  {
    1: 165433,
    2: 140618,
    3: 124075,
    4: 107531,
    5: 90988,
    "revenu brut": 661_000,
    trimf: 500,
    1.5: 148890,
    2.5: 132346,
    3.5: 115803,
    4.5: 99260,
  },
  {
    1: 165783,
    2: 140915,
    3: 124337,
    4: 107759,
    5: 91180,
    "revenu brut": 662000,
    trimf: 500,
    1.5: 149205,
    2.5: 132626,
    3.5: 116048,
    4.5: 99470,
  },
  {
    1: 166133,
    2: 141213,
    3: 124600,
    4: 107986,
    5: 91373,
    "revenu brut": 663000,
    trimf: 500,
    1.5: 149520,
    2.5: 132906,
    3.5: 116293,
    4.5: 99680,
  },
  {
    1: 166483,
    2: 141510,
    3: 124862,
    4: 108214,
    5: 91565,
    "revenu brut": 664000,
    trimf: 500,
    1.5: 149835,
    2.5: 133186,
    3.5: 116538,
    4.5: 99890,
  },
  {
    1: 166833,
    2: 141808,
    3: 125125,
    4: 108441,
    5: 91758,
    "revenu brut": 665000,
    trimf: 500,
    1.5: 150150,
    2.5: 133466,
    3.5: 116783,
    4.5: 100100,
  },
  {
    1: 167183,
    2: 142105,
    3: 125387,
    4: 108669,
    5: 91950,
    "revenu brut": 666000,
    trimf: 500,
    1.5: 150465,
    2.5: 133746,
    3.5: 117028,
    4.5: 100310,
  },
  {
    1: 167533,
    2: 142403,
    3: 125650,
    4: 108896,
    5: 92143,
    "revenu brut": 667000,
    trimf: 500,
    1.5: 150780,
    2.5: 134026,
    3.5: 117273,
    4.5: 100520,
  },
  {
    1: 167883,
    2: 142700,
    3: 125912,
    4: 109124,
    5: 92335,
    "revenu brut": 668000,
    trimf: 500,
    1.5: 151095,
    2.5: 134306,
    3.5: 117518,
    4.5: 100730,
  },
  {
    1: 168233,
    2: 142998,
    3: 126175,
    4: 109351,
    5: 92528,
    "revenu brut": 669000,
    trimf: 500,
    1.5: 151410,
    2.5: 134586,
    3.5: 117763,
    4.5: 100940,
  },
  {
    1: 168583,
    2: 143295,
    3: 126437,
    4: 109579,
    5: 92720,
    "revenu brut": 670000,
    trimf: 500,
    1.5: 151725,
    2.5: 134866,
    3.5: 118008,
    4.5: 101150,
  },
  {
    1: 168933,
    2: 143593,
    3: 126700,
    4: 109806,
    5: 92913,
    "revenu brut": 671_000,
    trimf: 500,
    1.5: 152040,
    2.5: 135146,
    3.5: 118253,
    4.5: 101360,
  },
  {
    1: 169283,
    2: 143890,
    3: 126962,
    4: 110034,
    5: 93105,
    "revenu brut": 672000,
    trimf: 500,
    1.5: 152355,
    2.5: 135426,
    3.5: 118498,
    4.5: 101570,
  },
  {
    1: 169633,
    2: 144188,
    3: 127225,
    4: 110261,
    5: 93298,
    "revenu brut": 673000,
    trimf: 500,
    1.5: 152670,
    2.5: 135706,
    3.5: 118743,
    4.5: 101780,
  },
  {
    1: 169983,
    2: 144485,
    3: 127487,
    4: 110489,
    5: 93490,
    "revenu brut": 674000,
    trimf: 500,
    1.5: 152985,
    2.5: 135986,
    3.5: 118988,
    4.5: 101990,
  },
  {
    1: 170333,
    2: 144783,
    3: 127750,
    4: 110716,
    5: 93683,
    "revenu brut": 675000,
    trimf: 500,
    1.5: 153300,
    2.5: 136266,
    3.5: 119233,
    4.5: 102200,
  },
  {
    1: 170683,
    2: 145080,
    3: 128012,
    4: 110944,
    5: 93875,
    "revenu brut": 676000,
    trimf: 500,
    1.5: 153615,
    2.5: 136546,
    3.5: 119478,
    4.5: 102410,
  },
  {
    1: 171033,
    2: 145378,
    3: 128275,
    4: 111171,
    5: 94068,
    "revenu brut": 677000,
    trimf: 500,
    1.5: 153930,
    2.5: 136826,
    3.5: 119723,
    4.5: 102620,
  },
  {
    1: 171383,
    2: 145675,
    3: 128537,
    4: 111399,
    5: 94260,
    "revenu brut": 678000,
    trimf: 500,
    1.5: 154245,
    2.5: 137106,
    3.5: 119968,
    4.5: 102830,
  },
  {
    1: 171733,
    2: 145973,
    3: 128800,
    4: 111626,
    5: 94453,
    "revenu brut": 679000,
    trimf: 500,
    1.5: 154560,
    2.5: 137386,
    3.5: 120213,
    4.5: 103040,
  },
  {
    1: 172083,
    2: 146270,
    3: 129062,
    4: 111854,
    5: 94645,
    "revenu brut": 680000,
    trimf: 500,
    1.5: 154875,
    2.5: 137666,
    3.5: 120458,
    4.5: 103250,
  },
  {
    1: 172433,
    2: 146568,
    3: 129325,
    4: 112081,
    5: 94838,
    "revenu brut": 681_000,
    trimf: 500,
    1.5: 155190,
    2.5: 137946,
    3.5: 120703,
    4.5: 103460,
  },
  {
    1: 172783,
    2: 146865,
    3: 129587,
    4: 112309,
    5: 95030,
    "revenu brut": 682000,
    trimf: 500,
    1.5: 155505,
    2.5: 138226,
    3.5: 120948,
    4.5: 103670,
  },
  {
    1: 173133,
    2: 147163,
    3: 129850,
    4: 112536,
    5: 95223,
    "revenu brut": 683000,
    trimf: 500,
    1.5: 155820,
    2.5: 138506,
    3.5: 121193,
    4.5: 103880,
  },
  {
    1: 173483,
    2: 147460,
    3: 130112,
    4: 112764,
    5: 95415,
    "revenu brut": 684000,
    trimf: 500,
    1.5: 156135,
    2.5: 138786,
    3.5: 121438,
    4.5: 104090,
  },
  {
    1: 173833,
    2: 147758,
    3: 130375,
    4: 112991,
    5: 95608,
    "revenu brut": 685000,
    trimf: 500,
    1.5: 156450,
    2.5: 139066,
    3.5: 121683,
    4.5: 104300,
  },
  {
    1: 174183,
    2: 148055,
    3: 130637,
    4: 113219,
    5: 95800,
    "revenu brut": 686000,
    trimf: 500,
    1.5: 156765,
    2.5: 139346,
    3.5: 121928,
    4.5: 104510,
  },
  {
    1: 174533,
    2: 148353,
    3: 130900,
    4: 113446,
    5: 95993,
    "revenu brut": 687000,
    trimf: 500,
    1.5: 157080,
    2.5: 139626,
    3.5: 122173,
    4.5: 104720,
  },
  {
    1: 174883,
    2: 148650,
    3: 131162,
    4: 113674,
    5: 96185,
    "revenu brut": 688000,
    trimf: 500,
    1.5: 157395,
    2.5: 139906,
    3.5: 122418,
    4.5: 104930,
  },
  {
    1: 175233,
    2: 148948,
    3: 131425,
    4: 113901,
    5: 96378,
    "revenu brut": 689000,
    trimf: 500,
    1.5: 157710,
    2.5: 140186,
    3.5: 122663,
    4.5: 105140,
  },
  {
    1: 175583,
    2: 149245,
    3: 131687,
    4: 114129,
    5: 96570,
    "revenu brut": 690000,
    trimf: 500,
    1.5: 158025,
    2.5: 140466,
    3.5: 122908,
    4.5: 105350,
  },
  {
    1: 175933,
    2: 149543,
    3: 131950,
    4: 114356,
    5: 96763,
    "revenu brut": 691_000,
    trimf: 500,
    1.5: 158340,
    2.5: 140746,
    3.5: 123153,
    4.5: 105560,
  },
  {
    1: 176283,
    2: 149840,
    3: 132212,
    4: 114584,
    5: 96955,
    "revenu brut": 692000,
    trimf: 500,
    1.5: 158655,
    2.5: 141026,
    3.5: 123398,
    4.5: 105770,
  },
  {
    1: 176633,
    2: 150138,
    3: 132475,
    4: 114811,
    5: 97148,
    "revenu brut": 693000,
    trimf: 500,
    1.5: 158970,
    2.5: 141306,
    3.5: 123643,
    4.5: 105980,
  },
  {
    1: 176983,
    2: 150435,
    3: 132737,
    4: 115039,
    5: 97340,
    "revenu brut": 694000,
    trimf: 500,
    1.5: 159285,
    2.5: 141586,
    3.5: 123888,
    4.5: 106190,
  },
  {
    1: 177333,
    2: 150733,
    3: 133000,
    4: 115266,
    5: 97533,
    "revenu brut": 695000,
    trimf: 500,
    1.5: 159600,
    2.5: 141866,
    3.5: 124133,
    4.5: 106400,
  },
  {
    1: 177683,
    2: 151030,
    3: 133262,
    4: 115494,
    5: 97725,
    "revenu brut": 696000,
    trimf: 500,
    1.5: 159915,
    2.5: 142146,
    3.5: 124378,
    4.5: 106610,
  },
  {
    1: 178033,
    2: 151328,
    3: 133525,
    4: 115721,
    5: 97918,
    "revenu brut": 697000,
    trimf: 500,
    1.5: 160230,
    2.5: 142426,
    3.5: 124623,
    4.5: 106820,
  },
  {
    1: 178383,
    2: 151625,
    3: 133787,
    4: 115949,
    5: 98110,
    "revenu brut": 698000,
    trimf: 500,
    1.5: 160545,
    2.5: 142706,
    3.5: 124868,
    4.5: 107030,
  },
  {
    1: 178733,
    2: 151923,
    3: 134050,
    4: 116176,
    5: 98303,
    "revenu brut": 699000,
    trimf: 500,
    1.5: 160860,
    2.5: 142986,
    3.5: 125113,
    4.5: 107240,
  },
  {
    1: 179083,
    2: 152220,
    3: 134312,
    4: 116404,
    5: 98495,
    "revenu brut": 700000,
    trimf: 500,
    1.5: 161175,
    2.5: 143266,
    3.5: 125358,
    4.5: 107450,
  },
  {
    1: 179433,
    2: 152518,
    3: 134575,
    4: 116631,
    5: 98688,
    "revenu brut": 701_000,
    trimf: 500,
    1.5: 161490,
    2.5: 143546,
    3.5: 125603,
    4.5: 107660,
  },
  {
    1: 179783,
    2: 152815,
    3: 134837,
    4: 116859,
    5: 98880,
    "revenu brut": 702000,
    trimf: 500,
    1.5: 161805,
    2.5: 143826,
    3.5: 125848,
    4.5: 107870,
  },
  {
    1: 180133,
    2: 153113,
    3: 135100,
    4: 117086,
    5: 99073,
    "revenu brut": 703000,
    trimf: 500,
    1.5: 162120,
    2.5: 144106,
    3.5: 126093,
    4.5: 108080,
  },
  {
    1: 180483,
    2: 153410,
    3: 135362,
    4: 117314,
    5: 99265,
    "revenu brut": 704000,
    trimf: 500,
    1.5: 162435,
    2.5: 144386,
    3.5: 126338,
    4.5: 108290,
  },
  {
    1: 180833,
    2: 153708,
    3: 135625,
    4: 117541,
    5: 99458,
    "revenu brut": 705000,
    trimf: 500,
    1.5: 162750,
    2.5: 144666,
    3.5: 126583,
    4.5: 108500,
  },
  {
    1: 181183,
    2: 154005,
    3: 135887,
    4: 117769,
    5: 99650,
    "revenu brut": 706000,
    trimf: 500,
    1.5: 163065,
    2.5: 144946,
    3.5: 126828,
    4.5: 108710,
  },
  {
    1: 181533,
    2: 154303,
    3: 136150,
    4: 117996,
    5: 99843,
    "revenu brut": 707000,
    trimf: 500,
    1.5: 163380,
    2.5: 145226,
    3.5: 127073,
    4.5: 108920,
  },
  {
    1: 181883,
    2: 154600,
    3: 136412,
    4: 118224,
    5: 1_00035,
    "revenu brut": 708000,
    trimf: 500,
    1.5: 163695,
    2.5: 145506,
    3.5: 127318,
    4.5: 109130,
  },
  {
    1: 182233,
    2: 154898,
    3: 136675,
    4: 118451,
    5: 100228,
    "revenu brut": 709000,
    trimf: 500,
    1.5: 164010,
    2.5: 145786,
    3.5: 127563,
    4.5: 109340,
  },
  {
    1: 182583,
    2: 155195,
    3: 136937,
    4: 118679,
    5: 100420,
    "revenu brut": 71_0000,
    trimf: 500,
    1.5: 164325,
    2.5: 146066,
    3.5: 127808,
    4.5: 109550,
  },
  {
    1: 182933,
    2: 155493,
    3: 137200,
    4: 118906,
    5: 100613,
    "revenu brut": 711_000,
    trimf: 500,
    1.5: 164640,
    2.5: 146346,
    3.5: 128053,
    4.5: 109760,
  },
  {
    1: 183283,
    2: 155790,
    3: 137462,
    4: 119134,
    5: 100805,
    "revenu brut": 712000,
    trimf: 500,
    1.5: 164955,
    2.5: 146626,
    3.5: 128298,
    4.5: 109970,
  },
  {
    1: 183633,
    2: 156088,
    3: 137725,
    4: 119361,
    5: 100998,
    "revenu brut": 713000,
    trimf: 500,
    1.5: 165270,
    2.5: 146906,
    3.5: 128543,
    4.5: 110180,
  },
  {
    1: 183983,
    2: 156385,
    3: 137987,
    4: 119589,
    5: 101190,
    "revenu brut": 714000,
    trimf: 500,
    1.5: 165585,
    2.5: 147186,
    3.5: 128788,
    4.5: 110390,
  },
  {
    1: 184333,
    2: 156683,
    3: 138250,
    4: 119816,
    5: 101383,
    "revenu brut": 715000,
    trimf: 500,
    1.5: 165900,
    2.5: 147466,
    3.5: 129033,
    4.5: 110600,
  },
  {
    1: 184683,
    2: 156980,
    3: 138512,
    4: 120044,
    5: 101575,
    "revenu brut": 716000,
    trimf: 500,
    1.5: 166215,
    2.5: 147746,
    3.5: 129278,
    4.5: 110810,
  },
  {
    1: 185033,
    2: 157278,
    3: 138775,
    4: 120271,
    5: 101768,
    "revenu brut": 717000,
    trimf: 500,
    1.5: 166530,
    2.5: 148026,
    3.5: 129523,
    4.5: 111020,
  },
  {
    1: 185383,
    2: 157575,
    3: 139037,
    4: 120499,
    5: 101960,
    "revenu brut": 718000,
    trimf: 500,
    1.5: 166845,
    2.5: 148306,
    3.5: 129768,
    4.5: 111230,
  },
  {
    1: 185733,
    2: 157873,
    3: 139300,
    4: 120726,
    5: 102153,
    "revenu brut": 719000,
    trimf: 500,
    1.5: 167160,
    2.5: 148586,
    3.5: 130013,
    4.5: 111440,
  },
  {
    1: 186083,
    2: 158170,
    3: 139562,
    4: 120954,
    5: 102345,
    "revenu brut": 720000,
    trimf: 500,
    1.5: 167475,
    2.5: 148866,
    3.5: 130258,
    4.5: 111650,
  },
  {
    1: 186433,
    2: 158468,
    3: 139825,
    4: 121181,
    5: 102538,
    "revenu brut": 721_000,
    trimf: 500,
    1.5: 167790,
    2.5: 149146,
    3.5: 130503,
    4.5: 111860,
  },
  {
    1: 186783,
    2: 158765,
    3: 140087,
    4: 121409,
    5: 102730,
    "revenu brut": 722000,
    trimf: 500,
    1.5: 168105,
    2.5: 149426,
    3.5: 130748,
    4.5: 112070,
  },
  {
    1: 187133,
    2: 159063,
    3: 140350,
    4: 121636,
    5: 102923,
    "revenu brut": 723000,
    trimf: 500,
    1.5: 168420,
    2.5: 149706,
    3.5: 130993,
    4.5: 112280,
  },
  {
    1: 187483,
    2: 159360,
    3: 140612,
    4: 121864,
    5: 103115,
    "revenu brut": 724000,
    trimf: 500,
    1.5: 168735,
    2.5: 149986,
    3.5: 131238,
    4.5: 112490,
  },
  {
    1: 187833,
    2: 159658,
    3: 140875,
    4: 122091,
    5: 103308,
    "revenu brut": 725000,
    trimf: 500,
    1.5: 169050,
    2.5: 150266,
    3.5: 131483,
    4.5: 112700,
  },
  {
    1: 188183,
    2: 159955,
    3: 141137,
    4: 122319,
    5: 103500,
    "revenu brut": 726000,
    trimf: 500,
    1.5: 169365,
    2.5: 150546,
    3.5: 131728,
    4.5: 112910,
  },
  {
    1: 188533,
    2: 160253,
    3: 141400,
    4: 122546,
    5: 103693,
    "revenu brut": 727000,
    trimf: 500,
    1.5: 169680,
    2.5: 150826,
    3.5: 131973,
    4.5: 113120,
  },
  {
    1: 188883,
    2: 160550,
    3: 141662,
    4: 122774,
    5: 103885,
    "revenu brut": 728000,
    trimf: 500,
    1.5: 169995,
    2.5: 151106,
    3.5: 132218,
    4.5: 113330,
  },
  {
    1: 189233,
    2: 160848,
    3: 141925,
    4: 123001,
    5: 104078,
    "revenu brut": 729000,
    trimf: 500,
    1.5: 170310,
    2.5: 151386,
    3.5: 132463,
    4.5: 113540,
  },
  {
    1: 189583,
    2: 161145,
    3: 142187,
    4: 123229,
    5: 104270,
    "revenu brut": 730000,
    trimf: 500,
    1.5: 170625,
    2.5: 151666,
    3.5: 132708,
    4.5: 113750,
  },
  {
    1: 189933,
    2: 161443,
    3: 142450,
    4: 123456,
    5: 104463,
    "revenu brut": 731_000,
    trimf: 500,
    1.5: 170940,
    2.5: 151946,
    3.5: 132953,
    4.5: 113960,
  },
  {
    1: 190283,
    2: 161740,
    3: 142712,
    4: 123684,
    5: 104655,
    "revenu brut": 732000,
    trimf: 500,
    1.5: 171255,
    2.5: 152226,
    3.5: 133198,
    4.5: 114170,
  },
  {
    1: 190633,
    2: 162038,
    3: 142975,
    4: 123911,
    5: 104848,
    "revenu brut": 733000,
    trimf: 500,
    1.5: 171570,
    2.5: 152506,
    3.5: 133443,
    4.5: 114380,
  },
  {
    1: 190983,
    2: 162335,
    3: 143237,
    4: 124139,
    5: 105040,
    "revenu brut": 734000,
    trimf: 500,
    1.5: 171885,
    2.5: 152786,
    3.5: 133688,
    4.5: 114590,
  },
  {
    1: 191333,
    2: 162633,
    3: 143500,
    4: 124366,
    5: 105233,
    "revenu brut": 735000,
    trimf: 500,
    1.5: 172200,
    2.5: 153066,
    3.5: 133933,
    4.5: 114800,
  },
  {
    1: 191683,
    2: 162930,
    3: 143762,
    4: 124594,
    5: 105425,
    "revenu brut": 736000,
    trimf: 500,
    1.5: 172515,
    2.5: 153346,
    3.5: 134178,
    4.5: 115010,
  },
  {
    1: 192033,
    2: 163228,
    3: 144025,
    4: 124821,
    5: 105618,
    "revenu brut": 737000,
    trimf: 500,
    1.5: 172830,
    2.5: 153626,
    3.5: 134423,
    4.5: 115220,
  },
  {
    1: 192383,
    2: 163525,
    3: 144287,
    4: 125049,
    5: 105810,
    "revenu brut": 738000,
    trimf: 500,
    1.5: 173145,
    2.5: 153906,
    3.5: 134668,
    4.5: 115430,
  },
  {
    1: 192733,
    2: 163823,
    3: 144550,
    4: 125276,
    5: 106003,
    "revenu brut": 739000,
    trimf: 500,
    1.5: 173460,
    2.5: 154186,
    3.5: 134913,
    4.5: 115640,
  },
  {
    1: 193083,
    2: 164120,
    3: 144812,
    4: 125504,
    5: 106195,
    "revenu brut": 740000,
    trimf: 500,
    1.5: 173775,
    2.5: 154466,
    3.5: 135158,
    4.5: 115850,
  },
  {
    1: 193433,
    2: 164418,
    3: 145075,
    4: 125731,
    5: 106388,
    "revenu brut": 741_000,
    trimf: 500,
    1.5: 174090,
    2.5: 154746,
    3.5: 135403,
    4.5: 116060,
  },
  {
    1: 193790,
    2: 164721,
    3: 145342,
    4: 125963,
    5: 106584,
    "revenu brut": 742000,
    trimf: 500,
    1.5: 174411,
    2.5: 155032,
    3.5: 135653,
    4.5: 116274,
  },
  {
    1: 194160,
    2: 165036,
    3: 145620,
    4: 126204,
    5: 106788,
    "revenu brut": 743000,
    trimf: 500,
    1.5: 174744,
    2.5: 155328,
    3.5: 135912,
    4.5: 116496,
  },
  {
    1: 194530,
    2: 165350,
    3: 145897,
    4: 126444,
    5: 106991,
    "revenu brut": 744000,
    trimf: 500,
    1.5: 175077,
    2.5: 155624,
    3.5: 136171,
    4.5: 116718,
  },
  {
    1: 194900,
    2: 165665,
    3: 146175,
    4: 126685,
    5: 107195,
    "revenu brut": 745000,
    trimf: 500,
    1.5: 175410,
    2.5: 155920,
    3.5: 136430,
    4.5: 116940,
  },
  {
    1: 195270,
    2: 165979,
    3: 146452,
    4: 126925,
    5: 107398,
    "revenu brut": 746000,
    trimf: 500,
    1.5: 175743,
    2.5: 156216,
    3.5: 136689,
    4.5: 117162,
  },
  {
    1: 195640,
    2: 166294,
    3: 146730,
    4: 127166,
    5: 107602,
    "revenu brut": 747000,
    trimf: 500,
    1.5: 176076,
    2.5: 156512,
    3.5: 136948,
    4.5: 117384,
  },
  {
    1: 196010,
    2: 166608,
    3: 147007,
    4: 127406,
    5: 107805,
    "revenu brut": 748000,
    trimf: 500,
    1.5: 176409,
    2.5: 156808,
    3.5: 137207,
    4.5: 117606,
  },
  {
    1: 196380,
    2: 166923,
    3: 147285,
    4: 127647,
    5: 108009,
    "revenu brut": 749000,
    trimf: 500,
    1.5: 176742,
    2.5: 157104,
    3.5: 137466,
    4.5: 117828,
  },
  {
    1: 196750,
    2: 167237,
    3: 147562,
    4: 127887,
    5: 108212,
    "revenu brut": 750000,
    trimf: 500,
    1.5: 177075,
    2.5: 157400,
    3.5: 137725,
    4.5: 118050,
  },
  {
    1: 197120,
    2: 167552,
    3: 147840,
    4: 128128,
    5: 108416,
    "revenu brut": 751_000,
    trimf: 500,
    1.5: 177408,
    2.5: 157696,
    3.5: 137984,
    4.5: 118272,
  },
  {
    1: 197490,
    2: 167866,
    3: 148117,
    4: 128368,
    5: 108619,
    "revenu brut": 752000,
    trimf: 500,
    1.5: 177741,
    2.5: 157992,
    3.5: 138243,
    4.5: 118494,
  },
  {
    1: 197860,
    2: 168181,
    3: 148395,
    4: 128609,
    5: 108823,
    "revenu brut": 753000,
    trimf: 500,
    1.5: 178074,
    2.5: 158288,
    3.5: 138502,
    4.5: 118716,
  },
  {
    1: 198230,
    2: 168495,
    3: 148672,
    4: 128849,
    5: 109026,
    "revenu brut": 754000,
    trimf: 500,
    1.5: 178407,
    2.5: 158584,
    3.5: 138761,
    4.5: 118938,
  },
  {
    1: 198600,
    2: 168810,
    3: 148950,
    4: 129090,
    5: 109230,
    "revenu brut": 755000,
    trimf: 500,
    1.5: 178740,
    2.5: 158880,
    3.5: 139020,
    4.5: 119160,
  },
  {
    1: 198970,
    2: 169124,
    3: 149227,
    4: 129330,
    5: 109433,
    "revenu brut": 756000,
    trimf: 500,
    1.5: 179073,
    2.5: 159176,
    3.5: 139279,
    4.5: 119382,
  },
  {
    1: 199340,
    2: 169439,
    3: 149505,
    4: 129571,
    5: 109637,
    "revenu brut": 757000,
    trimf: 500,
    1.5: 179406,
    2.5: 159472,
    3.5: 139538,
    4.5: 119604,
  },
  {
    1: 199710,
    2: 169753,
    3: 149782,
    4: 129811,
    5: 109840,
    "revenu brut": 758000,
    trimf: 500,
    1.5: 179739,
    2.5: 159768,
    3.5: 139797,
    4.5: 119826,
  },
  {
    1: 200080,
    2: 170068,
    3: 150060,
    4: 130052,
    5: 110044,
    "revenu brut": 759000,
    trimf: 500,
    1.5: 180072,
    2.5: 160064,
    3.5: 140056,
    4.5: 120048,
  },
  {
    1: 200450,
    2: 170382,
    3: 150337,
    4: 130292,
    5: 110247,
    "revenu brut": 760000,
    trimf: 500,
    1.5: 180405,
    2.5: 160360,
    3.5: 140315,
    4.5: 120270,
  },
  {
    1: 200820,
    2: 170697,
    3: 150615,
    4: 130533,
    5: 110451,
    "revenu brut": 761_000,
    trimf: 500,
    1.5: 180738,
    2.5: 160656,
    3.5: 140574,
    4.5: 120492,
  },
  {
    1: 201190,
    2: 171011,
    3: 150892,
    4: 130773,
    5: 110654,
    "revenu brut": 762000,
    trimf: 500,
    1.5: 181071,
    2.5: 160952,
    3.5: 140833,
    4.5: 120714,
  },
  {
    1: 201560,
    2: 171326,
    3: 151170,
    4: 131014,
    5: 110858,
    "revenu brut": 763000,
    trimf: 500,
    1.5: 181404,
    2.5: 161248,
    3.5: 141092,
    4.5: 120936,
  },
  {
    1: 201930,
    2: 171640,
    3: 151447,
    4: 131254,
    5: 111061,
    "revenu brut": 764000,
    trimf: 500,
    1.5: 181737,
    2.5: 161544,
    3.5: 141351,
    4.5: 121158,
  },
  {
    1: 202300,
    2: 171955,
    3: 151725,
    4: 131495,
    5: 111265,
    "revenu brut": 765000,
    trimf: 500,
    1.5: 182070,
    2.5: 161840,
    3.5: 141610,
    4.5: 121380,
  },
  {
    1: 202670,
    2: 172269,
    3: 152002,
    4: 131735,
    5: 111468,
    "revenu brut": 766000,
    trimf: 500,
    1.5: 182403,
    2.5: 162136,
    3.5: 141869,
    4.5: 121602,
  },
  {
    1: 203040,
    2: 172584,
    3: 152280,
    4: 131976,
    5: 111672,
    "revenu brut": 767000,
    trimf: 500,
    1.5: 182736,
    2.5: 162432,
    3.5: 142128,
    4.5: 121824,
  },
  {
    1: 203410,
    2: 172898,
    3: 152557,
    4: 132216,
    5: 111875,
    "revenu brut": 768000,
    trimf: 500,
    1.5: 183069,
    2.5: 162728,
    3.5: 142387,
    4.5: 122046,
  },
  {
    1: 203780,
    2: 173213,
    3: 152835,
    4: 132457,
    5: 112079,
    "revenu brut": 769000,
    trimf: 500,
    1.5: 183402,
    2.5: 163024,
    3.5: 142646,
    4.5: 122268,
  },
  {
    1: 204150,
    2: 173527,
    3: 153112,
    4: 132697,
    5: 112282,
    "revenu brut": 770000,
    trimf: 500,
    1.5: 183735,
    2.5: 163320,
    3.5: 142905,
    4.5: 122490,
  },
  {
    1: 204520,
    2: 173842,
    3: 153390,
    4: 132938,
    5: 112486,
    "revenu brut": 771_000,
    trimf: 500,
    1.5: 184068,
    2.5: 163616,
    3.5: 143164,
    4.5: 122712,
  },
  {
    1: 204890,
    2: 174156,
    3: 153667,
    4: 133178,
    5: 112689,
    "revenu brut": 772000,
    trimf: 500,
    1.5: 184401,
    2.5: 163912,
    3.5: 143423,
    4.5: 122934,
  },
  {
    1: 205260,
    2: 174471,
    3: 153945,
    4: 133419,
    5: 112893,
    "revenu brut": 773000,
    trimf: 500,
    1.5: 184734,
    2.5: 164208,
    3.5: 143682,
    4.5: 123156,
  },
  {
    1: 205630,
    2: 174785,
    3: 154222,
    4: 133659,
    5: 113096,
    "revenu brut": 774000,
    trimf: 500,
    1.5: 185067,
    2.5: 164504,
    3.5: 143941,
    4.5: 123378,
  },
  {
    1: 206000,
    2: 175100,
    3: 154500,
    4: 133900,
    5: 113300,
    "revenu brut": 775000,
    trimf: 500,
    1.5: 185400,
    2.5: 164800,
    3.5: 144200,
    4.5: 123600,
  },
  {
    1: 206370,
    2: 175414,
    3: 154777,
    4: 134140,
    5: 113503,
    "revenu brut": 776000,
    trimf: 500,
    1.5: 185733,
    2.5: 165096,
    3.5: 144459,
    4.5: 123822,
  },
  {
    1: 206740,
    2: 175729,
    3: 155055,
    4: 134381,
    5: 113707,
    "revenu brut": 777000,
    trimf: 500,
    1.5: 186066,
    2.5: 165392,
    3.5: 144718,
    4.5: 124044,
  },
  {
    1: 207110,
    2: 176043,
    3: 155332,
    4: 134621,
    5: 113910,
    "revenu brut": 778000,
    trimf: 500,
    1.5: 186399,
    2.5: 165688,
    3.5: 144977,
    4.5: 124266,
  },
  {
    1: 207480,
    2: 176358,
    3: 155610,
    4: 134862,
    5: 114114,
    "revenu brut": 779000,
    trimf: 500,
    1.5: 186732,
    2.5: 165984,
    3.5: 145236,
    4.5: 124488,
  },
  {
    1: 207850,
    2: 176672,
    3: 155887,
    4: 135102,
    5: 114317,
    "revenu brut": 780000,
    trimf: 500,
    1.5: 187065,
    2.5: 166280,
    3.5: 145495,
    4.5: 124710,
  },
  {
    1: 208220,
    2: 176987,
    3: 156165,
    4: 135343,
    5: 114521,
    "revenu brut": 781_000,
    trimf: 500,
    1.5: 187398,
    2.5: 166576,
    3.5: 145754,
    4.5: 124932,
  },
  {
    1: 208590,
    2: 177301,
    3: 156442,
    4: 135583,
    5: 114724,
    "revenu brut": 782000,
    trimf: 500,
    1.5: 187731,
    2.5: 166872,
    3.5: 146013,
    4.5: 125154,
  },
  {
    1: 208960,
    2: 177616,
    3: 156720,
    4: 135824,
    5: 114928,
    "revenu brut": 783000,
    trimf: 500,
    1.5: 188064,
    2.5: 167168,
    3.5: 146272,
    4.5: 125376,
  },
  {
    1: 209330,
    2: 177930,
    3: 156997,
    4: 136064,
    5: 115131,
    "revenu brut": 784000,
    trimf: 500,
    1.5: 188397,
    2.5: 167464,
    3.5: 146531,
    4.5: 125598,
  },
  {
    1: 209700,
    2: 178245,
    3: 157275,
    4: 136305,
    5: 115335,
    "revenu brut": 785000,
    trimf: 500,
    1.5: 188730,
    2.5: 167760,
    3.5: 146790,
    4.5: 125820,
  },
  {
    1: 210070,
    2: 178559,
    3: 157552,
    4: 136545,
    5: 115538,
    "revenu brut": 786000,
    trimf: 500,
    1.5: 189063,
    2.5: 168056,
    3.5: 147049,
    4.5: 126042,
  },
  {
    1: 210440,
    2: 178874,
    3: 157830,
    4: 136786,
    5: 115742,
    "revenu brut": 787000,
    trimf: 500,
    1.5: 189396,
    2.5: 168352,
    3.5: 147308,
    4.5: 126264,
  },
  {
    1: 210810,
    2: 179188,
    3: 158107,
    4: 137026,
    5: 115945,
    "revenu brut": 788000,
    trimf: 500,
    1.5: 189729,
    2.5: 168648,
    3.5: 147567,
    4.5: 126486,
  },
  {
    1: 211180,
    2: 179503,
    3: 158385,
    4: 137267,
    5: 116149,
    "revenu brut": 789000,
    trimf: 500,
    1.5: 190062,
    2.5: 168944,
    3.5: 147826,
    4.5: 126708,
  },
  {
    1: 211550,
    2: 179817,
    3: 158662,
    4: 137507,
    5: 116352,
    "revenu brut": 790000,
    trimf: 500,
    1.5: 190395,
    2.5: 169240,
    3.5: 148085,
    4.5: 126930,
  },
  {
    1: 211920,
    2: 180132,
    3: 158940,
    4: 137748,
    5: 116556,
    "revenu brut": 791_000,
    trimf: 500,
    1.5: 190728,
    2.5: 169536,
    3.5: 148344,
    4.5: 127152,
  },
  {
    1: 212290,
    2: 180446,
    3: 159217,
    4: 137988,
    5: 116759,
    "revenu brut": 792000,
    trimf: 500,
    1.5: 191061,
    2.5: 169832,
    3.5: 148603,
    4.5: 127374,
  },
  {
    1: 212660,
    2: 180761,
    3: 159495,
    4: 138229,
    5: 116963,
    "revenu brut": 793000,
    trimf: 500,
    1.5: 191394,
    2.5: 170128,
    3.5: 148862,
    4.5: 127596,
  },
  {
    1: 213030,
    2: 181075,
    3: 159772,
    4: 138469,
    5: 117166,
    "revenu brut": 794000,
    trimf: 500,
    1.5: 191727,
    2.5: 170424,
    3.5: 149121,
    4.5: 127818,
  },
  {
    1: 213400,
    2: 181390,
    3: 160050,
    4: 138710,
    5: 117370,
    "revenu brut": 795000,
    trimf: 500,
    1.5: 192060,
    2.5: 170720,
    3.5: 149380,
    4.5: 128040,
  },
  {
    1: 213770,
    2: 181704,
    3: 160327,
    4: 138950,
    5: 117573,
    "revenu brut": 796000,
    trimf: 500,
    1.5: 192393,
    2.5: 171016,
    3.5: 149639,
    4.5: 128262,
  },
  {
    1: 214140,
    2: 182019,
    3: 160605,
    4: 139191,
    5: 117777,
    "revenu brut": 797000,
    trimf: 500,
    1.5: 192726,
    2.5: 171312,
    3.5: 149898,
    4.5: 128484,
  },
  {
    1: 214510,
    2: 182333,
    3: 160882,
    4: 139431,
    5: 117980,
    "revenu brut": 798000,
    trimf: 500,
    1.5: 193059,
    2.5: 171608,
    3.5: 150157,
    4.5: 128706,
  },
  {
    1: 214880,
    2: 182648,
    3: 161160,
    4: 139672,
    5: 118184,
    "revenu brut": 799000,
    trimf: 500,
    1.5: 193392,
    2.5: 171904,
    3.5: 150416,
    4.5: 128928,
  },
  {
    1: 215250,
    2: 182962,
    3: 161437,
    4: 139912,
    5: 118387,
    "revenu brut": 800000,
    trimf: 500,
    1.5: 193725,
    2.5: 172200,
    3.5: 150675,
    4.5: 129150,
  },
  {
    1: 215620,
    2: 183277,
    3: 161715,
    4: 140153,
    5: 118591,
    "revenu brut": 801_000,
    trimf: 500,
    1.5: 194058,
    2.5: 172496,
    3.5: 150934,
    4.5: 129372,
  },
  {
    1: 215990,
    2: 183591,
    3: 161992,
    4: 140393,
    5: 118794,
    "revenu brut": 802000,
    trimf: 500,
    1.5: 194391,
    2.5: 172792,
    3.5: 151193,
    4.5: 129594,
  },
  {
    1: 216360,
    2: 183906,
    3: 162270,
    4: 140634,
    5: 118998,
    "revenu brut": 803000,
    trimf: 500,
    1.5: 194724,
    2.5: 173088,
    3.5: 151452,
    4.5: 129816,
  },
  {
    1: 216730,
    2: 184220,
    3: 162547,
    4: 140874,
    5: 119201,
    "revenu brut": 804000,
    trimf: 500,
    1.5: 195057,
    2.5: 173384,
    3.5: 151711,
    4.5: 130038,
  },
  {
    1: 217100,
    2: 184535,
    3: 162825,
    4: 141115,
    5: 119405,
    "revenu brut": 805000,
    trimf: 500,
    1.5: 195390,
    2.5: 173680,
    3.5: 151970,
    4.5: 130260,
  },
  {
    1: 217470,
    2: 184849,
    3: 163102,
    4: 141355,
    5: 119608,
    "revenu brut": 806000,
    trimf: 500,
    1.5: 195723,
    2.5: 173976,
    3.5: 152229,
    4.5: 130482,
  },
  {
    1: 217840,
    2: 185164,
    3: 163380,
    4: 141596,
    5: 119812,
    "revenu brut": 807000,
    trimf: 500,
    1.5: 196056,
    2.5: 174272,
    3.5: 152488,
    4.5: 130704,
  },
  {
    1: 218210,
    2: 185478,
    3: 163657,
    4: 141836,
    5: 120015,
    "revenu brut": 808000,
    trimf: 500,
    1.5: 196389,
    2.5: 174568,
    3.5: 152747,
    4.5: 130926,
  },
  {
    1: 218580,
    2: 185793,
    3: 163935,
    4: 142077,
    5: 120219,
    "revenu brut": 809000,
    trimf: 500,
    1.5: 196722,
    2.5: 174864,
    3.5: 153006,
    4.5: 131148,
  },
  {
    1: 218950,
    2: 186107,
    3: 164212,
    4: 142317,
    5: 120422,
    "revenu brut": 81_0000,
    trimf: 500,
    1.5: 197055,
    2.5: 175160,
    3.5: 153265,
    4.5: 131370,
  },
  {
    1: 219320,
    2: 186422,
    3: 164490,
    4: 142558,
    5: 120626,
    "revenu brut": 811_000,
    trimf: 500,
    1.5: 197388,
    2.5: 175456,
    3.5: 153524,
    4.5: 131592,
  },
  {
    1: 219690,
    2: 186736,
    3: 164767,
    4: 142798,
    5: 120829,
    "revenu brut": 812000,
    trimf: 500,
    1.5: 197721,
    2.5: 175752,
    3.5: 153783,
    4.5: 131814,
  },
  {
    1: 220060,
    2: 187051,
    3: 165045,
    4: 143039,
    5: 121033,
    "revenu brut": 813000,
    trimf: 500,
    1.5: 198054,
    2.5: 176048,
    3.5: 154042,
    4.5: 132036,
  },
  {
    1: 220430,
    2: 187365,
    3: 165322,
    4: 143279,
    5: 121236,
    "revenu brut": 814000,
    trimf: 500,
    1.5: 198387,
    2.5: 176344,
    3.5: 154301,
    4.5: 132258,
  },
  {
    1: 220800,
    2: 187680,
    3: 165600,
    4: 143520,
    5: 121440,
    "revenu brut": 815000,
    trimf: 500,
    1.5: 198720,
    2.5: 176640,
    3.5: 154560,
    4.5: 132480,
  },
  {
    1: 221170,
    2: 187994,
    3: 165877,
    4: 143760,
    5: 121643,
    "revenu brut": 816000,
    trimf: 500,
    1.5: 199053,
    2.5: 176936,
    3.5: 154819,
    4.5: 132702,
  },
  {
    1: 221540,
    2: 188309,
    3: 166155,
    4: 144001,
    5: 121847,
    "revenu brut": 817000,
    trimf: 500,
    1.5: 199386,
    2.5: 177232,
    3.5: 155078,
    4.5: 132924,
  },
  {
    1: 221910,
    2: 188623,
    3: 166432,
    4: 144241,
    5: 122050,
    "revenu brut": 818000,
    trimf: 500,
    1.5: 199719,
    2.5: 177528,
    3.5: 155337,
    4.5: 133146,
  },
  {
    1: 222280,
    2: 188938,
    3: 166710,
    4: 144482,
    5: 122254,
    "revenu brut": 819000,
    trimf: 500,
    1.5: 200052,
    2.5: 177824,
    3.5: 155596,
    4.5: 133368,
  },
  {
    1: 222650,
    2: 189252,
    3: 166987,
    4: 144722,
    5: 122457,
    "revenu brut": 820000,
    trimf: 500,
    1.5: 200385,
    2.5: 178120,
    3.5: 155855,
    4.5: 133590,
  },
  {
    1: 223020,
    2: 189567,
    3: 167265,
    4: 144963,
    5: 122661,
    "revenu brut": 821_000,
    trimf: 500,
    1.5: 200718,
    2.5: 178416,
    3.5: 156114,
    4.5: 133812,
  },
  {
    1: 223390,
    2: 189881,
    3: 167542,
    4: 145203,
    5: 122864,
    "revenu brut": 822000,
    trimf: 500,
    1.5: 201051,
    2.5: 178712,
    3.5: 156373,
    4.5: 134034,
  },
  {
    1: 223760,
    2: 190196,
    3: 167820,
    4: 145444,
    5: 123068,
    "revenu brut": 823000,
    trimf: 500,
    1.5: 201384,
    2.5: 179008,
    3.5: 156632,
    4.5: 134256,
  },
  {
    1: 224130,
    2: 190510,
    3: 168097,
    4: 145684,
    5: 123271,
    "revenu brut": 824000,
    trimf: 500,
    1.5: 201717,
    2.5: 179304,
    3.5: 156891,
    4.5: 134478,
  },
  {
    1: 224500,
    2: 190825,
    3: 168375,
    4: 145925,
    5: 123475,
    "revenu brut": 825000,
    trimf: 500,
    1.5: 202050,
    2.5: 179600,
    3.5: 157150,
    4.5: 134700,
  },
  {
    1: 224870,
    2: 191139,
    3: 168652,
    4: 146165,
    5: 123678,
    "revenu brut": 826000,
    trimf: 500,
    1.5: 202383,
    2.5: 179896,
    3.5: 157409,
    4.5: 134922,
  },
  {
    1: 225240,
    2: 191454,
    3: 168930,
    4: 146406,
    5: 123882,
    "revenu brut": 827000,
    trimf: 500,
    1.5: 202716,
    2.5: 180192,
    3.5: 157668,
    4.5: 135144,
  },
  {
    1: 225610,
    2: 191768,
    3: 169207,
    4: 146646,
    5: 124085,
    "revenu brut": 828000,
    trimf: 500,
    1.5: 203049,
    2.5: 180488,
    3.5: 157927,
    4.5: 135366,
  },
  {
    1: 225980,
    2: 192083,
    3: 169485,
    4: 146887,
    5: 124289,
    "revenu brut": 829000,
    trimf: 500,
    1.5: 203382,
    2.5: 180784,
    3.5: 158186,
    4.5: 135588,
  },
  {
    1: 226350,
    2: 192397,
    3: 169762,
    4: 147127,
    5: 124492,
    "revenu brut": 830000,
    trimf: 500,
    1.5: 203715,
    2.5: 181080,
    3.5: 158445,
    4.5: 135810,
  },
  {
    1: 226720,
    2: 192712,
    3: 170040,
    4: 147368,
    5: 124696,
    "revenu brut": 831_000,
    trimf: 500,
    1.5: 204048,
    2.5: 181376,
    3.5: 158704,
    4.5: 136032,
  },
  {
    1: 227090,
    2: 193026,
    3: 170317,
    4: 147608,
    5: 124899,
    "revenu brut": 832000,
    trimf: 500,
    1.5: 204381,
    2.5: 181672,
    3.5: 158963,
    4.5: 136254,
  },
  {
    1: 227460,
    2: 193341,
    3: 170595,
    4: 147849,
    5: 125103,
    "revenu brut": 833000,
    trimf: 500,
    1.5: 204714,
    2.5: 181968,
    3.5: 159222,
    4.5: 136476,
  },
  {
    1: 227830,
    2: 193655,
    3: 170872,
    4: 148089,
    5: 125306,
    "revenu brut": 834000,
    trimf: 500,
    1.5: 205047,
    2.5: 182264,
    3.5: 159481,
    4.5: 136698,
  },
  {
    1: 228200,
    2: 193970,
    3: 171150,
    4: 148330,
    5: 125510,
    "revenu brut": 835000,
    trimf: 500,
    1.5: 205380,
    2.5: 182560,
    3.5: 159740,
    4.5: 136920,
  },
  {
    1: 228570,
    2: 194284,
    3: 171427,
    4: 148570,
    5: 125713,
    "revenu brut": 836000,
    trimf: 500,
    1.5: 205713,
    2.5: 182856,
    3.5: 159999,
    4.5: 137142,
  },
  {
    1: 228940,
    2: 194599,
    3: 171705,
    4: 148811,
    5: 125917,
    "revenu brut": 837000,
    trimf: 500,
    1.5: 206046,
    2.5: 183152,
    3.5: 160258,
    4.5: 137364,
  },
  {
    1: 229310,
    2: 194913,
    3: 171982,
    4: 149051,
    5: 126120,
    "revenu brut": 838000,
    trimf: 500,
    1.5: 206379,
    2.5: 183448,
    3.5: 160517,
    4.5: 137586,
  },
  {
    1: 229680,
    2: 195228,
    3: 172260,
    4: 149292,
    5: 126324,
    "revenu brut": 839000,
    trimf: 500,
    1.5: 206712,
    2.5: 183744,
    3.5: 160776,
    4.5: 137808,
  },
  {
    1: 230050,
    2: 195542,
    3: 172537,
    4: 149532,
    5: 126527,
    "revenu brut": 840000,
    trimf: 500,
    1.5: 207045,
    2.5: 184040,
    3.5: 161035,
    4.5: 138030,
  },
  {
    1: 230420,
    2: 195857,
    3: 172815,
    4: 149773,
    5: 126731,
    "revenu brut": 841_000,
    trimf: 500,
    1.5: 207378,
    2.5: 184336,
    3.5: 161294,
    4.5: 138252,
  },
  {
    1: 230790,
    2: 196171,
    3: 173092,
    4: 150013,
    5: 126934,
    "revenu brut": 842000,
    trimf: 500,
    1.5: 207711,
    2.5: 184632,
    3.5: 161553,
    4.5: 138474,
  },
  {
    1: 231160,
    2: 196486,
    3: 173370,
    4: 150254,
    5: 127138,
    "revenu brut": 843000,
    trimf: 500,
    1.5: 208044,
    2.5: 184928,
    3.5: 161812,
    4.5: 138696,
  },
  {
    1: 231530,
    2: 196800,
    3: 173647,
    4: 150494,
    5: 127341,
    "revenu brut": 844000,
    trimf: 500,
    1.5: 208377,
    2.5: 185224,
    3.5: 162071,
    4.5: 138918,
  },
  {
    1: 231900,
    2: 197115,
    3: 173925,
    4: 150735,
    5: 127545,
    "revenu brut": 845000,
    trimf: 500,
    1.5: 208710,
    2.5: 185520,
    3.5: 162330,
    4.5: 139140,
  },
  {
    1: 232270,
    2: 197429,
    3: 174202,
    4: 150975,
    5: 127748,
    "revenu brut": 846000,
    trimf: 500,
    1.5: 209043,
    2.5: 185816,
    3.5: 162589,
    4.5: 139362,
  },
  {
    1: 232640,
    2: 197744,
    3: 174480,
    4: 151216,
    5: 127952,
    "revenu brut": 847000,
    trimf: 500,
    1.5: 209376,
    2.5: 186112,
    3.5: 162848,
    4.5: 139584,
  },
  {
    1: 233010,
    2: 198058,
    3: 174757,
    4: 151456,
    5: 128155,
    "revenu brut": 848000,
    trimf: 500,
    1.5: 209709,
    2.5: 186408,
    3.5: 163107,
    4.5: 139806,
  },
  {
    1: 233380,
    2: 198373,
    3: 175035,
    4: 151697,
    5: 128359,
    "revenu brut": 849000,
    trimf: 500,
    1.5: 210042,
    2.5: 186704,
    3.5: 163366,
    4.5: 140028,
  },
  {
    1: 233750,
    2: 198687,
    3: 175312,
    4: 151937,
    5: 128562,
    "revenu brut": 850000,
    trimf: 500,
    1.5: 210375,
    2.5: 187000,
    3.5: 163625,
    4.5: 140250,
  },
  {
    1: 234120,
    2: 199002,
    3: 175590,
    4: 152178,
    5: 128766,
    "revenu brut": 851_000,
    trimf: 500,
    1.5: 210708,
    2.5: 187296,
    3.5: 163884,
    4.5: 140472,
  },
  {
    1: 234490,
    2: 199316,
    3: 175867,
    4: 152418,
    5: 128969,
    "revenu brut": 852000,
    trimf: 500,
    1.5: 211041,
    2.5: 187592,
    3.5: 164143,
    4.5: 140694,
  },
  {
    1: 234860,
    2: 199631,
    3: 176145,
    4: 152659,
    5: 129173,
    "revenu brut": 853000,
    trimf: 500,
    1.5: 211374,
    2.5: 187888,
    3.5: 164402,
    4.5: 140916,
  },
  {
    1: 235230,
    2: 199945,
    3: 176422,
    4: 152899,
    5: 129376,
    "revenu brut": 854000,
    trimf: 500,
    1.5: 211707,
    2.5: 188184,
    3.5: 164661,
    4.5: 141138,
  },
  {
    1: 235600,
    2: 200260,
    3: 176700,
    4: 153140,
    5: 129580,
    "revenu brut": 855000,
    trimf: 500,
    1.5: 212040,
    2.5: 188480,
    3.5: 164920,
    4.5: 141360,
  },
  {
    1: 235970,
    2: 200574,
    3: 176977,
    4: 153380,
    5: 129783,
    "revenu brut": 856000,
    trimf: 500,
    1.5: 212373,
    2.5: 188776,
    3.5: 165179,
    4.5: 141582,
  },
  {
    1: 236340,
    2: 200889,
    3: 177255,
    4: 153621,
    5: 129987,
    "revenu brut": 857000,
    trimf: 500,
    1.5: 212706,
    2.5: 189072,
    3.5: 165438,
    4.5: 141804,
  },
  {
    1: 236710,
    2: 201203,
    3: 177532,
    4: 153861,
    5: 130190,
    "revenu brut": 858000,
    trimf: 500,
    1.5: 213039,
    2.5: 189368,
    3.5: 165697,
    4.5: 142026,
  },
  {
    1: 237080,
    2: 201518,
    3: 177810,
    4: 154102,
    5: 130394,
    "revenu brut": 859000,
    trimf: 500,
    1.5: 213372,
    2.5: 189664,
    3.5: 165956,
    4.5: 142248,
  },
  {
    1: 237450,
    2: 201832,
    3: 178087,
    4: 154342,
    5: 130597,
    "revenu brut": 860000,
    trimf: 500,
    1.5: 213705,
    2.5: 189960,
    3.5: 166215,
    4.5: 142470,
  },
  {
    1: 237820,
    2: 202147,
    3: 178365,
    4: 154583,
    5: 130801,
    "revenu brut": 861_000,
    trimf: 500,
    1.5: 214038,
    2.5: 190256,
    3.5: 166474,
    4.5: 142692,
  },
  {
    1: 238190,
    2: 202461,
    3: 178642,
    4: 154823,
    5: 131004,
    "revenu brut": 862000,
    trimf: 500,
    1.5: 214371,
    2.5: 190552,
    3.5: 166733,
    4.5: 142914,
  },
  {
    1: 238560,
    2: 202776,
    3: 178920,
    4: 155064,
    5: 131208,
    "revenu brut": 863000,
    trimf: 500,
    1.5: 214704,
    2.5: 190848,
    3.5: 166992,
    4.5: 143136,
  },
  {
    1: 238930,
    2: 203090,
    3: 179197,
    4: 155304,
    5: 131411,
    "revenu brut": 864000,
    trimf: 500,
    1.5: 215037,
    2.5: 191144,
    3.5: 167251,
    4.5: 143358,
  },
  {
    1: 239300,
    2: 203405,
    3: 179475,
    4: 155545,
    5: 131615,
    "revenu brut": 865000,
    trimf: 500,
    1.5: 215370,
    2.5: 191440,
    3.5: 167510,
    4.5: 143580,
  },
  {
    1: 239670,
    2: 203719,
    3: 179752,
    4: 155785,
    5: 131818,
    "revenu brut": 866000,
    trimf: 500,
    1.5: 215703,
    2.5: 191736,
    3.5: 167769,
    4.5: 143802,
  },
  {
    1: 240040,
    2: 204034,
    3: 180030,
    4: 156026,
    5: 132022,
    "revenu brut": 867000,
    trimf: 500,
    1.5: 216036,
    2.5: 192032,
    3.5: 168028,
    4.5: 144024,
  },
  {
    1: 240410,
    2: 204348,
    3: 180307,
    4: 156266,
    5: 132225,
    "revenu brut": 868000,
    trimf: 500,
    1.5: 216369,
    2.5: 192328,
    3.5: 168287,
    4.5: 144246,
  },
  {
    1: 240780,
    2: 204663,
    3: 180585,
    4: 156507,
    5: 132429,
    "revenu brut": 869000,
    trimf: 500,
    1.5: 216702,
    2.5: 192624,
    3.5: 168546,
    4.5: 144468,
  },
  {
    1: 241150,
    2: 204977,
    3: 180862,
    4: 156747,
    5: 132632,
    "revenu brut": 870000,
    trimf: 500,
    1.5: 217035,
    2.5: 192920,
    3.5: 168805,
    4.5: 144690,
  },
  {
    1: 241520,
    2: 205292,
    3: 181140,
    4: 156988,
    5: 132836,
    "revenu brut": 871_000,
    trimf: 500,
    1.5: 217368,
    2.5: 193216,
    3.5: 169064,
    4.5: 144912,
  },
  {
    1: 241890,
    2: 205606,
    3: 181417,
    4: 157228,
    5: 133039,
    "revenu brut": 872000,
    trimf: 500,
    1.5: 217701,
    2.5: 193512,
    3.5: 169323,
    4.5: 145134,
  },
  {
    1: 242260,
    2: 205921,
    3: 181695,
    4: 157469,
    5: 133243,
    "revenu brut": 873000,
    trimf: 500,
    1.5: 218034,
    2.5: 193808,
    3.5: 169582,
    4.5: 145356,
  },
  {
    1: 242630,
    2: 206235,
    3: 181972,
    4: 157709,
    5: 133446,
    "revenu brut": 874000,
    trimf: 500,
    1.5: 218367,
    2.5: 194104,
    3.5: 169841,
    4.5: 145578,
  },
  {
    1: 243000,
    2: 206550,
    3: 182250,
    4: 157950,
    5: 133650,
    "revenu brut": 875000,
    trimf: 500,
    1.5: 218700,
    2.5: 194400,
    3.5: 170100,
    4.5: 145800,
  },
  {
    1: 243370,
    2: 206864,
    3: 182527,
    4: 158190,
    5: 133853,
    "revenu brut": 876000,
    trimf: 500,
    1.5: 219033,
    2.5: 194696,
    3.5: 170359,
    4.5: 146022,
  },
  {
    1: 243740,
    2: 207179,
    3: 182805,
    4: 158431,
    5: 134057,
    "revenu brut": 877000,
    trimf: 500,
    1.5: 219366,
    2.5: 194992,
    3.5: 170618,
    4.5: 146244,
  },
  {
    1: 244110,
    2: 207493,
    3: 183082,
    4: 158671,
    5: 134260,
    "revenu brut": 878000,
    trimf: 500,
    1.5: 219699,
    2.5: 195288,
    3.5: 170877,
    4.5: 146466,
  },
  {
    1: 244480,
    2: 207808,
    3: 183360,
    4: 158912,
    5: 134464,
    "revenu brut": 879000,
    trimf: 500,
    1.5: 220032,
    2.5: 195584,
    3.5: 171136,
    4.5: 146688,
  },
  {
    1: 244850,
    2: 208122,
    3: 183637,
    4: 159152,
    5: 134667,
    "revenu brut": 880000,
    trimf: 500,
    1.5: 220365,
    2.5: 195880,
    3.5: 171395,
    4.5: 146910,
  },
  {
    1: 245220,
    2: 208437,
    3: 183915,
    4: 159393,
    5: 134871,
    "revenu brut": 881_000,
    trimf: 500,
    1.5: 220698,
    2.5: 196176,
    3.5: 171654,
    4.5: 147132,
  },
  {
    1: 245590,
    2: 208751,
    3: 184192,
    4: 159633,
    5: 135074,
    "revenu brut": 882000,
    trimf: 500,
    1.5: 221031,
    2.5: 196472,
    3.5: 171913,
    4.5: 147354,
  },
  {
    1: 245960,
    2: 209066,
    3: 184470,
    4: 159874,
    5: 135278,
    "revenu brut": 883000,
    trimf: 500,
    1.5: 221364,
    2.5: 196768,
    3.5: 172172,
    4.5: 147576,
  },
  {
    1: 246330,
    2: 209380,
    3: 184747,
    4: 160114,
    5: 135481,
    "revenu brut": 884000,
    trimf: 500,
    1.5: 221697,
    2.5: 197064,
    3.5: 172431,
    4.5: 147798,
  },
  {
    1: 246700,
    2: 209695,
    3: 185025,
    4: 160355,
    5: 135685,
    "revenu brut": 885000,
    trimf: 500,
    1.5: 222030,
    2.5: 197360,
    3.5: 172690,
    4.5: 148020,
  },
  {
    1: 247070,
    2: 21_0009,
    3: 185302,
    4: 160595,
    5: 135888,
    "revenu brut": 886000,
    trimf: 500,
    1.5: 222363,
    2.5: 197656,
    3.5: 172949,
    4.5: 148242,
  },
  {
    1: 247440,
    2: 210324,
    3: 185580,
    4: 160836,
    5: 136092,
    "revenu brut": 887000,
    trimf: 500,
    1.5: 222696,
    2.5: 197952,
    3.5: 173208,
    4.5: 148464,
  },
  {
    1: 247810,
    2: 210638,
    3: 185857,
    4: 161076,
    5: 136295,
    "revenu brut": 888000,
    trimf: 500,
    1.5: 223029,
    2.5: 198248,
    3.5: 173467,
    4.5: 148686,
  },
  {
    1: 248180,
    2: 210953,
    3: 186135,
    4: 161317,
    5: 136499,
    "revenu brut": 889000,
    trimf: 500,
    1.5: 223362,
    2.5: 198544,
    3.5: 173726,
    4.5: 148908,
  },
  {
    1: 248550,
    2: 211267,
    3: 186412,
    4: 161557,
    5: 136702,
    "revenu brut": 890000,
    trimf: 500,
    1.5: 223695,
    2.5: 198840,
    3.5: 173985,
    4.5: 149130,
  },
  {
    1: 248920,
    2: 211582,
    3: 186690,
    4: 161798,
    5: 136906,
    "revenu brut": 891_000,
    trimf: 500,
    1.5: 224028,
    2.5: 199136,
    3.5: 174244,
    4.5: 149352,
  },
  {
    1: 249290,
    2: 211896,
    3: 186967,
    4: 162038,
    5: 137109,
    "revenu brut": 892000,
    trimf: 500,
    1.5: 224361,
    2.5: 199432,
    3.5: 174503,
    4.5: 149574,
  },
  {
    1: 249660,
    2: 212211,
    3: 187245,
    4: 162279,
    5: 137313,
    "revenu brut": 893000,
    trimf: 500,
    1.5: 224694,
    2.5: 199728,
    3.5: 174762,
    4.5: 149796,
  },
  {
    1: 250030,
    2: 212525,
    3: 187522,
    4: 162519,
    5: 137516,
    "revenu brut": 894000,
    trimf: 500,
    1.5: 225030,
    2.5: 200024,
    3.5: 175021,
    4.5: 150018,
  },
  {
    1: 250400,
    2: 212840,
    3: 187800,
    4: 162760,
    5: 137720,
    "revenu brut": 895000,
    trimf: 500,
    1.5: 225400,
    2.5: 200320,
    3.5: 175280,
    4.5: 150240,
  },
  {
    1: 250770,
    2: 213154,
    3: 188077,
    4: 163000,
    5: 137923,
    "revenu brut": 896000,
    trimf: 500,
    1.5: 225770,
    2.5: 200616,
    3.5: 175539,
    4.5: 150462,
  },
  {
    1: 251140,
    2: 213469,
    3: 188355,
    4: 163241,
    5: 138127,
    "revenu brut": 897000,
    trimf: 500,
    1.5: 226140,
    2.5: 200912,
    3.5: 175798,
    4.5: 150684,
  },
  {
    1: 251510,
    2: 213783,
    3: 188632,
    4: 163481,
    5: 138330,
    "revenu brut": 898000,
    trimf: 500,
    1.5: 226510,
    2.5: 201208,
    3.5: 176057,
    4.5: 150906,
  },
  {
    1: 251880,
    2: 214098,
    3: 188910,
    4: 163722,
    5: 138534,
    "revenu brut": 899000,
    trimf: 500,
    1.5: 226880,
    2.5: 201504,
    3.5: 176316,
    4.5: 151128,
  },
  {
    1: 252250,
    2: 214412,
    3: 189187,
    4: 163962,
    5: 138737,
    "revenu brut": 900000,
    trimf: 500,
    1.5: 227250,
    2.5: 201800,
    3.5: 176575,
    4.5: 151350,
  },
  {
    1: 252620,
    2: 214727,
    3: 189465,
    4: 164203,
    5: 138941,
    "revenu brut": 901_000,
    trimf: 500,
    1.5: 227620,
    2.5: 202096,
    3.5: 176834,
    4.5: 151572,
  },
  {
    1: 252990,
    2: 215041,
    3: 189742,
    4: 164443,
    5: 139144,
    "revenu brut": 902000,
    trimf: 500,
    1.5: 227990,
    2.5: 202392,
    3.5: 177093,
    4.5: 151794,
  },
  {
    1: 253360,
    2: 215356,
    3: 190020,
    4: 164684,
    5: 139348,
    "revenu brut": 903000,
    trimf: 500,
    1.5: 228360,
    2.5: 202688,
    3.5: 177352,
    4.5: 152016,
  },
  {
    1: 253730,
    2: 215670,
    3: 190297,
    4: 164924,
    5: 139551,
    "revenu brut": 904000,
    trimf: 500,
    1.5: 228730,
    2.5: 202984,
    3.5: 177611,
    4.5: 152238,
  },
  {
    1: 254100,
    2: 215985,
    3: 190575,
    4: 165165,
    5: 139755,
    "revenu brut": 905000,
    trimf: 500,
    1.5: 229100,
    2.5: 203280,
    3.5: 177870,
    4.5: 152460,
  },
  {
    1: 254470,
    2: 216299,
    3: 190852,
    4: 165405,
    5: 139958,
    "revenu brut": 906000,
    trimf: 500,
    1.5: 229470,
    2.5: 203576,
    3.5: 178129,
    4.5: 152682,
  },
  {
    1: 254840,
    2: 216614,
    3: 191130,
    4: 165646,
    5: 140162,
    "revenu brut": 907000,
    trimf: 500,
    1.5: 229840,
    2.5: 203872,
    3.5: 178388,
    4.5: 152904,
  },
  {
    1: 255210,
    2: 216928,
    3: 191407,
    4: 165886,
    5: 140365,
    "revenu brut": 908000,
    trimf: 500,
    1.5: 230210,
    2.5: 204168,
    3.5: 178647,
    4.5: 153126,
  },
  {
    1: 255580,
    2: 217243,
    3: 191685,
    4: 166127,
    5: 140569,
    "revenu brut": 909000,
    trimf: 500,
    1.5: 230580,
    2.5: 204464,
    3.5: 178906,
    4.5: 153348,
  },
  {
    1: 255950,
    2: 217557,
    3: 191962,
    4: 166367,
    5: 140772,
    "revenu brut": 91_0000,
    trimf: 500,
    1.5: 230950,
    2.5: 204760,
    3.5: 179165,
    4.5: 153570,
  },
  {
    1: 256320,
    2: 217872,
    3: 192240,
    4: 166608,
    5: 140976,
    "revenu brut": 911_000,
    trimf: 500,
    1.5: 231320,
    2.5: 205056,
    3.5: 179424,
    4.5: 153792,
  },
  {
    1: 256690,
    2: 218186,
    3: 192517,
    4: 166848,
    5: 141179,
    "revenu brut": 912000,
    trimf: 500,
    1.5: 231690,
    2.5: 205352,
    3.5: 179683,
    4.5: 154014,
  },
  {
    1: 257060,
    2: 218501,
    3: 192795,
    4: 167089,
    5: 141383,
    "revenu brut": 913000,
    trimf: 500,
    1.5: 232060,
    2.5: 205648,
    3.5: 179942,
    4.5: 154236,
  },
  {
    1: 257430,
    2: 218815,
    3: 193072,
    4: 167329,
    5: 141586,
    "revenu brut": 914000,
    trimf: 500,
    1.5: 232430,
    2.5: 205944,
    3.5: 180201,
    4.5: 154458,
  },
  {
    1: 257800,
    2: 219130,
    3: 193350,
    4: 167570,
    5: 141790,
    "revenu brut": 915000,
    trimf: 500,
    1.5: 232800,
    2.5: 206240,
    3.5: 180460,
    4.5: 154680,
  },
  {
    1: 258170,
    2: 219444,
    3: 193627,
    4: 167810,
    5: 141993,
    "revenu brut": 916000,
    trimf: 500,
    1.5: 233170,
    2.5: 206536,
    3.5: 180719,
    4.5: 154902,
  },
  {
    1: 258540,
    2: 219759,
    3: 193905,
    4: 168051,
    5: 142197,
    "revenu brut": 917000,
    trimf: 500,
    1.5: 233540,
    2.5: 206832,
    3.5: 180978,
    4.5: 155124,
  },
  {
    1: 258910,
    2: 220073,
    3: 194182,
    4: 168291,
    5: 142400,
    "revenu brut": 918000,
    trimf: 500,
    1.5: 233910,
    2.5: 207128,
    3.5: 181237,
    4.5: 155346,
  },
  {
    1: 259280,
    2: 220388,
    3: 194460,
    4: 168532,
    5: 142604,
    "revenu brut": 919000,
    trimf: 500,
    1.5: 234280,
    2.5: 207424,
    3.5: 181496,
    4.5: 155568,
  },
  {
    1: 259650,
    2: 220702,
    3: 194737,
    4: 168772,
    5: 142807,
    "revenu brut": 920000,
    trimf: 500,
    1.5: 234650,
    2.5: 207720,
    3.5: 181755,
    4.5: 155790,
  },
  {
    1: 260020,
    2: 221017,
    3: 195015,
    4: 169013,
    5: 143011,
    "revenu brut": 921_000,
    trimf: 500,
    1.5: 235020,
    2.5: 208016,
    3.5: 182014,
    4.5: 156012,
  },
  {
    1: 260390,
    2: 221331,
    3: 195292,
    4: 169253,
    5: 143214,
    "revenu brut": 922000,
    trimf: 500,
    1.5: 235390,
    2.5: 208312,
    3.5: 182273,
    4.5: 156234,
  },
  {
    1: 260760,
    2: 221646,
    3: 195570,
    4: 169494,
    5: 143418,
    "revenu brut": 923000,
    trimf: 500,
    1.5: 235760,
    2.5: 208608,
    3.5: 182532,
    4.5: 156456,
  },
  {
    1: 261130,
    2: 221960,
    3: 195847,
    4: 169734,
    5: 143621,
    "revenu brut": 924000,
    trimf: 500,
    1.5: 236130,
    2.5: 208904,
    3.5: 182791,
    4.5: 156678,
  },
  {
    1: 261500,
    2: 222275,
    3: 196125,
    4: 169975,
    5: 143825,
    "revenu brut": 925000,
    trimf: 500,
    1.5: 236500,
    2.5: 209200,
    3.5: 183050,
    4.5: 156900,
  },
  {
    1: 261870,
    2: 222589,
    3: 196402,
    4: 170215,
    5: 144028,
    "revenu brut": 926000,
    trimf: 500,
    1.5: 236870,
    2.5: 209496,
    3.5: 183309,
    4.5: 157122,
  },
  {
    1: 262240,
    2: 222904,
    3: 196680,
    4: 170456,
    5: 144232,
    "revenu brut": 927000,
    trimf: 500,
    1.5: 237240,
    2.5: 209792,
    3.5: 183568,
    4.5: 157344,
  },
  {
    1: 262610,
    2: 223218,
    3: 196957,
    4: 170696,
    5: 144435,
    "revenu brut": 928000,
    trimf: 500,
    1.5: 237610,
    2.5: 210088,
    3.5: 183827,
    4.5: 157566,
  },
  {
    1: 262980,
    2: 223533,
    3: 197235,
    4: 170937,
    5: 144639,
    "revenu brut": 929000,
    trimf: 500,
    1.5: 237980,
    2.5: 210384,
    3.5: 184086,
    4.5: 157788,
  },
  {
    1: 263350,
    2: 223847,
    3: 197512,
    4: 171177,
    5: 144842,
    "revenu brut": 930000,
    trimf: 500,
    1.5: 238350,
    2.5: 210680,
    3.5: 184345,
    4.5: 158010,
  },
  {
    1: 263720,
    2: 224162,
    3: 197790,
    4: 171418,
    5: 145046,
    "revenu brut": 931_000,
    trimf: 500,
    1.5: 238720,
    2.5: 210976,
    3.5: 184604,
    4.5: 158232,
  },
  {
    1: 264090,
    2: 224476,
    3: 198067,
    4: 171658,
    5: 145249,
    "revenu brut": 932000,
    trimf: 500,
    1.5: 239090,
    2.5: 211272,
    3.5: 184863,
    4.5: 158454,
  },
  {
    1: 264460,
    2: 224791,
    3: 198345,
    4: 171899,
    5: 145453,
    "revenu brut": 933000,
    trimf: 500,
    1.5: 239460,
    2.5: 211568,
    3.5: 185122,
    4.5: 158676,
  },
  {
    1: 264830,
    2: 225105,
    3: 198622,
    4: 172139,
    5: 145656,
    "revenu brut": 934000,
    trimf: 500,
    1.5: 239830,
    2.5: 211864,
    3.5: 185381,
    4.5: 158898,
  },
  {
    1: 265200,
    2: 225420,
    3: 198900,
    4: 172380,
    5: 145860,
    "revenu brut": 935000,
    trimf: 500,
    1.5: 240200,
    2.5: 212160,
    3.5: 185640,
    4.5: 159120,
  },
  {
    1: 265570,
    2: 225734,
    3: 199177,
    4: 172620,
    5: 146063,
    "revenu brut": 936000,
    trimf: 500,
    1.5: 240570,
    2.5: 212456,
    3.5: 185899,
    4.5: 159342,
  },
  {
    1: 265940,
    2: 226049,
    3: 199455,
    4: 172861,
    5: 146267,
    "revenu brut": 937000,
    trimf: 500,
    1.5: 240940,
    2.5: 212752,
    3.5: 186158,
    4.5: 159564,
  },
  {
    1: 266310,
    2: 226363,
    3: 199732,
    4: 173101,
    5: 146470,
    "revenu brut": 938000,
    trimf: 500,
    1.5: 241310,
    2.5: 213048,
    3.5: 186417,
    4.5: 159786,
  },
  {
    1: 266680,
    2: 226678,
    3: 200010,
    4: 173342,
    5: 146674,
    "revenu brut": 939000,
    trimf: 500,
    1.5: 241680,
    2.5: 213344,
    3.5: 186676,
    4.5: 160008,
  },
  {
    1: 267050,
    2: 226992,
    3: 200287,
    4: 173582,
    5: 146877,
    "revenu brut": 940000,
    trimf: 500,
    1.5: 242050,
    2.5: 213640,
    3.5: 186935,
    4.5: 160230,
  },
  {
    1: 267420,
    2: 227307,
    3: 200565,
    4: 173823,
    5: 147081,
    "revenu brut": 941_000,
    trimf: 500,
    1.5: 242420,
    2.5: 213936,
    3.5: 187194,
    4.5: 160452,
  },
  {
    1: 267790,
    2: 227621,
    3: 200842,
    4: 174063,
    5: 147284,
    "revenu brut": 942000,
    trimf: 500,
    1.5: 242790,
    2.5: 214232,
    3.5: 187453,
    4.5: 160674,
  },
  {
    1: 268160,
    2: 227936,
    3: 201120,
    4: 174304,
    5: 147488,
    "revenu brut": 943000,
    trimf: 500,
    1.5: 243160,
    2.5: 214528,
    3.5: 187712,
    4.5: 160896,
  },
  {
    1: 268530,
    2: 228250,
    3: 201397,
    4: 174544,
    5: 147691,
    "revenu brut": 944000,
    trimf: 500,
    1.5: 243530,
    2.5: 214824,
    3.5: 187971,
    4.5: 161118,
  },
  {
    1: 268900,
    2: 228565,
    3: 201675,
    4: 174785,
    5: 147895,
    "revenu brut": 945000,
    trimf: 500,
    1.5: 243900,
    2.5: 215120,
    3.5: 188230,
    4.5: 161340,
  },
  {
    1: 269270,
    2: 228879,
    3: 201952,
    4: 175025,
    5: 148098,
    "revenu brut": 946000,
    trimf: 500,
    1.5: 244270,
    2.5: 215416,
    3.5: 188489,
    4.5: 161562,
  },
  {
    1: 269640,
    2: 229194,
    3: 202230,
    4: 175266,
    5: 148302,
    "revenu brut": 947000,
    trimf: 500,
    1.5: 244640,
    2.5: 215712,
    3.5: 188748,
    4.5: 161784,
  },
  {
    1: 270010,
    2: 229508,
    3: 202507,
    4: 175506,
    5: 148505,
    "revenu brut": 948000,
    trimf: 500,
    1.5: 245010,
    2.5: 216008,
    3.5: 189007,
    4.5: 162006,
  },
  {
    1: 270380,
    2: 229823,
    3: 202785,
    4: 175747,
    5: 148709,
    "revenu brut": 949000,
    trimf: 500,
    1.5: 245380,
    2.5: 216304,
    3.5: 189266,
    4.5: 162228,
  },
  {
    1: 270750,
    2: 230137,
    3: 203062,
    4: 175987,
    5: 148912,
    "revenu brut": 950000,
    trimf: 500,
    1.5: 245750,
    2.5: 216600,
    3.5: 189525,
    4.5: 162450,
  },
  {
    1: 271120,
    2: 230452,
    3: 203340,
    4: 176228,
    5: 149116,
    "revenu brut": 951_000,
    trimf: 500,
    1.5: 246120,
    2.5: 216896,
    3.5: 189784,
    4.5: 162672,
  },
  {
    1: 271490,
    2: 230766,
    3: 203617,
    4: 176468,
    5: 149319,
    "revenu brut": 952000,
    trimf: 500,
    1.5: 246490,
    2.5: 217192,
    3.5: 190043,
    4.5: 162894,
  },
  {
    1: 271860,
    2: 231081,
    3: 203895,
    4: 176709,
    5: 149523,
    "revenu brut": 953000,
    trimf: 500,
    1.5: 246860,
    2.5: 217488,
    3.5: 190302,
    4.5: 163116,
  },
  {
    1: 272230,
    2: 231395,
    3: 204172,
    4: 176949,
    5: 149726,
    "revenu brut": 954000,
    trimf: 500,
    1.5: 247230,
    2.5: 217784,
    3.5: 190561,
    4.5: 163338,
  },
  {
    1: 272600,
    2: 231710,
    3: 204450,
    4: 177190,
    5: 149930,
    "revenu brut": 955000,
    trimf: 500,
    1.5: 247600,
    2.5: 218080,
    3.5: 190820,
    4.5: 163560,
  },
  {
    1: 272970,
    2: 232024,
    3: 204727,
    4: 177430,
    5: 150133,
    "revenu brut": 956000,
    trimf: 500,
    1.5: 247970,
    2.5: 218376,
    3.5: 191079,
    4.5: 163782,
  },
  {
    1: 273340,
    2: 232339,
    3: 205005,
    4: 177671,
    5: 150337,
    "revenu brut": 957000,
    trimf: 500,
    1.5: 248340,
    2.5: 218672,
    3.5: 191338,
    4.5: 164004,
  },
  {
    1: 273710,
    2: 232653,
    3: 205282,
    4: 177911,
    5: 150540,
    "revenu brut": 958000,
    trimf: 500,
    1.5: 248710,
    2.5: 218968,
    3.5: 191597,
    4.5: 164226,
  },
  {
    1: 274080,
    2: 232968,
    3: 205560,
    4: 178152,
    5: 150744,
    "revenu brut": 959000,
    trimf: 500,
    1.5: 249080,
    2.5: 219264,
    3.5: 191856,
    4.5: 164448,
  },
  {
    1: 274450,
    2: 233282,
    3: 205837,
    4: 178392,
    5: 150947,
    "revenu brut": 960000,
    trimf: 500,
    1.5: 249450,
    2.5: 219560,
    3.5: 192115,
    4.5: 164670,
  },
  {
    1: 274820,
    2: 233597,
    3: 206115,
    4: 178633,
    5: 151151,
    "revenu brut": 961_000,
    trimf: 500,
    1.5: 249820,
    2.5: 219856,
    3.5: 192374,
    4.5: 164892,
  },
  {
    1: 275190,
    2: 233911,
    3: 206392,
    4: 178873,
    5: 151354,
    "revenu brut": 962000,
    trimf: 500,
    1.5: 250190,
    2.5: 220152,
    3.5: 192633,
    4.5: 165114,
  },
  {
    1: 275560,
    2: 234226,
    3: 206670,
    4: 179114,
    5: 151558,
    "revenu brut": 963000,
    trimf: 500,
    1.5: 250560,
    2.5: 220448,
    3.5: 192892,
    4.5: 165336,
  },
  {
    1: 275930,
    2: 234540,
    3: 206947,
    4: 179354,
    5: 151761,
    "revenu brut": 964000,
    trimf: 500,
    1.5: 250930,
    2.5: 220744,
    3.5: 193151,
    4.5: 165558,
  },
  {
    1: 276300,
    2: 234855,
    3: 207225,
    4: 179595,
    5: 151965,
    "revenu brut": 965000,
    trimf: 500,
    1.5: 251300,
    2.5: 221040,
    3.5: 193410,
    4.5: 165780,
  },
  {
    1: 276670,
    2: 235169,
    3: 207502,
    4: 179835,
    5: 152168,
    "revenu brut": 966000,
    trimf: 500,
    1.5: 251670,
    2.5: 221336,
    3.5: 193669,
    4.5: 166002,
  },
  {
    1: 277040,
    2: 235484,
    3: 207780,
    4: 180076,
    5: 152372,
    "revenu brut": 967000,
    trimf: 500,
    1.5: 252040,
    2.5: 221632,
    3.5: 193928,
    4.5: 166224,
  },
  {
    1: 277410,
    2: 235798,
    3: 208057,
    4: 180316,
    5: 152575,
    "revenu brut": 968000,
    trimf: 500,
    1.5: 252410,
    2.5: 221928,
    3.5: 194187,
    4.5: 166446,
  },
  {
    1: 277780,
    2: 236113,
    3: 208335,
    4: 180557,
    5: 152779,
    "revenu brut": 969000,
    trimf: 500,
    1.5: 252780,
    2.5: 222224,
    3.5: 194446,
    4.5: 166668,
  },
  {
    1: 278150,
    2: 236427,
    3: 208612,
    4: 180797,
    5: 152982,
    "revenu brut": 970000,
    trimf: 500,
    1.5: 253150,
    2.5: 222520,
    3.5: 194705,
    4.5: 166890,
  },
  {
    1: 278520,
    2: 236742,
    3: 208890,
    4: 181038,
    5: 153186,
    "revenu brut": 971_000,
    trimf: 500,
    1.5: 253520,
    2.5: 222816,
    3.5: 194964,
    4.5: 167112,
  },
  {
    1: 278890,
    2: 237056,
    3: 209167,
    4: 181278,
    5: 153389,
    "revenu brut": 972000,
    trimf: 500,
    1.5: 253890,
    2.5: 223112,
    3.5: 195223,
    4.5: 167334,
  },
  {
    1: 279260,
    2: 237371,
    3: 209445,
    4: 181519,
    5: 153593,
    "revenu brut": 973000,
    trimf: 500,
    1.5: 254260,
    2.5: 223408,
    3.5: 195482,
    4.5: 167556,
  },
  {
    1: 279630,
    2: 237685,
    3: 209722,
    4: 181759,
    5: 153796,
    "revenu brut": 974000,
    trimf: 500,
    1.5: 254630,
    2.5: 223704,
    3.5: 195741,
    4.5: 167778,
  },
  {
    1: 280000,
    2: 238000,
    3: 21_0000,
    4: 182000,
    5: 154000,
    "revenu brut": 975000,
    trimf: 500,
    1.5: 255000,
    2.5: 224000,
    3.5: 196000,
    4.5: 168000,
  },
  {
    1: 280370,
    2: 238314,
    3: 210277,
    4: 182240,
    5: 154203,
    "revenu brut": 976000,
    trimf: 500,
    1.5: 255370,
    2.5: 224296,
    3.5: 196259,
    4.5: 168222,
  },
  {
    1: 280740,
    2: 238629,
    3: 210555,
    4: 182481,
    5: 154407,
    "revenu brut": 977000,
    trimf: 500,
    1.5: 255740,
    2.5: 224592,
    3.5: 196518,
    4.5: 168444,
  },
  {
    1: 281110,
    2: 238943,
    3: 210832,
    4: 182721,
    5: 154610,
    "revenu brut": 978000,
    trimf: 500,
    1.5: 256110,
    2.5: 224888,
    3.5: 196777,
    4.5: 168666,
  },
  {
    1: 281480,
    2: 239258,
    3: 211110,
    4: 182962,
    5: 154814,
    "revenu brut": 979000,
    trimf: 500,
    1.5: 256480,
    2.5: 225184,
    3.5: 197036,
    4.5: 168888,
  },
  {
    1: 281850,
    2: 239572,
    3: 211387,
    4: 183202,
    5: 155017,
    "revenu brut": 980000,
    trimf: 500,
    1.5: 256850,
    2.5: 225480,
    3.5: 197295,
    4.5: 169110,
  },
  {
    1: 282220,
    2: 239887,
    3: 211665,
    4: 183443,
    5: 155221,
    "revenu brut": 981_000,
    trimf: 500,
    1.5: 257220,
    2.5: 225776,
    3.5: 197554,
    4.5: 169332,
  },
  {
    1: 282590,
    2: 240201,
    3: 211942,
    4: 183683,
    5: 155424,
    "revenu brut": 982000,
    trimf: 500,
    1.5: 257590,
    2.5: 226072,
    3.5: 197813,
    4.5: 169554,
  },
  {
    1: 282960,
    2: 240516,
    3: 212220,
    4: 183924,
    5: 155628,
    "revenu brut": 983000,
    trimf: 500,
    1.5: 257960,
    2.5: 226368,
    3.5: 198072,
    4.5: 169776,
  },
  {
    1: 283330,
    2: 240830,
    3: 212497,
    4: 184164,
    5: 155831,
    "revenu brut": 984000,
    trimf: 500,
    1.5: 258330,
    2.5: 226664,
    3.5: 198331,
    4.5: 169998,
  },
  {
    1: 283700,
    2: 241145,
    3: 212775,
    4: 184405,
    5: 156035,
    "revenu brut": 985000,
    trimf: 500,
    1.5: 258700,
    2.5: 226960,
    3.5: 198590,
    4.5: 170220,
  },
  {
    1: 284070,
    2: 241459,
    3: 213052,
    4: 184645,
    5: 156238,
    "revenu brut": 986000,
    trimf: 500,
    1.5: 259070,
    2.5: 227256,
    3.5: 198849,
    4.5: 170442,
  },
  {
    1: 284440,
    2: 241774,
    3: 213330,
    4: 184886,
    5: 156442,
    "revenu brut": 987000,
    trimf: 500,
    1.5: 259440,
    2.5: 227552,
    3.5: 199108,
    4.5: 170664,
  },
  {
    1: 284810,
    2: 242088,
    3: 213607,
    4: 185126,
    5: 156645,
    "revenu brut": 988000,
    trimf: 500,
    1.5: 259810,
    2.5: 227848,
    3.5: 199367,
    4.5: 170886,
  },
  {
    1: 285180,
    2: 242403,
    3: 213885,
    4: 185367,
    5: 156849,
    "revenu brut": 989000,
    trimf: 500,
    1.5: 260180,
    2.5: 228144,
    3.5: 199626,
    4.5: 171108,
  },
  {
    1: 285550,
    2: 242717,
    3: 214162,
    4: 185607,
    5: 157052,
    "revenu brut": 990000,
    trimf: 500,
    1.5: 260550,
    2.5: 228440,
    3.5: 199885,
    4.5: 171330,
  },
  {
    1: 285920,
    2: 243032,
    3: 214440,
    4: 185848,
    5: 157256,
    "revenu brut": 991_000,
    trimf: 500,
    1.5: 260920,
    2.5: 228736,
    3.5: 200144,
    4.5: 171552,
  },
  {
    1: 286290,
    2: 243346,
    3: 214717,
    4: 186088,
    5: 157459,
    "revenu brut": 992000,
    trimf: 500,
    1.5: 261290,
    2.5: 229032,
    3.5: 200403,
    4.5: 171774,
  },
  {
    1: 286660,
    2: 243661,
    3: 214995,
    4: 186329,
    5: 157663,
    "revenu brut": 993000,
    trimf: 500,
    1.5: 261660,
    2.5: 229328,
    3.5: 200662,
    4.5: 171996,
  },
  {
    1: 287030,
    2: 243975,
    3: 215272,
    4: 186569,
    5: 157866,
    "revenu brut": 994000,
    trimf: 500,
    1.5: 262030,
    2.5: 229624,
    3.5: 200921,
    4.5: 172218,
  },
  {
    1: 287400,
    2: 244290,
    3: 215550,
    4: 186810,
    5: 158070,
    "revenu brut": 995000,
    trimf: 500,
    1.5: 262400,
    2.5: 229920,
    3.5: 201180,
    4.5: 172440,
  },
  {
    1: 287770,
    2: 244604,
    3: 215827,
    4: 187050,
    5: 158273,
    "revenu brut": 996000,
    trimf: 500,
    1.5: 262770,
    2.5: 230216,
    3.5: 201439,
    4.5: 172662,
  },
  {
    1: 288140,
    2: 244919,
    3: 216105,
    4: 187291,
    5: 158477,
    "revenu brut": 997000,
    trimf: 500,
    1.5: 263140,
    2.5: 230512,
    3.5: 201698,
    4.5: 172884,
  },
  {
    1: 288510,
    2: 245233,
    3: 216382,
    4: 187531,
    5: 158680,
    "revenu brut": 998000,
    trimf: 500,
    1.5: 263510,
    2.5: 230808,
    3.5: 201957,
    4.5: 173106,
  },
  {
    1: 288880,
    2: 245548,
    3: 216660,
    4: 187772,
    5: 158884,
    "revenu brut": 999000,
    trimf: 500,
    1.5: 263880,
    2.5: 231104,
    3.5: 202216,
    4.5: 173328,
  },
  {
    1: 289250,
    2: 245862,
    3: 216937,
    4: 188012,
    5: 159087,
    "revenu brut": 1_000000,
    trimf: 1500,
    1.5: 264250,
    2.5: 231400,
    3.5: 202475,
    4.5: 173550,
  },
  {
    1: 289620,
    2: 246177,
    3: 217215,
    4: 188253,
    5: 159291,
    "revenu brut": 1001_000,
    trimf: 1500,
    1.5: 264620,
    2.5: 231696,
    3.5: 202734,
    4.5: 173772,
  },
  {
    1: 289990,
    2: 246491,
    3: 217492,
    4: 188493,
    5: 159494,
    "revenu brut": 1002000,
    trimf: 1500,
    1.5: 264990,
    2.5: 231992,
    3.5: 202993,
    4.5: 173994,
  },
  {
    1: 290360,
    2: 246806,
    3: 217770,
    4: 188734,
    5: 159698,
    "revenu brut": 1003000,
    trimf: 1500,
    1.5: 265360,
    2.5: 232288,
    3.5: 203252,
    4.5: 174216,
  },
  {
    1: 290730,
    2: 247120,
    3: 218047,
    4: 188974,
    5: 159901,
    "revenu brut": 1004000,
    trimf: 1500,
    1.5: 265730,
    2.5: 232584,
    3.5: 203511,
    4.5: 174438,
  },
  {
    1: 291100,
    2: 247435,
    3: 218325,
    4: 189215,
    5: 160105,
    "revenu brut": 1005000,
    trimf: 1500,
    1.5: 266100,
    2.5: 232880,
    3.5: 203770,
    4.5: 174660,
  },
  {
    1: 291470,
    2: 247749,
    3: 218602,
    4: 189455,
    5: 160308,
    "revenu brut": 1006000,
    trimf: 1500,
    1.5: 266470,
    2.5: 233176,
    3.5: 204029,
    4.5: 174882,
  },
  {
    1: 291840,
    2: 248064,
    3: 218880,
    4: 189696,
    5: 160512,
    "revenu brut": 1007000,
    trimf: 1500,
    1.5: 266840,
    2.5: 233472,
    3.5: 204288,
    4.5: 175104,
  },
  {
    1: 292210,
    2: 248378,
    3: 219157,
    4: 189936,
    5: 160715,
    "revenu brut": 1008000,
    trimf: 1500,
    1.5: 267210,
    2.5: 233768,
    3.5: 204547,
    4.5: 175326,
  },
  {
    1: 292580,
    2: 248693,
    3: 219435,
    4: 190177,
    5: 160919,
    "revenu brut": 1009000,
    trimf: 1500,
    1.5: 267580,
    2.5: 234064,
    3.5: 204806,
    4.5: 175548,
  },
  {
    1: 292950,
    2: 249007,
    3: 219712,
    4: 190417,
    5: 161122,
    "revenu brut": 101_0000,
    trimf: 1500,
    1.5: 267950,
    2.5: 234360,
    3.5: 205065,
    4.5: 175770,
  },
  {
    1: 293320,
    2: 249322,
    3: 219990,
    4: 190658,
    5: 161326,
    "revenu brut": 1011_000,
    trimf: 1500,
    1.5: 268320,
    2.5: 234656,
    3.5: 205324,
    4.5: 175992,
  },
  {
    1: 293690,
    2: 249636,
    3: 220267,
    4: 190898,
    5: 161529,
    "revenu brut": 1012000,
    trimf: 1500,
    1.5: 268690,
    2.5: 234952,
    3.5: 205583,
    4.5: 176214,
  },
  {
    1: 294060,
    2: 249951,
    3: 220545,
    4: 191139,
    5: 161733,
    "revenu brut": 1013000,
    trimf: 1500,
    1.5: 269060,
    2.5: 235248,
    3.5: 205842,
    4.5: 176436,
  },
  {
    1: 294430,
    2: 250265,
    3: 220822,
    4: 191379,
    5: 161936,
    "revenu brut": 1014000,
    trimf: 1500,
    1.5: 269430,
    2.5: 235544,
    3.5: 206101,
    4.5: 176658,
  },
  {
    1: 294800,
    2: 250580,
    3: 221100,
    4: 191620,
    5: 162140,
    "revenu brut": 1015000,
    trimf: 1500,
    1.5: 269800,
    2.5: 235840,
    3.5: 206360,
    4.5: 176880,
  },
  {
    1: 295170,
    2: 250894,
    3: 221377,
    4: 191860,
    5: 162343,
    "revenu brut": 1016000,
    trimf: 1500,
    1.5: 270170,
    2.5: 236136,
    3.5: 206619,
    4.5: 177102,
  },
  {
    1: 295540,
    2: 251209,
    3: 221655,
    4: 192101,
    5: 162547,
    "revenu brut": 1017000,
    trimf: 1500,
    1.5: 270540,
    2.5: 236432,
    3.5: 206878,
    4.5: 177324,
  },
  {
    1: 295910,
    2: 251523,
    3: 221932,
    4: 192341,
    5: 162750,
    "revenu brut": 1018000,
    trimf: 1500,
    1.5: 270910,
    2.5: 236728,
    3.5: 207137,
    4.5: 177546,
  },
  {
    1: 296280,
    2: 251838,
    3: 222210,
    4: 192582,
    5: 162954,
    "revenu brut": 1019000,
    trimf: 1500,
    1.5: 271280,
    2.5: 237024,
    3.5: 207396,
    4.5: 177768,
  },
  {
    1: 296650,
    2: 252152,
    3: 222487,
    4: 192822,
    5: 163157,
    "revenu brut": 1020000,
    trimf: 1500,
    1.5: 271650,
    2.5: 237320,
    3.5: 207655,
    4.5: 177990,
  },
  {
    1: 297020,
    2: 252467,
    3: 222765,
    4: 193063,
    5: 163361,
    "revenu brut": 1021_000,
    trimf: 1500,
    1.5: 272020,
    2.5: 237616,
    3.5: 207914,
    4.5: 178212,
  },
  {
    1: 297390,
    2: 252781,
    3: 223042,
    4: 193303,
    5: 163564,
    "revenu brut": 1022000,
    trimf: 1500,
    1.5: 272390,
    2.5: 237912,
    3.5: 208173,
    4.5: 178434,
  },
  {
    1: 297760,
    2: 253096,
    3: 223320,
    4: 193544,
    5: 163768,
    "revenu brut": 1023000,
    trimf: 1500,
    1.5: 272760,
    2.5: 238208,
    3.5: 208432,
    4.5: 178656,
  },
  {
    1: 298130,
    2: 253410,
    3: 223597,
    4: 193784,
    5: 163971,
    "revenu brut": 1024000,
    trimf: 1500,
    1.5: 273130,
    2.5: 238504,
    3.5: 208691,
    4.5: 178878,
  },
  {
    1: 298500,
    2: 253725,
    3: 223875,
    4: 194025,
    5: 164175,
    "revenu brut": 1025000,
    trimf: 1500,
    1.5: 273500,
    2.5: 238800,
    3.5: 208950,
    4.5: 179100,
  },
  {
    1: 298870,
    2: 254039,
    3: 224152,
    4: 194265,
    5: 164378,
    "revenu brut": 1026000,
    trimf: 1500,
    1.5: 273870,
    2.5: 239096,
    3.5: 209209,
    4.5: 179322,
  },
  {
    1: 299240,
    2: 254354,
    3: 224430,
    4: 194506,
    5: 164582,
    "revenu brut": 1027000,
    trimf: 1500,
    1.5: 274240,
    2.5: 239392,
    3.5: 209468,
    4.5: 179544,
  },
  {
    1: 299610,
    2: 254668,
    3: 224707,
    4: 194746,
    5: 164785,
    "revenu brut": 1028000,
    trimf: 1500,
    1.5: 274610,
    2.5: 239688,
    3.5: 209727,
    4.5: 179766,
  },
  {
    1: 299980,
    2: 254983,
    3: 224985,
    4: 194987,
    5: 164989,
    "revenu brut": 1029000,
    trimf: 1500,
    1.5: 274980,
    2.5: 239984,
    3.5: 209986,
    4.5: 179988,
  },
  {
    1: 300350,
    2: 255297,
    3: 225262,
    4: 195227,
    5: 165192,
    "revenu brut": 1030000,
    trimf: 1500,
    1.5: 275350,
    2.5: 240280,
    3.5: 210245,
    4.5: 180210,
  },
  {
    1: 300720,
    2: 255612,
    3: 225540,
    4: 195468,
    5: 165396,
    "revenu brut": 1031_000,
    trimf: 1500,
    1.5: 275720,
    2.5: 240576,
    3.5: 210504,
    4.5: 180432,
  },
  {
    1: 301090,
    2: 255926,
    3: 225817,
    4: 195708,
    5: 165599,
    "revenu brut": 1032000,
    trimf: 1500,
    1.5: 276090,
    2.5: 240872,
    3.5: 210763,
    4.5: 180654,
  },
  {
    1: 301460,
    2: 256241,
    3: 226095,
    4: 195949,
    5: 165803,
    "revenu brut": 1033000,
    trimf: 1500,
    1.5: 276460,
    2.5: 241168,
    3.5: 211022,
    4.5: 180876,
  },
  {
    1: 301830,
    2: 256555,
    3: 226372,
    4: 196189,
    5: 166006,
    "revenu brut": 1034000,
    trimf: 1500,
    1.5: 276830,
    2.5: 241464,
    3.5: 211281,
    4.5: 181098,
  },
  {
    1: 302200,
    2: 256870,
    3: 226650,
    4: 196430,
    5: 166210,
    "revenu brut": 1035000,
    trimf: 1500,
    1.5: 277200,
    2.5: 241760,
    3.5: 211540,
    4.5: 181320,
  },
  {
    1: 302570,
    2: 257184,
    3: 226927,
    4: 196670,
    5: 166413,
    "revenu brut": 1036000,
    trimf: 1500,
    1.5: 277570,
    2.5: 242056,
    3.5: 211799,
    4.5: 181542,
  },
  {
    1: 302940,
    2: 257499,
    3: 227205,
    4: 196911,
    5: 166617,
    "revenu brut": 1037000,
    trimf: 1500,
    1.5: 277940,
    2.5: 242352,
    3.5: 212058,
    4.5: 181764,
  },
  {
    1: 303310,
    2: 257813,
    3: 227482,
    4: 197151,
    5: 166820,
    "revenu brut": 1038000,
    trimf: 1500,
    1.5: 278310,
    2.5: 242648,
    3.5: 212317,
    4.5: 181986,
  },
  {
    1: 303680,
    2: 258128,
    3: 227760,
    4: 197392,
    5: 167024,
    "revenu brut": 1039000,
    trimf: 1500,
    1.5: 278680,
    2.5: 242944,
    3.5: 212576,
    4.5: 182208,
  },
  {
    1: 304050,
    2: 258442,
    3: 228037,
    4: 197632,
    5: 167227,
    "revenu brut": 1040000,
    trimf: 1500,
    1.5: 279050,
    2.5: 243240,
    3.5: 212835,
    4.5: 182430,
  },
  {
    1: 304420,
    2: 258757,
    3: 228315,
    4: 197873,
    5: 167431,
    "revenu brut": 1041_000,
    trimf: 1500,
    1.5: 279420,
    2.5: 243536,
    3.5: 213094,
    4.5: 182652,
  },
  {
    1: 304790,
    2: 259071,
    3: 228592,
    4: 198113,
    5: 167634,
    "revenu brut": 1042000,
    trimf: 1500,
    1.5: 279790,
    2.5: 243832,
    3.5: 213353,
    4.5: 182874,
  },
  {
    1: 305160,
    2: 259386,
    3: 228870,
    4: 198354,
    5: 167838,
    "revenu brut": 1043000,
    trimf: 1500,
    1.5: 280160,
    2.5: 244128,
    3.5: 213612,
    4.5: 183096,
  },
  {
    1: 305530,
    2: 259700,
    3: 229147,
    4: 198594,
    5: 168041,
    "revenu brut": 1044000,
    trimf: 1500,
    1.5: 280530,
    2.5: 244424,
    3.5: 213871,
    4.5: 183318,
  },
  {
    1: 305900,
    2: 260015,
    3: 229425,
    4: 198835,
    5: 168245,
    "revenu brut": 1045000,
    trimf: 1500,
    1.5: 280900,
    2.5: 244720,
    3.5: 214130,
    4.5: 183540,
  },
  {
    1: 306270,
    2: 260329,
    3: 229702,
    4: 199075,
    5: 168448,
    "revenu brut": 1046000,
    trimf: 1500,
    1.5: 281270,
    2.5: 245016,
    3.5: 214389,
    4.5: 183762,
  },
  {
    1: 306640,
    2: 260644,
    3: 229980,
    4: 199316,
    5: 168652,
    "revenu brut": 1047000,
    trimf: 1500,
    1.5: 281640,
    2.5: 245312,
    3.5: 214648,
    4.5: 183984,
  },
  {
    1: 307010,
    2: 260958,
    3: 230257,
    4: 199556,
    5: 168855,
    "revenu brut": 1048000,
    trimf: 1500,
    1.5: 282010,
    2.5: 245608,
    3.5: 214907,
    4.5: 184206,
  },
  {
    1: 307380,
    2: 261273,
    3: 230535,
    4: 199797,
    5: 169059,
    "revenu brut": 1049000,
    trimf: 1500,
    1.5: 282380,
    2.5: 245904,
    3.5: 215166,
    4.5: 184428,
  },
  {
    1: 307750,
    2: 261587,
    3: 230812,
    4: 200037,
    5: 169262,
    "revenu brut": 1050000,
    trimf: 1500,
    1.5: 282750,
    2.5: 246200,
    3.5: 215425,
    4.5: 184650,
  },
  {
    1: 308120,
    2: 261902,
    3: 231090,
    4: 200278,
    5: 169466,
    "revenu brut": 1051_000,
    trimf: 1500,
    1.5: 283120,
    2.5: 246496,
    3.5: 215684,
    4.5: 184872,
  },
  {
    1: 308490,
    2: 262216,
    3: 231367,
    4: 200518,
    5: 169669,
    "revenu brut": 1052000,
    trimf: 1500,
    1.5: 283490,
    2.5: 246792,
    3.5: 215943,
    4.5: 185094,
  },
  {
    1: 308860,
    2: 262531,
    3: 231645,
    4: 200759,
    5: 169873,
    "revenu brut": 1053000,
    trimf: 1500,
    1.5: 283860,
    2.5: 247088,
    3.5: 216202,
    4.5: 185316,
  },
  {
    1: 309230,
    2: 262845,
    3: 231922,
    4: 200999,
    5: 170076,
    "revenu brut": 1054000,
    trimf: 1500,
    1.5: 284230,
    2.5: 247384,
    3.5: 216461,
    4.5: 185538,
  },
  {
    1: 309600,
    2: 263160,
    3: 232200,
    4: 201240,
    5: 170280,
    "revenu brut": 1055000,
    trimf: 1500,
    1.5: 284600,
    2.5: 247680,
    3.5: 216720,
    4.5: 185760,
  },
  {
    1: 309970,
    2: 263474,
    3: 232477,
    4: 201480,
    5: 170483,
    "revenu brut": 1056000,
    trimf: 1500,
    1.5: 284970,
    2.5: 247976,
    3.5: 216979,
    4.5: 185982,
  },
  {
    1: 310340,
    2: 263789,
    3: 232755,
    4: 201721,
    5: 170687,
    "revenu brut": 1057000,
    trimf: 1500,
    1.5: 285340,
    2.5: 248272,
    3.5: 217238,
    4.5: 186204,
  },
  {
    1: 310710,
    2: 264103,
    3: 233032,
    4: 201961,
    5: 170890,
    "revenu brut": 1058000,
    trimf: 1500,
    1.5: 285710,
    2.5: 248568,
    3.5: 217497,
    4.5: 186426,
  },
  {
    1: 311080,
    2: 264418,
    3: 233310,
    4: 202202,
    5: 171094,
    "revenu brut": 1059000,
    trimf: 1500,
    1.5: 286080,
    2.5: 248864,
    3.5: 217756,
    4.5: 186648,
  },
  {
    1: 311450,
    2: 264732,
    3: 233587,
    4: 202442,
    5: 171297,
    "revenu brut": 1060000,
    trimf: 1500,
    1.5: 286450,
    2.5: 249160,
    3.5: 218015,
    4.5: 186870,
  },
  {
    1: 311820,
    2: 265047,
    3: 233865,
    4: 202683,
    5: 171501,
    "revenu brut": 1061_000,
    trimf: 1500,
    1.5: 286820,
    2.5: 249456,
    3.5: 218274,
    4.5: 187092,
  },
  {
    1: 312190,
    2: 265361,
    3: 234142,
    4: 202923,
    5: 171704,
    "revenu brut": 1062000,
    trimf: 1500,
    1.5: 287190,
    2.5: 249752,
    3.5: 218533,
    4.5: 187314,
  },
  {
    1: 312560,
    2: 265676,
    3: 234420,
    4: 203164,
    5: 171908,
    "revenu brut": 1063000,
    trimf: 1500,
    1.5: 287560,
    2.5: 250048,
    3.5: 218792,
    4.5: 187536,
  },
  {
    1: 312930,
    2: 265990,
    3: 234697,
    4: 203404,
    5: 172111,
    "revenu brut": 1064000,
    trimf: 1500,
    1.5: 287930,
    2.5: 250344,
    3.5: 219051,
    4.5: 187758,
  },
  {
    1: 313300,
    2: 266305,
    3: 234975,
    4: 203645,
    5: 172315,
    "revenu brut": 1065000,
    trimf: 1500,
    1.5: 288300,
    2.5: 250640,
    3.5: 219310,
    4.5: 187980,
  },
  {
    1: 313670,
    2: 266619,
    3: 235252,
    4: 203885,
    5: 172518,
    "revenu brut": 1066000,
    trimf: 1500,
    1.5: 288670,
    2.5: 250936,
    3.5: 219569,
    4.5: 188202,
  },
  {
    1: 314040,
    2: 266934,
    3: 235530,
    4: 204126,
    5: 172722,
    "revenu brut": 1067000,
    trimf: 1500,
    1.5: 289040,
    2.5: 251232,
    3.5: 219828,
    4.5: 188424,
  },
  {
    1: 314410,
    2: 267248,
    3: 235807,
    4: 204366,
    5: 172925,
    "revenu brut": 1068000,
    trimf: 1500,
    1.5: 289410,
    2.5: 251528,
    3.5: 220087,
    4.5: 188646,
  },
  {
    1: 314780,
    2: 267563,
    3: 236085,
    4: 204607,
    5: 173129,
    "revenu brut": 1069000,
    trimf: 1500,
    1.5: 289780,
    2.5: 251824,
    3.5: 220346,
    4.5: 188868,
  },
  {
    1: 315150,
    2: 267877,
    3: 236362,
    4: 204847,
    5: 173332,
    "revenu brut": 1070000,
    trimf: 1500,
    1.5: 290150,
    2.5: 252120,
    3.5: 220605,
    4.5: 189090,
  },
  {
    1: 315520,
    2: 268192,
    3: 236640,
    4: 205088,
    5: 173536,
    "revenu brut": 1071_000,
    trimf: 1500,
    1.5: 290520,
    2.5: 252416,
    3.5: 220864,
    4.5: 189312,
  },
  {
    1: 315890,
    2: 268506,
    3: 236917,
    4: 205328,
    5: 173739,
    "revenu brut": 1072000,
    trimf: 1500,
    1.5: 290890,
    2.5: 252712,
    3.5: 221123,
    4.5: 189534,
  },
  {
    1: 316260,
    2: 268821,
    3: 237195,
    4: 205569,
    5: 173943,
    "revenu brut": 1073000,
    trimf: 1500,
    1.5: 291260,
    2.5: 253008,
    3.5: 221382,
    4.5: 189756,
  },
  {
    1: 316630,
    2: 269135,
    3: 237472,
    4: 205809,
    5: 174146,
    "revenu brut": 1074000,
    trimf: 1500,
    1.5: 291630,
    2.5: 253304,
    3.5: 221641,
    4.5: 189978,
  },
  {
    1: 317000,
    2: 269450,
    3: 237750,
    4: 206050,
    5: 174350,
    "revenu brut": 1075000,
    trimf: 1500,
    1.5: 292000,
    2.5: 253600,
    3.5: 221900,
    4.5: 190200,
  },
  {
    1: 317370,
    2: 269764,
    3: 238027,
    4: 206290,
    5: 174553,
    "revenu brut": 1076000,
    trimf: 1500,
    1.5: 292370,
    2.5: 253896,
    3.5: 222159,
    4.5: 190422,
  },
  {
    1: 317740,
    2: 270079,
    3: 238305,
    4: 206531,
    5: 174757,
    "revenu brut": 1077000,
    trimf: 1500,
    1.5: 292740,
    2.5: 254192,
    3.5: 222418,
    4.5: 190644,
  },
  {
    1: 318110,
    2: 270393,
    3: 238582,
    4: 206771,
    5: 174960,
    "revenu brut": 1078000,
    trimf: 1500,
    1.5: 293110,
    2.5: 254488,
    3.5: 222677,
    4.5: 190866,
  },
  {
    1: 318480,
    2: 270708,
    3: 238860,
    4: 207012,
    5: 175164,
    "revenu brut": 1079000,
    trimf: 1500,
    1.5: 293480,
    2.5: 254784,
    3.5: 222936,
    4.5: 191088,
  },
  {
    1: 318850,
    2: 271022,
    3: 239137,
    4: 207252,
    5: 175367,
    "revenu brut": 1080000,
    trimf: 1500,
    1.5: 293850,
    2.5: 255080,
    3.5: 223195,
    4.5: 191310,
  },
  {
    1: 319220,
    2: 271337,
    3: 239415,
    4: 207493,
    5: 175571,
    "revenu brut": 1081_000,
    trimf: 1500,
    1.5: 294220,
    2.5: 255376,
    3.5: 223454,
    4.5: 191532,
  },
  {
    1: 319590,
    2: 271651,
    3: 239692,
    4: 207733,
    5: 175774,
    "revenu brut": 1082000,
    trimf: 1500,
    1.5: 294590,
    2.5: 255672,
    3.5: 223713,
    4.5: 191754,
  },
  {
    1: 319960,
    2: 271966,
    3: 239970,
    4: 207974,
    5: 175978,
    "revenu brut": 1083000,
    trimf: 1500,
    1.5: 294960,
    2.5: 255968,
    3.5: 223972,
    4.5: 191976,
  },
  {
    1: 320330,
    2: 272280,
    3: 240247,
    4: 208214,
    5: 176181,
    "revenu brut": 1084000,
    trimf: 1500,
    1.5: 295330,
    2.5: 256264,
    3.5: 224231,
    4.5: 192198,
  },
  {
    1: 320700,
    2: 272595,
    3: 240525,
    4: 208455,
    5: 176385,
    "revenu brut": 1085000,
    trimf: 1500,
    1.5: 295700,
    2.5: 256560,
    3.5: 224490,
    4.5: 192420,
  },
  {
    1: 321070,
    2: 272909,
    3: 240802,
    4: 208695,
    5: 176588,
    "revenu brut": 1086000,
    trimf: 1500,
    1.5: 296070,
    2.5: 256856,
    3.5: 224749,
    4.5: 192642,
  },
  {
    1: 321440,
    2: 273224,
    3: 241080,
    4: 208936,
    5: 176792,
    "revenu brut": 1087000,
    trimf: 1500,
    1.5: 296440,
    2.5: 257152,
    3.5: 225008,
    4.5: 192864,
  },
  {
    1: 321810,
    2: 273538,
    3: 241357,
    4: 209176,
    5: 176995,
    "revenu brut": 1088000,
    trimf: 1500,
    1.5: 296810,
    2.5: 257448,
    3.5: 225267,
    4.5: 193086,
  },
  {
    1: 322180,
    2: 273853,
    3: 241635,
    4: 209417,
    5: 177199,
    "revenu brut": 1089000,
    trimf: 1500,
    1.5: 297180,
    2.5: 257744,
    3.5: 225526,
    4.5: 193308,
  },
  {
    1: 322550,
    2: 274167,
    3: 241912,
    4: 209657,
    5: 177402,
    "revenu brut": 1090000,
    trimf: 1500,
    1.5: 297550,
    2.5: 258040,
    3.5: 225785,
    4.5: 193530,
  },
  {
    1: 322920,
    2: 274482,
    3: 242190,
    4: 209898,
    5: 177606,
    "revenu brut": 1091_000,
    trimf: 1500,
    1.5: 297920,
    2.5: 258336,
    3.5: 226044,
    4.5: 193752,
  },
  {
    1: 323290,
    2: 274796,
    3: 242467,
    4: 210138,
    5: 177809,
    "revenu brut": 1092000,
    trimf: 1500,
    1.5: 298290,
    2.5: 258632,
    3.5: 226303,
    4.5: 193974,
  },
  {
    1: 323660,
    2: 275111,
    3: 242745,
    4: 210379,
    5: 178013,
    "revenu brut": 1093000,
    trimf: 1500,
    1.5: 298660,
    2.5: 258928,
    3.5: 226562,
    4.5: 194196,
  },
  {
    1: 324030,
    2: 275425,
    3: 243022,
    4: 210619,
    5: 178216,
    "revenu brut": 1094000,
    trimf: 1500,
    1.5: 299030,
    2.5: 259224,
    3.5: 226821,
    4.5: 194418,
  },
  {
    1: 324400,
    2: 275740,
    3: 243300,
    4: 210860,
    5: 178420,
    "revenu brut": 1095000,
    trimf: 1500,
    1.5: 299400,
    2.5: 259520,
    3.5: 227080,
    4.5: 194640,
  },
  {
    1: 324770,
    2: 276054,
    3: 243577,
    4: 211100,
    5: 178623,
    "revenu brut": 1096000,
    trimf: 1500,
    1.5: 299770,
    2.5: 259816,
    3.5: 227339,
    4.5: 194862,
  },
  {
    1: 325140,
    2: 276369,
    3: 243855,
    4: 211341,
    5: 178827,
    "revenu brut": 1097000,
    trimf: 1500,
    1.5: 300140,
    2.5: 260112,
    3.5: 227598,
    4.5: 195084,
  },
  {
    1: 325510,
    2: 276683,
    3: 244132,
    4: 211581,
    5: 179030,
    "revenu brut": 1098000,
    trimf: 1500,
    1.5: 300510,
    2.5: 260408,
    3.5: 227857,
    4.5: 195306,
  },
  {
    1: 325880,
    2: 276998,
    3: 244410,
    4: 211822,
    5: 179234,
    "revenu brut": 1099000,
    trimf: 1500,
    1.5: 300880,
    2.5: 260704,
    3.5: 228116,
    4.5: 195528,
  },
  {
    1: 326250,
    2: 277312,
    3: 244687,
    4: 212062,
    5: 179437,
    "revenu brut": 11_00000,
    trimf: 1500,
    1.5: 301250,
    2.5: 261_000,
    3.5: 228375,
    4.5: 195750,
  },
  {
    1: 326620,
    2: 277627,
    3: 244965,
    4: 212303,
    5: 179641,
    "revenu brut": 1101_000,
    trimf: 1500,
    1.5: 301620,
    2.5: 261296,
    3.5: 228634,
    4.5: 195972,
  },
  {
    1: 326990,
    2: 277941,
    3: 245242,
    4: 212543,
    5: 179844,
    "revenu brut": 1102000,
    trimf: 1500,
    1.5: 301990,
    2.5: 261592,
    3.5: 228893,
    4.5: 196194,
  },
  {
    1: 327360,
    2: 278256,
    3: 245520,
    4: 212784,
    5: 180048,
    "revenu brut": 1103000,
    trimf: 1500,
    1.5: 302360,
    2.5: 261888,
    3.5: 229152,
    4.5: 196416,
  },
  {
    1: 327730,
    2: 278570,
    3: 245797,
    4: 213024,
    5: 180251,
    "revenu brut": 1104000,
    trimf: 1500,
    1.5: 302730,
    2.5: 262184,
    3.5: 229411,
    4.5: 196638,
  },
  {
    1: 328100,
    2: 278885,
    3: 246075,
    4: 213265,
    5: 180455,
    "revenu brut": 1105000,
    trimf: 1500,
    1.5: 303100,
    2.5: 262480,
    3.5: 229670,
    4.5: 196860,
  },
  {
    1: 328470,
    2: 279199,
    3: 246352,
    4: 213505,
    5: 180658,
    "revenu brut": 1106000,
    trimf: 1500,
    1.5: 303470,
    2.5: 262776,
    3.5: 229929,
    4.5: 197082,
  },
  {
    1: 328840,
    2: 279514,
    3: 246630,
    4: 213746,
    5: 180862,
    "revenu brut": 1107000,
    trimf: 1500,
    1.5: 303840,
    2.5: 263072,
    3.5: 230188,
    4.5: 197304,
  },
  {
    1: 329210,
    2: 279828,
    3: 246907,
    4: 213986,
    5: 181065,
    "revenu brut": 1108000,
    trimf: 1500,
    1.5: 304210,
    2.5: 263368,
    3.5: 230447,
    4.5: 197526,
  },
  {
    1: 329580,
    2: 280143,
    3: 247185,
    4: 214227,
    5: 181269,
    "revenu brut": 1109000,
    trimf: 1500,
    1.5: 304580,
    2.5: 263664,
    3.5: 230706,
    4.5: 197748,
  },
  {
    1: 329950,
    2: 280457,
    3: 247462,
    4: 214467,
    5: 181472,
    "revenu brut": 111_0000,
    trimf: 1500,
    1.5: 304950,
    2.5: 263960,
    3.5: 230965,
    4.5: 197970,
  },
  {
    1: 330320,
    2: 280772,
    3: 247740,
    4: 214708,
    5: 181676,
    "revenu brut": 1111_000,
    trimf: 1500,
    1.5: 305320,
    2.5: 264256,
    3.5: 231224,
    4.5: 198192,
  },
  {
    1: 330690,
    2: 281086,
    3: 248017,
    4: 214948,
    5: 181879,
    "revenu brut": 1112000,
    trimf: 1500,
    1.5: 305690,
    2.5: 264552,
    3.5: 231483,
    4.5: 198414,
  },
  {
    1: 331060,
    2: 281401,
    3: 248295,
    4: 215189,
    5: 182083,
    "revenu brut": 1113000,
    trimf: 1500,
    1.5: 306060,
    2.5: 264848,
    3.5: 231742,
    4.5: 198636,
  },
  {
    1: 331430,
    2: 281715,
    3: 248572,
    4: 215429,
    5: 182286,
    "revenu brut": 1114000,
    trimf: 1500,
    1.5: 306430,
    2.5: 265144,
    3.5: 232001,
    4.5: 198858,
  },
  {
    1: 331800,
    2: 282030,
    3: 248850,
    4: 215670,
    5: 182490,
    "revenu brut": 1115000,
    trimf: 1500,
    1.5: 306800,
    2.5: 265440,
    3.5: 232260,
    4.5: 199080,
  },
  {
    1: 332170,
    2: 282344,
    3: 249127,
    4: 215910,
    5: 182693,
    "revenu brut": 1116000,
    trimf: 1500,
    1.5: 307170,
    2.5: 265736,
    3.5: 232519,
    4.5: 199302,
  },
  {
    1: 332540,
    2: 282659,
    3: 249405,
    4: 216151,
    5: 182897,
    "revenu brut": 1117000,
    trimf: 1500,
    1.5: 307540,
    2.5: 266032,
    3.5: 232778,
    4.5: 199524,
  },
  {
    1: 332910,
    2: 282973,
    3: 249682,
    4: 216391,
    5: 183100,
    "revenu brut": 1118000,
    trimf: 1500,
    1.5: 307910,
    2.5: 266328,
    3.5: 233037,
    4.5: 199746,
  },
  {
    1: 333280,
    2: 283288,
    3: 249960,
    4: 216632,
    5: 183304,
    "revenu brut": 1119000,
    trimf: 1500,
    1.5: 308280,
    2.5: 266624,
    3.5: 233296,
    4.5: 199968,
  },
  {
    1: 333650,
    2: 283602,
    3: 250237,
    4: 216872,
    5: 183507,
    "revenu brut": 1120000,
    trimf: 1500,
    1.5: 308650,
    2.5: 266920,
    3.5: 233555,
    4.5: 200190,
  },
  {
    1: 334020,
    2: 283917,
    3: 250515,
    4: 217113,
    5: 183711,
    "revenu brut": 1121_000,
    trimf: 1500,
    1.5: 309020,
    2.5: 267216,
    3.5: 233814,
    4.5: 200412,
  },
  {
    1: 334390,
    2: 284231,
    3: 250792,
    4: 217353,
    5: 183914,
    "revenu brut": 1122000,
    trimf: 1500,
    1.5: 309390,
    2.5: 267512,
    3.5: 234073,
    4.5: 200634,
  },
  {
    1: 334760,
    2: 284546,
    3: 251070,
    4: 217594,
    5: 184118,
    "revenu brut": 1123000,
    trimf: 1500,
    1.5: 309760,
    2.5: 267808,
    3.5: 234332,
    4.5: 200856,
  },
  {
    1: 335130,
    2: 284860,
    3: 251347,
    4: 217834,
    5: 184321,
    "revenu brut": 1124000,
    trimf: 1500,
    1.5: 310130,
    2.5: 268104,
    3.5: 234591,
    4.5: 201078,
  },
  {
    1: 335500,
    2: 285175,
    3: 251625,
    4: 218075,
    5: 184525,
    "revenu brut": 1125000,
    trimf: 1500,
    1.5: 310500,
    2.5: 268400,
    3.5: 234850,
    4.5: 201300,
  },
  {
    1: 335870,
    2: 285489,
    3: 251902,
    4: 218315,
    5: 184728,
    "revenu brut": 1126000,
    trimf: 1500,
    1.5: 310870,
    2.5: 268696,
    3.5: 235109,
    4.5: 201522,
  },
  {
    1: 336240,
    2: 285804,
    3: 252180,
    4: 218556,
    5: 184932,
    "revenu brut": 1127000,
    trimf: 1500,
    1.5: 311240,
    2.5: 268992,
    3.5: 235368,
    4.5: 201744,
  },
  {
    1: 336610,
    2: 286118,
    3: 252457,
    4: 218796,
    5: 185135,
    "revenu brut": 1128000,
    trimf: 1500,
    1.5: 311610,
    2.5: 269288,
    3.5: 235627,
    4.5: 201966,
  },
  {
    1: 336980,
    2: 286433,
    3: 252735,
    4: 219037,
    5: 185339,
    "revenu brut": 1129000,
    trimf: 1500,
    1.5: 311980,
    2.5: 269584,
    3.5: 235886,
    4.5: 202188,
  },
  {
    1: 337350,
    2: 286747,
    3: 253012,
    4: 219277,
    5: 185542,
    "revenu brut": 1130000,
    trimf: 1500,
    1.5: 312350,
    2.5: 269880,
    3.5: 236145,
    4.5: 202410,
  },
  {
    1: 337720,
    2: 287062,
    3: 253290,
    4: 219518,
    5: 185746,
    "revenu brut": 1131_000,
    trimf: 1500,
    1.5: 312720,
    2.5: 270176,
    3.5: 236404,
    4.5: 202632,
  },
  {
    1: 338090,
    2: 287376,
    3: 253567,
    4: 219758,
    5: 185949,
    "revenu brut": 1132000,
    trimf: 1500,
    1.5: 313090,
    2.5: 270472,
    3.5: 236663,
    4.5: 202854,
  },
  {
    1: 338460,
    2: 287691,
    3: 253845,
    4: 219999,
    5: 186153,
    "revenu brut": 1133000,
    trimf: 1500,
    1.5: 313460,
    2.5: 270768,
    3.5: 236922,
    4.5: 203076,
  },
  {
    1: 338830,
    2: 288005,
    3: 254122,
    4: 220239,
    5: 186356,
    "revenu brut": 1134000,
    trimf: 1500,
    1.5: 313830,
    2.5: 271064,
    3.5: 237181,
    4.5: 203298,
  },
  {
    1: 339200,
    2: 288320,
    3: 254400,
    4: 220480,
    5: 186560,
    "revenu brut": 1135000,
    trimf: 1500,
    1.5: 314200,
    2.5: 271360,
    3.5: 237440,
    4.5: 203520,
  },
  {
    1: 339570,
    2: 288634,
    3: 254677,
    4: 220720,
    5: 186763,
    "revenu brut": 1136000,
    trimf: 1500,
    1.5: 314570,
    2.5: 271656,
    3.5: 237699,
    4.5: 203742,
  },
  {
    1: 339940,
    2: 288949,
    3: 254955,
    4: 220961,
    5: 186967,
    "revenu brut": 1137000,
    trimf: 1500,
    1.5: 314940,
    2.5: 271952,
    3.5: 237958,
    4.5: 203964,
  },
  {
    1: 340310,
    2: 289263,
    3: 255232,
    4: 221201,
    5: 187170,
    "revenu brut": 1138000,
    trimf: 1500,
    1.5: 315310,
    2.5: 272248,
    3.5: 238217,
    4.5: 204186,
  },
  {
    1: 340680,
    2: 289578,
    3: 255510,
    4: 221442,
    5: 187374,
    "revenu brut": 1139000,
    trimf: 1500,
    1.5: 315680,
    2.5: 272544,
    3.5: 238476,
    4.5: 204408,
  },
  {
    1: 341050,
    2: 289892,
    3: 255787,
    4: 221682,
    5: 187577,
    "revenu brut": 1140000,
    trimf: 1500,
    1.5: 316050,
    2.5: 272840,
    3.5: 238735,
    4.5: 204630,
  },
  {
    1: 341420,
    2: 290207,
    3: 256065,
    4: 221923,
    5: 187781,
    "revenu brut": 1141_000,
    trimf: 1500,
    1.5: 316420,
    2.5: 273136,
    3.5: 238994,
    4.5: 204852,
  },
  {
    1: 341790,
    2: 290521,
    3: 256342,
    4: 222163,
    5: 187984,
    "revenu brut": 1142000,
    trimf: 1500,
    1.5: 316790,
    2.5: 273432,
    3.5: 239253,
    4.5: 205074,
  },
  {
    1: 342160,
    2: 290836,
    3: 256620,
    4: 222404,
    5: 188188,
    "revenu brut": 1143000,
    trimf: 1500,
    1.5: 317160,
    2.5: 273728,
    3.5: 239512,
    4.5: 205296,
  },
  {
    1: 342530,
    2: 291150,
    3: 256897,
    4: 222644,
    5: 188391,
    "revenu brut": 1144000,
    trimf: 1500,
    1.5: 317530,
    2.5: 274024,
    3.5: 239771,
    4.5: 205518,
  },
  {
    1: 342900,
    2: 291465,
    3: 257175,
    4: 222885,
    5: 188595,
    "revenu brut": 1145000,
    trimf: 1500,
    1.5: 317900,
    2.5: 274320,
    3.5: 240030,
    4.5: 205740,
  },
  {
    1: 343270,
    2: 291779,
    3: 257452,
    4: 223125,
    5: 188798,
    "revenu brut": 1146000,
    trimf: 1500,
    1.5: 318270,
    2.5: 274616,
    3.5: 240289,
    4.5: 205962,
  },
  {
    1: 343640,
    2: 292094,
    3: 257730,
    4: 223366,
    5: 189002,
    "revenu brut": 1147000,
    trimf: 1500,
    1.5: 318640,
    2.5: 274912,
    3.5: 240548,
    4.5: 206184,
  },
  {
    1: 344010,
    2: 292408,
    3: 258007,
    4: 223606,
    5: 189205,
    "revenu brut": 1148000,
    trimf: 1500,
    1.5: 319010,
    2.5: 275208,
    3.5: 240807,
    4.5: 206406,
  },
  {
    1: 344380,
    2: 292723,
    3: 258285,
    4: 223847,
    5: 189409,
    "revenu brut": 1149000,
    trimf: 1500,
    1.5: 319380,
    2.5: 275504,
    3.5: 241066,
    4.5: 206628,
  },
  {
    1: 344750,
    2: 293037,
    3: 258562,
    4: 224087,
    5: 189612,
    "revenu brut": 1150000,
    trimf: 1500,
    1.5: 319750,
    2.5: 275800,
    3.5: 241325,
    4.5: 206850,
  },
  {
    1: 345120,
    2: 293352,
    3: 258840,
    4: 224328,
    5: 189816,
    "revenu brut": 1151_000,
    trimf: 1500,
    1.5: 320120,
    2.5: 276096,
    3.5: 241584,
    4.5: 207072,
  },
  {
    1: 345490,
    2: 293666,
    3: 259117,
    4: 224568,
    5: 190019,
    "revenu brut": 1152000,
    trimf: 1500,
    1.5: 320490,
    2.5: 276392,
    3.5: 241843,
    4.5: 207294,
  },
  {
    1: 345860,
    2: 293981,
    3: 259395,
    4: 224809,
    5: 190223,
    "revenu brut": 1153000,
    trimf: 1500,
    1.5: 320860,
    2.5: 276688,
    3.5: 242102,
    4.5: 207516,
  },
  {
    1: 346230,
    2: 294295,
    3: 259672,
    4: 225049,
    5: 190426,
    "revenu brut": 1154000,
    trimf: 1500,
    1.5: 321230,
    2.5: 276984,
    3.5: 242361,
    4.5: 207738,
  },
  {
    1: 346600,
    2: 294610,
    3: 259950,
    4: 225290,
    5: 190630,
    "revenu brut": 1155000,
    trimf: 1500,
    1.5: 321600,
    2.5: 277280,
    3.5: 242620,
    4.5: 207960,
  },
  {
    1: 346970,
    2: 294924,
    3: 260227,
    4: 225530,
    5: 190833,
    "revenu brut": 1156000,
    trimf: 1500,
    1.5: 321970,
    2.5: 277576,
    3.5: 242879,
    4.5: 208182,
  },
  {
    1: 347340,
    2: 295239,
    3: 260505,
    4: 225771,
    5: 191037,
    "revenu brut": 1157000,
    trimf: 1500,
    1.5: 322340,
    2.5: 277872,
    3.5: 243138,
    4.5: 208404,
  },
  {
    1: 347710,
    2: 295553,
    3: 260782,
    4: 226011,
    5: 191240,
    "revenu brut": 1158000,
    trimf: 1500,
    1.5: 322710,
    2.5: 278168,
    3.5: 243397,
    4.5: 208626,
  },
  {
    1: 348080,
    2: 295868,
    3: 261060,
    4: 226252,
    5: 191444,
    "revenu brut": 1159000,
    trimf: 1500,
    1.5: 323080,
    2.5: 278464,
    3.5: 243656,
    4.5: 208848,
  },
  {
    1: 348450,
    2: 296182,
    3: 261337,
    4: 226492,
    5: 191647,
    "revenu brut": 1160000,
    trimf: 1500,
    1.5: 323450,
    2.5: 278760,
    3.5: 243915,
    4.5: 209070,
  },
  {
    1: 348820,
    2: 296497,
    3: 261615,
    4: 226733,
    5: 191851,
    "revenu brut": 1161_000,
    trimf: 1500,
    1.5: 323820,
    2.5: 279056,
    3.5: 244174,
    4.5: 209292,
  },
  {
    1: 349190,
    2: 296811,
    3: 261892,
    4: 226973,
    5: 192054,
    "revenu brut": 1162000,
    trimf: 1500,
    1.5: 324190,
    2.5: 279352,
    3.5: 244433,
    4.5: 209514,
  },
  {
    1: 349560,
    2: 297126,
    3: 262170,
    4: 227214,
    5: 192258,
    "revenu brut": 1163000,
    trimf: 1500,
    1.5: 324560,
    2.5: 279648,
    3.5: 244692,
    4.5: 209736,
  },
  {
    1: 349930,
    2: 297440,
    3: 262447,
    4: 227454,
    5: 192461,
    "revenu brut": 1164000,
    trimf: 1500,
    1.5: 324930,
    2.5: 279944,
    3.5: 244951,
    4.5: 209958,
  },
  {
    1: 350300,
    2: 297755,
    3: 262725,
    4: 227695,
    5: 192665,
    "revenu brut": 1165000,
    trimf: 1500,
    1.5: 325300,
    2.5: 280240,
    3.5: 245210,
    4.5: 210180,
  },
  {
    1: 350670,
    2: 298069,
    3: 263002,
    4: 227935,
    5: 192868,
    "revenu brut": 1166000,
    trimf: 1500,
    1.5: 325670,
    2.5: 280536,
    3.5: 245469,
    4.5: 210402,
  },
  {
    1: 351040,
    2: 298384,
    3: 263280,
    4: 228176,
    5: 193072,
    "revenu brut": 1167000,
    trimf: 1500,
    1.5: 326040,
    2.5: 280832,
    3.5: 245728,
    4.5: 210624,
  },
  {
    1: 351410,
    2: 298698,
    3: 263557,
    4: 228416,
    5: 193275,
    "revenu brut": 1168000,
    trimf: 1500,
    1.5: 326410,
    2.5: 281128,
    3.5: 245987,
    4.5: 210846,
  },
  {
    1: 351780,
    2: 299013,
    3: 263835,
    4: 228657,
    5: 193479,
    "revenu brut": 1169000,
    trimf: 1500,
    1.5: 326780,
    2.5: 281424,
    3.5: 246246,
    4.5: 211068,
  },
  {
    1: 352150,
    2: 299327,
    3: 264112,
    4: 228897,
    5: 193682,
    "revenu brut": 1170000,
    trimf: 1500,
    1.5: 327150,
    2.5: 281720,
    3.5: 246505,
    4.5: 211290,
  },
  {
    1: 352520,
    2: 299642,
    3: 264390,
    4: 229138,
    5: 193886,
    "revenu brut": 1171_000,
    trimf: 1500,
    1.5: 327520,
    2.5: 282016,
    3.5: 246764,
    4.5: 211512,
  },
  {
    1: 352890,
    2: 299956,
    3: 264667,
    4: 229378,
    5: 194089,
    "revenu brut": 1172000,
    trimf: 1500,
    1.5: 327890,
    2.5: 282312,
    3.5: 247023,
    4.5: 211734,
  },
  {
    1: 353260,
    2: 300271,
    3: 264945,
    4: 229619,
    5: 194293,
    "revenu brut": 1173000,
    trimf: 1500,
    1.5: 328260,
    2.5: 282608,
    3.5: 247282,
    4.5: 211956,
  },
  {
    1: 353630,
    2: 300585,
    3: 265222,
    4: 229859,
    5: 194496,
    "revenu brut": 1174000,
    trimf: 1500,
    1.5: 328630,
    2.5: 282904,
    3.5: 247541,
    4.5: 212178,
  },
  {
    1: 354000,
    2: 300900,
    3: 265500,
    4: 230100,
    5: 194700,
    "revenu brut": 1175000,
    trimf: 1500,
    1.5: 329000,
    2.5: 283200,
    3.5: 247800,
    4.5: 212400,
  },
  {
    1: 354370,
    2: 301214,
    3: 265777,
    4: 230340,
    5: 194903,
    "revenu brut": 1176000,
    trimf: 1500,
    1.5: 329370,
    2.5: 283496,
    3.5: 248059,
    4.5: 212622,
  },
  {
    1: 354740,
    2: 301529,
    3: 266055,
    4: 230581,
    5: 195107,
    "revenu brut": 1177000,
    trimf: 1500,
    1.5: 329740,
    2.5: 283792,
    3.5: 248318,
    4.5: 212844,
  },
  {
    1: 355110,
    2: 301843,
    3: 266332,
    4: 230821,
    5: 195310,
    "revenu brut": 1178000,
    trimf: 1500,
    1.5: 330110,
    2.5: 284088,
    3.5: 248577,
    4.5: 213066,
  },
  {
    1: 355480,
    2: 302158,
    3: 266610,
    4: 231062,
    5: 195514,
    "revenu brut": 1179000,
    trimf: 1500,
    1.5: 330480,
    2.5: 284384,
    3.5: 248836,
    4.5: 213288,
  },
  {
    1: 355850,
    2: 302472,
    3: 266887,
    4: 231302,
    5: 195717,
    "revenu brut": 1180000,
    trimf: 1500,
    1.5: 330850,
    2.5: 284680,
    3.5: 249095,
    4.5: 213510,
  },
  {
    1: 356220,
    2: 302787,
    3: 267165,
    4: 231543,
    5: 195921,
    "revenu brut": 1181_000,
    trimf: 1500,
    1.5: 331220,
    2.5: 284976,
    3.5: 249354,
    4.5: 213732,
  },
  {
    1: 356590,
    2: 303101,
    3: 267442,
    4: 231783,
    5: 196124,
    "revenu brut": 1182000,
    trimf: 1500,
    1.5: 331590,
    2.5: 285272,
    3.5: 249613,
    4.5: 213954,
  },
  {
    1: 356960,
    2: 303416,
    3: 267720,
    4: 232024,
    5: 196328,
    "revenu brut": 1183000,
    trimf: 1500,
    1.5: 331960,
    2.5: 285568,
    3.5: 249872,
    4.5: 214176,
  },
  {
    1: 357330,
    2: 303730,
    3: 267997,
    4: 232264,
    5: 196531,
    "revenu brut": 1184000,
    trimf: 1500,
    1.5: 332330,
    2.5: 285864,
    3.5: 250131,
    4.5: 214398,
  },
  {
    1: 357700,
    2: 304045,
    3: 268275,
    4: 232505,
    5: 196735,
    "revenu brut": 1185000,
    trimf: 1500,
    1.5: 332700,
    2.5: 286160,
    3.5: 250390,
    4.5: 214620,
  },
  {
    1: 358070,
    2: 304359,
    3: 268552,
    4: 232745,
    5: 196938,
    "revenu brut": 1186000,
    trimf: 1500,
    1.5: 333070,
    2.5: 286456,
    3.5: 250649,
    4.5: 214842,
  },
  {
    1: 358440,
    2: 304674,
    3: 268830,
    4: 232986,
    5: 197142,
    "revenu brut": 1187000,
    trimf: 1500,
    1.5: 333440,
    2.5: 286752,
    3.5: 250908,
    4.5: 215064,
  },
  {
    1: 358810,
    2: 304988,
    3: 269107,
    4: 233226,
    5: 197345,
    "revenu brut": 1188000,
    trimf: 1500,
    1.5: 333810,
    2.5: 287048,
    3.5: 251167,
    4.5: 215286,
  },
  {
    1: 359180,
    2: 305303,
    3: 269385,
    4: 233467,
    5: 197549,
    "revenu brut": 1189000,
    trimf: 1500,
    1.5: 334180,
    2.5: 287344,
    3.5: 251426,
    4.5: 215508,
  },
  {
    1: 359550,
    2: 305617,
    3: 269662,
    4: 233707,
    5: 197752,
    "revenu brut": 1190000,
    trimf: 1500,
    1.5: 334550,
    2.5: 287640,
    3.5: 251685,
    4.5: 215730,
  },
  {
    1: 359920,
    2: 305932,
    3: 269940,
    4: 233948,
    5: 197956,
    "revenu brut": 1191_000,
    trimf: 1500,
    1.5: 334920,
    2.5: 287936,
    3.5: 251944,
    4.5: 215952,
  },
  {
    1: 360290,
    2: 306246,
    3: 270217,
    4: 234188,
    5: 198159,
    "revenu brut": 1192000,
    trimf: 1500,
    1.5: 335290,
    2.5: 288232,
    3.5: 252203,
    4.5: 216174,
  },
  {
    1: 360660,
    2: 306561,
    3: 270495,
    4: 234429,
    5: 198363,
    "revenu brut": 1193000,
    trimf: 1500,
    1.5: 335660,
    2.5: 288528,
    3.5: 252462,
    4.5: 216396,
  },
  {
    1: 361030,
    2: 306875,
    3: 270772,
    4: 234669,
    5: 198566,
    "revenu brut": 1194000,
    trimf: 1500,
    1.5: 336030,
    2.5: 288824,
    3.5: 252721,
    4.5: 216618,
  },
  {
    1: 361400,
    2: 307233,
    3: 271050,
    4: 234910,
    5: 198770,
    "revenu brut": 1195000,
    trimf: 1500,
    1.5: 336400,
    2.5: 289120,
    3.5: 252980,
    4.5: 216840,
  },
  {
    1: 361770,
    2: 307603,
    3: 271327,
    4: 235150,
    5: 198973,
    "revenu brut": 1196000,
    trimf: 1500,
    1.5: 336770,
    2.5: 289416,
    3.5: 253239,
    4.5: 217062,
  },
  {
    1: 362140,
    2: 307973,
    3: 271605,
    4: 235391,
    5: 199177,
    "revenu brut": 1197000,
    trimf: 1500,
    1.5: 337140,
    2.5: 289712,
    3.5: 253498,
    4.5: 217284,
  },
  {
    1: 362510,
    2: 308343,
    3: 271882,
    4: 235631,
    5: 199380,
    "revenu brut": 1198000,
    trimf: 1500,
    1.5: 337510,
    2.5: 290008,
    3.5: 253757,
    4.5: 217506,
  },
  {
    1: 362880,
    2: 308713,
    3: 272160,
    4: 235872,
    5: 199584,
    "revenu brut": 1199000,
    trimf: 1500,
    1.5: 337880,
    2.5: 290304,
    3.5: 254016,
    4.5: 217728,
  },
  {
    1: 363250,
    2: 309083,
    3: 272437,
    4: 236112,
    5: 199787,
    "revenu brut": 1200000,
    trimf: 1500,
    1.5: 338250,
    2.5: 290600,
    3.5: 254275,
    4.5: 217950,
  },
  {
    1: 363650,
    2: 309483,
    3: 272737,
    4: 236372,
    5: 200007,
    "revenu brut": 1201_000,
    trimf: 1500,
    1.5: 338650,
    2.5: 290920,
    3.5: 254555,
    4.5: 218190,
  },
  {
    1: 364050,
    2: 309883,
    3: 273037,
    4: 236632,
    5: 200227,
    "revenu brut": 1202000,
    trimf: 1500,
    1.5: 339050,
    2.5: 291240,
    3.5: 254835,
    4.5: 218430,
  },
  {
    1: 364450,
    2: 310283,
    3: 273337,
    4: 236892,
    5: 200447,
    "revenu brut": 1203000,
    trimf: 1500,
    1.5: 339450,
    2.5: 291560,
    3.5: 255115,
    4.5: 218670,
  },
  {
    1: 364850,
    2: 310683,
    3: 273637,
    4: 237152,
    5: 200667,
    "revenu brut": 1204000,
    trimf: 1500,
    1.5: 339850,
    2.5: 291880,
    3.5: 255395,
    4.5: 218910,
  },
  {
    1: 365250,
    2: 311083,
    3: 273937,
    4: 237412,
    5: 200887,
    "revenu brut": 1205000,
    trimf: 1500,
    1.5: 340250,
    2.5: 292200,
    3.5: 255675,
    4.5: 219150,
  },
  {
    1: 365650,
    2: 311483,
    3: 274237,
    4: 237672,
    5: 201107,
    "revenu brut": 1206000,
    trimf: 1500,
    1.5: 340650,
    2.5: 292520,
    3.5: 255955,
    4.5: 219390,
  },
  {
    1: 366050,
    2: 311883,
    3: 274537,
    4: 237932,
    5: 201327,
    "revenu brut": 1207000,
    trimf: 1500,
    1.5: 341050,
    2.5: 292840,
    3.5: 256235,
    4.5: 219630,
  },
  {
    1: 366450,
    2: 312283,
    3: 274837,
    4: 238192,
    5: 201547,
    "revenu brut": 1208000,
    trimf: 1500,
    1.5: 341450,
    2.5: 293160,
    3.5: 256515,
    4.5: 219870,
  },
  {
    1: 366850,
    2: 312683,
    3: 275137,
    4: 238452,
    5: 201767,
    "revenu brut": 1209000,
    trimf: 1500,
    1.5: 341850,
    2.5: 293480,
    3.5: 256795,
    4.5: 220110,
  },
  {
    1: 367250,
    2: 313083,
    3: 275437,
    4: 238712,
    5: 201987,
    "revenu brut": 121_0000,
    trimf: 1500,
    1.5: 342250,
    2.5: 293800,
    3.5: 257075,
    4.5: 220350,
  },
  {
    1: 367650,
    2: 313483,
    3: 275737,
    4: 238972,
    5: 202207,
    "revenu brut": 1211_000,
    trimf: 1500,
    1.5: 342650,
    2.5: 294120,
    3.5: 257355,
    4.5: 220590,
  },
  {
    1: 368050,
    2: 313883,
    3: 276037,
    4: 239232,
    5: 202427,
    "revenu brut": 1212000,
    trimf: 1500,
    1.5: 343050,
    2.5: 294440,
    3.5: 257635,
    4.5: 220830,
  },
  {
    1: 368450,
    2: 314283,
    3: 276337,
    4: 239492,
    5: 202647,
    "revenu brut": 1213000,
    trimf: 1500,
    1.5: 343450,
    2.5: 294760,
    3.5: 257915,
    4.5: 221070,
  },
  {
    1: 368850,
    2: 314683,
    3: 276637,
    4: 239752,
    5: 202867,
    "revenu brut": 1214000,
    trimf: 1500,
    1.5: 343850,
    2.5: 295080,
    3.5: 258195,
    4.5: 221310,
  },
  {
    1: 369250,
    2: 315083,
    3: 276937,
    4: 240012,
    5: 203087,
    "revenu brut": 1215000,
    trimf: 1500,
    1.5: 344250,
    2.5: 295400,
    3.5: 258475,
    4.5: 221550,
  },
  {
    1: 369650,
    2: 315483,
    3: 277237,
    4: 240272,
    5: 203307,
    "revenu brut": 1216000,
    trimf: 1500,
    1.5: 344650,
    2.5: 295720,
    3.5: 258755,
    4.5: 221790,
  },
  {
    1: 370050,
    2: 315883,
    3: 277537,
    4: 240532,
    5: 203527,
    "revenu brut": 1217000,
    trimf: 1500,
    1.5: 345050,
    2.5: 296040,
    3.5: 259035,
    4.5: 222030,
  },
  {
    1: 370450,
    2: 316283,
    3: 277837,
    4: 240792,
    5: 203747,
    "revenu brut": 1218000,
    trimf: 1500,
    1.5: 345450,
    2.5: 296360,
    3.5: 259315,
    4.5: 222270,
  },
  {
    1: 370850,
    2: 316683,
    3: 278137,
    4: 241052,
    5: 203967,
    "revenu brut": 1219000,
    trimf: 1500,
    1.5: 345850,
    2.5: 296680,
    3.5: 259595,
    4.5: 222510,
  },
  {
    1: 371250,
    2: 317083,
    3: 278437,
    4: 241312,
    5: 204187,
    "revenu brut": 1220000,
    trimf: 1500,
    1.5: 346250,
    2.5: 297000,
    3.5: 259875,
    4.5: 222750,
  },
  {
    1: 371650,
    2: 317483,
    3: 278737,
    4: 241572,
    5: 204407,
    "revenu brut": 1221_000,
    trimf: 1500,
    1.5: 346650,
    2.5: 297320,
    3.5: 260155,
    4.5: 222990,
  },
  {
    1: 372050,
    2: 317883,
    3: 279037,
    4: 241832,
    5: 204627,
    "revenu brut": 1222000,
    trimf: 1500,
    1.5: 347050,
    2.5: 297640,
    3.5: 260435,
    4.5: 223230,
  },
  {
    1: 372450,
    2: 318283,
    3: 279337,
    4: 242092,
    5: 204847,
    "revenu brut": 1223000,
    trimf: 1500,
    1.5: 347450,
    2.5: 297960,
    3.5: 260715,
    4.5: 223470,
  },
  {
    1: 372850,
    2: 318683,
    3: 279637,
    4: 242352,
    5: 205067,
    "revenu brut": 1224000,
    trimf: 1500,
    1.5: 347850,
    2.5: 298280,
    3.5: 260995,
    4.5: 223710,
  },
  {
    1: 373250,
    2: 319083,
    3: 279937,
    4: 242612,
    5: 205287,
    "revenu brut": 1225000,
    trimf: 1500,
    1.5: 348250,
    2.5: 298600,
    3.5: 261275,
    4.5: 223950,
  },
  {
    1: 373650,
    2: 319483,
    3: 280237,
    4: 242872,
    5: 205507,
    "revenu brut": 1226000,
    trimf: 1500,
    1.5: 348650,
    2.5: 298920,
    3.5: 261555,
    4.5: 224190,
  },
  {
    1: 374050,
    2: 319883,
    3: 280537,
    4: 243132,
    5: 205727,
    "revenu brut": 1227000,
    trimf: 1500,
    1.5: 349050,
    2.5: 299240,
    3.5: 261835,
    4.5: 224430,
  },
  {
    1: 374450,
    2: 320283,
    3: 280837,
    4: 243392,
    5: 205947,
    "revenu brut": 1228000,
    trimf: 1500,
    1.5: 349450,
    2.5: 299560,
    3.5: 262115,
    4.5: 224670,
  },
  {
    1: 374850,
    2: 320683,
    3: 281137,
    4: 243652,
    5: 206167,
    "revenu brut": 1229000,
    trimf: 1500,
    1.5: 349850,
    2.5: 299880,
    3.5: 262395,
    4.5: 224910,
  },
  {
    1: 375250,
    2: 321083,
    3: 281437,
    4: 243912,
    5: 206387,
    "revenu brut": 1230000,
    trimf: 1500,
    1.5: 350250,
    2.5: 300200,
    3.5: 262675,
    4.5: 225150,
  },
  {
    1: 375650,
    2: 321483,
    3: 281737,
    4: 244172,
    5: 206607,
    "revenu brut": 1231_000,
    trimf: 1500,
    1.5: 350650,
    2.5: 300520,
    3.5: 262955,
    4.5: 225390,
  },
  {
    1: 376050,
    2: 321883,
    3: 282037,
    4: 244432,
    5: 206827,
    "revenu brut": 1232000,
    trimf: 1500,
    1.5: 351050,
    2.5: 300840,
    3.5: 263235,
    4.5: 225630,
  },
  {
    1: 376450,
    2: 322283,
    3: 282337,
    4: 244692,
    5: 207047,
    "revenu brut": 1233000,
    trimf: 1500,
    1.5: 351450,
    2.5: 301160,
    3.5: 263515,
    4.5: 225870,
  },
  {
    1: 376850,
    2: 322683,
    3: 282637,
    4: 244952,
    5: 207267,
    "revenu brut": 1234000,
    trimf: 1500,
    1.5: 351850,
    2.5: 301480,
    3.5: 263795,
    4.5: 226110,
  },
  {
    1: 377250,
    2: 323083,
    3: 282937,
    4: 245212,
    5: 207487,
    "revenu brut": 1235000,
    trimf: 1500,
    1.5: 352250,
    2.5: 301800,
    3.5: 264075,
    4.5: 226350,
  },
  {
    1: 377650,
    2: 323483,
    3: 283237,
    4: 245472,
    5: 207707,
    "revenu brut": 1236000,
    trimf: 1500,
    1.5: 352650,
    2.5: 302120,
    3.5: 264355,
    4.5: 226590,
  },
  {
    1: 378050,
    2: 323883,
    3: 283537,
    4: 245732,
    5: 207927,
    "revenu brut": 1237000,
    trimf: 1500,
    1.5: 353050,
    2.5: 302440,
    3.5: 264635,
    4.5: 226830,
  },
  {
    1: 378450,
    2: 324283,
    3: 283837,
    4: 245992,
    5: 208147,
    "revenu brut": 1238000,
    trimf: 1500,
    1.5: 353450,
    2.5: 302760,
    3.5: 264915,
    4.5: 227070,
  },
  {
    1: 378850,
    2: 324683,
    3: 284137,
    4: 246252,
    5: 208367,
    "revenu brut": 1239000,
    trimf: 1500,
    1.5: 353850,
    2.5: 303080,
    3.5: 265195,
    4.5: 227310,
  },
  {
    1: 379250,
    2: 325083,
    3: 284437,
    4: 246512,
    5: 208587,
    "revenu brut": 1240000,
    trimf: 1500,
    1.5: 354250,
    2.5: 303400,
    3.5: 265475,
    4.5: 227550,
  },
  {
    1: 379650,
    2: 325483,
    3: 284737,
    4: 246772,
    5: 208807,
    "revenu brut": 1241_000,
    trimf: 1500,
    1.5: 354650,
    2.5: 303720,
    3.5: 265755,
    4.5: 227790,
  },
  {
    1: 380050,
    2: 325883,
    3: 285037,
    4: 247032,
    5: 209027,
    "revenu brut": 1242000,
    trimf: 1500,
    1.5: 355050,
    2.5: 304040,
    3.5: 266035,
    4.5: 228030,
  },
  {
    1: 380450,
    2: 326283,
    3: 285337,
    4: 247292,
    5: 209247,
    "revenu brut": 1243000,
    trimf: 1500,
    1.5: 355450,
    2.5: 304360,
    3.5: 266315,
    4.5: 228270,
  },
  {
    1: 380850,
    2: 326683,
    3: 285637,
    4: 247552,
    5: 209467,
    "revenu brut": 1244000,
    trimf: 1500,
    1.5: 355850,
    2.5: 304680,
    3.5: 266595,
    4.5: 228510,
  },
  {
    1: 381250,
    2: 327083,
    3: 285937,
    4: 247812,
    5: 209687,
    "revenu brut": 1245000,
    trimf: 1500,
    1.5: 356250,
    2.5: 305000,
    3.5: 266875,
    4.5: 228750,
  },
  {
    1: 381650,
    2: 327483,
    3: 286237,
    4: 248072,
    5: 209907,
    "revenu brut": 1246000,
    trimf: 1500,
    1.5: 356650,
    2.5: 305320,
    3.5: 267155,
    4.5: 228990,
  },
  {
    1: 382050,
    2: 327883,
    3: 286537,
    4: 248332,
    5: 210127,
    "revenu brut": 1247000,
    trimf: 1500,
    1.5: 357050,
    2.5: 305640,
    3.5: 267435,
    4.5: 229230,
  },
  {
    1: 382450,
    2: 328283,
    3: 286837,
    4: 248592,
    5: 210347,
    "revenu brut": 1248000,
    trimf: 1500,
    1.5: 357450,
    2.5: 305960,
    3.5: 267715,
    4.5: 229470,
  },
  {
    1: 382850,
    2: 328683,
    3: 287137,
    4: 248852,
    5: 210567,
    "revenu brut": 1249000,
    trimf: 1500,
    1.5: 357850,
    2.5: 306280,
    3.5: 267995,
    4.5: 229710,
  },
  {
    1: 383250,
    2: 329083,
    3: 287437,
    4: 249112,
    5: 210787,
    "revenu brut": 1250000,
    trimf: 1500,
    1.5: 358250,
    2.5: 306600,
    3.5: 268275,
    4.5: 229950,
  },
  {
    1: 383650,
    2: 329483,
    3: 287737,
    4: 249372,
    5: 211007,
    "revenu brut": 1251_000,
    trimf: 1500,
    1.5: 358650,
    2.5: 306920,
    3.5: 268555,
    4.5: 230190,
  },
  {
    1: 384050,
    2: 329883,
    3: 288037,
    4: 249632,
    5: 211227,
    "revenu brut": 1252000,
    trimf: 1500,
    1.5: 359050,
    2.5: 307240,
    3.5: 268835,
    4.5: 230430,
  },
  {
    1: 384450,
    2: 330283,
    3: 288337,
    4: 249892,
    5: 211447,
    "revenu brut": 1253000,
    trimf: 1500,
    1.5: 359450,
    2.5: 307560,
    3.5: 269115,
    4.5: 230670,
  },
  {
    1: 384850,
    2: 330683,
    3: 288637,
    4: 250152,
    5: 211667,
    "revenu brut": 1254000,
    trimf: 1500,
    1.5: 359850,
    2.5: 307880,
    3.5: 269395,
    4.5: 230910,
  },
  {
    1: 385250,
    2: 331083,
    3: 288937,
    4: 250412,
    5: 211887,
    "revenu brut": 1255000,
    trimf: 1500,
    1.5: 360250,
    2.5: 308200,
    3.5: 269675,
    4.5: 231150,
  },
  {
    1: 385650,
    2: 331483,
    3: 289237,
    4: 250672,
    5: 212107,
    "revenu brut": 1256000,
    trimf: 1500,
    1.5: 360650,
    2.5: 308520,
    3.5: 269955,
    4.5: 231390,
  },
  {
    1: 386050,
    2: 331883,
    3: 289537,
    4: 250932,
    5: 212327,
    "revenu brut": 1257000,
    trimf: 1500,
    1.5: 361050,
    2.5: 308840,
    3.5: 270235,
    4.5: 231630,
  },
  {
    1: 386450,
    2: 332283,
    3: 289837,
    4: 251192,
    5: 212547,
    "revenu brut": 1258000,
    trimf: 1500,
    1.5: 361450,
    2.5: 309160,
    3.5: 270515,
    4.5: 231870,
  },
  {
    1: 386850,
    2: 332683,
    3: 290137,
    4: 251452,
    5: 212767,
    "revenu brut": 1259000,
    trimf: 1500,
    1.5: 361850,
    2.5: 309480,
    3.5: 270795,
    4.5: 232110,
  },
  {
    1: 387250,
    2: 333083,
    3: 290437,
    4: 251712,
    5: 212987,
    "revenu brut": 1260000,
    trimf: 1500,
    1.5: 362250,
    2.5: 309800,
    3.5: 271075,
    4.5: 232350,
  },
  {
    1: 387650,
    2: 333483,
    3: 290737,
    4: 251972,
    5: 213207,
    "revenu brut": 1261_000,
    trimf: 1500,
    1.5: 362650,
    2.5: 310120,
    3.5: 271355,
    4.5: 232590,
  },
  {
    1: 388050,
    2: 333883,
    3: 291037,
    4: 252232,
    5: 213427,
    "revenu brut": 1262000,
    trimf: 1500,
    1.5: 363050,
    2.5: 310440,
    3.5: 271635,
    4.5: 232830,
  },
  {
    1: 388450,
    2: 334283,
    3: 291337,
    4: 252492,
    5: 213647,
    "revenu brut": 1263000,
    trimf: 1500,
    1.5: 363450,
    2.5: 310760,
    3.5: 271915,
    4.5: 233070,
  },
  {
    1: 388850,
    2: 334683,
    3: 291637,
    4: 252752,
    5: 213867,
    "revenu brut": 1264000,
    trimf: 1500,
    1.5: 363850,
    2.5: 311080,
    3.5: 272195,
    4.5: 233310,
  },
  {
    1: 389250,
    2: 335083,
    3: 291937,
    4: 253012,
    5: 214087,
    "revenu brut": 1265000,
    trimf: 1500,
    1.5: 364250,
    2.5: 311400,
    3.5: 272475,
    4.5: 233550,
  },
  {
    1: 389650,
    2: 335483,
    3: 292237,
    4: 253272,
    5: 214307,
    "revenu brut": 1266000,
    trimf: 1500,
    1.5: 364650,
    2.5: 311720,
    3.5: 272755,
    4.5: 233790,
  },
  {
    1: 390050,
    2: 335883,
    3: 292537,
    4: 253532,
    5: 214527,
    "revenu brut": 1267000,
    trimf: 1500,
    1.5: 365050,
    2.5: 312040,
    3.5: 273035,
    4.5: 234030,
  },
  {
    1: 390450,
    2: 336283,
    3: 292837,
    4: 253792,
    5: 214747,
    "revenu brut": 1268000,
    trimf: 1500,
    1.5: 365450,
    2.5: 312360,
    3.5: 273315,
    4.5: 234270,
  },
  {
    1: 390850,
    2: 336683,
    3: 293137,
    4: 254052,
    5: 214967,
    "revenu brut": 1269000,
    trimf: 1500,
    1.5: 365850,
    2.5: 312680,
    3.5: 273595,
    4.5: 234510,
  },
  {
    1: 391250,
    2: 337083,
    3: 293437,
    4: 254312,
    5: 215187,
    "revenu brut": 1270000,
    trimf: 1500,
    1.5: 366250,
    2.5: 313000,
    3.5: 273875,
    4.5: 234750,
  },
  {
    1: 391650,
    2: 337483,
    3: 293737,
    4: 254572,
    5: 215407,
    "revenu brut": 1271_000,
    trimf: 1500,
    1.5: 366650,
    2.5: 313320,
    3.5: 274155,
    4.5: 234990,
  },
  {
    1: 392050,
    2: 337883,
    3: 294037,
    4: 254832,
    5: 215627,
    "revenu brut": 1272000,
    trimf: 1500,
    1.5: 367050,
    2.5: 313640,
    3.5: 274435,
    4.5: 235230,
  },
  {
    1: 392450,
    2: 338283,
    3: 294337,
    4: 255092,
    5: 215847,
    "revenu brut": 1273000,
    trimf: 1500,
    1.5: 367450,
    2.5: 313960,
    3.5: 274715,
    4.5: 235470,
  },
  {
    1: 392850,
    2: 338683,
    3: 294637,
    4: 255352,
    5: 216067,
    "revenu brut": 1274000,
    trimf: 1500,
    1.5: 367850,
    2.5: 314280,
    3.5: 274995,
    4.5: 235710,
  },
  {
    1: 393250,
    2: 339083,
    3: 294937,
    4: 255612,
    5: 216287,
    "revenu brut": 1275000,
    trimf: 1500,
    1.5: 368250,
    2.5: 314600,
    3.5: 275275,
    4.5: 235950,
  },
  {
    1: 393650,
    2: 339483,
    3: 295237,
    4: 255872,
    5: 216507,
    "revenu brut": 1276000,
    trimf: 1500,
    1.5: 368650,
    2.5: 314920,
    3.5: 275555,
    4.5: 236190,
  },
  {
    1: 394050,
    2: 339883,
    3: 295537,
    4: 256132,
    5: 216727,
    "revenu brut": 1277000,
    trimf: 1500,
    1.5: 369050,
    2.5: 315240,
    3.5: 275835,
    4.5: 236430,
  },
  {
    1: 394450,
    2: 340283,
    3: 295837,
    4: 256392,
    5: 216947,
    "revenu brut": 1278000,
    trimf: 1500,
    1.5: 369450,
    2.5: 315560,
    3.5: 276115,
    4.5: 236670,
  },
  {
    1: 394850,
    2: 340683,
    3: 296137,
    4: 256652,
    5: 217167,
    "revenu brut": 1279000,
    trimf: 1500,
    1.5: 369850,
    2.5: 315880,
    3.5: 276395,
    4.5: 236910,
  },
  {
    1: 395250,
    2: 341083,
    3: 296437,
    4: 256912,
    5: 217387,
    "revenu brut": 1280000,
    trimf: 1500,
    1.5: 370250,
    2.5: 316200,
    3.5: 276675,
    4.5: 237150,
  },
  {
    1: 395650,
    2: 341483,
    3: 296737,
    4: 257172,
    5: 217607,
    "revenu brut": 1281_000,
    trimf: 1500,
    1.5: 370650,
    2.5: 316520,
    3.5: 276955,
    4.5: 237390,
  },
  {
    1: 396050,
    2: 341883,
    3: 297037,
    4: 257432,
    5: 217827,
    "revenu brut": 1282000,
    trimf: 1500,
    1.5: 371050,
    2.5: 316840,
    3.5: 277235,
    4.5: 237630,
  },
  {
    1: 396450,
    2: 342283,
    3: 297337,
    4: 257692,
    5: 218047,
    "revenu brut": 1283000,
    trimf: 1500,
    1.5: 371450,
    2.5: 317160,
    3.5: 277515,
    4.5: 237870,
  },
  {
    1: 396850,
    2: 342683,
    3: 297637,
    4: 257952,
    5: 218267,
    "revenu brut": 1284000,
    trimf: 1500,
    1.5: 371850,
    2.5: 317480,
    3.5: 277795,
    4.5: 238110,
  },
  {
    1: 397250,
    2: 343083,
    3: 297937,
    4: 258212,
    5: 218487,
    "revenu brut": 1285000,
    trimf: 1500,
    1.5: 372250,
    2.5: 317800,
    3.5: 278075,
    4.5: 238350,
  },
  {
    1: 397650,
    2: 343483,
    3: 298237,
    4: 258472,
    5: 218707,
    "revenu brut": 1286000,
    trimf: 1500,
    1.5: 372650,
    2.5: 318120,
    3.5: 278355,
    4.5: 238590,
  },
  {
    1: 398050,
    2: 343883,
    3: 298537,
    4: 258732,
    5: 218927,
    "revenu brut": 1287000,
    trimf: 1500,
    1.5: 373050,
    2.5: 318440,
    3.5: 278635,
    4.5: 238830,
  },
  {
    1: 398450,
    2: 344283,
    3: 298837,
    4: 258992,
    5: 219147,
    "revenu brut": 1288000,
    trimf: 1500,
    1.5: 373450,
    2.5: 318760,
    3.5: 278915,
    4.5: 239070,
  },
  {
    1: 398850,
    2: 344683,
    3: 299137,
    4: 259252,
    5: 219367,
    "revenu brut": 1289000,
    trimf: 1500,
    1.5: 373850,
    2.5: 319080,
    3.5: 279195,
    4.5: 239310,
  },
  {
    1: 399250,
    2: 345083,
    3: 299437,
    4: 259512,
    5: 219587,
    "revenu brut": 1290000,
    trimf: 1500,
    1.5: 374250,
    2.5: 319400,
    3.5: 279475,
    4.5: 239550,
  },
  {
    1: 399650,
    2: 345483,
    3: 299737,
    4: 259772,
    5: 219807,
    "revenu brut": 1291_000,
    trimf: 1500,
    1.5: 374650,
    2.5: 319720,
    3.5: 279755,
    4.5: 239790,
  },
  {
    1: 400050,
    2: 345883,
    3: 300037,
    4: 260032,
    5: 220027,
    "revenu brut": 1292000,
    trimf: 1500,
    1.5: 375050,
    2.5: 320040,
    3.5: 280035,
    4.5: 240030,
  },
  {
    1: 400450,
    2: 346283,
    3: 300337,
    4: 260292,
    5: 220247,
    "revenu brut": 1293000,
    trimf: 1500,
    1.5: 375450,
    2.5: 320360,
    3.5: 280315,
    4.5: 240270,
  },
  {
    1: 400850,
    2: 346683,
    3: 300637,
    4: 260552,
    5: 220467,
    "revenu brut": 1294000,
    trimf: 1500,
    1.5: 375850,
    2.5: 320680,
    3.5: 280595,
    4.5: 240510,
  },
  {
    1: 401250,
    2: 347083,
    3: 300937,
    4: 260812,
    5: 220687,
    "revenu brut": 1295000,
    trimf: 1500,
    1.5: 376250,
    2.5: 321_000,
    3.5: 280875,
    4.5: 240750,
  },
  {
    1: 401650,
    2: 347483,
    3: 301237,
    4: 261072,
    5: 220907,
    "revenu brut": 1296000,
    trimf: 1500,
    1.5: 376650,
    2.5: 321320,
    3.5: 281155,
    4.5: 240990,
  },
  {
    1: 402050,
    2: 347883,
    3: 301537,
    4: 261332,
    5: 221127,
    "revenu brut": 1297000,
    trimf: 1500,
    1.5: 377050,
    2.5: 321640,
    3.5: 281435,
    4.5: 241230,
  },
  {
    1: 402450,
    2: 348283,
    3: 301837,
    4: 261592,
    5: 221347,
    "revenu brut": 1298000,
    trimf: 1500,
    1.5: 377450,
    2.5: 321960,
    3.5: 281715,
    4.5: 241470,
  },
  {
    1: 402850,
    2: 348683,
    3: 302137,
    4: 261852,
    5: 221567,
    "revenu brut": 1299000,
    trimf: 1500,
    1.5: 377850,
    2.5: 322280,
    3.5: 281995,
    4.5: 241710,
  },
  {
    1: 403250,
    2: 349083,
    3: 302437,
    4: 262112,
    5: 221787,
    "revenu brut": 1300000,
    trimf: 1500,
    1.5: 378250,
    2.5: 322600,
    3.5: 282275,
    4.5: 241950,
  },
  {
    1: 403650,
    2: 349483,
    3: 302737,
    4: 262372,
    5: 222007,
    "revenu brut": 1301_000,
    trimf: 1500,
    1.5: 378650,
    2.5: 322920,
    3.5: 282555,
    4.5: 242190,
  },
  {
    1: 404050,
    2: 349883,
    3: 303037,
    4: 262632,
    5: 222227,
    "revenu brut": 1302000,
    trimf: 1500,
    1.5: 379050,
    2.5: 323240,
    3.5: 282835,
    4.5: 242430,
  },
  {
    1: 404450,
    2: 350283,
    3: 303337,
    4: 262892,
    5: 222447,
    "revenu brut": 1303000,
    trimf: 1500,
    1.5: 379450,
    2.5: 323560,
    3.5: 283115,
    4.5: 242670,
  },
  {
    1: 404850,
    2: 350683,
    3: 303637,
    4: 263152,
    5: 222667,
    "revenu brut": 1304000,
    trimf: 1500,
    1.5: 379850,
    2.5: 323880,
    3.5: 283395,
    4.5: 242910,
  },
  {
    1: 405250,
    2: 351083,
    3: 303937,
    4: 263412,
    5: 222887,
    "revenu brut": 1305000,
    trimf: 1500,
    1.5: 380250,
    2.5: 324200,
    3.5: 283675,
    4.5: 243150,
  },
  {
    1: 405650,
    2: 351483,
    3: 304237,
    4: 263672,
    5: 223107,
    "revenu brut": 1306000,
    trimf: 1500,
    1.5: 380650,
    2.5: 324520,
    3.5: 283955,
    4.5: 243390,
  },
  {
    1: 406050,
    2: 351883,
    3: 304537,
    4: 263932,
    5: 223327,
    "revenu brut": 1307000,
    trimf: 1500,
    1.5: 381050,
    2.5: 324840,
    3.5: 284235,
    4.5: 243630,
  },
  {
    1: 406450,
    2: 352283,
    3: 304837,
    4: 264192,
    5: 223547,
    "revenu brut": 1308000,
    trimf: 1500,
    1.5: 381450,
    2.5: 325160,
    3.5: 284515,
    4.5: 243870,
  },
  {
    1: 406850,
    2: 352683,
    3: 305137,
    4: 264452,
    5: 223767,
    "revenu brut": 1309000,
    trimf: 1500,
    1.5: 381850,
    2.5: 325480,
    3.5: 284795,
    4.5: 244110,
  },
  {
    1: 407250,
    2: 353083,
    3: 305437,
    4: 264712,
    5: 223987,
    "revenu brut": 131_0000,
    trimf: 1500,
    1.5: 382250,
    2.5: 325800,
    3.5: 285075,
    4.5: 244350,
  },
  {
    1: 407650,
    2: 353483,
    3: 305737,
    4: 264972,
    5: 224207,
    "revenu brut": 1311_000,
    trimf: 1500,
    1.5: 382650,
    2.5: 326120,
    3.5: 285355,
    4.5: 244590,
  },
  {
    1: 408050,
    2: 353883,
    3: 306037,
    4: 265232,
    5: 224427,
    "revenu brut": 1312000,
    trimf: 1500,
    1.5: 383050,
    2.5: 326440,
    3.5: 285635,
    4.5: 244830,
  },
  {
    1: 408450,
    2: 354283,
    3: 306337,
    4: 265492,
    5: 224647,
    "revenu brut": 1313000,
    trimf: 1500,
    1.5: 383450,
    2.5: 326760,
    3.5: 285915,
    4.5: 245070,
  },
  {
    1: 408850,
    2: 354683,
    3: 306637,
    4: 265752,
    5: 224867,
    "revenu brut": 1314000,
    trimf: 1500,
    1.5: 383850,
    2.5: 327080,
    3.5: 286195,
    4.5: 245310,
  },
  {
    1: 409250,
    2: 355083,
    3: 306937,
    4: 266012,
    5: 225087,
    "revenu brut": 1315000,
    trimf: 1500,
    1.5: 384250,
    2.5: 327400,
    3.5: 286475,
    4.5: 245550,
  },
  {
    1: 409650,
    2: 355483,
    3: 307237,
    4: 266272,
    5: 225307,
    "revenu brut": 1316000,
    trimf: 1500,
    1.5: 384650,
    2.5: 327720,
    3.5: 286755,
    4.5: 245790,
  },
  {
    1: 410050,
    2: 355883,
    3: 307537,
    4: 266532,
    5: 225527,
    "revenu brut": 1317000,
    trimf: 1500,
    1.5: 385050,
    2.5: 328040,
    3.5: 287035,
    4.5: 246030,
  },
  {
    1: 410450,
    2: 356283,
    3: 307837,
    4: 266792,
    5: 225747,
    "revenu brut": 1318000,
    trimf: 1500,
    1.5: 385450,
    2.5: 328360,
    3.5: 287315,
    4.5: 246270,
  },
  {
    1: 410850,
    2: 356683,
    3: 308137,
    4: 267052,
    5: 225967,
    "revenu brut": 1319000,
    trimf: 1500,
    1.5: 385850,
    2.5: 328680,
    3.5: 287595,
    4.5: 246510,
  },
  {
    1: 411250,
    2: 357083,
    3: 308437,
    4: 267312,
    5: 226187,
    "revenu brut": 1320000,
    trimf: 1500,
    1.5: 386250,
    2.5: 329000,
    3.5: 287875,
    4.5: 246750,
  },
  {
    1: 411650,
    2: 357483,
    3: 308737,
    4: 267572,
    5: 226407,
    "revenu brut": 1321_000,
    trimf: 1500,
    1.5: 386650,
    2.5: 329320,
    3.5: 288155,
    4.5: 246990,
  },
  {
    1: 412050,
    2: 357883,
    3: 309037,
    4: 267832,
    5: 226627,
    "revenu brut": 1322000,
    trimf: 1500,
    1.5: 387050,
    2.5: 329640,
    3.5: 288435,
    4.5: 247230,
  },
  {
    1: 412450,
    2: 358283,
    3: 309337,
    4: 268092,
    5: 226847,
    "revenu brut": 1323000,
    trimf: 1500,
    1.5: 387450,
    2.5: 329960,
    3.5: 288715,
    4.5: 247470,
  },
  {
    1: 412850,
    2: 358683,
    3: 309637,
    4: 268352,
    5: 227067,
    "revenu brut": 1324000,
    trimf: 1500,
    1.5: 387850,
    2.5: 330280,
    3.5: 288995,
    4.5: 247710,
  },
  {
    1: 413250,
    2: 359083,
    3: 309937,
    4: 268612,
    5: 227287,
    "revenu brut": 1325000,
    trimf: 1500,
    1.5: 388250,
    2.5: 330600,
    3.5: 289275,
    4.5: 247950,
  },
  {
    1: 413650,
    2: 359483,
    3: 310237,
    4: 268872,
    5: 227507,
    "revenu brut": 1326000,
    trimf: 1500,
    1.5: 388650,
    2.5: 330920,
    3.5: 289555,
    4.5: 248190,
  },
  {
    1: 414050,
    2: 359883,
    3: 310537,
    4: 269132,
    5: 227727,
    "revenu brut": 1327000,
    trimf: 1500,
    1.5: 389050,
    2.5: 331240,
    3.5: 289835,
    4.5: 248430,
  },
  {
    1: 414450,
    2: 360283,
    3: 310837,
    4: 269392,
    5: 227947,
    "revenu brut": 1328000,
    trimf: 1500,
    1.5: 389450,
    2.5: 331560,
    3.5: 290115,
    4.5: 248670,
  },
  {
    1: 414850,
    2: 360683,
    3: 311137,
    4: 269652,
    5: 228167,
    "revenu brut": 1329000,
    trimf: 1500,
    1.5: 389850,
    2.5: 331880,
    3.5: 290395,
    4.5: 248910,
  },
  {
    1: 415250,
    2: 361083,
    3: 311437,
    4: 269912,
    5: 228387,
    "revenu brut": 1330000,
    trimf: 1500,
    1.5: 390250,
    2.5: 332200,
    3.5: 290675,
    4.5: 249150,
  },
  {
    1: 415650,
    2: 361483,
    3: 311737,
    4: 270172,
    5: 228607,
    "revenu brut": 1331_000,
    trimf: 1500,
    1.5: 390650,
    2.5: 332520,
    3.5: 290955,
    4.5: 249390,
  },
  {
    1: 416050,
    2: 361883,
    3: 312037,
    4: 270432,
    5: 228827,
    "revenu brut": 1332000,
    trimf: 1500,
    1.5: 391050,
    2.5: 332840,
    3.5: 291235,
    4.5: 249630,
  },
  {
    1: 416450,
    2: 362283,
    3: 312337,
    4: 270692,
    5: 229047,
    "revenu brut": 1333000,
    trimf: 1500,
    1.5: 391450,
    2.5: 333160,
    3.5: 291515,
    4.5: 249870,
  },
  {
    1: 416850,
    2: 362683,
    3: 312637,
    4: 270952,
    5: 229267,
    "revenu brut": 1334000,
    trimf: 1500,
    1.5: 391850,
    2.5: 333480,
    3.5: 291795,
    4.5: 250110,
  },
  {
    1: 417250,
    2: 363083,
    3: 312937,
    4: 271212,
    5: 229487,
    "revenu brut": 1335000,
    trimf: 1500,
    1.5: 392250,
    2.5: 333800,
    3.5: 292075,
    4.5: 250350,
  },
  {
    1: 417650,
    2: 363483,
    3: 313237,
    4: 271472,
    5: 229707,
    "revenu brut": 1336000,
    trimf: 1500,
    1.5: 392650,
    2.5: 334120,
    3.5: 292355,
    4.5: 250590,
  },
  {
    1: 418050,
    2: 363883,
    3: 313537,
    4: 271732,
    5: 229927,
    "revenu brut": 1337000,
    trimf: 1500,
    1.5: 393050,
    2.5: 334440,
    3.5: 292635,
    4.5: 250830,
  },
  {
    1: 418450,
    2: 364283,
    3: 313837,
    4: 271992,
    5: 230147,
    "revenu brut": 1338000,
    trimf: 1500,
    1.5: 393450,
    2.5: 334760,
    3.5: 292915,
    4.5: 251070,
  },
  {
    1: 418850,
    2: 364683,
    3: 314137,
    4: 272252,
    5: 230367,
    "revenu brut": 1339000,
    trimf: 1500,
    1.5: 393850,
    2.5: 335080,
    3.5: 293195,
    4.5: 251310,
  },
  {
    1: 419250,
    2: 365083,
    3: 314437,
    4: 272512,
    5: 230587,
    "revenu brut": 1340000,
    trimf: 1500,
    1.5: 394250,
    2.5: 335400,
    3.5: 293475,
    4.5: 251550,
  },
  {
    1: 419650,
    2: 365483,
    3: 314737,
    4: 272772,
    5: 230807,
    "revenu brut": 1341_000,
    trimf: 1500,
    1.5: 394650,
    2.5: 335720,
    3.5: 293755,
    4.5: 251790,
  },
  {
    1: 420050,
    2: 365883,
    3: 315037,
    4: 273032,
    5: 231027,
    "revenu brut": 1342000,
    trimf: 1500,
    1.5: 395050,
    2.5: 336040,
    3.5: 294035,
    4.5: 252030,
  },
  {
    1: 420450,
    2: 366283,
    3: 315337,
    4: 273292,
    5: 231247,
    "revenu brut": 1343000,
    trimf: 1500,
    1.5: 395450,
    2.5: 336360,
    3.5: 294315,
    4.5: 252270,
  },
  {
    1: 420850,
    2: 366683,
    3: 315637,
    4: 273552,
    5: 231467,
    "revenu brut": 1344000,
    trimf: 1500,
    1.5: 395850,
    2.5: 336680,
    3.5: 294595,
    4.5: 252510,
  },
  {
    1: 421250,
    2: 367083,
    3: 315937,
    4: 273812,
    5: 231687,
    "revenu brut": 1345000,
    trimf: 1500,
    1.5: 396250,
    2.5: 337000,
    3.5: 294875,
    4.5: 252750,
  },
  {
    1: 421650,
    2: 367483,
    3: 316237,
    4: 274072,
    5: 231907,
    "revenu brut": 1346000,
    trimf: 1500,
    1.5: 396650,
    2.5: 337320,
    3.5: 295155,
    4.5: 252990,
  },
  {
    1: 422050,
    2: 367883,
    3: 316537,
    4: 274332,
    5: 232127,
    "revenu brut": 1347000,
    trimf: 1500,
    1.5: 397050,
    2.5: 337640,
    3.5: 295435,
    4.5: 253230,
  },
  {
    1: 422450,
    2: 368283,
    3: 316837,
    4: 274592,
    5: 232347,
    "revenu brut": 1348000,
    trimf: 1500,
    1.5: 397450,
    2.5: 337960,
    3.5: 295715,
    4.5: 253470,
  },
  {
    1: 422850,
    2: 368683,
    3: 317137,
    4: 274852,
    5: 232567,
    "revenu brut": 1349000,
    trimf: 1500,
    1.5: 397850,
    2.5: 338280,
    3.5: 295995,
    4.5: 253710,
  },
  {
    1: 423250,
    2: 369083,
    3: 317437,
    4: 275112,
    5: 232787,
    "revenu brut": 1350000,
    trimf: 1500,
    1.5: 398250,
    2.5: 338600,
    3.5: 296275,
    4.5: 253950,
  },
  {
    1: 423650,
    2: 369483,
    3: 317737,
    4: 275372,
    5: 233007,
    "revenu brut": 1351_000,
    trimf: 1500,
    1.5: 398650,
    2.5: 338920,
    3.5: 296555,
    4.5: 254190,
  },
  {
    1: 424050,
    2: 369883,
    3: 318037,
    4: 275632,
    5: 233227,
    "revenu brut": 1352000,
    trimf: 1500,
    1.5: 399050,
    2.5: 339240,
    3.5: 296835,
    4.5: 254430,
  },
  {
    1: 424450,
    2: 370283,
    3: 318337,
    4: 275892,
    5: 233447,
    "revenu brut": 1353000,
    trimf: 1500,
    1.5: 399450,
    2.5: 339560,
    3.5: 297115,
    4.5: 254670,
  },
  {
    1: 424850,
    2: 370683,
    3: 318637,
    4: 276152,
    5: 233667,
    "revenu brut": 1354000,
    trimf: 1500,
    1.5: 399850,
    2.5: 339880,
    3.5: 297395,
    4.5: 254910,
  },
  {
    1: 425250,
    2: 371083,
    3: 318937,
    4: 276412,
    5: 233887,
    "revenu brut": 1355000,
    trimf: 1500,
    1.5: 400250,
    2.5: 340200,
    3.5: 297675,
    4.5: 255150,
  },
  {
    1: 425650,
    2: 371483,
    3: 319237,
    4: 276672,
    5: 234107,
    "revenu brut": 1356000,
    trimf: 1500,
    1.5: 400650,
    2.5: 340520,
    3.5: 297955,
    4.5: 255390,
  },
  {
    1: 426050,
    2: 371883,
    3: 319537,
    4: 276932,
    5: 234327,
    "revenu brut": 1357000,
    trimf: 1500,
    1.5: 401050,
    2.5: 340840,
    3.5: 298235,
    4.5: 255630,
  },
  {
    1: 426450,
    2: 372283,
    3: 319837,
    4: 277192,
    5: 234547,
    "revenu brut": 1358000,
    trimf: 1500,
    1.5: 401450,
    2.5: 341160,
    3.5: 298515,
    4.5: 255870,
  },
  {
    1: 426850,
    2: 372683,
    3: 320137,
    4: 277452,
    5: 234767,
    "revenu brut": 1359000,
    trimf: 1500,
    1.5: 401850,
    2.5: 341480,
    3.5: 298795,
    4.5: 256110,
  },
  {
    1: 427250,
    2: 373083,
    3: 320437,
    4: 277712,
    5: 234987,
    "revenu brut": 1360000,
    trimf: 1500,
    1.5: 402250,
    2.5: 341800,
    3.5: 299075,
    4.5: 256350,
  },
  {
    1: 427650,
    2: 373483,
    3: 320737,
    4: 277972,
    5: 235207,
    "revenu brut": 1361_000,
    trimf: 1500,
    1.5: 402650,
    2.5: 342120,
    3.5: 299355,
    4.5: 256590,
  },
  {
    1: 428050,
    2: 373883,
    3: 321037,
    4: 278232,
    5: 235427,
    "revenu brut": 1362000,
    trimf: 1500,
    1.5: 403050,
    2.5: 342440,
    3.5: 299635,
    4.5: 256830,
  },
  {
    1: 428450,
    2: 374283,
    3: 321337,
    4: 278492,
    5: 235647,
    "revenu brut": 1363000,
    trimf: 1500,
    1.5: 403450,
    2.5: 342760,
    3.5: 299915,
    4.5: 257070,
  },
  {
    1: 428850,
    2: 374683,
    3: 321637,
    4: 278752,
    5: 235867,
    "revenu brut": 1364000,
    trimf: 1500,
    1.5: 403850,
    2.5: 343080,
    3.5: 300195,
    4.5: 257310,
  },
  {
    1: 429250,
    2: 375083,
    3: 321937,
    4: 279012,
    5: 236087,
    "revenu brut": 1365000,
    trimf: 1500,
    1.5: 404250,
    2.5: 343400,
    3.5: 300475,
    4.5: 257550,
  },
  {
    1: 429650,
    2: 375483,
    3: 322237,
    4: 279272,
    5: 236307,
    "revenu brut": 1366000,
    trimf: 1500,
    1.5: 404650,
    2.5: 343720,
    3.5: 300755,
    4.5: 257790,
  },
  {
    1: 430050,
    2: 375883,
    3: 322537,
    4: 279532,
    5: 236527,
    "revenu brut": 1367000,
    trimf: 1500,
    1.5: 405050,
    2.5: 344040,
    3.5: 301035,
    4.5: 258030,
  },
  {
    1: 430450,
    2: 376283,
    3: 322837,
    4: 279792,
    5: 236747,
    "revenu brut": 1368000,
    trimf: 1500,
    1.5: 405450,
    2.5: 344360,
    3.5: 301315,
    4.5: 258270,
  },
  {
    1: 430850,
    2: 376683,
    3: 323137,
    4: 280052,
    5: 236967,
    "revenu brut": 1369000,
    trimf: 1500,
    1.5: 405850,
    2.5: 344680,
    3.5: 301595,
    4.5: 258510,
  },
  {
    1: 431250,
    2: 377083,
    3: 323437,
    4: 280312,
    5: 237187,
    "revenu brut": 1370000,
    trimf: 1500,
    1.5: 406250,
    2.5: 345000,
    3.5: 301875,
    4.5: 258750,
  },
  {
    1: 431650,
    2: 377483,
    3: 323737,
    4: 280572,
    5: 237407,
    "revenu brut": 1371_000,
    trimf: 1500,
    1.5: 406650,
    2.5: 345320,
    3.5: 302155,
    4.5: 258990,
  },
  {
    1: 432050,
    2: 377883,
    3: 324037,
    4: 280832,
    5: 237627,
    "revenu brut": 1372000,
    trimf: 1500,
    1.5: 407050,
    2.5: 345640,
    3.5: 302435,
    4.5: 259230,
  },
  {
    1: 432450,
    2: 378283,
    3: 324337,
    4: 281092,
    5: 237847,
    "revenu brut": 1373000,
    trimf: 1500,
    1.5: 407450,
    2.5: 345960,
    3.5: 302715,
    4.5: 259470,
  },
  {
    1: 432850,
    2: 378683,
    3: 324637,
    4: 281352,
    5: 238067,
    "revenu brut": 1374000,
    trimf: 1500,
    1.5: 407850,
    2.5: 346280,
    3.5: 302995,
    4.5: 259710,
  },
  {
    1: 433250,
    2: 379083,
    3: 324937,
    4: 281612,
    5: 238287,
    "revenu brut": 1375000,
    trimf: 1500,
    1.5: 408250,
    2.5: 346600,
    3.5: 303275,
    4.5: 259950,
  },
  {
    1: 433650,
    2: 379483,
    3: 325237,
    4: 281872,
    5: 238507,
    "revenu brut": 1376000,
    trimf: 1500,
    1.5: 408650,
    2.5: 346920,
    3.5: 303555,
    4.5: 260190,
  },
  {
    1: 434050,
    2: 379883,
    3: 325537,
    4: 282132,
    5: 238727,
    "revenu brut": 1377000,
    trimf: 1500,
    1.5: 409050,
    2.5: 347240,
    3.5: 303835,
    4.5: 260430,
  },
  {
    1: 434450,
    2: 380283,
    3: 325837,
    4: 282392,
    5: 238947,
    "revenu brut": 1378000,
    trimf: 1500,
    1.5: 409450,
    2.5: 347560,
    3.5: 304115,
    4.5: 260670,
  },
  {
    1: 434850,
    2: 380683,
    3: 326137,
    4: 282652,
    5: 239167,
    "revenu brut": 1379000,
    trimf: 1500,
    1.5: 409850,
    2.5: 347880,
    3.5: 304395,
    4.5: 260910,
  },
  {
    1: 435250,
    2: 381083,
    3: 326437,
    4: 282912,
    5: 239387,
    "revenu brut": 1380000,
    trimf: 1500,
    1.5: 410250,
    2.5: 348200,
    3.5: 304675,
    4.5: 261150,
  },
  {
    1: 435650,
    2: 381483,
    3: 326737,
    4: 283172,
    5: 239607,
    "revenu brut": 1381_000,
    trimf: 1500,
    1.5: 410650,
    2.5: 348520,
    3.5: 304955,
    4.5: 261390,
  },
  {
    1: 436050,
    2: 381883,
    3: 327037,
    4: 283432,
    5: 239827,
    "revenu brut": 1382000,
    trimf: 1500,
    1.5: 411050,
    2.5: 348840,
    3.5: 305235,
    4.5: 261630,
  },
  {
    1: 436450,
    2: 382283,
    3: 327337,
    4: 283692,
    5: 240047,
    "revenu brut": 1383000,
    trimf: 1500,
    1.5: 411450,
    2.5: 349160,
    3.5: 305515,
    4.5: 261870,
  },
  {
    1: 436850,
    2: 382683,
    3: 327637,
    4: 283952,
    5: 240267,
    "revenu brut": 1384000,
    trimf: 1500,
    1.5: 411850,
    2.5: 349480,
    3.5: 305795,
    4.5: 262110,
  },
  {
    1: 437250,
    2: 383083,
    3: 327937,
    4: 284212,
    5: 240487,
    "revenu brut": 1385000,
    trimf: 1500,
    1.5: 412250,
    2.5: 349800,
    3.5: 306075,
    4.5: 262350,
  },
  {
    1: 437650,
    2: 383483,
    3: 328237,
    4: 284472,
    5: 240707,
    "revenu brut": 1386000,
    trimf: 1500,
    1.5: 412650,
    2.5: 350120,
    3.5: 306355,
    4.5: 262590,
  },
  {
    1: 438050,
    2: 383883,
    3: 328537,
    4: 284732,
    5: 240927,
    "revenu brut": 1387000,
    trimf: 1500,
    1.5: 413050,
    2.5: 350440,
    3.5: 306635,
    4.5: 262830,
  },
  {
    1: 438450,
    2: 384283,
    3: 328837,
    4: 284992,
    5: 241147,
    "revenu brut": 1388000,
    trimf: 1500,
    1.5: 413450,
    2.5: 350760,
    3.5: 306915,
    4.5: 263070,
  },
  {
    1: 438850,
    2: 384683,
    3: 329137,
    4: 285252,
    5: 241367,
    "revenu brut": 1389000,
    trimf: 1500,
    1.5: 413850,
    2.5: 351080,
    3.5: 307195,
    4.5: 263310,
  },
  {
    1: 439250,
    2: 385083,
    3: 329437,
    4: 285512,
    5: 241587,
    "revenu brut": 1390000,
    trimf: 1500,
    1.5: 414250,
    2.5: 351400,
    3.5: 307475,
    4.5: 263550,
  },
  {
    1: 439650,
    2: 385483,
    3: 329737,
    4: 285772,
    5: 241807,
    "revenu brut": 1391_000,
    trimf: 1500,
    1.5: 414650,
    2.5: 351720,
    3.5: 307755,
    4.5: 263790,
  },
  {
    1: 440050,
    2: 385883,
    3: 330037,
    4: 286032,
    5: 242027,
    "revenu brut": 1392000,
    trimf: 1500,
    1.5: 415050,
    2.5: 352040,
    3.5: 308035,
    4.5: 264030,
  },
  {
    1: 440450,
    2: 386283,
    3: 330337,
    4: 286292,
    5: 242247,
    "revenu brut": 1393000,
    trimf: 1500,
    1.5: 415450,
    2.5: 352360,
    3.5: 308315,
    4.5: 264270,
  },
  {
    1: 440850,
    2: 386683,
    3: 330637,
    4: 286552,
    5: 242467,
    "revenu brut": 1394000,
    trimf: 1500,
    1.5: 415850,
    2.5: 352680,
    3.5: 308595,
    4.5: 264510,
  },
  {
    1: 441250,
    2: 387083,
    3: 330937,
    4: 286812,
    5: 242687,
    "revenu brut": 1395000,
    trimf: 1500,
    1.5: 416250,
    2.5: 353000,
    3.5: 308875,
    4.5: 264750,
  },
  {
    1: 441650,
    2: 387483,
    3: 331237,
    4: 287072,
    5: 242907,
    "revenu brut": 1396000,
    trimf: 1500,
    1.5: 416650,
    2.5: 353320,
    3.5: 309155,
    4.5: 264990,
  },
  {
    1: 442050,
    2: 387883,
    3: 331537,
    4: 287332,
    5: 243127,
    "revenu brut": 1397000,
    trimf: 1500,
    1.5: 417050,
    2.5: 353640,
    3.5: 309435,
    4.5: 265230,
  },
  {
    1: 442450,
    2: 388283,
    3: 331837,
    4: 287592,
    5: 243347,
    "revenu brut": 1398000,
    trimf: 1500,
    1.5: 417450,
    2.5: 353960,
    3.5: 309715,
    4.5: 265470,
  },
  {
    1: 442850,
    2: 388683,
    3: 332137,
    4: 287852,
    5: 243567,
    "revenu brut": 1399000,
    trimf: 1500,
    1.5: 417850,
    2.5: 354280,
    3.5: 309995,
    4.5: 265710,
  },
  {
    1: 443250,
    2: 389083,
    3: 332437,
    4: 288112,
    5: 243787,
    "revenu brut": 1400000,
    trimf: 1500,
    1.5: 418250,
    2.5: 354600,
    3.5: 310275,
    4.5: 265950,
  },
  {
    1: 443650,
    2: 389483,
    3: 332737,
    4: 288372,
    5: 244007,
    "revenu brut": 1401_000,
    trimf: 1500,
    1.5: 418650,
    2.5: 354920,
    3.5: 310555,
    4.5: 266190,
  },
  {
    1: 444050,
    2: 389883,
    3: 333037,
    4: 288632,
    5: 244227,
    "revenu brut": 1402000,
    trimf: 1500,
    1.5: 419050,
    2.5: 355240,
    3.5: 310835,
    4.5: 266430,
  },
  {
    1: 444450,
    2: 390283,
    3: 333337,
    4: 288892,
    5: 244447,
    "revenu brut": 1403000,
    trimf: 1500,
    1.5: 419450,
    2.5: 355560,
    3.5: 311115,
    4.5: 266670,
  },
  {
    1: 444850,
    2: 390683,
    3: 333637,
    4: 289152,
    5: 244667,
    "revenu brut": 1404000,
    trimf: 1500,
    1.5: 419850,
    2.5: 355880,
    3.5: 311395,
    4.5: 266910,
  },
  {
    1: 445250,
    2: 391083,
    3: 333937,
    4: 289412,
    5: 244887,
    "revenu brut": 1405000,
    trimf: 1500,
    1.5: 420250,
    2.5: 356200,
    3.5: 311675,
    4.5: 267150,
  },
  {
    1: 445650,
    2: 391483,
    3: 334237,
    4: 289672,
    5: 245107,
    "revenu brut": 1406000,
    trimf: 1500,
    1.5: 420650,
    2.5: 356520,
    3.5: 311955,
    4.5: 267390,
  },
  {
    1: 446050,
    2: 391883,
    3: 334537,
    4: 289932,
    5: 245327,
    "revenu brut": 1407000,
    trimf: 1500,
    1.5: 421050,
    2.5: 356840,
    3.5: 312235,
    4.5: 267630,
  },
  {
    1: 446450,
    2: 392283,
    3: 334837,
    4: 290192,
    5: 245547,
    "revenu brut": 1408000,
    trimf: 1500,
    1.5: 421450,
    2.5: 357160,
    3.5: 312515,
    4.5: 267870,
  },
  {
    1: 446850,
    2: 392683,
    3: 335137,
    4: 290452,
    5: 245767,
    "revenu brut": 1409000,
    trimf: 1500,
    1.5: 421850,
    2.5: 357480,
    3.5: 312795,
    4.5: 268110,
  },
  {
    1: 447250,
    2: 393083,
    3: 335437,
    4: 290712,
    5: 245987,
    "revenu brut": 141_0000,
    trimf: 1500,
    1.5: 422250,
    2.5: 357800,
    3.5: 313075,
    4.5: 268350,
  },
  {
    1: 447650,
    2: 393483,
    3: 335737,
    4: 290972,
    5: 246207,
    "revenu brut": 1411_000,
    trimf: 1500,
    1.5: 422650,
    2.5: 358120,
    3.5: 313355,
    4.5: 268590,
  },
  {
    1: 448050,
    2: 393883,
    3: 336037,
    4: 291232,
    5: 246427,
    "revenu brut": 1412000,
    trimf: 1500,
    1.5: 423050,
    2.5: 358440,
    3.5: 313635,
    4.5: 268830,
  },
  {
    1: 448450,
    2: 394283,
    3: 336337,
    4: 291492,
    5: 246647,
    "revenu brut": 1413000,
    trimf: 1500,
    1.5: 423450,
    2.5: 358760,
    3.5: 313915,
    4.5: 269070,
  },
  {
    1: 448850,
    2: 394683,
    3: 336637,
    4: 291752,
    5: 246867,
    "revenu brut": 1414000,
    trimf: 1500,
    1.5: 423850,
    2.5: 359080,
    3.5: 314195,
    4.5: 269310,
  },
  {
    1: 449250,
    2: 395083,
    3: 336937,
    4: 292012,
    5: 247087,
    "revenu brut": 1415000,
    trimf: 1500,
    1.5: 424250,
    2.5: 359400,
    3.5: 314475,
    4.5: 269550,
  },
  {
    1: 449650,
    2: 395483,
    3: 337237,
    4: 292272,
    5: 247307,
    "revenu brut": 1416000,
    trimf: 1500,
    1.5: 424650,
    2.5: 359720,
    3.5: 314755,
    4.5: 269790,
  },
  {
    1: 450050,
    2: 395883,
    3: 337537,
    4: 292532,
    5: 247527,
    "revenu brut": 1417000,
    trimf: 1500,
    1.5: 425050,
    2.5: 360040,
    3.5: 315035,
    4.5: 270030,
  },
  {
    1: 450450,
    2: 396283,
    3: 337837,
    4: 292792,
    5: 247747,
    "revenu brut": 1418000,
    trimf: 1500,
    1.5: 425450,
    2.5: 360360,
    3.5: 315315,
    4.5: 270270,
  },
  {
    1: 450850,
    2: 396683,
    3: 338137,
    4: 293052,
    5: 247967,
    "revenu brut": 1419000,
    trimf: 1500,
    1.5: 425850,
    2.5: 360680,
    3.5: 315595,
    4.5: 270510,
  },
  {
    1: 451250,
    2: 397083,
    3: 338437,
    4: 293312,
    5: 248187,
    "revenu brut": 1420000,
    trimf: 1500,
    1.5: 426250,
    2.5: 361_000,
    3.5: 315875,
    4.5: 270750,
  },
  {
    1: 451650,
    2: 397483,
    3: 338737,
    4: 293572,
    5: 248407,
    "revenu brut": 1421_000,
    trimf: 1500,
    1.5: 426650,
    2.5: 361320,
    3.5: 316155,
    4.5: 270990,
  },
  {
    1: 452050,
    2: 397883,
    3: 339037,
    4: 293832,
    5: 248627,
    "revenu brut": 1422000,
    trimf: 1500,
    1.5: 427050,
    2.5: 361640,
    3.5: 316435,
    4.5: 271230,
  },
  {
    1: 452450,
    2: 398283,
    3: 339337,
    4: 294092,
    5: 248847,
    "revenu brut": 1423000,
    trimf: 1500,
    1.5: 427450,
    2.5: 361960,
    3.5: 316715,
    4.5: 271470,
  },
  {
    1: 452850,
    2: 398683,
    3: 339637,
    4: 294352,
    5: 249067,
    "revenu brut": 1424000,
    trimf: 1500,
    1.5: 427850,
    2.5: 362280,
    3.5: 316995,
    4.5: 271710,
  },
  {
    1: 453250,
    2: 399083,
    3: 339937,
    4: 294612,
    5: 249287,
    "revenu brut": 1425000,
    trimf: 1500,
    1.5: 428250,
    2.5: 362600,
    3.5: 317275,
    4.5: 271950,
  },
  {
    1: 453650,
    2: 399483,
    3: 340237,
    4: 294872,
    5: 249507,
    "revenu brut": 1426000,
    trimf: 1500,
    1.5: 428650,
    2.5: 362920,
    3.5: 317555,
    4.5: 272190,
  },
  {
    1: 454050,
    2: 399883,
    3: 340537,
    4: 295132,
    5: 249727,
    "revenu brut": 1427000,
    trimf: 1500,
    1.5: 429050,
    2.5: 363240,
    3.5: 317835,
    4.5: 272430,
  },
  {
    1: 454450,
    2: 400283,
    3: 340837,
    4: 295392,
    5: 249947,
    "revenu brut": 1428000,
    trimf: 1500,
    1.5: 429450,
    2.5: 363560,
    3.5: 318115,
    4.5: 272670,
  },
  {
    1: 454850,
    2: 400683,
    3: 341137,
    4: 295652,
    5: 250167,
    "revenu brut": 1429000,
    trimf: 1500,
    1.5: 429850,
    2.5: 363880,
    3.5: 318395,
    4.5: 272910,
  },
  {
    1: 455250,
    2: 401083,
    3: 341437,
    4: 295912,
    5: 250387,
    "revenu brut": 1430000,
    trimf: 1500,
    1.5: 430250,
    2.5: 364200,
    3.5: 318675,
    4.5: 273150,
  },
  {
    1: 455650,
    2: 401483,
    3: 341737,
    4: 296172,
    5: 250607,
    "revenu brut": 1431_000,
    trimf: 1500,
    1.5: 430650,
    2.5: 364520,
    3.5: 318955,
    4.5: 273390,
  },
  {
    1: 456050,
    2: 401883,
    3: 342037,
    4: 296432,
    5: 250827,
    "revenu brut": 1432000,
    trimf: 1500,
    1.5: 431050,
    2.5: 364840,
    3.5: 319235,
    4.5: 273630,
  },
  {
    1: 456450,
    2: 402283,
    3: 342337,
    4: 296692,
    5: 251047,
    "revenu brut": 1433000,
    trimf: 1500,
    1.5: 431450,
    2.5: 365160,
    3.5: 319515,
    4.5: 273870,
  },
  {
    1: 456850,
    2: 402683,
    3: 342637,
    4: 296952,
    5: 251267,
    "revenu brut": 1434000,
    trimf: 1500,
    1.5: 431850,
    2.5: 365480,
    3.5: 319795,
    4.5: 274110,
  },
  {
    1: 457250,
    2: 403083,
    3: 342937,
    4: 297212,
    5: 251487,
    "revenu brut": 1435000,
    trimf: 1500,
    1.5: 432250,
    2.5: 365800,
    3.5: 320075,
    4.5: 274350,
  },
  {
    1: 457650,
    2: 403483,
    3: 343237,
    4: 297472,
    5: 251707,
    "revenu brut": 1436000,
    trimf: 1500,
    1.5: 432650,
    2.5: 366120,
    3.5: 320355,
    4.5: 274590,
  },
  {
    1: 458050,
    2: 403883,
    3: 343537,
    4: 297732,
    5: 251927,
    "revenu brut": 1437000,
    trimf: 1500,
    1.5: 433050,
    2.5: 366440,
    3.5: 320635,
    4.5: 274830,
  },
  {
    1: 458450,
    2: 404283,
    3: 343837,
    4: 297992,
    5: 252147,
    "revenu brut": 1438000,
    trimf: 1500,
    1.5: 433450,
    2.5: 366783,
    3.5: 320915,
    4.5: 275070,
  },
  {
    1: 458850,
    2: 404683,
    3: 344137,
    4: 298252,
    5: 252367,
    "revenu brut": 1439000,
    trimf: 1500,
    1.5: 433850,
    2.5: 367183,
    3.5: 321195,
    4.5: 275310,
  },
  {
    1: 459250,
    2: 405083,
    3: 344437,
    4: 298512,
    5: 252587,
    "revenu brut": 1440000,
    trimf: 1500,
    1.5: 434250,
    2.5: 367583,
    3.5: 321475,
    4.5: 275550,
  },
  {
    1: 459650,
    2: 405483,
    3: 344737,
    4: 298772,
    5: 252807,
    "revenu brut": 1441_000,
    trimf: 1500,
    1.5: 434650,
    2.5: 367983,
    3.5: 321755,
    4.5: 275790,
  },
  {
    1: 460050,
    2: 405883,
    3: 345037,
    4: 299032,
    5: 253027,
    "revenu brut": 1442000,
    trimf: 1500,
    1.5: 435050,
    2.5: 368383,
    3.5: 322035,
    4.5: 276030,
  },
  {
    1: 460450,
    2: 406283,
    3: 345337,
    4: 299292,
    5: 253247,
    "revenu brut": 1443000,
    trimf: 1500,
    1.5: 435450,
    2.5: 368783,
    3.5: 322315,
    4.5: 276270,
  },
  {
    1: 460850,
    2: 406683,
    3: 345637,
    4: 299552,
    5: 253467,
    "revenu brut": 1444000,
    trimf: 1500,
    1.5: 435850,
    2.5: 369183,
    3.5: 322595,
    4.5: 276510,
  },
  {
    1: 461250,
    2: 407083,
    3: 345937,
    4: 299812,
    5: 253687,
    "revenu brut": 1445000,
    trimf: 1500,
    1.5: 436250,
    2.5: 369583,
    3.5: 322875,
    4.5: 276750,
  },
  {
    1: 461650,
    2: 407483,
    3: 346237,
    4: 300072,
    5: 253907,
    "revenu brut": 1446000,
    trimf: 1500,
    1.5: 436650,
    2.5: 369983,
    3.5: 323155,
    4.5: 276990,
  },
  {
    1: 462050,
    2: 407883,
    3: 346537,
    4: 300332,
    5: 254127,
    "revenu brut": 1447000,
    trimf: 1500,
    1.5: 437050,
    2.5: 370383,
    3.5: 323435,
    4.5: 277230,
  },
  {
    1: 462450,
    2: 408283,
    3: 346837,
    4: 300592,
    5: 254347,
    "revenu brut": 1448000,
    trimf: 1500,
    1.5: 437450,
    2.5: 370783,
    3.5: 323715,
    4.5: 277470,
  },
  {
    1: 462850,
    2: 408683,
    3: 347137,
    4: 300852,
    5: 254567,
    "revenu brut": 1449000,
    trimf: 1500,
    1.5: 437850,
    2.5: 371183,
    3.5: 323995,
    4.5: 277710,
  },
  {
    1: 463250,
    2: 409083,
    3: 347437,
    4: 301112,
    5: 254787,
    "revenu brut": 1450000,
    trimf: 1500,
    1.5: 438250,
    2.5: 371583,
    3.5: 324275,
    4.5: 277950,
  },
  {
    1: 463650,
    2: 409483,
    3: 347737,
    4: 301372,
    5: 255007,
    "revenu brut": 1451_000,
    trimf: 1500,
    1.5: 438650,
    2.5: 371983,
    3.5: 324555,
    4.5: 278190,
  },
  {
    1: 464050,
    2: 409883,
    3: 348037,
    4: 301632,
    5: 255227,
    "revenu brut": 1452000,
    trimf: 1500,
    1.5: 439050,
    2.5: 372383,
    3.5: 324835,
    4.5: 278430,
  },
  {
    1: 464450,
    2: 410283,
    3: 348337,
    4: 301892,
    5: 255447,
    "revenu brut": 1453000,
    trimf: 1500,
    1.5: 439450,
    2.5: 372783,
    3.5: 325115,
    4.5: 278670,
  },
  {
    1: 464850,
    2: 410683,
    3: 348637,
    4: 302152,
    5: 255667,
    "revenu brut": 1454000,
    trimf: 1500,
    1.5: 439850,
    2.5: 373183,
    3.5: 325395,
    4.5: 278910,
  },
  {
    1: 465250,
    2: 411083,
    3: 348937,
    4: 302412,
    5: 255887,
    "revenu brut": 1455000,
    trimf: 1500,
    1.5: 440250,
    2.5: 373583,
    3.5: 325675,
    4.5: 279150,
  },
  {
    1: 465650,
    2: 411483,
    3: 349237,
    4: 302672,
    5: 256107,
    "revenu brut": 1456000,
    trimf: 1500,
    1.5: 440650,
    2.5: 373983,
    3.5: 325955,
    4.5: 279390,
  },
  {
    1: 466050,
    2: 411883,
    3: 349537,
    4: 302932,
    5: 256327,
    "revenu brut": 1457000,
    trimf: 1500,
    1.5: 441050,
    2.5: 374383,
    3.5: 326235,
    4.5: 279630,
  },
  {
    1: 466450,
    2: 412283,
    3: 349837,
    4: 303192,
    5: 256547,
    "revenu brut": 1458000,
    trimf: 1500,
    1.5: 441450,
    2.5: 374783,
    3.5: 326515,
    4.5: 279870,
  },
  {
    1: 466850,
    2: 412683,
    3: 350137,
    4: 303452,
    5: 256767,
    "revenu brut": 1459000,
    trimf: 1500,
    1.5: 441850,
    2.5: 375183,
    3.5: 326795,
    4.5: 280110,
  },
  {
    1: 467250,
    2: 413083,
    3: 350437,
    4: 303712,
    5: 256987,
    "revenu brut": 1460000,
    trimf: 1500,
    1.5: 442250,
    2.5: 375583,
    3.5: 327075,
    4.5: 280350,
  },
  {
    1: 467650,
    2: 413483,
    3: 350737,
    4: 303972,
    5: 257207,
    "revenu brut": 1461_000,
    trimf: 1500,
    1.5: 442650,
    2.5: 375983,
    3.5: 327355,
    4.5: 280590,
  },
  {
    1: 468050,
    2: 413883,
    3: 351037,
    4: 304232,
    5: 257427,
    "revenu brut": 1462000,
    trimf: 1500,
    1.5: 443050,
    2.5: 376383,
    3.5: 327635,
    4.5: 280830,
  },
  {
    1: 468450,
    2: 414283,
    3: 351337,
    4: 304492,
    5: 257647,
    "revenu brut": 1463000,
    trimf: 1500,
    1.5: 443450,
    2.5: 376783,
    3.5: 327915,
    4.5: 281070,
  },
  {
    1: 468850,
    2: 414683,
    3: 351637,
    4: 304752,
    5: 257867,
    "revenu brut": 1464000,
    trimf: 1500,
    1.5: 443850,
    2.5: 377183,
    3.5: 328195,
    4.5: 281310,
  },
  {
    1: 469250,
    2: 415083,
    3: 351937,
    4: 305012,
    5: 258087,
    "revenu brut": 1465000,
    trimf: 1500,
    1.5: 444250,
    2.5: 377583,
    3.5: 328475,
    4.5: 281550,
  },
  {
    1: 469650,
    2: 415483,
    3: 352237,
    4: 305272,
    5: 258307,
    "revenu brut": 1466000,
    trimf: 1500,
    1.5: 444650,
    2.5: 377983,
    3.5: 328755,
    4.5: 281790,
  },
  {
    1: 470050,
    2: 415883,
    3: 352537,
    4: 305532,
    5: 258527,
    "revenu brut": 1467000,
    trimf: 1500,
    1.5: 445050,
    2.5: 378383,
    3.5: 329035,
    4.5: 282030,
  },
  {
    1: 470450,
    2: 416283,
    3: 352837,
    4: 305792,
    5: 258747,
    "revenu brut": 1468000,
    trimf: 1500,
    1.5: 445450,
    2.5: 378783,
    3.5: 329315,
    4.5: 282270,
  },
  {
    1: 470850,
    2: 416683,
    3: 353137,
    4: 306052,
    5: 258967,
    "revenu brut": 1469000,
    trimf: 1500,
    1.5: 445850,
    2.5: 379183,
    3.5: 329595,
    4.5: 282510,
  },
  {
    1: 471250,
    2: 417083,
    3: 353437,
    4: 306312,
    5: 259187,
    "revenu brut": 1470000,
    trimf: 1500,
    1.5: 446250,
    2.5: 379583,
    3.5: 329875,
    4.5: 282750,
  },
  {
    1: 471650,
    2: 417483,
    3: 353737,
    4: 306572,
    5: 259407,
    "revenu brut": 1471_000,
    trimf: 1500,
    1.5: 446650,
    2.5: 379983,
    3.5: 330155,
    4.5: 282990,
  },
  {
    1: 472050,
    2: 417883,
    3: 354037,
    4: 306832,
    5: 259627,
    "revenu brut": 1472000,
    trimf: 1500,
    1.5: 447050,
    2.5: 380383,
    3.5: 330435,
    4.5: 283230,
  },
  {
    1: 472450,
    2: 418283,
    3: 354337,
    4: 307092,
    5: 259847,
    "revenu brut": 1473000,
    trimf: 1500,
    1.5: 447450,
    2.5: 380783,
    3.5: 330715,
    4.5: 283470,
  },
  {
    1: 472850,
    2: 418683,
    3: 354637,
    4: 307352,
    5: 260067,
    "revenu brut": 1474000,
    trimf: 1500,
    1.5: 447850,
    2.5: 381183,
    3.5: 330995,
    4.5: 283710,
  },
  {
    1: 473250,
    2: 419083,
    3: 354937,
    4: 307612,
    5: 260287,
    "revenu brut": 1475000,
    trimf: 1500,
    1.5: 448250,
    2.5: 381583,
    3.5: 331275,
    4.5: 283950,
  },
  {
    1: 473650,
    2: 419483,
    3: 355237,
    4: 307872,
    5: 260507,
    "revenu brut": 1476000,
    trimf: 1500,
    1.5: 448650,
    2.5: 381983,
    3.5: 331555,
    4.5: 284190,
  },
  {
    1: 474050,
    2: 419883,
    3: 355537,
    4: 308132,
    5: 260727,
    "revenu brut": 1477000,
    trimf: 1500,
    1.5: 449050,
    2.5: 382383,
    3.5: 331835,
    4.5: 284430,
  },
  {
    1: 474450,
    2: 420283,
    3: 355837,
    4: 308392,
    5: 260947,
    "revenu brut": 1478000,
    trimf: 1500,
    1.5: 449450,
    2.5: 382783,
    3.5: 332115,
    4.5: 284670,
  },
  {
    1: 474850,
    2: 420683,
    3: 356137,
    4: 308652,
    5: 261167,
    "revenu brut": 1479000,
    trimf: 1500,
    1.5: 449850,
    2.5: 383183,
    3.5: 332395,
    4.5: 284910,
  },
  {
    1: 475250,
    2: 421083,
    3: 356437,
    4: 308912,
    5: 261387,
    "revenu brut": 1480000,
    trimf: 1500,
    1.5: 450250,
    2.5: 383583,
    3.5: 332675,
    4.5: 285150,
  },
  {
    1: 475650,
    2: 421483,
    3: 356737,
    4: 309172,
    5: 261607,
    "revenu brut": 1481_000,
    trimf: 1500,
    1.5: 450650,
    2.5: 383983,
    3.5: 332955,
    4.5: 285390,
  },
  {
    1: 476050,
    2: 421883,
    3: 357037,
    4: 309432,
    5: 261827,
    "revenu brut": 1482000,
    trimf: 1500,
    1.5: 451050,
    2.5: 384383,
    3.5: 333235,
    4.5: 285630,
  },
  {
    1: 476450,
    2: 422283,
    3: 357337,
    4: 309692,
    5: 262047,
    "revenu brut": 1483000,
    trimf: 1500,
    1.5: 451450,
    2.5: 384783,
    3.5: 333515,
    4.5: 285870,
  },
  {
    1: 476850,
    2: 422683,
    3: 357637,
    4: 309952,
    5: 262267,
    "revenu brut": 1484000,
    trimf: 1500,
    1.5: 451850,
    2.5: 385183,
    3.5: 333795,
    4.5: 286110,
  },
  {
    1: 477250,
    2: 423083,
    3: 357937,
    4: 310212,
    5: 262487,
    "revenu brut": 1485000,
    trimf: 1500,
    1.5: 452250,
    2.5: 385583,
    3.5: 334075,
    4.5: 286350,
  },
  {
    1: 477650,
    2: 423483,
    3: 358237,
    4: 310472,
    5: 262707,
    "revenu brut": 1486000,
    trimf: 1500,
    1.5: 452650,
    2.5: 385983,
    3.5: 334355,
    4.5: 286590,
  },
  {
    1: 478050,
    2: 423883,
    3: 358537,
    4: 310732,
    5: 262927,
    "revenu brut": 1487000,
    trimf: 1500,
    1.5: 453050,
    2.5: 386383,
    3.5: 334635,
    4.5: 286830,
  },
  {
    1: 478450,
    2: 424283,
    3: 358837,
    4: 310992,
    5: 263147,
    "revenu brut": 1488000,
    trimf: 1500,
    1.5: 453450,
    2.5: 386783,
    3.5: 334915,
    4.5: 287070,
  },
  {
    1: 478850,
    2: 424683,
    3: 359137,
    4: 311252,
    5: 263367,
    "revenu brut": 1489000,
    trimf: 1500,
    1.5: 453850,
    2.5: 387183,
    3.5: 335195,
    4.5: 287310,
  },
  {
    1: 479250,
    2: 425083,
    3: 359437,
    4: 311512,
    5: 263587,
    "revenu brut": 1490000,
    trimf: 1500,
    1.5: 454250,
    2.5: 387583,
    3.5: 335475,
    4.5: 287550,
  },
  {
    1: 479650,
    2: 425483,
    3: 359737,
    4: 311772,
    5: 263807,
    "revenu brut": 1491_000,
    trimf: 1500,
    1.5: 454650,
    2.5: 387983,
    3.5: 335755,
    4.5: 287790,
  },
  {
    1: 480050,
    2: 425883,
    3: 360037,
    4: 312032,
    5: 264027,
    "revenu brut": 1492000,
    trimf: 1500,
    1.5: 455050,
    2.5: 388383,
    3.5: 336035,
    4.5: 288030,
  },
  {
    1: 480450,
    2: 426283,
    3: 360337,
    4: 312292,
    5: 264247,
    "revenu brut": 1493000,
    trimf: 1500,
    1.5: 455450,
    2.5: 388783,
    3.5: 336315,
    4.5: 288270,
  },
  {
    1: 480850,
    2: 426683,
    3: 360637,
    4: 312552,
    5: 264467,
    "revenu brut": 1494000,
    trimf: 1500,
    1.5: 455850,
    2.5: 389183,
    3.5: 336595,
    4.5: 288510,
  },
  {
    1: 481250,
    2: 427083,
    3: 360937,
    4: 312812,
    5: 264687,
    "revenu brut": 1495000,
    trimf: 1500,
    1.5: 456250,
    2.5: 389583,
    3.5: 336875,
    4.5: 288750,
  },
  {
    1: 481650,
    2: 427483,
    3: 361237,
    4: 313072,
    5: 264907,
    "revenu brut": 1496000,
    trimf: 1500,
    1.5: 456650,
    2.5: 389983,
    3.5: 337155,
    4.5: 288990,
  },
  {
    1: 482050,
    2: 427883,
    3: 361537,
    4: 313332,
    5: 265127,
    "revenu brut": 1497000,
    trimf: 1500,
    1.5: 457050,
    2.5: 390383,
    3.5: 337435,
    4.5: 289230,
  },
  {
    1: 482450,
    2: 428283,
    3: 361837,
    4: 313592,
    5: 265347,
    "revenu brut": 1498000,
    trimf: 1500,
    1.5: 457450,
    2.5: 390783,
    3.5: 337715,
    4.5: 289470,
  },
  {
    1: 482850,
    2: 428683,
    3: 362137,
    4: 313852,
    5: 265567,
    "revenu brut": 1499000,
    trimf: 1500,
    1.5: 457850,
    2.5: 391183,
    3.5: 337995,
    4.5: 289710,
  },
  {
    1: 483250,
    2: 429083,
    3: 362437,
    4: 314112,
    5: 265787,
    "revenu brut": 1500000,
    trimf: 1500,
    1.5: 458250,
    2.5: 391583,
    3.5: 338275,
    4.5: 289950,
  },
  {
    1: 483650,
    2: 429483,
    3: 362737,
    4: 314372,
    5: 266007,
    "revenu brut": 1501_000,
    trimf: 1500,
    1.5: 458650,
    2.5: 391983,
    3.5: 338555,
    4.5: 290190,
  },
  {
    1: 484050,
    2: 429883,
    3: 363037,
    4: 314632,
    5: 266227,
    "revenu brut": 1502000,
    trimf: 1500,
    1.5: 459050,
    2.5: 392383,
    3.5: 338835,
    4.5: 290430,
  },
  {
    1: 484450,
    2: 430283,
    3: 363337,
    4: 314892,
    5: 266447,
    "revenu brut": 1503000,
    trimf: 1500,
    1.5: 459450,
    2.5: 392783,
    3.5: 339115,
    4.5: 290670,
  },
  {
    1: 484850,
    2: 430683,
    3: 363637,
    4: 315152,
    5: 266667,
    "revenu brut": 1504000,
    trimf: 1500,
    1.5: 459850,
    2.5: 393183,
    3.5: 339395,
    4.5: 290910,
  },
  {
    1: 485250,
    2: 431083,
    3: 363937,
    4: 315412,
    5: 266887,
    "revenu brut": 1505000,
    trimf: 1500,
    1.5: 460250,
    2.5: 393583,
    3.5: 339675,
    4.5: 291150,
  },
  {
    1: 485650,
    2: 431483,
    3: 364237,
    4: 315672,
    5: 267107,
    "revenu brut": 1506000,
    trimf: 1500,
    1.5: 460650,
    2.5: 393983,
    3.5: 339955,
    4.5: 291390,
  },
  {
    1: 486050,
    2: 431883,
    3: 364537,
    4: 315932,
    5: 267327,
    "revenu brut": 1507000,
    trimf: 1500,
    1.5: 461050,
    2.5: 394383,
    3.5: 340235,
    4.5: 291630,
  },
  {
    1: 486450,
    2: 432283,
    3: 364837,
    4: 316192,
    5: 267547,
    "revenu brut": 1508000,
    trimf: 1500,
    1.5: 461450,
    2.5: 394783,
    3.5: 340515,
    4.5: 291870,
  },
  {
    1: 486850,
    2: 432683,
    3: 365137,
    4: 316452,
    5: 267767,
    "revenu brut": 1509000,
    trimf: 1500,
    1.5: 461850,
    2.5: 395183,
    3.5: 340795,
    4.5: 292110,
  },
  {
    1: 487250,
    2: 433083,
    3: 365437,
    4: 316712,
    5: 267987,
    "revenu brut": 151_0000,
    trimf: 1500,
    1.5: 462250,
    2.5: 395583,
    3.5: 341075,
    4.5: 292350,
  },
  {
    1: 487650,
    2: 433483,
    3: 365737,
    4: 316972,
    5: 268207,
    "revenu brut": 1511_000,
    trimf: 1500,
    1.5: 462650,
    2.5: 395983,
    3.5: 341355,
    4.5: 292590,
  },
  {
    1: 488050,
    2: 433883,
    3: 366037,
    4: 317232,
    5: 268427,
    "revenu brut": 1512000,
    trimf: 1500,
    1.5: 463050,
    2.5: 396383,
    3.5: 341635,
    4.5: 292830,
  },
  {
    1: 488450,
    2: 434283,
    3: 366337,
    4: 317492,
    5: 268647,
    "revenu brut": 1513000,
    trimf: 1500,
    1.5: 463450,
    2.5: 396783,
    3.5: 341915,
    4.5: 293070,
  },
  {
    1: 488850,
    2: 434683,
    3: 366637,
    4: 317752,
    5: 268867,
    "revenu brut": 1514000,
    trimf: 1500,
    1.5: 463850,
    2.5: 397183,
    3.5: 342195,
    4.5: 293310,
  },
  {
    1: 489250,
    2: 435083,
    3: 366937,
    4: 318012,
    5: 269087,
    "revenu brut": 1515000,
    trimf: 1500,
    1.5: 464250,
    2.5: 397583,
    3.5: 342475,
    4.5: 293550,
  },
  {
    1: 489650,
    2: 435483,
    3: 367237,
    4: 318272,
    5: 269307,
    "revenu brut": 1516000,
    trimf: 1500,
    1.5: 464650,
    2.5: 397983,
    3.5: 342755,
    4.5: 293790,
  },
  {
    1: 490050,
    2: 435883,
    3: 367537,
    4: 318532,
    5: 269527,
    "revenu brut": 1517000,
    trimf: 1500,
    1.5: 465050,
    2.5: 398383,
    3.5: 343035,
    4.5: 294030,
  },
  {
    1: 490450,
    2: 436283,
    3: 367837,
    4: 318792,
    5: 269747,
    "revenu brut": 1518000,
    trimf: 1500,
    1.5: 465450,
    2.5: 398783,
    3.5: 343315,
    4.5: 294270,
  },
  {
    1: 490850,
    2: 436683,
    3: 368137,
    4: 319052,
    5: 269967,
    "revenu brut": 1519000,
    trimf: 1500,
    1.5: 465850,
    2.5: 399183,
    3.5: 343595,
    4.5: 294510,
  },
  {
    1: 491250,
    2: 437083,
    3: 368437,
    4: 319312,
    5: 270187,
    "revenu brut": 1520000,
    trimf: 1500,
    1.5: 466250,
    2.5: 399583,
    3.5: 343875,
    4.5: 294750,
  },
  {
    1: 491650,
    2: 437483,
    3: 368737,
    4: 319572,
    5: 270407,
    "revenu brut": 1521_000,
    trimf: 1500,
    1.5: 466650,
    2.5: 399983,
    3.5: 344155,
    4.5: 294990,
  },
  {
    1: 492050,
    2: 437883,
    3: 369037,
    4: 319832,
    5: 270627,
    "revenu brut": 1522000,
    trimf: 1500,
    1.5: 467050,
    2.5: 400383,
    3.5: 344435,
    4.5: 295230,
  },
  {
    1: 492450,
    2: 438283,
    3: 369337,
    4: 320092,
    5: 270847,
    "revenu brut": 1523000,
    trimf: 1500,
    1.5: 467450,
    2.5: 400783,
    3.5: 344715,
    4.5: 295470,
  },
  {
    1: 492850,
    2: 438683,
    3: 369637,
    4: 320352,
    5: 271067,
    "revenu brut": 1524000,
    trimf: 1500,
    1.5: 467850,
    2.5: 401183,
    3.5: 344995,
    4.5: 295710,
  },
  {
    1: 493250,
    2: 439083,
    3: 369937,
    4: 320612,
    5: 271287,
    "revenu brut": 1525000,
    trimf: 1500,
    1.5: 468250,
    2.5: 401583,
    3.5: 345275,
    4.5: 295950,
  },
  {
    1: 493650,
    2: 439483,
    3: 370237,
    4: 320872,
    5: 271507,
    "revenu brut": 1526000,
    trimf: 1500,
    1.5: 468650,
    2.5: 401983,
    3.5: 345555,
    4.5: 296190,
  },
  {
    1: 494050,
    2: 439883,
    3: 370537,
    4: 321132,
    5: 271727,
    "revenu brut": 1527000,
    trimf: 1500,
    1.5: 469050,
    2.5: 402383,
    3.5: 345835,
    4.5: 296430,
  },
  {
    1: 494450,
    2: 440283,
    3: 370837,
    4: 321392,
    5: 271947,
    "revenu brut": 1528000,
    trimf: 1500,
    1.5: 469450,
    2.5: 402783,
    3.5: 346115,
    4.5: 296670,
  },
  {
    1: 494850,
    2: 440683,
    3: 371137,
    4: 321652,
    5: 272167,
    "revenu brut": 1529000,
    trimf: 1500,
    1.5: 469850,
    2.5: 403183,
    3.5: 346395,
    4.5: 296910,
  },
  {
    1: 495250,
    2: 441083,
    3: 371437,
    4: 321912,
    5: 272387,
    "revenu brut": 1530000,
    trimf: 1500,
    1.5: 470250,
    2.5: 403583,
    3.5: 346675,
    4.5: 297150,
  },
  {
    1: 495650,
    2: 441483,
    3: 371737,
    4: 322172,
    5: 272607,
    "revenu brut": 1531_000,
    trimf: 1500,
    1.5: 470650,
    2.5: 403983,
    3.5: 346955,
    4.5: 297390,
  },
  {
    1: 496050,
    2: 441883,
    3: 372037,
    4: 322432,
    5: 272827,
    "revenu brut": 1532000,
    trimf: 1500,
    1.5: 471050,
    2.5: 404383,
    3.5: 347235,
    4.5: 297630,
  },
  {
    1: 496450,
    2: 442283,
    3: 372337,
    4: 322692,
    5: 273047,
    "revenu brut": 1533000,
    trimf: 1500,
    1.5: 471450,
    2.5: 404783,
    3.5: 347515,
    4.5: 297870,
  },
  {
    1: 496850,
    2: 442683,
    3: 372637,
    4: 322952,
    5: 273267,
    "revenu brut": 1534000,
    trimf: 1500,
    1.5: 471850,
    2.5: 405183,
    3.5: 347795,
    4.5: 298110,
  },
  {
    1: 497250,
    2: 443083,
    3: 372937,
    4: 323212,
    5: 273487,
    "revenu brut": 1535000,
    trimf: 1500,
    1.5: 472250,
    2.5: 405583,
    3.5: 348075,
    4.5: 298350,
  },
  {
    1: 497650,
    2: 443483,
    3: 373237,
    4: 323472,
    5: 273707,
    "revenu brut": 1536000,
    trimf: 1500,
    1.5: 472650,
    2.5: 405983,
    3.5: 348355,
    4.5: 298590,
  },
  {
    1: 498050,
    2: 443883,
    3: 373537,
    4: 323732,
    5: 273927,
    "revenu brut": 1537000,
    trimf: 1500,
    1.5: 473050,
    2.5: 406383,
    3.5: 348635,
    4.5: 298830,
  },
  {
    1: 498450,
    2: 444283,
    3: 373837,
    4: 323992,
    5: 274147,
    "revenu brut": 1538000,
    trimf: 1500,
    1.5: 473450,
    2.5: 406783,
    3.5: 348915,
    4.5: 299070,
  },
  {
    1: 498850,
    2: 444683,
    3: 374137,
    4: 324252,
    5: 274367,
    "revenu brut": 1539000,
    trimf: 1500,
    1.5: 473850,
    2.5: 407183,
    3.5: 349195,
    4.5: 299310,
  },
  {
    1: 499250,
    2: 445083,
    3: 374437,
    4: 324512,
    5: 274587,
    "revenu brut": 1540000,
    trimf: 1500,
    1.5: 474250,
    2.5: 407583,
    3.5: 349475,
    4.5: 299550,
  },
  {
    1: 499650,
    2: 445483,
    3: 374737,
    4: 324772,
    5: 274807,
    "revenu brut": 1541_000,
    trimf: 1500,
    1.5: 474650,
    2.5: 407983,
    3.5: 349755,
    4.5: 299790,
  },
  {
    1: 500050,
    2: 445883,
    3: 375037,
    4: 325032,
    5: 275027,
    "revenu brut": 1542000,
    trimf: 1500,
    1.5: 475050,
    2.5: 408383,
    3.5: 350035,
    4.5: 300030,
  },
  {
    1: 500450,
    2: 446283,
    3: 375337,
    4: 325292,
    5: 275247,
    "revenu brut": 1543000,
    trimf: 1500,
    1.5: 475450,
    2.5: 408783,
    3.5: 350315,
    4.5: 300270,
  },
  {
    1: 500850,
    2: 446683,
    3: 375637,
    4: 325552,
    5: 275467,
    "revenu brut": 1544000,
    trimf: 1500,
    1.5: 475850,
    2.5: 409183,
    3.5: 350595,
    4.5: 300510,
  },
  {
    1: 501250,
    2: 447083,
    3: 375937,
    4: 325812,
    5: 275687,
    "revenu brut": 1545000,
    trimf: 1500,
    1.5: 476250,
    2.5: 409583,
    3.5: 350875,
    4.5: 300750,
  },
  {
    1: 501650,
    2: 447483,
    3: 376237,
    4: 326072,
    5: 275907,
    "revenu brut": 1546000,
    trimf: 1500,
    1.5: 476650,
    2.5: 409983,
    3.5: 351155,
    4.5: 300990,
  },
  {
    1: 502050,
    2: 447883,
    3: 376537,
    4: 326332,
    5: 276127,
    "revenu brut": 1547000,
    trimf: 1500,
    1.5: 477050,
    2.5: 410383,
    3.5: 351435,
    4.5: 301230,
  },
  {
    1: 502450,
    2: 448283,
    3: 376837,
    4: 326592,
    5: 276347,
    "revenu brut": 1548000,
    trimf: 1500,
    1.5: 477450,
    2.5: 410783,
    3.5: 351715,
    4.5: 301470,
  },
  {
    1: 502850,
    2: 448683,
    3: 377137,
    4: 326852,
    5: 276567,
    "revenu brut": 1549000,
    trimf: 1500,
    1.5: 477850,
    2.5: 411183,
    3.5: 351995,
    4.5: 301710,
  },
  {
    1: 503250,
    2: 449083,
    3: 377437,
    4: 327112,
    5: 276787,
    "revenu brut": 1550000,
    trimf: 1500,
    1.5: 478250,
    2.5: 411583,
    3.5: 352275,
    4.5: 301950,
  },
  {
    1: 503650,
    2: 449483,
    3: 377737,
    4: 327372,
    5: 277007,
    "revenu brut": 1551_000,
    trimf: 1500,
    1.5: 478650,
    2.5: 411983,
    3.5: 352555,
    4.5: 302190,
  },
  {
    1: 504050,
    2: 449883,
    3: 378037,
    4: 327632,
    5: 277227,
    "revenu brut": 1552000,
    trimf: 1500,
    1.5: 479050,
    2.5: 412383,
    3.5: 352835,
    4.5: 302430,
  },
  {
    1: 504450,
    2: 450283,
    3: 378337,
    4: 327892,
    5: 277447,
    "revenu brut": 1553000,
    trimf: 1500,
    1.5: 479450,
    2.5: 412783,
    3.5: 353115,
    4.5: 302670,
  },
  {
    1: 504850,
    2: 450683,
    3: 378637,
    4: 328152,
    5: 277667,
    "revenu brut": 1554000,
    trimf: 1500,
    1.5: 479850,
    2.5: 413183,
    3.5: 353395,
    4.5: 302910,
  },
  {
    1: 505250,
    2: 451083,
    3: 378937,
    4: 328412,
    5: 277887,
    "revenu brut": 1555000,
    trimf: 1500,
    1.5: 480250,
    2.5: 413583,
    3.5: 353675,
    4.5: 303150,
  },
  {
    1: 505650,
    2: 451483,
    3: 379237,
    4: 328672,
    5: 278107,
    "revenu brut": 1556000,
    trimf: 1500,
    1.5: 480650,
    2.5: 413983,
    3.5: 353955,
    4.5: 303390,
  },
  {
    1: 506050,
    2: 451883,
    3: 379537,
    4: 328932,
    5: 278327,
    "revenu brut": 1557000,
    trimf: 1500,
    1.5: 481050,
    2.5: 414383,
    3.5: 354235,
    4.5: 303630,
  },
  {
    1: 506450,
    2: 452283,
    3: 379837,
    4: 329192,
    5: 278547,
    "revenu brut": 1558000,
    trimf: 1500,
    1.5: 481450,
    2.5: 414783,
    3.5: 354515,
    4.5: 303870,
  },
  {
    1: 506850,
    2: 452683,
    3: 380137,
    4: 329452,
    5: 278767,
    "revenu brut": 1559000,
    trimf: 1500,
    1.5: 481850,
    2.5: 415183,
    3.5: 354795,
    4.5: 304110,
  },
  {
    1: 507250,
    2: 453083,
    3: 380437,
    4: 329712,
    5: 278987,
    "revenu brut": 1560000,
    trimf: 1500,
    1.5: 482250,
    2.5: 415583,
    3.5: 355075,
    4.5: 304350,
  },
  {
    1: 507650,
    2: 453483,
    3: 380737,
    4: 329972,
    5: 279207,
    "revenu brut": 1561_000,
    trimf: 1500,
    1.5: 482650,
    2.5: 415983,
    3.5: 355355,
    4.5: 304590,
  },
  {
    1: 508050,
    2: 453883,
    3: 381037,
    4: 330232,
    5: 279427,
    "revenu brut": 1562000,
    trimf: 1500,
    1.5: 483050,
    2.5: 416383,
    3.5: 355635,
    4.5: 304830,
  },
  {
    1: 508450,
    2: 454283,
    3: 381337,
    4: 330492,
    5: 279647,
    "revenu brut": 1563000,
    trimf: 1500,
    1.5: 483450,
    2.5: 416783,
    3.5: 355915,
    4.5: 305070,
  },
  {
    1: 508850,
    2: 454683,
    3: 381637,
    4: 330752,
    5: 279867,
    "revenu brut": 1564000,
    trimf: 1500,
    1.5: 483850,
    2.5: 417183,
    3.5: 356195,
    4.5: 305310,
  },
  {
    1: 509250,
    2: 455083,
    3: 381937,
    4: 331012,
    5: 280087,
    "revenu brut": 1565000,
    trimf: 1500,
    1.5: 484250,
    2.5: 417583,
    3.5: 356475,
    4.5: 305550,
  },
  {
    1: 509650,
    2: 455483,
    3: 382237,
    4: 331272,
    5: 280307,
    "revenu brut": 1566000,
    trimf: 1500,
    1.5: 484650,
    2.5: 417983,
    3.5: 356755,
    4.5: 305790,
  },
  {
    1: 510050,
    2: 455883,
    3: 382537,
    4: 331532,
    5: 280527,
    "revenu brut": 1567000,
    trimf: 1500,
    1.5: 485050,
    2.5: 418383,
    3.5: 357035,
    4.5: 306030,
  },
  {
    1: 510450,
    2: 456283,
    3: 382837,
    4: 331792,
    5: 280747,
    "revenu brut": 1568000,
    trimf: 1500,
    1.5: 485450,
    2.5: 418783,
    3.5: 357315,
    4.5: 306270,
  },
  {
    1: 510850,
    2: 456683,
    3: 383137,
    4: 332052,
    5: 280967,
    "revenu brut": 1569000,
    trimf: 1500,
    1.5: 485850,
    2.5: 419183,
    3.5: 357595,
    4.5: 306510,
  },
  {
    1: 511250,
    2: 457083,
    3: 383437,
    4: 332312,
    5: 281187,
    "revenu brut": 1570000,
    trimf: 1500,
    1.5: 486250,
    2.5: 419583,
    3.5: 357875,
    4.5: 306750,
  },
  {
    1: 511650,
    2: 457483,
    3: 383737,
    4: 332572,
    5: 281407,
    "revenu brut": 1571_000,
    trimf: 1500,
    1.5: 486650,
    2.5: 419983,
    3.5: 358155,
    4.5: 306990,
  },
  {
    1: 512050,
    2: 457883,
    3: 384037,
    4: 332832,
    5: 281627,
    "revenu brut": 1572000,
    trimf: 1500,
    1.5: 487050,
    2.5: 420383,
    3.5: 358435,
    4.5: 307230,
  },
  {
    1: 512450,
    2: 458283,
    3: 384337,
    4: 333092,
    5: 281847,
    "revenu brut": 1573000,
    trimf: 1500,
    1.5: 487450,
    2.5: 420783,
    3.5: 358715,
    4.5: 307470,
  },
  {
    1: 512850,
    2: 458683,
    3: 384637,
    4: 333352,
    5: 282067,
    "revenu brut": 1574000,
    trimf: 1500,
    1.5: 487850,
    2.5: 421183,
    3.5: 358995,
    4.5: 307710,
  },
  {
    1: 513250,
    2: 459083,
    3: 384937,
    4: 333612,
    5: 282287,
    "revenu brut": 1575000,
    trimf: 1500,
    1.5: 488250,
    2.5: 421583,
    3.5: 359275,
    4.5: 307950,
  },
  {
    1: 513650,
    2: 459483,
    3: 385237,
    4: 333872,
    5: 282507,
    "revenu brut": 1576000,
    trimf: 1500,
    1.5: 488650,
    2.5: 421983,
    3.5: 359555,
    4.5: 308190,
  },
  {
    1: 514050,
    2: 459883,
    3: 385537,
    4: 334132,
    5: 282727,
    "revenu brut": 1577000,
    trimf: 1500,
    1.5: 489050,
    2.5: 422383,
    3.5: 359835,
    4.5: 308430,
  },
  {
    1: 514450,
    2: 460283,
    3: 385837,
    4: 334392,
    5: 282947,
    "revenu brut": 1578000,
    trimf: 1500,
    1.5: 489450,
    2.5: 422783,
    3.5: 360115,
    4.5: 308670,
  },
  {
    1: 514850,
    2: 460683,
    3: 386137,
    4: 334652,
    5: 283167,
    "revenu brut": 1579000,
    trimf: 1500,
    1.5: 489850,
    2.5: 423183,
    3.5: 360395,
    4.5: 308910,
  },
  {
    1: 515250,
    2: 461083,
    3: 386437,
    4: 334912,
    5: 283387,
    "revenu brut": 1580000,
    trimf: 1500,
    1.5: 490250,
    2.5: 423583,
    3.5: 360675,
    4.5: 309150,
  },
  {
    1: 515650,
    2: 461483,
    3: 386737,
    4: 335172,
    5: 283607,
    "revenu brut": 1581_000,
    trimf: 1500,
    1.5: 490650,
    2.5: 423983,
    3.5: 360955,
    4.5: 309390,
  },
  {
    1: 516050,
    2: 461883,
    3: 387037,
    4: 335432,
    5: 283827,
    "revenu brut": 1582000,
    trimf: 1500,
    1.5: 491050,
    2.5: 424383,
    3.5: 361235,
    4.5: 309630,
  },
  {
    1: 516450,
    2: 462283,
    3: 387337,
    4: 335692,
    5: 284047,
    "revenu brut": 1583000,
    trimf: 1500,
    1.5: 491450,
    2.5: 424783,
    3.5: 361515,
    4.5: 309870,
  },
  {
    1: 516850,
    2: 462683,
    3: 387637,
    4: 335952,
    5: 284267,
    "revenu brut": 1584000,
    trimf: 1500,
    1.5: 491850,
    2.5: 425183,
    3.5: 361795,
    4.5: 310110,
  },
  {
    1: 517250,
    2: 463083,
    3: 387937,
    4: 336212,
    5: 284487,
    "revenu brut": 1585000,
    trimf: 1500,
    1.5: 492250,
    2.5: 425583,
    3.5: 362075,
    4.5: 310350,
  },
  {
    1: 517650,
    2: 463483,
    3: 388237,
    4: 336472,
    5: 284707,
    "revenu brut": 1586000,
    trimf: 1500,
    1.5: 492650,
    2.5: 425983,
    3.5: 362355,
    4.5: 310590,
  },
  {
    1: 518050,
    2: 463883,
    3: 388537,
    4: 336732,
    5: 284927,
    "revenu brut": 1587000,
    trimf: 1500,
    1.5: 493050,
    2.5: 426383,
    3.5: 362635,
    4.5: 310830,
  },
  {
    1: 518450,
    2: 464283,
    3: 388837,
    4: 336992,
    5: 285147,
    "revenu brut": 1588000,
    trimf: 1500,
    1.5: 493450,
    2.5: 426783,
    3.5: 362915,
    4.5: 311070,
  },
  {
    1: 518850,
    2: 464683,
    3: 389137,
    4: 337252,
    5: 285367,
    "revenu brut": 1589000,
    trimf: 1500,
    1.5: 493850,
    2.5: 427183,
    3.5: 363195,
    4.5: 311310,
  },
  {
    1: 519250,
    2: 465083,
    3: 389437,
    4: 337512,
    5: 285587,
    "revenu brut": 1590000,
    trimf: 1500,
    1.5: 494250,
    2.5: 427583,
    3.5: 363475,
    4.5: 311550,
  },
  {
    1: 519650,
    2: 465483,
    3: 389737,
    4: 337772,
    5: 285807,
    "revenu brut": 1591_000,
    trimf: 1500,
    1.5: 494650,
    2.5: 427983,
    3.5: 363755,
    4.5: 311790,
  },
  {
    1: 520050,
    2: 465883,
    3: 390037,
    4: 338032,
    5: 286027,
    "revenu brut": 1592000,
    trimf: 1500,
    1.5: 495050,
    2.5: 428383,
    3.5: 364035,
    4.5: 312030,
  },
  {
    1: 520450,
    2: 466283,
    3: 390337,
    4: 338292,
    5: 286247,
    "revenu brut": 1593000,
    trimf: 1500,
    1.5: 495450,
    2.5: 428783,
    3.5: 364315,
    4.5: 312270,
  },
  {
    1: 520850,
    2: 466683,
    3: 390637,
    4: 338552,
    5: 286467,
    "revenu brut": 1594000,
    trimf: 1500,
    1.5: 495850,
    2.5: 429183,
    3.5: 364595,
    4.5: 312510,
  },
  {
    1: 521250,
    2: 467083,
    3: 390937,
    4: 338812,
    5: 286687,
    "revenu brut": 1595000,
    trimf: 1500,
    1.5: 496250,
    2.5: 429583,
    3.5: 364875,
    4.5: 312750,
  },
  {
    1: 521650,
    2: 467483,
    3: 391237,
    4: 339072,
    5: 286907,
    "revenu brut": 1596000,
    trimf: 1500,
    1.5: 496650,
    2.5: 429983,
    3.5: 365155,
    4.5: 312990,
  },
  {
    1: 522050,
    2: 467883,
    3: 391537,
    4: 339332,
    5: 287127,
    "revenu brut": 1597000,
    trimf: 1500,
    1.5: 497050,
    2.5: 430383,
    3.5: 365435,
    4.5: 313230,
  },
  {
    1: 522450,
    2: 468283,
    3: 391837,
    4: 339592,
    5: 287347,
    "revenu brut": 1598000,
    trimf: 1500,
    1.5: 497450,
    2.5: 430783,
    3.5: 365715,
    4.5: 313470,
  },
  {
    1: 522850,
    2: 468683,
    3: 392137,
    4: 339852,
    5: 287567,
    "revenu brut": 1599000,
    trimf: 1500,
    1.5: 497850,
    2.5: 431183,
    3.5: 365995,
    4.5: 313710,
  },
  {
    1: 523250,
    2: 469083,
    3: 392437,
    4: 340112,
    5: 287787,
    "revenu brut": 1600000,
    trimf: 1500,
    1.5: 498250,
    2.5: 431583,
    3.5: 366275,
    4.5: 313950,
  },
  {
    1: 523650,
    2: 469483,
    3: 392737,
    4: 340372,
    5: 288007,
    "revenu brut": 1601_000,
    trimf: 1500,
    1.5: 498650,
    2.5: 431983,
    3.5: 366555,
    4.5: 314190,
  },
  {
    1: 524050,
    2: 469883,
    3: 393037,
    4: 340632,
    5: 288227,
    "revenu brut": 1602000,
    trimf: 1500,
    1.5: 499050,
    2.5: 432383,
    3.5: 366835,
    4.5: 314430,
  },
  {
    1: 524450,
    2: 470283,
    3: 393337,
    4: 340892,
    5: 288447,
    "revenu brut": 1603000,
    trimf: 1500,
    1.5: 499450,
    2.5: 432783,
    3.5: 367115,
    4.5: 314670,
  },
  {
    1: 524850,
    2: 470683,
    3: 393637,
    4: 341152,
    5: 288667,
    "revenu brut": 1604000,
    trimf: 1500,
    1.5: 499850,
    2.5: 433183,
    3.5: 367395,
    4.5: 314910,
  },
  {
    1: 525250,
    2: 471083,
    3: 393937,
    4: 341412,
    5: 288887,
    "revenu brut": 1605000,
    trimf: 1500,
    1.5: 500250,
    2.5: 433583,
    3.5: 367675,
    4.5: 315150,
  },
  {
    1: 525650,
    2: 471483,
    3: 394237,
    4: 341672,
    5: 289107,
    "revenu brut": 1606000,
    trimf: 1500,
    1.5: 500650,
    2.5: 433983,
    3.5: 367955,
    4.5: 315390,
  },
  {
    1: 526050,
    2: 471883,
    3: 394537,
    4: 341932,
    5: 289327,
    "revenu brut": 1607000,
    trimf: 1500,
    1.5: 501050,
    2.5: 434383,
    3.5: 368235,
    4.5: 315630,
  },
  {
    1: 526450,
    2: 472283,
    3: 394837,
    4: 342192,
    5: 289547,
    "revenu brut": 1608000,
    trimf: 1500,
    1.5: 501450,
    2.5: 434783,
    3.5: 368515,
    4.5: 315870,
  },
  {
    1: 526850,
    2: 472683,
    3: 395137,
    4: 342452,
    5: 289767,
    "revenu brut": 1609000,
    trimf: 1500,
    1.5: 501850,
    2.5: 435183,
    3.5: 368795,
    4.5: 316110,
  },
  {
    1: 527250,
    2: 473083,
    3: 395437,
    4: 342712,
    5: 289987,
    "revenu brut": 161_0000,
    trimf: 1500,
    1.5: 502250,
    2.5: 435583,
    3.5: 369075,
    4.5: 316350,
  },
  {
    1: 527650,
    2: 473483,
    3: 395737,
    4: 342972,
    5: 290207,
    "revenu brut": 1611_000,
    trimf: 1500,
    1.5: 502650,
    2.5: 435983,
    3.5: 369355,
    4.5: 316590,
  },
  {
    1: 528050,
    2: 473883,
    3: 396037,
    4: 343232,
    5: 290427,
    "revenu brut": 1612000,
    trimf: 1500,
    1.5: 503050,
    2.5: 436383,
    3.5: 369635,
    4.5: 316830,
  },
  {
    1: 528450,
    2: 474283,
    3: 396337,
    4: 343492,
    5: 290647,
    "revenu brut": 1613000,
    trimf: 1500,
    1.5: 503450,
    2.5: 436783,
    3.5: 369915,
    4.5: 317070,
  },
  {
    1: 528850,
    2: 474683,
    3: 396637,
    4: 343752,
    5: 290867,
    "revenu brut": 1614000,
    trimf: 1500,
    1.5: 503850,
    2.5: 437183,
    3.5: 370195,
    4.5: 317310,
  },
  {
    1: 529250,
    2: 475083,
    3: 396937,
    4: 344012,
    5: 291087,
    "revenu brut": 1615000,
    trimf: 1500,
    1.5: 504250,
    2.5: 437583,
    3.5: 370475,
    4.5: 317550,
  },
  {
    1: 529650,
    2: 475483,
    3: 397237,
    4: 344272,
    5: 291307,
    "revenu brut": 1616000,
    trimf: 1500,
    1.5: 504650,
    2.5: 437983,
    3.5: 370755,
    4.5: 317790,
  },
  {
    1: 530050,
    2: 475883,
    3: 397537,
    4: 344532,
    5: 291527,
    "revenu brut": 1617000,
    trimf: 1500,
    1.5: 505050,
    2.5: 438383,
    3.5: 371035,
    4.5: 318030,
  },
  {
    1: 530450,
    2: 476283,
    3: 397837,
    4: 344792,
    5: 291747,
    "revenu brut": 1618000,
    trimf: 1500,
    1.5: 505450,
    2.5: 438783,
    3.5: 371315,
    4.5: 318270,
  },
  {
    1: 530850,
    2: 476683,
    3: 398137,
    4: 345052,
    5: 291967,
    "revenu brut": 1619000,
    trimf: 1500,
    1.5: 505850,
    2.5: 439183,
    3.5: 371595,
    4.5: 318510,
  },
  {
    1: 531250,
    2: 477083,
    3: 398437,
    4: 345312,
    5: 292187,
    "revenu brut": 1620000,
    trimf: 1500,
    1.5: 506250,
    2.5: 439583,
    3.5: 371875,
    4.5: 318750,
  },
  {
    1: 531650,
    2: 477483,
    3: 398737,
    4: 345572,
    5: 292407,
    "revenu brut": 1621_000,
    trimf: 1500,
    1.5: 506650,
    2.5: 439983,
    3.5: 372155,
    4.5: 318990,
  },
  {
    1: 532050,
    2: 477883,
    3: 399037,
    4: 345832,
    5: 292627,
    "revenu brut": 1622000,
    trimf: 1500,
    1.5: 507050,
    2.5: 440383,
    3.5: 372435,
    4.5: 319230,
  },
  {
    1: 532450,
    2: 478283,
    3: 399337,
    4: 346092,
    5: 292847,
    "revenu brut": 1623000,
    trimf: 1500,
    1.5: 507450,
    2.5: 440783,
    3.5: 372715,
    4.5: 319470,
  },
  {
    1: 532850,
    2: 478683,
    3: 399637,
    4: 346352,
    5: 293067,
    "revenu brut": 1624000,
    trimf: 1500,
    1.5: 507850,
    2.5: 441183,
    3.5: 372995,
    4.5: 319710,
  },
  {
    1: 533250,
    2: 479083,
    3: 399937,
    4: 346612,
    5: 293287,
    "revenu brut": 1625000,
    trimf: 1500,
    1.5: 508250,
    2.5: 441583,
    3.5: 373275,
    4.5: 319950,
  },
  {
    1: 533650,
    2: 479483,
    3: 400237,
    4: 346872,
    5: 293507,
    "revenu brut": 1626000,
    trimf: 1500,
    1.5: 508650,
    2.5: 441983,
    3.5: 373555,
    4.5: 320190,
  },
  {
    1: 534050,
    2: 479883,
    3: 400537,
    4: 347132,
    5: 293727,
    "revenu brut": 1627000,
    trimf: 1500,
    1.5: 509050,
    2.5: 442383,
    3.5: 373835,
    4.5: 320430,
  },
  {
    1: 534450,
    2: 480283,
    3: 400837,
    4: 347392,
    5: 293947,
    "revenu brut": 1628000,
    trimf: 1500,
    1.5: 509450,
    2.5: 442783,
    3.5: 374115,
    4.5: 320670,
  },
  {
    1: 534850,
    2: 480683,
    3: 401137,
    4: 347652,
    5: 294167,
    "revenu brut": 1629000,
    trimf: 1500,
    1.5: 509850,
    2.5: 443183,
    3.5: 374395,
    4.5: 320910,
  },
  {
    1: 535250,
    2: 481083,
    3: 401437,
    4: 347912,
    5: 294387,
    "revenu brut": 1630000,
    trimf: 1500,
    1.5: 510250,
    2.5: 443583,
    3.5: 374675,
    4.5: 321150,
  },
  {
    1: 535650,
    2: 481483,
    3: 401737,
    4: 348172,
    5: 294607,
    "revenu brut": 1631_000,
    trimf: 1500,
    1.5: 510650,
    2.5: 443983,
    3.5: 374955,
    4.5: 321390,
  },
  {
    1: 536050,
    2: 481883,
    3: 402037,
    4: 348432,
    5: 294827,
    "revenu brut": 1632000,
    trimf: 1500,
    1.5: 511050,
    2.5: 444383,
    3.5: 375235,
    4.5: 321630,
  },
  {
    1: 536450,
    2: 482283,
    3: 402337,
    4: 348692,
    5: 295047,
    "revenu brut": 1633000,
    trimf: 1500,
    1.5: 511450,
    2.5: 444783,
    3.5: 375515,
    4.5: 321870,
  },
  {
    1: 536850,
    2: 482683,
    3: 402637,
    4: 348952,
    5: 295267,
    "revenu brut": 1634000,
    trimf: 1500,
    1.5: 511850,
    2.5: 445183,
    3.5: 375795,
    4.5: 322110,
  },
  {
    1: 537250,
    2: 483083,
    3: 402937,
    4: 349212,
    5: 295487,
    "revenu brut": 1635000,
    trimf: 1500,
    1.5: 512250,
    2.5: 445583,
    3.5: 376075,
    4.5: 322350,
  },
  {
    1: 537650,
    2: 483483,
    3: 403237,
    4: 349472,
    5: 295707,
    "revenu brut": 1636000,
    trimf: 1500,
    1.5: 512650,
    2.5: 445983,
    3.5: 376355,
    4.5: 322590,
  },
  {
    1: 538050,
    2: 483883,
    3: 403537,
    4: 349732,
    5: 295927,
    "revenu brut": 1637000,
    trimf: 1500,
    1.5: 513050,
    2.5: 446383,
    3.5: 376635,
    4.5: 322830,
  },
  {
    1: 538450,
    2: 484283,
    3: 403837,
    4: 349992,
    5: 296147,
    "revenu brut": 1638000,
    trimf: 1500,
    1.5: 513450,
    2.5: 446783,
    3.5: 376915,
    4.5: 323070,
  },
  {
    1: 538850,
    2: 484683,
    3: 404137,
    4: 350252,
    5: 296367,
    "revenu brut": 1639000,
    trimf: 1500,
    1.5: 513850,
    2.5: 447183,
    3.5: 377195,
    4.5: 323310,
  },
  {
    1: 539250,
    2: 485083,
    3: 404437,
    4: 350512,
    5: 296587,
    "revenu brut": 1640000,
    trimf: 1500,
    1.5: 514250,
    2.5: 447583,
    3.5: 377475,
    4.5: 323550,
  },
  {
    1: 539650,
    2: 485483,
    3: 404737,
    4: 350772,
    5: 296807,
    "revenu brut": 1641_000,
    trimf: 1500,
    1.5: 514650,
    2.5: 447983,
    3.5: 377755,
    4.5: 323790,
  },
  {
    1: 540050,
    2: 485883,
    3: 405037,
    4: 351032,
    5: 297027,
    "revenu brut": 1642000,
    trimf: 1500,
    1.5: 515050,
    2.5: 448383,
    3.5: 378035,
    4.5: 324030,
  },
  {
    1: 540450,
    2: 486283,
    3: 405337,
    4: 351292,
    5: 297247,
    "revenu brut": 1643000,
    trimf: 1500,
    1.5: 515450,
    2.5: 448783,
    3.5: 378315,
    4.5: 324270,
  },
  {
    1: 540850,
    2: 486683,
    3: 405637,
    4: 351552,
    5: 297467,
    "revenu brut": 1644000,
    trimf: 1500,
    1.5: 515850,
    2.5: 449183,
    3.5: 378595,
    4.5: 324510,
  },
  {
    1: 541250,
    2: 487083,
    3: 405937,
    4: 351812,
    5: 297687,
    "revenu brut": 1645000,
    trimf: 1500,
    1.5: 516250,
    2.5: 449583,
    3.5: 378875,
    4.5: 324750,
  },
  {
    1: 541650,
    2: 487483,
    3: 406237,
    4: 352072,
    5: 297907,
    "revenu brut": 1646000,
    trimf: 1500,
    1.5: 516650,
    2.5: 449983,
    3.5: 379155,
    4.5: 324990,
  },
  {
    1: 542050,
    2: 487883,
    3: 406537,
    4: 352332,
    5: 298127,
    "revenu brut": 1647000,
    trimf: 1500,
    1.5: 517050,
    2.5: 450383,
    3.5: 379435,
    4.5: 325230,
  },
  {
    1: 542450,
    2: 488283,
    3: 406837,
    4: 352592,
    5: 298347,
    "revenu brut": 1648000,
    trimf: 1500,
    1.5: 517450,
    2.5: 450783,
    3.5: 379715,
    4.5: 325470,
  },
  {
    1: 542850,
    2: 488683,
    3: 407137,
    4: 352852,
    5: 298567,
    "revenu brut": 1649000,
    trimf: 1500,
    1.5: 517850,
    2.5: 451183,
    3.5: 379995,
    4.5: 325710,
  },
  {
    1: 543250,
    2: 489083,
    3: 407437,
    4: 353112,
    5: 298787,
    "revenu brut": 1650000,
    trimf: 1500,
    1.5: 518250,
    2.5: 451583,
    3.5: 380275,
    4.5: 325950,
  },
  {
    1: 543650,
    2: 489483,
    3: 407737,
    4: 353372,
    5: 299007,
    "revenu brut": 1651_000,
    trimf: 1500,
    1.5: 518650,
    2.5: 451983,
    3.5: 380555,
    4.5: 326190,
  },
  {
    1: 544050,
    2: 489883,
    3: 408037,
    4: 353632,
    5: 299227,
    "revenu brut": 1652000,
    trimf: 1500,
    1.5: 519050,
    2.5: 452383,
    3.5: 380835,
    4.5: 326430,
  },
  {
    1: 544450,
    2: 490283,
    3: 408337,
    4: 353892,
    5: 299447,
    "revenu brut": 1653000,
    trimf: 1500,
    1.5: 519450,
    2.5: 452783,
    3.5: 381115,
    4.5: 326670,
  },
  {
    1: 544850,
    2: 490683,
    3: 408637,
    4: 354152,
    5: 299667,
    "revenu brut": 1654000,
    trimf: 1500,
    1.5: 519850,
    2.5: 453183,
    3.5: 381395,
    4.5: 326910,
  },
  {
    1: 545250,
    2: 491083,
    3: 408937,
    4: 354412,
    5: 299887,
    "revenu brut": 1655000,
    trimf: 1500,
    1.5: 520250,
    2.5: 453583,
    3.5: 381675,
    4.5: 327150,
  },
  {
    1: 545650,
    2: 491483,
    3: 409237,
    4: 354672,
    5: 300107,
    "revenu brut": 1656000,
    trimf: 1500,
    1.5: 520650,
    2.5: 453983,
    3.5: 381955,
    4.5: 327390,
  },
  {
    1: 546050,
    2: 491883,
    3: 409537,
    4: 354932,
    5: 300327,
    "revenu brut": 1657000,
    trimf: 1500,
    1.5: 521050,
    2.5: 454383,
    3.5: 382235,
    4.5: 327630,
  },
  {
    1: 546450,
    2: 492283,
    3: 409837,
    4: 355192,
    5: 300547,
    "revenu brut": 1658000,
    trimf: 1500,
    1.5: 521450,
    2.5: 454783,
    3.5: 382515,
    4.5: 327870,
  },
  {
    1: 546850,
    2: 492683,
    3: 410137,
    4: 355452,
    5: 300767,
    "revenu brut": 1659000,
    trimf: 1500,
    1.5: 521850,
    2.5: 455183,
    3.5: 382795,
    4.5: 328110,
  },
  {
    1: 547250,
    2: 493083,
    3: 410437,
    4: 355712,
    5: 300987,
    "revenu brut": 1660000,
    trimf: 1500,
    1.5: 522250,
    2.5: 455583,
    3.5: 383075,
    4.5: 328350,
  },
  {
    1: 547650,
    2: 493483,
    3: 410737,
    4: 355972,
    5: 301207,
    "revenu brut": 1661_000,
    trimf: 1500,
    1.5: 522650,
    2.5: 455983,
    3.5: 383355,
    4.5: 328590,
  },
  {
    1: 548050,
    2: 493883,
    3: 411037,
    4: 356232,
    5: 301427,
    "revenu brut": 1662000,
    trimf: 1500,
    1.5: 523050,
    2.5: 456383,
    3.5: 383635,
    4.5: 328830,
  },
  {
    1: 548450,
    2: 494283,
    3: 411337,
    4: 356492,
    5: 301647,
    "revenu brut": 1663000,
    trimf: 1500,
    1.5: 523450,
    2.5: 456783,
    3.5: 383915,
    4.5: 329070,
  },
  {
    1: 548850,
    2: 494683,
    3: 411637,
    4: 356752,
    5: 301867,
    "revenu brut": 1664000,
    trimf: 1500,
    1.5: 523850,
    2.5: 457183,
    3.5: 384195,
    4.5: 329310,
  },
  {
    1: 549250,
    2: 495083,
    3: 411937,
    4: 357012,
    5: 302087,
    "revenu brut": 1665000,
    trimf: 1500,
    1.5: 524250,
    2.5: 457583,
    3.5: 384475,
    4.5: 329550,
  },
  {
    1: 549650,
    2: 495483,
    3: 412237,
    4: 357272,
    5: 302307,
    "revenu brut": 1666000,
    trimf: 1500,
    1.5: 524650,
    2.5: 457983,
    3.5: 384755,
    4.5: 329790,
  },
  {
    1: 550050,
    2: 495883,
    3: 412550,
    4: 357532,
    5: 302527,
    "revenu brut": 1667000,
    trimf: 1500,
    1.5: 525050,
    2.5: 458383,
    3.5: 385035,
    4.5: 330030,
  },
  {
    1: 550450,
    2: 496283,
    3: 412950,
    4: 357792,
    5: 302747,
    "revenu brut": 1668000,
    trimf: 1500,
    1.5: 525450,
    2.5: 458783,
    3.5: 385315,
    4.5: 330270,
  },
  {
    1: 550850,
    2: 496683,
    3: 413350,
    4: 358052,
    5: 302967,
    "revenu brut": 1669000,
    trimf: 1500,
    1.5: 525850,
    2.5: 459183,
    3.5: 385595,
    4.5: 330510,
  },
  {
    1: 551250,
    2: 497083,
    3: 413750,
    4: 358312,
    5: 303187,
    "revenu brut": 1670000,
    trimf: 1500,
    1.5: 526250,
    2.5: 459583,
    3.5: 385875,
    4.5: 330750,
  },
  {
    1: 551650,
    2: 497483,
    3: 414150,
    4: 358572,
    5: 303407,
    "revenu brut": 1671_000,
    trimf: 1500,
    1.5: 526650,
    2.5: 459983,
    3.5: 386155,
    4.5: 330990,
  },
  {
    1: 552050,
    2: 497883,
    3: 414550,
    4: 358832,
    5: 303627,
    "revenu brut": 1672000,
    trimf: 1500,
    1.5: 527050,
    2.5: 460383,
    3.5: 386435,
    4.5: 331230,
  },
  {
    1: 552450,
    2: 498283,
    3: 414950,
    4: 359092,
    5: 303847,
    "revenu brut": 1673000,
    trimf: 1500,
    1.5: 527450,
    2.5: 460783,
    3.5: 386715,
    4.5: 331470,
  },
  {
    1: 552850,
    2: 498683,
    3: 415350,
    4: 359352,
    5: 304067,
    "revenu brut": 1674000,
    trimf: 1500,
    1.5: 527850,
    2.5: 461183,
    3.5: 386995,
    4.5: 331710,
  },
  {
    1: 553250,
    2: 499083,
    3: 415750,
    4: 359612,
    5: 304287,
    "revenu brut": 1675000,
    trimf: 1500,
    1.5: 528250,
    2.5: 461583,
    3.5: 387275,
    4.5: 331950,
  },
  {
    1: 553650,
    2: 499483,
    3: 416150,
    4: 359872,
    5: 304507,
    "revenu brut": 1676000,
    trimf: 1500,
    1.5: 528650,
    2.5: 461983,
    3.5: 387555,
    4.5: 332190,
  },
  {
    1: 554050,
    2: 499883,
    3: 416550,
    4: 360132,
    5: 304727,
    "revenu brut": 1677000,
    trimf: 1500,
    1.5: 529050,
    2.5: 462383,
    3.5: 387835,
    4.5: 332430,
  },
  {
    1: 554450,
    2: 500283,
    3: 416950,
    4: 360392,
    5: 304947,
    "revenu brut": 1678000,
    trimf: 1500,
    1.5: 529450,
    2.5: 462783,
    3.5: 388115,
    4.5: 332670,
  },
  {
    1: 554850,
    2: 500683,
    3: 417350,
    4: 360652,
    5: 305167,
    "revenu brut": 1679000,
    trimf: 1500,
    1.5: 529850,
    2.5: 463183,
    3.5: 388395,
    4.5: 332910,
  },
  {
    1: 555250,
    2: 501083,
    3: 417750,
    4: 360912,
    5: 305387,
    "revenu brut": 1680000,
    trimf: 1500,
    1.5: 530250,
    2.5: 463583,
    3.5: 388675,
    4.5: 333150,
  },
  {
    1: 555650,
    2: 501483,
    3: 418150,
    4: 361172,
    5: 305607,
    "revenu brut": 1681_000,
    trimf: 1500,
    1.5: 530650,
    2.5: 463983,
    3.5: 388955,
    4.5: 333390,
  },
  {
    1: 556050,
    2: 501883,
    3: 418550,
    4: 361432,
    5: 305827,
    "revenu brut": 1682000,
    trimf: 1500,
    1.5: 531050,
    2.5: 464383,
    3.5: 389235,
    4.5: 333630,
  },
  {
    1: 556450,
    2: 502283,
    3: 418950,
    4: 361692,
    5: 306047,
    "revenu brut": 1683000,
    trimf: 1500,
    1.5: 531450,
    2.5: 464783,
    3.5: 389515,
    4.5: 333870,
  },
  {
    1: 556850,
    2: 502683,
    3: 419350,
    4: 361952,
    5: 306267,
    "revenu brut": 1684000,
    trimf: 1500,
    1.5: 531850,
    2.5: 465183,
    3.5: 389795,
    4.5: 334110,
  },
  {
    1: 557250,
    2: 503083,
    3: 419750,
    4: 362212,
    5: 306487,
    "revenu brut": 1685000,
    trimf: 1500,
    1.5: 532250,
    2.5: 465583,
    3.5: 390075,
    4.5: 334350,
  },
  {
    1: 557650,
    2: 503483,
    3: 420150,
    4: 362472,
    5: 306707,
    "revenu brut": 1686000,
    trimf: 1500,
    1.5: 532650,
    2.5: 465983,
    3.5: 390355,
    4.5: 334590,
  },
  {
    1: 558050,
    2: 503883,
    3: 420550,
    4: 362732,
    5: 306927,
    "revenu brut": 1687000,
    trimf: 1500,
    1.5: 533050,
    2.5: 466383,
    3.5: 390635,
    4.5: 334830,
  },
  {
    1: 558450,
    2: 504283,
    3: 420950,
    4: 362992,
    5: 307147,
    "revenu brut": 1688000,
    trimf: 1500,
    1.5: 533450,
    2.5: 466783,
    3.5: 390915,
    4.5: 335070,
  },
  {
    1: 558850,
    2: 504683,
    3: 421350,
    4: 363252,
    5: 307367,
    "revenu brut": 1689000,
    trimf: 1500,
    1.5: 533850,
    2.5: 467183,
    3.5: 391195,
    4.5: 335310,
  },
  {
    1: 559250,
    2: 505083,
    3: 421750,
    4: 363512,
    5: 307587,
    "revenu brut": 1690000,
    trimf: 1500,
    1.5: 534250,
    2.5: 467583,
    3.5: 391475,
    4.5: 335550,
  },
  {
    1: 559650,
    2: 505483,
    3: 422150,
    4: 363772,
    5: 307807,
    "revenu brut": 1691_000,
    trimf: 1500,
    1.5: 534650,
    2.5: 467983,
    3.5: 391755,
    4.5: 335790,
  },
  {
    1: 560050,
    2: 505883,
    3: 422550,
    4: 364032,
    5: 308027,
    "revenu brut": 1692000,
    trimf: 1500,
    1.5: 535050,
    2.5: 468383,
    3.5: 392035,
    4.5: 336030,
  },
  {
    1: 560450,
    2: 506283,
    3: 422950,
    4: 364292,
    5: 308247,
    "revenu brut": 1693000,
    trimf: 1500,
    1.5: 535450,
    2.5: 468783,
    3.5: 392315,
    4.5: 336270,
  },
  {
    1: 560850,
    2: 506683,
    3: 423350,
    4: 364552,
    5: 308467,
    "revenu brut": 1694000,
    trimf: 1500,
    1.5: 535850,
    2.5: 469183,
    3.5: 392595,
    4.5: 336510,
  },
  {
    1: 561250,
    2: 507083,
    3: 423750,
    4: 364812,
    5: 308687,
    "revenu brut": 1695000,
    trimf: 1500,
    1.5: 536250,
    2.5: 469583,
    3.5: 392875,
    4.5: 336750,
  },
  {
    1: 561650,
    2: 507483,
    3: 424150,
    4: 365072,
    5: 308907,
    "revenu brut": 1696000,
    trimf: 1500,
    1.5: 536650,
    2.5: 469983,
    3.5: 393155,
    4.5: 336990,
  },
  {
    1: 562050,
    2: 507883,
    3: 424550,
    4: 365332,
    5: 309127,
    "revenu brut": 1697000,
    trimf: 1500,
    1.5: 537050,
    2.5: 470383,
    3.5: 393435,
    4.5: 337230,
  },
  {
    1: 562450,
    2: 508283,
    3: 424950,
    4: 365592,
    5: 309347,
    "revenu brut": 1698000,
    trimf: 1500,
    1.5: 537450,
    2.5: 470783,
    3.5: 393715,
    4.5: 337470,
  },
  {
    1: 562850,
    2: 508683,
    3: 425350,
    4: 365852,
    5: 309567,
    "revenu brut": 1699000,
    trimf: 1500,
    1.5: 537850,
    2.5: 471183,
    3.5: 393995,
    4.5: 337710,
  },
  {
    1: 563250,
    2: 509083,
    3: 425750,
    4: 366112,
    5: 309787,
    "revenu brut": 1700000,
    trimf: 1500,
    1.5: 538250,
    2.5: 471583,
    3.5: 394275,
    4.5: 337950,
  },
  {
    1: 563650,
    2: 509483,
    3: 426150,
    4: 366372,
    5: 31_0007,
    "revenu brut": 1701_000,
    trimf: 1500,
    1.5: 538650,
    2.5: 471983,
    3.5: 394555,
    4.5: 338190,
  },
  {
    1: 564050,
    2: 509883,
    3: 426550,
    4: 366632,
    5: 310227,
    "revenu brut": 1702000,
    trimf: 1500,
    1.5: 539050,
    2.5: 472383,
    3.5: 394883,
    4.5: 338430,
  },
  {
    1: 564450,
    2: 510283,
    3: 426950,
    4: 366892,
    5: 310447,
    "revenu brut": 1703000,
    trimf: 1500,
    1.5: 539450,
    2.5: 472783,
    3.5: 395283,
    4.5: 338670,
  },
  {
    1: 564850,
    2: 510683,
    3: 427350,
    4: 367152,
    5: 310667,
    "revenu brut": 1704000,
    trimf: 1500,
    1.5: 539850,
    2.5: 473183,
    3.5: 395683,
    4.5: 338910,
  },
  {
    1: 565250,
    2: 511083,
    3: 427750,
    4: 367412,
    5: 310887,
    "revenu brut": 1705000,
    trimf: 1500,
    1.5: 540250,
    2.5: 473583,
    3.5: 396083,
    4.5: 339150,
  },
  {
    1: 565650,
    2: 511483,
    3: 428150,
    4: 367672,
    5: 311107,
    "revenu brut": 1706000,
    trimf: 1500,
    1.5: 540650,
    2.5: 473983,
    3.5: 396483,
    4.5: 339390,
  },
  {
    1: 566050,
    2: 511883,
    3: 428550,
    4: 367932,
    5: 311327,
    "revenu brut": 1707000,
    trimf: 1500,
    1.5: 541050,
    2.5: 474383,
    3.5: 396883,
    4.5: 339630,
  },
  {
    1: 566450,
    2: 512283,
    3: 428950,
    4: 368192,
    5: 311547,
    "revenu brut": 1708000,
    trimf: 1500,
    1.5: 541450,
    2.5: 474783,
    3.5: 397283,
    4.5: 339870,
  },
  {
    1: 566850,
    2: 512683,
    3: 429350,
    4: 368452,
    5: 311767,
    "revenu brut": 1709000,
    trimf: 1500,
    1.5: 541850,
    2.5: 475183,
    3.5: 397683,
    4.5: 340110,
  },
  {
    1: 567250,
    2: 513083,
    3: 429750,
    4: 368712,
    5: 311987,
    "revenu brut": 171_0000,
    trimf: 1500,
    1.5: 542250,
    2.5: 475583,
    3.5: 398083,
    4.5: 340350,
  },
  {
    1: 567650,
    2: 513483,
    3: 430150,
    4: 368972,
    5: 312207,
    "revenu brut": 1711_000,
    trimf: 1500,
    1.5: 542650,
    2.5: 475983,
    3.5: 398483,
    4.5: 340590,
  },
  {
    1: 568050,
    2: 513883,
    3: 430550,
    4: 369232,
    5: 312427,
    "revenu brut": 1712000,
    trimf: 1500,
    1.5: 543050,
    2.5: 476383,
    3.5: 398883,
    4.5: 340830,
  },
  {
    1: 568450,
    2: 514283,
    3: 430950,
    4: 369492,
    5: 312647,
    "revenu brut": 1713000,
    trimf: 1500,
    1.5: 543450,
    2.5: 476783,
    3.5: 399283,
    4.5: 341070,
  },
  {
    1: 568850,
    2: 514683,
    3: 431350,
    4: 369752,
    5: 312867,
    "revenu brut": 1714000,
    trimf: 1500,
    1.5: 543850,
    2.5: 477183,
    3.5: 399683,
    4.5: 341310,
  },
  {
    1: 569250,
    2: 515083,
    3: 431750,
    4: 370012,
    5: 313087,
    "revenu brut": 1715000,
    trimf: 1500,
    1.5: 544250,
    2.5: 477583,
    3.5: 400083,
    4.5: 341550,
  },
  {
    1: 569650,
    2: 515483,
    3: 432150,
    4: 370272,
    5: 313307,
    "revenu brut": 1716000,
    trimf: 1500,
    1.5: 544650,
    2.5: 477983,
    3.5: 400483,
    4.5: 341790,
  },
  {
    1: 570050,
    2: 515883,
    3: 432550,
    4: 370532,
    5: 313527,
    "revenu brut": 1717000,
    trimf: 1500,
    1.5: 545050,
    2.5: 478383,
    3.5: 400883,
    4.5: 342030,
  },
  {
    1: 570450,
    2: 516283,
    3: 432950,
    4: 370792,
    5: 313747,
    "revenu brut": 1718000,
    trimf: 1500,
    1.5: 545450,
    2.5: 478783,
    3.5: 401283,
    4.5: 342270,
  },
  {
    1: 570850,
    2: 516683,
    3: 433350,
    4: 371052,
    5: 313967,
    "revenu brut": 1719000,
    trimf: 1500,
    1.5: 545850,
    2.5: 479183,
    3.5: 401683,
    4.5: 342510,
  },
  {
    1: 571250,
    2: 517083,
    3: 433750,
    4: 371312,
    5: 314187,
    "revenu brut": 1720000,
    trimf: 1500,
    1.5: 546250,
    2.5: 479583,
    3.5: 402083,
    4.5: 342750,
  },
  {
    1: 571650,
    2: 517483,
    3: 434150,
    4: 371572,
    5: 314407,
    "revenu brut": 1721_000,
    trimf: 1500,
    1.5: 546650,
    2.5: 479983,
    3.5: 402483,
    4.5: 342990,
  },
  {
    1: 572050,
    2: 517883,
    3: 434550,
    4: 371832,
    5: 314627,
    "revenu brut": 1722000,
    trimf: 1500,
    1.5: 547050,
    2.5: 480383,
    3.5: 402883,
    4.5: 343230,
  },
  {
    1: 572450,
    2: 518283,
    3: 434950,
    4: 372092,
    5: 314847,
    "revenu brut": 1723000,
    trimf: 1500,
    1.5: 547450,
    2.5: 480783,
    3.5: 403283,
    4.5: 343470,
  },
  {
    1: 572850,
    2: 518683,
    3: 435350,
    4: 372352,
    5: 315067,
    "revenu brut": 1724000,
    trimf: 1500,
    1.5: 547850,
    2.5: 481183,
    3.5: 403683,
    4.5: 343710,
  },
  {
    1: 573250,
    2: 519083,
    3: 435750,
    4: 372612,
    5: 315287,
    "revenu brut": 1725000,
    trimf: 1500,
    1.5: 548250,
    2.5: 481583,
    3.5: 404083,
    4.5: 343950,
  },
  {
    1: 573650,
    2: 519483,
    3: 436150,
    4: 372872,
    5: 315507,
    "revenu brut": 1726000,
    trimf: 1500,
    1.5: 548650,
    2.5: 481983,
    3.5: 404483,
    4.5: 344190,
  },
  {
    1: 574050,
    2: 519883,
    3: 436550,
    4: 373132,
    5: 315727,
    "revenu brut": 1727000,
    trimf: 1500,
    1.5: 549050,
    2.5: 482383,
    3.5: 404883,
    4.5: 344466,
  },
  {
    1: 574450,
    2: 520283,
    3: 436950,
    4: 373392,
    5: 315947,
    "revenu brut": 1728000,
    trimf: 1500,
    1.5: 549450,
    2.5: 482783,
    3.5: 405283,
    4.5: 344866,
  },
  {
    1: 574850,
    2: 520683,
    3: 437350,
    4: 373652,
    5: 316167,
    "revenu brut": 1729000,
    trimf: 1500,
    1.5: 549850,
    2.5: 483183,
    3.5: 405683,
    4.5: 345266,
  },
  {
    1: 575250,
    2: 521083,
    3: 437750,
    4: 373912,
    5: 316387,
    "revenu brut": 1730000,
    trimf: 1500,
    1.5: 550250,
    2.5: 483583,
    3.5: 406083,
    4.5: 345666,
  },
  {
    1: 575650,
    2: 521483,
    3: 438150,
    4: 374172,
    5: 316607,
    "revenu brut": 1731_000,
    trimf: 1500,
    1.5: 550650,
    2.5: 483983,
    3.5: 406483,
    4.5: 346066,
  },
  {
    1: 576050,
    2: 521883,
    3: 438550,
    4: 374432,
    5: 316827,
    "revenu brut": 1732000,
    trimf: 1500,
    1.5: 551050,
    2.5: 484383,
    3.5: 406883,
    4.5: 346466,
  },
  {
    1: 576450,
    2: 522283,
    3: 438950,
    4: 374692,
    5: 317047,
    "revenu brut": 1733000,
    trimf: 1500,
    1.5: 551450,
    2.5: 484783,
    3.5: 407283,
    4.5: 346866,
  },
  {
    1: 576850,
    2: 522683,
    3: 439350,
    4: 374952,
    5: 317267,
    "revenu brut": 1734000,
    trimf: 1500,
    1.5: 551850,
    2.5: 485183,
    3.5: 407683,
    4.5: 347266,
  },
  {
    1: 577250,
    2: 523083,
    3: 439750,
    4: 375212,
    5: 317487,
    "revenu brut": 1735000,
    trimf: 1500,
    1.5: 552250,
    2.5: 485583,
    3.5: 408083,
    4.5: 347666,
  },
  {
    1: 577650,
    2: 523483,
    3: 440150,
    4: 375472,
    5: 317707,
    "revenu brut": 1736000,
    trimf: 1500,
    1.5: 552650,
    2.5: 485983,
    3.5: 408483,
    4.5: 348066,
  },
  {
    1: 578050,
    2: 523883,
    3: 440550,
    4: 375732,
    5: 317927,
    "revenu brut": 1737000,
    trimf: 1500,
    1.5: 553050,
    2.5: 486383,
    3.5: 408883,
    4.5: 348466,
  },
  {
    1: 578450,
    2: 524283,
    3: 440950,
    4: 375992,
    5: 318147,
    "revenu brut": 1738000,
    trimf: 1500,
    1.5: 553450,
    2.5: 486783,
    3.5: 409283,
    4.5: 348866,
  },
  {
    1: 578850,
    2: 524683,
    3: 441350,
    4: 376252,
    5: 318367,
    "revenu brut": 1739000,
    trimf: 1500,
    1.5: 553850,
    2.5: 487183,
    3.5: 409683,
    4.5: 349266,
  },
  {
    1: 579250,
    2: 525083,
    3: 441750,
    4: 376512,
    5: 318587,
    "revenu brut": 1740000,
    trimf: 1500,
    1.5: 554250,
    2.5: 487583,
    3.5: 410083,
    4.5: 349666,
  },
  {
    1: 579650,
    2: 525483,
    3: 442150,
    4: 376772,
    5: 318807,
    "revenu brut": 1741_000,
    trimf: 1500,
    1.5: 554650,
    2.5: 487983,
    3.5: 410483,
    4.5: 350066,
  },
  {
    1: 580050,
    2: 525883,
    3: 442550,
    4: 377032,
    5: 319027,
    "revenu brut": 1742000,
    trimf: 1500,
    1.5: 555050,
    2.5: 488383,
    3.5: 410883,
    4.5: 350466,
  },
  {
    1: 580450,
    2: 526283,
    3: 442950,
    4: 377292,
    5: 319247,
    "revenu brut": 1743000,
    trimf: 1500,
    1.5: 555450,
    2.5: 488783,
    3.5: 411283,
    4.5: 350866,
  },
  {
    1: 580850,
    2: 526683,
    3: 443350,
    4: 377552,
    5: 319467,
    "revenu brut": 1744000,
    trimf: 1500,
    1.5: 555850,
    2.5: 489183,
    3.5: 411683,
    4.5: 351266,
  },
  {
    1: 581250,
    2: 527083,
    3: 443750,
    4: 377812,
    5: 319687,
    "revenu brut": 1745000,
    trimf: 1500,
    1.5: 556250,
    2.5: 489583,
    3.5: 412083,
    4.5: 351666,
  },
  {
    1: 581650,
    2: 527483,
    3: 444150,
    4: 378072,
    5: 319907,
    "revenu brut": 1746000,
    trimf: 1500,
    1.5: 556650,
    2.5: 489983,
    3.5: 412483,
    4.5: 352066,
  },
  {
    1: 582050,
    2: 527883,
    3: 444550,
    4: 378332,
    5: 320127,
    "revenu brut": 1747000,
    trimf: 1500,
    1.5: 557050,
    2.5: 490383,
    3.5: 412883,
    4.5: 352466,
  },
  {
    1: 582450,
    2: 528283,
    3: 444950,
    4: 378592,
    5: 320347,
    "revenu brut": 1748000,
    trimf: 1500,
    1.5: 557450,
    2.5: 490783,
    3.5: 413283,
    4.5: 352866,
  },
  {
    1: 582850,
    2: 528683,
    3: 445350,
    4: 378852,
    5: 320567,
    "revenu brut": 1749000,
    trimf: 1500,
    1.5: 557850,
    2.5: 491183,
    3.5: 413683,
    4.5: 353266,
  },
  {
    1: 583250,
    2: 529083,
    3: 445750,
    4: 379112,
    5: 320787,
    "revenu brut": 1750000,
    trimf: 1500,
    1.5: 558250,
    2.5: 491583,
    3.5: 414083,
    4.5: 353666,
  },
  {
    1: 583650,
    2: 529483,
    3: 446150,
    4: 379372,
    5: 321007,
    "revenu brut": 1751_000,
    trimf: 1500,
    1.5: 558650,
    2.5: 491983,
    3.5: 414483,
    4.5: 354066,
  },
  {
    1: 584050,
    2: 529883,
    3: 446550,
    4: 379632,
    5: 321227,
    "revenu brut": 1752000,
    trimf: 1500,
    1.5: 559050,
    2.5: 492383,
    3.5: 414883,
    4.5: 354466,
  },
  {
    1: 584450,
    2: 530283,
    3: 446950,
    4: 379892,
    5: 321447,
    "revenu brut": 1753000,
    trimf: 1500,
    1.5: 559450,
    2.5: 492783,
    3.5: 415283,
    4.5: 354866,
  },
  {
    1: 584850,
    2: 530683,
    3: 447350,
    4: 380152,
    5: 321667,
    "revenu brut": 1754000,
    trimf: 1500,
    1.5: 559850,
    2.5: 493183,
    3.5: 415683,
    4.5: 355266,
  },
  {
    1: 585250,
    2: 531083,
    3: 447750,
    4: 380412,
    5: 321887,
    "revenu brut": 1755000,
    trimf: 1500,
    1.5: 560250,
    2.5: 493583,
    3.5: 416083,
    4.5: 355666,
  },
  {
    1: 585650,
    2: 531483,
    3: 448150,
    4: 380672,
    5: 322107,
    "revenu brut": 1756000,
    trimf: 1500,
    1.5: 560650,
    2.5: 493983,
    3.5: 416483,
    4.5: 356066,
  },
  {
    1: 586050,
    2: 531883,
    3: 448550,
    4: 380932,
    5: 322327,
    "revenu brut": 1757000,
    trimf: 1500,
    1.5: 561050,
    2.5: 494383,
    3.5: 416883,
    4.5: 356466,
  },
  {
    1: 586450,
    2: 532283,
    3: 448950,
    4: 381192,
    5: 322547,
    "revenu brut": 1758000,
    trimf: 1500,
    1.5: 561450,
    2.5: 494783,
    3.5: 417283,
    4.5: 356866,
  },
  {
    1: 586850,
    2: 532683,
    3: 449350,
    4: 381452,
    5: 322767,
    "revenu brut": 1759000,
    trimf: 1500,
    1.5: 561850,
    2.5: 495183,
    3.5: 417683,
    4.5: 357266,
  },
  {
    1: 587250,
    2: 533083,
    3: 449750,
    4: 381712,
    5: 322987,
    "revenu brut": 1760000,
    trimf: 1500,
    1.5: 562250,
    2.5: 495583,
    3.5: 418083,
    4.5: 357666,
  },
  {
    1: 587650,
    2: 533483,
    3: 450150,
    4: 381972,
    5: 323207,
    "revenu brut": 1761_000,
    trimf: 1500,
    1.5: 562650,
    2.5: 495983,
    3.5: 418483,
    4.5: 358066,
  },
  {
    1: 588050,
    2: 533883,
    3: 450550,
    4: 382232,
    5: 323427,
    "revenu brut": 1762000,
    trimf: 1500,
    1.5: 563050,
    2.5: 496383,
    3.5: 418883,
    4.5: 358466,
  },
  {
    1: 588450,
    2: 534283,
    3: 450950,
    4: 382492,
    5: 323647,
    "revenu brut": 1763000,
    trimf: 1500,
    1.5: 563450,
    2.5: 496783,
    3.5: 419283,
    4.5: 358866,
  },
  {
    1: 588850,
    2: 534683,
    3: 451350,
    4: 382752,
    5: 323867,
    "revenu brut": 1764000,
    trimf: 1500,
    1.5: 563850,
    2.5: 497183,
    3.5: 419683,
    4.5: 359266,
  },
  {
    1: 589250,
    2: 535083,
    3: 451750,
    4: 383012,
    5: 324250,
    "revenu brut": 1765000,
    trimf: 1500,
    1.5: 564250,
    2.5: 497583,
    3.5: 420083,
    4.5: 359666,
  },
  {
    1: 589650,
    2: 535483,
    3: 452150,
    4: 383272,
    5: 324650,
    "revenu brut": 1766000,
    trimf: 1500,
    1.5: 564650,
    2.5: 497983,
    3.5: 420483,
    4.5: 360066,
  },
  {
    1: 590050,
    2: 535883,
    3: 452550,
    4: 383532,
    5: 325050,
    "revenu brut": 1767000,
    trimf: 1500,
    1.5: 565050,
    2.5: 498383,
    3.5: 420883,
    4.5: 360466,
  },
  {
    1: 590450,
    2: 536283,
    3: 452950,
    4: 383792,
    5: 325450,
    "revenu brut": 1768000,
    trimf: 1500,
    1.5: 565450,
    2.5: 498783,
    3.5: 421283,
    4.5: 360866,
  },
  {
    1: 590850,
    2: 536683,
    3: 453350,
    4: 384052,
    5: 325850,
    "revenu brut": 1769000,
    trimf: 1500,
    1.5: 565850,
    2.5: 499183,
    3.5: 421683,
    4.5: 361266,
  },
  {
    1: 591250,
    2: 537083,
    3: 453750,
    4: 384312,
    5: 326250,
    "revenu brut": 1770000,
    trimf: 1500,
    1.5: 566250,
    2.5: 499583,
    3.5: 422083,
    4.5: 361666,
  },
  {
    1: 591650,
    2: 537483,
    3: 454150,
    4: 384572,
    5: 326650,
    "revenu brut": 1771_000,
    trimf: 1500,
    1.5: 566650,
    2.5: 499983,
    3.5: 422483,
    4.5: 362066,
  },
  {
    1: 592050,
    2: 537883,
    3: 454550,
    4: 384832,
    5: 327050,
    "revenu brut": 1772000,
    trimf: 1500,
    1.5: 567050,
    2.5: 500383,
    3.5: 422883,
    4.5: 362466,
  },
  {
    1: 592450,
    2: 538283,
    3: 454950,
    4: 385092,
    5: 327450,
    "revenu brut": 1773000,
    trimf: 1500,
    1.5: 567450,
    2.5: 500783,
    3.5: 423283,
    4.5: 362866,
  },
  {
    1: 592850,
    2: 538683,
    3: 455350,
    4: 385352,
    5: 327850,
    "revenu brut": 1774000,
    trimf: 1500,
    1.5: 567850,
    2.5: 501183,
    3.5: 423683,
    4.5: 363266,
  },
  {
    1: 593250,
    2: 539083,
    3: 455750,
    4: 385750,
    5: 328250,
    "revenu brut": 1775000,
    trimf: 1500,
    1.5: 568250,
    2.5: 501583,
    3.5: 424083,
    4.5: 363666,
  },
  {
    1: 593650,
    2: 539483,
    3: 456150,
    4: 386150,
    5: 328650,
    "revenu brut": 1776000,
    trimf: 1500,
    1.5: 568650,
    2.5: 501983,
    3.5: 424483,
    4.5: 364066,
  },
  {
    1: 594050,
    2: 539883,
    3: 456550,
    4: 386550,
    5: 329050,
    "revenu brut": 1777000,
    trimf: 1500,
    1.5: 569050,
    2.5: 502383,
    3.5: 424883,
    4.5: 364466,
  },
  {
    1: 594450,
    2: 540283,
    3: 456950,
    4: 386950,
    5: 329450,
    "revenu brut": 1778000,
    trimf: 1500,
    1.5: 569450,
    2.5: 502783,
    3.5: 425283,
    4.5: 364866,
  },
  {
    1: 594850,
    2: 540683,
    3: 457350,
    4: 387350,
    5: 329850,
    "revenu brut": 1779000,
    trimf: 1500,
    1.5: 569850,
    2.5: 503183,
    3.5: 425683,
    4.5: 365266,
  },
  {
    1: 595250,
    2: 541083,
    3: 457750,
    4: 387750,
    5: 330250,
    "revenu brut": 1780000,
    trimf: 1500,
    1.5: 570250,
    2.5: 503583,
    3.5: 426083,
    4.5: 365666,
  },
  {
    1: 595650,
    2: 541483,
    3: 458150,
    4: 388150,
    5: 330650,
    "revenu brut": 1781_000,
    trimf: 1500,
    1.5: 570650,
    2.5: 503983,
    3.5: 426483,
    4.5: 366066,
  },
  {
    1: 596050,
    2: 541883,
    3: 458550,
    4: 388550,
    5: 331050,
    "revenu brut": 1782000,
    trimf: 1500,
    1.5: 571050,
    2.5: 504383,
    3.5: 426883,
    4.5: 366466,
  },
  {
    1: 596450,
    2: 542283,
    3: 458950,
    4: 388950,
    5: 331450,
    "revenu brut": 1783000,
    trimf: 1500,
    1.5: 571450,
    2.5: 504783,
    3.5: 427283,
    4.5: 366866,
  },
  {
    1: 596850,
    2: 542683,
    3: 459350,
    4: 389350,
    5: 331850,
    "revenu brut": 1784000,
    trimf: 1500,
    1.5: 571850,
    2.5: 505183,
    3.5: 427683,
    4.5: 367266,
  },
  {
    1: 597250,
    2: 543083,
    3: 459750,
    4: 389750,
    5: 332250,
    "revenu brut": 1785000,
    trimf: 1500,
    1.5: 572250,
    2.5: 505583,
    3.5: 428083,
    4.5: 367666,
  },
  {
    1: 597650,
    2: 543483,
    3: 460150,
    4: 390150,
    5: 332650,
    "revenu brut": 1786000,
    trimf: 1500,
    1.5: 572650,
    2.5: 505983,
    3.5: 428483,
    4.5: 368066,
  },
  {
    1: 598050,
    2: 543883,
    3: 460550,
    4: 390550,
    5: 333050,
    "revenu brut": 1787000,
    trimf: 1500,
    1.5: 573050,
    2.5: 506383,
    3.5: 428883,
    4.5: 368466,
  },
  {
    1: 598450,
    2: 544283,
    3: 460950,
    4: 390950,
    5: 333450,
    "revenu brut": 1788000,
    trimf: 1500,
    1.5: 573450,
    2.5: 506783,
    3.5: 429283,
    4.5: 368866,
  },
  {
    1: 598850,
    2: 544683,
    3: 461350,
    4: 391350,
    5: 333850,
    "revenu brut": 1789000,
    trimf: 1500,
    1.5: 573850,
    2.5: 507183,
    3.5: 429683,
    4.5: 369266,
  },
  {
    1: 599250,
    2: 545083,
    3: 461750,
    4: 391750,
    5: 334250,
    "revenu brut": 1790000,
    trimf: 1500,
    1.5: 574250,
    2.5: 507583,
    3.5: 430083,
    4.5: 369666,
  },
  {
    1: 599650,
    2: 545483,
    3: 462150,
    4: 392150,
    5: 334650,
    "revenu brut": 1791_000,
    trimf: 1500,
    1.5: 574650,
    2.5: 507983,
    3.5: 430483,
    4.5: 370066,
  },
  {
    1: 600050,
    2: 545883,
    3: 462550,
    4: 392550,
    5: 335050,
    "revenu brut": 1792000,
    trimf: 1500,
    1.5: 575050,
    2.5: 508383,
    3.5: 430883,
    4.5: 370466,
  },
  {
    1: 600450,
    2: 546283,
    3: 462950,
    4: 392950,
    5: 335450,
    "revenu brut": 1793000,
    trimf: 1500,
    1.5: 575450,
    2.5: 508783,
    3.5: 431283,
    4.5: 370866,
  },
  {
    1: 600850,
    2: 546683,
    3: 463350,
    4: 393350,
    5: 335850,
    "revenu brut": 1794000,
    trimf: 1500,
    1.5: 575850,
    2.5: 509183,
    3.5: 431683,
    4.5: 371266,
  },
  {
    1: 601250,
    2: 547083,
    3: 463750,
    4: 393750,
    5: 336250,
    "revenu brut": 1795000,
    trimf: 1500,
    1.5: 576250,
    2.5: 509583,
    3.5: 432083,
    4.5: 371666,
  },
  {
    1: 601650,
    2: 547483,
    3: 464150,
    4: 394150,
    5: 336650,
    "revenu brut": 1796000,
    trimf: 1500,
    1.5: 576650,
    2.5: 509983,
    3.5: 432483,
    4.5: 372066,
  },
  {
    1: 602050,
    2: 547883,
    3: 464550,
    4: 394550,
    5: 337050,
    "revenu brut": 1797000,
    trimf: 1500,
    1.5: 577050,
    2.5: 510383,
    3.5: 432883,
    4.5: 372466,
  },
  {
    1: 602450,
    2: 548283,
    3: 464950,
    4: 394950,
    5: 337450,
    "revenu brut": 1798000,
    trimf: 1500,
    1.5: 577450,
    2.5: 510783,
    3.5: 433283,
    4.5: 372866,
  },
  {
    1: 602850,
    2: 548683,
    3: 465350,
    4: 395350,
    5: 337850,
    "revenu brut": 1799000,
    trimf: 1500,
    1.5: 577850,
    2.5: 511183,
    3.5: 433683,
    4.5: 373266,
  },
  {
    1: 603250,
    2: 549083,
    3: 465750,
    4: 395750,
    5: 338250,
    "revenu brut": 1800000,
    trimf: 1500,
    1.5: 578250,
    2.5: 511583,
    3.5: 434083,
    4.5: 373666,
  },
  {
    1: 603650,
    2: 549483,
    3: 466150,
    4: 396150,
    5: 338650,
    "revenu brut": 1801_000,
    trimf: 1500,
    1.5: 578650,
    2.5: 511983,
    3.5: 434483,
    4.5: 374066,
  },
  {
    1: 604050,
    2: 549883,
    3: 466550,
    4: 396550,
    5: 339050,
    "revenu brut": 1802000,
    trimf: 1500,
    1.5: 579050,
    2.5: 512383,
    3.5: 434883,
    4.5: 374466,
  },
  {
    1: 604450,
    2: 550283,
    3: 466950,
    4: 396950,
    5: 339450,
    "revenu brut": 1803000,
    trimf: 1500,
    1.5: 579450,
    2.5: 512783,
    3.5: 435283,
    4.5: 374866,
  },
  {
    1: 604850,
    2: 550683,
    3: 467350,
    4: 397350,
    5: 339850,
    "revenu brut": 1804000,
    trimf: 1500,
    1.5: 579850,
    2.5: 513183,
    3.5: 435683,
    4.5: 375266,
  },
  {
    1: 605250,
    2: 551083,
    3: 467750,
    4: 397750,
    5: 340250,
    "revenu brut": 1805000,
    trimf: 1500,
    1.5: 580250,
    2.5: 513583,
    3.5: 436083,
    4.5: 375666,
  },
  {
    1: 605650,
    2: 551483,
    3: 468150,
    4: 398150,
    5: 340650,
    "revenu brut": 1806000,
    trimf: 1500,
    1.5: 580650,
    2.5: 513983,
    3.5: 436483,
    4.5: 376066,
  },
  {
    1: 606050,
    2: 551883,
    3: 468550,
    4: 398550,
    5: 341050,
    "revenu brut": 1807000,
    trimf: 1500,
    1.5: 581050,
    2.5: 514383,
    3.5: 436883,
    4.5: 376466,
  },
  {
    1: 606450,
    2: 552283,
    3: 468950,
    4: 398950,
    5: 341450,
    "revenu brut": 1808000,
    trimf: 1500,
    1.5: 581450,
    2.5: 514783,
    3.5: 437283,
    4.5: 376866,
  },
  {
    1: 606850,
    2: 552683,
    3: 469350,
    4: 399350,
    5: 341850,
    "revenu brut": 1809000,
    trimf: 1500,
    1.5: 581850,
    2.5: 515183,
    3.5: 437683,
    4.5: 377266,
  },
  {
    1: 607250,
    2: 553083,
    3: 469750,
    4: 399750,
    5: 342250,
    "revenu brut": 181_0000,
    trimf: 1500,
    1.5: 582250,
    2.5: 515583,
    3.5: 438083,
    4.5: 377666,
  },
  {
    1: 607650,
    2: 553483,
    3: 470150,
    4: 400150,
    5: 342650,
    "revenu brut": 1811_000,
    trimf: 1500,
    1.5: 582650,
    2.5: 515983,
    3.5: 438483,
    4.5: 378066,
  },
  {
    1: 608050,
    2: 553883,
    3: 470550,
    4: 400550,
    5: 343050,
    "revenu brut": 1812000,
    trimf: 1500,
    1.5: 583050,
    2.5: 516383,
    3.5: 438883,
    4.5: 378466,
  },
  {
    1: 608450,
    2: 554283,
    3: 470950,
    4: 400950,
    5: 343450,
    "revenu brut": 1813000,
    trimf: 1500,
    1.5: 583450,
    2.5: 516783,
    3.5: 439283,
    4.5: 378866,
  },
  {
    1: 608850,
    2: 554683,
    3: 471350,
    4: 401350,
    5: 343850,
    "revenu brut": 1814000,
    trimf: 1500,
    1.5: 583850,
    2.5: 517183,
    3.5: 439683,
    4.5: 379266,
  },
  {
    1: 609250,
    2: 555083,
    3: 471750,
    4: 401750,
    5: 344250,
    "revenu brut": 1815000,
    trimf: 1500,
    1.5: 584250,
    2.5: 517583,
    3.5: 440083,
    4.5: 379666,
  },
  {
    1: 609650,
    2: 555483,
    3: 472150,
    4: 402150,
    5: 344650,
    "revenu brut": 1816000,
    trimf: 1500,
    1.5: 584650,
    2.5: 517983,
    3.5: 440483,
    4.5: 380066,
  },
  {
    1: 610050,
    2: 555883,
    3: 472550,
    4: 402550,
    5: 345050,
    "revenu brut": 1817000,
    trimf: 1500,
    1.5: 585050,
    2.5: 518383,
    3.5: 440883,
    4.5: 380466,
  },
  {
    1: 610450,
    2: 556283,
    3: 472950,
    4: 402950,
    5: 345450,
    "revenu brut": 1818000,
    trimf: 1500,
    1.5: 585450,
    2.5: 518783,
    3.5: 441283,
    4.5: 380866,
  },
  {
    1: 610850,
    2: 556683,
    3: 473350,
    4: 403350,
    5: 345850,
    "revenu brut": 1819000,
    trimf: 1500,
    1.5: 585850,
    2.5: 519183,
    3.5: 441683,
    4.5: 381266,
  },
  {
    1: 611250,
    2: 557083,
    3: 473750,
    4: 403750,
    5: 346250,
    "revenu brut": 1820000,
    trimf: 1500,
    1.5: 586250,
    2.5: 519583,
    3.5: 442083,
    4.5: 381666,
  },
  {
    1: 611650,
    2: 557483,
    3: 474150,
    4: 404150,
    5: 346650,
    "revenu brut": 1821_000,
    trimf: 1500,
    1.5: 586650,
    2.5: 519983,
    3.5: 442483,
    4.5: 382066,
  },
  {
    1: 612050,
    2: 557883,
    3: 474550,
    4: 404550,
    5: 347050,
    "revenu brut": 1822000,
    trimf: 1500,
    1.5: 587050,
    2.5: 520383,
    3.5: 442883,
    4.5: 382466,
  },
  {
    1: 612450,
    2: 558283,
    3: 474950,
    4: 404950,
    5: 347450,
    "revenu brut": 1823000,
    trimf: 1500,
    1.5: 587450,
    2.5: 520783,
    3.5: 443283,
    4.5: 382866,
  },
  {
    1: 612850,
    2: 558683,
    3: 475350,
    4: 405350,
    5: 347850,
    "revenu brut": 1824000,
    trimf: 1500,
    1.5: 587850,
    2.5: 521183,
    3.5: 443683,
    4.5: 383266,
  },
  {
    1: 613250,
    2: 559083,
    3: 475750,
    4: 405750,
    5: 348250,
    "revenu brut": 1825000,
    trimf: 1500,
    1.5: 588250,
    2.5: 521583,
    3.5: 444083,
    4.5: 383666,
  },
  {
    1: 613650,
    2: 559483,
    3: 476150,
    4: 406150,
    5: 348650,
    "revenu brut": 1826000,
    trimf: 1500,
    1.5: 588650,
    2.5: 521983,
    3.5: 444483,
    4.5: 384066,
  },
  {
    1: 614050,
    2: 559883,
    3: 476550,
    4: 406550,
    5: 349050,
    "revenu brut": 1827000,
    trimf: 1500,
    1.5: 589050,
    2.5: 522383,
    3.5: 444883,
    4.5: 384466,
  },
  {
    1: 614450,
    2: 560283,
    3: 476950,
    4: 406950,
    5: 349450,
    "revenu brut": 1828000,
    trimf: 1500,
    1.5: 589450,
    2.5: 522783,
    3.5: 445283,
    4.5: 384866,
  },
  {
    1: 614850,
    2: 560683,
    3: 477350,
    4: 407350,
    5: 349850,
    "revenu brut": 1829000,
    trimf: 1500,
    1.5: 589850,
    2.5: 523183,
    3.5: 445683,
    4.5: 385266,
  },
  {
    1: 615250,
    2: 561083,
    3: 477750,
    4: 407750,
    5: 350250,
    "revenu brut": 1830000,
    trimf: 1500,
    1.5: 590250,
    2.5: 523583,
    3.5: 446083,
    4.5: 385666,
  },
  {
    1: 615650,
    2: 561483,
    3: 478150,
    4: 408150,
    5: 350650,
    "revenu brut": 1831_000,
    trimf: 1500,
    1.5: 590650,
    2.5: 523983,
    3.5: 446483,
    4.5: 386066,
  },
  {
    1: 616050,
    2: 561883,
    3: 478550,
    4: 408550,
    5: 351050,
    "revenu brut": 1832000,
    trimf: 1500,
    1.5: 591050,
    2.5: 524383,
    3.5: 446883,
    4.5: 386466,
  },
  {
    1: 616450,
    2: 562283,
    3: 478950,
    4: 408950,
    5: 351450,
    "revenu brut": 1833000,
    trimf: 1500,
    1.5: 591450,
    2.5: 524783,
    3.5: 447283,
    4.5: 386866,
  },
  {
    1: 616850,
    2: 562683,
    3: 479350,
    4: 409350,
    5: 351850,
    "revenu brut": 1834000,
    trimf: 1500,
    1.5: 591850,
    2.5: 525183,
    3.5: 447683,
    4.5: 387266,
  },
  {
    1: 617250,
    2: 563083,
    3: 479750,
    4: 409750,
    5: 352250,
    "revenu brut": 1835000,
    trimf: 1500,
    1.5: 592250,
    2.5: 525583,
    3.5: 448083,
    4.5: 387666,
  },
  {
    1: 617650,
    2: 563483,
    3: 480150,
    4: 410150,
    5: 352650,
    "revenu brut": 1836000,
    trimf: 1500,
    1.5: 592650,
    2.5: 525983,
    3.5: 448483,
    4.5: 388066,
  },
  {
    1: 618050,
    2: 563883,
    3: 480550,
    4: 410550,
    5: 353050,
    "revenu brut": 1837000,
    trimf: 1500,
    1.5: 593050,
    2.5: 526383,
    3.5: 448883,
    4.5: 388466,
  },
  {
    1: 618450,
    2: 564283,
    3: 480950,
    4: 410950,
    5: 353450,
    "revenu brut": 1838000,
    trimf: 1500,
    1.5: 593450,
    2.5: 526783,
    3.5: 449283,
    4.5: 388866,
  },
  {
    1: 618850,
    2: 564683,
    3: 481350,
    4: 411350,
    5: 353850,
    "revenu brut": 1839000,
    trimf: 1500,
    1.5: 593850,
    2.5: 527183,
    3.5: 449683,
    4.5: 389266,
  },
  {
    1: 619250,
    2: 565083,
    3: 481750,
    4: 411750,
    5: 354250,
    "revenu brut": 1840000,
    trimf: 1500,
    1.5: 594250,
    2.5: 527583,
    3.5: 450083,
    4.5: 389666,
  },
  {
    1: 619650,
    2: 565483,
    3: 482150,
    4: 412150,
    5: 354650,
    "revenu brut": 1841_000,
    trimf: 1500,
    1.5: 594650,
    2.5: 527983,
    3.5: 450483,
    4.5: 390066,
  },
  {
    1: 620050,
    2: 565883,
    3: 482550,
    4: 412550,
    5: 355050,
    "revenu brut": 1842000,
    trimf: 1500,
    1.5: 595050,
    2.5: 528383,
    3.5: 450883,
    4.5: 390466,
  },
  {
    1: 620450,
    2: 566283,
    3: 482950,
    4: 412950,
    5: 355450,
    "revenu brut": 1843000,
    trimf: 1500,
    1.5: 595450,
    2.5: 528783,
    3.5: 451283,
    4.5: 390866,
  },
  {
    1: 620850,
    2: 566683,
    3: 483350,
    4: 413350,
    5: 355850,
    "revenu brut": 1844000,
    trimf: 1500,
    1.5: 595850,
    2.5: 529183,
    3.5: 451683,
    4.5: 391266,
  },
  {
    1: 621250,
    2: 567083,
    3: 483750,
    4: 413750,
    5: 356250,
    "revenu brut": 1845000,
    trimf: 1500,
    1.5: 596250,
    2.5: 529583,
    3.5: 452083,
    4.5: 391666,
  },
  {
    1: 621650,
    2: 567483,
    3: 484150,
    4: 414150,
    5: 356650,
    "revenu brut": 1846000,
    trimf: 1500,
    1.5: 596650,
    2.5: 529983,
    3.5: 452483,
    4.5: 392066,
  },
  {
    1: 622050,
    2: 567883,
    3: 484550,
    4: 414550,
    5: 357050,
    "revenu brut": 1847000,
    trimf: 1500,
    1.5: 597050,
    2.5: 530383,
    3.5: 452883,
    4.5: 392466,
  },
  {
    1: 622450,
    2: 568283,
    3: 484950,
    4: 414950,
    5: 357450,
    "revenu brut": 1848000,
    trimf: 1500,
    1.5: 597450,
    2.5: 530783,
    3.5: 453283,
    4.5: 392866,
  },
  {
    1: 622850,
    2: 568683,
    3: 485350,
    4: 415350,
    5: 357850,
    "revenu brut": 1849000,
    trimf: 1500,
    1.5: 597850,
    2.5: 531183,
    3.5: 453683,
    4.5: 393266,
  },
  {
    1: 623250,
    2: 569083,
    3: 485750,
    4: 415750,
    5: 358250,
    "revenu brut": 1850000,
    trimf: 1500,
    1.5: 598250,
    2.5: 531583,
    3.5: 454083,
    4.5: 393666,
  },
  {
    1: 623650,
    2: 569483,
    3: 486150,
    4: 416150,
    5: 358650,
    "revenu brut": 1851_000,
    trimf: 1500,
    1.5: 598650,
    2.5: 531983,
    3.5: 454483,
    4.5: 394066,
  },
  {
    1: 624050,
    2: 569883,
    3: 486550,
    4: 416550,
    5: 359050,
    "revenu brut": 1852000,
    trimf: 1500,
    1.5: 599050,
    2.5: 532383,
    3.5: 454883,
    4.5: 394466,
  },
  {
    1: 624450,
    2: 570283,
    3: 486950,
    4: 416950,
    5: 359450,
    "revenu brut": 1853000,
    trimf: 1500,
    1.5: 599450,
    2.5: 532783,
    3.5: 455283,
    4.5: 394866,
  },
  {
    1: 624850,
    2: 570683,
    3: 487350,
    4: 417350,
    5: 359850,
    "revenu brut": 1854000,
    trimf: 1500,
    1.5: 599850,
    2.5: 533183,
    3.5: 455683,
    4.5: 395266,
  },
  {
    1: 625250,
    2: 571083,
    3: 487750,
    4: 417750,
    5: 360250,
    "revenu brut": 1855000,
    trimf: 1500,
    1.5: 600250,
    2.5: 533583,
    3.5: 456083,
    4.5: 395666,
  },
  {
    1: 625650,
    2: 571483,
    3: 488150,
    4: 418150,
    5: 360650,
    "revenu brut": 1856000,
    trimf: 1500,
    1.5: 600650,
    2.5: 533983,
    3.5: 456483,
    4.5: 396066,
  },
  {
    1: 626050,
    2: 571883,
    3: 488550,
    4: 418550,
    5: 361050,
    "revenu brut": 1857000,
    trimf: 1500,
    1.5: 601050,
    2.5: 534383,
    3.5: 456883,
    4.5: 396466,
  },
  {
    1: 626450,
    2: 572283,
    3: 488950,
    4: 418950,
    5: 361450,
    "revenu brut": 1858000,
    trimf: 1500,
    1.5: 601450,
    2.5: 534783,
    3.5: 457283,
    4.5: 396866,
  },
  {
    1: 626850,
    2: 572683,
    3: 489350,
    4: 419350,
    5: 361850,
    "revenu brut": 1859000,
    trimf: 1500,
    1.5: 601850,
    2.5: 535183,
    3.5: 457683,
    4.5: 397266,
  },
  {
    1: 627250,
    2: 573083,
    3: 489750,
    4: 419750,
    5: 362250,
    "revenu brut": 1860000,
    trimf: 1500,
    1.5: 602250,
    2.5: 535583,
    3.5: 458083,
    4.5: 397666,
  },
  {
    1: 627650,
    2: 573483,
    3: 490150,
    4: 420150,
    5: 362650,
    "revenu brut": 1861_000,
    trimf: 1500,
    1.5: 602650,
    2.5: 535983,
    3.5: 458483,
    4.5: 398066,
  },
  {
    1: 628050,
    2: 573883,
    3: 490550,
    4: 420550,
    5: 363050,
    "revenu brut": 1862000,
    trimf: 1500,
    1.5: 603050,
    2.5: 536383,
    3.5: 458883,
    4.5: 398466,
  },
  {
    1: 628450,
    2: 574283,
    3: 490950,
    4: 420950,
    5: 363450,
    "revenu brut": 1863000,
    trimf: 1500,
    1.5: 603450,
    2.5: 536783,
    3.5: 459283,
    4.5: 398866,
  },
  {
    1: 628850,
    2: 574683,
    3: 491350,
    4: 421350,
    5: 363850,
    "revenu brut": 1864000,
    trimf: 1500,
    1.5: 603850,
    2.5: 537183,
    3.5: 459683,
    4.5: 399266,
  },
  {
    1: 629250,
    2: 575083,
    3: 491750,
    4: 421750,
    5: 364250,
    "revenu brut": 1865000,
    trimf: 1500,
    1.5: 604250,
    2.5: 537583,
    3.5: 460083,
    4.5: 399666,
  },
  {
    1: 629650,
    2: 575483,
    3: 492150,
    4: 422150,
    5: 364650,
    "revenu brut": 1866000,
    trimf: 1500,
    1.5: 604650,
    2.5: 537983,
    3.5: 460483,
    4.5: 400066,
  },
  {
    1: 630050,
    2: 575883,
    3: 492550,
    4: 422550,
    5: 365050,
    "revenu brut": 1867000,
    trimf: 1500,
    1.5: 605050,
    2.5: 538383,
    3.5: 460883,
    4.5: 400466,
  },
  {
    1: 630450,
    2: 576283,
    3: 492950,
    4: 422950,
    5: 365450,
    "revenu brut": 1868000,
    trimf: 1500,
    1.5: 605450,
    2.5: 538783,
    3.5: 461283,
    4.5: 400866,
  },
  {
    1: 630850,
    2: 576683,
    3: 493350,
    4: 423350,
    5: 365850,
    "revenu brut": 1869000,
    trimf: 1500,
    1.5: 605850,
    2.5: 539183,
    3.5: 461683,
    4.5: 401266,
  },
  {
    1: 631250,
    2: 577083,
    3: 493750,
    4: 423750,
    5: 366250,
    "revenu brut": 1870000,
    trimf: 1500,
    1.5: 606250,
    2.5: 539583,
    3.5: 462083,
    4.5: 401666,
  },
  {
    1: 631650,
    2: 577483,
    3: 494150,
    4: 424150,
    5: 366650,
    "revenu brut": 1871_000,
    trimf: 1500,
    1.5: 606650,
    2.5: 539983,
    3.5: 462483,
    4.5: 402066,
  },
  {
    1: 632050,
    2: 577883,
    3: 494550,
    4: 424550,
    5: 367050,
    "revenu brut": 1872000,
    trimf: 1500,
    1.5: 607050,
    2.5: 540383,
    3.5: 462883,
    4.5: 402466,
  },
  {
    1: 632450,
    2: 578283,
    3: 494950,
    4: 424950,
    5: 367450,
    "revenu brut": 1873000,
    trimf: 1500,
    1.5: 607450,
    2.5: 540783,
    3.5: 463283,
    4.5: 402866,
  },
  {
    1: 632850,
    2: 578683,
    3: 495350,
    4: 425350,
    5: 367850,
    "revenu brut": 1874000,
    trimf: 1500,
    1.5: 607850,
    2.5: 541183,
    3.5: 463683,
    4.5: 403266,
  },
  {
    1: 633250,
    2: 579083,
    3: 495750,
    4: 425750,
    5: 368250,
    "revenu brut": 1875000,
    trimf: 1500,
    1.5: 608250,
    2.5: 541583,
    3.5: 464083,
    4.5: 403666,
  },
  {
    1: 633650,
    2: 579483,
    3: 496150,
    4: 426150,
    5: 368650,
    "revenu brut": 1876000,
    trimf: 1500,
    1.5: 608650,
    2.5: 541983,
    3.5: 464483,
    4.5: 404066,
  },
  {
    1: 634050,
    2: 579883,
    3: 496550,
    4: 426550,
    5: 369050,
    "revenu brut": 1877000,
    trimf: 1500,
    1.5: 609050,
    2.5: 542383,
    3.5: 464883,
    4.5: 404466,
  },
  {
    1: 634450,
    2: 580283,
    3: 496950,
    4: 426950,
    5: 369450,
    "revenu brut": 1878000,
    trimf: 1500,
    1.5: 609450,
    2.5: 542783,
    3.5: 465283,
    4.5: 404866,
  },
  {
    1: 634850,
    2: 580683,
    3: 497350,
    4: 427350,
    5: 369850,
    "revenu brut": 1879000,
    trimf: 1500,
    1.5: 609850,
    2.5: 543183,
    3.5: 465683,
    4.5: 405266,
  },
  {
    1: 635250,
    2: 581083,
    3: 497750,
    4: 427750,
    5: 370250,
    "revenu brut": 1880000,
    trimf: 1500,
    1.5: 610250,
    2.5: 543583,
    3.5: 466083,
    4.5: 405666,
  },
  {
    1: 635650,
    2: 581483,
    3: 498150,
    4: 428150,
    5: 370650,
    "revenu brut": 1881_000,
    trimf: 1500,
    1.5: 610650,
    2.5: 543983,
    3.5: 466483,
    4.5: 406066,
  },
  {
    1: 636050,
    2: 581883,
    3: 498550,
    4: 428550,
    5: 371050,
    "revenu brut": 1882000,
    trimf: 1500,
    1.5: 611050,
    2.5: 544383,
    3.5: 466883,
    4.5: 406466,
  },
  {
    1: 636450,
    2: 582283,
    3: 498950,
    4: 428950,
    5: 371450,
    "revenu brut": 1883000,
    trimf: 1500,
    1.5: 611450,
    2.5: 544783,
    3.5: 467283,
    4.5: 406866,
  },
  {
    1: 636850,
    2: 582683,
    3: 499350,
    4: 429350,
    5: 371850,
    "revenu brut": 1884000,
    trimf: 1500,
    1.5: 611850,
    2.5: 545183,
    3.5: 467683,
    4.5: 407266,
  },
  {
    1: 637250,
    2: 583083,
    3: 499750,
    4: 429750,
    5: 372250,
    "revenu brut": 1885000,
    trimf: 1500,
    1.5: 612250,
    2.5: 545583,
    3.5: 468083,
    4.5: 407666,
  },
  {
    1: 637650,
    2: 583483,
    3: 500150,
    4: 430150,
    5: 372650,
    "revenu brut": 1886000,
    trimf: 1500,
    1.5: 612650,
    2.5: 545983,
    3.5: 468483,
    4.5: 408066,
  },
  {
    1: 638050,
    2: 583883,
    3: 500550,
    4: 430550,
    5: 373050,
    "revenu brut": 1887000,
    trimf: 1500,
    1.5: 613050,
    2.5: 546383,
    3.5: 468883,
    4.5: 408466,
  },
  {
    1: 638450,
    2: 584283,
    3: 500950,
    4: 430950,
    5: 373450,
    "revenu brut": 1888000,
    trimf: 1500,
    1.5: 613450,
    2.5: 546783,
    3.5: 469283,
    4.5: 408866,
  },
  {
    1: 638850,
    2: 584683,
    3: 501350,
    4: 431350,
    5: 373850,
    "revenu brut": 1889000,
    trimf: 1500,
    1.5: 613850,
    2.5: 547183,
    3.5: 469683,
    4.5: 409266,
  },
  {
    1: 639250,
    2: 585083,
    3: 501750,
    4: 431750,
    5: 374250,
    "revenu brut": 1890000,
    trimf: 1500,
    1.5: 614250,
    2.5: 547583,
    3.5: 470083,
    4.5: 409666,
  },
  {
    1: 639650,
    2: 585483,
    3: 502150,
    4: 432150,
    5: 374650,
    "revenu brut": 1891_000,
    trimf: 1500,
    1.5: 614650,
    2.5: 547983,
    3.5: 470483,
    4.5: 410066,
  },
  {
    1: 640050,
    2: 585883,
    3: 502550,
    4: 432550,
    5: 375050,
    "revenu brut": 1892000,
    trimf: 1500,
    1.5: 615050,
    2.5: 548383,
    3.5: 470883,
    4.5: 410466,
  },
  {
    1: 640450,
    2: 586283,
    3: 502950,
    4: 432950,
    5: 375450,
    "revenu brut": 1893000,
    trimf: 1500,
    1.5: 615450,
    2.5: 548783,
    3.5: 471283,
    4.5: 410866,
  },
  {
    1: 640850,
    2: 586683,
    3: 503350,
    4: 433350,
    5: 375850,
    "revenu brut": 1894000,
    trimf: 1500,
    1.5: 615850,
    2.5: 549183,
    3.5: 471683,
    4.5: 411266,
  },
  {
    1: 641250,
    2: 587083,
    3: 503750,
    4: 433750,
    5: 376250,
    "revenu brut": 1895000,
    trimf: 1500,
    1.5: 616250,
    2.5: 549583,
    3.5: 472083,
    4.5: 411666,
  },
  {
    1: 641650,
    2: 587483,
    3: 504150,
    4: 434150,
    5: 376650,
    "revenu brut": 1896000,
    trimf: 1500,
    1.5: 616650,
    2.5: 549983,
    3.5: 472483,
    4.5: 412066,
  },
  {
    1: 642050,
    2: 587883,
    3: 504550,
    4: 434550,
    5: 377050,
    "revenu brut": 1897000,
    trimf: 1500,
    1.5: 617050,
    2.5: 550383,
    3.5: 472883,
    4.5: 412466,
  },
  {
    1: 642450,
    2: 588283,
    3: 504950,
    4: 434950,
    5: 377450,
    "revenu brut": 1898000,
    trimf: 1500,
    1.5: 617450,
    2.5: 550783,
    3.5: 473283,
    4.5: 412866,
  },
  {
    1: 642850,
    2: 588683,
    3: 505350,
    4: 435350,
    5: 377850,
    "revenu brut": 1899000,
    trimf: 1500,
    1.5: 617850,
    2.5: 551183,
    3.5: 473683,
    4.5: 413266,
  },
  {
    1: 643250,
    2: 589083,
    3: 505750,
    4: 435750,
    5: 378250,
    "revenu brut": 1900000,
    trimf: 1500,
    1.5: 618250,
    2.5: 551583,
    3.5: 474083,
    4.5: 413666,
  },
  {
    1: 643650,
    2: 589483,
    3: 506150,
    4: 436150,
    5: 378650,
    "revenu brut": 1901_000,
    trimf: 1500,
    1.5: 618650,
    2.5: 551983,
    3.5: 474483,
    4.5: 414066,
  },
  {
    1: 644050,
    2: 589883,
    3: 506550,
    4: 436550,
    5: 379050,
    "revenu brut": 1902000,
    trimf: 1500,
    1.5: 619050,
    2.5: 552383,
    3.5: 474883,
    4.5: 414466,
  },
  {
    1: 644450,
    2: 590283,
    3: 506950,
    4: 436950,
    5: 379450,
    "revenu brut": 1903000,
    trimf: 1500,
    1.5: 619450,
    2.5: 552783,
    3.5: 475283,
    4.5: 414866,
  },
  {
    1: 644850,
    2: 590683,
    3: 507350,
    4: 437350,
    5: 379850,
    "revenu brut": 1904000,
    trimf: 1500,
    1.5: 619850,
    2.5: 553183,
    3.5: 475683,
    4.5: 415266,
  },
  {
    1: 645250,
    2: 591083,
    3: 507750,
    4: 437750,
    5: 380250,
    "revenu brut": 1905000,
    trimf: 1500,
    1.5: 620250,
    2.5: 553583,
    3.5: 476083,
    4.5: 415666,
  },
  {
    1: 645650,
    2: 591483,
    3: 508150,
    4: 438150,
    5: 380650,
    "revenu brut": 1906000,
    trimf: 1500,
    1.5: 620650,
    2.5: 553983,
    3.5: 476483,
    4.5: 416066,
  },
  {
    1: 646050,
    2: 591883,
    3: 508550,
    4: 438550,
    5: 381050,
    "revenu brut": 1907000,
    trimf: 1500,
    1.5: 621050,
    2.5: 554383,
    3.5: 476883,
    4.5: 416466,
  },
  {
    1: 646450,
    2: 592283,
    3: 508950,
    4: 438950,
    5: 381450,
    "revenu brut": 1908000,
    trimf: 1500,
    1.5: 621450,
    2.5: 554783,
    3.5: 477283,
    4.5: 416866,
  },
  {
    1: 646850,
    2: 592683,
    3: 509350,
    4: 439350,
    5: 381850,
    "revenu brut": 1909000,
    trimf: 1500,
    1.5: 621850,
    2.5: 555183,
    3.5: 477683,
    4.5: 417266,
  },
  {
    1: 647250,
    2: 593083,
    3: 509750,
    4: 439750,
    5: 382250,
    "revenu brut": 191_0000,
    trimf: 1500,
    1.5: 622250,
    2.5: 555583,
    3.5: 478083,
    4.5: 417666,
  },
  {
    1: 647650,
    2: 593483,
    3: 510150,
    4: 440150,
    5: 382650,
    "revenu brut": 1911_000,
    trimf: 1500,
    1.5: 622650,
    2.5: 555983,
    3.5: 478483,
    4.5: 418066,
  },
  {
    1: 648050,
    2: 593883,
    3: 510550,
    4: 440550,
    5: 383050,
    "revenu brut": 1912000,
    trimf: 1500,
    1.5: 623050,
    2.5: 556383,
    3.5: 478883,
    4.5: 418466,
  },
  {
    1: 648450,
    2: 594283,
    3: 510950,
    4: 440950,
    5: 383450,
    "revenu brut": 1913000,
    trimf: 1500,
    1.5: 623450,
    2.5: 556783,
    3.5: 479283,
    4.5: 418866,
  },
  {
    1: 648850,
    2: 594683,
    3: 511350,
    4: 441350,
    5: 383850,
    "revenu brut": 1914000,
    trimf: 1500,
    1.5: 623850,
    2.5: 557183,
    3.5: 479683,
    4.5: 419266,
  },
  {
    1: 649250,
    2: 595083,
    3: 511750,
    4: 441750,
    5: 384250,
    "revenu brut": 1915000,
    trimf: 1500,
    1.5: 624250,
    2.5: 557583,
    3.5: 480083,
    4.5: 419666,
  },
  {
    1: 649650,
    2: 595483,
    3: 512150,
    4: 442150,
    5: 384650,
    "revenu brut": 1916000,
    trimf: 1500,
    1.5: 624650,
    2.5: 557983,
    3.5: 480483,
    4.5: 420066,
  },
  {
    1: 650050,
    2: 595883,
    3: 512550,
    4: 442550,
    5: 385050,
    "revenu brut": 1917000,
    trimf: 1500,
    1.5: 625050,
    2.5: 558383,
    3.5: 480883,
    4.5: 420466,
  },
  {
    1: 650450,
    2: 596283,
    3: 512950,
    4: 442950,
    5: 385450,
    "revenu brut": 1918000,
    trimf: 1500,
    1.5: 625450,
    2.5: 558783,
    3.5: 481283,
    4.5: 420866,
  },
  {
    1: 650850,
    2: 596683,
    3: 513350,
    4: 443350,
    5: 385850,
    "revenu brut": 1919000,
    trimf: 1500,
    1.5: 625850,
    2.5: 559183,
    3.5: 481683,
    4.5: 421266,
  },
  {
    1: 651250,
    2: 597083,
    3: 513750,
    4: 443750,
    5: 386250,
    "revenu brut": 1920000,
    trimf: 1500,
    1.5: 626250,
    2.5: 559583,
    3.5: 482083,
    4.5: 421666,
  },
  {
    1: 651650,
    2: 597483,
    3: 514150,
    4: 444150,
    5: 386650,
    "revenu brut": 1921_000,
    trimf: 1500,
    1.5: 626650,
    2.5: 559983,
    3.5: 482483,
    4.5: 422066,
  },
  {
    1: 652050,
    2: 597883,
    3: 514550,
    4: 444550,
    5: 387050,
    "revenu brut": 1922000,
    trimf: 1500,
    1.5: 627050,
    2.5: 560383,
    3.5: 482883,
    4.5: 422466,
  },
  {
    1: 652450,
    2: 598283,
    3: 514950,
    4: 444950,
    5: 387450,
    "revenu brut": 1923000,
    trimf: 1500,
    1.5: 627450,
    2.5: 560783,
    3.5: 483283,
    4.5: 422866,
  },
  {
    1: 652850,
    2: 598683,
    3: 515350,
    4: 445350,
    5: 387850,
    "revenu brut": 1924000,
    trimf: 1500,
    1.5: 627850,
    2.5: 561183,
    3.5: 483683,
    4.5: 423266,
  },
  {
    1: 653250,
    2: 599083,
    3: 515750,
    4: 445750,
    5: 388250,
    "revenu brut": 1925000,
    trimf: 1500,
    1.5: 628250,
    2.5: 561583,
    3.5: 484083,
    4.5: 423666,
  },
  {
    1: 653650,
    2: 599483,
    3: 516150,
    4: 446150,
    5: 388650,
    "revenu brut": 1926000,
    trimf: 1500,
    1.5: 628650,
    2.5: 561983,
    3.5: 484483,
    4.5: 424066,
  },
  {
    1: 654050,
    2: 599883,
    3: 516550,
    4: 446550,
    5: 389050,
    "revenu brut": 1927000,
    trimf: 1500,
    1.5: 629050,
    2.5: 562383,
    3.5: 484883,
    4.5: 424466,
  },
  {
    1: 654450,
    2: 600283,
    3: 516950,
    4: 446950,
    5: 389450,
    "revenu brut": 1928000,
    trimf: 1500,
    1.5: 629450,
    2.5: 562783,
    3.5: 485283,
    4.5: 424866,
  },
  {
    1: 654850,
    2: 600683,
    3: 517350,
    4: 447350,
    5: 389850,
    "revenu brut": 1929000,
    trimf: 1500,
    1.5: 629850,
    2.5: 563183,
    3.5: 485683,
    4.5: 425266,
  },
  {
    1: 655250,
    2: 601083,
    3: 517750,
    4: 447750,
    5: 390250,
    "revenu brut": 1930000,
    trimf: 1500,
    1.5: 630250,
    2.5: 563583,
    3.5: 486083,
    4.5: 425666,
  },
  {
    1: 655650,
    2: 601483,
    3: 518150,
    4: 448150,
    5: 390650,
    "revenu brut": 1931_000,
    trimf: 1500,
    1.5: 630650,
    2.5: 563983,
    3.5: 486483,
    4.5: 426066,
  },
  {
    1: 656050,
    2: 601883,
    3: 518550,
    4: 448550,
    5: 391050,
    "revenu brut": 1932000,
    trimf: 1500,
    1.5: 631050,
    2.5: 564383,
    3.5: 486883,
    4.5: 426466,
  },
  {
    1: 656450,
    2: 602283,
    3: 518950,
    4: 448950,
    5: 391450,
    "revenu brut": 1933000,
    trimf: 1500,
    1.5: 631450,
    2.5: 564783,
    3.5: 487283,
    4.5: 426866,
  },
  {
    1: 656850,
    2: 602683,
    3: 519350,
    4: 449350,
    5: 391850,
    "revenu brut": 1934000,
    trimf: 1500,
    1.5: 631850,
    2.5: 565183,
    3.5: 487683,
    4.5: 427266,
  },
  {
    1: 657250,
    2: 603083,
    3: 519750,
    4: 449750,
    5: 392250,
    "revenu brut": 1935000,
    trimf: 1500,
    1.5: 632250,
    2.5: 565583,
    3.5: 488083,
    4.5: 427666,
  },
  {
    1: 657650,
    2: 603483,
    3: 520150,
    4: 450150,
    5: 392650,
    "revenu brut": 1936000,
    trimf: 1500,
    1.5: 632650,
    2.5: 565983,
    3.5: 488483,
    4.5: 428066,
  },
  {
    1: 658050,
    2: 603883,
    3: 520550,
    4: 450550,
    5: 393050,
    "revenu brut": 1937000,
    trimf: 1500,
    1.5: 633050,
    2.5: 566383,
    3.5: 488883,
    4.5: 428466,
  },
  {
    1: 658450,
    2: 604283,
    3: 520950,
    4: 450950,
    5: 393450,
    "revenu brut": 1938000,
    trimf: 1500,
    1.5: 633450,
    2.5: 566783,
    3.5: 489283,
    4.5: 428866,
  },
  {
    1: 658850,
    2: 604683,
    3: 521350,
    4: 451350,
    5: 393850,
    "revenu brut": 1939000,
    trimf: 1500,
    1.5: 633850,
    2.5: 567183,
    3.5: 489683,
    4.5: 429266,
  },
  {
    1: 659250,
    2: 605083,
    3: 521750,
    4: 451750,
    5: 394250,
    "revenu brut": 1940000,
    trimf: 1500,
    1.5: 634250,
    2.5: 567583,
    3.5: 490083,
    4.5: 429666,
  },
  {
    1: 659650,
    2: 605483,
    3: 522150,
    4: 452150,
    5: 394650,
    "revenu brut": 1941_000,
    trimf: 1500,
    1.5: 634650,
    2.5: 567983,
    3.5: 490483,
    4.5: 430066,
  },
  {
    1: 660050,
    2: 605883,
    3: 522550,
    4: 452550,
    5: 395050,
    "revenu brut": 1942000,
    trimf: 1500,
    1.5: 635050,
    2.5: 568383,
    3.5: 490883,
    4.5: 430466,
  },
  {
    1: 660450,
    2: 606283,
    3: 522950,
    4: 452950,
    5: 395450,
    "revenu brut": 1943000,
    trimf: 1500,
    1.5: 635450,
    2.5: 568783,
    3.5: 491283,
    4.5: 430866,
  },
  {
    1: 660850,
    2: 606683,
    3: 523350,
    4: 453350,
    5: 395850,
    "revenu brut": 1944000,
    trimf: 1500,
    1.5: 635850,
    2.5: 569183,
    3.5: 491683,
    4.5: 431266,
  },
  {
    1: 661250,
    2: 607083,
    3: 523750,
    4: 453750,
    5: 396250,
    "revenu brut": 1945000,
    trimf: 1500,
    1.5: 636250,
    2.5: 569583,
    3.5: 492083,
    4.5: 431666,
  },
  {
    1: 661650,
    2: 607483,
    3: 524150,
    4: 454150,
    5: 396650,
    "revenu brut": 1946000,
    trimf: 1500,
    1.5: 636650,
    2.5: 569983,
    3.5: 492483,
    4.5: 432066,
  },
  {
    1: 662050,
    2: 607883,
    3: 524550,
    4: 454550,
    5: 397050,
    "revenu brut": 1947000,
    trimf: 1500,
    1.5: 637050,
    2.5: 570383,
    3.5: 492883,
    4.5: 432466,
  },
  {
    1: 662450,
    2: 608283,
    3: 524950,
    4: 454950,
    5: 397450,
    "revenu brut": 1948000,
    trimf: 1500,
    1.5: 637450,
    2.5: 570783,
    3.5: 493283,
    4.5: 432866,
  },
  {
    1: 662850,
    2: 608683,
    3: 525350,
    4: 455350,
    5: 397850,
    "revenu brut": 1949000,
    trimf: 1500,
    1.5: 637850,
    2.5: 571183,
    3.5: 493683,
    4.5: 433266,
  },
  {
    1: 663250,
    2: 609083,
    3: 525750,
    4: 455750,
    5: 398250,
    "revenu brut": 1950000,
    trimf: 1500,
    1.5: 638250,
    2.5: 571583,
    3.5: 494083,
    4.5: 433666,
  },
  {
    1: 663650,
    2: 609483,
    3: 526150,
    4: 456150,
    5: 398650,
    "revenu brut": 1951_000,
    trimf: 1500,
    1.5: 638650,
    2.5: 571983,
    3.5: 494483,
    4.5: 434066,
  },
  {
    1: 664050,
    2: 609883,
    3: 526550,
    4: 456550,
    5: 399050,
    "revenu brut": 1952000,
    trimf: 1500,
    1.5: 639050,
    2.5: 572383,
    3.5: 494883,
    4.5: 434466,
  },
  {
    1: 664450,
    2: 610283,
    3: 526950,
    4: 456950,
    5: 399450,
    "revenu brut": 1953000,
    trimf: 1500,
    1.5: 639450,
    2.5: 572783,
    3.5: 495283,
    4.5: 434866,
  },
  {
    1: 664850,
    2: 610683,
    3: 527350,
    4: 457350,
    5: 399850,
    "revenu brut": 1954000,
    trimf: 1500,
    1.5: 639850,
    2.5: 573183,
    3.5: 495683,
    4.5: 435266,
  },
  {
    1: 665250,
    2: 611083,
    3: 527750,
    4: 457750,
    5: 400250,
    "revenu brut": 1955000,
    trimf: 1500,
    1.5: 640250,
    2.5: 573583,
    3.5: 496083,
    4.5: 435666,
  },
  {
    1: 665650,
    2: 611483,
    3: 528150,
    4: 458150,
    5: 400650,
    "revenu brut": 1956000,
    trimf: 1500,
    1.5: 640650,
    2.5: 573983,
    3.5: 496483,
    4.5: 436066,
  },
  {
    1: 666050,
    2: 611883,
    3: 528550,
    4: 458550,
    5: 401050,
    "revenu brut": 1957000,
    trimf: 1500,
    1.5: 641050,
    2.5: 574383,
    3.5: 496883,
    4.5: 436466,
  },
  {
    1: 666450,
    2: 612283,
    3: 528950,
    4: 458950,
    5: 401450,
    "revenu brut": 1958000,
    trimf: 1500,
    1.5: 641450,
    2.5: 574783,
    3.5: 497283,
    4.5: 436866,
  },
  {
    1: 666850,
    2: 612683,
    3: 529350,
    4: 459350,
    5: 401850,
    "revenu brut": 1959000,
    trimf: 1500,
    1.5: 641850,
    2.5: 575183,
    3.5: 497683,
    4.5: 437266,
  },
  {
    1: 667250,
    2: 613083,
    3: 529750,
    4: 459750,
    5: 402250,
    "revenu brut": 1960000,
    trimf: 1500,
    1.5: 642250,
    2.5: 575583,
    3.5: 498083,
    4.5: 437666,
  },
  {
    1: 667650,
    2: 613483,
    3: 530150,
    4: 460150,
    5: 402650,
    "revenu brut": 1961_000,
    trimf: 1500,
    1.5: 642650,
    2.5: 575983,
    3.5: 498483,
    4.5: 438066,
  },
  {
    1: 668050,
    2: 613883,
    3: 530550,
    4: 460550,
    5: 403050,
    "revenu brut": 1962000,
    trimf: 1500,
    1.5: 643050,
    2.5: 576383,
    3.5: 498883,
    4.5: 438466,
  },
  {
    1: 668450,
    2: 614283,
    3: 530950,
    4: 460950,
    5: 403450,
    "revenu brut": 1963000,
    trimf: 1500,
    1.5: 643450,
    2.5: 576783,
    3.5: 499283,
    4.5: 438866,
  },
  {
    1: 668850,
    2: 614683,
    3: 531350,
    4: 461350,
    5: 403850,
    "revenu brut": 1964000,
    trimf: 1500,
    1.5: 643850,
    2.5: 577183,
    3.5: 499683,
    4.5: 439266,
  },
  {
    1: 669250,
    2: 615083,
    3: 531750,
    4: 461750,
    5: 404250,
    "revenu brut": 1965000,
    trimf: 1500,
    1.5: 644250,
    2.5: 577583,
    3.5: 500083,
    4.5: 439666,
  },
  {
    1: 669650,
    2: 615483,
    3: 532150,
    4: 462150,
    5: 404650,
    "revenu brut": 1966000,
    trimf: 1500,
    1.5: 644650,
    2.5: 577983,
    3.5: 500483,
    4.5: 440066,
  },
  {
    1: 670050,
    2: 615883,
    3: 532550,
    4: 462550,
    5: 405050,
    "revenu brut": 1967000,
    trimf: 1500,
    1.5: 645050,
    2.5: 578383,
    3.5: 500883,
    4.5: 440466,
  },
  {
    1: 670450,
    2: 616283,
    3: 532950,
    4: 462950,
    5: 405450,
    "revenu brut": 1968000,
    trimf: 1500,
    1.5: 645450,
    2.5: 578783,
    3.5: 501283,
    4.5: 440866,
  },
  {
    1: 670850,
    2: 616683,
    3: 533350,
    4: 463350,
    5: 405850,
    "revenu brut": 1969000,
    trimf: 1500,
    1.5: 645850,
    2.5: 579183,
    3.5: 501683,
    4.5: 441266,
  },
  {
    1: 671250,
    2: 617083,
    3: 533750,
    4: 463750,
    5: 406250,
    "revenu brut": 1970000,
    trimf: 1500,
    1.5: 646250,
    2.5: 579583,
    3.5: 502083,
    4.5: 441666,
  },
  {
    1: 671650,
    2: 617483,
    3: 534150,
    4: 464150,
    5: 406650,
    "revenu brut": 1971_000,
    trimf: 1500,
    1.5: 646650,
    2.5: 579983,
    3.5: 502483,
    4.5: 442066,
  },
  {
    1: 672050,
    2: 617883,
    3: 534550,
    4: 464550,
    5: 407050,
    "revenu brut": 1972000,
    trimf: 1500,
    1.5: 647050,
    2.5: 580383,
    3.5: 502883,
    4.5: 442466,
  },
  {
    1: 672450,
    2: 618283,
    3: 534950,
    4: 464950,
    5: 407450,
    "revenu brut": 1973000,
    trimf: 1500,
    1.5: 647450,
    2.5: 580783,
    3.5: 503283,
    4.5: 442866,
  },
  {
    1: 672850,
    2: 618683,
    3: 535350,
    4: 465350,
    5: 407850,
    "revenu brut": 1974000,
    trimf: 1500,
    1.5: 647850,
    2.5: 581183,
    3.5: 503683,
    4.5: 443266,
  },
  {
    1: 673250,
    2: 619083,
    3: 535750,
    4: 465750,
    5: 408250,
    "revenu brut": 1975000,
    trimf: 1500,
    1.5: 648250,
    2.5: 581583,
    3.5: 504083,
    4.5: 443666,
  },
  {
    1: 673650,
    2: 619483,
    3: 536150,
    4: 466150,
    5: 408650,
    "revenu brut": 1976000,
    trimf: 1500,
    1.5: 648650,
    2.5: 581983,
    3.5: 504483,
    4.5: 444066,
  },
  {
    1: 674050,
    2: 619883,
    3: 536550,
    4: 466550,
    5: 409050,
    "revenu brut": 1977000,
    trimf: 1500,
    1.5: 649050,
    2.5: 582383,
    3.5: 504883,
    4.5: 444466,
  },
  {
    1: 674450,
    2: 620283,
    3: 536950,
    4: 466950,
    5: 409450,
    "revenu brut": 1978000,
    trimf: 1500,
    1.5: 649450,
    2.5: 582783,
    3.5: 505283,
    4.5: 444866,
  },
  {
    1: 674850,
    2: 620683,
    3: 537350,
    4: 467350,
    5: 409850,
    "revenu brut": 1979000,
    trimf: 1500,
    1.5: 649850,
    2.5: 583183,
    3.5: 505683,
    4.5: 445266,
  },
  {
    1: 675250,
    2: 621083,
    3: 537750,
    4: 467750,
    5: 410250,
    "revenu brut": 1980000,
    trimf: 1500,
    1.5: 650250,
    2.5: 583583,
    3.5: 506083,
    4.5: 445666,
  },
  {
    1: 675650,
    2: 621483,
    3: 538150,
    4: 468150,
    5: 410650,
    "revenu brut": 1981_000,
    trimf: 1500,
    1.5: 650650,
    2.5: 583983,
    3.5: 506483,
    4.5: 446066,
  },
  {
    1: 676050,
    2: 621883,
    3: 538550,
    4: 468550,
    5: 411050,
    "revenu brut": 1982000,
    trimf: 1500,
    1.5: 651050,
    2.5: 584383,
    3.5: 506883,
    4.5: 446466,
  },
  {
    1: 676450,
    2: 622283,
    3: 538950,
    4: 468950,
    5: 411450,
    "revenu brut": 1983000,
    trimf: 1500,
    1.5: 651450,
    2.5: 584783,
    3.5: 507283,
    4.5: 446866,
  },
  {
    1: 676850,
    2: 622683,
    3: 539350,
    4: 469350,
    5: 411850,
    "revenu brut": 1984000,
    trimf: 1500,
    1.5: 651850,
    2.5: 585183,
    3.5: 507683,
    4.5: 447266,
  },
  {
    1: 677250,
    2: 623083,
    3: 539750,
    4: 469750,
    5: 412250,
    "revenu brut": 1985000,
    trimf: 1500,
    1.5: 652250,
    2.5: 585583,
    3.5: 508083,
    4.5: 447666,
  },
  {
    1: 677650,
    2: 623483,
    3: 540150,
    4: 470150,
    5: 412650,
    "revenu brut": 1986000,
    trimf: 1500,
    1.5: 652650,
    2.5: 585983,
    3.5: 508483,
    4.5: 448066,
  },
  {
    1: 678050,
    2: 623883,
    3: 540550,
    4: 470550,
    5: 413050,
    "revenu brut": 1987000,
    trimf: 1500,
    1.5: 653050,
    2.5: 586383,
    3.5: 508883,
    4.5: 448466,
  },
  {
    1: 678450,
    2: 624283,
    3: 540950,
    4: 470950,
    5: 413450,
    "revenu brut": 1988000,
    trimf: 1500,
    1.5: 653450,
    2.5: 586783,
    3.5: 509283,
    4.5: 448866,
  },
  {
    1: 678850,
    2: 624683,
    3: 541350,
    4: 471350,
    5: 413850,
    "revenu brut": 1989000,
    trimf: 1500,
    1.5: 653850,
    2.5: 587183,
    3.5: 509683,
    4.5: 449266,
  },
  {
    1: 679250,
    2: 625083,
    3: 541750,
    4: 471750,
    5: 414250,
    "revenu brut": 1990000,
    trimf: 1500,
    1.5: 654250,
    2.5: 587583,
    3.5: 510083,
    4.5: 449666,
  },
  {
    1: 679650,
    2: 625483,
    3: 542150,
    4: 472150,
    5: 414650,
    "revenu brut": 1991_000,
    trimf: 1500,
    1.5: 654650,
    2.5: 587983,
    3.5: 510483,
    4.5: 450066,
  },
  {
    1: 680050,
    2: 625883,
    3: 542550,
    4: 472550,
    5: 415050,
    "revenu brut": 1992000,
    trimf: 1500,
    1.5: 655050,
    2.5: 588383,
    3.5: 510883,
    4.5: 450466,
  },
  {
    1: 680450,
    2: 626283,
    3: 542950,
    4: 472950,
    5: 415450,
    "revenu brut": 1993000,
    trimf: 1500,
    1.5: 655450,
    2.5: 588783,
    3.5: 511283,
    4.5: 450866,
  },
  {
    1: 680850,
    2: 626683,
    3: 543350,
    4: 473350,
    5: 415850,
    "revenu brut": 1994000,
    trimf: 1500,
    1.5: 655850,
    2.5: 589183,
    3.5: 511683,
    4.5: 451266,
  },
  {
    1: 681250,
    2: 627083,
    3: 543750,
    4: 473750,
    5: 416250,
    "revenu brut": 1995000,
    trimf: 1500,
    1.5: 656250,
    2.5: 589583,
    3.5: 512083,
    4.5: 451666,
  },
  {
    1: 681650,
    2: 627483,
    3: 544150,
    4: 474150,
    5: 416650,
    "revenu brut": 1996000,
    trimf: 1500,
    1.5: 656650,
    2.5: 589983,
    3.5: 512483,
    4.5: 452066,
  },
  {
    1: 682050,
    2: 627883,
    3: 544550,
    4: 474550,
    5: 417050,
    "revenu brut": 1997000,
    trimf: 1500,
    1.5: 657050,
    2.5: 590383,
    3.5: 512883,
    4.5: 452466,
  },
  {
    1: 682450,
    2: 628283,
    3: 544950,
    4: 474950,
    5: 417450,
    "revenu brut": 1998000,
    trimf: 1500,
    1.5: 657450,
    2.5: 590783,
    3.5: 513283,
    4.5: 452866,
  },
  {
    1: 682850,
    2: 628683,
    3: 545350,
    4: 475350,
    5: 417850,
    "revenu brut": 1999000,
    trimf: 1500,
    1.5: 657850,
    2.5: 591183,
    3.5: 513683,
    4.5: 453266,
  },
  {
    1: 683250,
    2: 629083,
    3: 545750,
    4: 475750,
    5: 418250,
    "revenu brut": 2000000,
    trimf: 1500,
    1.5: 658250,
    2.5: 591583,
    3.5: 514083,
    4.5: 453666,
  },
  {
    1: 683650,
    2: 629483,
    3: 546150,
    4: 476150,
    5: 418650,
    "revenu brut": 2001_000,
    trimf: 1500,
    1.5: 658650,
    2.5: 591983,
    3.5: 514483,
    4.5: 454066,
  },
  {
    1: 684050,
    2: 629883,
    3: 546550,
    4: 476550,
    5: 419050,
    "revenu brut": 2002000,
    trimf: 1500,
    1.5: 659050,
    2.5: 592383,
    3.5: 514883,
    4.5: 454466,
  },
  {
    1: 684450,
    2: 630283,
    3: 546950,
    4: 476950,
    5: 419450,
    "revenu brut": 2003000,
    trimf: 1500,
    1.5: 659450,
    2.5: 592783,
    3.5: 515283,
    4.5: 454866,
  },
  {
    1: 684850,
    2: 630683,
    3: 547350,
    4: 477350,
    5: 419850,
    "revenu brut": 2004000,
    trimf: 1500,
    1.5: 659850,
    2.5: 593183,
    3.5: 515683,
    4.5: 455266,
  },
  {
    1: 685250,
    2: 631083,
    3: 547750,
    4: 477750,
    5: 420250,
    "revenu brut": 2005000,
    trimf: 1500,
    1.5: 660250,
    2.5: 593583,
    3.5: 516083,
    4.5: 455666,
  },
  {
    1: 685650,
    2: 631483,
    3: 548150,
    4: 478150,
    5: 420650,
    "revenu brut": 2006000,
    trimf: 1500,
    1.5: 660650,
    2.5: 593983,
    3.5: 516483,
    4.5: 456066,
  },
  {
    1: 686050,
    2: 631883,
    3: 548550,
    4: 478550,
    5: 421050,
    "revenu brut": 2007000,
    trimf: 1500,
    1.5: 661050,
    2.5: 594383,
    3.5: 516883,
    4.5: 456466,
  },
  {
    1: 686450,
    2: 632283,
    3: 548950,
    4: 478950,
    5: 421450,
    "revenu brut": 2008000,
    trimf: 1500,
    1.5: 661450,
    2.5: 594783,
    3.5: 517283,
    4.5: 456866,
  },
  {
    1: 686850,
    2: 632683,
    3: 549350,
    4: 479350,
    5: 421850,
    "revenu brut": 2009000,
    trimf: 1500,
    1.5: 661850,
    2.5: 595183,
    3.5: 517683,
    4.5: 457266,
  },
  {
    1: 687250,
    2: 633083,
    3: 549750,
    4: 479750,
    5: 422250,
    "revenu brut": 201_0000,
    trimf: 1500,
    1.5: 662250,
    2.5: 595583,
    3.5: 518083,
    4.5: 457666,
  },
  {
    1: 687650,
    2: 633483,
    3: 550150,
    4: 480150,
    5: 422650,
    "revenu brut": 2011_000,
    trimf: 1500,
    1.5: 662650,
    2.5: 595983,
    3.5: 518483,
    4.5: 458066,
  },
  {
    1: 688050,
    2: 633883,
    3: 550550,
    4: 480550,
    5: 423050,
    "revenu brut": 2012000,
    trimf: 1500,
    1.5: 663050,
    2.5: 596383,
    3.5: 518883,
    4.5: 458466,
  },
  {
    1: 688450,
    2: 634283,
    3: 550950,
    4: 480950,
    5: 423450,
    "revenu brut": 2013000,
    trimf: 1500,
    1.5: 663450,
    2.5: 596783,
    3.5: 519283,
    4.5: 458866,
  },
  {
    1: 688850,
    2: 634683,
    3: 551350,
    4: 481350,
    5: 423850,
    "revenu brut": 2014000,
    trimf: 1500,
    1.5: 663850,
    2.5: 597183,
    3.5: 519683,
    4.5: 459266,
  },
  {
    1: 689250,
    2: 635083,
    3: 551750,
    4: 481750,
    5: 424250,
    "revenu brut": 2015000,
    trimf: 1500,
    1.5: 664250,
    2.5: 597583,
    3.5: 520083,
    4.5: 459666,
  },
  {
    1: 689650,
    2: 635483,
    3: 552150,
    4: 482150,
    5: 424650,
    "revenu brut": 2016000,
    trimf: 1500,
    1.5: 664650,
    2.5: 597983,
    3.5: 520483,
    4.5: 460066,
  },
  {
    1: 690050,
    2: 635883,
    3: 552550,
    4: 482550,
    5: 425050,
    "revenu brut": 2017000,
    trimf: 1500,
    1.5: 665050,
    2.5: 598383,
    3.5: 520883,
    4.5: 460466,
  },
  {
    1: 690450,
    2: 636283,
    3: 552950,
    4: 482950,
    5: 425450,
    "revenu brut": 2018000,
    trimf: 1500,
    1.5: 665450,
    2.5: 598783,
    3.5: 521283,
    4.5: 460866,
  },
  {
    1: 690850,
    2: 636683,
    3: 553350,
    4: 483350,
    5: 425850,
    "revenu brut": 2019000,
    trimf: 1500,
    1.5: 665850,
    2.5: 599183,
    3.5: 521683,
    4.5: 461266,
  },
  {
    1: 691250,
    2: 637083,
    3: 553750,
    4: 483750,
    5: 426250,
    "revenu brut": 2020000,
    trimf: 1500,
    1.5: 666250,
    2.5: 599583,
    3.5: 522083,
    4.5: 461666,
  },
  {
    1: 691650,
    2: 637483,
    3: 554150,
    4: 484150,
    5: 426650,
    "revenu brut": 2021_000,
    trimf: 1500,
    1.5: 666650,
    2.5: 599983,
    3.5: 522483,
    4.5: 462066,
  },
  {
    1: 692050,
    2: 637883,
    3: 554550,
    4: 484550,
    5: 427050,
    "revenu brut": 2022000,
    trimf: 1500,
    1.5: 667050,
    2.5: 600383,
    3.5: 522883,
    4.5: 462466,
  },
  {
    1: 692450,
    2: 638283,
    3: 554950,
    4: 484950,
    5: 427450,
    "revenu brut": 2023000,
    trimf: 1500,
    1.5: 667450,
    2.5: 600783,
    3.5: 523283,
    4.5: 462866,
  },
  {
    1: 692850,
    2: 638683,
    3: 555350,
    4: 485350,
    5: 427850,
    "revenu brut": 2024000,
    trimf: 1500,
    1.5: 667850,
    2.5: 601183,
    3.5: 523683,
    4.5: 463266,
  },
  {
    1: 693250,
    2: 639083,
    3: 555750,
    4: 485750,
    5: 428250,
    "revenu brut": 2025000,
    trimf: 1500,
    1.5: 668250,
    2.5: 601583,
    3.5: 524083,
    4.5: 463666,
  },
  {
    1: 693650,
    2: 639483,
    3: 556150,
    4: 486150,
    5: 428650,
    "revenu brut": 2026000,
    trimf: 1500,
    1.5: 668650,
    2.5: 601983,
    3.5: 524483,
    4.5: 464066,
  },
  {
    1: 694050,
    2: 639883,
    3: 556550,
    4: 486550,
    5: 429050,
    "revenu brut": 2027000,
    trimf: 1500,
    1.5: 669050,
    2.5: 602383,
    3.5: 524883,
    4.5: 464466,
  },
  {
    1: 694450,
    2: 640283,
    3: 556950,
    4: 486950,
    5: 429450,
    "revenu brut": 2028000,
    trimf: 1500,
    1.5: 669450,
    2.5: 602783,
    3.5: 525283,
    4.5: 464866,
  },
  {
    1: 694850,
    2: 640683,
    3: 557350,
    4: 487350,
    5: 429850,
    "revenu brut": 2029000,
    trimf: 1500,
    1.5: 669850,
    2.5: 603183,
    3.5: 525683,
    4.5: 465266,
  },
  {
    1: 695250,
    2: 641083,
    3: 557750,
    4: 487750,
    5: 430250,
    "revenu brut": 2030000,
    trimf: 1500,
    1.5: 670250,
    2.5: 603583,
    3.5: 526083,
    4.5: 465666,
  },
  {
    1: 695650,
    2: 641483,
    3: 558150,
    4: 488150,
    5: 430650,
    "revenu brut": 2031_000,
    trimf: 1500,
    1.5: 670650,
    2.5: 603983,
    3.5: 526483,
    4.5: 466066,
  },
  {
    1: 696050,
    2: 641883,
    3: 558550,
    4: 488550,
    5: 431050,
    "revenu brut": 2032000,
    trimf: 1500,
    1.5: 671050,
    2.5: 604383,
    3.5: 526883,
    4.5: 466466,
  },
  {
    1: 696450,
    2: 642283,
    3: 558950,
    4: 488950,
    5: 431450,
    "revenu brut": 2033000,
    trimf: 1500,
    1.5: 671450,
    2.5: 604783,
    3.5: 527283,
    4.5: 466866,
  },
  {
    1: 696850,
    2: 642683,
    3: 559350,
    4: 489350,
    5: 431850,
    "revenu brut": 2034000,
    trimf: 1500,
    1.5: 671850,
    2.5: 605183,
    3.5: 527683,
    4.5: 467266,
  },
  {
    1: 697250,
    2: 643083,
    3: 559750,
    4: 489750,
    5: 432250,
    "revenu brut": 2035000,
    trimf: 1500,
    1.5: 672250,
    2.5: 605583,
    3.5: 528083,
    4.5: 467666,
  },
  {
    1: 697650,
    2: 643483,
    3: 560150,
    4: 490150,
    5: 432650,
    "revenu brut": 2036000,
    trimf: 1500,
    1.5: 672650,
    2.5: 605983,
    3.5: 528483,
    4.5: 468066,
  },
  {
    1: 698050,
    2: 643883,
    3: 560550,
    4: 490550,
    5: 433050,
    "revenu brut": 2037000,
    trimf: 1500,
    1.5: 673050,
    2.5: 606383,
    3.5: 528883,
    4.5: 468466,
  },
  {
    1: 698450,
    2: 644283,
    3: 560950,
    4: 490950,
    5: 433450,
    "revenu brut": 2038000,
    trimf: 1500,
    1.5: 673450,
    2.5: 606783,
    3.5: 529283,
    4.5: 468866,
  },
  {
    1: 698850,
    2: 644683,
    3: 561350,
    4: 491350,
    5: 433850,
    "revenu brut": 2039000,
    trimf: 1500,
    1.5: 673850,
    2.5: 607183,
    3.5: 529683,
    4.5: 469266,
  },
  {
    1: 699250,
    2: 645083,
    3: 561750,
    4: 491750,
    5: 434250,
    "revenu brut": 2040000,
    trimf: 1500,
    1.5: 674250,
    2.5: 607583,
    3.5: 530083,
    4.5: 469666,
  },
  {
    1: 699650,
    2: 645483,
    3: 562150,
    4: 492150,
    5: 434650,
    "revenu brut": 2041_000,
    trimf: 1500,
    1.5: 674650,
    2.5: 607983,
    3.5: 530483,
    4.5: 470066,
  },
  {
    1: 700050,
    2: 645883,
    3: 562550,
    4: 492550,
    5: 435050,
    "revenu brut": 2042000,
    trimf: 1500,
    1.5: 675050,
    2.5: 608383,
    3.5: 530883,
    4.5: 470466,
  },
  {
    1: 700450,
    2: 646283,
    3: 562950,
    4: 492950,
    5: 435450,
    "revenu brut": 2043000,
    trimf: 1500,
    1.5: 675450,
    2.5: 608783,
    3.5: 531283,
    4.5: 470866,
  },
  {
    1: 700850,
    2: 646683,
    3: 563350,
    4: 493350,
    5: 435850,
    "revenu brut": 2044000,
    trimf: 1500,
    1.5: 675850,
    2.5: 609183,
    3.5: 531683,
    4.5: 471266,
  },
  {
    1: 701250,
    2: 647083,
    3: 563750,
    4: 493750,
    5: 436250,
    "revenu brut": 2045000,
    trimf: 1500,
    1.5: 676250,
    2.5: 609583,
    3.5: 532083,
    4.5: 471666,
  },
  {
    1: 701650,
    2: 647483,
    3: 564150,
    4: 494150,
    5: 436650,
    "revenu brut": 2046000,
    trimf: 1500,
    1.5: 676650,
    2.5: 609983,
    3.5: 532483,
    4.5: 472066,
  },
  {
    1: 702050,
    2: 647883,
    3: 564550,
    4: 494550,
    5: 437050,
    "revenu brut": 2047000,
    trimf: 1500,
    1.5: 677050,
    2.5: 610383,
    3.5: 532883,
    4.5: 472466,
  },
  {
    1: 702450,
    2: 648283,
    3: 564950,
    4: 494950,
    5: 437450,
    "revenu brut": 2048000,
    trimf: 1500,
    1.5: 677450,
    2.5: 610783,
    3.5: 533283,
    4.5: 472866,
  },
  {
    1: 702850,
    2: 648683,
    3: 565350,
    4: 495350,
    5: 437850,
    "revenu brut": 2049000,
    trimf: 1500,
    1.5: 677850,
    2.5: 611183,
    3.5: 533683,
    4.5: 473266,
  },
  {
    1: 703250,
    2: 649083,
    3: 565750,
    4: 495750,
    5: 438250,
    "revenu brut": 2050000,
    trimf: 1500,
    1.5: 678250,
    2.5: 611583,
    3.5: 534083,
    4.5: 473666,
  },
  {
    1: 703650,
    2: 649483,
    3: 566150,
    4: 496150,
    5: 438650,
    "revenu brut": 2051_000,
    trimf: 1500,
    1.5: 678650,
    2.5: 611983,
    3.5: 534483,
    4.5: 474066,
  },
  {
    1: 704050,
    2: 649883,
    3: 566550,
    4: 496550,
    5: 439050,
    "revenu brut": 2052000,
    trimf: 1500,
    1.5: 679050,
    2.5: 612383,
    3.5: 534883,
    4.5: 474466,
  },
  {
    1: 704450,
    2: 650283,
    3: 566950,
    4: 496950,
    5: 439450,
    "revenu brut": 2053000,
    trimf: 1500,
    1.5: 679450,
    2.5: 612783,
    3.5: 535283,
    4.5: 474866,
  },
  {
    1: 704850,
    2: 650683,
    3: 567350,
    4: 497350,
    5: 439850,
    "revenu brut": 2054000,
    trimf: 1500,
    1.5: 679850,
    2.5: 613183,
    3.5: 535683,
    4.5: 475266,
  },
  {
    1: 705250,
    2: 651083,
    3: 567750,
    4: 497750,
    5: 440250,
    "revenu brut": 2055000,
    trimf: 1500,
    1.5: 680250,
    2.5: 613583,
    3.5: 536083,
    4.5: 475666,
  },
  {
    1: 705650,
    2: 651483,
    3: 568150,
    4: 498150,
    5: 440650,
    "revenu brut": 2056000,
    trimf: 1500,
    1.5: 680650,
    2.5: 613983,
    3.5: 536483,
    4.5: 476066,
  },
  {
    1: 706050,
    2: 651883,
    3: 568550,
    4: 498550,
    5: 441050,
    "revenu brut": 2057000,
    trimf: 1500,
    1.5: 681050,
    2.5: 614383,
    3.5: 536883,
    4.5: 476466,
  },
  {
    1: 706450,
    2: 652283,
    3: 568950,
    4: 498950,
    5: 441450,
    "revenu brut": 2058000,
    trimf: 1500,
    1.5: 681450,
    2.5: 614783,
    3.5: 537283,
    4.5: 476866,
  },
  {
    1: 706850,
    2: 652683,
    3: 569350,
    4: 499350,
    5: 441850,
    "revenu brut": 2059000,
    trimf: 1500,
    1.5: 681850,
    2.5: 615183,
    3.5: 537683,
    4.5: 477266,
  },
  {
    1: 707250,
    2: 653083,
    3: 569750,
    4: 499750,
    5: 442250,
    "revenu brut": 2060000,
    trimf: 1500,
    1.5: 682250,
    2.5: 615583,
    3.5: 538083,
    4.5: 477666,
  },
  {
    1: 707650,
    2: 653483,
    3: 570150,
    4: 500150,
    5: 442650,
    "revenu brut": 2061_000,
    trimf: 1500,
    1.5: 682650,
    2.5: 615983,
    3.5: 538483,
    4.5: 478066,
  },
  {
    1: 708050,
    2: 653883,
    3: 570550,
    4: 500550,
    5: 443050,
    "revenu brut": 2062000,
    trimf: 1500,
    1.5: 683050,
    2.5: 616383,
    3.5: 538883,
    4.5: 478466,
  },
  {
    1: 708450,
    2: 654283,
    3: 570950,
    4: 500950,
    5: 443450,
    "revenu brut": 2063000,
    trimf: 1500,
    1.5: 683450,
    2.5: 616783,
    3.5: 539283,
    4.5: 478866,
  },
  {
    1: 708850,
    2: 654683,
    3: 571350,
    4: 501350,
    5: 443850,
    "revenu brut": 2064000,
    trimf: 1500,
    1.5: 683850,
    2.5: 617183,
    3.5: 539683,
    4.5: 479266,
  },
  {
    1: 709250,
    2: 655083,
    3: 571750,
    4: 501750,
    5: 444250,
    "revenu brut": 2065000,
    trimf: 1500,
    1.5: 684250,
    2.5: 617583,
    3.5: 540083,
    4.5: 479666,
  },
  {
    1: 709650,
    2: 655483,
    3: 572150,
    4: 502150,
    5: 444650,
    "revenu brut": 2066000,
    trimf: 1500,
    1.5: 684650,
    2.5: 617983,
    3.5: 540483,
    4.5: 480066,
  },
  {
    1: 710050,
    2: 655883,
    3: 572550,
    4: 502550,
    5: 445050,
    "revenu brut": 2067000,
    trimf: 1500,
    1.5: 685050,
    2.5: 618383,
    3.5: 540883,
    4.5: 480466,
  },
  {
    1: 710450,
    2: 656283,
    3: 572950,
    4: 502950,
    5: 445450,
    "revenu brut": 2068000,
    trimf: 1500,
    1.5: 685450,
    2.5: 618783,
    3.5: 541283,
    4.5: 480866,
  },
  {
    1: 710850,
    2: 656683,
    3: 573350,
    4: 503350,
    5: 445850,
    "revenu brut": 2069000,
    trimf: 1500,
    1.5: 685850,
    2.5: 619183,
    3.5: 541683,
    4.5: 481266,
  },
  {
    1: 711250,
    2: 657083,
    3: 573750,
    4: 503750,
    5: 446250,
    "revenu brut": 2070000,
    trimf: 1500,
    1.5: 686250,
    2.5: 619583,
    3.5: 542083,
    4.5: 481666,
  },
  {
    1: 711650,
    2: 657483,
    3: 574150,
    4: 504150,
    5: 446650,
    "revenu brut": 2071_000,
    trimf: 1500,
    1.5: 686650,
    2.5: 619983,
    3.5: 542483,
    4.5: 482066,
  },
  {
    1: 712050,
    2: 657883,
    3: 574550,
    4: 504550,
    5: 447050,
    "revenu brut": 2072000,
    trimf: 1500,
    1.5: 687050,
    2.5: 620383,
    3.5: 542883,
    4.5: 482466,
  },
  {
    1: 712450,
    2: 658283,
    3: 574950,
    4: 504950,
    5: 447450,
    "revenu brut": 2073000,
    trimf: 1500,
    1.5: 687450,
    2.5: 620783,
    3.5: 543283,
    4.5: 482866,
  },
  {
    1: 712850,
    2: 658683,
    3: 575350,
    4: 505350,
    5: 447850,
    "revenu brut": 2074000,
    trimf: 1500,
    1.5: 687850,
    2.5: 621183,
    3.5: 543683,
    4.5: 483266,
  },
  {
    1: 713250,
    2: 659083,
    3: 575750,
    4: 505750,
    5: 448250,
    "revenu brut": 2075000,
    trimf: 1500,
    1.5: 688250,
    2.5: 621583,
    3.5: 544083,
    4.5: 483666,
  },
  {
    1: 713650,
    2: 659483,
    3: 576150,
    4: 506150,
    5: 448650,
    "revenu brut": 2076000,
    trimf: 1500,
    1.5: 688650,
    2.5: 621983,
    3.5: 544483,
    4.5: 484066,
  },
  {
    1: 714050,
    2: 659883,
    3: 576550,
    4: 506550,
    5: 449050,
    "revenu brut": 2077000,
    trimf: 1500,
    1.5: 689050,
    2.5: 622383,
    3.5: 544883,
    4.5: 484466,
  },
  {
    1: 714450,
    2: 660283,
    3: 576950,
    4: 506950,
    5: 449450,
    "revenu brut": 2078000,
    trimf: 1500,
    1.5: 689450,
    2.5: 622783,
    3.5: 545283,
    4.5: 484866,
  },
  {
    1: 714850,
    2: 660683,
    3: 577350,
    4: 507350,
    5: 449850,
    "revenu brut": 2079000,
    trimf: 1500,
    1.5: 689850,
    2.5: 623183,
    3.5: 545683,
    4.5: 485266,
  },
  {
    1: 715250,
    2: 661083,
    3: 577750,
    4: 507750,
    5: 450250,
    "revenu brut": 2080000,
    trimf: 1500,
    1.5: 690250,
    2.5: 623583,
    3.5: 546083,
    4.5: 485666,
  },
  {
    1: 715650,
    2: 661483,
    3: 578150,
    4: 508150,
    5: 450650,
    "revenu brut": 2081_000,
    trimf: 1500,
    1.5: 690650,
    2.5: 623983,
    3.5: 546483,
    4.5: 486066,
  },
  {
    1: 716050,
    2: 661883,
    3: 578550,
    4: 508550,
    5: 451050,
    "revenu brut": 2082000,
    trimf: 1500,
    1.5: 691050,
    2.5: 624383,
    3.5: 546883,
    4.5: 486466,
  },
  {
    1: 716450,
    2: 662283,
    3: 578950,
    4: 508950,
    5: 451450,
    "revenu brut": 2083000,
    trimf: 1500,
    1.5: 691450,
    2.5: 624783,
    3.5: 547283,
    4.5: 486866,
  },
  {
    1: 716850,
    2: 662683,
    3: 579350,
    4: 509350,
    5: 451850,
    "revenu brut": 2084000,
    trimf: 1500,
    1.5: 691850,
    2.5: 625183,
    3.5: 547683,
    4.5: 487266,
  },
  {
    1: 717250,
    2: 663083,
    3: 579750,
    4: 509750,
    5: 452250,
    "revenu brut": 2085000,
    trimf: 1500,
    1.5: 692250,
    2.5: 625583,
    3.5: 548083,
    4.5: 487666,
  },
  {
    1: 717650,
    2: 663483,
    3: 580150,
    4: 510150,
    5: 452650,
    "revenu brut": 2086000,
    trimf: 1500,
    1.5: 692650,
    2.5: 625983,
    3.5: 548483,
    4.5: 488066,
  },
  {
    1: 718050,
    2: 663883,
    3: 580550,
    4: 510550,
    5: 453050,
    "revenu brut": 2087000,
    trimf: 1500,
    1.5: 693050,
    2.5: 626383,
    3.5: 548883,
    4.5: 488466,
  },
  {
    1: 718450,
    2: 664283,
    3: 580950,
    4: 510950,
    5: 453450,
    "revenu brut": 2088000,
    trimf: 1500,
    1.5: 693450,
    2.5: 626783,
    3.5: 549283,
    4.5: 488866,
  },
  {
    1: 718850,
    2: 664683,
    3: 581350,
    4: 511350,
    5: 453850,
    "revenu brut": 2089000,
    trimf: 1500,
    1.5: 693850,
    2.5: 627183,
    3.5: 549683,
    4.5: 489266,
  },
  {
    1: 719250,
    2: 665083,
    3: 581750,
    4: 511750,
    5: 454250,
    "revenu brut": 2090000,
    trimf: 1500,
    1.5: 694250,
    2.5: 627583,
    3.5: 550083,
    4.5: 489666,
  },
  {
    1: 719650,
    2: 665483,
    3: 582150,
    4: 512150,
    5: 454650,
    "revenu brut": 2091_000,
    trimf: 1500,
    1.5: 694650,
    2.5: 627983,
    3.5: 550483,
    4.5: 490066,
  },
  {
    1: 720050,
    2: 665883,
    3: 582550,
    4: 512550,
    5: 455050,
    "revenu brut": 2092000,
    trimf: 1500,
    1.5: 695050,
    2.5: 628383,
    3.5: 550883,
    4.5: 490466,
  },
  {
    1: 720450,
    2: 666283,
    3: 582950,
    4: 512950,
    5: 455450,
    "revenu brut": 2093000,
    trimf: 1500,
    1.5: 695450,
    2.5: 628783,
    3.5: 551283,
    4.5: 490866,
  },
  {
    1: 720850,
    2: 666683,
    3: 583350,
    4: 513350,
    5: 455850,
    "revenu brut": 2094000,
    trimf: 1500,
    1.5: 695850,
    2.5: 629183,
    3.5: 551683,
    4.5: 491266,
  },
  {
    1: 721250,
    2: 667083,
    3: 583750,
    4: 513750,
    5: 456250,
    "revenu brut": 2095000,
    trimf: 1500,
    1.5: 696250,
    2.5: 629583,
    3.5: 552083,
    4.5: 491666,
  },
  {
    1: 721650,
    2: 667483,
    3: 584150,
    4: 514150,
    5: 456650,
    "revenu brut": 2096000,
    trimf: 1500,
    1.5: 696650,
    2.5: 629983,
    3.5: 552483,
    4.5: 492066,
  },
  {
    1: 722050,
    2: 667883,
    3: 584550,
    4: 514550,
    5: 457050,
    "revenu brut": 2097000,
    trimf: 1500,
    1.5: 697050,
    2.5: 630383,
    3.5: 552883,
    4.5: 492466,
  },
  {
    1: 722450,
    2: 668283,
    3: 584950,
    4: 514950,
    5: 457450,
    "revenu brut": 2098000,
    trimf: 1500,
    1.5: 697450,
    2.5: 630783,
    3.5: 553283,
    4.5: 492866,
  },
  {
    1: 722850,
    2: 668683,
    3: 585350,
    4: 515350,
    5: 457850,
    "revenu brut": 2099000,
    trimf: 1500,
    1.5: 697850,
    2.5: 631183,
    3.5: 553683,
    4.5: 493266,
  },
  {
    1: 723250,
    2: 669083,
    3: 585750,
    4: 515750,
    5: 458250,
    "revenu brut": 21_00000,
    trimf: 1500,
    1.5: 698250,
    2.5: 631583,
    3.5: 554083,
    4.5: 493666,
  },
  {
    1: 723650,
    2: 669483,
    3: 586150,
    4: 516150,
    5: 458650,
    "revenu brut": 2101_000,
    trimf: 1500,
    1.5: 698650,
    2.5: 631983,
    3.5: 554483,
    4.5: 494066,
  },
  {
    1: 724050,
    2: 669883,
    3: 586550,
    4: 516550,
    5: 459050,
    "revenu brut": 2102000,
    trimf: 1500,
    1.5: 699050,
    2.5: 632383,
    3.5: 554883,
    4.5: 494466,
  },
  {
    1: 724450,
    2: 670283,
    3: 586950,
    4: 516950,
    5: 459450,
    "revenu brut": 2103000,
    trimf: 1500,
    1.5: 699450,
    2.5: 632783,
    3.5: 555283,
    4.5: 494866,
  },
  {
    1: 724850,
    2: 670683,
    3: 587350,
    4: 517350,
    5: 459850,
    "revenu brut": 2104000,
    trimf: 1500,
    1.5: 699850,
    2.5: 633183,
    3.5: 555683,
    4.5: 495266,
  },
  {
    1: 725250,
    2: 671083,
    3: 587750,
    4: 517750,
    5: 460250,
    "revenu brut": 2105000,
    trimf: 1500,
    1.5: 700250,
    2.5: 633583,
    3.5: 556083,
    4.5: 495666,
  },
  {
    1: 725650,
    2: 671483,
    3: 588150,
    4: 518150,
    5: 460650,
    "revenu brut": 2106000,
    trimf: 1500,
    1.5: 700650,
    2.5: 633983,
    3.5: 556483,
    4.5: 496066,
  },
  {
    1: 726050,
    2: 671883,
    3: 588550,
    4: 518550,
    5: 461050,
    "revenu brut": 2107000,
    trimf: 1500,
    1.5: 701050,
    2.5: 634383,
    3.5: 556883,
    4.5: 496466,
  },
  {
    1: 726450,
    2: 672283,
    3: 588950,
    4: 518950,
    5: 461450,
    "revenu brut": 2108000,
    trimf: 1500,
    1.5: 701450,
    2.5: 634783,
    3.5: 557283,
    4.5: 496866,
  },
  {
    1: 726850,
    2: 672683,
    3: 589350,
    4: 519350,
    5: 461850,
    "revenu brut": 2109000,
    trimf: 1500,
    1.5: 701850,
    2.5: 635183,
    3.5: 557683,
    4.5: 497266,
  },
  {
    1: 727250,
    2: 673083,
    3: 589750,
    4: 519750,
    5: 462250,
    "revenu brut": 211_0000,
    trimf: 1500,
    1.5: 702250,
    2.5: 635583,
    3.5: 558083,
    4.5: 497666,
  },
  {
    1: 727650,
    2: 673483,
    3: 590150,
    4: 520150,
    5: 462650,
    "revenu brut": 2111_000,
    trimf: 1500,
    1.5: 702650,
    2.5: 635983,
    3.5: 558483,
    4.5: 498066,
  },
  {
    1: 728050,
    2: 673883,
    3: 590550,
    4: 520550,
    5: 463050,
    "revenu brut": 2112000,
    trimf: 1500,
    1.5: 703050,
    2.5: 636383,
    3.5: 558883,
    4.5: 498466,
  },
  {
    1: 728450,
    2: 674283,
    3: 590950,
    4: 520950,
    5: 463450,
    "revenu brut": 2113000,
    trimf: 1500,
    1.5: 703450,
    2.5: 636783,
    3.5: 559283,
    4.5: 498866,
  },
  {
    1: 728850,
    2: 674683,
    3: 591350,
    4: 521350,
    5: 463850,
    "revenu brut": 2114000,
    trimf: 1500,
    1.5: 703850,
    2.5: 637183,
    3.5: 559683,
    4.5: 499266,
  },
  {
    1: 729250,
    2: 675083,
    3: 591750,
    4: 521750,
    5: 464250,
    "revenu brut": 2115000,
    trimf: 1500,
    1.5: 704250,
    2.5: 637583,
    3.5: 560083,
    4.5: 499666,
  },
  {
    1: 729650,
    2: 675483,
    3: 592150,
    4: 522150,
    5: 464650,
    "revenu brut": 2116000,
    trimf: 1500,
    1.5: 704650,
    2.5: 637983,
    3.5: 560483,
    4.5: 500066,
  },
  {
    1: 730050,
    2: 675883,
    3: 592550,
    4: 522550,
    5: 465050,
    "revenu brut": 2117000,
    trimf: 1500,
    1.5: 705050,
    2.5: 638383,
    3.5: 560883,
    4.5: 500466,
  },
  {
    1: 730450,
    2: 676283,
    3: 592950,
    4: 522950,
    5: 465450,
    "revenu brut": 2118000,
    trimf: 1500,
    1.5: 705450,
    2.5: 638783,
    3.5: 561283,
    4.5: 500866,
  },
  {
    1: 730850,
    2: 676683,
    3: 593350,
    4: 523350,
    5: 465850,
    "revenu brut": 2119000,
    trimf: 1500,
    1.5: 705850,
    2.5: 639183,
    3.5: 561683,
    4.5: 501266,
  },
  {
    1: 731250,
    2: 677083,
    3: 593750,
    4: 523750,
    5: 466250,
    "revenu brut": 2120000,
    trimf: 1500,
    1.5: 706250,
    2.5: 639583,
    3.5: 562083,
    4.5: 501666,
  },
  {
    1: 731650,
    2: 677483,
    3: 594150,
    4: 524150,
    5: 466650,
    "revenu brut": 2121_000,
    trimf: 1500,
    1.5: 706650,
    2.5: 639983,
    3.5: 562483,
    4.5: 502066,
  },
  {
    1: 732050,
    2: 677883,
    3: 594550,
    4: 524550,
    5: 467050,
    "revenu brut": 2122000,
    trimf: 1500,
    1.5: 707050,
    2.5: 640383,
    3.5: 562883,
    4.5: 502466,
  },
  {
    1: 732450,
    2: 678283,
    3: 594950,
    4: 524950,
    5: 467450,
    "revenu brut": 2123000,
    trimf: 1500,
    1.5: 707450,
    2.5: 640783,
    3.5: 563283,
    4.5: 502866,
  },
  {
    1: 732850,
    2: 678683,
    3: 595350,
    4: 525350,
    5: 467850,
    "revenu brut": 2124000,
    trimf: 1500,
    1.5: 707850,
    2.5: 641183,
    3.5: 563683,
    4.5: 503266,
  },
  {
    1: 733250,
    2: 679083,
    3: 595750,
    4: 525750,
    5: 468250,
    "revenu brut": 2125000,
    trimf: 1500,
    1.5: 708250,
    2.5: 641583,
    3.5: 564083,
    4.5: 503666,
  },
  {
    1: 733650,
    2: 679483,
    3: 596150,
    4: 526150,
    5: 468650,
    "revenu brut": 2126000,
    trimf: 1500,
    1.5: 708650,
    2.5: 641983,
    3.5: 564483,
    4.5: 504066,
  },
  {
    1: 734050,
    2: 679883,
    3: 596550,
    4: 526550,
    5: 469050,
    "revenu brut": 2127000,
    trimf: 1500,
    1.5: 709050,
    2.5: 642383,
    3.5: 564883,
    4.5: 504466,
  },
  {
    1: 734450,
    2: 680283,
    3: 596950,
    4: 526950,
    5: 469450,
    "revenu brut": 2128000,
    trimf: 1500,
    1.5: 709450,
    2.5: 642783,
    3.5: 565283,
    4.5: 504866,
  },
  {
    1: 734850,
    2: 680683,
    3: 597350,
    4: 527350,
    5: 469850,
    "revenu brut": 2129000,
    trimf: 1500,
    1.5: 709850,
    2.5: 643183,
    3.5: 565683,
    4.5: 505266,
  },
  {
    1: 735250,
    2: 681083,
    3: 597750,
    4: 527750,
    5: 470250,
    "revenu brut": 2130000,
    trimf: 1500,
    1.5: 710250,
    2.5: 643583,
    3.5: 566083,
    4.5: 505666,
  },
  {
    1: 735650,
    2: 681483,
    3: 598150,
    4: 528150,
    5: 470650,
    "revenu brut": 2131_000,
    trimf: 1500,
    1.5: 710650,
    2.5: 643983,
    3.5: 566483,
    4.5: 506066,
  },
  {
    1: 736050,
    2: 681883,
    3: 598550,
    4: 528550,
    5: 471050,
    "revenu brut": 2132000,
    trimf: 1500,
    1.5: 711050,
    2.5: 644383,
    3.5: 566883,
    4.5: 506466,
  },
  {
    1: 736450,
    2: 682283,
    3: 598950,
    4: 528950,
    5: 471450,
    "revenu brut": 2133000,
    trimf: 1500,
    1.5: 711450,
    2.5: 644783,
    3.5: 567283,
    4.5: 506866,
  },
  {
    1: 736850,
    2: 682683,
    3: 599350,
    4: 529350,
    5: 471850,
    "revenu brut": 2134000,
    trimf: 1500,
    1.5: 711850,
    2.5: 645183,
    3.5: 567683,
    4.5: 507266,
  },
  {
    1: 737250,
    2: 683083,
    3: 599750,
    4: 529750,
    5: 472250,
    "revenu brut": 2135000,
    trimf: 1500,
    1.5: 712250,
    2.5: 645583,
    3.5: 568083,
    4.5: 507666,
  },
  {
    1: 737650,
    2: 683483,
    3: 600150,
    4: 530150,
    5: 472650,
    "revenu brut": 2136000,
    trimf: 1500,
    1.5: 712650,
    2.5: 645983,
    3.5: 568483,
    4.5: 508066,
  },
  {
    1: 738050,
    2: 683883,
    3: 600550,
    4: 530550,
    5: 473050,
    "revenu brut": 2137000,
    trimf: 1500,
    1.5: 713050,
    2.5: 646383,
    3.5: 568883,
    4.5: 508466,
  },
  {
    1: 738450,
    2: 684283,
    3: 600950,
    4: 530950,
    5: 473450,
    "revenu brut": 2138000,
    trimf: 1500,
    1.5: 713450,
    2.5: 646783,
    3.5: 569283,
    4.5: 508866,
  },
  {
    1: 738850,
    2: 684683,
    3: 601350,
    4: 531350,
    5: 473850,
    "revenu brut": 2139000,
    trimf: 1500,
    1.5: 713850,
    2.5: 647183,
    3.5: 569683,
    4.5: 509266,
  },
  {
    1: 739250,
    2: 685083,
    3: 601750,
    4: 531750,
    5: 474250,
    "revenu brut": 2140000,
    trimf: 1500,
    1.5: 714250,
    2.5: 647583,
    3.5: 570083,
    4.5: 509666,
  },
  {
    1: 739650,
    2: 685483,
    3: 602150,
    4: 532150,
    5: 474650,
    "revenu brut": 2141_000,
    trimf: 1500,
    1.5: 714650,
    2.5: 647983,
    3.5: 570483,
    4.5: 510066,
  },
  {
    1: 740050,
    2: 685883,
    3: 602550,
    4: 532550,
    5: 475050,
    "revenu brut": 2142000,
    trimf: 1500,
    1.5: 715050,
    2.5: 648383,
    3.5: 570883,
    4.5: 510466,
  },
  {
    1: 740450,
    2: 686283,
    3: 602950,
    4: 532950,
    5: 475450,
    "revenu brut": 2143000,
    trimf: 1500,
    1.5: 715450,
    2.5: 648783,
    3.5: 571283,
    4.5: 510866,
  },
  {
    1: 740850,
    2: 686683,
    3: 603350,
    4: 533350,
    5: 475850,
    "revenu brut": 2144000,
    trimf: 1500,
    1.5: 715850,
    2.5: 649183,
    3.5: 571683,
    4.5: 511266,
  },
  {
    1: 741250,
    2: 687083,
    3: 603750,
    4: 533750,
    5: 476250,
    "revenu brut": 2145000,
    trimf: 1500,
    1.5: 716250,
    2.5: 649583,
    3.5: 572083,
    4.5: 511666,
  },
  {
    1: 741650,
    2: 687483,
    3: 604150,
    4: 534150,
    5: 476650,
    "revenu brut": 2146000,
    trimf: 1500,
    1.5: 716650,
    2.5: 649983,
    3.5: 572483,
    4.5: 512066,
  },
  {
    1: 742050,
    2: 687883,
    3: 604550,
    4: 534550,
    5: 477050,
    "revenu brut": 2147000,
    trimf: 1500,
    1.5: 717050,
    2.5: 650383,
    3.5: 572883,
    4.5: 512466,
  },
  {
    1: 742450,
    2: 688283,
    3: 604950,
    4: 534950,
    5: 477450,
    "revenu brut": 2148000,
    trimf: 1500,
    1.5: 717450,
    2.5: 650783,
    3.5: 573283,
    4.5: 512866,
  },
  {
    1: 742850,
    2: 688683,
    3: 605350,
    4: 535350,
    5: 477850,
    "revenu brut": 2149000,
    trimf: 1500,
    1.5: 717850,
    2.5: 651183,
    3.5: 573683,
    4.5: 513266,
  },
  {
    1: 743250,
    2: 689083,
    3: 605750,
    4: 535750,
    5: 478250,
    "revenu brut": 2150000,
    trimf: 1500,
    1.5: 718250,
    2.5: 651583,
    3.5: 574083,
    4.5: 513666,
  },
  {
    1: 743650,
    2: 689483,
    3: 606150,
    4: 536150,
    5: 478650,
    "revenu brut": 2151_000,
    trimf: 1500,
    1.5: 718650,
    2.5: 651983,
    3.5: 574483,
    4.5: 514066,
  },
  {
    1: 744050,
    2: 689883,
    3: 606550,
    4: 536550,
    5: 479050,
    "revenu brut": 2152000,
    trimf: 1500,
    1.5: 719050,
    2.5: 652383,
    3.5: 574883,
    4.5: 514466,
  },
  {
    1: 744450,
    2: 690283,
    3: 606950,
    4: 536950,
    5: 479450,
    "revenu brut": 2153000,
    trimf: 1500,
    1.5: 719450,
    2.5: 652783,
    3.5: 575283,
    4.5: 514866,
  },
  {
    1: 744850,
    2: 690683,
    3: 607350,
    4: 537350,
    5: 479850,
    "revenu brut": 2154000,
    trimf: 1500,
    1.5: 719850,
    2.5: 653183,
    3.5: 575683,
    4.5: 515266,
  },
  {
    1: 745250,
    2: 691083,
    3: 607750,
    4: 537750,
    5: 480250,
    "revenu brut": 2155000,
    trimf: 1500,
    1.5: 720250,
    2.5: 653583,
    3.5: 576083,
    4.5: 515666,
  },
  {
    1: 745650,
    2: 691483,
    3: 608150,
    4: 538150,
    5: 480650,
    "revenu brut": 2156000,
    trimf: 1500,
    1.5: 720650,
    2.5: 653983,
    3.5: 576483,
    4.5: 516066,
  },
  {
    1: 746050,
    2: 691883,
    3: 608550,
    4: 538550,
    5: 481050,
    "revenu brut": 2157000,
    trimf: 1500,
    1.5: 721050,
    2.5: 654383,
    3.5: 576883,
    4.5: 516466,
  },
  {
    1: 746450,
    2: 692283,
    3: 608950,
    4: 538950,
    5: 481450,
    "revenu brut": 2158000,
    trimf: 1500,
    1.5: 721450,
    2.5: 654783,
    3.5: 577283,
    4.5: 516866,
  },
  {
    1: 746850,
    2: 692683,
    3: 609350,
    4: 539350,
    5: 481850,
    "revenu brut": 2159000,
    trimf: 1500,
    1.5: 721850,
    2.5: 655183,
    3.5: 577683,
    4.5: 517266,
  },
  {
    1: 747250,
    2: 693083,
    3: 609750,
    4: 539750,
    5: 482250,
    "revenu brut": 2160000,
    trimf: 1500,
    1.5: 722250,
    2.5: 655583,
    3.5: 578083,
    4.5: 517666,
  },
  {
    1: 747650,
    2: 693483,
    3: 610150,
    4: 540150,
    5: 482650,
    "revenu brut": 2161_000,
    trimf: 1500,
    1.5: 722650,
    2.5: 655983,
    3.5: 578483,
    4.5: 518066,
  },
  {
    1: 748050,
    2: 693883,
    3: 610550,
    4: 540550,
    5: 483050,
    "revenu brut": 2162000,
    trimf: 1500,
    1.5: 723050,
    2.5: 656383,
    3.5: 578883,
    4.5: 518466,
  },
  {
    1: 748450,
    2: 694283,
    3: 610950,
    4: 540950,
    5: 483450,
    "revenu brut": 2163000,
    trimf: 1500,
    1.5: 723450,
    2.5: 656783,
    3.5: 579283,
    4.5: 518866,
  },
  {
    1: 748850,
    2: 694683,
    3: 611350,
    4: 541350,
    5: 483850,
    "revenu brut": 2164000,
    trimf: 1500,
    1.5: 723850,
    2.5: 657183,
    3.5: 579683,
    4.5: 519266,
  },
  {
    1: 749250,
    2: 695083,
    3: 611750,
    4: 541750,
    5: 484250,
    "revenu brut": 2165000,
    trimf: 1500,
    1.5: 724250,
    2.5: 657583,
    3.5: 580083,
    4.5: 519666,
  },
  {
    1: 749650,
    2: 695483,
    3: 612150,
    4: 542150,
    5: 484650,
    "revenu brut": 2166000,
    trimf: 1500,
    1.5: 724650,
    2.5: 657983,
    3.5: 580483,
    4.5: 520066,
  },
  {
    1: 750050,
    2: 695883,
    3: 612550,
    4: 542550,
    5: 485050,
    "revenu brut": 2167000,
    trimf: 1500,
    1.5: 725050,
    2.5: 658383,
    3.5: 580883,
    4.5: 520466,
  },
  {
    1: 750450,
    2: 696283,
    3: 612950,
    4: 542950,
    5: 485450,
    "revenu brut": 2168000,
    trimf: 1500,
    1.5: 725450,
    2.5: 658783,
    3.5: 581283,
    4.5: 520866,
  },
  {
    1: 750850,
    2: 696683,
    3: 613350,
    4: 543350,
    5: 485850,
    "revenu brut": 2169000,
    trimf: 1500,
    1.5: 725850,
    2.5: 659183,
    3.5: 581683,
    4.5: 521266,
  },
  {
    1: 751250,
    2: 697083,
    3: 613750,
    4: 543750,
    5: 486250,
    "revenu brut": 2170000,
    trimf: 1500,
    1.5: 726250,
    2.5: 659583,
    3.5: 582083,
    4.5: 521666,
  },
  {
    1: 751650,
    2: 697483,
    3: 614150,
    4: 544150,
    5: 486650,
    "revenu brut": 2171_000,
    trimf: 1500,
    1.5: 726650,
    2.5: 659983,
    3.5: 582483,
    4.5: 522066,
  },
  {
    1: 752050,
    2: 697883,
    3: 614550,
    4: 544550,
    5: 487050,
    "revenu brut": 2172000,
    trimf: 1500,
    1.5: 727050,
    2.5: 660383,
    3.5: 582883,
    4.5: 522466,
  },
  {
    1: 752450,
    2: 698283,
    3: 614950,
    4: 544950,
    5: 487450,
    "revenu brut": 2173000,
    trimf: 1500,
    1.5: 727450,
    2.5: 660783,
    3.5: 583283,
    4.5: 522866,
  },
  {
    1: 752850,
    2: 698683,
    3: 615350,
    4: 545350,
    5: 487850,
    "revenu brut": 2174000,
    trimf: 1500,
    1.5: 727850,
    2.5: 661183,
    3.5: 583683,
    4.5: 523266,
  },
  {
    1: 753250,
    2: 699083,
    3: 615750,
    4: 545750,
    5: 488250,
    "revenu brut": 2175000,
    trimf: 1500,
    1.5: 728250,
    2.5: 661583,
    3.5: 584083,
    4.5: 523666,
  },
  {
    1: 753650,
    2: 699483,
    3: 616150,
    4: 546150,
    5: 488650,
    "revenu brut": 2176000,
    trimf: 1500,
    1.5: 728650,
    2.5: 661983,
    3.5: 584483,
    4.5: 524066,
  },
  {
    1: 754050,
    2: 699883,
    3: 616550,
    4: 546550,
    5: 489050,
    "revenu brut": 2177000,
    trimf: 1500,
    1.5: 729050,
    2.5: 662383,
    3.5: 584883,
    4.5: 524466,
  },
  {
    1: 754450,
    2: 700283,
    3: 616950,
    4: 546950,
    5: 489450,
    "revenu brut": 2178000,
    trimf: 1500,
    1.5: 729450,
    2.5: 662783,
    3.5: 585283,
    4.5: 524866,
  },
  {
    1: 754850,
    2: 700683,
    3: 617350,
    4: 547350,
    5: 489850,
    "revenu brut": 2179000,
    trimf: 1500,
    1.5: 729850,
    2.5: 663183,
    3.5: 585683,
    4.5: 525266,
  },
  {
    1: 755250,
    2: 701083,
    3: 617750,
    4: 547750,
    5: 490250,
    "revenu brut": 2180000,
    trimf: 1500,
    1.5: 730250,
    2.5: 663583,
    3.5: 586083,
    4.5: 525666,
  },
  {
    1: 755650,
    2: 701483,
    3: 618150,
    4: 548150,
    5: 490650,
    "revenu brut": 2181_000,
    trimf: 1500,
    1.5: 730650,
    2.5: 663983,
    3.5: 586483,
    4.5: 526066,
  },
  {
    1: 756050,
    2: 701883,
    3: 618550,
    4: 548550,
    5: 491050,
    "revenu brut": 2182000,
    trimf: 1500,
    1.5: 731050,
    2.5: 664383,
    3.5: 586883,
    4.5: 526466,
  },
  {
    1: 756450,
    2: 702283,
    3: 618950,
    4: 548950,
    5: 491450,
    "revenu brut": 2183000,
    trimf: 1500,
    1.5: 731450,
    2.5: 664783,
    3.5: 587283,
    4.5: 526866,
  },
  {
    1: 756850,
    2: 702683,
    3: 619350,
    4: 549350,
    5: 491850,
    "revenu brut": 2184000,
    trimf: 1500,
    1.5: 731850,
    2.5: 665183,
    3.5: 587683,
    4.5: 527266,
  },
  {
    1: 757250,
    2: 703083,
    3: 619750,
    4: 549750,
    5: 492250,
    "revenu brut": 2185000,
    trimf: 1500,
    1.5: 732250,
    2.5: 665583,
    3.5: 588083,
    4.5: 527666,
  },
  {
    1: 757650,
    2: 703483,
    3: 620150,
    4: 550150,
    5: 492650,
    "revenu brut": 2186000,
    trimf: 1500,
    1.5: 732650,
    2.5: 665983,
    3.5: 588483,
    4.5: 528066,
  },
  {
    1: 758050,
    2: 703883,
    3: 620550,
    4: 550550,
    5: 493050,
    "revenu brut": 2187000,
    trimf: 1500,
    1.5: 733050,
    2.5: 666383,
    3.5: 588883,
    4.5: 528466,
  },
  {
    1: 758450,
    2: 704283,
    3: 620950,
    4: 550950,
    5: 493450,
    "revenu brut": 2188000,
    trimf: 1500,
    1.5: 733450,
    2.5: 666783,
    3.5: 589283,
    4.5: 528866,
  },
  {
    1: 758850,
    2: 704683,
    3: 621350,
    4: 551350,
    5: 493850,
    "revenu brut": 2189000,
    trimf: 1500,
    1.5: 733850,
    2.5: 667183,
    3.5: 589683,
    4.5: 529266,
  },
  {
    1: 759250,
    2: 705083,
    3: 621750,
    4: 551750,
    5: 494250,
    "revenu brut": 2190000,
    trimf: 1500,
    1.5: 734250,
    2.5: 667583,
    3.5: 590083,
    4.5: 529666,
  },
  {
    1: 759650,
    2: 705483,
    3: 622150,
    4: 552150,
    5: 494650,
    "revenu brut": 2191_000,
    trimf: 1500,
    1.5: 734650,
    2.5: 667983,
    3.5: 590483,
    4.5: 530066,
  },
  {
    1: 760050,
    2: 705883,
    3: 622550,
    4: 552550,
    5: 495050,
    "revenu brut": 2192000,
    trimf: 1500,
    1.5: 735050,
    2.5: 668383,
    3.5: 590883,
    4.5: 530466,
  },
  {
    1: 760450,
    2: 706283,
    3: 622950,
    4: 552950,
    5: 495450,
    "revenu brut": 2193000,
    trimf: 1500,
    1.5: 735450,
    2.5: 668783,
    3.5: 591283,
    4.5: 530866,
  },
  {
    1: 760850,
    2: 706683,
    3: 623350,
    4: 553350,
    5: 495850,
    "revenu brut": 2194000,
    trimf: 1500,
    1.5: 735850,
    2.5: 669183,
    3.5: 591683,
    4.5: 531266,
  },
  {
    1: 761250,
    2: 707083,
    3: 623750,
    4: 553750,
    5: 496250,
    "revenu brut": 2195000,
    trimf: 1500,
    1.5: 736250,
    2.5: 669583,
    3.5: 592083,
    4.5: 531666,
  },
  {
    1: 761650,
    2: 707483,
    3: 624150,
    4: 554150,
    5: 496650,
    "revenu brut": 2196000,
    trimf: 1500,
    1.5: 736650,
    2.5: 669983,
    3.5: 592483,
    4.5: 532066,
  },
  {
    1: 762050,
    2: 707883,
    3: 624550,
    4: 554550,
    5: 497050,
    "revenu brut": 2197000,
    trimf: 1500,
    1.5: 737050,
    2.5: 670383,
    3.5: 592883,
    4.5: 532466,
  },
  {
    1: 762450,
    2: 708283,
    3: 624950,
    4: 554950,
    5: 497450,
    "revenu brut": 2198000,
    trimf: 1500,
    1.5: 737450,
    2.5: 670783,
    3.5: 593283,
    4.5: 532866,
  },
  {
    1: 762850,
    2: 708683,
    3: 625350,
    4: 555350,
    5: 497850,
    "revenu brut": 2199000,
    trimf: 1500,
    1.5: 737850,
    2.5: 671183,
    3.5: 593683,
    4.5: 533266,
  },
  {
    1: 763250,
    2: 709083,
    3: 625750,
    4: 555750,
    5: 498250,
    "revenu brut": 2200000,
    trimf: 1500,
    1.5: 738250,
    2.5: 671583,
    3.5: 594083,
    4.5: 533666,
  },
  {
    1: 763650,
    2: 709483,
    3: 626150,
    4: 556150,
    5: 498650,
    "revenu brut": 2201_000,
    trimf: 1500,
    1.5: 738650,
    2.5: 671983,
    3.5: 594483,
    4.5: 534066,
  },
  {
    1: 764050,
    2: 709883,
    3: 626550,
    4: 556550,
    5: 499050,
    "revenu brut": 2202000,
    trimf: 1500,
    1.5: 739050,
    2.5: 672383,
    3.5: 594883,
    4.5: 534466,
  },
  {
    1: 764450,
    2: 710283,
    3: 626950,
    4: 556950,
    5: 499450,
    "revenu brut": 2203000,
    trimf: 1500,
    1.5: 739450,
    2.5: 672783,
    3.5: 595283,
    4.5: 534866,
  },
  {
    1: 764850,
    2: 710683,
    3: 627350,
    4: 557350,
    5: 499850,
    "revenu brut": 2204000,
    trimf: 1500,
    1.5: 739850,
    2.5: 673183,
    3.5: 595683,
    4.5: 535266,
  },
  {
    1: 765250,
    2: 711083,
    3: 627750,
    4: 557750,
    5: 500250,
    "revenu brut": 2205000,
    trimf: 1500,
    1.5: 740250,
    2.5: 673583,
    3.5: 596083,
    4.5: 535666,
  },
  {
    1: 765650,
    2: 711483,
    3: 628150,
    4: 558150,
    5: 500650,
    "revenu brut": 2206000,
    trimf: 1500,
    1.5: 740650,
    2.5: 673983,
    3.5: 596483,
    4.5: 536066,
  },
  {
    1: 766050,
    2: 711883,
    3: 628550,
    4: 558550,
    5: 501050,
    "revenu brut": 2207000,
    trimf: 1500,
    1.5: 741050,
    2.5: 674383,
    3.5: 596883,
    4.5: 536466,
  },
  {
    1: 766450,
    2: 712283,
    3: 628950,
    4: 558950,
    5: 501450,
    "revenu brut": 2208000,
    trimf: 1500,
    1.5: 741450,
    2.5: 674783,
    3.5: 597283,
    4.5: 536866,
  },
  {
    1: 766850,
    2: 712683,
    3: 629350,
    4: 559350,
    5: 501850,
    "revenu brut": 2209000,
    trimf: 1500,
    1.5: 741850,
    2.5: 675183,
    3.5: 597683,
    4.5: 537266,
  },
  {
    1: 767250,
    2: 713083,
    3: 629750,
    4: 559750,
    5: 502250,
    "revenu brut": 221_0000,
    trimf: 1500,
    1.5: 742250,
    2.5: 675583,
    3.5: 598083,
    4.5: 537666,
  },
  {
    1: 767650,
    2: 713483,
    3: 630150,
    4: 560150,
    5: 502650,
    "revenu brut": 2211_000,
    trimf: 1500,
    1.5: 742650,
    2.5: 675983,
    3.5: 598483,
    4.5: 538066,
  },
  {
    1: 768050,
    2: 713883,
    3: 630550,
    4: 560550,
    5: 503050,
    "revenu brut": 2212000,
    trimf: 1500,
    1.5: 743050,
    2.5: 676383,
    3.5: 598883,
    4.5: 538466,
  },
  {
    1: 768450,
    2: 714283,
    3: 630950,
    4: 560950,
    5: 503450,
    "revenu brut": 2213000,
    trimf: 1500,
    1.5: 743450,
    2.5: 676783,
    3.5: 599283,
    4.5: 538866,
  },
  {
    1: 768850,
    2: 714683,
    3: 631350,
    4: 561350,
    5: 503850,
    "revenu brut": 2214000,
    trimf: 1500,
    1.5: 743850,
    2.5: 677183,
    3.5: 599683,
    4.5: 539266,
  },
  {
    1: 769250,
    2: 715083,
    3: 631750,
    4: 561750,
    5: 504250,
    "revenu brut": 2215000,
    trimf: 1500,
    1.5: 744250,
    2.5: 677583,
    3.5: 600083,
    4.5: 539666,
  },
  {
    1: 769650,
    2: 715483,
    3: 632150,
    4: 562150,
    5: 504650,
    "revenu brut": 2216000,
    trimf: 1500,
    1.5: 744650,
    2.5: 677983,
    3.5: 600483,
    4.5: 540066,
  },
  {
    1: 770050,
    2: 715883,
    3: 632550,
    4: 562550,
    5: 505050,
    "revenu brut": 2217000,
    trimf: 1500,
    1.5: 745050,
    2.5: 678383,
    3.5: 600883,
    4.5: 540466,
  },
  {
    1: 770450,
    2: 716283,
    3: 632950,
    4: 562950,
    5: 505450,
    "revenu brut": 2218000,
    trimf: 1500,
    1.5: 745450,
    2.5: 678783,
    3.5: 601283,
    4.5: 540866,
  },
  {
    1: 770850,
    2: 716683,
    3: 633350,
    4: 563350,
    5: 505850,
    "revenu brut": 2219000,
    trimf: 1500,
    1.5: 745850,
    2.5: 679183,
    3.5: 601683,
    4.5: 541266,
  },
  {
    1: 771250,
    2: 717083,
    3: 633750,
    4: 563750,
    5: 506250,
    "revenu brut": 2220000,
    trimf: 1500,
    1.5: 746250,
    2.5: 679583,
    3.5: 602083,
    4.5: 541666,
  },
  {
    1: 771650,
    2: 717483,
    3: 634150,
    4: 564150,
    5: 506650,
    "revenu brut": 2221_000,
    trimf: 1500,
    1.5: 746650,
    2.5: 679983,
    3.5: 602483,
    4.5: 542066,
  },
  {
    1: 772050,
    2: 717883,
    3: 634550,
    4: 564550,
    5: 507050,
    "revenu brut": 2222000,
    trimf: 1500,
    1.5: 747050,
    2.5: 680383,
    3.5: 602883,
    4.5: 542466,
  },
  {
    1: 772450,
    2: 718283,
    3: 634950,
    4: 564950,
    5: 507450,
    "revenu brut": 2223000,
    trimf: 1500,
    1.5: 747450,
    2.5: 680783,
    3.5: 603283,
    4.5: 542866,
  },
  {
    1: 772850,
    2: 718683,
    3: 635350,
    4: 565350,
    5: 507850,
    "revenu brut": 2224000,
    trimf: 1500,
    1.5: 747850,
    2.5: 681183,
    3.5: 603683,
    4.5: 543266,
  },
  {
    1: 773250,
    2: 719083,
    3: 635750,
    4: 565750,
    5: 508250,
    "revenu brut": 2225000,
    trimf: 1500,
    1.5: 748250,
    2.5: 681583,
    3.5: 604083,
    4.5: 543666,
  },
  {
    1: 773650,
    2: 719483,
    3: 636150,
    4: 566150,
    5: 508650,
    "revenu brut": 2226000,
    trimf: 1500,
    1.5: 748650,
    2.5: 681983,
    3.5: 604483,
    4.5: 544066,
  },
  {
    1: 774050,
    2: 719883,
    3: 636550,
    4: 566550,
    5: 509050,
    "revenu brut": 2227000,
    trimf: 1500,
    1.5: 749050,
    2.5: 682383,
    3.5: 604883,
    4.5: 544466,
  },
  {
    1: 774450,
    2: 720283,
    3: 636950,
    4: 566950,
    5: 509450,
    "revenu brut": 2228000,
    trimf: 1500,
    1.5: 749450,
    2.5: 682783,
    3.5: 605283,
    4.5: 544866,
  },
  {
    1: 774850,
    2: 720683,
    3: 637350,
    4: 567350,
    5: 509850,
    "revenu brut": 2229000,
    trimf: 1500,
    1.5: 749850,
    2.5: 683183,
    3.5: 605683,
    4.5: 545266,
  },
  {
    1: 775250,
    2: 721083,
    3: 637750,
    4: 567750,
    5: 510250,
    "revenu brut": 2230000,
    trimf: 1500,
    1.5: 750250,
    2.5: 683583,
    3.5: 606083,
    4.5: 545666,
  },
  {
    1: 775650,
    2: 721483,
    3: 638150,
    4: 568150,
    5: 510650,
    "revenu brut": 2231_000,
    trimf: 1500,
    1.5: 750650,
    2.5: 683983,
    3.5: 606483,
    4.5: 546066,
  },
  {
    1: 776050,
    2: 721883,
    3: 638550,
    4: 568550,
    5: 511050,
    "revenu brut": 2232000,
    trimf: 1500,
    1.5: 751050,
    2.5: 684383,
    3.5: 606883,
    4.5: 546466,
  },
  {
    1: 776450,
    2: 722283,
    3: 638950,
    4: 568950,
    5: 511450,
    "revenu brut": 2233000,
    trimf: 1500,
    1.5: 751450,
    2.5: 684783,
    3.5: 607283,
    4.5: 546866,
  },
  {
    1: 776850,
    2: 722683,
    3: 639350,
    4: 569350,
    5: 511850,
    "revenu brut": 2234000,
    trimf: 1500,
    1.5: 751850,
    2.5: 685183,
    3.5: 607683,
    4.5: 547266,
  },
  {
    1: 777250,
    2: 723083,
    3: 639750,
    4: 569750,
    5: 512250,
    "revenu brut": 2235000,
    trimf: 1500,
    1.5: 752250,
    2.5: 685583,
    3.5: 608083,
    4.5: 547666,
  },
  {
    1: 777650,
    2: 723483,
    3: 640150,
    4: 570150,
    5: 512650,
    "revenu brut": 2236000,
    trimf: 1500,
    1.5: 752650,
    2.5: 685983,
    3.5: 608483,
    4.5: 548066,
  },
  {
    1: 778050,
    2: 723883,
    3: 640550,
    4: 570550,
    5: 513050,
    "revenu brut": 2237000,
    trimf: 1500,
    1.5: 753050,
    2.5: 686383,
    3.5: 608883,
    4.5: 548466,
  },
  {
    1: 778450,
    2: 724283,
    3: 640950,
    4: 570950,
    5: 513450,
    "revenu brut": 2238000,
    trimf: 1500,
    1.5: 753450,
    2.5: 686783,
    3.5: 609283,
    4.5: 548866,
  },
  {
    1: 778850,
    2: 724683,
    3: 641350,
    4: 571350,
    5: 513850,
    "revenu brut": 2239000,
    trimf: 1500,
    1.5: 753850,
    2.5: 687183,
    3.5: 609683,
    4.5: 549266,
  },
  {
    1: 779250,
    2: 725083,
    3: 641750,
    4: 571750,
    5: 514250,
    "revenu brut": 2240000,
    trimf: 1500,
    1.5: 754250,
    2.5: 687583,
    3.5: 610083,
    4.5: 549666,
  },
  {
    1: 779650,
    2: 725483,
    3: 642150,
    4: 572150,
    5: 514650,
    "revenu brut": 2241_000,
    trimf: 1500,
    1.5: 754650,
    2.5: 687983,
    3.5: 610483,
    4.5: 550066,
  },
  {
    1: 780050,
    2: 725883,
    3: 642550,
    4: 572550,
    5: 515050,
    "revenu brut": 2242000,
    trimf: 1500,
    1.5: 755050,
    2.5: 688383,
    3.5: 610883,
    4.5: 550466,
  },
  {
    1: 780450,
    2: 726283,
    3: 642950,
    4: 572950,
    5: 515450,
    "revenu brut": 2243000,
    trimf: 1500,
    1.5: 755450,
    2.5: 688783,
    3.5: 611283,
    4.5: 550866,
  },
  {
    1: 780850,
    2: 726683,
    3: 643350,
    4: 573350,
    5: 515850,
    "revenu brut": 2244000,
    trimf: 1500,
    1.5: 755850,
    2.5: 689183,
    3.5: 611683,
    4.5: 551266,
  },
  {
    1: 781250,
    2: 727083,
    3: 643750,
    4: 573750,
    5: 516250,
    "revenu brut": 2245000,
    trimf: 1500,
    1.5: 756250,
    2.5: 689583,
    3.5: 612083,
    4.5: 551666,
  },
  {
    1: 781650,
    2: 727483,
    3: 644150,
    4: 574150,
    5: 516650,
    "revenu brut": 2246000,
    trimf: 1500,
    1.5: 756650,
    2.5: 689983,
    3.5: 612483,
    4.5: 552066,
  },
  {
    1: 782050,
    2: 727883,
    3: 644550,
    4: 574550,
    5: 517050,
    "revenu brut": 2247000,
    trimf: 1500,
    1.5: 757050,
    2.5: 690383,
    3.5: 612883,
    4.5: 552466,
  },
  {
    1: 782450,
    2: 728283,
    3: 644950,
    4: 574950,
    5: 517450,
    "revenu brut": 2248000,
    trimf: 1500,
    1.5: 757450,
    2.5: 690783,
    3.5: 613283,
    4.5: 552866,
  },
  {
    1: 782850,
    2: 728683,
    3: 645350,
    4: 575350,
    5: 517850,
    "revenu brut": 2249000,
    trimf: 1500,
    1.5: 757850,
    2.5: 691183,
    3.5: 613683,
    4.5: 553266,
  },
  {
    1: 783250,
    2: 729083,
    3: 645750,
    4: 575750,
    5: 518250,
    "revenu brut": 2250000,
    trimf: 1500,
    1.5: 758250,
    2.5: 691583,
    3.5: 614083,
    4.5: 553666,
  },
  {
    1: 783650,
    2: 729483,
    3: 646150,
    4: 576150,
    5: 518650,
    "revenu brut": 2251_000,
    trimf: 1500,
    1.5: 758650,
    2.5: 691983,
    3.5: 614483,
    4.5: 554066,
  },
  {
    1: 784050,
    2: 729883,
    3: 646550,
    4: 576550,
    5: 519050,
    "revenu brut": 2252000,
    trimf: 1500,
    1.5: 759050,
    2.5: 692383,
    3.5: 614883,
    4.5: 554466,
  },
  {
    1: 784450,
    2: 730283,
    3: 646950,
    4: 576950,
    5: 519450,
    "revenu brut": 2253000,
    trimf: 1500,
    1.5: 759450,
    2.5: 692783,
    3.5: 615283,
    4.5: 554866,
  },
  {
    1: 784850,
    2: 730683,
    3: 647350,
    4: 577350,
    5: 519850,
    "revenu brut": 2254000,
    trimf: 1500,
    1.5: 759850,
    2.5: 693183,
    3.5: 615683,
    4.5: 555266,
  },
  {
    1: 785250,
    2: 731083,
    3: 647750,
    4: 577750,
    5: 520250,
    "revenu brut": 2255000,
    trimf: 1500,
    1.5: 760250,
    2.5: 693583,
    3.5: 616083,
    4.5: 555666,
  },
  {
    1: 785650,
    2: 731483,
    3: 648150,
    4: 578150,
    5: 520650,
    "revenu brut": 2256000,
    trimf: 1500,
    1.5: 760650,
    2.5: 693983,
    3.5: 616483,
    4.5: 556066,
  },
  {
    1: 786050,
    2: 731883,
    3: 648550,
    4: 578550,
    5: 521050,
    "revenu brut": 2257000,
    trimf: 1500,
    1.5: 761050,
    2.5: 694383,
    3.5: 616883,
    4.5: 556466,
  },
  {
    1: 786450,
    2: 732283,
    3: 648950,
    4: 578950,
    5: 521450,
    "revenu brut": 2258000,
    trimf: 1500,
    1.5: 761450,
    2.5: 694783,
    3.5: 617283,
    4.5: 556866,
  },
  {
    1: 786850,
    2: 732683,
    3: 649350,
    4: 579350,
    5: 521850,
    "revenu brut": 2259000,
    trimf: 1500,
    1.5: 761850,
    2.5: 695183,
    3.5: 617683,
    4.5: 557266,
  },
  {
    1: 787250,
    2: 733083,
    3: 649750,
    4: 579750,
    5: 522250,
    "revenu brut": 2260000,
    trimf: 1500,
    1.5: 762250,
    2.5: 695583,
    3.5: 618083,
    4.5: 557666,
  },
  {
    1: 787650,
    2: 733483,
    3: 650150,
    4: 580150,
    5: 522650,
    "revenu brut": 2261_000,
    trimf: 1500,
    1.5: 762650,
    2.5: 695983,
    3.5: 618483,
    4.5: 558066,
  },
  {
    1: 788050,
    2: 733883,
    3: 650550,
    4: 580550,
    5: 523050,
    "revenu brut": 2262000,
    trimf: 1500,
    1.5: 763050,
    2.5: 696383,
    3.5: 618883,
    4.5: 558466,
  },
  {
    1: 788450,
    2: 734283,
    3: 650950,
    4: 580950,
    5: 523450,
    "revenu brut": 2263000,
    trimf: 1500,
    1.5: 763450,
    2.5: 696783,
    3.5: 619283,
    4.5: 558866,
  },
  {
    1: 788850,
    2: 734683,
    3: 651350,
    4: 581350,
    5: 523850,
    "revenu brut": 2264000,
    trimf: 1500,
    1.5: 763850,
    2.5: 697183,
    3.5: 619683,
    4.5: 559266,
  },
  {
    1: 789250,
    2: 735083,
    3: 651750,
    4: 581750,
    5: 524250,
    "revenu brut": 2265000,
    trimf: 1500,
    1.5: 764250,
    2.5: 697583,
    3.5: 620083,
    4.5: 559666,
  },
  {
    1: 789650,
    2: 735483,
    3: 652150,
    4: 582150,
    5: 524650,
    "revenu brut": 2266000,
    trimf: 1500,
    1.5: 764650,
    2.5: 697983,
    3.5: 620483,
    4.5: 560066,
  },
  {
    1: 790050,
    2: 735883,
    3: 652550,
    4: 582550,
    5: 525050,
    "revenu brut": 2267000,
    trimf: 1500,
    1.5: 765050,
    2.5: 698383,
    3.5: 620883,
    4.5: 560466,
  },
  {
    1: 790450,
    2: 736283,
    3: 652950,
    4: 582950,
    5: 525450,
    "revenu brut": 2268000,
    trimf: 1500,
    1.5: 765450,
    2.5: 698783,
    3.5: 621283,
    4.5: 560866,
  },
  {
    1: 790850,
    2: 736683,
    3: 653350,
    4: 583350,
    5: 525850,
    "revenu brut": 2269000,
    trimf: 1500,
    1.5: 765850,
    2.5: 699183,
    3.5: 621683,
    4.5: 561266,
  },
  {
    1: 791250,
    2: 737083,
    3: 653750,
    4: 583750,
    5: 526250,
    "revenu brut": 2270000,
    trimf: 1500,
    1.5: 766250,
    2.5: 699583,
    3.5: 622083,
    4.5: 561666,
  },
  {
    1: 791650,
    2: 737483,
    3: 654150,
    4: 584150,
    5: 526650,
    "revenu brut": 2271_000,
    trimf: 1500,
    1.5: 766650,
    2.5: 699983,
    3.5: 622483,
    4.5: 562066,
  },
  {
    1: 792050,
    2: 737883,
    3: 654550,
    4: 584550,
    5: 527050,
    "revenu brut": 2272000,
    trimf: 1500,
    1.5: 767050,
    2.5: 700383,
    3.5: 622883,
    4.5: 562466,
  },
  {
    1: 792450,
    2: 738283,
    3: 654950,
    4: 584950,
    5: 527450,
    "revenu brut": 2273000,
    trimf: 1500,
    1.5: 767450,
    2.5: 700783,
    3.5: 623283,
    4.5: 562866,
  },
  {
    1: 792850,
    2: 738683,
    3: 655350,
    4: 585350,
    5: 527850,
    "revenu brut": 2274000,
    trimf: 1500,
    1.5: 767850,
    2.5: 701183,
    3.5: 623683,
    4.5: 563266,
  },
  {
    1: 793250,
    2: 739083,
    3: 655750,
    4: 585750,
    5: 528250,
    "revenu brut": 2275000,
    trimf: 1500,
    1.5: 768250,
    2.5: 701583,
    3.5: 624083,
    4.5: 563666,
  },
  {
    1: 793650,
    2: 739483,
    3: 656150,
    4: 586150,
    5: 528650,
    "revenu brut": 2276000,
    trimf: 1500,
    1.5: 768650,
    2.5: 701983,
    3.5: 624483,
    4.5: 564066,
  },
  {
    1: 794050,
    2: 739883,
    3: 656550,
    4: 586550,
    5: 529050,
    "revenu brut": 2277000,
    trimf: 1500,
    1.5: 769050,
    2.5: 702383,
    3.5: 624883,
    4.5: 564466,
  },
  {
    1: 794450,
    2: 740283,
    3: 656950,
    4: 586950,
    5: 529450,
    "revenu brut": 2278000,
    trimf: 1500,
    1.5: 769450,
    2.5: 702783,
    3.5: 625283,
    4.5: 564866,
  },
  {
    1: 794850,
    2: 740683,
    3: 657350,
    4: 587350,
    5: 529850,
    "revenu brut": 2279000,
    trimf: 1500,
    1.5: 769850,
    2.5: 703183,
    3.5: 625683,
    4.5: 565266,
  },
  {
    1: 795250,
    2: 741083,
    3: 657750,
    4: 587750,
    5: 530250,
    "revenu brut": 2280000,
    trimf: 1500,
    1.5: 770250,
    2.5: 703583,
    3.5: 626083,
    4.5: 565666,
  },
  {
    1: 795650,
    2: 741483,
    3: 658150,
    4: 588150,
    5: 530650,
    "revenu brut": 2281_000,
    trimf: 1500,
    1.5: 770650,
    2.5: 703983,
    3.5: 626483,
    4.5: 566066,
  },
  {
    1: 796050,
    2: 741883,
    3: 658550,
    4: 588550,
    5: 531050,
    "revenu brut": 2282000,
    trimf: 1500,
    1.5: 771050,
    2.5: 704383,
    3.5: 626883,
    4.5: 566466,
  },
  {
    1: 796450,
    2: 742283,
    3: 658950,
    4: 588950,
    5: 531450,
    "revenu brut": 2283000,
    trimf: 1500,
    1.5: 771450,
    2.5: 704783,
    3.5: 627283,
    4.5: 566866,
  },
  {
    1: 796850,
    2: 742683,
    3: 659350,
    4: 589350,
    5: 531850,
    "revenu brut": 2284000,
    trimf: 1500,
    1.5: 771850,
    2.5: 705183,
    3.5: 627683,
    4.5: 567266,
  },
  {
    1: 797250,
    2: 743083,
    3: 659750,
    4: 589750,
    5: 532250,
    "revenu brut": 2285000,
    trimf: 1500,
    1.5: 772250,
    2.5: 705583,
    3.5: 628083,
    4.5: 567666,
  },
  {
    1: 797650,
    2: 743483,
    3: 660150,
    4: 590150,
    5: 532650,
    "revenu brut": 2286000,
    trimf: 1500,
    1.5: 772650,
    2.5: 705983,
    3.5: 628483,
    4.5: 568066,
  },
  {
    1: 798050,
    2: 743883,
    3: 660550,
    4: 590550,
    5: 533050,
    "revenu brut": 2287000,
    trimf: 1500,
    1.5: 773050,
    2.5: 706383,
    3.5: 628883,
    4.5: 568466,
  },
  {
    1: 798450,
    2: 744283,
    3: 660950,
    4: 590950,
    5: 533450,
    "revenu brut": 2288000,
    trimf: 1500,
    1.5: 773450,
    2.5: 706783,
    3.5: 629283,
    4.5: 568866,
  },
  {
    1: 798850,
    2: 744683,
    3: 661350,
    4: 591350,
    5: 533850,
    "revenu brut": 2289000,
    trimf: 1500,
    1.5: 773850,
    2.5: 707183,
    3.5: 629683,
    4.5: 569266,
  },
  {
    1: 799250,
    2: 745083,
    3: 661750,
    4: 591750,
    5: 534250,
    "revenu brut": 2290000,
    trimf: 1500,
    1.5: 774250,
    2.5: 707583,
    3.5: 630083,
    4.5: 569666,
  },
  {
    1: 799650,
    2: 745483,
    3: 662150,
    4: 592150,
    5: 534650,
    "revenu brut": 2291_000,
    trimf: 1500,
    1.5: 774650,
    2.5: 707983,
    3.5: 630483,
    4.5: 570066,
  },
  {
    1: 800050,
    2: 745883,
    3: 662550,
    4: 592550,
    5: 535050,
    "revenu brut": 2292000,
    trimf: 1500,
    1.5: 775050,
    2.5: 708383,
    3.5: 630883,
    4.5: 570466,
  },
  {
    1: 800450,
    2: 746283,
    3: 662950,
    4: 592950,
    5: 535450,
    "revenu brut": 2293000,
    trimf: 1500,
    1.5: 775450,
    2.5: 708783,
    3.5: 631283,
    4.5: 570866,
  },
  {
    1: 800850,
    2: 746683,
    3: 663350,
    4: 593350,
    5: 535850,
    "revenu brut": 2294000,
    trimf: 1500,
    1.5: 775850,
    2.5: 709183,
    3.5: 631683,
    4.5: 571266,
  },
  {
    1: 801250,
    2: 747083,
    3: 663750,
    4: 593750,
    5: 536250,
    "revenu brut": 2295000,
    trimf: 1500,
    1.5: 776250,
    2.5: 709583,
    3.5: 632083,
    4.5: 571666,
  },
  {
    1: 801650,
    2: 747483,
    3: 664150,
    4: 594150,
    5: 536650,
    "revenu brut": 2296000,
    trimf: 1500,
    1.5: 776650,
    2.5: 709983,
    3.5: 632483,
    4.5: 572066,
  },
  {
    1: 802050,
    2: 747883,
    3: 664550,
    4: 594550,
    5: 537050,
    "revenu brut": 2297000,
    trimf: 1500,
    1.5: 777050,
    2.5: 710383,
    3.5: 632883,
    4.5: 572466,
  },
  {
    1: 802450,
    2: 748283,
    3: 664950,
    4: 594950,
    5: 537450,
    "revenu brut": 2298000,
    trimf: 1500,
    1.5: 777450,
    2.5: 710783,
    3.5: 633283,
    4.5: 572866,
  },
  {
    1: 802850,
    2: 748683,
    3: 665350,
    4: 595350,
    5: 537850,
    "revenu brut": 2299000,
    trimf: 1500,
    1.5: 777850,
    2.5: 711183,
    3.5: 633683,
    4.5: 573266,
  },
  {
    1: 803250,
    2: 749083,
    3: 665750,
    4: 595750,
    5: 538250,
    "revenu brut": 2300000,
    trimf: 1500,
    1.5: 778250,
    2.5: 711583,
    3.5: 634083,
    4.5: 573666,
  },
  {
    1: 803650,
    2: 749483,
    3: 666150,
    4: 596150,
    5: 538650,
    "revenu brut": 2301_000,
    trimf: 1500,
    1.5: 778650,
    2.5: 711983,
    3.5: 634483,
    4.5: 574066,
  },
  {
    1: 804050,
    2: 749883,
    3: 666550,
    4: 596550,
    5: 539050,
    "revenu brut": 2302000,
    trimf: 1500,
    1.5: 779050,
    2.5: 712383,
    3.5: 634883,
    4.5: 574466,
  },
  {
    1: 804450,
    2: 750283,
    3: 666950,
    4: 596950,
    5: 539450,
    "revenu brut": 2303000,
    trimf: 1500,
    1.5: 779450,
    2.5: 712783,
    3.5: 635283,
    4.5: 574866,
  },
  {
    1: 804850,
    2: 750683,
    3: 667350,
    4: 597350,
    5: 539850,
    "revenu brut": 2304000,
    trimf: 1500,
    1.5: 779850,
    2.5: 713183,
    3.5: 635683,
    4.5: 575266,
  },
  {
    1: 805250,
    2: 751083,
    3: 667750,
    4: 597750,
    5: 540250,
    "revenu brut": 2305000,
    trimf: 1500,
    1.5: 780250,
    2.5: 713583,
    3.5: 636083,
    4.5: 575666,
  },
  {
    1: 805650,
    2: 751483,
    3: 668150,
    4: 598150,
    5: 540650,
    "revenu brut": 2306000,
    trimf: 1500,
    1.5: 780650,
    2.5: 713983,
    3.5: 636483,
    4.5: 576066,
  },
  {
    1: 806050,
    2: 751883,
    3: 668550,
    4: 598550,
    5: 541050,
    "revenu brut": 2307000,
    trimf: 1500,
    1.5: 781050,
    2.5: 714383,
    3.5: 636883,
    4.5: 576466,
  },
  {
    1: 806450,
    2: 752283,
    3: 668950,
    4: 598950,
    5: 541450,
    "revenu brut": 2308000,
    trimf: 1500,
    1.5: 781450,
    2.5: 714783,
    3.5: 637283,
    4.5: 576866,
  },
  {
    1: 806850,
    2: 752683,
    3: 669350,
    4: 599350,
    5: 541850,
    "revenu brut": 2309000,
    trimf: 1500,
    1.5: 781850,
    2.5: 715183,
    3.5: 637683,
    4.5: 577266,
  },
  {
    1: 807250,
    2: 753083,
    3: 669750,
    4: 599750,
    5: 542250,
    "revenu brut": 231_0000,
    trimf: 1500,
    1.5: 782250,
    2.5: 715583,
    3.5: 638083,
    4.5: 577666,
  },
  {
    1: 807650,
    2: 753483,
    3: 670150,
    4: 600150,
    5: 542650,
    "revenu brut": 2311_000,
    trimf: 1500,
    1.5: 782650,
    2.5: 715983,
    3.5: 638483,
    4.5: 578066,
  },
  {
    1: 808050,
    2: 753883,
    3: 670550,
    4: 600550,
    5: 543050,
    "revenu brut": 2312000,
    trimf: 1500,
    1.5: 783050,
    2.5: 716383,
    3.5: 638883,
    4.5: 578466,
  },
  {
    1: 808450,
    2: 754283,
    3: 670950,
    4: 600950,
    5: 543450,
    "revenu brut": 2313000,
    trimf: 1500,
    1.5: 783450,
    2.5: 716783,
    3.5: 639283,
    4.5: 578866,
  },
  {
    1: 808850,
    2: 754683,
    3: 671350,
    4: 601350,
    5: 543850,
    "revenu brut": 2314000,
    trimf: 1500,
    1.5: 783850,
    2.5: 717183,
    3.5: 639683,
    4.5: 579266,
  },
  {
    1: 809250,
    2: 755083,
    3: 671750,
    4: 601750,
    5: 544250,
    "revenu brut": 2315000,
    trimf: 1500,
    1.5: 784250,
    2.5: 717583,
    3.5: 640083,
    4.5: 579666,
  },
  {
    1: 809650,
    2: 755483,
    3: 672150,
    4: 602150,
    5: 544650,
    "revenu brut": 2316000,
    trimf: 1500,
    1.5: 784650,
    2.5: 717983,
    3.5: 640483,
    4.5: 580066,
  },
  {
    1: 810050,
    2: 755883,
    3: 672550,
    4: 602550,
    5: 545050,
    "revenu brut": 2317000,
    trimf: 1500,
    1.5: 785050,
    2.5: 718383,
    3.5: 640883,
    4.5: 580466,
  },
  {
    1: 810450,
    2: 756283,
    3: 672950,
    4: 602950,
    5: 545450,
    "revenu brut": 2318000,
    trimf: 1500,
    1.5: 785450,
    2.5: 718783,
    3.5: 641283,
    4.5: 580866,
  },
  {
    1: 810850,
    2: 756683,
    3: 673350,
    4: 603350,
    5: 545850,
    "revenu brut": 2319000,
    trimf: 1500,
    1.5: 785850,
    2.5: 719183,
    3.5: 641683,
    4.5: 581266,
  },
  {
    1: 811250,
    2: 757083,
    3: 673750,
    4: 603750,
    5: 546250,
    "revenu brut": 2320000,
    trimf: 1500,
    1.5: 786250,
    2.5: 719583,
    3.5: 642083,
    4.5: 581666,
  },
  {
    1: 811650,
    2: 757483,
    3: 674150,
    4: 604150,
    5: 546650,
    "revenu brut": 2321_000,
    trimf: 1500,
    1.5: 786650,
    2.5: 719983,
    3.5: 642483,
    4.5: 582066,
  },
  {
    1: 812050,
    2: 757883,
    3: 674550,
    4: 604550,
    5: 547050,
    "revenu brut": 2322000,
    trimf: 1500,
    1.5: 787050,
    2.5: 720383,
    3.5: 642883,
    4.5: 582466,
  },
  {
    1: 812450,
    2: 758283,
    3: 674950,
    4: 604950,
    5: 547450,
    "revenu brut": 2323000,
    trimf: 1500,
    1.5: 787450,
    2.5: 720783,
    3.5: 643283,
    4.5: 582866,
  },
  {
    1: 812850,
    2: 758683,
    3: 675350,
    4: 605350,
    5: 547850,
    "revenu brut": 2324000,
    trimf: 1500,
    1.5: 787850,
    2.5: 721183,
    3.5: 643683,
    4.5: 583266,
  },
  {
    1: 813250,
    2: 759083,
    3: 675750,
    4: 605750,
    5: 548250,
    "revenu brut": 2325000,
    trimf: 1500,
    1.5: 788250,
    2.5: 721583,
    3.5: 644083,
    4.5: 583666,
  },
  {
    1: 813650,
    2: 759483,
    3: 676150,
    4: 606150,
    5: 548650,
    "revenu brut": 2326000,
    trimf: 1500,
    1.5: 788650,
    2.5: 721983,
    3.5: 644483,
    4.5: 584066,
  },
  {
    1: 814050,
    2: 759883,
    3: 676550,
    4: 606550,
    5: 549050,
    "revenu brut": 2327000,
    trimf: 1500,
    1.5: 789050,
    2.5: 722383,
    3.5: 644883,
    4.5: 584466,
  },
  {
    1: 814450,
    2: 760283,
    3: 676950,
    4: 606950,
    5: 549450,
    "revenu brut": 2328000,
    trimf: 1500,
    1.5: 789450,
    2.5: 722783,
    3.5: 645283,
    4.5: 584866,
  },
  {
    1: 814850,
    2: 760683,
    3: 677350,
    4: 607350,
    5: 549850,
    "revenu brut": 2329000,
    trimf: 1500,
    1.5: 789850,
    2.5: 723183,
    3.5: 645683,
    4.5: 585266,
  },
  {
    1: 815250,
    2: 761083,
    3: 677750,
    4: 607750,
    5: 550250,
    "revenu brut": 2330000,
    trimf: 1500,
    1.5: 790250,
    2.5: 723583,
    3.5: 646083,
    4.5: 585666,
  },
  {
    1: 815650,
    2: 761483,
    3: 678150,
    4: 608150,
    5: 550650,
    "revenu brut": 2331_000,
    trimf: 1500,
    1.5: 790650,
    2.5: 723983,
    3.5: 646483,
    4.5: 586066,
  },
  {
    1: 816050,
    2: 761883,
    3: 678550,
    4: 608550,
    5: 551050,
    "revenu brut": 2332000,
    trimf: 1500,
    1.5: 791050,
    2.5: 724383,
    3.5: 646883,
    4.5: 586466,
  },
  {
    1: 816450,
    2: 762283,
    3: 678950,
    4: 608950,
    5: 551450,
    "revenu brut": 2333000,
    trimf: 1500,
    1.5: 791450,
    2.5: 724783,
    3.5: 647283,
    4.5: 586866,
  },
  {
    1: 816850,
    2: 762683,
    3: 679350,
    4: 609350,
    5: 551850,
    "revenu brut": 2334000,
    trimf: 1500,
    1.5: 791850,
    2.5: 725183,
    3.5: 647683,
    4.5: 587266,
  },
  {
    1: 817250,
    2: 763083,
    3: 679750,
    4: 609750,
    5: 552250,
    "revenu brut": 2335000,
    trimf: 1500,
    1.5: 792250,
    2.5: 725583,
    3.5: 648083,
    4.5: 587666,
  },
  {
    1: 817650,
    2: 763483,
    3: 680150,
    4: 610150,
    5: 552650,
    "revenu brut": 2336000,
    trimf: 1500,
    1.5: 792650,
    2.5: 725983,
    3.5: 648483,
    4.5: 588066,
  },
  {
    1: 818050,
    2: 763883,
    3: 680550,
    4: 610550,
    5: 553050,
    "revenu brut": 2337000,
    trimf: 1500,
    1.5: 793050,
    2.5: 726383,
    3.5: 648883,
    4.5: 588466,
  },
  {
    1: 818450,
    2: 764283,
    3: 680950,
    4: 610950,
    5: 553450,
    "revenu brut": 2338000,
    trimf: 1500,
    1.5: 793450,
    2.5: 726783,
    3.5: 649283,
    4.5: 588866,
  },
  {
    1: 818850,
    2: 764683,
    3: 681350,
    4: 611350,
    5: 553850,
    "revenu brut": 2339000,
    trimf: 1500,
    1.5: 793850,
    2.5: 727183,
    3.5: 649683,
    4.5: 589266,
  },
  {
    1: 819250,
    2: 765083,
    3: 681750,
    4: 611750,
    5: 554250,
    "revenu brut": 2340000,
    trimf: 1500,
    1.5: 794250,
    2.5: 727583,
    3.5: 650083,
    4.5: 589666,
  },
  {
    1: 819650,
    2: 765483,
    3: 682150,
    4: 612150,
    5: 554650,
    "revenu brut": 2341_000,
    trimf: 1500,
    1.5: 794650,
    2.5: 727983,
    3.5: 650483,
    4.5: 590066,
  },
  {
    1: 820050,
    2: 765883,
    3: 682550,
    4: 612550,
    5: 555050,
    "revenu brut": 2342000,
    trimf: 1500,
    1.5: 795050,
    2.5: 728383,
    3.5: 650883,
    4.5: 590466,
  },
  {
    1: 820450,
    2: 766283,
    3: 682950,
    4: 612950,
    5: 555450,
    "revenu brut": 2343000,
    trimf: 1500,
    1.5: 795450,
    2.5: 728783,
    3.5: 651283,
    4.5: 590866,
  },
  {
    1: 820850,
    2: 766683,
    3: 683350,
    4: 613350,
    5: 555850,
    "revenu brut": 2344000,
    trimf: 1500,
    1.5: 795850,
    2.5: 729183,
    3.5: 651683,
    4.5: 591266,
  },
  {
    1: 821250,
    2: 767083,
    3: 683750,
    4: 613750,
    5: 556250,
    "revenu brut": 2345000,
    trimf: 1500,
    1.5: 796250,
    2.5: 729583,
    3.5: 652083,
    4.5: 591666,
  },
  {
    1: 821650,
    2: 767483,
    3: 684150,
    4: 614150,
    5: 556650,
    "revenu brut": 2346000,
    trimf: 1500,
    1.5: 796650,
    2.5: 729983,
    3.5: 652483,
    4.5: 592066,
  },
  {
    1: 822050,
    2: 767883,
    3: 684550,
    4: 614550,
    5: 557050,
    "revenu brut": 2347000,
    trimf: 1500,
    1.5: 797050,
    2.5: 730383,
    3.5: 652883,
    4.5: 592466,
  },
  {
    1: 822450,
    2: 768283,
    3: 684950,
    4: 614950,
    5: 557450,
    "revenu brut": 2348000,
    trimf: 1500,
    1.5: 797450,
    2.5: 730783,
    3.5: 653283,
    4.5: 592866,
  },
  {
    1: 822850,
    2: 768683,
    3: 685350,
    4: 615350,
    5: 557850,
    "revenu brut": 2349000,
    trimf: 1500,
    1.5: 797850,
    2.5: 731183,
    3.5: 653683,
    4.5: 593266,
  },
  {
    1: 823250,
    2: 769083,
    3: 685750,
    4: 615750,
    5: 558250,
    "revenu brut": 2350000,
    trimf: 1500,
    1.5: 798250,
    2.5: 731583,
    3.5: 654083,
    4.5: 593666,
  },
  {
    1: 823650,
    2: 769483,
    3: 686150,
    4: 616150,
    5: 558650,
    "revenu brut": 2351_000,
    trimf: 1500,
    1.5: 798650,
    2.5: 731983,
    3.5: 654483,
    4.5: 594066,
  },
  {
    1: 824050,
    2: 769883,
    3: 686550,
    4: 616550,
    5: 559050,
    "revenu brut": 2352000,
    trimf: 1500,
    1.5: 799050,
    2.5: 732383,
    3.5: 654883,
    4.5: 594466,
  },
  {
    1: 824450,
    2: 770283,
    3: 686950,
    4: 616950,
    5: 559450,
    "revenu brut": 2353000,
    trimf: 1500,
    1.5: 799450,
    2.5: 732783,
    3.5: 655283,
    4.5: 594866,
  },
  {
    1: 824850,
    2: 770683,
    3: 687350,
    4: 617350,
    5: 559850,
    "revenu brut": 2354000,
    trimf: 1500,
    1.5: 799850,
    2.5: 733183,
    3.5: 655683,
    4.5: 595266,
  },
  {
    1: 825250,
    2: 771083,
    3: 687750,
    4: 617750,
    5: 560250,
    "revenu brut": 2355000,
    trimf: 1500,
    1.5: 800250,
    2.5: 733583,
    3.5: 656083,
    4.5: 595666,
  },
  {
    1: 825650,
    2: 771483,
    3: 688150,
    4: 618150,
    5: 560650,
    "revenu brut": 2356000,
    trimf: 1500,
    1.5: 800650,
    2.5: 733983,
    3.5: 656483,
    4.5: 596066,
  },
  {
    1: 826050,
    2: 771883,
    3: 688550,
    4: 618550,
    5: 561050,
    "revenu brut": 2357000,
    trimf: 1500,
    1.5: 801050,
    2.5: 734383,
    3.5: 656883,
    4.5: 596466,
  },
  {
    1: 826450,
    2: 772283,
    3: 688950,
    4: 618950,
    5: 561450,
    "revenu brut": 2358000,
    trimf: 1500,
    1.5: 801450,
    2.5: 734783,
    3.5: 657283,
    4.5: 596866,
  },
  {
    1: 826850,
    2: 772683,
    3: 689350,
    4: 619350,
    5: 561850,
    "revenu brut": 2359000,
    trimf: 1500,
    1.5: 801850,
    2.5: 735183,
    3.5: 657683,
    4.5: 597266,
  },
  {
    1: 827250,
    2: 773083,
    3: 689750,
    4: 619750,
    5: 562250,
    "revenu brut": 2360000,
    trimf: 1500,
    1.5: 802250,
    2.5: 735583,
    3.5: 658083,
    4.5: 597666,
  },
  {
    1: 827650,
    2: 773483,
    3: 690150,
    4: 620150,
    5: 562650,
    "revenu brut": 2361_000,
    trimf: 1500,
    1.5: 802650,
    2.5: 735983,
    3.5: 658483,
    4.5: 598066,
  },
  {
    1: 828050,
    2: 773883,
    3: 690550,
    4: 620550,
    5: 563050,
    "revenu brut": 2362000,
    trimf: 1500,
    1.5: 803050,
    2.5: 736383,
    3.5: 658883,
    4.5: 598466,
  },
  {
    1: 828450,
    2: 774283,
    3: 690950,
    4: 620950,
    5: 563450,
    "revenu brut": 2363000,
    trimf: 1500,
    1.5: 803450,
    2.5: 736783,
    3.5: 659283,
    4.5: 598866,
  },
  {
    1: 828850,
    2: 774683,
    3: 691350,
    4: 621350,
    5: 563850,
    "revenu brut": 2364000,
    trimf: 1500,
    1.5: 803850,
    2.5: 737183,
    3.5: 659683,
    4.5: 599266,
  },
  {
    1: 829250,
    2: 775083,
    3: 691750,
    4: 621750,
    5: 564250,
    "revenu brut": 2365000,
    trimf: 1500,
    1.5: 804250,
    2.5: 737583,
    3.5: 660083,
    4.5: 599666,
  },
  {
    1: 829650,
    2: 775483,
    3: 692150,
    4: 622150,
    5: 564650,
    "revenu brut": 2366000,
    trimf: 1500,
    1.5: 804650,
    2.5: 737983,
    3.5: 660483,
    4.5: 600066,
  },
  {
    1: 830050,
    2: 775883,
    3: 692550,
    4: 622550,
    5: 565050,
    "revenu brut": 2367000,
    trimf: 1500,
    1.5: 805050,
    2.5: 738383,
    3.5: 660883,
    4.5: 600466,
  },
  {
    1: 830450,
    2: 776283,
    3: 692950,
    4: 622950,
    5: 565450,
    "revenu brut": 2368000,
    trimf: 1500,
    1.5: 805450,
    2.5: 738783,
    3.5: 661283,
    4.5: 600866,
  },
  {
    1: 830850,
    2: 776683,
    3: 693350,
    4: 623350,
    5: 565850,
    "revenu brut": 2369000,
    trimf: 1500,
    1.5: 805850,
    2.5: 739183,
    3.5: 661683,
    4.5: 601266,
  },
  {
    1: 831250,
    2: 777083,
    3: 693750,
    4: 623750,
    5: 566250,
    "revenu brut": 2370000,
    trimf: 1500,
    1.5: 806250,
    2.5: 739583,
    3.5: 662083,
    4.5: 601666,
  },
  {
    1: 831650,
    2: 777483,
    3: 694150,
    4: 624150,
    5: 566650,
    "revenu brut": 2371_000,
    trimf: 1500,
    1.5: 806650,
    2.5: 739983,
    3.5: 662483,
    4.5: 602066,
  },
  {
    1: 832050,
    2: 777883,
    3: 694550,
    4: 624550,
    5: 567050,
    "revenu brut": 2372000,
    trimf: 1500,
    1.5: 807050,
    2.5: 740383,
    3.5: 662883,
    4.5: 602466,
  },
  {
    1: 832450,
    2: 778283,
    3: 694950,
    4: 624950,
    5: 567450,
    "revenu brut": 2373000,
    trimf: 1500,
    1.5: 807450,
    2.5: 740783,
    3.5: 663283,
    4.5: 602866,
  },
  {
    1: 832850,
    2: 778683,
    3: 695350,
    4: 625350,
    5: 567850,
    "revenu brut": 2374000,
    trimf: 1500,
    1.5: 807850,
    2.5: 741183,
    3.5: 663683,
    4.5: 603266,
  },
  {
    1: 833250,
    2: 779083,
    3: 695750,
    4: 625750,
    5: 568250,
    "revenu brut": 2375000,
    trimf: 1500,
    1.5: 808250,
    2.5: 741583,
    3.5: 664083,
    4.5: 603666,
  },
  {
    1: 833650,
    2: 779483,
    3: 696150,
    4: 626150,
    5: 568650,
    "revenu brut": 2376000,
    trimf: 1500,
    1.5: 808650,
    2.5: 741983,
    3.5: 664483,
    4.5: 604066,
  },
  {
    1: 834050,
    2: 779883,
    3: 696550,
    4: 626550,
    5: 569050,
    "revenu brut": 2377000,
    trimf: 1500,
    1.5: 809050,
    2.5: 742383,
    3.5: 664883,
    4.5: 604466,
  },
  {
    1: 834450,
    2: 780283,
    3: 696950,
    4: 626950,
    5: 569450,
    "revenu brut": 2378000,
    trimf: 1500,
    1.5: 809450,
    2.5: 742783,
    3.5: 665283,
    4.5: 604866,
  },
  {
    1: 834850,
    2: 780683,
    3: 697350,
    4: 627350,
    5: 569850,
    "revenu brut": 2379000,
    trimf: 1500,
    1.5: 809850,
    2.5: 743183,
    3.5: 665683,
    4.5: 605266,
  },
  {
    1: 835250,
    2: 781083,
    3: 697750,
    4: 627750,
    5: 570250,
    "revenu brut": 2380000,
    trimf: 1500,
    1.5: 810250,
    2.5: 743583,
    3.5: 666083,
    4.5: 605666,
  },
  {
    1: 835650,
    2: 781483,
    3: 698150,
    4: 628150,
    5: 570650,
    "revenu brut": 2381_000,
    trimf: 1500,
    1.5: 810650,
    2.5: 743983,
    3.5: 666483,
    4.5: 606066,
  },
  {
    1: 836050,
    2: 781883,
    3: 698550,
    4: 628550,
    5: 571050,
    "revenu brut": 2382000,
    trimf: 1500,
    1.5: 811050,
    2.5: 744383,
    3.5: 666883,
    4.5: 606466,
  },
  {
    1: 836450,
    2: 782283,
    3: 698950,
    4: 628950,
    5: 571450,
    "revenu brut": 2383000,
    trimf: 1500,
    1.5: 811450,
    2.5: 744783,
    3.5: 667283,
    4.5: 606866,
  },
  {
    1: 836850,
    2: 782683,
    3: 699350,
    4: 629350,
    5: 571850,
    "revenu brut": 2384000,
    trimf: 1500,
    1.5: 811850,
    2.5: 745183,
    3.5: 667683,
    4.5: 607266,
  },
  {
    1: 837250,
    2: 783083,
    3: 699750,
    4: 629750,
    5: 572250,
    "revenu brut": 2385000,
    trimf: 1500,
    1.5: 812250,
    2.5: 745583,
    3.5: 668083,
    4.5: 607666,
  },
  {
    1: 837650,
    2: 783483,
    3: 700150,
    4: 630150,
    5: 572650,
    "revenu brut": 2386000,
    trimf: 1500,
    1.5: 812650,
    2.5: 745983,
    3.5: 668483,
    4.5: 608066,
  },
  {
    1: 838050,
    2: 783883,
    3: 700550,
    4: 630550,
    5: 573050,
    "revenu brut": 2387000,
    trimf: 1500,
    1.5: 813050,
    2.5: 746383,
    3.5: 668883,
    4.5: 608466,
  },
  {
    1: 838450,
    2: 784283,
    3: 700950,
    4: 630950,
    5: 573450,
    "revenu brut": 2388000,
    trimf: 1500,
    1.5: 813450,
    2.5: 746783,
    3.5: 669283,
    4.5: 608866,
  },
  {
    1: 838850,
    2: 784683,
    3: 701350,
    4: 631350,
    5: 573850,
    "revenu brut": 2389000,
    trimf: 1500,
    1.5: 813850,
    2.5: 747183,
    3.5: 669683,
    4.5: 609266,
  },
  {
    1: 839250,
    2: 785083,
    3: 701750,
    4: 631750,
    5: 574250,
    "revenu brut": 2390000,
    trimf: 1500,
    1.5: 814250,
    2.5: 747583,
    3.5: 670083,
    4.5: 609666,
  },
  {
    1: 839650,
    2: 785483,
    3: 702150,
    4: 632150,
    5: 574650,
    "revenu brut": 2391_000,
    trimf: 1500,
    1.5: 814650,
    2.5: 747983,
    3.5: 670483,
    4.5: 610066,
  },
  {
    1: 840050,
    2: 785883,
    3: 702550,
    4: 632550,
    5: 575050,
    "revenu brut": 2392000,
    trimf: 1500,
    1.5: 815050,
    2.5: 748383,
    3.5: 670883,
    4.5: 610466,
  },
  {
    1: 840450,
    2: 786283,
    3: 702950,
    4: 632950,
    5: 575450,
    "revenu brut": 2393000,
    trimf: 1500,
    1.5: 815450,
    2.5: 748783,
    3.5: 671283,
    4.5: 610866,
  },
  {
    1: 840850,
    2: 786683,
    3: 703350,
    4: 633350,
    5: 575850,
    "revenu brut": 2394000,
    trimf: 1500,
    1.5: 815850,
    2.5: 749183,
    3.5: 671683,
    4.5: 611266,
  },
  {
    1: 841250,
    2: 787083,
    3: 703750,
    4: 633750,
    5: 576250,
    "revenu brut": 2395000,
    trimf: 1500,
    1.5: 816250,
    2.5: 749583,
    3.5: 672083,
    4.5: 611666,
  },
  {
    1: 841650,
    2: 787483,
    3: 704150,
    4: 634150,
    5: 576650,
    "revenu brut": 2396000,
    trimf: 1500,
    1.5: 816650,
    2.5: 749983,
    3.5: 672483,
    4.5: 612066,
  },
  {
    1: 842050,
    2: 787883,
    3: 704550,
    4: 634550,
    5: 577050,
    "revenu brut": 2397000,
    trimf: 1500,
    1.5: 817050,
    2.5: 750383,
    3.5: 672883,
    4.5: 612466,
  },
  {
    1: 842450,
    2: 788283,
    3: 704950,
    4: 634950,
    5: 577450,
    "revenu brut": 2398000,
    trimf: 1500,
    1.5: 817450,
    2.5: 750783,
    3.5: 673283,
    4.5: 612866,
  },
  {
    1: 842850,
    2: 788683,
    3: 705350,
    4: 635350,
    5: 577850,
    "revenu brut": 2399000,
    trimf: 1500,
    1.5: 817850,
    2.5: 751183,
    3.5: 673683,
    4.5: 613266,
  },
  {
    1: 843250,
    2: 789083,
    3: 705750,
    4: 635750,
    5: 578250,
    "revenu brut": 2400000,
    trimf: 1500,
    1.5: 818250,
    2.5: 751583,
    3.5: 674083,
    4.5: 613666,
  },
  {
    1: 843650,
    2: 789483,
    3: 706150,
    4: 636150,
    5: 578650,
    "revenu brut": 2401_000,
    trimf: 1500,
    1.5: 818650,
    2.5: 751983,
    3.5: 674483,
    4.5: 614066,
  },
  {
    1: 844050,
    2: 789883,
    3: 706550,
    4: 636550,
    5: 579050,
    "revenu brut": 2402000,
    trimf: 1500,
    1.5: 819050,
    2.5: 752383,
    3.5: 674883,
    4.5: 614466,
  },
  {
    1: 844450,
    2: 790283,
    3: 706950,
    4: 636950,
    5: 579450,
    "revenu brut": 2403000,
    trimf: 1500,
    1.5: 819450,
    2.5: 752783,
    3.5: 675283,
    4.5: 614866,
  },
  {
    1: 844850,
    2: 790683,
    3: 707350,
    4: 637350,
    5: 579850,
    "revenu brut": 2404000,
    trimf: 1500,
    1.5: 819850,
    2.5: 753183,
    3.5: 675683,
    4.5: 615266,
  },
  {
    1: 845250,
    2: 791083,
    3: 707750,
    4: 637750,
    5: 580250,
    "revenu brut": 2405000,
    trimf: 1500,
    1.5: 820250,
    2.5: 753583,
    3.5: 676083,
    4.5: 615666,
  },
  {
    1: 845650,
    2: 791483,
    3: 708150,
    4: 638150,
    5: 580650,
    "revenu brut": 2406000,
    trimf: 1500,
    1.5: 820650,
    2.5: 753983,
    3.5: 676483,
    4.5: 616066,
  },
  {
    1: 846050,
    2: 791883,
    3: 708550,
    4: 638550,
    5: 581050,
    "revenu brut": 2407000,
    trimf: 1500,
    1.5: 821050,
    2.5: 754383,
    3.5: 676883,
    4.5: 616466,
  },
  {
    1: 846450,
    2: 792283,
    3: 708950,
    4: 638950,
    5: 581450,
    "revenu brut": 2408000,
    trimf: 1500,
    1.5: 821450,
    2.5: 754783,
    3.5: 677283,
    4.5: 616866,
  },
  {
    1: 846850,
    2: 792683,
    3: 709350,
    4: 639350,
    5: 581850,
    "revenu brut": 2409000,
    trimf: 1500,
    1.5: 821850,
    2.5: 755183,
    3.5: 677683,
    4.5: 617266,
  },
  {
    1: 847250,
    2: 793083,
    3: 709750,
    4: 639750,
    5: 582250,
    "revenu brut": 241_0000,
    trimf: 1500,
    1.5: 822250,
    2.5: 755583,
    3.5: 678083,
    4.5: 617666,
  },
  {
    1: 847650,
    2: 793483,
    3: 710150,
    4: 640150,
    5: 582650,
    "revenu brut": 2411_000,
    trimf: 1500,
    1.5: 822650,
    2.5: 755983,
    3.5: 678483,
    4.5: 618066,
  },
  {
    1: 848050,
    2: 793883,
    3: 710550,
    4: 640550,
    5: 583050,
    "revenu brut": 2412000,
    trimf: 1500,
    1.5: 823050,
    2.5: 756383,
    3.5: 678883,
    4.5: 618466,
  },
  {
    1: 848450,
    2: 794283,
    3: 710950,
    4: 640950,
    5: 583450,
    "revenu brut": 2413000,
    trimf: 1500,
    1.5: 823450,
    2.5: 756783,
    3.5: 679283,
    4.5: 618866,
  },
  {
    1: 848850,
    2: 794683,
    3: 711350,
    4: 641350,
    5: 583850,
    "revenu brut": 2414000,
    trimf: 1500,
    1.5: 823850,
    2.5: 757183,
    3.5: 679683,
    4.5: 619266,
  },
  {
    1: 849250,
    2: 795083,
    3: 711750,
    4: 641750,
    5: 584250,
    "revenu brut": 2415000,
    trimf: 1500,
    1.5: 824250,
    2.5: 757583,
    3.5: 680083,
    4.5: 619666,
  },
  {
    1: 849650,
    2: 795483,
    3: 712150,
    4: 642150,
    5: 584650,
    "revenu brut": 2416000,
    trimf: 1500,
    1.5: 824650,
    2.5: 757983,
    3.5: 680483,
    4.5: 620066,
  },
  {
    1: 850050,
    2: 795883,
    3: 712550,
    4: 642550,
    5: 585050,
    "revenu brut": 2417000,
    trimf: 1500,
    1.5: 825050,
    2.5: 758383,
    3.5: 680883,
    4.5: 620466,
  },
  {
    1: 850450,
    2: 796283,
    3: 712950,
    4: 642950,
    5: 585450,
    "revenu brut": 2418000,
    trimf: 1500,
    1.5: 825450,
    2.5: 758783,
    3.5: 681283,
    4.5: 620866,
  },
  {
    1: 850850,
    2: 796683,
    3: 713350,
    4: 643350,
    5: 585850,
    "revenu brut": 2419000,
    trimf: 1500,
    1.5: 825850,
    2.5: 759183,
    3.5: 681683,
    4.5: 621266,
  },
  {
    1: 851250,
    2: 797083,
    3: 713750,
    4: 643750,
    5: 586250,
    "revenu brut": 2420000,
    trimf: 1500,
    1.5: 826250,
    2.5: 759583,
    3.5: 682083,
    4.5: 621666,
  },
  {
    1: 851650,
    2: 797483,
    3: 714150,
    4: 644150,
    5: 586650,
    "revenu brut": 2421_000,
    trimf: 1500,
    1.5: 826650,
    2.5: 759983,
    3.5: 682483,
    4.5: 622066,
  },
  {
    1: 852050,
    2: 797883,
    3: 714550,
    4: 644550,
    5: 587050,
    "revenu brut": 2422000,
    trimf: 1500,
    1.5: 827050,
    2.5: 760383,
    3.5: 682883,
    4.5: 622466,
  },
  {
    1: 852450,
    2: 798283,
    3: 714950,
    4: 644950,
    5: 587450,
    "revenu brut": 2423000,
    trimf: 1500,
    1.5: 827450,
    2.5: 760783,
    3.5: 683283,
    4.5: 622866,
  },
  {
    1: 852850,
    2: 798683,
    3: 715350,
    4: 645350,
    5: 587850,
    "revenu brut": 2424000,
    trimf: 1500,
    1.5: 827850,
    2.5: 761183,
    3.5: 683683,
    4.5: 623266,
  },
  {
    1: 853250,
    2: 799083,
    3: 715750,
    4: 645750,
    5: 588250,
    "revenu brut": 2425000,
    trimf: 1500,
    1.5: 828250,
    2.5: 761583,
    3.5: 684083,
    4.5: 623666,
  },
  {
    1: 853650,
    2: 799483,
    3: 716150,
    4: 646150,
    5: 588650,
    "revenu brut": 2426000,
    trimf: 1500,
    1.5: 828650,
    2.5: 761983,
    3.5: 684483,
    4.5: 624066,
  },
  {
    1: 854050,
    2: 799883,
    3: 716550,
    4: 646550,
    5: 589050,
    "revenu brut": 2427000,
    trimf: 1500,
    1.5: 829050,
    2.5: 762383,
    3.5: 684883,
    4.5: 624466,
  },
  {
    1: 854450,
    2: 800283,
    3: 716950,
    4: 646950,
    5: 589450,
    "revenu brut": 2428000,
    trimf: 1500,
    1.5: 829450,
    2.5: 762783,
    3.5: 685283,
    4.5: 624866,
  },
  {
    1: 854850,
    2: 800683,
    3: 717350,
    4: 647350,
    5: 589850,
    "revenu brut": 2429000,
    trimf: 1500,
    1.5: 829850,
    2.5: 763183,
    3.5: 685683,
    4.5: 625266,
  },
  {
    1: 855250,
    2: 801083,
    3: 717750,
    4: 647750,
    5: 590250,
    "revenu brut": 2430000,
    trimf: 1500,
    1.5: 830250,
    2.5: 763583,
    3.5: 686083,
    4.5: 625666,
  },
  {
    1: 855650,
    2: 801483,
    3: 718150,
    4: 648150,
    5: 590650,
    "revenu brut": 2431_000,
    trimf: 1500,
    1.5: 830650,
    2.5: 763983,
    3.5: 686483,
    4.5: 626066,
  },
  {
    1: 856050,
    2: 801883,
    3: 718550,
    4: 648550,
    5: 591050,
    "revenu brut": 2432000,
    trimf: 1500,
    1.5: 831050,
    2.5: 764383,
    3.5: 686883,
    4.5: 626466,
  },
  {
    1: 856450,
    2: 802283,
    3: 718950,
    4: 648950,
    5: 591450,
    "revenu brut": 2433000,
    trimf: 1500,
    1.5: 831450,
    2.5: 764783,
    3.5: 687283,
    4.5: 626866,
  },
  {
    1: 856850,
    2: 802683,
    3: 719350,
    4: 649350,
    5: 591850,
    "revenu brut": 2434000,
    trimf: 1500,
    1.5: 831850,
    2.5: 765183,
    3.5: 687683,
    4.5: 627266,
  },
  {
    1: 857250,
    2: 803083,
    3: 719750,
    4: 649750,
    5: 592250,
    "revenu brut": 2435000,
    trimf: 1500,
    1.5: 832250,
    2.5: 765583,
    3.5: 688083,
    4.5: 627666,
  },
  {
    1: 857650,
    2: 803483,
    3: 720150,
    4: 650150,
    5: 592650,
    "revenu brut": 2436000,
    trimf: 1500,
    1.5: 832650,
    2.5: 765983,
    3.5: 688483,
    4.5: 628066,
  },
  {
    1: 858050,
    2: 803883,
    3: 720550,
    4: 650550,
    5: 593050,
    "revenu brut": 2437000,
    trimf: 1500,
    1.5: 833050,
    2.5: 766383,
    3.5: 688883,
    4.5: 628466,
  },
  {
    1: 858450,
    2: 804283,
    3: 720950,
    4: 650950,
    5: 593450,
    "revenu brut": 2438000,
    trimf: 1500,
    1.5: 833450,
    2.5: 766783,
    3.5: 689283,
    4.5: 628866,
  },
  {
    1: 858850,
    2: 804683,
    3: 721350,
    4: 651350,
    5: 593850,
    "revenu brut": 2439000,
    trimf: 1500,
    1.5: 833850,
    2.5: 767183,
    3.5: 689683,
    4.5: 629266,
  },
  {
    1: 859250,
    2: 805083,
    3: 721750,
    4: 651750,
    5: 594250,
    "revenu brut": 2440000,
    trimf: 1500,
    1.5: 834250,
    2.5: 767583,
    3.5: 690083,
    4.5: 629666,
  },
  {
    1: 859650,
    2: 805483,
    3: 722150,
    4: 652150,
    5: 594650,
    "revenu brut": 2441_000,
    trimf: 1500,
    1.5: 834650,
    2.5: 767983,
    3.5: 690483,
    4.5: 630066,
  },
  {
    1: 860050,
    2: 805883,
    3: 722550,
    4: 652550,
    5: 595050,
    "revenu brut": 2442000,
    trimf: 1500,
    1.5: 835050,
    2.5: 768383,
    3.5: 690883,
    4.5: 630466,
  },
  {
    1: 860450,
    2: 806283,
    3: 722950,
    4: 652950,
    5: 595450,
    "revenu brut": 2443000,
    trimf: 1500,
    1.5: 835450,
    2.5: 768783,
    3.5: 691283,
    4.5: 630866,
  },
  {
    1: 860850,
    2: 806683,
    3: 723350,
    4: 653350,
    5: 595850,
    "revenu brut": 2444000,
    trimf: 1500,
    1.5: 835850,
    2.5: 769183,
    3.5: 691683,
    4.5: 631266,
  },
  {
    1: 861250,
    2: 807083,
    3: 723750,
    4: 653750,
    5: 596250,
    "revenu brut": 2445000,
    trimf: 1500,
    1.5: 836250,
    2.5: 769583,
    3.5: 692083,
    4.5: 631666,
  },
  {
    1: 861650,
    2: 807483,
    3: 724150,
    4: 654150,
    5: 596650,
    "revenu brut": 2446000,
    trimf: 1500,
    1.5: 836650,
    2.5: 769983,
    3.5: 692483,
    4.5: 632066,
  },
  {
    1: 862050,
    2: 807883,
    3: 724550,
    4: 654550,
    5: 597050,
    "revenu brut": 2447000,
    trimf: 1500,
    1.5: 837050,
    2.5: 770383,
    3.5: 692883,
    4.5: 632466,
  },
  {
    1: 862450,
    2: 808283,
    3: 724950,
    4: 654950,
    5: 597450,
    "revenu brut": 2448000,
    trimf: 1500,
    1.5: 837450,
    2.5: 770783,
    3.5: 693283,
    4.5: 632866,
  },
  {
    1: 862850,
    2: 808683,
    3: 725350,
    4: 655350,
    5: 597850,
    "revenu brut": 2449000,
    trimf: 1500,
    1.5: 837850,
    2.5: 771183,
    3.5: 693683,
    4.5: 633266,
  },
  {
    1: 863250,
    2: 809083,
    3: 725750,
    4: 655750,
    5: 598250,
    "revenu brut": 2450000,
    trimf: 1500,
    1.5: 838250,
    2.5: 771583,
    3.5: 694083,
    4.5: 633666,
  },
  {
    1: 863650,
    2: 809483,
    3: 726150,
    4: 656150,
    5: 598650,
    "revenu brut": 2451_000,
    trimf: 1500,
    1.5: 838650,
    2.5: 771983,
    3.5: 694483,
    4.5: 634066,
  },
  {
    1: 864050,
    2: 809883,
    3: 726550,
    4: 656550,
    5: 599050,
    "revenu brut": 2452000,
    trimf: 1500,
    1.5: 839050,
    2.5: 772383,
    3.5: 694883,
    4.5: 634466,
  },
  {
    1: 864450,
    2: 810283,
    3: 726950,
    4: 656950,
    5: 599450,
    "revenu brut": 2453000,
    trimf: 1500,
    1.5: 839450,
    2.5: 772783,
    3.5: 695283,
    4.5: 634866,
  },
  {
    1: 864850,
    2: 810683,
    3: 727350,
    4: 657350,
    5: 599850,
    "revenu brut": 2454000,
    trimf: 1500,
    1.5: 839850,
    2.5: 773183,
    3.5: 695683,
    4.5: 635266,
  },
  {
    1: 865250,
    2: 811083,
    3: 727750,
    4: 657750,
    5: 600250,
    "revenu brut": 2455000,
    trimf: 1500,
    1.5: 840250,
    2.5: 773583,
    3.5: 696083,
    4.5: 635666,
  },
  {
    1: 865650,
    2: 811483,
    3: 728150,
    4: 658150,
    5: 600650,
    "revenu brut": 2456000,
    trimf: 1500,
    1.5: 840650,
    2.5: 773983,
    3.5: 696483,
    4.5: 636066,
  },
  {
    1: 866050,
    2: 811883,
    3: 728550,
    4: 658550,
    5: 601050,
    "revenu brut": 2457000,
    trimf: 1500,
    1.5: 841050,
    2.5: 774383,
    3.5: 696883,
    4.5: 636466,
  },
  {
    1: 866450,
    2: 812283,
    3: 728950,
    4: 658950,
    5: 601450,
    "revenu brut": 2458000,
    trimf: 1500,
    1.5: 841450,
    2.5: 774783,
    3.5: 697283,
    4.5: 636866,
  },
  {
    1: 866850,
    2: 812683,
    3: 729350,
    4: 659350,
    5: 601850,
    "revenu brut": 2459000,
    trimf: 1500,
    1.5: 841850,
    2.5: 775183,
    3.5: 697683,
    4.5: 637266,
  },
  {
    1: 867250,
    2: 813083,
    3: 729750,
    4: 659750,
    5: 602250,
    "revenu brut": 2460000,
    trimf: 1500,
    1.5: 842250,
    2.5: 775583,
    3.5: 698083,
    4.5: 637666,
  },
  {
    1: 867650,
    2: 813483,
    3: 730150,
    4: 660150,
    5: 602650,
    "revenu brut": 2461_000,
    trimf: 1500,
    1.5: 842650,
    2.5: 775983,
    3.5: 698483,
    4.5: 638066,
  },
  {
    1: 868050,
    2: 813883,
    3: 730550,
    4: 660550,
    5: 603050,
    "revenu brut": 2462000,
    trimf: 1500,
    1.5: 843050,
    2.5: 776383,
    3.5: 698883,
    4.5: 638466,
  },
  {
    1: 868450,
    2: 814283,
    3: 730950,
    4: 660950,
    5: 603450,
    "revenu brut": 2463000,
    trimf: 1500,
    1.5: 843450,
    2.5: 776783,
    3.5: 699283,
    4.5: 638866,
  },
  {
    1: 868850,
    2: 814683,
    3: 731350,
    4: 661350,
    5: 603850,
    "revenu brut": 2464000,
    trimf: 1500,
    1.5: 843850,
    2.5: 777183,
    3.5: 699683,
    4.5: 639266,
  },
  {
    1: 869250,
    2: 815083,
    3: 731750,
    4: 661750,
    5: 604250,
    "revenu brut": 2465000,
    trimf: 1500,
    1.5: 844250,
    2.5: 777583,
    3.5: 700083,
    4.5: 639666,
  },
  {
    1: 869650,
    2: 815483,
    3: 732150,
    4: 662150,
    5: 604650,
    "revenu brut": 2466000,
    trimf: 1500,
    1.5: 844650,
    2.5: 777983,
    3.5: 700483,
    4.5: 640066,
  },
  {
    1: 870050,
    2: 815883,
    3: 732550,
    4: 662550,
    5: 605050,
    "revenu brut": 2467000,
    trimf: 1500,
    1.5: 845050,
    2.5: 778383,
    3.5: 700883,
    4.5: 640466,
  },
  {
    1: 870450,
    2: 816283,
    3: 732950,
    4: 662950,
    5: 605450,
    "revenu brut": 2468000,
    trimf: 1500,
    1.5: 845450,
    2.5: 778783,
    3.5: 701283,
    4.5: 640866,
  },
  {
    1: 870850,
    2: 816683,
    3: 733350,
    4: 663350,
    5: 605850,
    "revenu brut": 2469000,
    trimf: 1500,
    1.5: 845850,
    2.5: 779183,
    3.5: 701683,
    4.5: 641266,
  },
  {
    1: 871250,
    2: 817083,
    3: 733750,
    4: 663750,
    5: 606250,
    "revenu brut": 2470000,
    trimf: 1500,
    1.5: 846250,
    2.5: 779583,
    3.5: 702083,
    4.5: 641666,
  },
  {
    1: 871650,
    2: 817483,
    3: 734150,
    4: 664150,
    5: 606650,
    "revenu brut": 2471_000,
    trimf: 1500,
    1.5: 846650,
    2.5: 779983,
    3.5: 702483,
    4.5: 642066,
  },
  {
    1: 872050,
    2: 817883,
    3: 734550,
    4: 664550,
    5: 607050,
    "revenu brut": 2472000,
    trimf: 1500,
    1.5: 847050,
    2.5: 780383,
    3.5: 702883,
    4.5: 642466,
  },
  {
    1: 872450,
    2: 818283,
    3: 734950,
    4: 664950,
    5: 607450,
    "revenu brut": 2473000,
    trimf: 1500,
    1.5: 847450,
    2.5: 780783,
    3.5: 703283,
    4.5: 642866,
  },
  {
    1: 872850,
    2: 818683,
    3: 735350,
    4: 665350,
    5: 607850,
    "revenu brut": 2474000,
    trimf: 1500,
    1.5: 847850,
    2.5: 781183,
    3.5: 703683,
    4.5: 643266,
  },
  {
    1: 873250,
    2: 819083,
    3: 735750,
    4: 665750,
    5: 608250,
    "revenu brut": 2475000,
    trimf: 1500,
    1.5: 848250,
    2.5: 781583,
    3.5: 704083,
    4.5: 643666,
  },
  {
    1: 873650,
    2: 819483,
    3: 736150,
    4: 666150,
    5: 608650,
    "revenu brut": 2476000,
    trimf: 1500,
    1.5: 848650,
    2.5: 781983,
    3.5: 704483,
    4.5: 644066,
  },
  {
    1: 874050,
    2: 819883,
    3: 736550,
    4: 666550,
    5: 609050,
    "revenu brut": 2477000,
    trimf: 1500,
    1.5: 849050,
    2.5: 782383,
    3.5: 704883,
    4.5: 644466,
  },
  {
    1: 874450,
    2: 820283,
    3: 736950,
    4: 666950,
    5: 609450,
    "revenu brut": 2478000,
    trimf: 1500,
    1.5: 849450,
    2.5: 782783,
    3.5: 705283,
    4.5: 644866,
  },
  {
    1: 874850,
    2: 820683,
    3: 737350,
    4: 667350,
    5: 609850,
    "revenu brut": 2479000,
    trimf: 1500,
    1.5: 849850,
    2.5: 783183,
    3.5: 705683,
    4.5: 645266,
  },
  {
    1: 875250,
    2: 821083,
    3: 737750,
    4: 667750,
    5: 610250,
    "revenu brut": 2480000,
    trimf: 1500,
    1.5: 850250,
    2.5: 783583,
    3.5: 706083,
    4.5: 645666,
  },
  {
    1: 875650,
    2: 821483,
    3: 738150,
    4: 668150,
    5: 610650,
    "revenu brut": 2481_000,
    trimf: 1500,
    1.5: 850650,
    2.5: 783983,
    3.5: 706483,
    4.5: 646066,
  },
  {
    1: 876050,
    2: 821883,
    3: 738550,
    4: 668550,
    5: 611050,
    "revenu brut": 2482000,
    trimf: 1500,
    1.5: 851050,
    2.5: 784383,
    3.5: 706883,
    4.5: 646466,
  },
  {
    1: 876450,
    2: 822283,
    3: 738950,
    4: 668950,
    5: 611450,
    "revenu brut": 2483000,
    trimf: 1500,
    1.5: 851450,
    2.5: 784783,
    3.5: 707283,
    4.5: 646866,
  },
  {
    1: 876850,
    2: 822683,
    3: 739350,
    4: 669350,
    5: 611850,
    "revenu brut": 2484000,
    trimf: 1500,
    1.5: 851850,
    2.5: 785183,
    3.5: 707683,
    4.5: 647266,
  },
  {
    1: 877250,
    2: 823083,
    3: 739750,
    4: 669750,
    5: 612250,
    "revenu brut": 2485000,
    trimf: 1500,
    1.5: 852250,
    2.5: 785583,
    3.5: 708083,
    4.5: 647666,
  },
  {
    1: 877650,
    2: 823483,
    3: 740150,
    4: 670150,
    5: 612650,
    "revenu brut": 2486000,
    trimf: 1500,
    1.5: 852650,
    2.5: 785983,
    3.5: 708483,
    4.5: 648066,
  },
  {
    1: 878050,
    2: 823883,
    3: 740550,
    4: 670550,
    5: 613050,
    "revenu brut": 2487000,
    trimf: 1500,
    1.5: 853050,
    2.5: 786383,
    3.5: 708883,
    4.5: 648466,
  },
  {
    1: 878450,
    2: 824283,
    3: 740950,
    4: 670950,
    5: 613450,
    "revenu brut": 2488000,
    trimf: 1500,
    1.5: 853450,
    2.5: 786783,
    3.5: 709283,
    4.5: 648866,
  },
  {
    1: 878850,
    2: 824683,
    3: 741350,
    4: 671350,
    5: 613850,
    "revenu brut": 2489000,
    trimf: 1500,
    1.5: 853850,
    2.5: 787183,
    3.5: 709683,
    4.5: 649266,
  },
  {
    1: 879250,
    2: 825083,
    3: 741750,
    4: 671750,
    5: 614250,
    "revenu brut": 2490000,
    trimf: 1500,
    1.5: 854250,
    2.5: 787583,
    3.5: 710083,
    4.5: 649666,
  },
  {
    1: 879650,
    2: 825483,
    3: 742150,
    4: 672150,
    5: 614650,
    "revenu brut": 2491_000,
    trimf: 1500,
    1.5: 854650,
    2.5: 787983,
    3.5: 710483,
    4.5: 650066,
  },
  {
    1: 880050,
    2: 825883,
    3: 742550,
    4: 672550,
    5: 615050,
    "revenu brut": 2492000,
    trimf: 1500,
    1.5: 855050,
    2.5: 788383,
    3.5: 710883,
    4.5: 650466,
  },
  {
    1: 880450,
    2: 826283,
    3: 742950,
    4: 672950,
    5: 615450,
    "revenu brut": 2493000,
    trimf: 1500,
    1.5: 855450,
    2.5: 788783,
    3.5: 711283,
    4.5: 650866,
  },
  {
    1: 880850,
    2: 826683,
    3: 743350,
    4: 673350,
    5: 615850,
    "revenu brut": 2494000,
    trimf: 1500,
    1.5: 855850,
    2.5: 789183,
    3.5: 711683,
    4.5: 651266,
  },
  {
    1: 881250,
    2: 827083,
    3: 743750,
    4: 673750,
    5: 616250,
    "revenu brut": 2495000,
    trimf: 1500,
    1.5: 856250,
    2.5: 789583,
    3.5: 712083,
    4.5: 651666,
  },
  {
    1: 881650,
    2: 827483,
    3: 744150,
    4: 674150,
    5: 616650,
    "revenu brut": 2496000,
    trimf: 1500,
    1.5: 856650,
    2.5: 789983,
    3.5: 712483,
    4.5: 652066,
  },
  {
    1: 882050,
    2: 827883,
    3: 744550,
    4: 674550,
    5: 617050,
    "revenu brut": 2497000,
    trimf: 1500,
    1.5: 857050,
    2.5: 790383,
    3.5: 712883,
    4.5: 652466,
  },
  {
    1: 882450,
    2: 828283,
    3: 744950,
    4: 674950,
    5: 617450,
    "revenu brut": 2498000,
    trimf: 1500,
    1.5: 857450,
    2.5: 790783,
    3.5: 713283,
    4.5: 652866,
  },
  {
    1: 882850,
    2: 828683,
    3: 745350,
    4: 675350,
    5: 617850,
    "revenu brut": 2499000,
    trimf: 1500,
    1.5: 857850,
    2.5: 791183,
    3.5: 713683,
    4.5: 653266,
  },
  {
    1: 883250,
    2: 829083,
    3: 745750,
    4: 675750,
    5: 618250,
    "revenu brut": 2500000,
    trimf: 1500,
    1.5: 858250,
    2.5: 791583,
    3.5: 714083,
    4.5: 653666,
  },
  {
    1: 883650,
    2: 829483,
    3: 746150,
    4: 676150,
    5: 618650,
    "revenu brut": 2501_000,
    trimf: 1500,
    1.5: 858650,
    2.5: 791983,
    3.5: 714483,
    4.5: 654066,
  },
  {
    1: 884050,
    2: 829883,
    3: 746550,
    4: 676550,
    5: 619050,
    "revenu brut": 2502000,
    trimf: 1500,
    1.5: 859050,
    2.5: 792383,
    3.5: 714883,
    4.5: 654466,
  },
  {
    1: 884450,
    2: 830283,
    3: 746950,
    4: 676950,
    5: 619450,
    "revenu brut": 2503000,
    trimf: 1500,
    1.5: 859450,
    2.5: 792783,
    3.5: 715283,
    4.5: 654866,
  },
  {
    1: 884850,
    2: 830683,
    3: 747350,
    4: 677350,
    5: 619850,
    "revenu brut": 2504000,
    trimf: 1500,
    1.5: 859850,
    2.5: 793183,
    3.5: 715683,
    4.5: 655266,
  },
  {
    1: 885250,
    2: 831083,
    3: 747750,
    4: 677750,
    5: 620250,
    "revenu brut": 2505000,
    trimf: 1500,
    1.5: 860250,
    2.5: 793583,
    3.5: 716083,
    4.5: 655666,
  },
  {
    1: 885650,
    2: 831483,
    3: 748150,
    4: 678150,
    5: 620650,
    "revenu brut": 2506000,
    trimf: 1500,
    1.5: 860650,
    2.5: 793983,
    3.5: 716483,
    4.5: 656066,
  },
  {
    1: 886050,
    2: 831883,
    3: 748550,
    4: 678550,
    5: 621050,
    "revenu brut": 2507000,
    trimf: 1500,
    1.5: 861050,
    2.5: 794383,
    3.5: 716883,
    4.5: 656466,
  },
  {
    1: 886450,
    2: 832283,
    3: 748950,
    4: 678950,
    5: 621450,
    "revenu brut": 2508000,
    trimf: 1500,
    1.5: 861450,
    2.5: 794783,
    3.5: 717283,
    4.5: 656866,
  },
  {
    1: 886850,
    2: 832683,
    3: 749350,
    4: 679350,
    5: 621850,
    "revenu brut": 2509000,
    trimf: 1500,
    1.5: 861850,
    2.5: 795183,
    3.5: 717683,
    4.5: 657266,
  },
  {
    1: 887250,
    2: 833083,
    3: 749750,
    4: 679750,
    5: 622250,
    "revenu brut": 251_0000,
    trimf: 1500,
    1.5: 862250,
    2.5: 795583,
    3.5: 718083,
    4.5: 657666,
  },
  {
    1: 887650,
    2: 833483,
    3: 750150,
    4: 680150,
    5: 622650,
    "revenu brut": 2511_000,
    trimf: 1500,
    1.5: 862650,
    2.5: 795983,
    3.5: 718483,
    4.5: 658066,
  },
  {
    1: 888050,
    2: 833883,
    3: 750550,
    4: 680550,
    5: 623050,
    "revenu brut": 2512000,
    trimf: 1500,
    1.5: 863050,
    2.5: 796383,
    3.5: 718883,
    4.5: 658466,
  },
  {
    1: 888450,
    2: 834283,
    3: 750950,
    4: 680950,
    5: 623450,
    "revenu brut": 2513000,
    trimf: 1500,
    1.5: 863450,
    2.5: 796783,
    3.5: 719283,
    4.5: 658866,
  },
  {
    1: 888850,
    2: 834683,
    3: 751350,
    4: 681350,
    5: 623850,
    "revenu brut": 2514000,
    trimf: 1500,
    1.5: 863850,
    2.5: 797183,
    3.5: 719683,
    4.5: 659266,
  },
  {
    1: 889250,
    2: 835083,
    3: 751750,
    4: 681750,
    5: 624250,
    "revenu brut": 2515000,
    trimf: 1500,
    1.5: 864250,
    2.5: 797583,
    3.5: 720083,
    4.5: 659666,
  },
  {
    1: 889650,
    2: 835483,
    3: 752150,
    4: 682150,
    5: 624650,
    "revenu brut": 2516000,
    trimf: 1500,
    1.5: 864650,
    2.5: 797983,
    3.5: 720483,
    4.5: 660066,
  },
  {
    1: 890050,
    2: 835883,
    3: 752550,
    4: 682550,
    5: 625050,
    "revenu brut": 2517000,
    trimf: 1500,
    1.5: 865050,
    2.5: 798383,
    3.5: 720883,
    4.5: 660466,
  },
  {
    1: 890450,
    2: 836283,
    3: 752950,
    4: 682950,
    5: 625450,
    "revenu brut": 2518000,
    trimf: 1500,
    1.5: 865450,
    2.5: 798783,
    3.5: 721283,
    4.5: 660866,
  },
  {
    1: 890850,
    2: 836683,
    3: 753350,
    4: 683350,
    5: 625850,
    "revenu brut": 2519000,
    trimf: 1500,
    1.5: 865850,
    2.5: 799183,
    3.5: 721683,
    4.5: 661266,
  },
  {
    1: 891250,
    2: 837083,
    3: 753750,
    4: 683750,
    5: 626250,
    "revenu brut": 2520000,
    trimf: 1500,
    1.5: 866250,
    2.5: 799583,
    3.5: 722083,
    4.5: 661666,
  },
  {
    1: 891650,
    2: 837483,
    3: 754150,
    4: 684150,
    5: 626650,
    "revenu brut": 2521_000,
    trimf: 1500,
    1.5: 866650,
    2.5: 799983,
    3.5: 722483,
    4.5: 662066,
  },
  {
    1: 892050,
    2: 837883,
    3: 754550,
    4: 684550,
    5: 627050,
    "revenu brut": 2522000,
    trimf: 1500,
    1.5: 867050,
    2.5: 800383,
    3.5: 722883,
    4.5: 662466,
  },
  {
    1: 892450,
    2: 838283,
    3: 754950,
    4: 684950,
    5: 627450,
    "revenu brut": 2523000,
    trimf: 1500,
    1.5: 867450,
    2.5: 800783,
    3.5: 723283,
    4.5: 662866,
  },
  {
    1: 892850,
    2: 838683,
    3: 755350,
    4: 685350,
    5: 627850,
    "revenu brut": 2524000,
    trimf: 1500,
    1.5: 867850,
    2.5: 801183,
    3.5: 723683,
    4.5: 663266,
  },
  {
    1: 893250,
    2: 839083,
    3: 755750,
    4: 685750,
    5: 628250,
    "revenu brut": 2525000,
    trimf: 1500,
    1.5: 868250,
    2.5: 801583,
    3.5: 724083,
    4.5: 663666,
  },
  {
    1: 893650,
    2: 839483,
    3: 756150,
    4: 686150,
    5: 628650,
    "revenu brut": 2526000,
    trimf: 1500,
    1.5: 868650,
    2.5: 801983,
    3.5: 724483,
    4.5: 664066,
  },
  {
    1: 894050,
    2: 839883,
    3: 756550,
    4: 686550,
    5: 629050,
    "revenu brut": 2527000,
    trimf: 1500,
    1.5: 869050,
    2.5: 802383,
    3.5: 724883,
    4.5: 664466,
  },
  {
    1: 894450,
    2: 840283,
    3: 756950,
    4: 686950,
    5: 629450,
    "revenu brut": 2528000,
    trimf: 1500,
    1.5: 869450,
    2.5: 802783,
    3.5: 725283,
    4.5: 664866,
  },
  {
    1: 894850,
    2: 840683,
    3: 757350,
    4: 687350,
    5: 629850,
    "revenu brut": 2529000,
    trimf: 1500,
    1.5: 869850,
    2.5: 803183,
    3.5: 725683,
    4.5: 665266,
  },
  {
    1: 895250,
    2: 841083,
    3: 757750,
    4: 687750,
    5: 630250,
    "revenu brut": 2530000,
    trimf: 1500,
    1.5: 870250,
    2.5: 803583,
    3.5: 726083,
    4.5: 665666,
  },
  {
    1: 895650,
    2: 841483,
    3: 758150,
    4: 688150,
    5: 630650,
    "revenu brut": 2531_000,
    trimf: 1500,
    1.5: 870650,
    2.5: 803983,
    3.5: 726483,
    4.5: 666066,
  },
  {
    1: 896050,
    2: 841883,
    3: 758550,
    4: 688550,
    5: 631050,
    "revenu brut": 2532000,
    trimf: 1500,
    1.5: 871050,
    2.5: 804383,
    3.5: 726883,
    4.5: 666466,
  },
  {
    1: 896450,
    2: 842283,
    3: 758950,
    4: 688950,
    5: 631450,
    "revenu brut": 2533000,
    trimf: 1500,
    1.5: 871450,
    2.5: 804783,
    3.5: 727283,
    4.5: 666866,
  },
  {
    1: 896850,
    2: 842683,
    3: 759350,
    4: 689350,
    5: 631850,
    "revenu brut": 2534000,
    trimf: 1500,
    1.5: 871850,
    2.5: 805183,
    3.5: 727683,
    4.5: 667266,
  },
  {
    1: 897250,
    2: 843083,
    3: 759750,
    4: 689750,
    5: 632250,
    "revenu brut": 2535000,
    trimf: 1500,
    1.5: 872250,
    2.5: 805583,
    3.5: 728083,
    4.5: 667666,
  },
  {
    1: 897650,
    2: 843483,
    3: 760150,
    4: 690150,
    5: 632650,
    "revenu brut": 2536000,
    trimf: 1500,
    1.5: 872650,
    2.5: 805983,
    3.5: 728483,
    4.5: 668066,
  },
  {
    1: 898050,
    2: 843883,
    3: 760550,
    4: 690550,
    5: 633050,
    "revenu brut": 2537000,
    trimf: 1500,
    1.5: 873050,
    2.5: 806383,
    3.5: 728883,
    4.5: 668466,
  },
  {
    1: 898450,
    2: 844283,
    3: 760950,
    4: 690950,
    5: 633450,
    "revenu brut": 2538000,
    trimf: 1500,
    1.5: 873450,
    2.5: 806783,
    3.5: 729283,
    4.5: 668866,
  },
  {
    1: 898850,
    2: 844683,
    3: 761350,
    4: 691350,
    5: 633850,
    "revenu brut": 2539000,
    trimf: 1500,
    1.5: 873850,
    2.5: 807183,
    3.5: 729683,
    4.5: 669266,
  },
  {
    1: 899250,
    2: 845083,
    3: 761750,
    4: 691750,
    5: 634250,
    "revenu brut": 2540000,
    trimf: 1500,
    1.5: 874250,
    2.5: 807583,
    3.5: 730083,
    4.5: 669666,
  },
  {
    1: 899650,
    2: 845483,
    3: 762150,
    4: 692150,
    5: 634650,
    "revenu brut": 2541_000,
    trimf: 1500,
    1.5: 874650,
    2.5: 807983,
    3.5: 730483,
    4.5: 670066,
  },
  {
    1: 900050,
    2: 845883,
    3: 762550,
    4: 692550,
    5: 635050,
    "revenu brut": 2542000,
    trimf: 1500,
    1.5: 875050,
    2.5: 808383,
    3.5: 730883,
    4.5: 670466,
  },
  {
    1: 900450,
    2: 846283,
    3: 762950,
    4: 692950,
    5: 635450,
    "revenu brut": 2543000,
    trimf: 1500,
    1.5: 875450,
    2.5: 808783,
    3.5: 731283,
    4.5: 670866,
  },
  {
    1: 900850,
    2: 846683,
    3: 763350,
    4: 693350,
    5: 635850,
    "revenu brut": 2544000,
    trimf: 1500,
    1.5: 875850,
    2.5: 809183,
    3.5: 731683,
    4.5: 671266,
  },
  {
    1: 901250,
    2: 847083,
    3: 763750,
    4: 693750,
    5: 636250,
    "revenu brut": 2545000,
    trimf: 1500,
    1.5: 876250,
    2.5: 809583,
    3.5: 732083,
    4.5: 671666,
  },
  {
    1: 901650,
    2: 847483,
    3: 764150,
    4: 694150,
    5: 636650,
    "revenu brut": 2546000,
    trimf: 1500,
    1.5: 876650,
    2.5: 809983,
    3.5: 732483,
    4.5: 672066,
  },
  {
    1: 902050,
    2: 847883,
    3: 764550,
    4: 694550,
    5: 637050,
    "revenu brut": 2547000,
    trimf: 1500,
    1.5: 877050,
    2.5: 810383,
    3.5: 732883,
    4.5: 672466,
  },
  {
    1: 902450,
    2: 848283,
    3: 764950,
    4: 694950,
    5: 637450,
    "revenu brut": 2548000,
    trimf: 1500,
    1.5: 877450,
    2.5: 810783,
    3.5: 733283,
    4.5: 672866,
  },
  {
    1: 902850,
    2: 848683,
    3: 765350,
    4: 695350,
    5: 637850,
    "revenu brut": 2549000,
    trimf: 1500,
    1.5: 877850,
    2.5: 811183,
    3.5: 733683,
    4.5: 673266,
  },
  {
    1: 903250,
    2: 849083,
    3: 765750,
    4: 695750,
    5: 638250,
    "revenu brut": 2550000,
    trimf: 1500,
    1.5: 878250,
    2.5: 811583,
    3.5: 734083,
    4.5: 673666,
  },
  {
    1: 903650,
    2: 849483,
    3: 766150,
    4: 696150,
    5: 638650,
    "revenu brut": 2551_000,
    trimf: 1500,
    1.5: 878650,
    2.5: 811983,
    3.5: 734483,
    4.5: 674066,
  },
  {
    1: 904050,
    2: 849883,
    3: 766550,
    4: 696550,
    5: 639050,
    "revenu brut": 2552000,
    trimf: 1500,
    1.5: 879050,
    2.5: 812383,
    3.5: 734883,
    4.5: 674466,
  },
  {
    1: 904450,
    2: 850283,
    3: 766950,
    4: 696950,
    5: 639450,
    "revenu brut": 2553000,
    trimf: 1500,
    1.5: 879450,
    2.5: 812783,
    3.5: 735283,
    4.5: 674866,
  },
  {
    1: 904850,
    2: 850683,
    3: 767350,
    4: 697350,
    5: 639850,
    "revenu brut": 2554000,
    trimf: 1500,
    1.5: 879850,
    2.5: 813183,
    3.5: 735683,
    4.5: 675266,
  },
  {
    1: 905250,
    2: 851083,
    3: 767750,
    4: 697750,
    5: 640250,
    "revenu brut": 2555000,
    trimf: 1500,
    1.5: 880250,
    2.5: 813583,
    3.5: 736083,
    4.5: 675666,
  },
  {
    1: 905650,
    2: 851483,
    3: 768150,
    4: 698150,
    5: 640650,
    "revenu brut": 2556000,
    trimf: 1500,
    1.5: 880650,
    2.5: 813983,
    3.5: 736483,
    4.5: 676066,
  },
  {
    1: 906050,
    2: 851883,
    3: 768550,
    4: 698550,
    5: 641050,
    "revenu brut": 2557000,
    trimf: 1500,
    1.5: 881050,
    2.5: 814383,
    3.5: 736883,
    4.5: 676466,
  },
  {
    1: 906450,
    2: 852283,
    3: 768950,
    4: 698950,
    5: 641450,
    "revenu brut": 2558000,
    trimf: 1500,
    1.5: 881450,
    2.5: 814783,
    3.5: 737283,
    4.5: 676866,
  },
  {
    1: 906850,
    2: 852683,
    3: 769350,
    4: 699350,
    5: 641850,
    "revenu brut": 2559000,
    trimf: 1500,
    1.5: 881850,
    2.5: 815183,
    3.5: 737683,
    4.5: 677266,
  },
  {
    1: 907250,
    2: 853083,
    3: 769750,
    4: 699750,
    5: 642250,
    "revenu brut": 2560000,
    trimf: 1500,
    1.5: 882250,
    2.5: 815583,
    3.5: 738083,
    4.5: 677666,
  },
  {
    1: 907650,
    2: 853483,
    3: 770150,
    4: 700150,
    5: 642650,
    "revenu brut": 2561_000,
    trimf: 1500,
    1.5: 882650,
    2.5: 815983,
    3.5: 738483,
    4.5: 678066,
  },
  {
    1: 908050,
    2: 853883,
    3: 770550,
    4: 700550,
    5: 643050,
    "revenu brut": 2562000,
    trimf: 1500,
    1.5: 883050,
    2.5: 816383,
    3.5: 738883,
    4.5: 678466,
  },
  {
    1: 908450,
    2: 854283,
    3: 770950,
    4: 700950,
    5: 643450,
    "revenu brut": 2563000,
    trimf: 1500,
    1.5: 883450,
    2.5: 816783,
    3.5: 739283,
    4.5: 678866,
  },
  {
    1: 908850,
    2: 854683,
    3: 771350,
    4: 701350,
    5: 643850,
    "revenu brut": 2564000,
    trimf: 1500,
    1.5: 883850,
    2.5: 817183,
    3.5: 739683,
    4.5: 679266,
  },
  {
    1: 909250,
    2: 855083,
    3: 771750,
    4: 701750,
    5: 644250,
    "revenu brut": 2565000,
    trimf: 1500,
    1.5: 884250,
    2.5: 817583,
    3.5: 740083,
    4.5: 679666,
  },
  {
    1: 909650,
    2: 855483,
    3: 772150,
    4: 702150,
    5: 644650,
    "revenu brut": 2566000,
    trimf: 1500,
    1.5: 884650,
    2.5: 817983,
    3.5: 740483,
    4.5: 680066,
  },
  {
    1: 910050,
    2: 855883,
    3: 772550,
    4: 702550,
    5: 645050,
    "revenu brut": 2567000,
    trimf: 1500,
    1.5: 885050,
    2.5: 818383,
    3.5: 740883,
    4.5: 680466,
  },
  {
    1: 910450,
    2: 856283,
    3: 772950,
    4: 702950,
    5: 645450,
    "revenu brut": 2568000,
    trimf: 1500,
    1.5: 885450,
    2.5: 818783,
    3.5: 741283,
    4.5: 680866,
  },
  {
    1: 910850,
    2: 856683,
    3: 773350,
    4: 703350,
    5: 645850,
    "revenu brut": 2569000,
    trimf: 1500,
    1.5: 885850,
    2.5: 819183,
    3.5: 741683,
    4.5: 681266,
  },
  {
    1: 911250,
    2: 857083,
    3: 773750,
    4: 703750,
    5: 646250,
    "revenu brut": 2570000,
    trimf: 1500,
    1.5: 886250,
    2.5: 819583,
    3.5: 742083,
    4.5: 681666,
  },
  {
    1: 911650,
    2: 857483,
    3: 774150,
    4: 704150,
    5: 646650,
    "revenu brut": 2571_000,
    trimf: 1500,
    1.5: 886650,
    2.5: 819983,
    3.5: 742483,
    4.5: 682066,
  },
  {
    1: 912050,
    2: 857883,
    3: 774550,
    4: 704550,
    5: 647050,
    "revenu brut": 2572000,
    trimf: 1500,
    1.5: 887050,
    2.5: 820383,
    3.5: 742883,
    4.5: 682466,
  },
  {
    1: 912450,
    2: 858283,
    3: 774950,
    4: 704950,
    5: 647450,
    "revenu brut": 2573000,
    trimf: 1500,
    1.5: 887450,
    2.5: 820783,
    3.5: 743283,
    4.5: 682866,
  },
  {
    1: 912850,
    2: 858683,
    3: 775350,
    4: 705350,
    5: 647850,
    "revenu brut": 2574000,
    trimf: 1500,
    1.5: 887850,
    2.5: 821183,
    3.5: 743683,
    4.5: 683266,
  },
  {
    1: 913250,
    2: 859083,
    3: 775750,
    4: 705750,
    5: 648250,
    "revenu brut": 2575000,
    trimf: 1500,
    1.5: 888250,
    2.5: 821583,
    3.5: 744083,
    4.5: 683666,
  },
  {
    1: 913650,
    2: 859483,
    3: 776150,
    4: 706150,
    5: 648650,
    "revenu brut": 2576000,
    trimf: 1500,
    1.5: 888650,
    2.5: 821983,
    3.5: 744483,
    4.5: 684066,
  },
  {
    1: 914050,
    2: 859883,
    3: 776550,
    4: 706550,
    5: 649050,
    "revenu brut": 2577000,
    trimf: 1500,
    1.5: 889050,
    2.5: 822383,
    3.5: 744883,
    4.5: 684466,
  },
  {
    1: 914450,
    2: 860283,
    3: 776950,
    4: 706950,
    5: 649450,
    "revenu brut": 2578000,
    trimf: 1500,
    1.5: 889450,
    2.5: 822783,
    3.5: 745283,
    4.5: 684866,
  },
  {
    1: 914850,
    2: 860683,
    3: 777350,
    4: 707350,
    5: 649850,
    "revenu brut": 2579000,
    trimf: 1500,
    1.5: 889850,
    2.5: 823183,
    3.5: 745683,
    4.5: 685266,
  },
  {
    1: 915250,
    2: 861083,
    3: 777750,
    4: 707750,
    5: 650250,
    "revenu brut": 2580000,
    trimf: 1500,
    1.5: 890250,
    2.5: 823583,
    3.5: 746083,
    4.5: 685666,
  },
  {
    1: 915650,
    2: 861483,
    3: 778150,
    4: 708150,
    5: 650650,
    "revenu brut": 2581_000,
    trimf: 1500,
    1.5: 890650,
    2.5: 823983,
    3.5: 746483,
    4.5: 686066,
  },
  {
    1: 916050,
    2: 861883,
    3: 778550,
    4: 708550,
    5: 651050,
    "revenu brut": 2582000,
    trimf: 1500,
    1.5: 891050,
    2.5: 824383,
    3.5: 746883,
    4.5: 686466,
  },
  {
    1: 916450,
    2: 862283,
    3: 778950,
    4: 708950,
    5: 651450,
    "revenu brut": 2583000,
    trimf: 1500,
    1.5: 891450,
    2.5: 824783,
    3.5: 747283,
    4.5: 686866,
  },
  {
    1: 916850,
    2: 862683,
    3: 779350,
    4: 709350,
    5: 651850,
    "revenu brut": 2584000,
    trimf: 1500,
    1.5: 891850,
    2.5: 825183,
    3.5: 747683,
    4.5: 687266,
  },
  {
    1: 917250,
    2: 863083,
    3: 779750,
    4: 709750,
    5: 652250,
    "revenu brut": 2585000,
    trimf: 1500,
    1.5: 892250,
    2.5: 825583,
    3.5: 748083,
    4.5: 687666,
  },
  {
    1: 917650,
    2: 863483,
    3: 780150,
    4: 710150,
    5: 652650,
    "revenu brut": 2586000,
    trimf: 1500,
    1.5: 892650,
    2.5: 825983,
    3.5: 748483,
    4.5: 688066,
  },
  {
    1: 918050,
    2: 863883,
    3: 780550,
    4: 710550,
    5: 653050,
    "revenu brut": 2587000,
    trimf: 1500,
    1.5: 893050,
    2.5: 826383,
    3.5: 748883,
    4.5: 688466,
  },
  {
    1: 918450,
    2: 864283,
    3: 780950,
    4: 710950,
    5: 653450,
    "revenu brut": 2588000,
    trimf: 1500,
    1.5: 893450,
    2.5: 826783,
    3.5: 749283,
    4.5: 688866,
  },
  {
    1: 918850,
    2: 864683,
    3: 781350,
    4: 711350,
    5: 653850,
    "revenu brut": 2589000,
    trimf: 1500,
    1.5: 893850,
    2.5: 827183,
    3.5: 749683,
    4.5: 689266,
  },
  {
    1: 919250,
    2: 865083,
    3: 781750,
    4: 711750,
    5: 654250,
    "revenu brut": 2590000,
    trimf: 1500,
    1.5: 894250,
    2.5: 827583,
    3.5: 750083,
    4.5: 689666,
  },
  {
    1: 919650,
    2: 865483,
    3: 782150,
    4: 712150,
    5: 654650,
    "revenu brut": 2591_000,
    trimf: 1500,
    1.5: 894650,
    2.5: 827983,
    3.5: 750483,
    4.5: 690066,
  },
  {
    1: 920050,
    2: 865883,
    3: 782550,
    4: 712550,
    5: 655050,
    "revenu brut": 2592000,
    trimf: 1500,
    1.5: 895050,
    2.5: 828383,
    3.5: 750883,
    4.5: 690466,
  },
  {
    1: 920450,
    2: 866283,
    3: 782950,
    4: 712950,
    5: 655450,
    "revenu brut": 2593000,
    trimf: 1500,
    1.5: 895450,
    2.5: 828783,
    3.5: 751283,
    4.5: 690866,
  },
  {
    1: 920850,
    2: 866683,
    3: 783350,
    4: 713350,
    5: 655850,
    "revenu brut": 2594000,
    trimf: 1500,
    1.5: 895850,
    2.5: 829183,
    3.5: 751683,
    4.5: 691266,
  },
  {
    1: 921250,
    2: 867083,
    3: 783750,
    4: 713750,
    5: 656250,
    "revenu brut": 2595000,
    trimf: 1500,
    1.5: 896250,
    2.5: 829583,
    3.5: 752083,
    4.5: 691666,
  },
  {
    1: 921650,
    2: 867483,
    3: 784150,
    4: 714150,
    5: 656650,
    "revenu brut": 2596000,
    trimf: 1500,
    1.5: 896650,
    2.5: 829983,
    3.5: 752483,
    4.5: 692066,
  },
  {
    1: 922050,
    2: 867883,
    3: 784550,
    4: 714550,
    5: 657050,
    "revenu brut": 2597000,
    trimf: 1500,
    1.5: 897050,
    2.5: 830383,
    3.5: 752883,
    4.5: 692466,
  },
  {
    1: 922450,
    2: 868283,
    3: 784950,
    4: 714950,
    5: 657450,
    "revenu brut": 2598000,
    trimf: 1500,
    1.5: 897450,
    2.5: 830783,
    3.5: 753283,
    4.5: 692866,
  },
  {
    1: 922850,
    2: 868683,
    3: 785350,
    4: 715350,
    5: 657850,
    "revenu brut": 2599000,
    trimf: 1500,
    1.5: 897850,
    2.5: 831183,
    3.5: 753683,
    4.5: 693266,
  },
  {
    1: 923250,
    2: 869083,
    3: 785750,
    4: 715750,
    5: 658250,
    "revenu brut": 2600000,
    trimf: 1500,
    1.5: 898250,
    2.5: 831583,
    3.5: 754083,
    4.5: 693666,
  },
  {
    1: 923650,
    2: 869483,
    3: 786150,
    4: 716150,
    5: 658650,
    "revenu brut": 2601_000,
    trimf: 1500,
    1.5: 898650,
    2.5: 831983,
    3.5: 754483,
    4.5: 694066,
  },
  {
    1: 924050,
    2: 869883,
    3: 786550,
    4: 716550,
    5: 659050,
    "revenu brut": 2602000,
    trimf: 1500,
    1.5: 899050,
    2.5: 832383,
    3.5: 754883,
    4.5: 694466,
  },
  {
    1: 924450,
    2: 870283,
    3: 786950,
    4: 716950,
    5: 659450,
    "revenu brut": 2603000,
    trimf: 1500,
    1.5: 899450,
    2.5: 832783,
    3.5: 755283,
    4.5: 694866,
  },
  {
    1: 924850,
    2: 870683,
    3: 787350,
    4: 717350,
    5: 659850,
    "revenu brut": 2604000,
    trimf: 1500,
    1.5: 899850,
    2.5: 833183,
    3.5: 755683,
    4.5: 695266,
  },
  {
    1: 925250,
    2: 871083,
    3: 787750,
    4: 717750,
    5: 660250,
    "revenu brut": 2605000,
    trimf: 1500,
    1.5: 900250,
    2.5: 833583,
    3.5: 756083,
    4.5: 695666,
  },
  {
    1: 925650,
    2: 871483,
    3: 788150,
    4: 718150,
    5: 660650,
    "revenu brut": 2606000,
    trimf: 1500,
    1.5: 900650,
    2.5: 833983,
    3.5: 756483,
    4.5: 696066,
  },
  {
    1: 926050,
    2: 871883,
    3: 788550,
    4: 718550,
    5: 661050,
    "revenu brut": 2607000,
    trimf: 1500,
    1.5: 901050,
    2.5: 834383,
    3.5: 756883,
    4.5: 696466,
  },
  {
    1: 926450,
    2: 872283,
    3: 788950,
    4: 718950,
    5: 661450,
    "revenu brut": 2608000,
    trimf: 1500,
    1.5: 901450,
    2.5: 834783,
    3.5: 757283,
    4.5: 696866,
  },
  {
    1: 926850,
    2: 872683,
    3: 789350,
    4: 719350,
    5: 661850,
    "revenu brut": 2609000,
    trimf: 1500,
    1.5: 901850,
    2.5: 835183,
    3.5: 757683,
    4.5: 697266,
  },
  {
    1: 927250,
    2: 873083,
    3: 789750,
    4: 719750,
    5: 662250,
    "revenu brut": 261_0000,
    trimf: 1500,
    1.5: 902250,
    2.5: 835583,
    3.5: 758083,
    4.5: 697666,
  },
  {
    1: 927650,
    2: 873483,
    3: 790150,
    4: 720150,
    5: 662650,
    "revenu brut": 2611_000,
    trimf: 1500,
    1.5: 902650,
    2.5: 835983,
    3.5: 758483,
    4.5: 698066,
  },
  {
    1: 928050,
    2: 873883,
    3: 790550,
    4: 720550,
    5: 663050,
    "revenu brut": 2612000,
    trimf: 1500,
    1.5: 903050,
    2.5: 836383,
    3.5: 758883,
    4.5: 698466,
  },
  {
    1: 928450,
    2: 874283,
    3: 790950,
    4: 720950,
    5: 663450,
    "revenu brut": 2613000,
    trimf: 1500,
    1.5: 903450,
    2.5: 836783,
    3.5: 759283,
    4.5: 698866,
  },
  {
    1: 928850,
    2: 874683,
    3: 791350,
    4: 721350,
    5: 663850,
    "revenu brut": 2614000,
    trimf: 1500,
    1.5: 903850,
    2.5: 837183,
    3.5: 759683,
    4.5: 699266,
  },
  {
    1: 929250,
    2: 875083,
    3: 791750,
    4: 721750,
    5: 664250,
    "revenu brut": 2615000,
    trimf: 1500,
    1.5: 904250,
    2.5: 837583,
    3.5: 760083,
    4.5: 699666,
  },
  {
    1: 929650,
    2: 875483,
    3: 792150,
    4: 722150,
    5: 664650,
    "revenu brut": 2616000,
    trimf: 1500,
    1.5: 904650,
    2.5: 837983,
    3.5: 760483,
    4.5: 700066,
  },
  {
    1: 930050,
    2: 875883,
    3: 792550,
    4: 722550,
    5: 665050,
    "revenu brut": 2617000,
    trimf: 1500,
    1.5: 905050,
    2.5: 838383,
    3.5: 760883,
    4.5: 700466,
  },
  {
    1: 930450,
    2: 876283,
    3: 792950,
    4: 722950,
    5: 665450,
    "revenu brut": 2618000,
    trimf: 1500,
    1.5: 905450,
    2.5: 838783,
    3.5: 761283,
    4.5: 700866,
  },
  {
    1: 930850,
    2: 876683,
    3: 793350,
    4: 723350,
    5: 665850,
    "revenu brut": 2619000,
    trimf: 1500,
    1.5: 905850,
    2.5: 839183,
    3.5: 761683,
    4.5: 701266,
  },
  {
    1: 931250,
    2: 877083,
    3: 793750,
    4: 723750,
    5: 666250,
    "revenu brut": 2620000,
    trimf: 1500,
    1.5: 906250,
    2.5: 839583,
    3.5: 762083,
    4.5: 701666,
  },
  {
    1: 931650,
    2: 877483,
    3: 794150,
    4: 724150,
    5: 666650,
    "revenu brut": 2621_000,
    trimf: 1500,
    1.5: 906650,
    2.5: 839983,
    3.5: 762483,
    4.5: 702066,
  },
  {
    1: 932050,
    2: 877883,
    3: 794550,
    4: 724550,
    5: 667050,
    "revenu brut": 2622000,
    trimf: 1500,
    1.5: 907050,
    2.5: 840383,
    3.5: 762883,
    4.5: 702466,
  },
  {
    1: 932450,
    2: 878283,
    3: 794950,
    4: 724950,
    5: 667450,
    "revenu brut": 2623000,
    trimf: 1500,
    1.5: 907450,
    2.5: 840783,
    3.5: 763283,
    4.5: 702866,
  },
  {
    1: 932850,
    2: 878683,
    3: 795350,
    4: 725350,
    5: 667850,
    "revenu brut": 2624000,
    trimf: 1500,
    1.5: 907850,
    2.5: 841183,
    3.5: 763683,
    4.5: 703266,
  },
  {
    1: 933250,
    2: 879083,
    3: 795750,
    4: 725750,
    5: 668250,
    "revenu brut": 2625000,
    trimf: 1500,
    1.5: 908250,
    2.5: 841583,
    3.5: 764083,
    4.5: 703666,
  },
  {
    1: 933650,
    2: 879483,
    3: 796150,
    4: 726150,
    5: 668650,
    "revenu brut": 2626000,
    trimf: 1500,
    1.5: 908650,
    2.5: 841983,
    3.5: 764483,
    4.5: 704066,
  },
  {
    1: 934050,
    2: 879883,
    3: 796550,
    4: 726550,
    5: 669050,
    "revenu brut": 2627000,
    trimf: 1500,
    1.5: 909050,
    2.5: 842383,
    3.5: 764883,
    4.5: 704466,
  },
  {
    1: 934450,
    2: 880283,
    3: 796950,
    4: 726950,
    5: 669450,
    "revenu brut": 2628000,
    trimf: 1500,
    1.5: 909450,
    2.5: 842783,
    3.5: 765283,
    4.5: 704866,
  },
  {
    1: 934850,
    2: 880683,
    3: 797350,
    4: 727350,
    5: 669850,
    "revenu brut": 2629000,
    trimf: 1500,
    1.5: 909850,
    2.5: 843183,
    3.5: 765683,
    4.5: 705266,
  },
  {
    1: 935250,
    2: 881083,
    3: 797750,
    4: 727750,
    5: 670250,
    "revenu brut": 2630000,
    trimf: 1500,
    1.5: 910250,
    2.5: 843583,
    3.5: 766083,
    4.5: 705666,
  },
  {
    1: 935650,
    2: 881483,
    3: 798150,
    4: 728150,
    5: 670650,
    "revenu brut": 2631_000,
    trimf: 1500,
    1.5: 910650,
    2.5: 843983,
    3.5: 766483,
    4.5: 706066,
  },
  {
    1: 936050,
    2: 881883,
    3: 798550,
    4: 728550,
    5: 671050,
    "revenu brut": 2632000,
    trimf: 1500,
    1.5: 911050,
    2.5: 844383,
    3.5: 766883,
    4.5: 706466,
  },
  {
    1: 936450,
    2: 882283,
    3: 798950,
    4: 728950,
    5: 671450,
    "revenu brut": 2633000,
    trimf: 1500,
    1.5: 911450,
    2.5: 844783,
    3.5: 767283,
    4.5: 706866,
  },
  {
    1: 936850,
    2: 882683,
    3: 799350,
    4: 729350,
    5: 671850,
    "revenu brut": 2634000,
    trimf: 1500,
    1.5: 911850,
    2.5: 845183,
    3.5: 767683,
    4.5: 707266,
  },
  {
    1: 937250,
    2: 883083,
    3: 799750,
    4: 729750,
    5: 672250,
    "revenu brut": 2635000,
    trimf: 1500,
    1.5: 912250,
    2.5: 845583,
    3.5: 768083,
    4.5: 707666,
  },
  {
    1: 937650,
    2: 883483,
    3: 800150,
    4: 730150,
    5: 672650,
    "revenu brut": 2636000,
    trimf: 1500,
    1.5: 912650,
    2.5: 845983,
    3.5: 768483,
    4.5: 708066,
  },
  {
    1: 938050,
    2: 883883,
    3: 800550,
    4: 730550,
    5: 673050,
    "revenu brut": 2637000,
    trimf: 1500,
    1.5: 913050,
    2.5: 846383,
    3.5: 768883,
    4.5: 708466,
  },
  {
    1: 938450,
    2: 884283,
    3: 800950,
    4: 730950,
    5: 673450,
    "revenu brut": 2638000,
    trimf: 1500,
    1.5: 913450,
    2.5: 846783,
    3.5: 769283,
    4.5: 708866,
  },
  {
    1: 938850,
    2: 884683,
    3: 801350,
    4: 731350,
    5: 673850,
    "revenu brut": 2639000,
    trimf: 1500,
    1.5: 913850,
    2.5: 847183,
    3.5: 769683,
    4.5: 709266,
  },
  {
    1: 939250,
    2: 885083,
    3: 801750,
    4: 731750,
    5: 674250,
    "revenu brut": 2640000,
    trimf: 1500,
    1.5: 914250,
    2.5: 847583,
    3.5: 770083,
    4.5: 709666,
  },
  {
    1: 939650,
    2: 885483,
    3: 802150,
    4: 732150,
    5: 674650,
    "revenu brut": 2641_000,
    trimf: 1500,
    1.5: 914650,
    2.5: 847983,
    3.5: 770483,
    4.5: 710066,
  },
  {
    1: 940050,
    2: 885883,
    3: 802550,
    4: 732550,
    5: 675050,
    "revenu brut": 2642000,
    trimf: 1500,
    1.5: 915050,
    2.5: 848383,
    3.5: 770883,
    4.5: 710466,
  },
  {
    1: 940450,
    2: 886283,
    3: 802950,
    4: 732950,
    5: 675450,
    "revenu brut": 2643000,
    trimf: 1500,
    1.5: 915450,
    2.5: 848783,
    3.5: 771283,
    4.5: 710866,
  },
  {
    1: 940850,
    2: 886683,
    3: 803350,
    4: 733350,
    5: 675850,
    "revenu brut": 2644000,
    trimf: 1500,
    1.5: 915850,
    2.5: 849183,
    3.5: 771683,
    4.5: 711266,
  },
  {
    1: 941250,
    2: 887083,
    3: 803750,
    4: 733750,
    5: 676250,
    "revenu brut": 2645000,
    trimf: 1500,
    1.5: 916250,
    2.5: 849583,
    3.5: 772083,
    4.5: 711666,
  },
  {
    1: 941650,
    2: 887483,
    3: 804150,
    4: 734150,
    5: 676650,
    "revenu brut": 2646000,
    trimf: 1500,
    1.5: 916650,
    2.5: 849983,
    3.5: 772483,
    4.5: 712066,
  },
  {
    1: 942050,
    2: 887883,
    3: 804550,
    4: 734550,
    5: 677050,
    "revenu brut": 2647000,
    trimf: 1500,
    1.5: 917050,
    2.5: 850383,
    3.5: 772883,
    4.5: 712466,
  },
  {
    1: 942450,
    2: 888283,
    3: 804950,
    4: 734950,
    5: 677450,
    "revenu brut": 2648000,
    trimf: 1500,
    1.5: 917450,
    2.5: 850783,
    3.5: 773283,
    4.5: 712866,
  },
  {
    1: 942850,
    2: 888683,
    3: 805350,
    4: 735350,
    5: 677850,
    "revenu brut": 2649000,
    trimf: 1500,
    1.5: 917850,
    2.5: 851183,
    3.5: 773683,
    4.5: 713266,
  },
  {
    1: 943250,
    2: 889083,
    3: 805750,
    4: 735750,
    5: 678250,
    "revenu brut": 2650000,
    trimf: 1500,
    1.5: 918250,
    2.5: 851583,
    3.5: 774083,
    4.5: 713666,
  },
  {
    1: 943650,
    2: 889483,
    3: 806150,
    4: 736150,
    5: 678650,
    "revenu brut": 2651_000,
    trimf: 1500,
    1.5: 918650,
    2.5: 851983,
    3.5: 774483,
    4.5: 714066,
  },
  {
    1: 944050,
    2: 889883,
    3: 806550,
    4: 736550,
    5: 679050,
    "revenu brut": 2652000,
    trimf: 1500,
    1.5: 919050,
    2.5: 852383,
    3.5: 774883,
    4.5: 714466,
  },
  {
    1: 944450,
    2: 890283,
    3: 806950,
    4: 736950,
    5: 679450,
    "revenu brut": 2653000,
    trimf: 1500,
    1.5: 919450,
    2.5: 852783,
    3.5: 775283,
    4.5: 714866,
  },
  {
    1: 944850,
    2: 890683,
    3: 807350,
    4: 737350,
    5: 679850,
    "revenu brut": 2654000,
    trimf: 1500,
    1.5: 919850,
    2.5: 853183,
    3.5: 775683,
    4.5: 715266,
  },
  {
    1: 945250,
    2: 891083,
    3: 807750,
    4: 737750,
    5: 680250,
    "revenu brut": 2655000,
    trimf: 1500,
    1.5: 920250,
    2.5: 853583,
    3.5: 776083,
    4.5: 715666,
  },
  {
    1: 945650,
    2: 891483,
    3: 808150,
    4: 738150,
    5: 680650,
    "revenu brut": 2656000,
    trimf: 1500,
    1.5: 920650,
    2.5: 853983,
    3.5: 776483,
    4.5: 716066,
  },
  {
    1: 946050,
    2: 891883,
    3: 808550,
    4: 738550,
    5: 681050,
    "revenu brut": 2657000,
    trimf: 1500,
    1.5: 921050,
    2.5: 854383,
    3.5: 776883,
    4.5: 716466,
  },
  {
    1: 946450,
    2: 892283,
    3: 808950,
    4: 738950,
    5: 681450,
    "revenu brut": 2658000,
    trimf: 1500,
    1.5: 921450,
    2.5: 854783,
    3.5: 777283,
    4.5: 716866,
  },
  {
    1: 946850,
    2: 892683,
    3: 809350,
    4: 739350,
    5: 681850,
    "revenu brut": 2659000,
    trimf: 1500,
    1.5: 921850,
    2.5: 855183,
    3.5: 777683,
    4.5: 717266,
  },
  {
    1: 947250,
    2: 893083,
    3: 809750,
    4: 739750,
    5: 682250,
    "revenu brut": 2660000,
    trimf: 1500,
    1.5: 922250,
    2.5: 855583,
    3.5: 778083,
    4.5: 717666,
  },
  {
    1: 947650,
    2: 893483,
    3: 810150,
    4: 740150,
    5: 682650,
    "revenu brut": 2661_000,
    trimf: 1500,
    1.5: 922650,
    2.5: 855983,
    3.5: 778483,
    4.5: 718066,
  },
  {
    1: 948050,
    2: 893883,
    3: 810550,
    4: 740550,
    5: 683050,
    "revenu brut": 2662000,
    trimf: 1500,
    1.5: 923050,
    2.5: 856383,
    3.5: 778883,
    4.5: 718466,
  },
  {
    1: 948450,
    2: 894283,
    3: 810950,
    4: 740950,
    5: 683450,
    "revenu brut": 2663000,
    trimf: 1500,
    1.5: 923450,
    2.5: 856783,
    3.5: 779283,
    4.5: 718866,
  },
  {
    1: 948850,
    2: 894683,
    3: 811350,
    4: 741350,
    5: 683850,
    "revenu brut": 2664000,
    trimf: 1500,
    1.5: 923850,
    2.5: 857183,
    3.5: 779683,
    4.5: 719266,
  },
  {
    1: 949250,
    2: 895083,
    3: 811750,
    4: 741750,
    5: 684250,
    "revenu brut": 2665000,
    trimf: 1500,
    1.5: 924250,
    2.5: 857583,
    3.5: 780083,
    4.5: 719666,
  },
  {
    1: 949650,
    2: 895483,
    3: 812150,
    4: 742150,
    5: 684650,
    "revenu brut": 2666000,
    trimf: 1500,
    1.5: 924650,
    2.5: 857983,
    3.5: 780483,
    4.5: 720066,
  },
  {
    1: 950050,
    2: 895883,
    3: 812550,
    4: 742550,
    5: 685050,
    "revenu brut": 2667000,
    trimf: 1500,
    1.5: 925050,
    2.5: 858383,
    3.5: 780883,
    4.5: 720466,
  },
  {
    1: 950450,
    2: 896283,
    3: 812950,
    4: 742950,
    5: 685450,
    "revenu brut": 2668000,
    trimf: 1500,
    1.5: 925450,
    2.5: 858783,
    3.5: 781283,
    4.5: 720866,
  },
  {
    1: 950850,
    2: 896683,
    3: 813350,
    4: 743350,
    5: 685850,
    "revenu brut": 2669000,
    trimf: 1500,
    1.5: 925850,
    2.5: 859183,
    3.5: 781683,
    4.5: 721266,
  },
  {
    1: 951250,
    2: 897083,
    3: 813750,
    4: 743750,
    5: 686250,
    "revenu brut": 2670000,
    trimf: 1500,
    1.5: 926250,
    2.5: 859583,
    3.5: 782083,
    4.5: 721666,
  },
  {
    1: 951650,
    2: 897483,
    3: 814150,
    4: 744150,
    5: 686650,
    "revenu brut": 2671_000,
    trimf: 1500,
    1.5: 926650,
    2.5: 859983,
    3.5: 782483,
    4.5: 722066,
  },
  {
    1: 952050,
    2: 897883,
    3: 814550,
    4: 744550,
    5: 687050,
    "revenu brut": 2672000,
    trimf: 1500,
    1.5: 927050,
    2.5: 860383,
    3.5: 782883,
    4.5: 722466,
  },
  {
    1: 952450,
    2: 898283,
    3: 814950,
    4: 744950,
    5: 687450,
    "revenu brut": 2673000,
    trimf: 1500,
    1.5: 927450,
    2.5: 860783,
    3.5: 783283,
    4.5: 722866,
  },
  {
    1: 952850,
    2: 898683,
    3: 815350,
    4: 745350,
    5: 687850,
    "revenu brut": 2674000,
    trimf: 1500,
    1.5: 927850,
    2.5: 861183,
    3.5: 783683,
    4.5: 723266,
  },
  {
    1: 953250,
    2: 899083,
    3: 815750,
    4: 745750,
    5: 688250,
    "revenu brut": 2675000,
    trimf: 1500,
    1.5: 928250,
    2.5: 861583,
    3.5: 784083,
    4.5: 723666,
  },
  {
    1: 953650,
    2: 899483,
    3: 816150,
    4: 746150,
    5: 688650,
    "revenu brut": 2676000,
    trimf: 1500,
    1.5: 928650,
    2.5: 861983,
    3.5: 784483,
    4.5: 724066,
  },
  {
    1: 954050,
    2: 899883,
    3: 816550,
    4: 746550,
    5: 689050,
    "revenu brut": 2677000,
    trimf: 1500,
    1.5: 929050,
    2.5: 862383,
    3.5: 784883,
    4.5: 724466,
  },
  {
    1: 954450,
    2: 900283,
    3: 816950,
    4: 746950,
    5: 689450,
    "revenu brut": 2678000,
    trimf: 1500,
    1.5: 929450,
    2.5: 862783,
    3.5: 785283,
    4.5: 724866,
  },
  {
    1: 954850,
    2: 900683,
    3: 817350,
    4: 747350,
    5: 689850,
    "revenu brut": 2679000,
    trimf: 1500,
    1.5: 929850,
    2.5: 863183,
    3.5: 785683,
    4.5: 725266,
  },
  {
    1: 955250,
    2: 901083,
    3: 817750,
    4: 747750,
    5: 690250,
    "revenu brut": 2680000,
    trimf: 1500,
    1.5: 930250,
    2.5: 863583,
    3.5: 786083,
    4.5: 725666,
  },
  {
    1: 955650,
    2: 901483,
    3: 818150,
    4: 748150,
    5: 690650,
    "revenu brut": 2681_000,
    trimf: 1500,
    1.5: 930650,
    2.5: 863983,
    3.5: 786483,
    4.5: 726066,
  },
  {
    1: 956050,
    2: 901883,
    3: 818550,
    4: 748550,
    5: 691050,
    "revenu brut": 2682000,
    trimf: 1500,
    1.5: 931050,
    2.5: 864383,
    3.5: 786883,
    4.5: 726466,
  },
  {
    1: 956450,
    2: 902283,
    3: 818950,
    4: 748950,
    5: 691450,
    "revenu brut": 2683000,
    trimf: 1500,
    1.5: 931450,
    2.5: 864783,
    3.5: 787283,
    4.5: 726866,
  },
  {
    1: 956850,
    2: 902683,
    3: 819350,
    4: 749350,
    5: 691850,
    "revenu brut": 2684000,
    trimf: 1500,
    1.5: 931850,
    2.5: 865183,
    3.5: 787683,
    4.5: 727266,
  },
  {
    1: 957250,
    2: 903083,
    3: 819750,
    4: 749750,
    5: 692250,
    "revenu brut": 2685000,
    trimf: 1500,
    1.5: 932250,
    2.5: 865583,
    3.5: 788083,
    4.5: 727666,
  },
  {
    1: 957650,
    2: 903483,
    3: 820150,
    4: 750150,
    5: 692650,
    "revenu brut": 2686000,
    trimf: 1500,
    1.5: 932650,
    2.5: 865983,
    3.5: 788483,
    4.5: 728066,
  },
  {
    1: 958050,
    2: 903883,
    3: 820550,
    4: 750550,
    5: 693050,
    "revenu brut": 2687000,
    trimf: 1500,
    1.5: 933050,
    2.5: 866383,
    3.5: 788883,
    4.5: 728466,
  },
  {
    1: 958450,
    2: 904283,
    3: 820950,
    4: 750950,
    5: 693450,
    "revenu brut": 2688000,
    trimf: 1500,
    1.5: 933450,
    2.5: 866783,
    3.5: 789283,
    4.5: 728866,
  },
  {
    1: 958850,
    2: 904683,
    3: 821350,
    4: 751350,
    5: 693850,
    "revenu brut": 2689000,
    trimf: 1500,
    1.5: 933850,
    2.5: 867183,
    3.5: 789683,
    4.5: 729266,
  },
  {
    1: 959250,
    2: 905083,
    3: 821750,
    4: 751750,
    5: 694250,
    "revenu brut": 2690000,
    trimf: 1500,
    1.5: 934250,
    2.5: 867583,
    3.5: 790083,
    4.5: 729666,
  },
  {
    1: 959650,
    2: 905483,
    3: 822150,
    4: 752150,
    5: 694650,
    "revenu brut": 2691_000,
    trimf: 1500,
    1.5: 934650,
    2.5: 867983,
    3.5: 790483,
    4.5: 730066,
  },
  {
    1: 960050,
    2: 905883,
    3: 822550,
    4: 752550,
    5: 695050,
    "revenu brut": 2692000,
    trimf: 1500,
    1.5: 935050,
    2.5: 868383,
    3.5: 790883,
    4.5: 730466,
  },
  {
    1: 960450,
    2: 906283,
    3: 822950,
    4: 752950,
    5: 695450,
    "revenu brut": 2693000,
    trimf: 1500,
    1.5: 935450,
    2.5: 868783,
    3.5: 791283,
    4.5: 730866,
  },
  {
    1: 960850,
    2: 906683,
    3: 823350,
    4: 753350,
    5: 695850,
    "revenu brut": 2694000,
    trimf: 1500,
    1.5: 935850,
    2.5: 869183,
    3.5: 791683,
    4.5: 731266,
  },
  {
    1: 961250,
    2: 907083,
    3: 823750,
    4: 753750,
    5: 696250,
    "revenu brut": 2695000,
    trimf: 1500,
    1.5: 936250,
    2.5: 869583,
    3.5: 792083,
    4.5: 731666,
  },
  {
    1: 961650,
    2: 907483,
    3: 824150,
    4: 754150,
    5: 696650,
    "revenu brut": 2696000,
    trimf: 1500,
    1.5: 936650,
    2.5: 869983,
    3.5: 792483,
    4.5: 732066,
  },
  {
    1: 962050,
    2: 907883,
    3: 824550,
    4: 754550,
    5: 697050,
    "revenu brut": 2697000,
    trimf: 1500,
    1.5: 937050,
    2.5: 870383,
    3.5: 792883,
    4.5: 732466,
  },
  {
    1: 962450,
    2: 908283,
    3: 824950,
    4: 754950,
    5: 697450,
    "revenu brut": 2698000,
    trimf: 1500,
    1.5: 937450,
    2.5: 870783,
    3.5: 793283,
    4.5: 732866,
  },
  {
    1: 962850,
    2: 908683,
    3: 825350,
    4: 755350,
    5: 697850,
    "revenu brut": 2699000,
    trimf: 1500,
    1.5: 937850,
    2.5: 871183,
    3.5: 793683,
    4.5: 733266,
  },
  {
    1: 963250,
    2: 909083,
    3: 825750,
    4: 755750,
    5: 698250,
    "revenu brut": 2700000,
    trimf: 1500,
    1.5: 938250,
    2.5: 871583,
    3.5: 794083,
    4.5: 733666,
  },
  {
    1: 963650,
    2: 909483,
    3: 826150,
    4: 756150,
    5: 698650,
    "revenu brut": 2701_000,
    trimf: 1500,
    1.5: 938650,
    2.5: 871983,
    3.5: 794483,
    4.5: 734066,
  },
  {
    1: 964050,
    2: 909883,
    3: 826550,
    4: 756550,
    5: 699050,
    "revenu brut": 2702000,
    trimf: 1500,
    1.5: 939050,
    2.5: 872383,
    3.5: 794883,
    4.5: 734466,
  },
  {
    1: 964450,
    2: 910283,
    3: 826950,
    4: 756950,
    5: 699450,
    "revenu brut": 2703000,
    trimf: 1500,
    1.5: 939450,
    2.5: 872783,
    3.5: 795283,
    4.5: 734866,
  },
  {
    1: 964850,
    2: 910683,
    3: 827350,
    4: 757350,
    5: 699850,
    "revenu brut": 2704000,
    trimf: 1500,
    1.5: 939850,
    2.5: 873183,
    3.5: 795683,
    4.5: 735266,
  },
  {
    1: 965250,
    2: 911083,
    3: 827750,
    4: 757750,
    5: 700250,
    "revenu brut": 2705000,
    trimf: 1500,
    1.5: 940250,
    2.5: 873583,
    3.5: 796083,
    4.5: 735666,
  },
  {
    1: 965650,
    2: 911483,
    3: 828150,
    4: 758150,
    5: 700650,
    "revenu brut": 2706000,
    trimf: 1500,
    1.5: 940650,
    2.5: 873983,
    3.5: 796483,
    4.5: 736066,
  },
  {
    1: 966050,
    2: 911883,
    3: 828550,
    4: 758550,
    5: 701050,
    "revenu brut": 2707000,
    trimf: 1500,
    1.5: 941050,
    2.5: 874383,
    3.5: 796883,
    4.5: 736466,
  },
  {
    1: 966450,
    2: 912283,
    3: 828950,
    4: 758950,
    5: 701450,
    "revenu brut": 2708000,
    trimf: 1500,
    1.5: 941450,
    2.5: 874783,
    3.5: 797283,
    4.5: 736866,
  },
  {
    1: 966850,
    2: 912683,
    3: 829350,
    4: 759350,
    5: 701850,
    "revenu brut": 2709000,
    trimf: 1500,
    1.5: 941850,
    2.5: 875183,
    3.5: 797683,
    4.5: 737266,
  },
  {
    1: 967250,
    2: 913083,
    3: 829750,
    4: 759750,
    5: 702250,
    "revenu brut": 271_0000,
    trimf: 1500,
    1.5: 942250,
    2.5: 875583,
    3.5: 798083,
    4.5: 737666,
  },
  {
    1: 967650,
    2: 913483,
    3: 830150,
    4: 760150,
    5: 702650,
    "revenu brut": 2711_000,
    trimf: 1500,
    1.5: 942650,
    2.5: 875983,
    3.5: 798483,
    4.5: 738066,
  },
  {
    1: 968050,
    2: 913883,
    3: 830550,
    4: 760550,
    5: 703050,
    "revenu brut": 2712000,
    trimf: 1500,
    1.5: 943050,
    2.5: 876383,
    3.5: 798883,
    4.5: 738466,
  },
  {
    1: 968450,
    2: 914283,
    3: 830950,
    4: 760950,
    5: 703450,
    "revenu brut": 2713000,
    trimf: 1500,
    1.5: 943450,
    2.5: 876783,
    3.5: 799283,
    4.5: 738866,
  },
  {
    1: 968850,
    2: 914683,
    3: 831350,
    4: 761350,
    5: 703850,
    "revenu brut": 2714000,
    trimf: 1500,
    1.5: 943850,
    2.5: 877183,
    3.5: 799683,
    4.5: 739266,
  },
  {
    1: 969250,
    2: 915083,
    3: 831750,
    4: 761750,
    5: 704250,
    "revenu brut": 2715000,
    trimf: 1500,
    1.5: 944250,
    2.5: 877583,
    3.5: 800083,
    4.5: 739666,
  },
  {
    1: 969650,
    2: 915483,
    3: 832150,
    4: 762150,
    5: 704650,
    "revenu brut": 2716000,
    trimf: 1500,
    1.5: 944650,
    2.5: 877983,
    3.5: 800483,
    4.5: 740066,
  },
  {
    1: 970050,
    2: 915883,
    3: 832550,
    4: 762550,
    5: 705050,
    "revenu brut": 2717000,
    trimf: 1500,
    1.5: 945050,
    2.5: 878383,
    3.5: 800883,
    4.5: 740466,
  },
  {
    1: 970450,
    2: 916283,
    3: 832950,
    4: 762950,
    5: 705450,
    "revenu brut": 2718000,
    trimf: 1500,
    1.5: 945450,
    2.5: 878783,
    3.5: 801283,
    4.5: 740866,
  },
  {
    1: 970850,
    2: 916683,
    3: 833350,
    4: 763350,
    5: 705850,
    "revenu brut": 2719000,
    trimf: 1500,
    1.5: 945850,
    2.5: 879183,
    3.5: 801683,
    4.5: 741266,
  },
  {
    1: 971250,
    2: 917083,
    3: 833750,
    4: 763750,
    5: 706250,
    "revenu brut": 2720000,
    trimf: 1500,
    1.5: 946250,
    2.5: 879583,
    3.5: 802083,
    4.5: 741666,
  },
  {
    1: 971650,
    2: 917483,
    3: 834150,
    4: 764150,
    5: 706650,
    "revenu brut": 2721_000,
    trimf: 1500,
    1.5: 946650,
    2.5: 879983,
    3.5: 802483,
    4.5: 742066,
  },
  {
    1: 972050,
    2: 917883,
    3: 834550,
    4: 764550,
    5: 707050,
    "revenu brut": 2722000,
    trimf: 1500,
    1.5: 947050,
    2.5: 880383,
    3.5: 802883,
    4.5: 742466,
  },
  {
    1: 972450,
    2: 918283,
    3: 834950,
    4: 764950,
    5: 707450,
    "revenu brut": 2723000,
    trimf: 1500,
    1.5: 947450,
    2.5: 880783,
    3.5: 803283,
    4.5: 742866,
  },
  {
    1: 972850,
    2: 918683,
    3: 835350,
    4: 765350,
    5: 707850,
    "revenu brut": 2724000,
    trimf: 1500,
    1.5: 947850,
    2.5: 881183,
    3.5: 803683,
    4.5: 743266,
  },
  {
    1: 973250,
    2: 919083,
    3: 835750,
    4: 765750,
    5: 708250,
    "revenu brut": 2725000,
    trimf: 1500,
    1.5: 948250,
    2.5: 881583,
    3.5: 804083,
    4.5: 743666,
  },
  {
    1: 973650,
    2: 919483,
    3: 836150,
    4: 766150,
    5: 708650,
    "revenu brut": 2726000,
    trimf: 1500,
    1.5: 948650,
    2.5: 881983,
    3.5: 804483,
    4.5: 744066,
  },
  {
    1: 974050,
    2: 919883,
    3: 836550,
    4: 766550,
    5: 709050,
    "revenu brut": 2727000,
    trimf: 1500,
    1.5: 949050,
    2.5: 882383,
    3.5: 804883,
    4.5: 744466,
  },
  {
    1: 974450,
    2: 920283,
    3: 836950,
    4: 766950,
    5: 709450,
    "revenu brut": 2728000,
    trimf: 1500,
    1.5: 949450,
    2.5: 882783,
    3.5: 805283,
    4.5: 744866,
  },
  {
    1: 974850,
    2: 920683,
    3: 837350,
    4: 767350,
    5: 709850,
    "revenu brut": 2729000,
    trimf: 1500,
    1.5: 949850,
    2.5: 883183,
    3.5: 805683,
    4.5: 745266,
  },
  {
    1: 975250,
    2: 921083,
    3: 837750,
    4: 767750,
    5: 710250,
    "revenu brut": 2730000,
    trimf: 1500,
    1.5: 950250,
    2.5: 883583,
    3.5: 806083,
    4.5: 745666,
  },
  {
    1: 975650,
    2: 921483,
    3: 838150,
    4: 768150,
    5: 710650,
    "revenu brut": 2731_000,
    trimf: 1500,
    1.5: 950650,
    2.5: 883983,
    3.5: 806483,
    4.5: 746066,
  },
  {
    1: 976050,
    2: 921883,
    3: 838550,
    4: 768550,
    5: 711050,
    "revenu brut": 2732000,
    trimf: 1500,
    1.5: 951050,
    2.5: 884383,
    3.5: 806883,
    4.5: 746466,
  },
  {
    1: 976450,
    2: 922283,
    3: 838950,
    4: 768950,
    5: 711450,
    "revenu brut": 2733000,
    trimf: 1500,
    1.5: 951450,
    2.5: 884783,
    3.5: 807283,
    4.5: 746866,
  },
  {
    1: 976850,
    2: 922683,
    3: 839350,
    4: 769350,
    5: 711850,
    "revenu brut": 2734000,
    trimf: 1500,
    1.5: 951850,
    2.5: 885183,
    3.5: 807683,
    4.5: 747266,
  },
  {
    1: 977250,
    2: 923083,
    3: 839750,
    4: 769750,
    5: 712250,
    "revenu brut": 2735000,
    trimf: 1500,
    1.5: 952250,
    2.5: 885583,
    3.5: 808083,
    4.5: 747666,
  },
  {
    1: 977650,
    2: 923483,
    3: 840150,
    4: 770150,
    5: 712650,
    "revenu brut": 2736000,
    trimf: 1500,
    1.5: 952650,
    2.5: 885983,
    3.5: 808483,
    4.5: 748066,
  },
  {
    1: 978050,
    2: 923883,
    3: 840550,
    4: 770550,
    5: 713050,
    "revenu brut": 2737000,
    trimf: 1500,
    1.5: 953050,
    2.5: 886383,
    3.5: 808883,
    4.5: 748466,
  },
  {
    1: 978450,
    2: 924283,
    3: 840950,
    4: 770950,
    5: 713450,
    "revenu brut": 2738000,
    trimf: 1500,
    1.5: 953450,
    2.5: 886783,
    3.5: 809283,
    4.5: 748866,
  },
  {
    1: 978850,
    2: 924683,
    3: 841350,
    4: 771350,
    5: 713850,
    "revenu brut": 2739000,
    trimf: 1500,
    1.5: 953850,
    2.5: 887183,
    3.5: 809683,
    4.5: 749266,
  },
  {
    1: 979250,
    2: 925083,
    3: 841750,
    4: 771750,
    5: 714250,
    "revenu brut": 2740000,
    trimf: 1500,
    1.5: 954250,
    2.5: 887583,
    3.5: 810083,
    4.5: 749666,
  },
  {
    1: 979650,
    2: 925483,
    3: 842150,
    4: 772150,
    5: 714650,
    "revenu brut": 2741_000,
    trimf: 1500,
    1.5: 954650,
    2.5: 887983,
    3.5: 810483,
    4.5: 750066,
  },
  {
    1: 980050,
    2: 925883,
    3: 842550,
    4: 772550,
    5: 715050,
    "revenu brut": 2742000,
    trimf: 1500,
    1.5: 955050,
    2.5: 888383,
    3.5: 810883,
    4.5: 750466,
  },
  {
    1: 980450,
    2: 926283,
    3: 842950,
    4: 772950,
    5: 715450,
    "revenu brut": 2743000,
    trimf: 1500,
    1.5: 955450,
    2.5: 888783,
    3.5: 811283,
    4.5: 750866,
  },
  {
    1: 980850,
    2: 926683,
    3: 843350,
    4: 773350,
    5: 715850,
    "revenu brut": 2744000,
    trimf: 1500,
    1.5: 955850,
    2.5: 889183,
    3.5: 811683,
    4.5: 751266,
  },
  {
    1: 981250,
    2: 927083,
    3: 843750,
    4: 773750,
    5: 716250,
    "revenu brut": 2745000,
    trimf: 1500,
    1.5: 956250,
    2.5: 889583,
    3.5: 812083,
    4.5: 751666,
  },
  {
    1: 981650,
    2: 927483,
    3: 844150,
    4: 774150,
    5: 716650,
    "revenu brut": 2746000,
    trimf: 1500,
    1.5: 956650,
    2.5: 889983,
    3.5: 812483,
    4.5: 752066,
  },
  {
    1: 982050,
    2: 927883,
    3: 844550,
    4: 774550,
    5: 717050,
    "revenu brut": 2747000,
    trimf: 1500,
    1.5: 957050,
    2.5: 890383,
    3.5: 812883,
    4.5: 752466,
  },
  {
    1: 982450,
    2: 928283,
    3: 844950,
    4: 774950,
    5: 717450,
    "revenu brut": 2748000,
    trimf: 1500,
    1.5: 957450,
    2.5: 890783,
    3.5: 813283,
    4.5: 752866,
  },
  {
    1: 982850,
    2: 928683,
    3: 845350,
    4: 775350,
    5: 717850,
    "revenu brut": 2749000,
    trimf: 1500,
    1.5: 957850,
    2.5: 891183,
    3.5: 813683,
    4.5: 753266,
  },
  {
    1: 983250,
    2: 929083,
    3: 845750,
    4: 775750,
    5: 718250,
    "revenu brut": 2750000,
    trimf: 1500,
    1.5: 958250,
    2.5: 891583,
    3.5: 814083,
    4.5: 753666,
  },
  {
    1: 983650,
    2: 929483,
    3: 846150,
    4: 776150,
    5: 718650,
    "revenu brut": 2751_000,
    trimf: 1500,
    1.5: 958650,
    2.5: 891983,
    3.5: 814483,
    4.5: 754066,
  },
  {
    1: 984050,
    2: 929883,
    3: 846550,
    4: 776550,
    5: 719050,
    "revenu brut": 2752000,
    trimf: 1500,
    1.5: 959050,
    2.5: 892383,
    3.5: 814883,
    4.5: 754466,
  },
  {
    1: 984450,
    2: 930283,
    3: 846950,
    4: 776950,
    5: 719450,
    "revenu brut": 2753000,
    trimf: 1500,
    1.5: 959450,
    2.5: 892783,
    3.5: 815283,
    4.5: 754866,
  },
  {
    1: 984850,
    2: 930683,
    3: 847350,
    4: 777350,
    5: 719850,
    "revenu brut": 2754000,
    trimf: 1500,
    1.5: 959850,
    2.5: 893183,
    3.5: 815683,
    4.5: 755266,
  },
  {
    1: 985250,
    2: 931083,
    3: 847750,
    4: 777750,
    5: 720250,
    "revenu brut": 2755000,
    trimf: 1500,
    1.5: 960250,
    2.5: 893583,
    3.5: 816083,
    4.5: 755666,
  },
  {
    1: 985650,
    2: 931483,
    3: 848150,
    4: 778150,
    5: 720650,
    "revenu brut": 2756000,
    trimf: 1500,
    1.5: 960650,
    2.5: 893983,
    3.5: 816483,
    4.5: 756066,
  },
  {
    1: 986050,
    2: 931883,
    3: 848550,
    4: 778550,
    5: 721050,
    "revenu brut": 2757000,
    trimf: 1500,
    1.5: 961050,
    2.5: 894383,
    3.5: 816883,
    4.5: 756466,
  },
  {
    1: 986450,
    2: 932283,
    3: 848950,
    4: 778950,
    5: 721450,
    "revenu brut": 2758000,
    trimf: 1500,
    1.5: 961450,
    2.5: 894783,
    3.5: 817283,
    4.5: 756866,
  },
  {
    1: 986850,
    2: 932683,
    3: 849350,
    4: 779350,
    5: 721850,
    "revenu brut": 2759000,
    trimf: 1500,
    1.5: 961850,
    2.5: 895183,
    3.5: 817683,
    4.5: 757266,
  },
  {
    1: 987250,
    2: 933083,
    3: 849750,
    4: 779750,
    5: 722250,
    "revenu brut": 2760000,
    trimf: 1500,
    1.5: 962250,
    2.5: 895583,
    3.5: 818083,
    4.5: 757666,
  },
  {
    1: 987650,
    2: 933483,
    3: 850150,
    4: 780150,
    5: 722650,
    "revenu brut": 2761_000,
    trimf: 1500,
    1.5: 962650,
    2.5: 895983,
    3.5: 818483,
    4.5: 758066,
  },
  {
    1: 988050,
    2: 933883,
    3: 850550,
    4: 780550,
    5: 723050,
    "revenu brut": 2762000,
    trimf: 1500,
    1.5: 963050,
    2.5: 896383,
    3.5: 818883,
    4.5: 758466,
  },
  {
    1: 988450,
    2: 934283,
    3: 850950,
    4: 780950,
    5: 723450,
    "revenu brut": 2763000,
    trimf: 1500,
    1.5: 963450,
    2.5: 896783,
    3.5: 819283,
    4.5: 758866,
  },
  {
    1: 988850,
    2: 934683,
    3: 851350,
    4: 781350,
    5: 723850,
    "revenu brut": 2764000,
    trimf: 1500,
    1.5: 963850,
    2.5: 897183,
    3.5: 819683,
    4.5: 759266,
  },
  {
    1: 989250,
    2: 935083,
    3: 851750,
    4: 781750,
    5: 724250,
    "revenu brut": 2765000,
    trimf: 1500,
    1.5: 964250,
    2.5: 897583,
    3.5: 820083,
    4.5: 759666,
  },
  {
    1: 989650,
    2: 935483,
    3: 852150,
    4: 782150,
    5: 724650,
    "revenu brut": 2766000,
    trimf: 1500,
    1.5: 964650,
    2.5: 897983,
    3.5: 820483,
    4.5: 760066,
  },
  {
    1: 990050,
    2: 935883,
    3: 852550,
    4: 782550,
    5: 725050,
    "revenu brut": 2767000,
    trimf: 1500,
    1.5: 965050,
    2.5: 898383,
    3.5: 820883,
    4.5: 760466,
  },
  {
    1: 990450,
    2: 936283,
    3: 852950,
    4: 782950,
    5: 725450,
    "revenu brut": 2768000,
    trimf: 1500,
    1.5: 965450,
    2.5: 898783,
    3.5: 821283,
    4.5: 760866,
  },
  {
    1: 990850,
    2: 936683,
    3: 853350,
    4: 783350,
    5: 725850,
    "revenu brut": 2769000,
    trimf: 1500,
    1.5: 965850,
    2.5: 899183,
    3.5: 821683,
    4.5: 761266,
  },
  {
    1: 991250,
    2: 937083,
    3: 853750,
    4: 783750,
    5: 726250,
    "revenu brut": 2770000,
    trimf: 1500,
    1.5: 966250,
    2.5: 899583,
    3.5: 822083,
    4.5: 761666,
  },
  {
    1: 991650,
    2: 937483,
    3: 854150,
    4: 784150,
    5: 726650,
    "revenu brut": 2771_000,
    trimf: 1500,
    1.5: 966650,
    2.5: 899983,
    3.5: 822483,
    4.5: 762066,
  },
  {
    1: 992050,
    2: 937883,
    3: 854550,
    4: 784550,
    5: 727050,
    "revenu brut": 2772000,
    trimf: 1500,
    1.5: 967050,
    2.5: 900383,
    3.5: 822883,
    4.5: 762466,
  },
  {
    1: 992450,
    2: 938283,
    3: 854950,
    4: 784950,
    5: 727450,
    "revenu brut": 2773000,
    trimf: 1500,
    1.5: 967450,
    2.5: 900783,
    3.5: 823283,
    4.5: 762866,
  },
  {
    1: 992850,
    2: 938683,
    3: 855350,
    4: 785350,
    5: 727850,
    "revenu brut": 2774000,
    trimf: 1500,
    1.5: 967850,
    2.5: 901183,
    3.5: 823683,
    4.5: 763266,
  },
  {
    1: 993250,
    2: 939083,
    3: 855750,
    4: 785750,
    5: 728250,
    "revenu brut": 2775000,
    trimf: 1500,
    1.5: 968250,
    2.5: 901583,
    3.5: 824083,
    4.5: 763666,
  },
  {
    1: 993650,
    2: 939483,
    3: 856150,
    4: 786150,
    5: 728650,
    "revenu brut": 2776000,
    trimf: 1500,
    1.5: 968650,
    2.5: 901983,
    3.5: 824483,
    4.5: 764066,
  },
  {
    1: 994050,
    2: 939883,
    3: 856550,
    4: 786550,
    5: 729050,
    "revenu brut": 2777000,
    trimf: 1500,
    1.5: 969050,
    2.5: 902383,
    3.5: 824883,
    4.5: 764466,
  },
  {
    1: 994450,
    2: 940283,
    3: 856950,
    4: 786950,
    5: 729450,
    "revenu brut": 2778000,
    trimf: 1500,
    1.5: 969450,
    2.5: 902783,
    3.5: 825283,
    4.5: 764866,
  },
  {
    1: 994850,
    2: 940683,
    3: 857350,
    4: 787350,
    5: 729850,
    "revenu brut": 2779000,
    trimf: 1500,
    1.5: 969850,
    2.5: 903183,
    3.5: 825683,
    4.5: 765266,
  },
  {
    1: 995250,
    2: 941083,
    3: 857750,
    4: 787750,
    5: 730250,
    "revenu brut": 2780000,
    trimf: 1500,
    1.5: 970250,
    2.5: 903583,
    3.5: 826083,
    4.5: 765666,
  },
  {
    1: 995650,
    2: 941483,
    3: 858150,
    4: 788150,
    5: 730650,
    "revenu brut": 2781_000,
    trimf: 1500,
    1.5: 970650,
    2.5: 903983,
    3.5: 826483,
    4.5: 766066,
  },
  {
    1: 996050,
    2: 941883,
    3: 858550,
    4: 788550,
    5: 731050,
    "revenu brut": 2782000,
    trimf: 1500,
    1.5: 971050,
    2.5: 904383,
    3.5: 826883,
    4.5: 766466,
  },
  {
    1: 996450,
    2: 942283,
    3: 858950,
    4: 788950,
    5: 731450,
    "revenu brut": 2783000,
    trimf: 1500,
    1.5: 971450,
    2.5: 904783,
    3.5: 827283,
    4.5: 766866,
  },
  {
    1: 996850,
    2: 942683,
    3: 859350,
    4: 789350,
    5: 731850,
    "revenu brut": 2784000,
    trimf: 1500,
    1.5: 971850,
    2.5: 905183,
    3.5: 827683,
    4.5: 767266,
  },
  {
    1: 997250,
    2: 943083,
    3: 859750,
    4: 789750,
    5: 732250,
    "revenu brut": 2785000,
    trimf: 1500,
    1.5: 972250,
    2.5: 905583,
    3.5: 828083,
    4.5: 767666,
  },
  {
    1: 997650,
    2: 943483,
    3: 860150,
    4: 790150,
    5: 732650,
    "revenu brut": 2786000,
    trimf: 1500,
    1.5: 972650,
    2.5: 905983,
    3.5: 828483,
    4.5: 768066,
  },
  {
    1: 998050,
    2: 943883,
    3: 860550,
    4: 790550,
    5: 733050,
    "revenu brut": 2787000,
    trimf: 1500,
    1.5: 973050,
    2.5: 906383,
    3.5: 828883,
    4.5: 768466,
  },
  {
    1: 998450,
    2: 944283,
    3: 860950,
    4: 790950,
    5: 733450,
    "revenu brut": 2788000,
    trimf: 1500,
    1.5: 973450,
    2.5: 906783,
    3.5: 829283,
    4.5: 768866,
  },
  {
    1: 998850,
    2: 944683,
    3: 861350,
    4: 791350,
    5: 733850,
    "revenu brut": 2789000,
    trimf: 1500,
    1.5: 973850,
    2.5: 907183,
    3.5: 829683,
    4.5: 769266,
  },
  {
    1: 999250,
    2: 945083,
    3: 861750,
    4: 791750,
    5: 734250,
    "revenu brut": 2790000,
    trimf: 1500,
    1.5: 974250,
    2.5: 907583,
    3.5: 830083,
    4.5: 769666,
  },
  {
    1: 999650,
    2: 945483,
    3: 862150,
    4: 792150,
    5: 734650,
    "revenu brut": 2791_000,
    trimf: 1500,
    1.5: 974650,
    2.5: 907983,
    3.5: 830483,
    4.5: 770066,
  },
  {
    1: 1_000050,
    2: 945883,
    3: 862550,
    4: 792550,
    5: 735050,
    "revenu brut": 2792000,
    trimf: 1500,
    1.5: 975050,
    2.5: 908383,
    3.5: 830883,
    4.5: 770466,
  },
  {
    1: 1_000450,
    2: 946283,
    3: 862950,
    4: 792950,
    5: 735450,
    "revenu brut": 2793000,
    trimf: 1500,
    1.5: 975450,
    2.5: 908783,
    3.5: 831283,
    4.5: 770866,
  },
  {
    1: 1_000850,
    2: 946683,
    3: 863350,
    4: 793350,
    5: 735850,
    "revenu brut": 2794000,
    trimf: 1500,
    1.5: 975850,
    2.5: 909183,
    3.5: 831683,
    4.5: 771266,
  },
  {
    1: 1001250,
    2: 947083,
    3: 863750,
    4: 793750,
    5: 736250,
    "revenu brut": 2795000,
    trimf: 1500,
    1.5: 976250,
    2.5: 909583,
    3.5: 832083,
    4.5: 771666,
  },
  {
    1: 1001650,
    2: 947483,
    3: 864150,
    4: 794150,
    5: 736650,
    "revenu brut": 2796000,
    trimf: 1500,
    1.5: 976650,
    2.5: 909983,
    3.5: 832483,
    4.5: 772066,
  },
  {
    1: 1002050,
    2: 947883,
    3: 864550,
    4: 794550,
    5: 737050,
    "revenu brut": 2797000,
    trimf: 1500,
    1.5: 977050,
    2.5: 910383,
    3.5: 832883,
    4.5: 772466,
  },
  {
    1: 1002450,
    2: 948283,
    3: 864950,
    4: 794950,
    5: 737450,
    "revenu brut": 2798000,
    trimf: 1500,
    1.5: 977450,
    2.5: 910783,
    3.5: 833283,
    4.5: 772866,
  },
  {
    1: 1002850,
    2: 948683,
    3: 865350,
    4: 795350,
    5: 737850,
    "revenu brut": 2799000,
    trimf: 1500,
    1.5: 977850,
    2.5: 911183,
    3.5: 833683,
    4.5: 773266,
  },
  {
    1: 1003250,
    2: 949083,
    3: 865750,
    4: 795750,
    5: 738250,
    "revenu brut": 2800000,
    trimf: 1500,
    1.5: 978250,
    2.5: 911583,
    3.5: 834083,
    4.5: 773666,
  },
  {
    1: 1003650,
    2: 949483,
    3: 866150,
    4: 796150,
    5: 738650,
    "revenu brut": 2801_000,
    trimf: 1500,
    1.5: 978650,
    2.5: 911983,
    3.5: 834483,
    4.5: 774066,
  },
  {
    1: 1004050,
    2: 949883,
    3: 866550,
    4: 796550,
    5: 739050,
    "revenu brut": 2802000,
    trimf: 1500,
    1.5: 979050,
    2.5: 912383,
    3.5: 834883,
    4.5: 774466,
  },
  {
    1: 1004450,
    2: 950283,
    3: 866950,
    4: 796950,
    5: 739450,
    "revenu brut": 2803000,
    trimf: 1500,
    1.5: 979450,
    2.5: 912783,
    3.5: 835283,
    4.5: 774866,
  },
  {
    1: 1004850,
    2: 950683,
    3: 867350,
    4: 797350,
    5: 739850,
    "revenu brut": 2804000,
    trimf: 1500,
    1.5: 979850,
    2.5: 913183,
    3.5: 835683,
    4.5: 775266,
  },
  {
    1: 1005250,
    2: 951083,
    3: 867750,
    4: 797750,
    5: 740250,
    "revenu brut": 2805000,
    trimf: 1500,
    1.5: 980250,
    2.5: 913583,
    3.5: 836083,
    4.5: 775666,
  },
  {
    1: 1005650,
    2: 951483,
    3: 868150,
    4: 798150,
    5: 740650,
    "revenu brut": 2806000,
    trimf: 1500,
    1.5: 980650,
    2.5: 913983,
    3.5: 836483,
    4.5: 776066,
  },
  {
    1: 1006050,
    2: 951883,
    3: 868550,
    4: 798550,
    5: 741050,
    "revenu brut": 2807000,
    trimf: 1500,
    1.5: 981050,
    2.5: 914383,
    3.5: 836883,
    4.5: 776466,
  },
  {
    1: 1006450,
    2: 952283,
    3: 868950,
    4: 798950,
    5: 741450,
    "revenu brut": 2808000,
    trimf: 1500,
    1.5: 981450,
    2.5: 914783,
    3.5: 837283,
    4.5: 776866,
  },
  {
    1: 1006850,
    2: 952683,
    3: 869350,
    4: 799350,
    5: 741850,
    "revenu brut": 2809000,
    trimf: 1500,
    1.5: 981850,
    2.5: 915183,
    3.5: 837683,
    4.5: 777266,
  },
  {
    1: 1007250,
    2: 953083,
    3: 869750,
    4: 799750,
    5: 742250,
    "revenu brut": 281_0000,
    trimf: 1500,
    1.5: 982250,
    2.5: 915583,
    3.5: 838083,
    4.5: 777666,
  },
  {
    1: 1007650,
    2: 953483,
    3: 870150,
    4: 800150,
    5: 742650,
    "revenu brut": 2811_000,
    trimf: 1500,
    1.5: 982650,
    2.5: 915983,
    3.5: 838483,
    4.5: 778066,
  },
  {
    1: 1008050,
    2: 953883,
    3: 870550,
    4: 800550,
    5: 743050,
    "revenu brut": 2812000,
    trimf: 1500,
    1.5: 983050,
    2.5: 916383,
    3.5: 838883,
    4.5: 778466,
  },
  {
    1: 1008450,
    2: 954283,
    3: 870950,
    4: 800950,
    5: 743450,
    "revenu brut": 2813000,
    trimf: 1500,
    1.5: 983450,
    2.5: 916783,
    3.5: 839283,
    4.5: 778866,
  },
  {
    1: 1008850,
    2: 954683,
    3: 871350,
    4: 801350,
    5: 743850,
    "revenu brut": 2814000,
    trimf: 1500,
    1.5: 983850,
    2.5: 917183,
    3.5: 839683,
    4.5: 779266,
  },
  {
    1: 1009250,
    2: 955083,
    3: 871750,
    4: 801750,
    5: 744250,
    "revenu brut": 2815000,
    trimf: 1500,
    1.5: 984250,
    2.5: 917583,
    3.5: 840083,
    4.5: 779666,
  },
  {
    1: 1009650,
    2: 955483,
    3: 872150,
    4: 802150,
    5: 744650,
    "revenu brut": 2816000,
    trimf: 1500,
    1.5: 984650,
    2.5: 917983,
    3.5: 840483,
    4.5: 780066,
  },
  {
    1: 1010050,
    2: 955883,
    3: 872550,
    4: 802550,
    5: 745050,
    "revenu brut": 2817000,
    trimf: 1500,
    1.5: 985050,
    2.5: 918383,
    3.5: 840883,
    4.5: 780466,
  },
  {
    1: 1010450,
    2: 956283,
    3: 872950,
    4: 802950,
    5: 745450,
    "revenu brut": 2818000,
    trimf: 1500,
    1.5: 985450,
    2.5: 918783,
    3.5: 841283,
    4.5: 780866,
  },
  {
    1: 1010850,
    2: 956683,
    3: 873350,
    4: 803350,
    5: 745850,
    "revenu brut": 2819000,
    trimf: 1500,
    1.5: 985850,
    2.5: 919183,
    3.5: 841683,
    4.5: 781266,
  },
  {
    1: 1011250,
    2: 957083,
    3: 873750,
    4: 803750,
    5: 746250,
    "revenu brut": 2820000,
    trimf: 1500,
    1.5: 986250,
    2.5: 919583,
    3.5: 842083,
    4.5: 781666,
  },
  {
    1: 1011650,
    2: 957483,
    3: 874150,
    4: 804150,
    5: 746650,
    "revenu brut": 2821_000,
    trimf: 1500,
    1.5: 986650,
    2.5: 919983,
    3.5: 842483,
    4.5: 782066,
  },
  {
    1: 1012050,
    2: 957883,
    3: 874550,
    4: 804550,
    5: 747050,
    "revenu brut": 2822000,
    trimf: 1500,
    1.5: 987050,
    2.5: 920383,
    3.5: 842883,
    4.5: 782466,
  },
  {
    1: 1012450,
    2: 958283,
    3: 874950,
    4: 804950,
    5: 747450,
    "revenu brut": 2823000,
    trimf: 1500,
    1.5: 987450,
    2.5: 920783,
    3.5: 843283,
    4.5: 782866,
  },
  {
    1: 1012850,
    2: 958683,
    3: 875350,
    4: 805350,
    5: 747850,
    "revenu brut": 2824000,
    trimf: 1500,
    1.5: 987850,
    2.5: 921183,
    3.5: 843683,
    4.5: 783266,
  },
  {
    1: 1013250,
    2: 959083,
    3: 875750,
    4: 805750,
    5: 748250,
    "revenu brut": 2825000,
    trimf: 1500,
    1.5: 988250,
    2.5: 921583,
    3.5: 844083,
    4.5: 783666,
  },
  {
    1: 1013650,
    2: 959483,
    3: 876150,
    4: 806150,
    5: 748650,
    "revenu brut": 2826000,
    trimf: 1500,
    1.5: 988650,
    2.5: 921983,
    3.5: 844483,
    4.5: 784066,
  },
  {
    1: 1014050,
    2: 959883,
    3: 876550,
    4: 806550,
    5: 749050,
    "revenu brut": 2827000,
    trimf: 1500,
    1.5: 989050,
    2.5: 922383,
    3.5: 844883,
    4.5: 784466,
  },
  {
    1: 1014450,
    2: 960283,
    3: 876950,
    4: 806950,
    5: 749450,
    "revenu brut": 2828000,
    trimf: 1500,
    1.5: 989450,
    2.5: 922783,
    3.5: 845283,
    4.5: 784866,
  },
  {
    1: 1014850,
    2: 960683,
    3: 877350,
    4: 807350,
    5: 749850,
    "revenu brut": 2829000,
    trimf: 1500,
    1.5: 989850,
    2.5: 923183,
    3.5: 845683,
    4.5: 785266,
  },
  {
    1: 1015250,
    2: 961083,
    3: 877750,
    4: 807750,
    5: 750250,
    "revenu brut": 2830000,
    trimf: 1500,
    1.5: 990250,
    2.5: 923583,
    3.5: 846083,
    4.5: 785666,
  },
  {
    1: 1015650,
    2: 961483,
    3: 878150,
    4: 808150,
    5: 750650,
    "revenu brut": 2831_000,
    trimf: 1500,
    1.5: 990650,
    2.5: 923983,
    3.5: 846483,
    4.5: 786066,
  },
  {
    1: 1016050,
    2: 961883,
    3: 878550,
    4: 808550,
    5: 751050,
    "revenu brut": 2832000,
    trimf: 1500,
    1.5: 991050,
    2.5: 924383,
    3.5: 846883,
    4.5: 786466,
  },
  {
    1: 1016450,
    2: 962283,
    3: 878950,
    4: 808950,
    5: 751450,
    "revenu brut": 2833000,
    trimf: 1500,
    1.5: 991450,
    2.5: 924783,
    3.5: 847283,
    4.5: 786866,
  },
  {
    1: 1016850,
    2: 962683,
    3: 879350,
    4: 809350,
    5: 751850,
    "revenu brut": 2834000,
    trimf: 1500,
    1.5: 991850,
    2.5: 925183,
    3.5: 847683,
    4.5: 787266,
  },
  {
    1: 1017250,
    2: 963083,
    3: 879750,
    4: 809750,
    5: 752250,
    "revenu brut": 2835000,
    trimf: 1500,
    1.5: 992250,
    2.5: 925583,
    3.5: 848083,
    4.5: 787666,
  },
  {
    1: 1017650,
    2: 963483,
    3: 880150,
    4: 810150,
    5: 752650,
    "revenu brut": 2836000,
    trimf: 1500,
    1.5: 992650,
    2.5: 925983,
    3.5: 848483,
    4.5: 788066,
  },
  {
    1: 1018050,
    2: 963883,
    3: 880550,
    4: 810550,
    5: 753050,
    "revenu brut": 2837000,
    trimf: 1500,
    1.5: 993050,
    2.5: 926383,
    3.5: 848883,
    4.5: 788466,
  },
  {
    1: 1018450,
    2: 964283,
    3: 880950,
    4: 810950,
    5: 753450,
    "revenu brut": 2838000,
    trimf: 1500,
    1.5: 993450,
    2.5: 926783,
    3.5: 849283,
    4.5: 788866,
  },
  {
    1: 1018850,
    2: 964683,
    3: 881350,
    4: 811350,
    5: 753850,
    "revenu brut": 2839000,
    trimf: 1500,
    1.5: 993850,
    2.5: 927183,
    3.5: 849683,
    4.5: 789266,
  },
  {
    1: 1019250,
    2: 965083,
    3: 881750,
    4: 811750,
    5: 754250,
    "revenu brut": 2840000,
    trimf: 1500,
    1.5: 994250,
    2.5: 927583,
    3.5: 850083,
    4.5: 789666,
  },
  {
    1: 1019650,
    2: 965483,
    3: 882150,
    4: 812150,
    5: 754650,
    "revenu brut": 2841_000,
    trimf: 1500,
    1.5: 994650,
    2.5: 927983,
    3.5: 850483,
    4.5: 790066,
  },
  {
    1: 1020050,
    2: 965883,
    3: 882550,
    4: 812550,
    5: 755050,
    "revenu brut": 2842000,
    trimf: 1500,
    1.5: 995050,
    2.5: 928383,
    3.5: 850883,
    4.5: 790466,
  },
  {
    1: 1020450,
    2: 966283,
    3: 882950,
    4: 812950,
    5: 755450,
    "revenu brut": 2843000,
    trimf: 1500,
    1.5: 995450,
    2.5: 928783,
    3.5: 851283,
    4.5: 790866,
  },
  {
    1: 1020850,
    2: 966683,
    3: 883350,
    4: 813350,
    5: 755850,
    "revenu brut": 2844000,
    trimf: 1500,
    1.5: 995850,
    2.5: 929183,
    3.5: 851683,
    4.5: 791266,
  },
  {
    1: 1021250,
    2: 967083,
    3: 883750,
    4: 813750,
    5: 756250,
    "revenu brut": 2845000,
    trimf: 1500,
    1.5: 996250,
    2.5: 929583,
    3.5: 852083,
    4.5: 791666,
  },
  {
    1: 1021650,
    2: 967483,
    3: 884150,
    4: 814150,
    5: 756650,
    "revenu brut": 2846000,
    trimf: 1500,
    1.5: 996650,
    2.5: 929983,
    3.5: 852483,
    4.5: 792066,
  },
  {
    1: 1022050,
    2: 967883,
    3: 884550,
    4: 814550,
    5: 757050,
    "revenu brut": 2847000,
    trimf: 1500,
    1.5: 997050,
    2.5: 930383,
    3.5: 852883,
    4.5: 792466,
  },
  {
    1: 1022450,
    2: 968283,
    3: 884950,
    4: 814950,
    5: 757450,
    "revenu brut": 2848000,
    trimf: 1500,
    1.5: 997450,
    2.5: 930783,
    3.5: 853283,
    4.5: 792866,
  },
  {
    1: 1022850,
    2: 968683,
    3: 885350,
    4: 815350,
    5: 757850,
    "revenu brut": 2849000,
    trimf: 1500,
    1.5: 997850,
    2.5: 931183,
    3.5: 853683,
    4.5: 793266,
  },
  {
    1: 1023250,
    2: 969083,
    3: 885750,
    4: 815750,
    5: 758250,
    "revenu brut": 2850000,
    trimf: 1500,
    1.5: 998250,
    2.5: 931583,
    3.5: 854083,
    4.5: 793666,
  },
  {
    1: 1023650,
    2: 969483,
    3: 886150,
    4: 816150,
    5: 758650,
    "revenu brut": 2851_000,
    trimf: 1500,
    1.5: 998650,
    2.5: 931983,
    3.5: 854483,
    4.5: 794066,
  },
  {
    1: 1024050,
    2: 969883,
    3: 886550,
    4: 816550,
    5: 759050,
    "revenu brut": 2852000,
    trimf: 1500,
    1.5: 999050,
    2.5: 932383,
    3.5: 854883,
    4.5: 794466,
  },
  {
    1: 1024450,
    2: 970283,
    3: 886950,
    4: 816950,
    5: 759450,
    "revenu brut": 2853000,
    trimf: 1500,
    1.5: 999450,
    2.5: 932783,
    3.5: 855283,
    4.5: 794866,
  },
  {
    1: 1024850,
    2: 970683,
    3: 887350,
    4: 817350,
    5: 759850,
    "revenu brut": 2854000,
    trimf: 1500,
    1.5: 999850,
    2.5: 933183,
    3.5: 855683,
    4.5: 795266,
  },
  {
    1: 1025250,
    2: 971083,
    3: 887750,
    4: 817750,
    5: 760250,
    "revenu brut": 2855000,
    trimf: 1500,
    1.5: 1_000250,
    2.5: 933583,
    3.5: 856083,
    4.5: 795666,
  },
  {
    1: 1025650,
    2: 971483,
    3: 888150,
    4: 818150,
    5: 760650,
    "revenu brut": 2856000,
    trimf: 1500,
    1.5: 1_000650,
    2.5: 933983,
    3.5: 856483,
    4.5: 796066,
  },
  {
    1: 1026050,
    2: 971883,
    3: 888550,
    4: 818550,
    5: 761050,
    "revenu brut": 2857000,
    trimf: 1500,
    1.5: 1001050,
    2.5: 934383,
    3.5: 856883,
    4.5: 796466,
  },
  {
    1: 1026450,
    2: 972283,
    3: 888950,
    4: 818950,
    5: 761450,
    "revenu brut": 2858000,
    trimf: 1500,
    1.5: 1001450,
    2.5: 934783,
    3.5: 857283,
    4.5: 796866,
  },
  {
    1: 1026850,
    2: 972683,
    3: 889350,
    4: 819350,
    5: 761850,
    "revenu brut": 2859000,
    trimf: 1500,
    1.5: 1001850,
    2.5: 935183,
    3.5: 857683,
    4.5: 797266,
  },
  {
    1: 1027250,
    2: 973083,
    3: 889750,
    4: 819750,
    5: 762250,
    "revenu brut": 2860000,
    trimf: 1500,
    1.5: 1002250,
    2.5: 935583,
    3.5: 858083,
    4.5: 797666,
  },
  {
    1: 1027650,
    2: 973483,
    3: 890150,
    4: 820150,
    5: 762650,
    "revenu brut": 2861_000,
    trimf: 1500,
    1.5: 1002650,
    2.5: 935983,
    3.5: 858483,
    4.5: 798066,
  },
  {
    1: 1028050,
    2: 973883,
    3: 890550,
    4: 820550,
    5: 763050,
    "revenu brut": 2862000,
    trimf: 1500,
    1.5: 1003050,
    2.5: 936383,
    3.5: 858883,
    4.5: 798466,
  },
  {
    1: 1028450,
    2: 974283,
    3: 890950,
    4: 820950,
    5: 763450,
    "revenu brut": 2863000,
    trimf: 1500,
    1.5: 1003450,
    2.5: 936783,
    3.5: 859283,
    4.5: 798866,
  },
  {
    1: 1028850,
    2: 974683,
    3: 891350,
    4: 821350,
    5: 763850,
    "revenu brut": 2864000,
    trimf: 1500,
    1.5: 1003850,
    2.5: 937183,
    3.5: 859683,
    4.5: 799266,
  },
  {
    1: 1029250,
    2: 975083,
    3: 891750,
    4: 821750,
    5: 764250,
    "revenu brut": 2865000,
    trimf: 1500,
    1.5: 1004250,
    2.5: 937583,
    3.5: 860083,
    4.5: 799666,
  },
  {
    1: 1029650,
    2: 975483,
    3: 892150,
    4: 822150,
    5: 764650,
    "revenu brut": 2866000,
    trimf: 1500,
    1.5: 1004650,
    2.5: 937983,
    3.5: 860483,
    4.5: 800066,
  },
  {
    1: 1030050,
    2: 975883,
    3: 892550,
    4: 822550,
    5: 765050,
    "revenu brut": 2867000,
    trimf: 1500,
    1.5: 1005050,
    2.5: 938383,
    3.5: 860883,
    4.5: 800466,
  },
  {
    1: 1030450,
    2: 976283,
    3: 892950,
    4: 822950,
    5: 765450,
    "revenu brut": 2868000,
    trimf: 1500,
    1.5: 1005450,
    2.5: 938783,
    3.5: 861283,
    4.5: 800866,
  },
  {
    1: 1030850,
    2: 976683,
    3: 893350,
    4: 823350,
    5: 765850,
    "revenu brut": 2869000,
    trimf: 1500,
    1.5: 1005850,
    2.5: 939183,
    3.5: 861683,
    4.5: 801266,
  },
  {
    1: 1031250,
    2: 977083,
    3: 893750,
    4: 823750,
    5: 766250,
    "revenu brut": 2870000,
    trimf: 1500,
    1.5: 1006250,
    2.5: 939583,
    3.5: 862083,
    4.5: 801666,
  },
  {
    1: 1031650,
    2: 977483,
    3: 894150,
    4: 824150,
    5: 766650,
    "revenu brut": 2871_000,
    trimf: 1500,
    1.5: 1006650,
    2.5: 939983,
    3.5: 862483,
    4.5: 802066,
  },
  {
    1: 1032050,
    2: 977883,
    3: 894550,
    4: 824550,
    5: 767050,
    "revenu brut": 2872000,
    trimf: 1500,
    1.5: 1007050,
    2.5: 940383,
    3.5: 862883,
    4.5: 802466,
  },
  {
    1: 1032450,
    2: 978283,
    3: 894950,
    4: 824950,
    5: 767450,
    "revenu brut": 2873000,
    trimf: 1500,
    1.5: 1007450,
    2.5: 940783,
    3.5: 863283,
    4.5: 802866,
  },
  {
    1: 1032850,
    2: 978683,
    3: 895350,
    4: 825350,
    5: 767850,
    "revenu brut": 2874000,
    trimf: 1500,
    1.5: 1007850,
    2.5: 941183,
    3.5: 863683,
    4.5: 803266,
  },
  {
    1: 1033250,
    2: 979083,
    3: 895750,
    4: 825750,
    5: 768250,
    "revenu brut": 2875000,
    trimf: 1500,
    1.5: 1008250,
    2.5: 941583,
    3.5: 864083,
    4.5: 803666,
  },
  {
    1: 1033650,
    2: 979483,
    3: 896150,
    4: 826150,
    5: 768650,
    "revenu brut": 2876000,
    trimf: 1500,
    1.5: 1008650,
    2.5: 941983,
    3.5: 864483,
    4.5: 804066,
  },
  {
    1: 1034050,
    2: 979883,
    3: 896550,
    4: 826550,
    5: 769050,
    "revenu brut": 2877000,
    trimf: 1500,
    1.5: 1009050,
    2.5: 942383,
    3.5: 864883,
    4.5: 804466,
  },
  {
    1: 1034450,
    2: 980283,
    3: 896950,
    4: 826950,
    5: 769450,
    "revenu brut": 2878000,
    trimf: 1500,
    1.5: 1009450,
    2.5: 942783,
    3.5: 865283,
    4.5: 804866,
  },
  {
    1: 1034850,
    2: 980683,
    3: 897350,
    4: 827350,
    5: 769850,
    "revenu brut": 2879000,
    trimf: 1500,
    1.5: 1009850,
    2.5: 943183,
    3.5: 865683,
    4.5: 805266,
  },
  {
    1: 1035250,
    2: 981083,
    3: 897750,
    4: 827750,
    5: 770250,
    "revenu brut": 2880000,
    trimf: 1500,
    1.5: 1010250,
    2.5: 943583,
    3.5: 866083,
    4.5: 805666,
  },
  {
    1: 1035650,
    2: 981483,
    3: 898150,
    4: 828150,
    5: 770650,
    "revenu brut": 2881_000,
    trimf: 1500,
    1.5: 1010650,
    2.5: 943983,
    3.5: 866483,
    4.5: 806066,
  },
  {
    1: 1036050,
    2: 981883,
    3: 898550,
    4: 828550,
    5: 771050,
    "revenu brut": 2882000,
    trimf: 1500,
    1.5: 1011050,
    2.5: 944383,
    3.5: 866883,
    4.5: 806466,
  },
  {
    1: 1036450,
    2: 982283,
    3: 898950,
    4: 828950,
    5: 771450,
    "revenu brut": 2883000,
    trimf: 1500,
    1.5: 1011450,
    2.5: 944783,
    3.5: 867283,
    4.5: 806866,
  },
  {
    1: 1036850,
    2: 982683,
    3: 899350,
    4: 829350,
    5: 771850,
    "revenu brut": 2884000,
    trimf: 1500,
    1.5: 1011850,
    2.5: 945183,
    3.5: 867683,
    4.5: 807266,
  },
  {
    1: 1037250,
    2: 983083,
    3: 899750,
    4: 829750,
    5: 772250,
    "revenu brut": 2885000,
    trimf: 1500,
    1.5: 1012250,
    2.5: 945583,
    3.5: 868083,
    4.5: 807666,
  },
  {
    1: 1037650,
    2: 983483,
    3: 900150,
    4: 830150,
    5: 772650,
    "revenu brut": 2886000,
    trimf: 1500,
    1.5: 1012650,
    2.5: 945983,
    3.5: 868483,
    4.5: 808066,
  },
  {
    1: 1038050,
    2: 983883,
    3: 900550,
    4: 830550,
    5: 773050,
    "revenu brut": 2887000,
    trimf: 1500,
    1.5: 1013050,
    2.5: 946383,
    3.5: 868883,
    4.5: 808466,
  },
  {
    1: 1038450,
    2: 984283,
    3: 900950,
    4: 830950,
    5: 773450,
    "revenu brut": 2888000,
    trimf: 1500,
    1.5: 1013450,
    2.5: 946783,
    3.5: 869283,
    4.5: 808866,
  },
  {
    1: 1038850,
    2: 984683,
    3: 901350,
    4: 831350,
    5: 773850,
    "revenu brut": 2889000,
    trimf: 1500,
    1.5: 1013850,
    2.5: 947183,
    3.5: 869683,
    4.5: 809266,
  },
  {
    1: 1039250,
    2: 985083,
    3: 901750,
    4: 831750,
    5: 774250,
    "revenu brut": 2890000,
    trimf: 1500,
    1.5: 1014250,
    2.5: 947583,
    3.5: 870083,
    4.5: 809666,
  },
  {
    1: 1039650,
    2: 985483,
    3: 902150,
    4: 832150,
    5: 774650,
    "revenu brut": 2891_000,
    trimf: 1500,
    1.5: 1014650,
    2.5: 947983,
    3.5: 870483,
    4.5: 810066,
  },
  {
    1: 1040050,
    2: 985883,
    3: 902550,
    4: 832550,
    5: 775050,
    "revenu brut": 2892000,
    trimf: 1500,
    1.5: 1015050,
    2.5: 948383,
    3.5: 870883,
    4.5: 810466,
  },
  {
    1: 1040450,
    2: 986283,
    3: 902950,
    4: 832950,
    5: 775450,
    "revenu brut": 2893000,
    trimf: 1500,
    1.5: 1015450,
    2.5: 948783,
    3.5: 871283,
    4.5: 810866,
  },
  {
    1: 1040850,
    2: 986683,
    3: 903350,
    4: 833350,
    5: 775850,
    "revenu brut": 2894000,
    trimf: 1500,
    1.5: 1015850,
    2.5: 949183,
    3.5: 871683,
    4.5: 811266,
  },
  {
    1: 1041250,
    2: 987083,
    3: 903750,
    4: 833750,
    5: 776250,
    "revenu brut": 2895000,
    trimf: 1500,
    1.5: 1016250,
    2.5: 949583,
    3.5: 872083,
    4.5: 811666,
  },
  {
    1: 1041650,
    2: 987483,
    3: 904150,
    4: 834150,
    5: 776650,
    "revenu brut": 2896000,
    trimf: 1500,
    1.5: 1016650,
    2.5: 949983,
    3.5: 872483,
    4.5: 812066,
  },
  {
    1: 1042050,
    2: 987883,
    3: 904550,
    4: 834550,
    5: 777050,
    "revenu brut": 2897000,
    trimf: 1500,
    1.5: 1017050,
    2.5: 950383,
    3.5: 872883,
    4.5: 812466,
  },
  {
    1: 1042450,
    2: 988283,
    3: 904950,
    4: 834950,
    5: 777450,
    "revenu brut": 2898000,
    trimf: 1500,
    1.5: 1017450,
    2.5: 950783,
    3.5: 873283,
    4.5: 812866,
  },
  {
    1: 1042850,
    2: 988683,
    3: 905350,
    4: 835350,
    5: 777850,
    "revenu brut": 2899000,
    trimf: 1500,
    1.5: 1017850,
    2.5: 951183,
    3.5: 873683,
    4.5: 813266,
  },
  {
    1: 1043250,
    2: 989083,
    3: 905750,
    4: 835750,
    5: 778250,
    "revenu brut": 2900000,
    trimf: 1500,
    1.5: 1018250,
    2.5: 951583,
    3.5: 874083,
    4.5: 813666,
  },
  {
    1: 1043650,
    2: 989483,
    3: 906150,
    4: 836150,
    5: 778650,
    "revenu brut": 2901_000,
    trimf: 1500,
    1.5: 1018650,
    2.5: 951983,
    3.5: 874483,
    4.5: 814066,
  },
  {
    1: 1044050,
    2: 989883,
    3: 906550,
    4: 836550,
    5: 779050,
    "revenu brut": 2902000,
    trimf: 1500,
    1.5: 1019050,
    2.5: 952383,
    3.5: 874883,
    4.5: 814466,
  },
  {
    1: 1044450,
    2: 990283,
    3: 906950,
    4: 836950,
    5: 779450,
    "revenu brut": 2903000,
    trimf: 1500,
    1.5: 1019450,
    2.5: 952783,
    3.5: 875283,
    4.5: 814866,
  },
  {
    1: 1044850,
    2: 990683,
    3: 907350,
    4: 837350,
    5: 779850,
    "revenu brut": 2904000,
    trimf: 1500,
    1.5: 1019850,
    2.5: 953183,
    3.5: 875683,
    4.5: 815266,
  },
  {
    1: 1045250,
    2: 991083,
    3: 907750,
    4: 837750,
    5: 780250,
    "revenu brut": 2905000,
    trimf: 1500,
    1.5: 1020250,
    2.5: 953583,
    3.5: 876083,
    4.5: 815666,
  },
  {
    1: 1045650,
    2: 991483,
    3: 908150,
    4: 838150,
    5: 780650,
    "revenu brut": 2906000,
    trimf: 1500,
    1.5: 1020650,
    2.5: 953983,
    3.5: 876483,
    4.5: 816066,
  },
  {
    1: 1046050,
    2: 991883,
    3: 908550,
    4: 838550,
    5: 781050,
    "revenu brut": 2907000,
    trimf: 1500,
    1.5: 1021050,
    2.5: 954383,
    3.5: 876883,
    4.5: 816466,
  },
  {
    1: 1046450,
    2: 992283,
    3: 908950,
    4: 838950,
    5: 781450,
    "revenu brut": 2908000,
    trimf: 1500,
    1.5: 1021450,
    2.5: 954783,
    3.5: 877283,
    4.5: 816866,
  },
  {
    1: 1046850,
    2: 992683,
    3: 909350,
    4: 839350,
    5: 781850,
    "revenu brut": 2909000,
    trimf: 1500,
    1.5: 1021850,
    2.5: 955183,
    3.5: 877683,
    4.5: 817266,
  },
  {
    1: 1047250,
    2: 993083,
    3: 909750,
    4: 839750,
    5: 782250,
    "revenu brut": 291_0000,
    trimf: 1500,
    1.5: 1022250,
    2.5: 955583,
    3.5: 878083,
    4.5: 817666,
  },
  {
    1: 1047650,
    2: 993483,
    3: 910150,
    4: 840150,
    5: 782650,
    "revenu brut": 2911_000,
    trimf: 1500,
    1.5: 1022650,
    2.5: 955983,
    3.5: 878483,
    4.5: 818066,
  },
  {
    1: 1048050,
    2: 993883,
    3: 910550,
    4: 840550,
    5: 783050,
    "revenu brut": 2912000,
    trimf: 1500,
    1.5: 1023050,
    2.5: 956383,
    3.5: 878883,
    4.5: 818466,
  },
  {
    1: 1048450,
    2: 994283,
    3: 910950,
    4: 840950,
    5: 783450,
    "revenu brut": 2913000,
    trimf: 1500,
    1.5: 1023450,
    2.5: 956783,
    3.5: 879283,
    4.5: 818866,
  },
  {
    1: 1048850,
    2: 994683,
    3: 911350,
    4: 841350,
    5: 783850,
    "revenu brut": 2914000,
    trimf: 1500,
    1.5: 1023850,
    2.5: 957183,
    3.5: 879683,
    4.5: 819266,
  },
  {
    1: 1049250,
    2: 995083,
    3: 911750,
    4: 841750,
    5: 784250,
    "revenu brut": 2915000,
    trimf: 1500,
    1.5: 1024250,
    2.5: 957583,
    3.5: 880083,
    4.5: 819666,
  },
  {
    1: 1049650,
    2: 995483,
    3: 912150,
    4: 842150,
    5: 784650,
    "revenu brut": 2916000,
    trimf: 1500,
    1.5: 1024650,
    2.5: 957983,
    3.5: 880483,
    4.5: 820066,
  },
  {
    1: 1050050,
    2: 995883,
    3: 912550,
    4: 842550,
    5: 785050,
    "revenu brut": 2917000,
    trimf: 1500,
    1.5: 1025050,
    2.5: 958383,
    3.5: 880883,
    4.5: 820466,
  },
  {
    1: 1050450,
    2: 996283,
    3: 912950,
    4: 842950,
    5: 785450,
    "revenu brut": 2918000,
    trimf: 1500,
    1.5: 1025450,
    2.5: 958783,
    3.5: 881283,
    4.5: 820866,
  },
  {
    1: 1050850,
    2: 996683,
    3: 913350,
    4: 843350,
    5: 785850,
    "revenu brut": 2919000,
    trimf: 1500,
    1.5: 1025850,
    2.5: 959183,
    3.5: 881683,
    4.5: 821266,
  },
  {
    1: 1051250,
    2: 997083,
    3: 913750,
    4: 843750,
    5: 786250,
    "revenu brut": 2920000,
    trimf: 1500,
    1.5: 1026250,
    2.5: 959583,
    3.5: 882083,
    4.5: 821666,
  },
  {
    1: 1051650,
    2: 997483,
    3: 914150,
    4: 844150,
    5: 786650,
    "revenu brut": 2921_000,
    trimf: 1500,
    1.5: 1026650,
    2.5: 959983,
    3.5: 882483,
    4.5: 822066,
  },
  {
    1: 1052050,
    2: 997883,
    3: 914550,
    4: 844550,
    5: 787050,
    "revenu brut": 2922000,
    trimf: 1500,
    1.5: 1027050,
    2.5: 960383,
    3.5: 882883,
    4.5: 822466,
  },
  {
    1: 1052450,
    2: 998283,
    3: 914950,
    4: 844950,
    5: 787450,
    "revenu brut": 2923000,
    trimf: 1500,
    1.5: 1027450,
    2.5: 960783,
    3.5: 883283,
    4.5: 822866,
  },
  {
    1: 1052850,
    2: 998683,
    3: 915350,
    4: 845350,
    5: 787850,
    "revenu brut": 2924000,
    trimf: 1500,
    1.5: 1027850,
    2.5: 961183,
    3.5: 883683,
    4.5: 823266,
  },
  {
    1: 1053250,
    2: 999083,
    3: 915750,
    4: 845750,
    5: 788250,
    "revenu brut": 2925000,
    trimf: 1500,
    1.5: 1028250,
    2.5: 961583,
    3.5: 884083,
    4.5: 823666,
  },
  {
    1: 1053650,
    2: 999483,
    3: 916150,
    4: 846150,
    5: 788650,
    "revenu brut": 2926000,
    trimf: 1500,
    1.5: 1028650,
    2.5: 961983,
    3.5: 884483,
    4.5: 824066,
  },
  {
    1: 1054050,
    2: 999883,
    3: 916550,
    4: 846550,
    5: 789050,
    "revenu brut": 2927000,
    trimf: 1500,
    1.5: 1029050,
    2.5: 962383,
    3.5: 884883,
    4.5: 824466,
  },
  {
    1: 1054450,
    2: 1_000283,
    3: 916950,
    4: 846950,
    5: 789450,
    "revenu brut": 2928000,
    trimf: 1500,
    1.5: 1029450,
    2.5: 962783,
    3.5: 885283,
    4.5: 824866,
  },
  {
    1: 1054850,
    2: 1_000683,
    3: 917350,
    4: 847350,
    5: 789850,
    "revenu brut": 2929000,
    trimf: 1500,
    1.5: 1029850,
    2.5: 963183,
    3.5: 885683,
    4.5: 825266,
  },
  {
    1: 1055250,
    2: 1001083,
    3: 917750,
    4: 847750,
    5: 790250,
    "revenu brut": 2930000,
    trimf: 1500,
    1.5: 1030250,
    2.5: 963583,
    3.5: 886083,
    4.5: 825666,
  },
  {
    1: 1055650,
    2: 1001483,
    3: 918150,
    4: 848150,
    5: 790650,
    "revenu brut": 2931_000,
    trimf: 1500,
    1.5: 1030650,
    2.5: 963983,
    3.5: 886483,
    4.5: 826066,
  },
  {
    1: 1056050,
    2: 1001883,
    3: 918550,
    4: 848550,
    5: 791050,
    "revenu brut": 2932000,
    trimf: 1500,
    1.5: 1031050,
    2.5: 964383,
    3.5: 886883,
    4.5: 826466,
  },
  {
    1: 1056450,
    2: 1002283,
    3: 918950,
    4: 848950,
    5: 791450,
    "revenu brut": 2933000,
    trimf: 1500,
    1.5: 1031450,
    2.5: 964783,
    3.5: 887283,
    4.5: 826866,
  },
  {
    1: 1056850,
    2: 1002683,
    3: 919350,
    4: 849350,
    5: 791850,
    "revenu brut": 2934000,
    trimf: 1500,
    1.5: 1031850,
    2.5: 965183,
    3.5: 887683,
    4.5: 827266,
  },
  {
    1: 1057250,
    2: 1003083,
    3: 919750,
    4: 849750,
    5: 792250,
    "revenu brut": 2935000,
    trimf: 1500,
    1.5: 1032250,
    2.5: 965583,
    3.5: 888083,
    4.5: 827666,
  },
  {
    1: 1057650,
    2: 1003483,
    3: 920150,
    4: 850150,
    5: 792650,
    "revenu brut": 2936000,
    trimf: 1500,
    1.5: 1032650,
    2.5: 965983,
    3.5: 888483,
    4.5: 828066,
  },
  {
    1: 1058050,
    2: 1003883,
    3: 920550,
    4: 850550,
    5: 793050,
    "revenu brut": 2937000,
    trimf: 1500,
    1.5: 1033050,
    2.5: 966383,
    3.5: 888883,
    4.5: 828466,
  },
  {
    1: 1058450,
    2: 1004283,
    3: 920950,
    4: 850950,
    5: 793450,
    "revenu brut": 2938000,
    trimf: 1500,
    1.5: 1033450,
    2.5: 966783,
    3.5: 889283,
    4.5: 828866,
  },
  {
    1: 1058850,
    2: 1004683,
    3: 921350,
    4: 851350,
    5: 793850,
    "revenu brut": 2939000,
    trimf: 1500,
    1.5: 1033850,
    2.5: 967183,
    3.5: 889683,
    4.5: 829266,
  },
  {
    1: 1059250,
    2: 1005083,
    3: 921750,
    4: 851750,
    5: 794250,
    "revenu brut": 2940000,
    trimf: 1500,
    1.5: 1034250,
    2.5: 967583,
    3.5: 890083,
    4.5: 829666,
  },
  {
    1: 1059650,
    2: 1005483,
    3: 922150,
    4: 852150,
    5: 794650,
    "revenu brut": 2941_000,
    trimf: 1500,
    1.5: 1034650,
    2.5: 967983,
    3.5: 890483,
    4.5: 830066,
  },
  {
    1: 1060050,
    2: 1005883,
    3: 922550,
    4: 852550,
    5: 795050,
    "revenu brut": 2942000,
    trimf: 1500,
    1.5: 1035050,
    2.5: 968383,
    3.5: 890883,
    4.5: 830466,
  },
  {
    1: 1060450,
    2: 1006283,
    3: 922950,
    4: 852950,
    5: 795450,
    "revenu brut": 2943000,
    trimf: 1500,
    1.5: 1035450,
    2.5: 968783,
    3.5: 891283,
    4.5: 830866,
  },
  {
    1: 1060850,
    2: 1006683,
    3: 923350,
    4: 853350,
    5: 795850,
    "revenu brut": 2944000,
    trimf: 1500,
    1.5: 1035850,
    2.5: 969183,
    3.5: 891683,
    4.5: 831266,
  },
  {
    1: 1061250,
    2: 1007083,
    3: 923750,
    4: 853750,
    5: 796250,
    "revenu brut": 2945000,
    trimf: 1500,
    1.5: 1036250,
    2.5: 969583,
    3.5: 892083,
    4.5: 831666,
  },
  {
    1: 1061650,
    2: 1007483,
    3: 924150,
    4: 854150,
    5: 796650,
    "revenu brut": 2946000,
    trimf: 1500,
    1.5: 1036650,
    2.5: 969983,
    3.5: 892483,
    4.5: 832066,
  },
  {
    1: 1062050,
    2: 1007883,
    3: 924550,
    4: 854550,
    5: 797050,
    "revenu brut": 2947000,
    trimf: 1500,
    1.5: 1037050,
    2.5: 970383,
    3.5: 892883,
    4.5: 832466,
  },
  {
    1: 1062450,
    2: 1008283,
    3: 924950,
    4: 854950,
    5: 797450,
    "revenu brut": 2948000,
    trimf: 1500,
    1.5: 1037450,
    2.5: 970783,
    3.5: 893283,
    4.5: 832866,
  },
  {
    1: 1062850,
    2: 1008683,
    3: 925350,
    4: 855350,
    5: 797850,
    "revenu brut": 2949000,
    trimf: 1500,
    1.5: 1037850,
    2.5: 971183,
    3.5: 893683,
    4.5: 833266,
  },
  {
    1: 1063250,
    2: 1009083,
    3: 925750,
    4: 855750,
    5: 798250,
    "revenu brut": 2950000,
    trimf: 1500,
    1.5: 1038250,
    2.5: 971583,
    3.5: 894083,
    4.5: 833666,
  },
  {
    1: 1063650,
    2: 1009483,
    3: 926150,
    4: 856150,
    5: 798650,
    "revenu brut": 2951_000,
    trimf: 1500,
    1.5: 1038650,
    2.5: 971983,
    3.5: 894483,
    4.5: 834066,
  },
  {
    1: 1064050,
    2: 1009883,
    3: 926550,
    4: 856550,
    5: 799050,
    "revenu brut": 2952000,
    trimf: 1500,
    1.5: 1039050,
    2.5: 972383,
    3.5: 894883,
    4.5: 834466,
  },
  {
    1: 1064450,
    2: 1010283,
    3: 926950,
    4: 856950,
    5: 799450,
    "revenu brut": 2953000,
    trimf: 1500,
    1.5: 1039450,
    2.5: 972783,
    3.5: 895283,
    4.5: 834866,
  },
  {
    1: 1064850,
    2: 1010683,
    3: 927350,
    4: 857350,
    5: 799850,
    "revenu brut": 2954000,
    trimf: 1500,
    1.5: 1039850,
    2.5: 973183,
    3.5: 895683,
    4.5: 835266,
  },
  {
    1: 1065250,
    2: 1011083,
    3: 927750,
    4: 857750,
    5: 800250,
    "revenu brut": 2955000,
    trimf: 1500,
    1.5: 1040250,
    2.5: 973583,
    3.5: 896083,
    4.5: 835666,
  },
  {
    1: 1065650,
    2: 1011483,
    3: 928150,
    4: 858150,
    5: 800650,
    "revenu brut": 2956000,
    trimf: 1500,
    1.5: 1040650,
    2.5: 973983,
    3.5: 896483,
    4.5: 836066,
  },
  {
    1: 1066050,
    2: 1011883,
    3: 928550,
    4: 858550,
    5: 801050,
    "revenu brut": 2957000,
    trimf: 1500,
    1.5: 1041050,
    2.5: 974383,
    3.5: 896883,
    4.5: 836466,
  },
  {
    1: 1066450,
    2: 1012283,
    3: 928950,
    4: 858950,
    5: 801450,
    "revenu brut": 2958000,
    trimf: 1500,
    1.5: 1041450,
    2.5: 974783,
    3.5: 897283,
    4.5: 836866,
  },
  {
    1: 1066850,
    2: 1012683,
    3: 929350,
    4: 859350,
    5: 801850,
    "revenu brut": 2959000,
    trimf: 1500,
    1.5: 1041850,
    2.5: 975183,
    3.5: 897683,
    4.5: 837266,
  },
  {
    1: 1067250,
    2: 1013083,
    3: 929750,
    4: 859750,
    5: 802250,
    "revenu brut": 2960000,
    trimf: 1500,
    1.5: 1042250,
    2.5: 975583,
    3.5: 898083,
    4.5: 837666,
  },
  {
    1: 1067650,
    2: 1013483,
    3: 930150,
    4: 860150,
    5: 802650,
    "revenu brut": 2961_000,
    trimf: 1500,
    1.5: 1042650,
    2.5: 975983,
    3.5: 898483,
    4.5: 838066,
  },
  {
    1: 1068050,
    2: 1013883,
    3: 930550,
    4: 860550,
    5: 803050,
    "revenu brut": 2962000,
    trimf: 1500,
    1.5: 1043050,
    2.5: 976383,
    3.5: 898883,
    4.5: 838466,
  },
  {
    1: 1068450,
    2: 1014283,
    3: 930950,
    4: 860950,
    5: 803450,
    "revenu brut": 2963000,
    trimf: 1500,
    1.5: 1043450,
    2.5: 976783,
    3.5: 899283,
    4.5: 838866,
  },
  {
    1: 1068850,
    2: 1014683,
    3: 931350,
    4: 861350,
    5: 803850,
    "revenu brut": 2964000,
    trimf: 1500,
    1.5: 1043850,
    2.5: 977183,
    3.5: 899683,
    4.5: 839266,
  },
  {
    1: 1069250,
    2: 1015083,
    3: 931750,
    4: 861750,
    5: 804250,
    "revenu brut": 2965000,
    trimf: 1500,
    1.5: 1044250,
    2.5: 977583,
    3.5: 900083,
    4.5: 839666,
  },
  {
    1: 1069650,
    2: 1015483,
    3: 932150,
    4: 862150,
    5: 804650,
    "revenu brut": 2966000,
    trimf: 1500,
    1.5: 1044650,
    2.5: 977983,
    3.5: 900483,
    4.5: 840066,
  },
  {
    1: 1070050,
    2: 1015883,
    3: 932550,
    4: 862550,
    5: 805050,
    "revenu brut": 2967000,
    trimf: 1500,
    1.5: 1045050,
    2.5: 978383,
    3.5: 900883,
    4.5: 840466,
  },
  {
    1: 1070450,
    2: 1016283,
    3: 932950,
    4: 862950,
    5: 805450,
    "revenu brut": 2968000,
    trimf: 1500,
    1.5: 1045450,
    2.5: 978783,
    3.5: 901283,
    4.5: 840866,
  },
  {
    1: 1070850,
    2: 1016683,
    3: 933350,
    4: 863350,
    5: 805850,
    "revenu brut": 2969000,
    trimf: 1500,
    1.5: 1045850,
    2.5: 979183,
    3.5: 901683,
    4.5: 841266,
  },
  {
    1: 1071250,
    2: 1017083,
    3: 933750,
    4: 863750,
    5: 806250,
    "revenu brut": 2970000,
    trimf: 1500,
    1.5: 1046250,
    2.5: 979583,
    3.5: 902083,
    4.5: 841666,
  },
  {
    1: 1071650,
    2: 1017483,
    3: 934150,
    4: 864150,
    5: 806650,
    "revenu brut": 2971_000,
    trimf: 1500,
    1.5: 1046650,
    2.5: 979983,
    3.5: 902483,
    4.5: 842066,
  },
  {
    1: 1072050,
    2: 1017883,
    3: 934550,
    4: 864550,
    5: 807050,
    "revenu brut": 2972000,
    trimf: 1500,
    1.5: 1047050,
    2.5: 980383,
    3.5: 902883,
    4.5: 842466,
  },
  {
    1: 1072450,
    2: 1018283,
    3: 934950,
    4: 864950,
    5: 807450,
    "revenu brut": 2973000,
    trimf: 1500,
    1.5: 1047450,
    2.5: 980783,
    3.5: 903283,
    4.5: 842866,
  },
  {
    1: 1072850,
    2: 1018683,
    3: 935350,
    4: 865350,
    5: 807850,
    "revenu brut": 2974000,
    trimf: 1500,
    1.5: 1047850,
    2.5: 981183,
    3.5: 903683,
    4.5: 843266,
  },
  {
    1: 1073250,
    2: 1019083,
    3: 935750,
    4: 865750,
    5: 808250,
    "revenu brut": 2975000,
    trimf: 1500,
    1.5: 1048250,
    2.5: 981583,
    3.5: 904083,
    4.5: 843666,
  },
  {
    1: 1073650,
    2: 1019483,
    3: 936150,
    4: 866150,
    5: 808650,
    "revenu brut": 2976000,
    trimf: 1500,
    1.5: 1048650,
    2.5: 981983,
    3.5: 904483,
    4.5: 844066,
  },
  {
    1: 1074050,
    2: 1019883,
    3: 936550,
    4: 866550,
    5: 809050,
    "revenu brut": 2977000,
    trimf: 1500,
    1.5: 1049050,
    2.5: 982383,
    3.5: 904883,
    4.5: 844466,
  },
  {
    1: 1074450,
    2: 1020283,
    3: 936950,
    4: 866950,
    5: 809450,
    "revenu brut": 2978000,
    trimf: 1500,
    1.5: 1049450,
    2.5: 982783,
    3.5: 905283,
    4.5: 844866,
  },
  {
    1: 1074850,
    2: 1020683,
    3: 937350,
    4: 867350,
    5: 809850,
    "revenu brut": 2979000,
    trimf: 1500,
    1.5: 1049850,
    2.5: 983183,
    3.5: 905683,
    4.5: 845266,
  },
  {
    1: 1075250,
    2: 1021083,
    3: 937750,
    4: 867750,
    5: 810250,
    "revenu brut": 2980000,
    trimf: 1500,
    1.5: 1050250,
    2.5: 983583,
    3.5: 906083,
    4.5: 845666,
  },
  {
    1: 1075650,
    2: 1021483,
    3: 938150,
    4: 868150,
    5: 810650,
    "revenu brut": 2981_000,
    trimf: 1500,
    1.5: 1050650,
    2.5: 983983,
    3.5: 906483,
    4.5: 846066,
  },
  {
    1: 1076050,
    2: 1021883,
    3: 938550,
    4: 868550,
    5: 811050,
    "revenu brut": 2982000,
    trimf: 1500,
    1.5: 1051050,
    2.5: 984383,
    3.5: 906883,
    4.5: 846466,
  },
  {
    1: 1076450,
    2: 1022283,
    3: 938950,
    4: 868950,
    5: 811450,
    "revenu brut": 2983000,
    trimf: 1500,
    1.5: 1051450,
    2.5: 984783,
    3.5: 907283,
    4.5: 846866,
  },
  {
    1: 1076850,
    2: 1022683,
    3: 939350,
    4: 869350,
    5: 811850,
    "revenu brut": 2984000,
    trimf: 1500,
    1.5: 1051850,
    2.5: 985183,
    3.5: 907683,
    4.5: 847266,
  },
  {
    1: 1077250,
    2: 1023083,
    3: 939750,
    4: 869750,
    5: 812250,
    "revenu brut": 2985000,
    trimf: 1500,
    1.5: 1052250,
    2.5: 985583,
    3.5: 908083,
    4.5: 847666,
  },
  {
    1: 1077650,
    2: 1023483,
    3: 940150,
    4: 870150,
    5: 812650,
    "revenu brut": 2986000,
    trimf: 1500,
    1.5: 1052650,
    2.5: 985983,
    3.5: 908483,
    4.5: 848066,
  },
  {
    1: 1078050,
    2: 1023883,
    3: 940550,
    4: 870550,
    5: 813050,
    "revenu brut": 2987000,
    trimf: 1500,
    1.5: 1053050,
    2.5: 986383,
    3.5: 908883,
    4.5: 848466,
  },
  {
    1: 1078450,
    2: 1024283,
    3: 940950,
    4: 870950,
    5: 813450,
    "revenu brut": 2988000,
    trimf: 1500,
    1.5: 1053450,
    2.5: 986783,
    3.5: 909283,
    4.5: 848866,
  },
  {
    1: 1078850,
    2: 1024683,
    3: 941350,
    4: 871350,
    5: 813850,
    "revenu brut": 2989000,
    trimf: 1500,
    1.5: 1053850,
    2.5: 987183,
    3.5: 909683,
    4.5: 849266,
  },
  {
    1: 1079250,
    2: 1025083,
    3: 941750,
    4: 871750,
    5: 814250,
    "revenu brut": 2990000,
    trimf: 1500,
    1.5: 1054250,
    2.5: 987583,
    3.5: 910083,
    4.5: 849666,
  },
  {
    1: 1079650,
    2: 1025483,
    3: 942150,
    4: 872150,
    5: 814650,
    "revenu brut": 2991_000,
    trimf: 1500,
    1.5: 1054650,
    2.5: 987983,
    3.5: 910483,
    4.5: 850066,
  },
  {
    1: 1080050,
    2: 1025883,
    3: 942550,
    4: 872550,
    5: 815050,
    "revenu brut": 2992000,
    trimf: 1500,
    1.5: 1055050,
    2.5: 988383,
    3.5: 910883,
    4.5: 850466,
  },
  {
    1: 1080450,
    2: 1026283,
    3: 942950,
    4: 872950,
    5: 815450,
    "revenu brut": 2993000,
    trimf: 1500,
    1.5: 1055450,
    2.5: 988783,
    3.5: 911283,
    4.5: 850866,
  },
  {
    1: 1080850,
    2: 1026683,
    3: 943350,
    4: 873350,
    5: 815850,
    "revenu brut": 2994000,
    trimf: 1500,
    1.5: 1055850,
    2.5: 989183,
    3.5: 911683,
    4.5: 851266,
  },
  {
    1: 1081250,
    2: 1027083,
    3: 943750,
    4: 873750,
    5: 816250,
    "revenu brut": 2995000,
    trimf: 1500,
    1.5: 1056250,
    2.5: 989583,
    3.5: 912083,
    4.5: 851666,
  },
  {
    1: 1081650,
    2: 1027483,
    3: 944150,
    4: 874150,
    5: 816650,
    "revenu brut": 2996000,
    trimf: 1500,
    1.5: 1056650,
    2.5: 989983,
    3.5: 912483,
    4.5: 852066,
  },
  {
    1: 1082050,
    2: 1027883,
    3: 944550,
    4: 874550,
    5: 817050,
    "revenu brut": 2997000,
    trimf: 1500,
    1.5: 1057050,
    2.5: 990383,
    3.5: 912883,
    4.5: 852466,
  },
  {
    1: 1082450,
    2: 1028283,
    3: 944950,
    4: 874950,
    5: 817450,
    "revenu brut": 2998000,
    trimf: 1500,
    1.5: 1057450,
    2.5: 990783,
    3.5: 913283,
    4.5: 852866,
  },
  {
    1: 1082850,
    2: 1028683,
    3: 945350,
    4: 875350,
    5: 817850,
    "revenu brut": 2999000,
    trimf: 1500,
    1.5: 1057850,
    2.5: 991183,
    3.5: 913683,
    4.5: 853266,
  },
  {
    1: 1083250,
    2: 1029083,
    3: 945750,
    4: 875750,
    5: 818250,
    "revenu brut": 3000000,
    trimf: 1500,
    1.5: 1058250,
    2.5: 991583,
    3.5: 914083,
    4.5: 853666,
  },
  {
    1: 1083650,
    2: 1029483,
    3: 946150,
    4: 876150,
    5: 818650,
    "revenu brut": 3001_000,
    trimf: 1500,
    1.5: 1058650,
    2.5: 991983,
    3.5: 914483,
    4.5: 854066,
  },
  {
    1: 1084050,
    2: 1029883,
    3: 946550,
    4: 876550,
    5: 819050,
    "revenu brut": 3002000,
    trimf: 1500,
    1.5: 1059050,
    2.5: 992383,
    3.5: 914883,
    4.5: 854466,
  },
  {
    1: 1084450,
    2: 1030283,
    3: 946950,
    4: 876950,
    5: 819450,
    "revenu brut": 3003000,
    trimf: 1500,
    1.5: 1059450,
    2.5: 992783,
    3.5: 915283,
    4.5: 854866,
  },
  {
    1: 1084850,
    2: 1030683,
    3: 947350,
    4: 877350,
    5: 819850,
    "revenu brut": 3004000,
    trimf: 1500,
    1.5: 1059850,
    2.5: 993183,
    3.5: 915683,
    4.5: 855266,
  },
  {
    1: 1085250,
    2: 1031083,
    3: 947750,
    4: 877750,
    5: 820250,
    "revenu brut": 3005000,
    trimf: 1500,
    1.5: 1060250,
    2.5: 993583,
    3.5: 916083,
    4.5: 855666,
  },
  {
    1: 1085650,
    2: 1031483,
    3: 948150,
    4: 878150,
    5: 820650,
    "revenu brut": 3006000,
    trimf: 1500,
    1.5: 1060650,
    2.5: 993983,
    3.5: 916483,
    4.5: 856066,
  },
  {
    1: 1086050,
    2: 1031883,
    3: 948550,
    4: 878550,
    5: 821050,
    "revenu brut": 3007000,
    trimf: 1500,
    1.5: 1061050,
    2.5: 994383,
    3.5: 916883,
    4.5: 856466,
  },
  {
    1: 1086450,
    2: 1032283,
    3: 948950,
    4: 878950,
    5: 821450,
    "revenu brut": 3008000,
    trimf: 1500,
    1.5: 1061450,
    2.5: 994783,
    3.5: 917283,
    4.5: 856866,
  },
  {
    1: 1086850,
    2: 1032683,
    3: 949350,
    4: 879350,
    5: 821850,
    "revenu brut": 3009000,
    trimf: 1500,
    1.5: 1061850,
    2.5: 995183,
    3.5: 917683,
    4.5: 857266,
  },
  {
    1: 1087250,
    2: 1033083,
    3: 949750,
    4: 879750,
    5: 822250,
    "revenu brut": 301_0000,
    trimf: 1500,
    1.5: 1062250,
    2.5: 995583,
    3.5: 918083,
    4.5: 857666,
  },
  {
    1: 1087650,
    2: 1033483,
    3: 950150,
    4: 880150,
    5: 822650,
    "revenu brut": 3011_000,
    trimf: 1500,
    1.5: 1062650,
    2.5: 995983,
    3.5: 918483,
    4.5: 858066,
  },
  {
    1: 1088050,
    2: 1033883,
    3: 950550,
    4: 880550,
    5: 823050,
    "revenu brut": 3012000,
    trimf: 1500,
    1.5: 1063050,
    2.5: 996383,
    3.5: 918883,
    4.5: 858466,
  },
  {
    1: 1088450,
    2: 1034283,
    3: 950950,
    4: 880950,
    5: 823450,
    "revenu brut": 3013000,
    trimf: 1500,
    1.5: 1063450,
    2.5: 996783,
    3.5: 919283,
    4.5: 858866,
  },
  {
    1: 1088850,
    2: 1034683,
    3: 951350,
    4: 881350,
    5: 823850,
    "revenu brut": 3014000,
    trimf: 1500,
    1.5: 1063850,
    2.5: 997183,
    3.5: 919683,
    4.5: 859266,
  },
  {
    1: 1089250,
    2: 1035083,
    3: 951750,
    4: 881750,
    5: 824250,
    "revenu brut": 3015000,
    trimf: 1500,
    1.5: 1064250,
    2.5: 997583,
    3.5: 920083,
    4.5: 859666,
  },
  {
    1: 1089650,
    2: 1035483,
    3: 952150,
    4: 882150,
    5: 824650,
    "revenu brut": 3016000,
    trimf: 1500,
    1.5: 1064650,
    2.5: 997983,
    3.5: 920483,
    4.5: 860066,
  },
  {
    1: 1090050,
    2: 1035883,
    3: 952550,
    4: 882550,
    5: 825050,
    "revenu brut": 3017000,
    trimf: 1500,
    1.5: 1065050,
    2.5: 998383,
    3.5: 920883,
    4.5: 860466,
  },
  {
    1: 1090450,
    2: 1036283,
    3: 952950,
    4: 882950,
    5: 825450,
    "revenu brut": 3018000,
    trimf: 1500,
    1.5: 1065450,
    2.5: 998783,
    3.5: 921283,
    4.5: 860866,
  },
  {
    1: 1090850,
    2: 1036683,
    3: 953350,
    4: 883350,
    5: 825850,
    "revenu brut": 3019000,
    trimf: 1500,
    1.5: 1065850,
    2.5: 999183,
    3.5: 921683,
    4.5: 861266,
  },
  {
    1: 1091250,
    2: 1037083,
    3: 953750,
    4: 883750,
    5: 826250,
    "revenu brut": 3020000,
    trimf: 1500,
    1.5: 1066250,
    2.5: 999583,
    3.5: 922083,
    4.5: 861666,
  },
  {
    1: 1091650,
    2: 1037483,
    3: 954150,
    4: 884150,
    5: 826650,
    "revenu brut": 3021_000,
    trimf: 1500,
    1.5: 1066650,
    2.5: 999983,
    3.5: 922483,
    4.5: 862066,
  },
  {
    1: 1092050,
    2: 1037883,
    3: 954550,
    4: 884550,
    5: 827050,
    "revenu brut": 3022000,
    trimf: 1500,
    1.5: 1067050,
    2.5: 1_000383,
    3.5: 922883,
    4.5: 862466,
  },
  {
    1: 1092450,
    2: 1038283,
    3: 954950,
    4: 884950,
    5: 827450,
    "revenu brut": 3023000,
    trimf: 1500,
    1.5: 1067450,
    2.5: 1_000783,
    3.5: 923283,
    4.5: 862866,
  },
  {
    1: 1092850,
    2: 1038683,
    3: 955350,
    4: 885350,
    5: 827850,
    "revenu brut": 3024000,
    trimf: 1500,
    1.5: 1067850,
    2.5: 1001183,
    3.5: 923683,
    4.5: 863266,
  },
  {
    1: 1093250,
    2: 1039083,
    3: 955750,
    4: 885750,
    5: 828250,
    "revenu brut": 3025000,
    trimf: 1500,
    1.5: 1068250,
    2.5: 1001583,
    3.5: 924083,
    4.5: 863666,
  },
  {
    1: 1093650,
    2: 1039483,
    3: 956150,
    4: 886150,
    5: 828650,
    "revenu brut": 3026000,
    trimf: 1500,
    1.5: 1068650,
    2.5: 1001983,
    3.5: 924483,
    4.5: 864066,
  },
  {
    1: 1094050,
    2: 1039883,
    3: 956550,
    4: 886550,
    5: 829050,
    "revenu brut": 3027000,
    trimf: 1500,
    1.5: 1069050,
    2.5: 1002383,
    3.5: 924883,
    4.5: 864466,
  },
  {
    1: 1094450,
    2: 1040283,
    3: 956950,
    4: 886950,
    5: 829450,
    "revenu brut": 3028000,
    trimf: 1500,
    1.5: 1069450,
    2.5: 1002783,
    3.5: 925283,
    4.5: 864866,
  },
  {
    1: 1094850,
    2: 1040683,
    3: 957350,
    4: 887350,
    5: 829850,
    "revenu brut": 3029000,
    trimf: 1500,
    1.5: 1069850,
    2.5: 1003183,
    3.5: 925683,
    4.5: 865266,
  },
  {
    1: 1095250,
    2: 1041083,
    3: 957750,
    4: 887750,
    5: 830250,
    "revenu brut": 3030000,
    trimf: 1500,
    1.5: 1070250,
    2.5: 1003583,
    3.5: 926083,
    4.5: 865666,
  },
  {
    1: 1095650,
    2: 1041483,
    3: 958150,
    4: 888150,
    5: 830650,
    "revenu brut": 3031_000,
    trimf: 1500,
    1.5: 1070650,
    2.5: 1003983,
    3.5: 926483,
    4.5: 866066,
  },
  {
    1: 1096050,
    2: 1041883,
    3: 958550,
    4: 888550,
    5: 831050,
    "revenu brut": 3032000,
    trimf: 1500,
    1.5: 1071050,
    2.5: 1004383,
    3.5: 926883,
    4.5: 866466,
  },
  {
    1: 1096450,
    2: 1042283,
    3: 958950,
    4: 888950,
    5: 831450,
    "revenu brut": 3033000,
    trimf: 1500,
    1.5: 1071450,
    2.5: 1004783,
    3.5: 927283,
    4.5: 866866,
  },
  {
    1: 1096850,
    2: 1042683,
    3: 959350,
    4: 889350,
    5: 831850,
    "revenu brut": 3034000,
    trimf: 1500,
    1.5: 1071850,
    2.5: 1005183,
    3.5: 927683,
    4.5: 867266,
  },
  {
    1: 1097250,
    2: 1043083,
    3: 959750,
    4: 889750,
    5: 832250,
    "revenu brut": 3035000,
    trimf: 1500,
    1.5: 1072250,
    2.5: 1005583,
    3.5: 928083,
    4.5: 867666,
  },
  {
    1: 1097650,
    2: 1043483,
    3: 960150,
    4: 890150,
    5: 832650,
    "revenu brut": 3036000,
    trimf: 1500,
    1.5: 1072650,
    2.5: 1005983,
    3.5: 928483,
    4.5: 868066,
  },
  {
    1: 1098050,
    2: 1043883,
    3: 960550,
    4: 890550,
    5: 833050,
    "revenu brut": 3037000,
    trimf: 1500,
    1.5: 1073050,
    2.5: 1006383,
    3.5: 928883,
    4.5: 868466,
  },
  {
    1: 1098450,
    2: 1044283,
    3: 960950,
    4: 890950,
    5: 833450,
    "revenu brut": 3038000,
    trimf: 1500,
    1.5: 1073450,
    2.5: 1006783,
    3.5: 929283,
    4.5: 868866,
  },
  {
    1: 1098850,
    2: 1044683,
    3: 961350,
    4: 891350,
    5: 833850,
    "revenu brut": 3039000,
    trimf: 1500,
    1.5: 1073850,
    2.5: 1007183,
    3.5: 929683,
    4.5: 869266,
  },
  {
    1: 1099250,
    2: 1045083,
    3: 961750,
    4: 891750,
    5: 834250,
    "revenu brut": 3040000,
    trimf: 1500,
    1.5: 1074250,
    2.5: 1007583,
    3.5: 930083,
    4.5: 869666,
  },
  {
    1: 1099650,
    2: 1045483,
    3: 962150,
    4: 892150,
    5: 834650,
    "revenu brut": 3041_000,
    trimf: 1500,
    1.5: 1074650,
    2.5: 1007983,
    3.5: 930483,
    4.5: 870066,
  },
  {
    1: 11_00050,
    2: 1045883,
    3: 962550,
    4: 892550,
    5: 835050,
    "revenu brut": 3042000,
    trimf: 1500,
    1.5: 1075050,
    2.5: 1008383,
    3.5: 930883,
    4.5: 870466,
  },
  {
    1: 1100450,
    2: 1046283,
    3: 962950,
    4: 892950,
    5: 835450,
    "revenu brut": 3043000,
    trimf: 1500,
    1.5: 1075450,
    2.5: 1008783,
    3.5: 931283,
    4.5: 870866,
  },
  {
    1: 1100850,
    2: 1046683,
    3: 963350,
    4: 893350,
    5: 835850,
    "revenu brut": 3044000,
    trimf: 1500,
    1.5: 1075850,
    2.5: 1009183,
    3.5: 931683,
    4.5: 871266,
  },
  {
    1: 1101250,
    2: 1047083,
    3: 963750,
    4: 893750,
    5: 836250,
    "revenu brut": 3045000,
    trimf: 1500,
    1.5: 1076250,
    2.5: 1009583,
    3.5: 932083,
    4.5: 871666,
  },
  {
    1: 1101650,
    2: 1047483,
    3: 964150,
    4: 894150,
    5: 836650,
    "revenu brut": 3046000,
    trimf: 1500,
    1.5: 1076650,
    2.5: 1009983,
    3.5: 932483,
    4.5: 872066,
  },
  {
    1: 1102050,
    2: 1047883,
    3: 964550,
    4: 894550,
    5: 837050,
    "revenu brut": 3047000,
    trimf: 1500,
    1.5: 1077050,
    2.5: 1010383,
    3.5: 932883,
    4.5: 872466,
  },
  {
    1: 1102450,
    2: 1048283,
    3: 964950,
    4: 894950,
    5: 837450,
    "revenu brut": 3048000,
    trimf: 1500,
    1.5: 1077450,
    2.5: 1010783,
    3.5: 933283,
    4.5: 872866,
  },
  {
    1: 1102850,
    2: 1048683,
    3: 965350,
    4: 895350,
    5: 837850,
    "revenu brut": 3049000,
    trimf: 1500,
    1.5: 1077850,
    2.5: 1011183,
    3.5: 933683,
    4.5: 873266,
  },
  {
    1: 1103250,
    2: 1049083,
    3: 965750,
    4: 895750,
    5: 838250,
    "revenu brut": 3050000,
    trimf: 1500,
    1.5: 1078250,
    2.5: 1011583,
    3.5: 934083,
    4.5: 873666,
  },
  {
    1: 1103650,
    2: 1049483,
    3: 966150,
    4: 896150,
    5: 838650,
    "revenu brut": 3051_000,
    trimf: 1500,
    1.5: 1078650,
    2.5: 1011983,
    3.5: 934483,
    4.5: 874066,
  },
  {
    1: 1104050,
    2: 1049883,
    3: 966550,
    4: 896550,
    5: 839050,
    "revenu brut": 3052000,
    trimf: 1500,
    1.5: 1079050,
    2.5: 1012383,
    3.5: 934883,
    4.5: 874466,
  },
  {
    1: 1104450,
    2: 1050283,
    3: 966950,
    4: 896950,
    5: 839450,
    "revenu brut": 3053000,
    trimf: 1500,
    1.5: 1079450,
    2.5: 1012783,
    3.5: 935283,
    4.5: 874866,
  },
  {
    1: 1104850,
    2: 1050683,
    3: 967350,
    4: 897350,
    5: 839850,
    "revenu brut": 3054000,
    trimf: 1500,
    1.5: 1079850,
    2.5: 1013183,
    3.5: 935683,
    4.5: 875266,
  },
  {
    1: 1105250,
    2: 1051083,
    3: 967750,
    4: 897750,
    5: 840250,
    "revenu brut": 3055000,
    trimf: 1500,
    1.5: 1080250,
    2.5: 1013583,
    3.5: 936083,
    4.5: 875666,
  },
  {
    1: 1105650,
    2: 1051483,
    3: 968150,
    4: 898150,
    5: 840650,
    "revenu brut": 3056000,
    trimf: 1500,
    1.5: 1080650,
    2.5: 1013983,
    3.5: 936483,
    4.5: 876066,
  },
  {
    1: 1106050,
    2: 1051883,
    3: 968550,
    4: 898550,
    5: 841050,
    "revenu brut": 3057000,
    trimf: 1500,
    1.5: 1081050,
    2.5: 1014383,
    3.5: 936883,
    4.5: 876466,
  },
  {
    1: 1106450,
    2: 1052283,
    3: 968950,
    4: 898950,
    5: 841450,
    "revenu brut": 3058000,
    trimf: 1500,
    1.5: 1081450,
    2.5: 1014783,
    3.5: 937283,
    4.5: 876866,
  },
  {
    1: 1106850,
    2: 1052683,
    3: 969350,
    4: 899350,
    5: 841850,
    "revenu brut": 3059000,
    trimf: 1500,
    1.5: 1081850,
    2.5: 1015183,
    3.5: 937683,
    4.5: 877266,
  },
  {
    1: 1107250,
    2: 1053083,
    3: 969750,
    4: 899750,
    5: 842250,
    "revenu brut": 3060000,
    trimf: 1500,
    1.5: 1082250,
    2.5: 1015583,
    3.5: 938083,
    4.5: 877666,
  },
  {
    1: 1107650,
    2: 1053483,
    3: 970150,
    4: 900150,
    5: 842650,
    "revenu brut": 3061_000,
    trimf: 1500,
    1.5: 1082650,
    2.5: 1015983,
    3.5: 938483,
    4.5: 878066,
  },
  {
    1: 1108050,
    2: 1053883,
    3: 970550,
    4: 900550,
    5: 843050,
    "revenu brut": 3062000,
    trimf: 1500,
    1.5: 1083050,
    2.5: 1016383,
    3.5: 938883,
    4.5: 878466,
  },
  {
    1: 1108450,
    2: 1054283,
    3: 970950,
    4: 900950,
    5: 843450,
    "revenu brut": 3063000,
    trimf: 1500,
    1.5: 1083450,
    2.5: 1016783,
    3.5: 939283,
    4.5: 878866,
  },
  {
    1: 1108850,
    2: 1054683,
    3: 971350,
    4: 901350,
    5: 843850,
    "revenu brut": 3064000,
    trimf: 1500,
    1.5: 1083850,
    2.5: 1017183,
    3.5: 939683,
    4.5: 879266,
  },
  {
    1: 1109250,
    2: 1055083,
    3: 971750,
    4: 901750,
    5: 844250,
    "revenu brut": 3065000,
    trimf: 1500,
    1.5: 1084250,
    2.5: 1017583,
    3.5: 940083,
    4.5: 879666,
  },
  {
    1: 1109650,
    2: 1055483,
    3: 972150,
    4: 902150,
    5: 844650,
    "revenu brut": 3066000,
    trimf: 1500,
    1.5: 1084650,
    2.5: 1017983,
    3.5: 940483,
    4.5: 880066,
  },
  {
    1: 1110050,
    2: 1055883,
    3: 972550,
    4: 902550,
    5: 845050,
    "revenu brut": 3067000,
    trimf: 1500,
    1.5: 1085050,
    2.5: 1018383,
    3.5: 940883,
    4.5: 880466,
  },
  {
    1: 1110450,
    2: 1056283,
    3: 972950,
    4: 902950,
    5: 845450,
    "revenu brut": 3068000,
    trimf: 1500,
    1.5: 1085450,
    2.5: 1018783,
    3.5: 941283,
    4.5: 880866,
  },
  {
    1: 1110850,
    2: 1056683,
    3: 973350,
    4: 903350,
    5: 845850,
    "revenu brut": 3069000,
    trimf: 1500,
    1.5: 1085850,
    2.5: 1019183,
    3.5: 941683,
    4.5: 881266,
  },
  {
    1: 1111250,
    2: 1057083,
    3: 973750,
    4: 903750,
    5: 846250,
    "revenu brut": 3070000,
    trimf: 1500,
    1.5: 1086250,
    2.5: 1019583,
    3.5: 942083,
    4.5: 881666,
  },
  {
    1: 1111650,
    2: 1057483,
    3: 974150,
    4: 904150,
    5: 846650,
    "revenu brut": 3071_000,
    trimf: 1500,
    1.5: 1086650,
    2.5: 1019983,
    3.5: 942483,
    4.5: 882066,
  },
  {
    1: 1112050,
    2: 1057883,
    3: 974550,
    4: 904550,
    5: 847050,
    "revenu brut": 3072000,
    trimf: 1500,
    1.5: 1087050,
    2.5: 1020383,
    3.5: 942883,
    4.5: 882466,
  },
  {
    1: 1112450,
    2: 1058283,
    3: 974950,
    4: 904950,
    5: 847450,
    "revenu brut": 3073000,
    trimf: 1500,
    1.5: 1087450,
    2.5: 1020783,
    3.5: 943283,
    4.5: 882866,
  },
  {
    1: 1112850,
    2: 1058683,
    3: 975350,
    4: 905350,
    5: 847850,
    "revenu brut": 3074000,
    trimf: 1500,
    1.5: 1087850,
    2.5: 1021183,
    3.5: 943683,
    4.5: 883266,
  },
  {
    1: 1113250,
    2: 1059083,
    3: 975750,
    4: 905750,
    5: 848250,
    "revenu brut": 3075000,
    trimf: 1500,
    1.5: 1088250,
    2.5: 1021583,
    3.5: 944083,
    4.5: 883666,
  },
  {
    1: 1113650,
    2: 1059483,
    3: 976150,
    4: 906150,
    5: 848650,
    "revenu brut": 3076000,
    trimf: 1500,
    1.5: 1088650,
    2.5: 1021983,
    3.5: 944483,
    4.5: 884066,
  },
  {
    1: 1114050,
    2: 1059883,
    3: 976550,
    4: 906550,
    5: 849050,
    "revenu brut": 3077000,
    trimf: 1500,
    1.5: 1089050,
    2.5: 1022383,
    3.5: 944883,
    4.5: 884466,
  },
  {
    1: 1114450,
    2: 1060283,
    3: 976950,
    4: 906950,
    5: 849450,
    "revenu brut": 3078000,
    trimf: 1500,
    1.5: 1089450,
    2.5: 1022783,
    3.5: 945283,
    4.5: 884866,
  },
  {
    1: 1114850,
    2: 1060683,
    3: 977350,
    4: 907350,
    5: 849850,
    "revenu brut": 3079000,
    trimf: 1500,
    1.5: 1089850,
    2.5: 1023183,
    3.5: 945683,
    4.5: 885266,
  },
  {
    1: 1115250,
    2: 1061083,
    3: 977750,
    4: 907750,
    5: 850250,
    "revenu brut": 3080000,
    trimf: 1500,
    1.5: 1090250,
    2.5: 1023583,
    3.5: 946083,
    4.5: 885666,
  },
  {
    1: 1115650,
    2: 1061483,
    3: 978150,
    4: 908150,
    5: 850650,
    "revenu brut": 3081_000,
    trimf: 1500,
    1.5: 1090650,
    2.5: 1023983,
    3.5: 946483,
    4.5: 886066,
  },
  {
    1: 1116050,
    2: 1061883,
    3: 978550,
    4: 908550,
    5: 851050,
    "revenu brut": 3082000,
    trimf: 1500,
    1.5: 1091050,
    2.5: 1024383,
    3.5: 946883,
    4.5: 886466,
  },
  {
    1: 1116450,
    2: 1062283,
    3: 978950,
    4: 908950,
    5: 851450,
    "revenu brut": 3083000,
    trimf: 1500,
    1.5: 1091450,
    2.5: 1024783,
    3.5: 947283,
    4.5: 886866,
  },
  {
    1: 1116850,
    2: 1062683,
    3: 979350,
    4: 909350,
    5: 851850,
    "revenu brut": 3084000,
    trimf: 1500,
    1.5: 1091850,
    2.5: 1025183,
    3.5: 947683,
    4.5: 887266,
  },
  {
    1: 1117250,
    2: 1063083,
    3: 979750,
    4: 909750,
    5: 852250,
    "revenu brut": 3085000,
    trimf: 1500,
    1.5: 1092250,
    2.5: 1025583,
    3.5: 948083,
    4.5: 887666,
  },
  {
    1: 1117650,
    2: 1063483,
    3: 980150,
    4: 910150,
    5: 852650,
    "revenu brut": 3086000,
    trimf: 1500,
    1.5: 1092650,
    2.5: 1025983,
    3.5: 948483,
    4.5: 888066,
  },
  {
    1: 1118050,
    2: 1063883,
    3: 980550,
    4: 910550,
    5: 853050,
    "revenu brut": 3087000,
    trimf: 1500,
    1.5: 1093050,
    2.5: 1026383,
    3.5: 948883,
    4.5: 888466,
  },
  {
    1: 1118450,
    2: 1064283,
    3: 980950,
    4: 910950,
    5: 853450,
    "revenu brut": 3088000,
    trimf: 1500,
    1.5: 1093450,
    2.5: 1026783,
    3.5: 949283,
    4.5: 888866,
  },
  {
    1: 1118850,
    2: 1064683,
    3: 981350,
    4: 911350,
    5: 853850,
    "revenu brut": 3089000,
    trimf: 1500,
    1.5: 1093850,
    2.5: 1027183,
    3.5: 949683,
    4.5: 889266,
  },
  {
    1: 1119250,
    2: 1065083,
    3: 981750,
    4: 911750,
    5: 854250,
    "revenu brut": 3090000,
    trimf: 1500,
    1.5: 1094250,
    2.5: 1027583,
    3.5: 950083,
    4.5: 889666,
  },
  {
    1: 1119650,
    2: 1065483,
    3: 982150,
    4: 912150,
    5: 854650,
    "revenu brut": 3091_000,
    trimf: 1500,
    1.5: 1094650,
    2.5: 1027983,
    3.5: 950483,
    4.5: 890066,
  },
  {
    1: 1120050,
    2: 1065883,
    3: 982550,
    4: 912550,
    5: 855050,
    "revenu brut": 3092000,
    trimf: 1500,
    1.5: 1095050,
    2.5: 1028383,
    3.5: 950883,
    4.5: 890466,
  },
  {
    1: 1120450,
    2: 1066283,
    3: 982950,
    4: 912950,
    5: 855450,
    "revenu brut": 3093000,
    trimf: 1500,
    1.5: 1095450,
    2.5: 1028783,
    3.5: 951283,
    4.5: 890866,
  },
  {
    1: 1120850,
    2: 1066683,
    3: 983350,
    4: 913350,
    5: 855850,
    "revenu brut": 3094000,
    trimf: 1500,
    1.5: 1095850,
    2.5: 1029183,
    3.5: 951683,
    4.5: 891266,
  },
  {
    1: 1121250,
    2: 1067083,
    3: 983750,
    4: 913750,
    5: 856250,
    "revenu brut": 3095000,
    trimf: 1500,
    1.5: 1096250,
    2.5: 1029583,
    3.5: 952083,
    4.5: 891666,
  },
  {
    1: 1121650,
    2: 1067483,
    3: 984150,
    4: 914150,
    5: 856650,
    "revenu brut": 3096000,
    trimf: 1500,
    1.5: 1096650,
    2.5: 1029983,
    3.5: 952483,
    4.5: 892066,
  },
  {
    1: 1122050,
    2: 1067883,
    3: 984550,
    4: 914550,
    5: 857050,
    "revenu brut": 3097000,
    trimf: 1500,
    1.5: 1097050,
    2.5: 1030383,
    3.5: 952883,
    4.5: 892466,
  },
  {
    1: 1122450,
    2: 1068283,
    3: 984950,
    4: 914950,
    5: 857450,
    "revenu brut": 3098000,
    trimf: 1500,
    1.5: 1097450,
    2.5: 1030783,
    3.5: 953283,
    4.5: 892866,
  },
  {
    1: 1122850,
    2: 1068683,
    3: 985350,
    4: 915350,
    5: 857850,
    "revenu brut": 3099000,
    trimf: 1500,
    1.5: 1097850,
    2.5: 1031183,
    3.5: 953683,
    4.5: 893266,
  },
  {
    1: 1123250,
    2: 1069083,
    3: 985750,
    4: 915750,
    5: 858250,
    "revenu brut": 31_00000,
    trimf: 1500,
    1.5: 1098250,
    2.5: 1031583,
    3.5: 954083,
    4.5: 893666,
  },
  {
    1: 1123650,
    2: 1069483,
    3: 986150,
    4: 916150,
    5: 858650,
    "revenu brut": 3101_000,
    trimf: 1500,
    1.5: 1098650,
    2.5: 1031983,
    3.5: 954483,
    4.5: 894066,
  },
  {
    1: 1124050,
    2: 1069883,
    3: 986550,
    4: 916550,
    5: 859050,
    "revenu brut": 3102000,
    trimf: 1500,
    1.5: 1099050,
    2.5: 1032383,
    3.5: 954883,
    4.5: 894466,
  },
  {
    1: 1124450,
    2: 1070283,
    3: 986950,
    4: 916950,
    5: 859450,
    "revenu brut": 3103000,
    trimf: 1500,
    1.5: 1099450,
    2.5: 1032783,
    3.5: 955283,
    4.5: 894866,
  },
  {
    1: 1124850,
    2: 1070683,
    3: 987350,
    4: 917350,
    5: 859850,
    "revenu brut": 3104000,
    trimf: 1500,
    1.5: 1099850,
    2.5: 1033183,
    3.5: 955683,
    4.5: 895266,
  },
  {
    1: 1125250,
    2: 1071083,
    3: 987750,
    4: 917750,
    5: 860250,
    "revenu brut": 3105000,
    trimf: 1500,
    1.5: 1100250,
    2.5: 1033583,
    3.5: 956083,
    4.5: 895666,
  },
  {
    1: 1125650,
    2: 1071483,
    3: 988150,
    4: 918150,
    5: 860650,
    "revenu brut": 3106000,
    trimf: 1500,
    1.5: 1100650,
    2.5: 1033983,
    3.5: 956483,
    4.5: 896066,
  },
  {
    1: 1126050,
    2: 1071883,
    3: 988550,
    4: 918550,
    5: 861050,
    "revenu brut": 3107000,
    trimf: 1500,
    1.5: 1101050,
    2.5: 1034383,
    3.5: 956883,
    4.5: 896466,
  },
  {
    1: 1126450,
    2: 1072283,
    3: 988950,
    4: 918950,
    5: 861450,
    "revenu brut": 3108000,
    trimf: 1500,
    1.5: 1101450,
    2.5: 1034783,
    3.5: 957283,
    4.5: 896866,
  },
  {
    1: 1126850,
    2: 1072683,
    3: 989350,
    4: 919350,
    5: 861850,
    "revenu brut": 3109000,
    trimf: 1500,
    1.5: 1101850,
    2.5: 1035183,
    3.5: 957683,
    4.5: 897266,
  },
  {
    1: 1127250,
    2: 1073083,
    3: 989750,
    4: 919750,
    5: 862250,
    "revenu brut": 311_0000,
    trimf: 1500,
    1.5: 1102250,
    2.5: 1035583,
    3.5: 958083,
    4.5: 897666,
  },
  {
    1: 1127650,
    2: 1073483,
    3: 990150,
    4: 920150,
    5: 862650,
    "revenu brut": 3111_000,
    trimf: 1500,
    1.5: 1102650,
    2.5: 1035983,
    3.5: 958483,
    4.5: 898066,
  },
  {
    1: 1128050,
    2: 1073883,
    3: 990550,
    4: 920550,
    5: 863050,
    "revenu brut": 3112000,
    trimf: 1500,
    1.5: 1103050,
    2.5: 1036383,
    3.5: 958883,
    4.5: 898466,
  },
  {
    1: 1128450,
    2: 1074283,
    3: 990950,
    4: 920950,
    5: 863450,
    "revenu brut": 3113000,
    trimf: 1500,
    1.5: 1103450,
    2.5: 1036783,
    3.5: 959283,
    4.5: 898866,
  },
  {
    1: 1128850,
    2: 1074683,
    3: 991350,
    4: 921350,
    5: 863850,
    "revenu brut": 3114000,
    trimf: 1500,
    1.5: 1103850,
    2.5: 1037183,
    3.5: 959683,
    4.5: 899266,
  },
  {
    1: 1129250,
    2: 1075083,
    3: 991750,
    4: 921750,
    5: 864250,
    "revenu brut": 3115000,
    trimf: 1500,
    1.5: 1104250,
    2.5: 1037583,
    3.5: 960083,
    4.5: 899666,
  },
  {
    1: 1129650,
    2: 1075483,
    3: 992150,
    4: 922150,
    5: 864650,
    "revenu brut": 3116000,
    trimf: 1500,
    1.5: 1104650,
    2.5: 1037983,
    3.5: 960483,
    4.5: 900066,
  },
  {
    1: 1130050,
    2: 1075883,
    3: 992550,
    4: 922550,
    5: 865050,
    "revenu brut": 3117000,
    trimf: 1500,
    1.5: 1105050,
    2.5: 1038383,
    3.5: 960883,
    4.5: 900466,
  },
  {
    1: 1130450,
    2: 1076283,
    3: 992950,
    4: 922950,
    5: 865450,
    "revenu brut": 3118000,
    trimf: 1500,
    1.5: 1105450,
    2.5: 1038783,
    3.5: 961283,
    4.5: 900866,
  },
  {
    1: 1130850,
    2: 1076683,
    3: 993350,
    4: 923350,
    5: 865850,
    "revenu brut": 3119000,
    trimf: 1500,
    1.5: 1105850,
    2.5: 1039183,
    3.5: 961683,
    4.5: 901266,
  },
  {
    1: 1131250,
    2: 1077083,
    3: 993750,
    4: 923750,
    5: 866250,
    "revenu brut": 3120000,
    trimf: 1500,
    1.5: 1106250,
    2.5: 1039583,
    3.5: 962083,
    4.5: 901666,
  },
  {
    1: 1131650,
    2: 1077483,
    3: 994150,
    4: 924150,
    5: 866650,
    "revenu brut": 3121_000,
    trimf: 1500,
    1.5: 1106650,
    2.5: 1039983,
    3.5: 962483,
    4.5: 902066,
  },
  {
    1: 1132050,
    2: 1077883,
    3: 994550,
    4: 924550,
    5: 867050,
    "revenu brut": 3122000,
    trimf: 1500,
    1.5: 1107050,
    2.5: 1040383,
    3.5: 962883,
    4.5: 902466,
  },
  {
    1: 1132450,
    2: 1078283,
    3: 994950,
    4: 924950,
    5: 867450,
    "revenu brut": 3123000,
    trimf: 1500,
    1.5: 1107450,
    2.5: 1040783,
    3.5: 963283,
    4.5: 902866,
  },
  {
    1: 1132850,
    2: 1078683,
    3: 995350,
    4: 925350,
    5: 867850,
    "revenu brut": 3124000,
    trimf: 1500,
    1.5: 1107850,
    2.5: 1041183,
    3.5: 963683,
    4.5: 903266,
  },
  {
    1: 1133250,
    2: 1079083,
    3: 995750,
    4: 925750,
    5: 868250,
    "revenu brut": 3125000,
    trimf: 1500,
    1.5: 1108250,
    2.5: 1041583,
    3.5: 964083,
    4.5: 903666,
  },
  {
    1: 1133650,
    2: 1079483,
    3: 996150,
    4: 926150,
    5: 868650,
    "revenu brut": 3126000,
    trimf: 1500,
    1.5: 1108650,
    2.5: 1041983,
    3.5: 964483,
    4.5: 904066,
  },
  {
    1: 1134050,
    2: 1079883,
    3: 996550,
    4: 926550,
    5: 869050,
    "revenu brut": 3127000,
    trimf: 1500,
    1.5: 1109050,
    2.5: 1042383,
    3.5: 964883,
    4.5: 904466,
  },
  {
    1: 1134450,
    2: 1080283,
    3: 996950,
    4: 926950,
    5: 869450,
    "revenu brut": 3128000,
    trimf: 1500,
    1.5: 1109450,
    2.5: 1042783,
    3.5: 965283,
    4.5: 904866,
  },
  {
    1: 1134850,
    2: 1080683,
    3: 997350,
    4: 927350,
    5: 869850,
    "revenu brut": 3129000,
    trimf: 1500,
    1.5: 1109850,
    2.5: 1043183,
    3.5: 965683,
    4.5: 905266,
  },
  {
    1: 1135250,
    2: 1081083,
    3: 997750,
    4: 927750,
    5: 870250,
    "revenu brut": 3130000,
    trimf: 1500,
    1.5: 1110250,
    2.5: 1043583,
    3.5: 966083,
    4.5: 905666,
  },
  {
    1: 1135650,
    2: 1081483,
    3: 998150,
    4: 928150,
    5: 870650,
    "revenu brut": 3131_000,
    trimf: 1500,
    1.5: 1110650,
    2.5: 1043983,
    3.5: 966483,
    4.5: 906066,
  },
  {
    1: 1136050,
    2: 1081883,
    3: 998550,
    4: 928550,
    5: 871050,
    "revenu brut": 3132000,
    trimf: 1500,
    1.5: 1111050,
    2.5: 1044383,
    3.5: 966883,
    4.5: 906466,
  },
  {
    1: 1136450,
    2: 1082283,
    3: 998950,
    4: 928950,
    5: 871450,
    "revenu brut": 3133000,
    trimf: 1500,
    1.5: 1111450,
    2.5: 1044783,
    3.5: 967283,
    4.5: 906866,
  },
  {
    1: 1136850,
    2: 1082683,
    3: 999350,
    4: 929350,
    5: 871850,
    "revenu brut": 3134000,
    trimf: 1500,
    1.5: 1111850,
    2.5: 1045183,
    3.5: 967683,
    4.5: 907266,
  },
  {
    1: 1137250,
    2: 1083083,
    3: 999750,
    4: 929750,
    5: 872250,
    "revenu brut": 3135000,
    trimf: 1500,
    1.5: 1112250,
    2.5: 1045583,
    3.5: 968083,
    4.5: 907666,
  },
  {
    1: 1137650,
    2: 1083483,
    3: 1_000150,
    4: 930150,
    5: 872650,
    "revenu brut": 3136000,
    trimf: 1500,
    1.5: 1112650,
    2.5: 1045983,
    3.5: 968483,
    4.5: 908066,
  },
  {
    1: 1138050,
    2: 1083883,
    3: 1_000550,
    4: 930550,
    5: 873050,
    "revenu brut": 3137000,
    trimf: 1500,
    1.5: 1113050,
    2.5: 1046383,
    3.5: 968883,
    4.5: 908466,
  },
  {
    1: 1138450,
    2: 1084283,
    3: 1_000950,
    4: 930950,
    5: 873450,
    "revenu brut": 3138000,
    trimf: 1500,
    1.5: 1113450,
    2.5: 1046783,
    3.5: 969283,
    4.5: 908866,
  },
  {
    1: 1138850,
    2: 1084683,
    3: 1001350,
    4: 931350,
    5: 873850,
    "revenu brut": 3139000,
    trimf: 1500,
    1.5: 1113850,
    2.5: 1047183,
    3.5: 969683,
    4.5: 909266,
  },
  {
    1: 1139250,
    2: 1085083,
    3: 1001750,
    4: 931750,
    5: 874250,
    "revenu brut": 3140000,
    trimf: 1500,
    1.5: 1114250,
    2.5: 1047583,
    3.5: 970083,
    4.5: 909666,
  },
  {
    1: 1139650,
    2: 1085483,
    3: 1002150,
    4: 932150,
    5: 874650,
    "revenu brut": 3141_000,
    trimf: 1500,
    1.5: 1114650,
    2.5: 1047983,
    3.5: 970483,
    4.5: 910066,
  },
  {
    1: 1140050,
    2: 1085883,
    3: 1002550,
    4: 932550,
    5: 875050,
    "revenu brut": 3142000,
    trimf: 1500,
    1.5: 1115050,
    2.5: 1048383,
    3.5: 970883,
    4.5: 910466,
  },
  {
    1: 1140450,
    2: 1086283,
    3: 1002950,
    4: 932950,
    5: 875450,
    "revenu brut": 3143000,
    trimf: 1500,
    1.5: 1115450,
    2.5: 1048783,
    3.5: 971283,
    4.5: 910866,
  },
  {
    1: 1140850,
    2: 1086683,
    3: 1003350,
    4: 933350,
    5: 875850,
    "revenu brut": 3144000,
    trimf: 1500,
    1.5: 1115850,
    2.5: 1049183,
    3.5: 971683,
    4.5: 911266,
  },
  {
    1: 1141250,
    2: 1087083,
    3: 1003750,
    4: 933750,
    5: 876250,
    "revenu brut": 3145000,
    trimf: 1500,
    1.5: 1116250,
    2.5: 1049583,
    3.5: 972083,
    4.5: 911666,
  },
  {
    1: 1141650,
    2: 1087483,
    3: 1004150,
    4: 934150,
    5: 876650,
    "revenu brut": 3146000,
    trimf: 1500,
    1.5: 1116650,
    2.5: 1049983,
    3.5: 972483,
    4.5: 912066,
  },
  {
    1: 1142050,
    2: 1087883,
    3: 1004550,
    4: 934550,
    5: 877050,
    "revenu brut": 3147000,
    trimf: 1500,
    1.5: 1117050,
    2.5: 1050383,
    3.5: 972883,
    4.5: 912466,
  },
  {
    1: 1142450,
    2: 1088283,
    3: 1004950,
    4: 934950,
    5: 877450,
    "revenu brut": 3148000,
    trimf: 1500,
    1.5: 1117450,
    2.5: 1050783,
    3.5: 973283,
    4.5: 912866,
  },
  {
    1: 1142850,
    2: 1088683,
    3: 1005350,
    4: 935350,
    5: 877850,
    "revenu brut": 3149000,
    trimf: 1500,
    1.5: 1117850,
    2.5: 1051183,
    3.5: 973683,
    4.5: 913266,
  },
  {
    1: 1143250,
    2: 1089083,
    3: 1005750,
    4: 935750,
    5: 878250,
    "revenu brut": 3150000,
    trimf: 1500,
    1.5: 1118250,
    2.5: 1051583,
    3.5: 974083,
    4.5: 913666,
  },
  {
    1: 1143650,
    2: 1089483,
    3: 1006150,
    4: 936150,
    5: 878650,
    "revenu brut": 3151_000,
    trimf: 1500,
    1.5: 1118650,
    2.5: 1051983,
    3.5: 974483,
    4.5: 914066,
  },
  {
    1: 1144050,
    2: 1089883,
    3: 1006550,
    4: 936550,
    5: 879050,
    "revenu brut": 3152000,
    trimf: 1500,
    1.5: 1119050,
    2.5: 1052383,
    3.5: 974883,
    4.5: 914466,
  },
  {
    1: 1144450,
    2: 1090283,
    3: 1006950,
    4: 936950,
    5: 879450,
    "revenu brut": 3153000,
    trimf: 1500,
    1.5: 1119450,
    2.5: 1052783,
    3.5: 975283,
    4.5: 914866,
  },
  {
    1: 1144850,
    2: 1090683,
    3: 1007350,
    4: 937350,
    5: 879850,
    "revenu brut": 3154000,
    trimf: 1500,
    1.5: 1119850,
    2.5: 1053183,
    3.5: 975683,
    4.5: 915266,
  },
  {
    1: 1145250,
    2: 1091083,
    3: 1007750,
    4: 937750,
    5: 880250,
    "revenu brut": 3155000,
    trimf: 1500,
    1.5: 1120250,
    2.5: 1053583,
    3.5: 976083,
    4.5: 915666,
  },
  {
    1: 1145650,
    2: 1091483,
    3: 1008150,
    4: 938150,
    5: 880650,
    "revenu brut": 3156000,
    trimf: 1500,
    1.5: 1120650,
    2.5: 1053983,
    3.5: 976483,
    4.5: 916066,
  },
  {
    1: 1146050,
    2: 1091883,
    3: 1008550,
    4: 938550,
    5: 881050,
    "revenu brut": 3157000,
    trimf: 1500,
    1.5: 1121050,
    2.5: 1054383,
    3.5: 976883,
    4.5: 916466,
  },
  {
    1: 1146450,
    2: 1092283,
    3: 1008950,
    4: 938950,
    5: 881450,
    "revenu brut": 3158000,
    trimf: 1500,
    1.5: 1121450,
    2.5: 1054783,
    3.5: 977283,
    4.5: 916866,
  },
  {
    1: 1146850,
    2: 1092683,
    3: 1009350,
    4: 939350,
    5: 881850,
    "revenu brut": 3159000,
    trimf: 1500,
    1.5: 1121850,
    2.5: 1055183,
    3.5: 977683,
    4.5: 917266,
  },
  {
    1: 1147250,
    2: 1093083,
    3: 1009750,
    4: 939750,
    5: 882250,
    "revenu brut": 3160000,
    trimf: 1500,
    1.5: 1122250,
    2.5: 1055583,
    3.5: 978083,
    4.5: 917666,
  },
  {
    1: 1147650,
    2: 1093483,
    3: 1010150,
    4: 940150,
    5: 882650,
    "revenu brut": 3161_000,
    trimf: 1500,
    1.5: 1122650,
    2.5: 1055983,
    3.5: 978483,
    4.5: 918066,
  },
  {
    1: 1148050,
    2: 1093883,
    3: 1010550,
    4: 940550,
    5: 883050,
    "revenu brut": 3162000,
    trimf: 1500,
    1.5: 1123050,
    2.5: 1056383,
    3.5: 978883,
    4.5: 918466,
  },
  {
    1: 1148450,
    2: 1094283,
    3: 1010950,
    4: 940950,
    5: 883450,
    "revenu brut": 3163000,
    trimf: 1500,
    1.5: 1123450,
    2.5: 1056783,
    3.5: 979283,
    4.5: 918866,
  },
  {
    1: 1148850,
    2: 1094683,
    3: 1011350,
    4: 941350,
    5: 883850,
    "revenu brut": 3164000,
    trimf: 1500,
    1.5: 1123850,
    2.5: 1057183,
    3.5: 979683,
    4.5: 919266,
  },
  {
    1: 1149250,
    2: 1095083,
    3: 1011750,
    4: 941750,
    5: 884250,
    "revenu brut": 3165000,
    trimf: 1500,
    1.5: 1124250,
    2.5: 1057583,
    3.5: 980083,
    4.5: 919666,
  },
  {
    1: 1149650,
    2: 1095483,
    3: 1012150,
    4: 942150,
    5: 884650,
    "revenu brut": 3166000,
    trimf: 1500,
    1.5: 1124650,
    2.5: 1057983,
    3.5: 980483,
    4.5: 920066,
  },
  {
    1: 1150050,
    2: 1095883,
    3: 1012550,
    4: 942550,
    5: 885050,
    "revenu brut": 3167000,
    trimf: 1500,
    1.5: 1125050,
    2.5: 1058383,
    3.5: 980883,
    4.5: 920466,
  },
  {
    1: 1150450,
    2: 1096283,
    3: 1012950,
    4: 942950,
    5: 885450,
    "revenu brut": 3168000,
    trimf: 1500,
    1.5: 1125450,
    2.5: 1058783,
    3.5: 981283,
    4.5: 920866,
  },
  {
    1: 1150850,
    2: 1096683,
    3: 1013350,
    4: 943350,
    5: 885850,
    "revenu brut": 3169000,
    trimf: 1500,
    1.5: 1125850,
    2.5: 1059183,
    3.5: 981683,
    4.5: 921266,
  },
  {
    1: 1151250,
    2: 1097083,
    3: 1013750,
    4: 943750,
    5: 886250,
    "revenu brut": 3170000,
    trimf: 1500,
    1.5: 1126250,
    2.5: 1059583,
    3.5: 982083,
    4.5: 921666,
  },
  {
    1: 1151650,
    2: 1097483,
    3: 1014150,
    4: 944150,
    5: 886650,
    "revenu brut": 3171_000,
    trimf: 1500,
    1.5: 1126650,
    2.5: 1059983,
    3.5: 982483,
    4.5: 922066,
  },
  {
    1: 1152050,
    2: 1097883,
    3: 1014550,
    4: 944550,
    5: 887050,
    "revenu brut": 3172000,
    trimf: 1500,
    1.5: 1127050,
    2.5: 1060383,
    3.5: 982883,
    4.5: 922466,
  },
  {
    1: 1152450,
    2: 1098283,
    3: 1014950,
    4: 944950,
    5: 887450,
    "revenu brut": 3173000,
    trimf: 1500,
    1.5: 1127450,
    2.5: 1060783,
    3.5: 983283,
    4.5: 922866,
  },
  {
    1: 1152850,
    2: 1098683,
    3: 1015350,
    4: 945350,
    5: 887850,
    "revenu brut": 3174000,
    trimf: 1500,
    1.5: 1127850,
    2.5: 1061183,
    3.5: 983683,
    4.5: 923266,
  },
  {
    1: 1153250,
    2: 1099083,
    3: 1015750,
    4: 945750,
    5: 888250,
    "revenu brut": 3175000,
    trimf: 1500,
    1.5: 1128250,
    2.5: 1061583,
    3.5: 984083,
    4.5: 923666,
  },
  {
    1: 1153650,
    2: 1099483,
    3: 1016150,
    4: 946150,
    5: 888650,
    "revenu brut": 3176000,
    trimf: 1500,
    1.5: 1128650,
    2.5: 1061983,
    3.5: 984483,
    4.5: 924066,
  },
  {
    1: 1154050,
    2: 1099883,
    3: 1016550,
    4: 946550,
    5: 889050,
    "revenu brut": 3177000,
    trimf: 1500,
    1.5: 1129050,
    2.5: 1062383,
    3.5: 984883,
    4.5: 924466,
  },
  {
    1: 1154450,
    2: 1100283,
    3: 1016950,
    4: 946950,
    5: 889450,
    "revenu brut": 3178000,
    trimf: 1500,
    1.5: 1129450,
    2.5: 1062783,
    3.5: 985283,
    4.5: 924866,
  },
  {
    1: 1154850,
    2: 1100683,
    3: 1017350,
    4: 947350,
    5: 889850,
    "revenu brut": 3179000,
    trimf: 1500,
    1.5: 1129850,
    2.5: 1063183,
    3.5: 985683,
    4.5: 925266,
  },
  {
    1: 1155250,
    2: 1101083,
    3: 1017750,
    4: 947750,
    5: 890250,
    "revenu brut": 3180000,
    trimf: 1500,
    1.5: 1130250,
    2.5: 1063583,
    3.5: 986083,
    4.5: 925666,
  },
  {
    1: 1155650,
    2: 1101483,
    3: 1018150,
    4: 948150,
    5: 890650,
    "revenu brut": 3181_000,
    trimf: 1500,
    1.5: 1130650,
    2.5: 1063983,
    3.5: 986483,
    4.5: 926066,
  },
  {
    1: 1156050,
    2: 1101883,
    3: 1018550,
    4: 948550,
    5: 891050,
    "revenu brut": 3182000,
    trimf: 1500,
    1.5: 1131050,
    2.5: 1064383,
    3.5: 986883,
    4.5: 926466,
  },
  {
    1: 1156450,
    2: 1102283,
    3: 1018950,
    4: 948950,
    5: 891450,
    "revenu brut": 3183000,
    trimf: 1500,
    1.5: 1131450,
    2.5: 1064783,
    3.5: 987283,
    4.5: 926866,
  },
  {
    1: 1156850,
    2: 1102683,
    3: 1019350,
    4: 949350,
    5: 891850,
    "revenu brut": 3184000,
    trimf: 1500,
    1.5: 1131850,
    2.5: 1065183,
    3.5: 987683,
    4.5: 927266,
  },
  {
    1: 1157250,
    2: 1103083,
    3: 1019750,
    4: 949750,
    5: 892250,
    "revenu brut": 3185000,
    trimf: 1500,
    1.5: 1132250,
    2.5: 1065583,
    3.5: 988083,
    4.5: 927666,
  },
  {
    1: 1157650,
    2: 1103483,
    3: 1020150,
    4: 950150,
    5: 892650,
    "revenu brut": 3186000,
    trimf: 1500,
    1.5: 1132650,
    2.5: 1065983,
    3.5: 988483,
    4.5: 928066,
  },
  {
    1: 1158050,
    2: 1103883,
    3: 1020550,
    4: 950550,
    5: 893050,
    "revenu brut": 3187000,
    trimf: 1500,
    1.5: 1133050,
    2.5: 1066383,
    3.5: 988883,
    4.5: 928466,
  },
  {
    1: 1158450,
    2: 1104283,
    3: 1020950,
    4: 950950,
    5: 893450,
    "revenu brut": 3188000,
    trimf: 1500,
    1.5: 1133450,
    2.5: 1066783,
    3.5: 989283,
    4.5: 928866,
  },
  {
    1: 1158850,
    2: 1104683,
    3: 1021350,
    4: 951350,
    5: 893850,
    "revenu brut": 3189000,
    trimf: 1500,
    1.5: 1133850,
    2.5: 1067183,
    3.5: 989683,
    4.5: 929266,
  },
  {
    1: 1159250,
    2: 1105083,
    3: 1021750,
    4: 951750,
    5: 894250,
    "revenu brut": 3190000,
    trimf: 1500,
    1.5: 1134250,
    2.5: 1067583,
    3.5: 990083,
    4.5: 929666,
  },
  {
    1: 1159650,
    2: 1105483,
    3: 1022150,
    4: 952150,
    5: 894650,
    "revenu brut": 3191_000,
    trimf: 1500,
    1.5: 1134650,
    2.5: 1067983,
    3.5: 990483,
    4.5: 930066,
  },
  {
    1: 1160050,
    2: 1105883,
    3: 1022550,
    4: 952550,
    5: 895050,
    "revenu brut": 3192000,
    trimf: 1500,
    1.5: 1135050,
    2.5: 1068383,
    3.5: 990883,
    4.5: 930466,
  },
  {
    1: 1160450,
    2: 1106283,
    3: 1022950,
    4: 952950,
    5: 895450,
    "revenu brut": 3193000,
    trimf: 1500,
    1.5: 1135450,
    2.5: 1068783,
    3.5: 991283,
    4.5: 930866,
  },
  {
    1: 1160850,
    2: 1106683,
    3: 1023350,
    4: 953350,
    5: 895850,
    "revenu brut": 3194000,
    trimf: 1500,
    1.5: 1135850,
    2.5: 1069183,
    3.5: 991683,
    4.5: 931266,
  },
  {
    1: 1161250,
    2: 1107083,
    3: 1023750,
    4: 953750,
    5: 896250,
    "revenu brut": 3195000,
    trimf: 1500,
    1.5: 1136250,
    2.5: 1069583,
    3.5: 992083,
    4.5: 931666,
  },
  {
    1: 1161650,
    2: 1107483,
    3: 1024150,
    4: 954150,
    5: 896650,
    "revenu brut": 3196000,
    trimf: 1500,
    1.5: 1136650,
    2.5: 1069983,
    3.5: 992483,
    4.5: 932066,
  },
  {
    1: 1162050,
    2: 1107883,
    3: 1024550,
    4: 954550,
    5: 897050,
    "revenu brut": 3197000,
    trimf: 1500,
    1.5: 1137050,
    2.5: 1070383,
    3.5: 992883,
    4.5: 932466,
  },
  {
    1: 1162450,
    2: 1108283,
    3: 1024950,
    4: 954950,
    5: 897450,
    "revenu brut": 3198000,
    trimf: 1500,
    1.5: 1137450,
    2.5: 1070783,
    3.5: 993283,
    4.5: 932866,
  },
  {
    1: 1162850,
    2: 1108683,
    3: 1025350,
    4: 955350,
    5: 897850,
    "revenu brut": 3199000,
    trimf: 1500,
    1.5: 1137850,
    2.5: 1071183,
    3.5: 993683,
    4.5: 933266,
  },
  {
    1: 1163250,
    2: 1109083,
    3: 1025750,
    4: 955750,
    5: 898250,
    "revenu brut": 3200000,
    trimf: 1500,
    1.5: 1138250,
    2.5: 1071583,
    3.5: 994083,
    4.5: 933666,
  },
  {
    1: 1163650,
    2: 1109483,
    3: 1026150,
    4: 956150,
    5: 898650,
    "revenu brut": 3201_000,
    trimf: 1500,
    1.5: 1138650,
    2.5: 1071983,
    3.5: 994483,
    4.5: 934066,
  },
  {
    1: 1164050,
    2: 1109883,
    3: 1026550,
    4: 956550,
    5: 899050,
    "revenu brut": 3202000,
    trimf: 1500,
    1.5: 1139050,
    2.5: 1072383,
    3.5: 994883,
    4.5: 934466,
  },
  {
    1: 1164450,
    2: 1110283,
    3: 1026950,
    4: 956950,
    5: 899450,
    "revenu brut": 3203000,
    trimf: 1500,
    1.5: 1139450,
    2.5: 1072783,
    3.5: 995283,
    4.5: 934866,
  },
  {
    1: 1164850,
    2: 1110683,
    3: 1027350,
    4: 957350,
    5: 899850,
    "revenu brut": 3204000,
    trimf: 1500,
    1.5: 1139850,
    2.5: 1073183,
    3.5: 995683,
    4.5: 935266,
  },
  {
    1: 1165250,
    2: 1111083,
    3: 1027750,
    4: 957750,
    5: 900250,
    "revenu brut": 3205000,
    trimf: 1500,
    1.5: 1140250,
    2.5: 1073583,
    3.5: 996083,
    4.5: 935666,
  },
  {
    1: 1165650,
    2: 1111483,
    3: 1028150,
    4: 958150,
    5: 900650,
    "revenu brut": 3206000,
    trimf: 1500,
    1.5: 1140650,
    2.5: 1073983,
    3.5: 996483,
    4.5: 936066,
  },
  {
    1: 1166050,
    2: 1111883,
    3: 1028550,
    4: 958550,
    5: 901050,
    "revenu brut": 3207000,
    trimf: 1500,
    1.5: 1141050,
    2.5: 1074383,
    3.5: 996883,
    4.5: 936466,
  },
  {
    1: 1166450,
    2: 1112283,
    3: 1028950,
    4: 958950,
    5: 901450,
    "revenu brut": 3208000,
    trimf: 1500,
    1.5: 1141450,
    2.5: 1074783,
    3.5: 997283,
    4.5: 936866,
  },
  {
    1: 1166850,
    2: 1112683,
    3: 1029350,
    4: 959350,
    5: 901850,
    "revenu brut": 3209000,
    trimf: 1500,
    1.5: 1141850,
    2.5: 1075183,
    3.5: 997683,
    4.5: 937266,
  },
  {
    1: 1167250,
    2: 1113083,
    3: 1029750,
    4: 959750,
    5: 902250,
    "revenu brut": 321_0000,
    trimf: 1500,
    1.5: 1142250,
    2.5: 1075583,
    3.5: 998083,
    4.5: 937666,
  },
  {
    1: 1167650,
    2: 1113483,
    3: 1030150,
    4: 960150,
    5: 902650,
    "revenu brut": 3211_000,
    trimf: 1500,
    1.5: 1142650,
    2.5: 1075983,
    3.5: 998483,
    4.5: 938066,
  },
  {
    1: 1168050,
    2: 1113883,
    3: 1030550,
    4: 960550,
    5: 903050,
    "revenu brut": 3212000,
    trimf: 1500,
    1.5: 1143050,
    2.5: 1076383,
    3.5: 998883,
    4.5: 938466,
  },
  {
    1: 1168450,
    2: 1114283,
    3: 1030950,
    4: 960950,
    5: 903450,
    "revenu brut": 3213000,
    trimf: 1500,
    1.5: 1143450,
    2.5: 1076783,
    3.5: 999283,
    4.5: 938866,
  },
  {
    1: 1168850,
    2: 1114683,
    3: 1031350,
    4: 961350,
    5: 903850,
    "revenu brut": 3214000,
    trimf: 1500,
    1.5: 1143850,
    2.5: 1077183,
    3.5: 999683,
    4.5: 939266,
  },
  {
    1: 1169250,
    2: 1115083,
    3: 1031750,
    4: 961750,
    5: 904250,
    "revenu brut": 3215000,
    trimf: 1500,
    1.5: 1144250,
    2.5: 1077583,
    3.5: 1_000083,
    4.5: 939666,
  },
  {
    1: 1169650,
    2: 1115483,
    3: 1032150,
    4: 962150,
    5: 904650,
    "revenu brut": 3216000,
    trimf: 1500,
    1.5: 1144650,
    2.5: 1077983,
    3.5: 1_000483,
    4.5: 940066,
  },
  {
    1: 1170050,
    2: 1115883,
    3: 1032550,
    4: 962550,
    5: 905050,
    "revenu brut": 3217000,
    trimf: 1500,
    1.5: 1145050,
    2.5: 1078383,
    3.5: 1_000883,
    4.5: 940466,
  },
  {
    1: 1170450,
    2: 1116283,
    3: 1032950,
    4: 962950,
    5: 905450,
    "revenu brut": 3218000,
    trimf: 1500,
    1.5: 1145450,
    2.5: 1078783,
    3.5: 1001283,
    4.5: 940866,
  },
  {
    1: 1170850,
    2: 1116683,
    3: 1033350,
    4: 963350,
    5: 905850,
    "revenu brut": 3219000,
    trimf: 1500,
    1.5: 1145850,
    2.5: 1079183,
    3.5: 1001683,
    4.5: 941266,
  },
  {
    1: 1171250,
    2: 1117083,
    3: 1033750,
    4: 963750,
    5: 906250,
    "revenu brut": 3220000,
    trimf: 1500,
    1.5: 1146250,
    2.5: 1079583,
    3.5: 1002083,
    4.5: 941666,
  },
  {
    1: 1171650,
    2: 1117483,
    3: 1034150,
    4: 964150,
    5: 906650,
    "revenu brut": 3221_000,
    trimf: 1500,
    1.5: 1146650,
    2.5: 1079983,
    3.5: 1002483,
    4.5: 942066,
  },
  {
    1: 1172050,
    2: 1117883,
    3: 1034550,
    4: 964550,
    5: 907050,
    "revenu brut": 3222000,
    trimf: 1500,
    1.5: 1147050,
    2.5: 1080383,
    3.5: 1002883,
    4.5: 942466,
  },
  {
    1: 1172450,
    2: 1118283,
    3: 1034950,
    4: 964950,
    5: 907450,
    "revenu brut": 3223000,
    trimf: 1500,
    1.5: 1147450,
    2.5: 1080783,
    3.5: 1003283,
    4.5: 942866,
  },
  {
    1: 1172850,
    2: 1118683,
    3: 1035350,
    4: 965350,
    5: 907850,
    "revenu brut": 3224000,
    trimf: 1500,
    1.5: 1147850,
    2.5: 1081183,
    3.5: 1003683,
    4.5: 943266,
  },
  {
    1: 1173250,
    2: 1119083,
    3: 1035750,
    4: 965750,
    5: 908250,
    "revenu brut": 3225000,
    trimf: 1500,
    1.5: 1148250,
    2.5: 1081583,
    3.5: 1004083,
    4.5: 943666,
  },
  {
    1: 1173650,
    2: 1119483,
    3: 1036150,
    4: 966150,
    5: 908650,
    "revenu brut": 3226000,
    trimf: 1500,
    1.5: 1148650,
    2.5: 1081983,
    3.5: 1004483,
    4.5: 944066,
  },
  {
    1: 1174050,
    2: 1119883,
    3: 1036550,
    4: 966550,
    5: 909050,
    "revenu brut": 3227000,
    trimf: 1500,
    1.5: 1149050,
    2.5: 1082383,
    3.5: 1004883,
    4.5: 944466,
  },
  {
    1: 1174450,
    2: 1120283,
    3: 1036950,
    4: 966950,
    5: 909450,
    "revenu brut": 3228000,
    trimf: 1500,
    1.5: 1149450,
    2.5: 1082783,
    3.5: 1005283,
    4.5: 944866,
  },
  {
    1: 1174850,
    2: 1120683,
    3: 1037350,
    4: 967350,
    5: 909850,
    "revenu brut": 3229000,
    trimf: 1500,
    1.5: 1149850,
    2.5: 1083183,
    3.5: 1005683,
    4.5: 945266,
  },
  {
    1: 1175250,
    2: 1121083,
    3: 1037750,
    4: 967750,
    5: 910250,
    "revenu brut": 3230000,
    trimf: 1500,
    1.5: 1150250,
    2.5: 1083583,
    3.5: 1006083,
    4.5: 945666,
  },
  {
    1: 1175650,
    2: 1121483,
    3: 1038150,
    4: 968150,
    5: 910650,
    "revenu brut": 3231_000,
    trimf: 1500,
    1.5: 1150650,
    2.5: 1083983,
    3.5: 1006483,
    4.5: 946066,
  },
  {
    1: 1176050,
    2: 1121883,
    3: 1038550,
    4: 968550,
    5: 911050,
    "revenu brut": 3232000,
    trimf: 1500,
    1.5: 1151050,
    2.5: 1084383,
    3.5: 1006883,
    4.5: 946466,
  },
  {
    1: 1176450,
    2: 1122283,
    3: 1038950,
    4: 968950,
    5: 911450,
    "revenu brut": 3233000,
    trimf: 1500,
    1.5: 1151450,
    2.5: 1084783,
    3.5: 1007283,
    4.5: 946866,
  },
  {
    1: 1176850,
    2: 1122683,
    3: 1039350,
    4: 969350,
    5: 911850,
    "revenu brut": 3234000,
    trimf: 1500,
    1.5: 1151850,
    2.5: 1085183,
    3.5: 1007683,
    4.5: 947266,
  },
  {
    1: 1177250,
    2: 1123083,
    3: 1039750,
    4: 969750,
    5: 912250,
    "revenu brut": 3235000,
    trimf: 1500,
    1.5: 1152250,
    2.5: 1085583,
    3.5: 1008083,
    4.5: 947666,
  },
  {
    1: 1177650,
    2: 1123483,
    3: 1040150,
    4: 970150,
    5: 912650,
    "revenu brut": 3236000,
    trimf: 1500,
    1.5: 1152650,
    2.5: 1085983,
    3.5: 1008483,
    4.5: 948066,
  },
  {
    1: 1178050,
    2: 1123883,
    3: 1040550,
    4: 970550,
    5: 913050,
    "revenu brut": 3237000,
    trimf: 1500,
    1.5: 1153050,
    2.5: 1086383,
    3.5: 1008883,
    4.5: 948466,
  },
  {
    1: 1178450,
    2: 1124283,
    3: 1040950,
    4: 970950,
    5: 913450,
    "revenu brut": 3238000,
    trimf: 1500,
    1.5: 1153450,
    2.5: 1086783,
    3.5: 1009283,
    4.5: 948866,
  },
  {
    1: 1178850,
    2: 1124683,
    3: 1041350,
    4: 971350,
    5: 913850,
    "revenu brut": 3239000,
    trimf: 1500,
    1.5: 1153850,
    2.5: 1087183,
    3.5: 1009683,
    4.5: 949266,
  },
  {
    1: 1179250,
    2: 1125083,
    3: 1041750,
    4: 971750,
    5: 914250,
    "revenu brut": 3240000,
    trimf: 1500,
    1.5: 1154250,
    2.5: 1087583,
    3.5: 1010083,
    4.5: 949666,
  },
  {
    1: 1179650,
    2: 1125483,
    3: 1042150,
    4: 972150,
    5: 914650,
    "revenu brut": 3241_000,
    trimf: 1500,
    1.5: 1154650,
    2.5: 1087983,
    3.5: 1010483,
    4.5: 950066,
  },
  {
    1: 1180050,
    2: 1125883,
    3: 1042550,
    4: 972550,
    5: 915050,
    "revenu brut": 3242000,
    trimf: 1500,
    1.5: 1155050,
    2.5: 1088383,
    3.5: 1010883,
    4.5: 950466,
  },
  {
    1: 1180450,
    2: 1126283,
    3: 1042950,
    4: 972950,
    5: 915450,
    "revenu brut": 3243000,
    trimf: 1500,
    1.5: 1155450,
    2.5: 1088783,
    3.5: 1011283,
    4.5: 950866,
  },
  {
    1: 1180850,
    2: 1126683,
    3: 1043350,
    4: 973350,
    5: 915850,
    "revenu brut": 3244000,
    trimf: 1500,
    1.5: 1155850,
    2.5: 1089183,
    3.5: 1011683,
    4.5: 951266,
  },
  {
    1: 1181250,
    2: 1127083,
    3: 1043750,
    4: 973750,
    5: 916250,
    "revenu brut": 3245000,
    trimf: 1500,
    1.5: 1156250,
    2.5: 1089583,
    3.5: 1012083,
    4.5: 951666,
  },
  {
    1: 1181650,
    2: 1127483,
    3: 1044150,
    4: 974150,
    5: 916650,
    "revenu brut": 3246000,
    trimf: 1500,
    1.5: 1156650,
    2.5: 1089983,
    3.5: 1012483,
    4.5: 952066,
  },
  {
    1: 1182050,
    2: 1127883,
    3: 1044550,
    4: 974550,
    5: 917050,
    "revenu brut": 3247000,
    trimf: 1500,
    1.5: 1157050,
    2.5: 1090383,
    3.5: 1012883,
    4.5: 952466,
  },
  {
    1: 1182450,
    2: 1128283,
    3: 1044950,
    4: 974950,
    5: 917450,
    "revenu brut": 3248000,
    trimf: 1500,
    1.5: 1157450,
    2.5: 1090783,
    3.5: 1013283,
    4.5: 952866,
  },
  {
    1: 1182850,
    2: 1128683,
    3: 1045350,
    4: 975350,
    5: 917850,
    "revenu brut": 3249000,
    trimf: 1500,
    1.5: 1157850,
    2.5: 1091183,
    3.5: 1013683,
    4.5: 953266,
  },
  {
    1: 1183250,
    2: 1129083,
    3: 1045750,
    4: 975750,
    5: 918250,
    "revenu brut": 3250000,
    trimf: 1500,
    1.5: 1158250,
    2.5: 1091583,
    3.5: 1014083,
    4.5: 953666,
  },
  {
    1: 1183650,
    2: 1129483,
    3: 1046150,
    4: 976150,
    5: 918650,
    "revenu brut": 3251_000,
    trimf: 1500,
    1.5: 1158650,
    2.5: 1091983,
    3.5: 1014483,
    4.5: 954066,
  },
  {
    1: 1184050,
    2: 1129883,
    3: 1046550,
    4: 976550,
    5: 919050,
    "revenu brut": 3252000,
    trimf: 1500,
    1.5: 1159050,
    2.5: 1092383,
    3.5: 1014883,
    4.5: 954466,
  },
  {
    1: 1184450,
    2: 1130283,
    3: 1046950,
    4: 976950,
    5: 919450,
    "revenu brut": 3253000,
    trimf: 1500,
    1.5: 1159450,
    2.5: 1092783,
    3.5: 1015283,
    4.5: 954866,
  },
  {
    1: 1184850,
    2: 1130683,
    3: 1047350,
    4: 977350,
    5: 919850,
    "revenu brut": 3254000,
    trimf: 1500,
    1.5: 1159850,
    2.5: 1093183,
    3.5: 1015683,
    4.5: 955266,
  },
  {
    1: 1185250,
    2: 1131083,
    3: 1047750,
    4: 977750,
    5: 920250,
    "revenu brut": 3255000,
    trimf: 1500,
    1.5: 1160250,
    2.5: 1093583,
    3.5: 1016083,
    4.5: 955666,
  },
  {
    1: 1185650,
    2: 1131483,
    3: 1048150,
    4: 978150,
    5: 920650,
    "revenu brut": 3256000,
    trimf: 1500,
    1.5: 1160650,
    2.5: 1093983,
    3.5: 1016483,
    4.5: 956066,
  },
  {
    1: 1186050,
    2: 1131883,
    3: 1048550,
    4: 978550,
    5: 921050,
    "revenu brut": 3257000,
    trimf: 1500,
    1.5: 1161050,
    2.5: 1094383,
    3.5: 1016883,
    4.5: 956466,
  },
  {
    1: 1186450,
    2: 1132283,
    3: 1048950,
    4: 978950,
    5: 921450,
    "revenu brut": 3258000,
    trimf: 1500,
    1.5: 1161450,
    2.5: 1094783,
    3.5: 1017283,
    4.5: 956866,
  },
  {
    1: 1186850,
    2: 1132683,
    3: 1049350,
    4: 979350,
    5: 921850,
    "revenu brut": 3259000,
    trimf: 1500,
    1.5: 1161850,
    2.5: 1095183,
    3.5: 1017683,
    4.5: 957266,
  },
  {
    1: 1187250,
    2: 1133083,
    3: 1049750,
    4: 979750,
    5: 922250,
    "revenu brut": 3260000,
    trimf: 1500,
    1.5: 1162250,
    2.5: 1095583,
    3.5: 1018083,
    4.5: 957666,
  },
  {
    1: 1187650,
    2: 1133483,
    3: 1050150,
    4: 980150,
    5: 922650,
    "revenu brut": 3261_000,
    trimf: 1500,
    1.5: 1162650,
    2.5: 1095983,
    3.5: 1018483,
    4.5: 958066,
  },
  {
    1: 1188050,
    2: 1133883,
    3: 1050550,
    4: 980550,
    5: 923050,
    "revenu brut": 3262000,
    trimf: 1500,
    1.5: 1163050,
    2.5: 1096383,
    3.5: 1018883,
    4.5: 958466,
  },
  {
    1: 1188450,
    2: 1134283,
    3: 1050950,
    4: 980950,
    5: 923450,
    "revenu brut": 3263000,
    trimf: 1500,
    1.5: 1163450,
    2.5: 1096783,
    3.5: 1019283,
    4.5: 958866,
  },
  {
    1: 1188850,
    2: 1134683,
    3: 1051350,
    4: 981350,
    5: 923850,
    "revenu brut": 3264000,
    trimf: 1500,
    1.5: 1163850,
    2.5: 1097183,
    3.5: 1019683,
    4.5: 959266,
  },
  {
    1: 1189250,
    2: 1135083,
    3: 1051750,
    4: 981750,
    5: 924250,
    "revenu brut": 3265000,
    trimf: 1500,
    1.5: 1164250,
    2.5: 1097583,
    3.5: 1020083,
    4.5: 959666,
  },
  {
    1: 1189650,
    2: 1135483,
    3: 1052150,
    4: 982150,
    5: 924650,
    "revenu brut": 3266000,
    trimf: 1500,
    1.5: 1164650,
    2.5: 1097983,
    3.5: 1020483,
    4.5: 960066,
  },
  {
    1: 1190050,
    2: 1135883,
    3: 1052550,
    4: 982550,
    5: 925050,
    "revenu brut": 3267000,
    trimf: 1500,
    1.5: 1165050,
    2.5: 1098383,
    3.5: 1020883,
    4.5: 960466,
  },
  {
    1: 1190450,
    2: 1136283,
    3: 1052950,
    4: 982950,
    5: 925450,
    "revenu brut": 3268000,
    trimf: 1500,
    1.5: 1165450,
    2.5: 1098783,
    3.5: 1021283,
    4.5: 960866,
  },
  {
    1: 1190850,
    2: 1136683,
    3: 1053350,
    4: 983350,
    5: 925850,
    "revenu brut": 3269000,
    trimf: 1500,
    1.5: 1165850,
    2.5: 1099183,
    3.5: 1021683,
    4.5: 961266,
  },
  {
    1: 1191250,
    2: 1137083,
    3: 1053750,
    4: 983750,
    5: 926250,
    "revenu brut": 3270000,
    trimf: 1500,
    1.5: 1166250,
    2.5: 1099583,
    3.5: 1022083,
    4.5: 961666,
  },
  {
    1: 1191650,
    2: 1137483,
    3: 1054150,
    4: 984150,
    5: 926650,
    "revenu brut": 3271_000,
    trimf: 1500,
    1.5: 1166650,
    2.5: 1099983,
    3.5: 1022483,
    4.5: 962066,
  },
  {
    1: 1192050,
    2: 1137883,
    3: 1054550,
    4: 984550,
    5: 927050,
    "revenu brut": 3272000,
    trimf: 1500,
    1.5: 1167050,
    2.5: 1100383,
    3.5: 1022883,
    4.5: 962466,
  },
  {
    1: 1192450,
    2: 1138283,
    3: 1054950,
    4: 984950,
    5: 927450,
    "revenu brut": 3273000,
    trimf: 1500,
    1.5: 1167450,
    2.5: 1100783,
    3.5: 1023283,
    4.5: 962866,
  },
  {
    1: 1192850,
    2: 1138683,
    3: 1055350,
    4: 985350,
    5: 927850,
    "revenu brut": 3274000,
    trimf: 1500,
    1.5: 1167850,
    2.5: 1101183,
    3.5: 1023683,
    4.5: 963266,
  },
  {
    1: 1193250,
    2: 1139083,
    3: 1055750,
    4: 985750,
    5: 928250,
    "revenu brut": 3275000,
    trimf: 1500,
    1.5: 1168250,
    2.5: 1101583,
    3.5: 1024083,
    4.5: 963666,
  },
  {
    1: 1193650,
    2: 1139483,
    3: 1056150,
    4: 986150,
    5: 928650,
    "revenu brut": 3276000,
    trimf: 1500,
    1.5: 1168650,
    2.5: 1101983,
    3.5: 1024483,
    4.5: 964066,
  },
  {
    1: 1194050,
    2: 1139883,
    3: 1056550,
    4: 986550,
    5: 929050,
    "revenu brut": 3277000,
    trimf: 1500,
    1.5: 1169050,
    2.5: 1102383,
    3.5: 1024883,
    4.5: 964466,
  },
  {
    1: 1194450,
    2: 1140283,
    3: 1056950,
    4: 986950,
    5: 929450,
    "revenu brut": 3278000,
    trimf: 1500,
    1.5: 1169450,
    2.5: 1102783,
    3.5: 1025283,
    4.5: 964866,
  },
  {
    1: 1194850,
    2: 1140683,
    3: 1057350,
    4: 987350,
    5: 929850,
    "revenu brut": 3279000,
    trimf: 1500,
    1.5: 1169850,
    2.5: 1103183,
    3.5: 1025683,
    4.5: 965266,
  },
  {
    1: 1195250,
    2: 1141083,
    3: 1057750,
    4: 987750,
    5: 930250,
    "revenu brut": 3280000,
    trimf: 1500,
    1.5: 1170250,
    2.5: 1103583,
    3.5: 1026083,
    4.5: 965666,
  },
  {
    1: 1195650,
    2: 1141483,
    3: 1058150,
    4: 988150,
    5: 930650,
    "revenu brut": 3281_000,
    trimf: 1500,
    1.5: 1170650,
    2.5: 1103983,
    3.5: 1026483,
    4.5: 966066,
  },
  {
    1: 1196050,
    2: 1141883,
    3: 1058550,
    4: 988550,
    5: 931050,
    "revenu brut": 3282000,
    trimf: 1500,
    1.5: 1171050,
    2.5: 1104383,
    3.5: 1026883,
    4.5: 966466,
  },
  {
    1: 1196450,
    2: 1142283,
    3: 1058950,
    4: 988950,
    5: 931450,
    "revenu brut": 3283000,
    trimf: 1500,
    1.5: 1171450,
    2.5: 1104783,
    3.5: 1027283,
    4.5: 966866,
  },
  {
    1: 1196850,
    2: 1142683,
    3: 1059350,
    4: 989350,
    5: 931850,
    "revenu brut": 3284000,
    trimf: 1500,
    1.5: 1171850,
    2.5: 1105183,
    3.5: 1027683,
    4.5: 967266,
  },
  {
    1: 1197250,
    2: 1143083,
    3: 1059750,
    4: 989750,
    5: 932250,
    "revenu brut": 3285000,
    trimf: 1500,
    1.5: 1172250,
    2.5: 1105583,
    3.5: 1028083,
    4.5: 967666,
  },
  {
    1: 1197650,
    2: 1143483,
    3: 1060150,
    4: 990150,
    5: 932650,
    "revenu brut": 3286000,
    trimf: 1500,
    1.5: 1172650,
    2.5: 1105983,
    3.5: 1028483,
    4.5: 968066,
  },
  {
    1: 1198050,
    2: 1143883,
    3: 1060550,
    4: 990550,
    5: 933050,
    "revenu brut": 3287000,
    trimf: 1500,
    1.5: 1173050,
    2.5: 1106383,
    3.5: 1028883,
    4.5: 968466,
  },
  {
    1: 1198450,
    2: 1144283,
    3: 1060950,
    4: 990950,
    5: 933450,
    "revenu brut": 3288000,
    trimf: 1500,
    1.5: 1173450,
    2.5: 1106783,
    3.5: 1029283,
    4.5: 968866,
  },
  {
    1: 1198850,
    2: 1144683,
    3: 1061350,
    4: 991350,
    5: 933850,
    "revenu brut": 3289000,
    trimf: 1500,
    1.5: 1173850,
    2.5: 1107183,
    3.5: 1029683,
    4.5: 969266,
  },
  {
    1: 1199250,
    2: 1145083,
    3: 1061750,
    4: 991750,
    5: 934250,
    "revenu brut": 3290000,
    trimf: 1500,
    1.5: 1174250,
    2.5: 1107583,
    3.5: 1030083,
    4.5: 969666,
  },
  {
    1: 1199650,
    2: 1145483,
    3: 1062150,
    4: 992150,
    5: 934650,
    "revenu brut": 3291_000,
    trimf: 1500,
    1.5: 1174650,
    2.5: 1107983,
    3.5: 1030483,
    4.5: 970066,
  },
  {
    1: 1200050,
    2: 1145883,
    3: 1062550,
    4: 992550,
    5: 935050,
    "revenu brut": 3292000,
    trimf: 1500,
    1.5: 1175050,
    2.5: 1108383,
    3.5: 1030883,
    4.5: 970466,
  },
  {
    1: 1200450,
    2: 1146283,
    3: 1062950,
    4: 992950,
    5: 935450,
    "revenu brut": 3293000,
    trimf: 1500,
    1.5: 1175450,
    2.5: 1108783,
    3.5: 1031283,
    4.5: 970866,
  },
  {
    1: 1200850,
    2: 1146683,
    3: 1063350,
    4: 993350,
    5: 935850,
    "revenu brut": 3294000,
    trimf: 1500,
    1.5: 1175850,
    2.5: 1109183,
    3.5: 1031683,
    4.5: 971266,
  },
  {
    1: 1201250,
    2: 1147083,
    3: 1063750,
    4: 993750,
    5: 936250,
    "revenu brut": 3295000,
    trimf: 1500,
    1.5: 1176250,
    2.5: 1109583,
    3.5: 1032083,
    4.5: 971666,
  },
  {
    1: 1201650,
    2: 1147483,
    3: 1064150,
    4: 994150,
    5: 936650,
    "revenu brut": 3296000,
    trimf: 1500,
    1.5: 1176650,
    2.5: 1109983,
    3.5: 1032483,
    4.5: 972066,
  },
  {
    1: 1202050,
    2: 1147883,
    3: 1064550,
    4: 994550,
    5: 937050,
    "revenu brut": 3297000,
    trimf: 1500,
    1.5: 1177050,
    2.5: 1110383,
    3.5: 1032883,
    4.5: 972466,
  },
  {
    1: 1202450,
    2: 1148283,
    3: 1064950,
    4: 994950,
    5: 937450,
    "revenu brut": 3298000,
    trimf: 1500,
    1.5: 1177450,
    2.5: 1110783,
    3.5: 1033283,
    4.5: 972866,
  },
  {
    1: 1202850,
    2: 1148683,
    3: 1065350,
    4: 995350,
    5: 937850,
    "revenu brut": 3299000,
    trimf: 1500,
    1.5: 1177850,
    2.5: 1111183,
    3.5: 1033683,
    4.5: 973266,
  },
  {
    1: 1203250,
    2: 1149083,
    3: 1065750,
    4: 995750,
    5: 938250,
    "revenu brut": 3300000,
    trimf: 1500,
    1.5: 1178250,
    2.5: 1111583,
    3.5: 1034083,
    4.5: 973666,
  },
  {
    1: 1203650,
    2: 1149483,
    3: 1066150,
    4: 996150,
    5: 938650,
    "revenu brut": 3301_000,
    trimf: 1500,
    1.5: 1178650,
    2.5: 1111983,
    3.5: 1034483,
    4.5: 974066,
  },
  {
    1: 1204050,
    2: 1149883,
    3: 1066550,
    4: 996550,
    5: 939050,
    "revenu brut": 3302000,
    trimf: 1500,
    1.5: 1179050,
    2.5: 1112383,
    3.5: 1034883,
    4.5: 974466,
  },
  {
    1: 1204450,
    2: 1150283,
    3: 1066950,
    4: 996950,
    5: 939450,
    "revenu brut": 3303000,
    trimf: 1500,
    1.5: 1179450,
    2.5: 1112783,
    3.5: 1035283,
    4.5: 974866,
  },
  {
    1: 1204850,
    2: 1150683,
    3: 1067350,
    4: 997350,
    5: 939850,
    "revenu brut": 3304000,
    trimf: 1500,
    1.5: 1179850,
    2.5: 1113183,
    3.5: 1035683,
    4.5: 975266,
  },
  {
    1: 1205250,
    2: 1151083,
    3: 1067750,
    4: 997750,
    5: 940250,
    "revenu brut": 3305000,
    trimf: 1500,
    1.5: 1180250,
    2.5: 1113583,
    3.5: 1036083,
    4.5: 975666,
  },
  {
    1: 1205650,
    2: 1151483,
    3: 1068150,
    4: 998150,
    5: 940650,
    "revenu brut": 3306000,
    trimf: 1500,
    1.5: 1180650,
    2.5: 1113983,
    3.5: 1036483,
    4.5: 976066,
  },
  {
    1: 1206050,
    2: 1151883,
    3: 1068550,
    4: 998550,
    5: 941050,
    "revenu brut": 3307000,
    trimf: 1500,
    1.5: 1181050,
    2.5: 1114383,
    3.5: 1036883,
    4.5: 976466,
  },
  {
    1: 1206450,
    2: 1152283,
    3: 1068950,
    4: 998950,
    5: 941450,
    "revenu brut": 3308000,
    trimf: 1500,
    1.5: 1181450,
    2.5: 1114783,
    3.5: 1037283,
    4.5: 976866,
  },
  {
    1: 1206850,
    2: 1152683,
    3: 1069350,
    4: 999350,
    5: 941850,
    "revenu brut": 3309000,
    trimf: 1500,
    1.5: 1181850,
    2.5: 1115183,
    3.5: 1037683,
    4.5: 977266,
  },
  {
    1: 1207250,
    2: 1153083,
    3: 1069750,
    4: 999750,
    5: 942250,
    "revenu brut": 331_0000,
    trimf: 1500,
    1.5: 1182250,
    2.5: 1115583,
    3.5: 1038083,
    4.5: 977666,
  },
  {
    1: 1207650,
    2: 1153483,
    3: 1070150,
    4: 1_000150,
    5: 942650,
    "revenu brut": 3311_000,
    trimf: 1500,
    1.5: 1182650,
    2.5: 1115983,
    3.5: 1038483,
    4.5: 978066,
  },
  {
    1: 1208050,
    2: 1153883,
    3: 1070550,
    4: 1_000550,
    5: 943050,
    "revenu brut": 3312000,
    trimf: 1500,
    1.5: 1183050,
    2.5: 1116383,
    3.5: 1038883,
    4.5: 978466,
  },
  {
    1: 1208450,
    2: 1154283,
    3: 1070950,
    4: 1_000950,
    5: 943450,
    "revenu brut": 3313000,
    trimf: 1500,
    1.5: 1183450,
    2.5: 1116783,
    3.5: 1039283,
    4.5: 978866,
  },
  {
    1: 1208850,
    2: 1154683,
    3: 1071350,
    4: 1001350,
    5: 943850,
    "revenu brut": 3314000,
    trimf: 1500,
    1.5: 1183850,
    2.5: 1117183,
    3.5: 1039683,
    4.5: 979266,
  },
  {
    1: 1209250,
    2: 1155083,
    3: 1071750,
    4: 1001750,
    5: 944250,
    "revenu brut": 3315000,
    trimf: 1500,
    1.5: 1184250,
    2.5: 1117583,
    3.5: 1040083,
    4.5: 979666,
  },
  {
    1: 1209650,
    2: 1155483,
    3: 1072150,
    4: 1002150,
    5: 944650,
    "revenu brut": 3316000,
    trimf: 1500,
    1.5: 1184650,
    2.5: 1117983,
    3.5: 1040483,
    4.5: 980066,
  },
  {
    1: 1210050,
    2: 1155883,
    3: 1072550,
    4: 1002550,
    5: 945050,
    "revenu brut": 3317000,
    trimf: 1500,
    1.5: 1185050,
    2.5: 1118383,
    3.5: 1040883,
    4.5: 980466,
  },
  {
    1: 1210450,
    2: 1156283,
    3: 1072950,
    4: 1002950,
    5: 945450,
    "revenu brut": 3318000,
    trimf: 1500,
    1.5: 1185450,
    2.5: 1118783,
    3.5: 1041283,
    4.5: 980866,
  },
  {
    1: 1210850,
    2: 1156683,
    3: 1073350,
    4: 1003350,
    5: 945850,
    "revenu brut": 3319000,
    trimf: 1500,
    1.5: 1185850,
    2.5: 1119183,
    3.5: 1041683,
    4.5: 981266,
  },
  {
    1: 1211250,
    2: 1157083,
    3: 1073750,
    4: 1003750,
    5: 946250,
    "revenu brut": 3320000,
    trimf: 1500,
    1.5: 1186250,
    2.5: 1119583,
    3.5: 1042083,
    4.5: 981666,
  },
  {
    1: 1211650,
    2: 1157483,
    3: 1074150,
    4: 1004150,
    5: 946650,
    "revenu brut": 3321_000,
    trimf: 1500,
    1.5: 1186650,
    2.5: 1119983,
    3.5: 1042483,
    4.5: 982066,
  },
  {
    1: 1212050,
    2: 1157883,
    3: 1074550,
    4: 1004550,
    5: 947050,
    "revenu brut": 3322000,
    trimf: 1500,
    1.5: 1187050,
    2.5: 1120383,
    3.5: 1042883,
    4.5: 982466,
  },
  {
    1: 1212450,
    2: 1158283,
    3: 1074950,
    4: 1004950,
    5: 947450,
    "revenu brut": 3323000,
    trimf: 1500,
    1.5: 1187450,
    2.5: 1120783,
    3.5: 1043283,
    4.5: 982866,
  },
  {
    1: 1212850,
    2: 1158683,
    3: 1075350,
    4: 1005350,
    5: 947850,
    "revenu brut": 3324000,
    trimf: 1500,
    1.5: 1187850,
    2.5: 1121183,
    3.5: 1043683,
    4.5: 983266,
  },
  {
    1: 1213250,
    2: 1159083,
    3: 1075750,
    4: 1005750,
    5: 948250,
    "revenu brut": 3325000,
    trimf: 1500,
    1.5: 1188250,
    2.5: 1121583,
    3.5: 1044083,
    4.5: 983666,
  },
  {
    1: 1213650,
    2: 1159483,
    3: 1076150,
    4: 1006150,
    5: 948650,
    "revenu brut": 3326000,
    trimf: 1500,
    1.5: 1188650,
    2.5: 1121983,
    3.5: 1044483,
    4.5: 984066,
  },
  {
    1: 1214050,
    2: 1159883,
    3: 1076550,
    4: 1006550,
    5: 949050,
    "revenu brut": 3327000,
    trimf: 1500,
    1.5: 1189050,
    2.5: 1122383,
    3.5: 1044883,
    4.5: 984466,
  },
  {
    1: 1214450,
    2: 1160283,
    3: 1076950,
    4: 1006950,
    5: 949450,
    "revenu brut": 3328000,
    trimf: 1500,
    1.5: 1189450,
    2.5: 1122783,
    3.5: 1045283,
    4.5: 984866,
  },
  {
    1: 1214850,
    2: 1160683,
    3: 1077350,
    4: 1007350,
    5: 949850,
    "revenu brut": 3329000,
    trimf: 1500,
    1.5: 1189850,
    2.5: 1123183,
    3.5: 1045683,
    4.5: 985266,
  },
  {
    1: 1215250,
    2: 1161083,
    3: 1077750,
    4: 1007750,
    5: 950250,
    "revenu brut": 3330000,
    trimf: 1500,
    1.5: 1190250,
    2.5: 1123583,
    3.5: 1046083,
    4.5: 985666,
  },
  {
    1: 1215650,
    2: 1161483,
    3: 1078150,
    4: 1008150,
    5: 950650,
    "revenu brut": 3331_000,
    trimf: 1500,
    1.5: 1190650,
    2.5: 1123983,
    3.5: 1046483,
    4.5: 986066,
  },
  {
    1: 1216050,
    2: 1161883,
    3: 1078550,
    4: 1008550,
    5: 951050,
    "revenu brut": 3332000,
    trimf: 1500,
    1.5: 1191050,
    2.5: 1124383,
    3.5: 1046883,
    4.5: 986466,
  },
  {
    1: 1216450,
    2: 1162283,
    3: 1078950,
    4: 1008950,
    5: 951450,
    "revenu brut": 3333000,
    trimf: 1500,
    1.5: 1191450,
    2.5: 1124783,
    3.5: 1047283,
    4.5: 986866,
  },
  {
    1: 1216850,
    2: 1162683,
    3: 1079350,
    4: 1009350,
    5: 951850,
    "revenu brut": 3334000,
    trimf: 1500,
    1.5: 1191850,
    2.5: 1125183,
    3.5: 1047683,
    4.5: 987266,
  },
  {
    1: 1217250,
    2: 1163083,
    3: 1079750,
    4: 1009750,
    5: 952250,
    "revenu brut": 3335000,
    trimf: 1500,
    1.5: 1192250,
    2.5: 1125583,
    3.5: 1048083,
    4.5: 987666,
  },
  {
    1: 1217650,
    2: 1163483,
    3: 1080150,
    4: 1010150,
    5: 952650,
    "revenu brut": 3336000,
    trimf: 1500,
    1.5: 1192650,
    2.5: 1125983,
    3.5: 1048483,
    4.5: 988066,
  },
  {
    1: 1218050,
    2: 1163883,
    3: 1080550,
    4: 1010550,
    5: 953050,
    "revenu brut": 3337000,
    trimf: 1500,
    1.5: 1193050,
    2.5: 1126383,
    3.5: 1048883,
    4.5: 988466,
  },
  {
    1: 1218450,
    2: 1164283,
    3: 1080950,
    4: 1010950,
    5: 953450,
    "revenu brut": 3338000,
    trimf: 1500,
    1.5: 1193450,
    2.5: 1126783,
    3.5: 1049283,
    4.5: 988866,
  },
  {
    1: 1218850,
    2: 1164683,
    3: 1081350,
    4: 1011350,
    5: 953850,
    "revenu brut": 3339000,
    trimf: 1500,
    1.5: 1193850,
    2.5: 1127183,
    3.5: 1049683,
    4.5: 989266,
  },
  {
    1: 1219250,
    2: 1165083,
    3: 1081750,
    4: 1011750,
    5: 954250,
    "revenu brut": 3340000,
    trimf: 1500,
    1.5: 1194250,
    2.5: 1127583,
    3.5: 1050083,
    4.5: 989666,
  },
  {
    1: 1219650,
    2: 1165483,
    3: 1082150,
    4: 1012150,
    5: 954650,
    "revenu brut": 3341_000,
    trimf: 1500,
    1.5: 1194650,
    2.5: 1127983,
    3.5: 1050483,
    4.5: 990066,
  },
  {
    1: 1220050,
    2: 1165883,
    3: 1082550,
    4: 1012550,
    5: 955050,
    "revenu brut": 3342000,
    trimf: 1500,
    1.5: 1195050,
    2.5: 1128383,
    3.5: 1050883,
    4.5: 990466,
  },
  {
    1: 1220450,
    2: 1166283,
    3: 1082950,
    4: 1012950,
    5: 955450,
    "revenu brut": 3343000,
    trimf: 1500,
    1.5: 1195450,
    2.5: 1128783,
    3.5: 1051283,
    4.5: 990866,
  },
  {
    1: 1220850,
    2: 1166683,
    3: 1083350,
    4: 1013350,
    5: 955850,
    "revenu brut": 3344000,
    trimf: 1500,
    1.5: 1195850,
    2.5: 1129183,
    3.5: 1051683,
    4.5: 991266,
  },
  {
    1: 1221250,
    2: 1167083,
    3: 1083750,
    4: 1013750,
    5: 956250,
    "revenu brut": 3345000,
    trimf: 1500,
    1.5: 1196250,
    2.5: 1129583,
    3.5: 1052083,
    4.5: 991666,
  },
  {
    1: 1221650,
    2: 1167483,
    3: 1084150,
    4: 1014150,
    5: 956650,
    "revenu brut": 3346000,
    trimf: 1500,
    1.5: 1196650,
    2.5: 1129983,
    3.5: 1052483,
    4.5: 992066,
  },
  {
    1: 1222050,
    2: 1167883,
    3: 1084550,
    4: 1014550,
    5: 957050,
    "revenu brut": 3347000,
    trimf: 1500,
    1.5: 1197050,
    2.5: 1130383,
    3.5: 1052883,
    4.5: 992466,
  },
  {
    1: 1222450,
    2: 1168283,
    3: 1084950,
    4: 1014950,
    5: 957450,
    "revenu brut": 3348000,
    trimf: 1500,
    1.5: 1197450,
    2.5: 1130783,
    3.5: 1053283,
    4.5: 992866,
  },
  {
    1: 1222850,
    2: 1168683,
    3: 1085350,
    4: 1015350,
    5: 957850,
    "revenu brut": 3349000,
    trimf: 1500,
    1.5: 1197850,
    2.5: 1131183,
    3.5: 1053683,
    4.5: 993266,
  },
  {
    1: 1223250,
    2: 1169083,
    3: 1085750,
    4: 1015750,
    5: 958250,
    "revenu brut": 3350000,
    trimf: 1500,
    1.5: 1198250,
    2.5: 1131583,
    3.5: 1054083,
    4.5: 993666,
  },
  {
    1: 1223650,
    2: 1169483,
    3: 1086150,
    4: 1016150,
    5: 958650,
    "revenu brut": 3351_000,
    trimf: 1500,
    1.5: 1198650,
    2.5: 1131983,
    3.5: 1054483,
    4.5: 994066,
  },
  {
    1: 1224050,
    2: 1169883,
    3: 1086550,
    4: 1016550,
    5: 959050,
    "revenu brut": 3352000,
    trimf: 1500,
    1.5: 1199050,
    2.5: 1132383,
    3.5: 1054883,
    4.5: 994466,
  },
  {
    1: 1224450,
    2: 1170283,
    3: 1086950,
    4: 1016950,
    5: 959450,
    "revenu brut": 3353000,
    trimf: 1500,
    1.5: 1199450,
    2.5: 1132783,
    3.5: 1055283,
    4.5: 994866,
  },
  {
    1: 1224850,
    2: 1170683,
    3: 1087350,
    4: 1017350,
    5: 959850,
    "revenu brut": 3354000,
    trimf: 1500,
    1.5: 1199850,
    2.5: 1133183,
    3.5: 1055683,
    4.5: 995266,
  },
  {
    1: 1225250,
    2: 1171083,
    3: 1087750,
    4: 1017750,
    5: 960250,
    "revenu brut": 3355000,
    trimf: 1500,
    1.5: 1200250,
    2.5: 1133583,
    3.5: 1056083,
    4.5: 995666,
  },
  {
    1: 1225650,
    2: 1171483,
    3: 1088150,
    4: 1018150,
    5: 960650,
    "revenu brut": 3356000,
    trimf: 1500,
    1.5: 1200650,
    2.5: 1133983,
    3.5: 1056483,
    4.5: 996066,
  },
  {
    1: 1226050,
    2: 1171883,
    3: 1088550,
    4: 1018550,
    5: 961050,
    "revenu brut": 3357000,
    trimf: 1500,
    1.5: 1201050,
    2.5: 1134383,
    3.5: 1056883,
    4.5: 996466,
  },
  {
    1: 1226450,
    2: 1172283,
    3: 1088950,
    4: 1018950,
    5: 961450,
    "revenu brut": 3358000,
    trimf: 1500,
    1.5: 1201450,
    2.5: 1134783,
    3.5: 1057283,
    4.5: 996866,
  },
  {
    1: 1226850,
    2: 1172683,
    3: 1089350,
    4: 1019350,
    5: 961850,
    "revenu brut": 3359000,
    trimf: 1500,
    1.5: 1201850,
    2.5: 1135183,
    3.5: 1057683,
    4.5: 997266,
  },
  {
    1: 1227250,
    2: 1173083,
    3: 1089750,
    4: 1019750,
    5: 962250,
    "revenu brut": 3360000,
    trimf: 1500,
    1.5: 1202250,
    2.5: 1135583,
    3.5: 1058083,
    4.5: 997666,
  },
  {
    1: 1227650,
    2: 1173483,
    3: 1090150,
    4: 1020150,
    5: 962650,
    "revenu brut": 3361_000,
    trimf: 1500,
    1.5: 1202650,
    2.5: 1135983,
    3.5: 1058483,
    4.5: 998066,
  },
  {
    1: 1228050,
    2: 1173883,
    3: 1090550,
    4: 1020550,
    5: 963050,
    "revenu brut": 3362000,
    trimf: 1500,
    1.5: 1203050,
    2.5: 1136383,
    3.5: 1058883,
    4.5: 998466,
  },
  {
    1: 1228450,
    2: 1174283,
    3: 1090950,
    4: 1020950,
    5: 963450,
    "revenu brut": 3363000,
    trimf: 1500,
    1.5: 1203450,
    2.5: 1136783,
    3.5: 1059283,
    4.5: 998866,
  },
  {
    1: 1228850,
    2: 1174683,
    3: 1091350,
    4: 1021350,
    5: 963850,
    "revenu brut": 3364000,
    trimf: 1500,
    1.5: 1203850,
    2.5: 1137183,
    3.5: 1059683,
    4.5: 999266,
  },
  {
    1: 1229250,
    2: 1175083,
    3: 1091750,
    4: 1021750,
    5: 964250,
    "revenu brut": 3365000,
    trimf: 1500,
    1.5: 1204250,
    2.5: 1137583,
    3.5: 1060083,
    4.5: 999666,
  },
  {
    1: 1229650,
    2: 1175483,
    3: 1092150,
    4: 1022150,
    5: 964650,
    "revenu brut": 3366000,
    trimf: 1500,
    1.5: 1204650,
    2.5: 1137983,
    3.5: 1060483,
    4.5: 1_000066,
  },
  {
    1: 1230050,
    2: 1175883,
    3: 1092550,
    4: 1022550,
    5: 965050,
    "revenu brut": 3367000,
    trimf: 1500,
    1.5: 1205050,
    2.5: 1138383,
    3.5: 1060883,
    4.5: 1_000466,
  },
  {
    1: 1230450,
    2: 1176283,
    3: 1092950,
    4: 1022950,
    5: 965450,
    "revenu brut": 3368000,
    trimf: 1500,
    1.5: 1205450,
    2.5: 1138783,
    3.5: 1061283,
    4.5: 1_000866,
  },
  {
    1: 1230850,
    2: 1176683,
    3: 1093350,
    4: 1023350,
    5: 965850,
    "revenu brut": 3369000,
    trimf: 1500,
    1.5: 1205850,
    2.5: 1139183,
    3.5: 1061683,
    4.5: 1001266,
  },
  {
    1: 1231250,
    2: 1177083,
    3: 1093750,
    4: 1023750,
    5: 966250,
    "revenu brut": 3370000,
    trimf: 1500,
    1.5: 1206250,
    2.5: 1139583,
    3.5: 1062083,
    4.5: 1001666,
  },
  {
    1: 1231650,
    2: 1177483,
    3: 1094150,
    4: 1024150,
    5: 966650,
    "revenu brut": 3371_000,
    trimf: 1500,
    1.5: 1206650,
    2.5: 1139983,
    3.5: 1062483,
    4.5: 1002066,
  },
  {
    1: 1232050,
    2: 1177883,
    3: 1094550,
    4: 1024550,
    5: 967050,
    "revenu brut": 3372000,
    trimf: 1500,
    1.5: 1207050,
    2.5: 1140383,
    3.5: 1062883,
    4.5: 1002466,
  },
  {
    1: 1232450,
    2: 1178283,
    3: 1094950,
    4: 1024950,
    5: 967450,
    "revenu brut": 3373000,
    trimf: 1500,
    1.5: 1207450,
    2.5: 1140783,
    3.5: 1063283,
    4.5: 1002866,
  },
  {
    1: 1232850,
    2: 1178683,
    3: 1095350,
    4: 1025350,
    5: 967850,
    "revenu brut": 3374000,
    trimf: 1500,
    1.5: 1207850,
    2.5: 1141183,
    3.5: 1063683,
    4.5: 1003266,
  },
  {
    1: 1233250,
    2: 1179083,
    3: 1095750,
    4: 1025750,
    5: 968250,
    "revenu brut": 3375000,
    trimf: 1500,
    1.5: 1208250,
    2.5: 1141583,
    3.5: 1064083,
    4.5: 1003666,
  },
  {
    1: 1233650,
    2: 1179483,
    3: 1096150,
    4: 1026150,
    5: 968650,
    "revenu brut": 3376000,
    trimf: 1500,
    1.5: 1208650,
    2.5: 1141983,
    3.5: 1064483,
    4.5: 1004066,
  },
  {
    1: 1234050,
    2: 1179883,
    3: 1096550,
    4: 1026550,
    5: 969050,
    "revenu brut": 3377000,
    trimf: 1500,
    1.5: 1209050,
    2.5: 1142383,
    3.5: 1064883,
    4.5: 1004466,
  },
  {
    1: 1234450,
    2: 1180283,
    3: 1096950,
    4: 1026950,
    5: 969450,
    "revenu brut": 3378000,
    trimf: 1500,
    1.5: 1209450,
    2.5: 1142783,
    3.5: 1065283,
    4.5: 1004866,
  },
  {
    1: 1234850,
    2: 1180683,
    3: 1097350,
    4: 1027350,
    5: 969850,
    "revenu brut": 3379000,
    trimf: 1500,
    1.5: 1209850,
    2.5: 1143183,
    3.5: 1065683,
    4.5: 1005266,
  },
  {
    1: 1235250,
    2: 1181083,
    3: 1097750,
    4: 1027750,
    5: 970250,
    "revenu brut": 3380000,
    trimf: 1500,
    1.5: 1210250,
    2.5: 1143583,
    3.5: 1066083,
    4.5: 1005666,
  },
  {
    1: 1235650,
    2: 1181483,
    3: 1098150,
    4: 1028150,
    5: 970650,
    "revenu brut": 3381_000,
    trimf: 1500,
    1.5: 1210650,
    2.5: 1143983,
    3.5: 1066483,
    4.5: 1006066,
  },
  {
    1: 1236050,
    2: 1181883,
    3: 1098550,
    4: 1028550,
    5: 971050,
    "revenu brut": 3382000,
    trimf: 1500,
    1.5: 1211050,
    2.5: 1144383,
    3.5: 1066883,
    4.5: 1006466,
  },
  {
    1: 1236450,
    2: 1182283,
    3: 1098950,
    4: 1028950,
    5: 971450,
    "revenu brut": 3383000,
    trimf: 1500,
    1.5: 1211450,
    2.5: 1144783,
    3.5: 1067283,
    4.5: 1006866,
  },
  {
    1: 1236850,
    2: 1182683,
    3: 1099350,
    4: 1029350,
    5: 971850,
    "revenu brut": 3384000,
    trimf: 1500,
    1.5: 1211850,
    2.5: 1145183,
    3.5: 1067683,
    4.5: 1007266,
  },
  {
    1: 1237250,
    2: 1183083,
    3: 1099750,
    4: 1029750,
    5: 972250,
    "revenu brut": 3385000,
    trimf: 1500,
    1.5: 1212250,
    2.5: 1145583,
    3.5: 1068083,
    4.5: 1007666,
  },
  {
    1: 1237650,
    2: 1183483,
    3: 1100150,
    4: 1030150,
    5: 972650,
    "revenu brut": 3386000,
    trimf: 1500,
    1.5: 1212650,
    2.5: 1145983,
    3.5: 1068483,
    4.5: 1008066,
  },
  {
    1: 1238050,
    2: 1183883,
    3: 1100550,
    4: 1030550,
    5: 973050,
    "revenu brut": 3387000,
    trimf: 1500,
    1.5: 1213050,
    2.5: 1146383,
    3.5: 1068883,
    4.5: 1008466,
  },
  {
    1: 1238450,
    2: 1184283,
    3: 1100950,
    4: 1030950,
    5: 973450,
    "revenu brut": 3388000,
    trimf: 1500,
    1.5: 1213450,
    2.5: 1146783,
    3.5: 1069283,
    4.5: 1008866,
  },
  {
    1: 1238850,
    2: 1184683,
    3: 1101350,
    4: 1031350,
    5: 973850,
    "revenu brut": 3389000,
    trimf: 1500,
    1.5: 1213850,
    2.5: 1147183,
    3.5: 1069683,
    4.5: 1009266,
  },
  {
    1: 1239250,
    2: 1185083,
    3: 1101750,
    4: 1031750,
    5: 974250,
    "revenu brut": 3390000,
    trimf: 1500,
    1.5: 1214250,
    2.5: 1147583,
    3.5: 1070083,
    4.5: 1009666,
  },
  {
    1: 1239650,
    2: 1185483,
    3: 1102150,
    4: 1032150,
    5: 974650,
    "revenu brut": 3391_000,
    trimf: 1500,
    1.5: 1214650,
    2.5: 1147983,
    3.5: 1070483,
    4.5: 1010066,
  },
  {
    1: 1240050,
    2: 1185883,
    3: 1102550,
    4: 1032550,
    5: 975050,
    "revenu brut": 3392000,
    trimf: 1500,
    1.5: 1215050,
    2.5: 1148383,
    3.5: 1070883,
    4.5: 1010466,
  },
  {
    1: 1240450,
    2: 1186283,
    3: 1102950,
    4: 1032950,
    5: 975450,
    "revenu brut": 3393000,
    trimf: 1500,
    1.5: 1215450,
    2.5: 1148783,
    3.5: 1071283,
    4.5: 1010866,
  },
  {
    1: 1240850,
    2: 1186683,
    3: 1103350,
    4: 1033350,
    5: 975850,
    "revenu brut": 3394000,
    trimf: 1500,
    1.5: 1215850,
    2.5: 1149183,
    3.5: 1071683,
    4.5: 1011266,
  },
  {
    1: 1241250,
    2: 1187083,
    3: 1103750,
    4: 1033750,
    5: 976250,
    "revenu brut": 3395000,
    trimf: 1500,
    1.5: 1216250,
    2.5: 1149583,
    3.5: 1072083,
    4.5: 1011666,
  },
  {
    1: 1241650,
    2: 1187483,
    3: 1104150,
    4: 1034150,
    5: 976650,
    "revenu brut": 3396000,
    trimf: 1500,
    1.5: 1216650,
    2.5: 1149983,
    3.5: 1072483,
    4.5: 1012066,
  },
  {
    1: 1242050,
    2: 1187883,
    3: 1104550,
    4: 1034550,
    5: 977050,
    "revenu brut": 3397000,
    trimf: 1500,
    1.5: 1217050,
    2.5: 1150383,
    3.5: 1072883,
    4.5: 1012466,
  },
  {
    1: 1242450,
    2: 1188283,
    3: 1104950,
    4: 1034950,
    5: 977450,
    "revenu brut": 3398000,
    trimf: 1500,
    1.5: 1217450,
    2.5: 1150783,
    3.5: 1073283,
    4.5: 1012866,
  },
  {
    1: 1242850,
    2: 1188683,
    3: 1105350,
    4: 1035350,
    5: 977850,
    "revenu brut": 3399000,
    trimf: 1500,
    1.5: 1217850,
    2.5: 1151183,
    3.5: 1073683,
    4.5: 1013266,
  },
  {
    1: 1243250,
    2: 1189083,
    3: 1105750,
    4: 1035750,
    5: 978250,
    "revenu brut": 3400000,
    trimf: 1500,
    1.5: 1218250,
    2.5: 1151583,
    3.5: 1074083,
    4.5: 1013666,
  },
  {
    1: 1243650,
    2: 1189483,
    3: 1106150,
    4: 1036150,
    5: 978650,
    "revenu brut": 3401_000,
    trimf: 1500,
    1.5: 1218650,
    2.5: 1151983,
    3.5: 1074483,
    4.5: 1014066,
  },
  {
    1: 1244050,
    2: 1189883,
    3: 1106550,
    4: 1036550,
    5: 979050,
    "revenu brut": 3402000,
    trimf: 1500,
    1.5: 1219050,
    2.5: 1152383,
    3.5: 1074883,
    4.5: 1014466,
  },
  {
    1: 1244450,
    2: 1190283,
    3: 1106950,
    4: 1036950,
    5: 979450,
    "revenu brut": 3403000,
    trimf: 1500,
    1.5: 1219450,
    2.5: 1152783,
    3.5: 1075283,
    4.5: 1014866,
  },
  {
    1: 1244850,
    2: 1190683,
    3: 1107350,
    4: 1037350,
    5: 979850,
    "revenu brut": 3404000,
    trimf: 1500,
    1.5: 1219850,
    2.5: 1153183,
    3.5: 1075683,
    4.5: 1015266,
  },
  {
    1: 1245250,
    2: 1191083,
    3: 1107750,
    4: 1037750,
    5: 980250,
    "revenu brut": 3405000,
    trimf: 1500,
    1.5: 1220250,
    2.5: 1153583,
    3.5: 1076083,
    4.5: 1015666,
  },
  {
    1: 1245650,
    2: 1191483,
    3: 1108150,
    4: 1038150,
    5: 980650,
    "revenu brut": 3406000,
    trimf: 1500,
    1.5: 1220650,
    2.5: 1153983,
    3.5: 1076483,
    4.5: 1016066,
  },
  {
    1: 1246050,
    2: 1191883,
    3: 1108550,
    4: 1038550,
    5: 981050,
    "revenu brut": 3407000,
    trimf: 1500,
    1.5: 1221050,
    2.5: 1154383,
    3.5: 1076883,
    4.5: 1016466,
  },
  {
    1: 1246450,
    2: 1192283,
    3: 1108950,
    4: 1038950,
    5: 981450,
    "revenu brut": 3408000,
    trimf: 1500,
    1.5: 1221450,
    2.5: 1154783,
    3.5: 1077283,
    4.5: 1016866,
  },
  {
    1: 1246850,
    2: 1192683,
    3: 1109350,
    4: 1039350,
    5: 981850,
    "revenu brut": 3409000,
    trimf: 1500,
    1.5: 1221850,
    2.5: 1155183,
    3.5: 1077683,
    4.5: 1017266,
  },
  {
    1: 1247250,
    2: 1193083,
    3: 1109750,
    4: 1039750,
    5: 982250,
    "revenu brut": 341_0000,
    trimf: 1500,
    1.5: 1222250,
    2.5: 1155583,
    3.5: 1078083,
    4.5: 1017666,
  },
  {
    1: 1247650,
    2: 1193483,
    3: 1110150,
    4: 1040150,
    5: 982650,
    "revenu brut": 3411_000,
    trimf: 1500,
    1.5: 1222650,
    2.5: 1155983,
    3.5: 1078483,
    4.5: 1018066,
  },
  {
    1: 1248050,
    2: 1193883,
    3: 1110550,
    4: 1040550,
    5: 983050,
    "revenu brut": 3412000,
    trimf: 1500,
    1.5: 1223050,
    2.5: 1156383,
    3.5: 1078883,
    4.5: 1018466,
  },
  {
    1: 1248450,
    2: 1194283,
    3: 1110950,
    4: 1040950,
    5: 983450,
    "revenu brut": 3413000,
    trimf: 1500,
    1.5: 1223450,
    2.5: 1156783,
    3.5: 1079283,
    4.5: 1018866,
  },
  {
    1: 1248850,
    2: 1194683,
    3: 1111350,
    4: 1041350,
    5: 983850,
    "revenu brut": 3414000,
    trimf: 1500,
    1.5: 1223850,
    2.5: 1157183,
    3.5: 1079683,
    4.5: 1019266,
  },
  {
    1: 1249250,
    2: 1195083,
    3: 1111750,
    4: 1041750,
    5: 984250,
    "revenu brut": 3415000,
    trimf: 1500,
    1.5: 1224250,
    2.5: 1157583,
    3.5: 1080083,
    4.5: 1019666,
  },
  {
    1: 1249650,
    2: 1195483,
    3: 1112150,
    4: 1042150,
    5: 984650,
    "revenu brut": 3416000,
    trimf: 1500,
    1.5: 1224650,
    2.5: 1157983,
    3.5: 1080483,
    4.5: 1020066,
  },
  {
    1: 1250050,
    2: 1195883,
    3: 1112550,
    4: 1042550,
    5: 985050,
    "revenu brut": 3417000,
    trimf: 1500,
    1.5: 1225050,
    2.5: 1158383,
    3.5: 1080883,
    4.5: 1020466,
  },
  {
    1: 1250450,
    2: 1196283,
    3: 1112950,
    4: 1042950,
    5: 985450,
    "revenu brut": 3418000,
    trimf: 1500,
    1.5: 1225450,
    2.5: 1158783,
    3.5: 1081283,
    4.5: 1020866,
  },
  {
    1: 1250850,
    2: 1196683,
    3: 1113350,
    4: 1043350,
    5: 985850,
    "revenu brut": 3419000,
    trimf: 1500,
    1.5: 1225850,
    2.5: 1159183,
    3.5: 1081683,
    4.5: 1021266,
  },
  {
    1: 1251250,
    2: 1197083,
    3: 1113750,
    4: 1043750,
    5: 986250,
    "revenu brut": 3420000,
    trimf: 1500,
    1.5: 1226250,
    2.5: 1159583,
    3.5: 1082083,
    4.5: 1021666,
  },
  {
    1: 1251650,
    2: 1197483,
    3: 1114150,
    4: 1044150,
    5: 986650,
    "revenu brut": 3421_000,
    trimf: 1500,
    1.5: 1226650,
    2.5: 1159983,
    3.5: 1082483,
    4.5: 1022066,
  },
  {
    1: 1252050,
    2: 1197883,
    3: 1114550,
    4: 1044550,
    5: 987050,
    "revenu brut": 3422000,
    trimf: 1500,
    1.5: 1227050,
    2.5: 1160383,
    3.5: 1082883,
    4.5: 1022466,
  },
  {
    1: 1252450,
    2: 1198283,
    3: 1114950,
    4: 1044950,
    5: 987450,
    "revenu brut": 3423000,
    trimf: 1500,
    1.5: 1227450,
    2.5: 1160783,
    3.5: 1083283,
    4.5: 1022866,
  },
  {
    1: 1252850,
    2: 1198683,
    3: 1115350,
    4: 1045350,
    5: 987850,
    "revenu brut": 3424000,
    trimf: 1500,
    1.5: 1227850,
    2.5: 1161183,
    3.5: 1083683,
    4.5: 1023266,
  },
  {
    1: 1253250,
    2: 1199083,
    3: 1115750,
    4: 1045750,
    5: 988250,
    "revenu brut": 3425000,
    trimf: 1500,
    1.5: 1228250,
    2.5: 1161583,
    3.5: 1084083,
    4.5: 1023666,
  },
  {
    1: 1253650,
    2: 1199483,
    3: 1116150,
    4: 1046150,
    5: 988650,
    "revenu brut": 3426000,
    trimf: 1500,
    1.5: 1228650,
    2.5: 1161983,
    3.5: 1084483,
    4.5: 1024066,
  },
  {
    1: 1254050,
    2: 1199883,
    3: 1116550,
    4: 1046550,
    5: 989050,
    "revenu brut": 3427000,
    trimf: 1500,
    1.5: 1229050,
    2.5: 1162383,
    3.5: 1084883,
    4.5: 1024466,
  },
  {
    1: 1254450,
    2: 1200283,
    3: 1116950,
    4: 1046950,
    5: 989450,
    "revenu brut": 3428000,
    trimf: 1500,
    1.5: 1229450,
    2.5: 1162783,
    3.5: 1085283,
    4.5: 1024866,
  },
  {
    1: 1254850,
    2: 1200683,
    3: 1117350,
    4: 1047350,
    5: 989850,
    "revenu brut": 3429000,
    trimf: 1500,
    1.5: 1229850,
    2.5: 1163183,
    3.5: 1085683,
    4.5: 1025266,
  },
  {
    1: 1255250,
    2: 1201083,
    3: 1117750,
    4: 1047750,
    5: 990250,
    "revenu brut": 3430000,
    trimf: 1500,
    1.5: 1230250,
    2.5: 1163583,
    3.5: 1086083,
    4.5: 1025666,
  },
  {
    1: 1255650,
    2: 1201483,
    3: 1118150,
    4: 1048150,
    5: 990650,
    "revenu brut": 3431_000,
    trimf: 1500,
    1.5: 1230650,
    2.5: 1163983,
    3.5: 1086483,
    4.5: 1026066,
  },
  {
    1: 1256050,
    2: 1201883,
    3: 1118550,
    4: 1048550,
    5: 991050,
    "revenu brut": 3432000,
    trimf: 1500,
    1.5: 1231050,
    2.5: 1164383,
    3.5: 1086883,
    4.5: 1026466,
  },
  {
    1: 1256450,
    2: 1202283,
    3: 1118950,
    4: 1048950,
    5: 991450,
    "revenu brut": 3433000,
    trimf: 1500,
    1.5: 1231450,
    2.5: 1164783,
    3.5: 1087283,
    4.5: 1026866,
  },
  {
    1: 1256850,
    2: 1202683,
    3: 1119350,
    4: 1049350,
    5: 991850,
    "revenu brut": 3434000,
    trimf: 1500,
    1.5: 1231850,
    2.5: 1165183,
    3.5: 1087683,
    4.5: 1027266,
  },
  {
    1: 1257250,
    2: 1203083,
    3: 1119750,
    4: 1049750,
    5: 992250,
    "revenu brut": 3435000,
    trimf: 1500,
    1.5: 1232250,
    2.5: 1165583,
    3.5: 1088083,
    4.5: 1027666,
  },
  {
    1: 1257650,
    2: 1203483,
    3: 1120150,
    4: 1050150,
    5: 992650,
    "revenu brut": 3436000,
    trimf: 1500,
    1.5: 1232650,
    2.5: 1165983,
    3.5: 1088483,
    4.5: 1028066,
  },
  {
    1: 1258050,
    2: 1203883,
    3: 1120550,
    4: 1050550,
    5: 993050,
    "revenu brut": 3437000,
    trimf: 1500,
    1.5: 1233050,
    2.5: 1166383,
    3.5: 1088883,
    4.5: 1028466,
  },
  {
    1: 1258450,
    2: 1204283,
    3: 1120950,
    4: 1050950,
    5: 993450,
    "revenu brut": 3438000,
    trimf: 1500,
    1.5: 1233450,
    2.5: 1166783,
    3.5: 1089283,
    4.5: 1028866,
  },
  {
    1: 1258850,
    2: 1204683,
    3: 1121350,
    4: 1051350,
    5: 993850,
    "revenu brut": 3439000,
    trimf: 1500,
    1.5: 1233850,
    2.5: 1167183,
    3.5: 1089683,
    4.5: 1029266,
  },
  {
    1: 1259250,
    2: 1205083,
    3: 1121750,
    4: 1051750,
    5: 994250,
    "revenu brut": 3440000,
    trimf: 1500,
    1.5: 1234250,
    2.5: 1167583,
    3.5: 1090083,
    4.5: 1029666,
  },
  {
    1: 1259650,
    2: 1205483,
    3: 1122150,
    4: 1052150,
    5: 994650,
    "revenu brut": 3441_000,
    trimf: 1500,
    1.5: 1234650,
    2.5: 1167983,
    3.5: 1090483,
    4.5: 1030066,
  },
  {
    1: 1260050,
    2: 1205883,
    3: 1122550,
    4: 1052550,
    5: 995050,
    "revenu brut": 3442000,
    trimf: 1500,
    1.5: 1235050,
    2.5: 1168383,
    3.5: 1090883,
    4.5: 1030466,
  },
  {
    1: 1260450,
    2: 1206283,
    3: 1122950,
    4: 1052950,
    5: 995450,
    "revenu brut": 3443000,
    trimf: 1500,
    1.5: 1235450,
    2.5: 1168783,
    3.5: 1091283,
    4.5: 1030866,
  },
  {
    1: 1260850,
    2: 1206683,
    3: 1123350,
    4: 1053350,
    5: 995850,
    "revenu brut": 3444000,
    trimf: 1500,
    1.5: 1235850,
    2.5: 1169183,
    3.5: 1091683,
    4.5: 1031266,
  },
  {
    1: 1261250,
    2: 1207083,
    3: 1123750,
    4: 1053750,
    5: 996250,
    "revenu brut": 3445000,
    trimf: 1500,
    1.5: 1236250,
    2.5: 1169583,
    3.5: 1092083,
    4.5: 1031666,
  },
  {
    1: 1261650,
    2: 1207483,
    3: 1124150,
    4: 1054150,
    5: 996650,
    "revenu brut": 3446000,
    trimf: 1500,
    1.5: 1236650,
    2.5: 1169983,
    3.5: 1092483,
    4.5: 1032066,
  },
  {
    1: 1262050,
    2: 1207883,
    3: 1124550,
    4: 1054550,
    5: 997050,
    "revenu brut": 3447000,
    trimf: 1500,
    1.5: 1237050,
    2.5: 1170383,
    3.5: 1092883,
    4.5: 1032466,
  },
  {
    1: 1262450,
    2: 1208283,
    3: 1124950,
    4: 1054950,
    5: 997450,
    "revenu brut": 3448000,
    trimf: 1500,
    1.5: 1237450,
    2.5: 1170783,
    3.5: 1093283,
    4.5: 1032866,
  },
  {
    1: 1262850,
    2: 1208683,
    3: 1125350,
    4: 1055350,
    5: 997850,
    "revenu brut": 3449000,
    trimf: 1500,
    1.5: 1237850,
    2.5: 1171183,
    3.5: 1093683,
    4.5: 1033266,
  },
  {
    1: 1263250,
    2: 1209083,
    3: 1125750,
    4: 1055750,
    5: 998250,
    "revenu brut": 3450000,
    trimf: 1500,
    1.5: 1238250,
    2.5: 1171583,
    3.5: 1094083,
    4.5: 1033666,
  },
  {
    1: 1263650,
    2: 1209483,
    3: 1126150,
    4: 1056150,
    5: 998650,
    "revenu brut": 3451_000,
    trimf: 1500,
    1.5: 1238650,
    2.5: 1171983,
    3.5: 1094483,
    4.5: 1034066,
  },
  {
    1: 1264050,
    2: 1209883,
    3: 1126550,
    4: 1056550,
    5: 999050,
    "revenu brut": 3452000,
    trimf: 1500,
    1.5: 1239050,
    2.5: 1172383,
    3.5: 1094883,
    4.5: 1034466,
  },
  {
    1: 1264450,
    2: 1210283,
    3: 1126950,
    4: 1056950,
    5: 999450,
    "revenu brut": 3453000,
    trimf: 1500,
    1.5: 1239450,
    2.5: 1172783,
    3.5: 1095283,
    4.5: 1034866,
  },
  {
    1: 1264850,
    2: 1210683,
    3: 1127350,
    4: 1057350,
    5: 999850,
    "revenu brut": 3454000,
    trimf: 1500,
    1.5: 1239850,
    2.5: 1173183,
    3.5: 1095683,
    4.5: 1035266,
  },
  {
    1: 1265250,
    2: 1211083,
    3: 1127750,
    4: 1057750,
    5: 1_000250,
    "revenu brut": 3455000,
    trimf: 1500,
    1.5: 1240250,
    2.5: 1173583,
    3.5: 1096083,
    4.5: 1035666,
  },
  {
    1: 1265650,
    2: 1211483,
    3: 1128150,
    4: 1058150,
    5: 1_000650,
    "revenu brut": 3456000,
    trimf: 1500,
    1.5: 1240650,
    2.5: 1173983,
    3.5: 1096483,
    4.5: 1036066,
  },
  {
    1: 1266050,
    2: 1211883,
    3: 1128550,
    4: 1058550,
    5: 1001050,
    "revenu brut": 3457000,
    trimf: 1500,
    1.5: 1241050,
    2.5: 1174383,
    3.5: 1096883,
    4.5: 1036466,
  },
  {
    1: 1266450,
    2: 1212283,
    3: 1128950,
    4: 1058950,
    5: 1001450,
    "revenu brut": 3458000,
    trimf: 1500,
    1.5: 1241450,
    2.5: 1174783,
    3.5: 1097283,
    4.5: 1036866,
  },
  {
    1: 1266850,
    2: 1212683,
    3: 1129350,
    4: 1059350,
    5: 1001850,
    "revenu brut": 3459000,
    trimf: 1500,
    1.5: 1241850,
    2.5: 1175183,
    3.5: 1097683,
    4.5: 1037266,
  },
  {
    1: 1267250,
    2: 1213083,
    3: 1129750,
    4: 1059750,
    5: 1002250,
    "revenu brut": 3460000,
    trimf: 1500,
    1.5: 1242250,
    2.5: 1175583,
    3.5: 1098083,
    4.5: 1037666,
  },
  {
    1: 1267650,
    2: 1213483,
    3: 1130150,
    4: 1060150,
    5: 1002650,
    "revenu brut": 3461_000,
    trimf: 1500,
    1.5: 1242650,
    2.5: 1175983,
    3.5: 1098483,
    4.5: 1038066,
  },
  {
    1: 1268050,
    2: 1213883,
    3: 1130550,
    4: 1060550,
    5: 1003050,
    "revenu brut": 3462000,
    trimf: 1500,
    1.5: 1243050,
    2.5: 1176383,
    3.5: 1098883,
    4.5: 1038466,
  },
  {
    1: 1268450,
    2: 1214283,
    3: 1130950,
    4: 1060950,
    5: 1003450,
    "revenu brut": 3463000,
    trimf: 1500,
    1.5: 1243450,
    2.5: 1176783,
    3.5: 1099283,
    4.5: 1038866,
  },
  {
    1: 1268850,
    2: 1214683,
    3: 1131350,
    4: 1061350,
    5: 1003850,
    "revenu brut": 3464000,
    trimf: 1500,
    1.5: 1243850,
    2.5: 1177183,
    3.5: 1099683,
    4.5: 1039266,
  },
  {
    1: 1269250,
    2: 1215083,
    3: 1131750,
    4: 1061750,
    5: 1004250,
    "revenu brut": 3465000,
    trimf: 1500,
    1.5: 1244250,
    2.5: 1177583,
    3.5: 11_00083,
    4.5: 1039666,
  },
  {
    1: 1269650,
    2: 1215483,
    3: 1132150,
    4: 1062150,
    5: 1004650,
    "revenu brut": 3466000,
    trimf: 1500,
    1.5: 1244650,
    2.5: 1177983,
    3.5: 1100483,
    4.5: 1040066,
  },
  {
    1: 1270050,
    2: 1215883,
    3: 1132550,
    4: 1062550,
    5: 1005050,
    "revenu brut": 3467000,
    trimf: 1500,
    1.5: 1245050,
    2.5: 1178383,
    3.5: 1100883,
    4.5: 1040466,
  },
  {
    1: 1270450,
    2: 1216283,
    3: 1132950,
    4: 1062950,
    5: 1005450,
    "revenu brut": 3468000,
    trimf: 1500,
    1.5: 1245450,
    2.5: 1178783,
    3.5: 1101283,
    4.5: 1040866,
  },
  {
    1: 1270850,
    2: 1216683,
    3: 1133350,
    4: 1063350,
    5: 1005850,
    "revenu brut": 3469000,
    trimf: 1500,
    1.5: 1245850,
    2.5: 1179183,
    3.5: 1101683,
    4.5: 1041266,
  },
  {
    1: 1271250,
    2: 1217083,
    3: 1133750,
    4: 1063750,
    5: 1006250,
    "revenu brut": 3470000,
    trimf: 1500,
    1.5: 1246250,
    2.5: 1179583,
    3.5: 1102083,
    4.5: 1041666,
  },
  {
    1: 1271650,
    2: 1217483,
    3: 1134150,
    4: 1064150,
    5: 1006650,
    "revenu brut": 3471_000,
    trimf: 1500,
    1.5: 1246650,
    2.5: 1179983,
    3.5: 1102483,
    4.5: 1042066,
  },
  {
    1: 1272050,
    2: 1217883,
    3: 1134550,
    4: 1064550,
    5: 1007050,
    "revenu brut": 3472000,
    trimf: 1500,
    1.5: 1247050,
    2.5: 1180383,
    3.5: 1102883,
    4.5: 1042466,
  },
  {
    1: 1272450,
    2: 1218283,
    3: 1134950,
    4: 1064950,
    5: 1007450,
    "revenu brut": 3473000,
    trimf: 1500,
    1.5: 1247450,
    2.5: 1180783,
    3.5: 1103283,
    4.5: 1042866,
  },
  {
    1: 1272850,
    2: 1218683,
    3: 1135350,
    4: 1065350,
    5: 1007850,
    "revenu brut": 3474000,
    trimf: 1500,
    1.5: 1247850,
    2.5: 1181183,
    3.5: 1103683,
    4.5: 1043266,
  },
  {
    1: 1273250,
    2: 1219083,
    3: 1135750,
    4: 1065750,
    5: 1008250,
    "revenu brut": 3475000,
    trimf: 1500,
    1.5: 1248250,
    2.5: 1181583,
    3.5: 1104083,
    4.5: 1043666,
  },
  {
    1: 1273650,
    2: 1219483,
    3: 1136150,
    4: 1066150,
    5: 1008650,
    "revenu brut": 3476000,
    trimf: 1500,
    1.5: 1248650,
    2.5: 1181983,
    3.5: 1104483,
    4.5: 1044066,
  },
  {
    1: 1274050,
    2: 1219883,
    3: 1136550,
    4: 1066550,
    5: 1009050,
    "revenu brut": 3477000,
    trimf: 1500,
    1.5: 1249050,
    2.5: 1182383,
    3.5: 1104883,
    4.5: 1044466,
  },
  {
    1: 1274450,
    2: 1220283,
    3: 1136950,
    4: 1066950,
    5: 1009450,
    "revenu brut": 3478000,
    trimf: 1500,
    1.5: 1249450,
    2.5: 1182783,
    3.5: 1105283,
    4.5: 1044866,
  },
  {
    1: 1274850,
    2: 1220683,
    3: 1137350,
    4: 1067350,
    5: 1009850,
    "revenu brut": 3479000,
    trimf: 1500,
    1.5: 1249850,
    2.5: 1183183,
    3.5: 1105683,
    4.5: 1045266,
  },
  {
    1: 1275250,
    2: 1221083,
    3: 1137750,
    4: 1067750,
    5: 1010250,
    "revenu brut": 3480000,
    trimf: 1500,
    1.5: 1250250,
    2.5: 1183583,
    3.5: 1106083,
    4.5: 1045666,
  },
  {
    1: 1275650,
    2: 1221483,
    3: 1138150,
    4: 1068150,
    5: 1010650,
    "revenu brut": 3481_000,
    trimf: 1500,
    1.5: 1250650,
    2.5: 1183983,
    3.5: 1106483,
    4.5: 1046066,
  },
  {
    1: 1276050,
    2: 1221883,
    3: 1138550,
    4: 1068550,
    5: 1011050,
    "revenu brut": 3482000,
    trimf: 1500,
    1.5: 1251050,
    2.5: 1184383,
    3.5: 1106883,
    4.5: 1046466,
  },
  {
    1: 1276450,
    2: 1222283,
    3: 1138950,
    4: 1068950,
    5: 1011450,
    "revenu brut": 3483000,
    trimf: 1500,
    1.5: 1251450,
    2.5: 1184783,
    3.5: 1107283,
    4.5: 1046866,
  },
  {
    1: 1276850,
    2: 1222683,
    3: 1139350,
    4: 1069350,
    5: 1011850,
    "revenu brut": 3484000,
    trimf: 1500,
    1.5: 1251850,
    2.5: 1185183,
    3.5: 1107683,
    4.5: 1047266,
  },
  {
    1: 1277250,
    2: 1223083,
    3: 1139750,
    4: 1069750,
    5: 1012250,
    "revenu brut": 3485000,
    trimf: 1500,
    1.5: 1252250,
    2.5: 1185583,
    3.5: 1108083,
    4.5: 1047666,
  },
  {
    1: 1277650,
    2: 1223483,
    3: 1140150,
    4: 1070150,
    5: 1012650,
    "revenu brut": 3486000,
    trimf: 1500,
    1.5: 1252650,
    2.5: 1185983,
    3.5: 1108483,
    4.5: 1048066,
  },
  {
    1: 1278050,
    2: 1223883,
    3: 1140550,
    4: 1070550,
    5: 1013050,
    "revenu brut": 3487000,
    trimf: 1500,
    1.5: 1253050,
    2.5: 1186383,
    3.5: 1108883,
    4.5: 1048466,
  },
  {
    1: 1278450,
    2: 1224283,
    3: 1140950,
    4: 1070950,
    5: 1013450,
    "revenu brut": 3488000,
    trimf: 1500,
    1.5: 1253450,
    2.5: 1186783,
    3.5: 1109283,
    4.5: 1048866,
  },
  {
    1: 1278850,
    2: 1224683,
    3: 1141350,
    4: 1071350,
    5: 1013850,
    "revenu brut": 3489000,
    trimf: 1500,
    1.5: 1253850,
    2.5: 1187183,
    3.5: 1109683,
    4.5: 1049266,
  },
  {
    1: 1279250,
    2: 1225083,
    3: 1141750,
    4: 1071750,
    5: 1014250,
    "revenu brut": 3490000,
    trimf: 1500,
    1.5: 1254250,
    2.5: 1187583,
    3.5: 1110083,
    4.5: 1049666,
  },
  {
    1: 1279650,
    2: 1225483,
    3: 1142150,
    4: 1072150,
    5: 1014650,
    "revenu brut": 3491_000,
    trimf: 1500,
    1.5: 1254650,
    2.5: 1187983,
    3.5: 1110483,
    4.5: 1050066,
  },
  {
    1: 1280050,
    2: 1225883,
    3: 1142550,
    4: 1072550,
    5: 1015050,
    "revenu brut": 3492000,
    trimf: 1500,
    1.5: 1255050,
    2.5: 1188383,
    3.5: 1110883,
    4.5: 1050466,
  },
  {
    1: 1280450,
    2: 1226283,
    3: 1142950,
    4: 1072950,
    5: 1015450,
    "revenu brut": 3493000,
    trimf: 1500,
    1.5: 1255450,
    2.5: 1188783,
    3.5: 1111283,
    4.5: 1050866,
  },
  {
    1: 1280850,
    2: 1226683,
    3: 1143350,
    4: 1073350,
    5: 1015850,
    "revenu brut": 3494000,
    trimf: 1500,
    1.5: 1255850,
    2.5: 1189183,
    3.5: 1111683,
    4.5: 1051266,
  },
  {
    1: 1281250,
    2: 1227083,
    3: 1143750,
    4: 1073750,
    5: 1016250,
    "revenu brut": 3495000,
    trimf: 1500,
    1.5: 1256250,
    2.5: 1189583,
    3.5: 1112083,
    4.5: 1051666,
  },
  {
    1: 1281650,
    2: 1227483,
    3: 1144150,
    4: 1074150,
    5: 1016650,
    "revenu brut": 3496000,
    trimf: 1500,
    1.5: 1256650,
    2.5: 1189983,
    3.5: 1112483,
    4.5: 1052066,
  },
  {
    1: 1282050,
    2: 1227883,
    3: 1144550,
    4: 1074550,
    5: 1017050,
    "revenu brut": 3497000,
    trimf: 1500,
    1.5: 1257050,
    2.5: 1190383,
    3.5: 1112883,
    4.5: 1052466,
  },
  {
    1: 1282450,
    2: 1228283,
    3: 1144950,
    4: 1074950,
    5: 1017450,
    "revenu brut": 3498000,
    trimf: 1500,
    1.5: 1257450,
    2.5: 1190783,
    3.5: 1113283,
    4.5: 1052866,
  },
  {
    1: 1282850,
    2: 1228683,
    3: 1145350,
    4: 1075350,
    5: 1017850,
    "revenu brut": 3499000,
    trimf: 1500,
    1.5: 1257850,
    2.5: 1191183,
    3.5: 1113683,
    4.5: 1053266,
  },
  {
    1: 1283250,
    2: 1229083,
    3: 1145750,
    4: 1075750,
    5: 1018250,
    "revenu brut": 3500000,
    trimf: 1500,
    1.5: 1258250,
    2.5: 1191583,
    3.5: 1114083,
    4.5: 1053666,
  },
  {
    1: 1283650,
    2: 1229483,
    3: 1146150,
    4: 1076150,
    5: 1018650,
    "revenu brut": 3501_000,
    trimf: 1500,
    1.5: 1258650,
    2.5: 1191983,
    3.5: 1114483,
    4.5: 1054066,
  },
  {
    1: 1284050,
    2: 1229883,
    3: 1146550,
    4: 1076550,
    5: 1019050,
    "revenu brut": 3502000,
    trimf: 1500,
    1.5: 1259050,
    2.5: 1192383,
    3.5: 1114883,
    4.5: 1054466,
  },
  {
    1: 1284450,
    2: 1230283,
    3: 1146950,
    4: 1076950,
    5: 1019450,
    "revenu brut": 3503000,
    trimf: 1500,
    1.5: 1259450,
    2.5: 1192783,
    3.5: 1115283,
    4.5: 1054866,
  },
  {
    1: 1284850,
    2: 1230683,
    3: 1147350,
    4: 1077350,
    5: 1019850,
    "revenu brut": 3504000,
    trimf: 1500,
    1.5: 1259850,
    2.5: 1193183,
    3.5: 1115683,
    4.5: 1055266,
  },
  {
    1: 1285250,
    2: 1231083,
    3: 1147750,
    4: 1077750,
    5: 1020250,
    "revenu brut": 3505000,
    trimf: 1500,
    1.5: 1260250,
    2.5: 1193583,
    3.5: 1116083,
    4.5: 1055666,
  },
  {
    1: 1285650,
    2: 1231483,
    3: 1148150,
    4: 1078150,
    5: 1020650,
    "revenu brut": 3506000,
    trimf: 1500,
    1.5: 1260650,
    2.5: 1193983,
    3.5: 1116483,
    4.5: 1056066,
  },
  {
    1: 1286050,
    2: 1231883,
    3: 1148550,
    4: 1078550,
    5: 1021050,
    "revenu brut": 3507000,
    trimf: 1500,
    1.5: 1261050,
    2.5: 1194383,
    3.5: 1116883,
    4.5: 1056466,
  },
  {
    1: 1286450,
    2: 1232283,
    3: 1148950,
    4: 1078950,
    5: 1021450,
    "revenu brut": 3508000,
    trimf: 1500,
    1.5: 1261450,
    2.5: 1194783,
    3.5: 1117283,
    4.5: 1056866,
  },
  {
    1: 1286850,
    2: 1232683,
    3: 1149350,
    4: 1079350,
    5: 1021850,
    "revenu brut": 3509000,
    trimf: 1500,
    1.5: 1261850,
    2.5: 1195183,
    3.5: 1117683,
    4.5: 1057266,
  },
  {
    1: 1287250,
    2: 1233083,
    3: 1149750,
    4: 1079750,
    5: 1022250,
    "revenu brut": 351_0000,
    trimf: 1500,
    1.5: 1262250,
    2.5: 1195583,
    3.5: 1118083,
    4.5: 1057666,
  },
  {
    1: 1287650,
    2: 1233483,
    3: 1150150,
    4: 1080150,
    5: 1022650,
    "revenu brut": 3511_000,
    trimf: 1500,
    1.5: 1262650,
    2.5: 1195983,
    3.5: 1118483,
    4.5: 1058066,
  },
  {
    1: 1288050,
    2: 1233883,
    3: 1150550,
    4: 1080550,
    5: 1023050,
    "revenu brut": 3512000,
    trimf: 1500,
    1.5: 1263050,
    2.5: 1196383,
    3.5: 1118883,
    4.5: 1058466,
  },
  {
    1: 1288450,
    2: 1234283,
    3: 1150950,
    4: 1080950,
    5: 1023450,
    "revenu brut": 3513000,
    trimf: 1500,
    1.5: 1263450,
    2.5: 1196783,
    3.5: 1119283,
    4.5: 1058866,
  },
  {
    1: 1288850,
    2: 1234683,
    3: 1151350,
    4: 1081350,
    5: 1023850,
    "revenu brut": 3514000,
    trimf: 1500,
    1.5: 1263850,
    2.5: 1197183,
    3.5: 1119683,
    4.5: 1059266,
  },
  {
    1: 1289250,
    2: 1235083,
    3: 1151750,
    4: 1081750,
    5: 1024250,
    "revenu brut": 3515000,
    trimf: 1500,
    1.5: 1264250,
    2.5: 1197583,
    3.5: 1120083,
    4.5: 1059666,
  },
  {
    1: 1289650,
    2: 1235483,
    3: 1152150,
    4: 1082150,
    5: 1024650,
    "revenu brut": 3516000,
    trimf: 1500,
    1.5: 1264650,
    2.5: 1197983,
    3.5: 1120483,
    4.5: 1060066,
  },
  {
    1: 1290050,
    2: 1235883,
    3: 1152550,
    4: 1082550,
    5: 1025050,
    "revenu brut": 3517000,
    trimf: 1500,
    1.5: 1265050,
    2.5: 1198383,
    3.5: 1120883,
    4.5: 1060466,
  },
  {
    1: 1290450,
    2: 1236283,
    3: 1152950,
    4: 1082950,
    5: 1025450,
    "revenu brut": 3518000,
    trimf: 1500,
    1.5: 1265450,
    2.5: 1198783,
    3.5: 1121283,
    4.5: 1060866,
  },
  {
    1: 1290850,
    2: 1236683,
    3: 1153350,
    4: 1083350,
    5: 1025850,
    "revenu brut": 3519000,
    trimf: 1500,
    1.5: 1265850,
    2.5: 1199183,
    3.5: 1121683,
    4.5: 1061266,
  },
  {
    1: 1291250,
    2: 1237083,
    3: 1153750,
    4: 1083750,
    5: 1026250,
    "revenu brut": 3520000,
    trimf: 1500,
    1.5: 1266250,
    2.5: 1199583,
    3.5: 1122083,
    4.5: 1061666,
  },
  {
    1: 1291650,
    2: 1237483,
    3: 1154150,
    4: 1084150,
    5: 1026650,
    "revenu brut": 3521_000,
    trimf: 1500,
    1.5: 1266650,
    2.5: 1199983,
    3.5: 1122483,
    4.5: 1062066,
  },
  {
    1: 1292050,
    2: 1237883,
    3: 1154550,
    4: 1084550,
    5: 1027050,
    "revenu brut": 3522000,
    trimf: 1500,
    1.5: 1267050,
    2.5: 1200383,
    3.5: 1122883,
    4.5: 1062466,
  },
  {
    1: 1292450,
    2: 1238283,
    3: 1154950,
    4: 1084950,
    5: 1027450,
    "revenu brut": 3523000,
    trimf: 1500,
    1.5: 1267450,
    2.5: 1200783,
    3.5: 1123283,
    4.5: 1062866,
  },
  {
    1: 1292850,
    2: 1238683,
    3: 1155350,
    4: 1085350,
    5: 1027850,
    "revenu brut": 3524000,
    trimf: 1500,
    1.5: 1267850,
    2.5: 1201183,
    3.5: 1123683,
    4.5: 1063266,
  },
  {
    1: 1293250,
    2: 1239083,
    3: 1155750,
    4: 1085750,
    5: 1028250,
    "revenu brut": 3525000,
    trimf: 1500,
    1.5: 1268250,
    2.5: 1201583,
    3.5: 1124083,
    4.5: 1063666,
  },
  {
    1: 1293650,
    2: 1239483,
    3: 1156150,
    4: 1086150,
    5: 1028650,
    "revenu brut": 3526000,
    trimf: 1500,
    1.5: 1268650,
    2.5: 1201983,
    3.5: 1124483,
    4.5: 1064066,
  },
  {
    1: 1294050,
    2: 1239883,
    3: 1156550,
    4: 1086550,
    5: 1029050,
    "revenu brut": 3527000,
    trimf: 1500,
    1.5: 1269050,
    2.5: 1202383,
    3.5: 1124883,
    4.5: 1064466,
  },
  {
    1: 1294450,
    2: 1240283,
    3: 1156950,
    4: 1086950,
    5: 1029450,
    "revenu brut": 3528000,
    trimf: 1500,
    1.5: 1269450,
    2.5: 1202783,
    3.5: 1125283,
    4.5: 1064866,
  },
  {
    1: 1294850,
    2: 1240683,
    3: 1157350,
    4: 1087350,
    5: 1029850,
    "revenu brut": 3529000,
    trimf: 1500,
    1.5: 1269850,
    2.5: 1203183,
    3.5: 1125683,
    4.5: 1065266,
  },
  {
    1: 1295250,
    2: 1241083,
    3: 1157750,
    4: 1087750,
    5: 1030250,
    "revenu brut": 3530000,
    trimf: 1500,
    1.5: 1270250,
    2.5: 1203583,
    3.5: 1126083,
    4.5: 1065666,
  },
  {
    1: 1295650,
    2: 1241483,
    3: 1158150,
    4: 1088150,
    5: 1030650,
    "revenu brut": 3531_000,
    trimf: 1500,
    1.5: 1270650,
    2.5: 1203983,
    3.5: 1126483,
    4.5: 1066066,
  },
  {
    1: 1296050,
    2: 1241883,
    3: 1158550,
    4: 1088550,
    5: 1031050,
    "revenu brut": 3532000,
    trimf: 1500,
    1.5: 1271050,
    2.5: 1204383,
    3.5: 1126883,
    4.5: 1066466,
  },
  {
    1: 1296450,
    2: 1242283,
    3: 1158950,
    4: 1088950,
    5: 1031450,
    "revenu brut": 3533000,
    trimf: 1500,
    1.5: 1271450,
    2.5: 1204783,
    3.5: 1127283,
    4.5: 1066866,
  },
  {
    1: 1296850,
    2: 1242683,
    3: 1159350,
    4: 1089350,
    5: 1031850,
    "revenu brut": 3534000,
    trimf: 1500,
    1.5: 1271850,
    2.5: 1205183,
    3.5: 1127683,
    4.5: 1067266,
  },
  {
    1: 1297250,
    2: 1243083,
    3: 1159750,
    4: 1089750,
    5: 1032250,
    "revenu brut": 3535000,
    trimf: 1500,
    1.5: 1272250,
    2.5: 1205583,
    3.5: 1128083,
    4.5: 1067666,
  },
  {
    1: 1297650,
    2: 1243483,
    3: 1160150,
    4: 1090150,
    5: 1032650,
    "revenu brut": 3536000,
    trimf: 1500,
    1.5: 1272650,
    2.5: 1205983,
    3.5: 1128483,
    4.5: 1068066,
  },
  {
    1: 1298050,
    2: 1243883,
    3: 1160550,
    4: 1090550,
    5: 1033050,
    "revenu brut": 3537000,
    trimf: 1500,
    1.5: 1273050,
    2.5: 1206383,
    3.5: 1128883,
    4.5: 1068466,
  },
  {
    1: 1298450,
    2: 1244283,
    3: 1160950,
    4: 1090950,
    5: 1033450,
    "revenu brut": 3538000,
    trimf: 1500,
    1.5: 1273450,
    2.5: 1206783,
    3.5: 1129283,
    4.5: 1068866,
  },
  {
    1: 1298850,
    2: 1244683,
    3: 1161350,
    4: 1091350,
    5: 1033850,
    "revenu brut": 3539000,
    trimf: 1500,
    1.5: 1273850,
    2.5: 1207183,
    3.5: 1129683,
    4.5: 1069266,
  },
  {
    1: 1299250,
    2: 1245083,
    3: 1161750,
    4: 1091750,
    5: 1034250,
    "revenu brut": 3540000,
    trimf: 1500,
    1.5: 1274250,
    2.5: 1207583,
    3.5: 1130083,
    4.5: 1069666,
  },
  {
    1: 1299650,
    2: 1245483,
    3: 1162150,
    4: 1092150,
    5: 1034650,
    "revenu brut": 3541_000,
    trimf: 1500,
    1.5: 1274650,
    2.5: 1207983,
    3.5: 1130483,
    4.5: 1070066,
  },
  {
    1: 1300050,
    2: 1245883,
    3: 1162550,
    4: 1092550,
    5: 1035050,
    "revenu brut": 3542000,
    trimf: 1500,
    1.5: 1275050,
    2.5: 1208383,
    3.5: 1130883,
    4.5: 1070466,
  },
  {
    1: 1300450,
    2: 1246283,
    3: 1162950,
    4: 1092950,
    5: 1035450,
    "revenu brut": 3543000,
    trimf: 1500,
    1.5: 1275450,
    2.5: 1208783,
    3.5: 1131283,
    4.5: 1070866,
  },
  {
    1: 1300850,
    2: 1246683,
    3: 1163350,
    4: 1093350,
    5: 1035850,
    "revenu brut": 3544000,
    trimf: 1500,
    1.5: 1275850,
    2.5: 1209183,
    3.5: 1131683,
    4.5: 1071266,
  },
  {
    1: 1301250,
    2: 1247083,
    3: 1163750,
    4: 1093750,
    5: 1036250,
    "revenu brut": 3545000,
    trimf: 1500,
    1.5: 1276250,
    2.5: 1209583,
    3.5: 1132083,
    4.5: 1071666,
  },
  {
    1: 1301650,
    2: 1247483,
    3: 1164150,
    4: 1094150,
    5: 1036650,
    "revenu brut": 3546000,
    trimf: 1500,
    1.5: 1276650,
    2.5: 1209983,
    3.5: 1132483,
    4.5: 1072066,
  },
  {
    1: 1302050,
    2: 1247883,
    3: 1164550,
    4: 1094550,
    5: 1037050,
    "revenu brut": 3547000,
    trimf: 1500,
    1.5: 1277050,
    2.5: 1210383,
    3.5: 1132883,
    4.5: 1072466,
  },
  {
    1: 1302450,
    2: 1248283,
    3: 1164950,
    4: 1094950,
    5: 1037450,
    "revenu brut": 3548000,
    trimf: 1500,
    1.5: 1277450,
    2.5: 1210783,
    3.5: 1133283,
    4.5: 1072866,
  },
  {
    1: 1302850,
    2: 1248683,
    3: 1165350,
    4: 1095350,
    5: 1037850,
    "revenu brut": 3549000,
    trimf: 1500,
    1.5: 1277850,
    2.5: 1211183,
    3.5: 1133683,
    4.5: 1073266,
  },
  {
    1: 1303250,
    2: 1249083,
    3: 1165750,
    4: 1095750,
    5: 1038250,
    "revenu brut": 3550000,
    trimf: 1500,
    1.5: 1278250,
    2.5: 1211583,
    3.5: 1134083,
    4.5: 1073666,
  },
  {
    1: 1303650,
    2: 1249483,
    3: 1166150,
    4: 1096150,
    5: 1038650,
    "revenu brut": 3551_000,
    trimf: 1500,
    1.5: 1278650,
    2.5: 1211983,
    3.5: 1134483,
    4.5: 1074066,
  },
  {
    1: 1304050,
    2: 1249883,
    3: 1166550,
    4: 1096550,
    5: 1039050,
    "revenu brut": 3552000,
    trimf: 1500,
    1.5: 1279050,
    2.5: 1212383,
    3.5: 1134883,
    4.5: 1074466,
  },
  {
    1: 1304450,
    2: 1250283,
    3: 1166950,
    4: 1096950,
    5: 1039450,
    "revenu brut": 3553000,
    trimf: 1500,
    1.5: 1279450,
    2.5: 1212783,
    3.5: 1135283,
    4.5: 1074866,
  },
  {
    1: 1304850,
    2: 1250683,
    3: 1167350,
    4: 1097350,
    5: 1039850,
    "revenu brut": 3554000,
    trimf: 1500,
    1.5: 1279850,
    2.5: 1213183,
    3.5: 1135683,
    4.5: 1075266,
  },
  {
    1: 1305250,
    2: 1251083,
    3: 1167750,
    4: 1097750,
    5: 1040250,
    "revenu brut": 3555000,
    trimf: 1500,
    1.5: 1280250,
    2.5: 1213583,
    3.5: 1136083,
    4.5: 1075666,
  },
  {
    1: 1305650,
    2: 1251483,
    3: 1168150,
    4: 1098150,
    5: 1040650,
    "revenu brut": 3556000,
    trimf: 1500,
    1.5: 1280650,
    2.5: 1213983,
    3.5: 1136483,
    4.5: 1076066,
  },
  {
    1: 1306050,
    2: 1251883,
    3: 1168550,
    4: 1098550,
    5: 1041050,
    "revenu brut": 3557000,
    trimf: 1500,
    1.5: 1281050,
    2.5: 1214383,
    3.5: 1136883,
    4.5: 1076466,
  },
  {
    1: 1306450,
    2: 1252283,
    3: 1168950,
    4: 1098950,
    5: 1041450,
    "revenu brut": 3558000,
    trimf: 1500,
    1.5: 1281450,
    2.5: 1214783,
    3.5: 1137283,
    4.5: 1076866,
  },
  {
    1: 1306850,
    2: 1252683,
    3: 1169350,
    4: 1099350,
    5: 1041850,
    "revenu brut": 3559000,
    trimf: 1500,
    1.5: 1281850,
    2.5: 1215183,
    3.5: 1137683,
    4.5: 1077266,
  },
  {
    1: 1307250,
    2: 1253083,
    3: 1169750,
    4: 1099750,
    5: 1042250,
    "revenu brut": 3560000,
    trimf: 1500,
    1.5: 1282250,
    2.5: 1215583,
    3.5: 1138083,
    4.5: 1077666,
  },
  {
    1: 1307650,
    2: 1253483,
    3: 1170150,
    4: 1100150,
    5: 1042650,
    "revenu brut": 3561_000,
    trimf: 1500,
    1.5: 1282650,
    2.5: 1215983,
    3.5: 1138483,
    4.5: 1078066,
  },
  {
    1: 1308050,
    2: 1253883,
    3: 1170550,
    4: 1100550,
    5: 1043050,
    "revenu brut": 3562000,
    trimf: 1500,
    1.5: 1283050,
    2.5: 1216383,
    3.5: 1138883,
    4.5: 1078466,
  },
  {
    1: 1308450,
    2: 1254283,
    3: 1170950,
    4: 1100950,
    5: 1043450,
    "revenu brut": 3563000,
    trimf: 1500,
    1.5: 1283450,
    2.5: 1216783,
    3.5: 1139283,
    4.5: 1078866,
  },
  {
    1: 1308850,
    2: 1254683,
    3: 1171350,
    4: 1101350,
    5: 1043850,
    "revenu brut": 3564000,
    trimf: 1500,
    1.5: 1283850,
    2.5: 1217183,
    3.5: 1139683,
    4.5: 1079266,
  },
  {
    1: 1309250,
    2: 1255083,
    3: 1171750,
    4: 1101750,
    5: 1044250,
    "revenu brut": 3565000,
    trimf: 1500,
    1.5: 1284250,
    2.5: 1217583,
    3.5: 1140083,
    4.5: 1079666,
  },
  {
    1: 1309650,
    2: 1255483,
    3: 1172150,
    4: 1102150,
    5: 1044650,
    "revenu brut": 3566000,
    trimf: 1500,
    1.5: 1284650,
    2.5: 1217983,
    3.5: 1140483,
    4.5: 1080066,
  },
  {
    1: 1310050,
    2: 1255883,
    3: 1172550,
    4: 1102550,
    5: 1045050,
    "revenu brut": 3567000,
    trimf: 1500,
    1.5: 1285050,
    2.5: 1218383,
    3.5: 1140883,
    4.5: 1080466,
  },
  {
    1: 1310450,
    2: 1256283,
    3: 1172950,
    4: 1102950,
    5: 1045450,
    "revenu brut": 3568000,
    trimf: 1500,
    1.5: 1285450,
    2.5: 1218783,
    3.5: 1141283,
    4.5: 1080866,
  },
  {
    1: 1310850,
    2: 1256683,
    3: 1173350,
    4: 1103350,
    5: 1045850,
    "revenu brut": 3569000,
    trimf: 1500,
    1.5: 1285850,
    2.5: 1219183,
    3.5: 1141683,
    4.5: 1081266,
  },
  {
    1: 1311250,
    2: 1257083,
    3: 1173750,
    4: 1103750,
    5: 1046250,
    "revenu brut": 3570000,
    trimf: 1500,
    1.5: 1286250,
    2.5: 1219583,
    3.5: 1142083,
    4.5: 1081666,
  },
  {
    1: 1311650,
    2: 1257483,
    3: 1174150,
    4: 1104150,
    5: 1046650,
    "revenu brut": 3571_000,
    trimf: 1500,
    1.5: 1286650,
    2.5: 1219983,
    3.5: 1142483,
    4.5: 1082066,
  },
  {
    1: 1312050,
    2: 1257883,
    3: 1174550,
    4: 1104550,
    5: 1047050,
    "revenu brut": 3572000,
    trimf: 1500,
    1.5: 1287050,
    2.5: 1220383,
    3.5: 1142883,
    4.5: 1082466,
  },
  {
    1: 1312450,
    2: 1258283,
    3: 1174950,
    4: 1104950,
    5: 1047450,
    "revenu brut": 3573000,
    trimf: 1500,
    1.5: 1287450,
    2.5: 1220783,
    3.5: 1143283,
    4.5: 1082866,
  },
  {
    1: 1312850,
    2: 1258683,
    3: 1175350,
    4: 1105350,
    5: 1047850,
    "revenu brut": 3574000,
    trimf: 1500,
    1.5: 1287850,
    2.5: 1221183,
    3.5: 1143683,
    4.5: 1083266,
  },
  {
    1: 1313250,
    2: 1259083,
    3: 1175750,
    4: 1105750,
    5: 1048250,
    "revenu brut": 3575000,
    trimf: 1500,
    1.5: 1288250,
    2.5: 1221583,
    3.5: 1144083,
    4.5: 1083666,
  },
  {
    1: 1313650,
    2: 1259483,
    3: 1176150,
    4: 1106150,
    5: 1048650,
    "revenu brut": 3576000,
    trimf: 1500,
    1.5: 1288650,
    2.5: 1221983,
    3.5: 1144483,
    4.5: 1084066,
  },
  {
    1: 1314050,
    2: 1259883,
    3: 1176550,
    4: 1106550,
    5: 1049050,
    "revenu brut": 3577000,
    trimf: 1500,
    1.5: 1289050,
    2.5: 1222383,
    3.5: 1144883,
    4.5: 1084466,
  },
  {
    1: 1314450,
    2: 1260283,
    3: 1176950,
    4: 1106950,
    5: 1049450,
    "revenu brut": 3578000,
    trimf: 1500,
    1.5: 1289450,
    2.5: 1222783,
    3.5: 1145283,
    4.5: 1084866,
  },
  {
    1: 1314850,
    2: 1260683,
    3: 1177350,
    4: 1107350,
    5: 1049850,
    "revenu brut": 3579000,
    trimf: 1500,
    1.5: 1289850,
    2.5: 1223183,
    3.5: 1145683,
    4.5: 1085266,
  },
  {
    1: 1315250,
    2: 1261083,
    3: 1177750,
    4: 1107750,
    5: 1050250,
    "revenu brut": 3580000,
    trimf: 1500,
    1.5: 1290250,
    2.5: 1223583,
    3.5: 1146083,
    4.5: 1085666,
  },
  {
    1: 1315650,
    2: 1261483,
    3: 1178150,
    4: 1108150,
    5: 1050650,
    "revenu brut": 3581_000,
    trimf: 1500,
    1.5: 1290650,
    2.5: 1223983,
    3.5: 1146483,
    4.5: 1086066,
  },
  {
    1: 1316050,
    2: 1261883,
    3: 1178550,
    4: 1108550,
    5: 1051050,
    "revenu brut": 3582000,
    trimf: 1500,
    1.5: 1291050,
    2.5: 1224383,
    3.5: 1146883,
    4.5: 1086466,
  },
  {
    1: 1316450,
    2: 1262283,
    3: 1178950,
    4: 1108950,
    5: 1051450,
    "revenu brut": 3583000,
    trimf: 1500,
    1.5: 1291450,
    2.5: 1224783,
    3.5: 1147283,
    4.5: 1086866,
  },
  {
    1: 1316850,
    2: 1262683,
    3: 1179350,
    4: 1109350,
    5: 1051850,
    "revenu brut": 3584000,
    trimf: 1500,
    1.5: 1291850,
    2.5: 1225183,
    3.5: 1147683,
    4.5: 1087266,
  },
  {
    1: 1317250,
    2: 1263083,
    3: 1179750,
    4: 1109750,
    5: 1052250,
    "revenu brut": 3585000,
    trimf: 1500,
    1.5: 1292250,
    2.5: 1225583,
    3.5: 1148083,
    4.5: 1087666,
  },
  {
    1: 1317650,
    2: 1263483,
    3: 1180150,
    4: 1110150,
    5: 1052650,
    "revenu brut": 3586000,
    trimf: 1500,
    1.5: 1292650,
    2.5: 1225983,
    3.5: 1148483,
    4.5: 1088066,
  },
  {
    1: 1318050,
    2: 1263883,
    3: 1180550,
    4: 1110550,
    5: 1053050,
    "revenu brut": 3587000,
    trimf: 1500,
    1.5: 1293050,
    2.5: 1226383,
    3.5: 1148883,
    4.5: 1088466,
  },
  {
    1: 1318450,
    2: 1264283,
    3: 1180950,
    4: 1110950,
    5: 1053450,
    "revenu brut": 3588000,
    trimf: 1500,
    1.5: 1293450,
    2.5: 1226783,
    3.5: 1149283,
    4.5: 1088866,
  },
  {
    1: 1318850,
    2: 1264683,
    3: 1181350,
    4: 1111350,
    5: 1053850,
    "revenu brut": 3589000,
    trimf: 1500,
    1.5: 1293850,
    2.5: 1227183,
    3.5: 1149683,
    4.5: 1089266,
  },
  {
    1: 1319250,
    2: 1265083,
    3: 1181750,
    4: 1111750,
    5: 1054250,
    "revenu brut": 3590000,
    trimf: 1500,
    1.5: 1294250,
    2.5: 1227583,
    3.5: 1150083,
    4.5: 1089666,
  },
  {
    1: 1319650,
    2: 1265483,
    3: 1182150,
    4: 1112150,
    5: 1054650,
    "revenu brut": 3591_000,
    trimf: 1500,
    1.5: 1294650,
    2.5: 1227983,
    3.5: 1150483,
    4.5: 1090066,
  },
  {
    1: 1320050,
    2: 1265883,
    3: 1182550,
    4: 1112550,
    5: 1055050,
    "revenu brut": 3592000,
    trimf: 1500,
    1.5: 1295050,
    2.5: 1228383,
    3.5: 1150883,
    4.5: 1090466,
  },
  {
    1: 1320450,
    2: 1266283,
    3: 1182950,
    4: 1112950,
    5: 1055450,
    "revenu brut": 3593000,
    trimf: 1500,
    1.5: 1295450,
    2.5: 1228783,
    3.5: 1151283,
    4.5: 1090866,
  },
  {
    1: 1320850,
    2: 1266683,
    3: 1183350,
    4: 1113350,
    5: 1055850,
    "revenu brut": 3594000,
    trimf: 1500,
    1.5: 1295850,
    2.5: 1229183,
    3.5: 1151683,
    4.5: 1091266,
  },
  {
    1: 1321250,
    2: 1267083,
    3: 1183750,
    4: 1113750,
    5: 1056250,
    "revenu brut": 3595000,
    trimf: 1500,
    1.5: 1296250,
    2.5: 1229583,
    3.5: 1152083,
    4.5: 1091666,
  },
  {
    1: 1321650,
    2: 1267483,
    3: 1184150,
    4: 1114150,
    5: 1056650,
    "revenu brut": 3596000,
    trimf: 1500,
    1.5: 1296650,
    2.5: 1229983,
    3.5: 1152483,
    4.5: 1092066,
  },
  {
    1: 1322050,
    2: 1267883,
    3: 1184550,
    4: 1114550,
    5: 1057050,
    "revenu brut": 3597000,
    trimf: 1500,
    1.5: 1297050,
    2.5: 1230383,
    3.5: 1152883,
    4.5: 1092466,
  },
  {
    1: 1322450,
    2: 1268283,
    3: 1184950,
    4: 1114950,
    5: 1057450,
    "revenu brut": 3598000,
    trimf: 1500,
    1.5: 1297450,
    2.5: 1230783,
    3.5: 1153283,
    4.5: 1092866,
  },
  {
    1: 1322850,
    2: 1268683,
    3: 1185350,
    4: 1115350,
    5: 1057850,
    "revenu brut": 3599000,
    trimf: 1500,
    1.5: 1297850,
    2.5: 1231183,
    3.5: 1153683,
    4.5: 1093266,
  },
  {
    1: 1323250,
    2: 1269083,
    3: 1185750,
    4: 1115750,
    5: 1058250,
    "revenu brut": 3600000,
    trimf: 1500,
    1.5: 1298250,
    2.5: 1231583,
    3.5: 1154083,
    4.5: 1093666,
  },
  {
    1: 1323650,
    2: 1269483,
    3: 1186150,
    4: 1116150,
    5: 1058650,
    "revenu brut": 3601_000,
    trimf: 1500,
    1.5: 1298650,
    2.5: 1231983,
    3.5: 1154483,
    4.5: 1094066,
  },
  {
    1: 1324050,
    2: 1269883,
    3: 1186550,
    4: 1116550,
    5: 1059050,
    "revenu brut": 3602000,
    trimf: 1500,
    1.5: 1299050,
    2.5: 1232383,
    3.5: 1154883,
    4.5: 1094466,
  },
  {
    1: 1324450,
    2: 1270283,
    3: 1186950,
    4: 1116950,
    5: 1059450,
    "revenu brut": 3603000,
    trimf: 1500,
    1.5: 1299450,
    2.5: 1232783,
    3.5: 1155283,
    4.5: 1094866,
  },
  {
    1: 1324850,
    2: 1270683,
    3: 1187350,
    4: 1117350,
    5: 1059850,
    "revenu brut": 3604000,
    trimf: 1500,
    1.5: 1299850,
    2.5: 1233183,
    3.5: 1155683,
    4.5: 1095266,
  },
  {
    1: 1325250,
    2: 1271083,
    3: 1187750,
    4: 1117750,
    5: 1060250,
    "revenu brut": 3605000,
    trimf: 1500,
    1.5: 1300250,
    2.5: 1233583,
    3.5: 1156083,
    4.5: 1095666,
  },
  {
    1: 1325650,
    2: 1271483,
    3: 1188150,
    4: 1118150,
    5: 1060650,
    "revenu brut": 3606000,
    trimf: 1500,
    1.5: 1300650,
    2.5: 1233983,
    3.5: 1156483,
    4.5: 1096066,
  },
  {
    1: 1326050,
    2: 1271883,
    3: 1188550,
    4: 1118550,
    5: 1061050,
    "revenu brut": 3607000,
    trimf: 1500,
    1.5: 1301050,
    2.5: 1234383,
    3.5: 1156883,
    4.5: 1096466,
  },
  {
    1: 1326450,
    2: 1272283,
    3: 1188950,
    4: 1118950,
    5: 1061450,
    "revenu brut": 3608000,
    trimf: 1500,
    1.5: 1301450,
    2.5: 1234783,
    3.5: 1157283,
    4.5: 1096866,
  },
  {
    1: 1326850,
    2: 1272683,
    3: 1189350,
    4: 1119350,
    5: 1061850,
    "revenu brut": 3609000,
    trimf: 1500,
    1.5: 1301850,
    2.5: 1235183,
    3.5: 1157683,
    4.5: 1097266,
  },
  {
    1: 1327250,
    2: 1273083,
    3: 1189750,
    4: 1119750,
    5: 1062250,
    "revenu brut": 361_0000,
    trimf: 1500,
    1.5: 1302250,
    2.5: 1235583,
    3.5: 1158083,
    4.5: 1097666,
  },
  {
    1: 1327650,
    2: 1273483,
    3: 1190150,
    4: 1120150,
    5: 1062650,
    "revenu brut": 3611_000,
    trimf: 1500,
    1.5: 1302650,
    2.5: 1235983,
    3.5: 1158483,
    4.5: 1098066,
  },
  {
    1: 1328050,
    2: 1273883,
    3: 1190550,
    4: 1120550,
    5: 1063050,
    "revenu brut": 3612000,
    trimf: 1500,
    1.5: 1303050,
    2.5: 1236383,
    3.5: 1158883,
    4.5: 1098466,
  },
  {
    1: 1328450,
    2: 1274283,
    3: 1190950,
    4: 1120950,
    5: 1063450,
    "revenu brut": 3613000,
    trimf: 1500,
    1.5: 1303450,
    2.5: 1236783,
    3.5: 1159283,
    4.5: 1098866,
  },
  {
    1: 1328850,
    2: 1274683,
    3: 1191350,
    4: 1121350,
    5: 1063850,
    "revenu brut": 3614000,
    trimf: 1500,
    1.5: 1303850,
    2.5: 1237183,
    3.5: 1159683,
    4.5: 1099266,
  },
  {
    1: 1329250,
    2: 1275083,
    3: 1191750,
    4: 1121750,
    5: 1064250,
    "revenu brut": 3615000,
    trimf: 1500,
    1.5: 1304250,
    2.5: 1237583,
    3.5: 1160083,
    4.5: 1099666,
  },
  {
    1: 1329650,
    2: 1275483,
    3: 1192150,
    4: 1122150,
    5: 1064650,
    "revenu brut": 3616000,
    trimf: 1500,
    1.5: 1304650,
    2.5: 1237983,
    3.5: 1160483,
    4.5: 11_00066,
  },
  {
    1: 1330050,
    2: 1275883,
    3: 1192550,
    4: 1122550,
    5: 1065050,
    "revenu brut": 3617000,
    trimf: 1500,
    1.5: 1305050,
    2.5: 1238383,
    3.5: 1160883,
    4.5: 1100466,
  },
  {
    1: 1330450,
    2: 1276283,
    3: 1192950,
    4: 1122950,
    5: 1065450,
    "revenu brut": 3618000,
    trimf: 1500,
    1.5: 1305450,
    2.5: 1238783,
    3.5: 1161283,
    4.5: 1100866,
  },
  {
    1: 1330850,
    2: 1276683,
    3: 1193350,
    4: 1123350,
    5: 1065850,
    "revenu brut": 3619000,
    trimf: 1500,
    1.5: 1305850,
    2.5: 1239183,
    3.5: 1161683,
    4.5: 1101266,
  },
  {
    1: 1331250,
    2: 1277083,
    3: 1193750,
    4: 1123750,
    5: 1066250,
    "revenu brut": 3620000,
    trimf: 1500,
    1.5: 1306250,
    2.5: 1239583,
    3.5: 1162083,
    4.5: 1101666,
  },
  {
    1: 1331650,
    2: 1277483,
    3: 1194150,
    4: 1124150,
    5: 1066650,
    "revenu brut": 3621_000,
    trimf: 1500,
    1.5: 1306650,
    2.5: 1239983,
    3.5: 1162483,
    4.5: 1102066,
  },
  {
    1: 1332050,
    2: 1277883,
    3: 1194550,
    4: 1124550,
    5: 1067050,
    "revenu brut": 3622000,
    trimf: 1500,
    1.5: 1307050,
    2.5: 1240383,
    3.5: 1162883,
    4.5: 1102466,
  },
  {
    1: 1332450,
    2: 1278283,
    3: 1194950,
    4: 1124950,
    5: 1067450,
    "revenu brut": 3623000,
    trimf: 1500,
    1.5: 1307450,
    2.5: 1240783,
    3.5: 1163283,
    4.5: 1102866,
  },
  {
    1: 1332850,
    2: 1278683,
    3: 1195350,
    4: 1125350,
    5: 1067850,
    "revenu brut": 3624000,
    trimf: 1500,
    1.5: 1307850,
    2.5: 1241183,
    3.5: 1163683,
    4.5: 1103266,
  },
  {
    1: 1333250,
    2: 1279083,
    3: 1195750,
    4: 1125750,
    5: 1068250,
    "revenu brut": 3625000,
    trimf: 1500,
    1.5: 1308250,
    2.5: 1241583,
    3.5: 1164083,
    4.5: 1103666,
  },
  {
    1: 1333650,
    2: 1279483,
    3: 1196150,
    4: 1126150,
    5: 1068650,
    "revenu brut": 3626000,
    trimf: 1500,
    1.5: 1308650,
    2.5: 1241983,
    3.5: 1164483,
    4.5: 1104066,
  },
  {
    1: 1334050,
    2: 1279883,
    3: 1196550,
    4: 1126550,
    5: 1069050,
    "revenu brut": 3627000,
    trimf: 1500,
    1.5: 1309050,
    2.5: 1242383,
    3.5: 1164883,
    4.5: 1104466,
  },
  {
    1: 1334450,
    2: 1280283,
    3: 1196950,
    4: 1126950,
    5: 1069450,
    "revenu brut": 3628000,
    trimf: 1500,
    1.5: 1309450,
    2.5: 1242783,
    3.5: 1165283,
    4.5: 1104866,
  },
  {
    1: 1334850,
    2: 1280683,
    3: 1197350,
    4: 1127350,
    5: 1069850,
    "revenu brut": 3629000,
    trimf: 1500,
    1.5: 1309850,
    2.5: 1243183,
    3.5: 1165683,
    4.5: 1105266,
  },
  {
    1: 1335250,
    2: 1281083,
    3: 1197750,
    4: 1127750,
    5: 1070250,
    "revenu brut": 3630000,
    trimf: 1500,
    1.5: 1310250,
    2.5: 1243583,
    3.5: 1166083,
    4.5: 1105666,
  },
  {
    1: 1335650,
    2: 1281483,
    3: 1198150,
    4: 1128150,
    5: 1070650,
    "revenu brut": 3631_000,
    trimf: 1500,
    1.5: 1310650,
    2.5: 1243983,
    3.5: 1166483,
    4.5: 1106066,
  },
  {
    1: 1336050,
    2: 1281883,
    3: 1198550,
    4: 1128550,
    5: 1071050,
    "revenu brut": 3632000,
    trimf: 1500,
    1.5: 1311050,
    2.5: 1244383,
    3.5: 1166883,
    4.5: 1106466,
  },
  {
    1: 1336450,
    2: 1282283,
    3: 1198950,
    4: 1128950,
    5: 1071450,
    "revenu brut": 3633000,
    trimf: 1500,
    1.5: 1311450,
    2.5: 1244783,
    3.5: 1167283,
    4.5: 1106866,
  },
  {
    1: 1336850,
    2: 1282683,
    3: 1199350,
    4: 1129350,
    5: 1071850,
    "revenu brut": 3634000,
    trimf: 1500,
    1.5: 1311850,
    2.5: 1245183,
    3.5: 1167683,
    4.5: 1107266,
  },
  {
    1: 1337250,
    2: 1283083,
    3: 1199750,
    4: 1129750,
    5: 1072250,
    "revenu brut": 3635000,
    trimf: 1500,
    1.5: 1312250,
    2.5: 1245583,
    3.5: 1168083,
    4.5: 1107666,
  },
  {
    1: 1337650,
    2: 1283483,
    3: 1200150,
    4: 1130150,
    5: 1072650,
    "revenu brut": 3636000,
    trimf: 1500,
    1.5: 1312650,
    2.5: 1245983,
    3.5: 1168483,
    4.5: 1108066,
  },
  {
    1: 1338050,
    2: 1283883,
    3: 1200550,
    4: 1130550,
    5: 1073050,
    "revenu brut": 3637000,
    trimf: 1500,
    1.5: 1313050,
    2.5: 1246383,
    3.5: 1168883,
    4.5: 1108466,
  },
  {
    1: 1338450,
    2: 1284283,
    3: 1200950,
    4: 1130950,
    5: 1073450,
    "revenu brut": 3638000,
    trimf: 1500,
    1.5: 1313450,
    2.5: 1246783,
    3.5: 1169283,
    4.5: 1108866,
  },
  {
    1: 1338850,
    2: 1284683,
    3: 1201350,
    4: 1131350,
    5: 1073850,
    "revenu brut": 3639000,
    trimf: 1500,
    1.5: 1313850,
    2.5: 1247183,
    3.5: 1169683,
    4.5: 1109266,
  },
  {
    1: 1339250,
    2: 1285083,
    3: 1201750,
    4: 1131750,
    5: 1074250,
    "revenu brut": 3640000,
    trimf: 1500,
    1.5: 1314250,
    2.5: 1247583,
    3.5: 1170083,
    4.5: 1109666,
  },
  {
    1: 1339650,
    2: 1285483,
    3: 1202150,
    4: 1132150,
    5: 1074650,
    "revenu brut": 3641_000,
    trimf: 1500,
    1.5: 1314650,
    2.5: 1247983,
    3.5: 1170483,
    4.5: 1110066,
  },
  {
    1: 1340050,
    2: 1285883,
    3: 1202550,
    4: 1132550,
    5: 1075050,
    "revenu brut": 3642000,
    trimf: 1500,
    1.5: 1315050,
    2.5: 1248383,
    3.5: 1170883,
    4.5: 1110466,
  },
  {
    1: 1340450,
    2: 1286283,
    3: 1202950,
    4: 1132950,
    5: 1075450,
    "revenu brut": 3643000,
    trimf: 1500,
    1.5: 1315450,
    2.5: 1248783,
    3.5: 1171283,
    4.5: 1110866,
  },
  {
    1: 1340850,
    2: 1286683,
    3: 1203350,
    4: 1133350,
    5: 1075850,
    "revenu brut": 3644000,
    trimf: 1500,
    1.5: 1315850,
    2.5: 1249183,
    3.5: 1171683,
    4.5: 1111266,
  },
  {
    1: 1341250,
    2: 1287083,
    3: 1203750,
    4: 1133750,
    5: 1076250,
    "revenu brut": 3645000,
    trimf: 1500,
    1.5: 1316250,
    2.5: 1249583,
    3.5: 1172083,
    4.5: 1111666,
  },
  {
    1: 1341650,
    2: 1287483,
    3: 1204150,
    4: 1134150,
    5: 1076650,
    "revenu brut": 3646000,
    trimf: 1500,
    1.5: 1316650,
    2.5: 1249983,
    3.5: 1172483,
    4.5: 1112066,
  },
  {
    1: 1342050,
    2: 1287883,
    3: 1204550,
    4: 1134550,
    5: 1077050,
    "revenu brut": 3647000,
    trimf: 1500,
    1.5: 1317050,
    2.5: 1250383,
    3.5: 1172883,
    4.5: 1112466,
  },
  {
    1: 1342450,
    2: 1288283,
    3: 1204950,
    4: 1134950,
    5: 1077450,
    "revenu brut": 3648000,
    trimf: 1500,
    1.5: 1317450,
    2.5: 1250783,
    3.5: 1173283,
    4.5: 1112866,
  },
  {
    1: 1342850,
    2: 1288683,
    3: 1205350,
    4: 1135350,
    5: 1077850,
    "revenu brut": 3649000,
    trimf: 1500,
    1.5: 1317850,
    2.5: 1251183,
    3.5: 1173683,
    4.5: 1113266,
  },
  {
    1: 1343250,
    2: 1289083,
    3: 1205750,
    4: 1135750,
    5: 1078250,
    "revenu brut": 3650000,
    trimf: 1500,
    1.5: 1318250,
    2.5: 1251583,
    3.5: 1174083,
    4.5: 1113666,
  },
  {
    1: 1343650,
    2: 1289483,
    3: 1206150,
    4: 1136150,
    5: 1078650,
    "revenu brut": 3651_000,
    trimf: 1500,
    1.5: 1318650,
    2.5: 1251983,
    3.5: 1174483,
    4.5: 1114066,
  },
  {
    1: 1344050,
    2: 1289883,
    3: 1206550,
    4: 1136550,
    5: 1079050,
    "revenu brut": 3652000,
    trimf: 1500,
    1.5: 1319050,
    2.5: 1252383,
    3.5: 1174883,
    4.5: 1114466,
  },
  {
    1: 1344450,
    2: 1290283,
    3: 1206950,
    4: 1136950,
    5: 1079450,
    "revenu brut": 3653000,
    trimf: 1500,
    1.5: 1319450,
    2.5: 1252783,
    3.5: 1175283,
    4.5: 1114866,
  },
  {
    1: 1344850,
    2: 1290683,
    3: 1207350,
    4: 1137350,
    5: 1079850,
    "revenu brut": 3654000,
    trimf: 1500,
    1.5: 1319850,
    2.5: 1253183,
    3.5: 1175683,
    4.5: 1115266,
  },
  {
    1: 1345250,
    2: 1291083,
    3: 1207750,
    4: 1137750,
    5: 1080250,
    "revenu brut": 3655000,
    trimf: 1500,
    1.5: 1320250,
    2.5: 1253583,
    3.5: 1176083,
    4.5: 1115666,
  },
  {
    1: 1345650,
    2: 1291483,
    3: 1208150,
    4: 1138150,
    5: 1080650,
    "revenu brut": 3656000,
    trimf: 1500,
    1.5: 1320650,
    2.5: 1253983,
    3.5: 1176483,
    4.5: 1116066,
  },
  {
    1: 1346050,
    2: 1291883,
    3: 1208550,
    4: 1138550,
    5: 1081050,
    "revenu brut": 3657000,
    trimf: 1500,
    1.5: 1321050,
    2.5: 1254383,
    3.5: 1176883,
    4.5: 1116466,
  },
  {
    1: 1346450,
    2: 1292283,
    3: 1208950,
    4: 1138950,
    5: 1081450,
    "revenu brut": 3658000,
    trimf: 1500,
    1.5: 1321450,
    2.5: 1254783,
    3.5: 1177283,
    4.5: 1116866,
  },
  {
    1: 1346850,
    2: 1292683,
    3: 1209350,
    4: 1139350,
    5: 1081850,
    "revenu brut": 3659000,
    trimf: 1500,
    1.5: 1321850,
    2.5: 1255183,
    3.5: 1177683,
    4.5: 1117266,
  },
  {
    1: 1347250,
    2: 1293083,
    3: 1209750,
    4: 1139750,
    5: 1082250,
    "revenu brut": 3660000,
    trimf: 1500,
    1.5: 1322250,
    2.5: 1255583,
    3.5: 1178083,
    4.5: 1117666,
  },
  {
    1: 1347650,
    2: 1293483,
    3: 1210150,
    4: 1140150,
    5: 1082650,
    "revenu brut": 3661_000,
    trimf: 1500,
    1.5: 1322650,
    2.5: 1255983,
    3.5: 1178483,
    4.5: 1118066,
  },
  {
    1: 1348050,
    2: 1293883,
    3: 1210550,
    4: 1140550,
    5: 1083050,
    "revenu brut": 3662000,
    trimf: 1500,
    1.5: 1323050,
    2.5: 1256383,
    3.5: 1178883,
    4.5: 1118466,
  },
  {
    1: 1348450,
    2: 1294283,
    3: 1210950,
    4: 1140950,
    5: 1083450,
    "revenu brut": 3663000,
    trimf: 1500,
    1.5: 1323450,
    2.5: 1256783,
    3.5: 1179283,
    4.5: 1118866,
  },
  {
    1: 1348850,
    2: 1294683,
    3: 1211350,
    4: 1141350,
    5: 1083850,
    "revenu brut": 3664000,
    trimf: 1500,
    1.5: 1323850,
    2.5: 1257183,
    3.5: 1179683,
    4.5: 1119266,
  },
  {
    1: 1349250,
    2: 1295083,
    3: 1211750,
    4: 1141750,
    5: 1084250,
    "revenu brut": 3665000,
    trimf: 1500,
    1.5: 1324250,
    2.5: 1257583,
    3.5: 1180083,
    4.5: 1119666,
  },
  {
    1: 1349650,
    2: 1295483,
    3: 1212150,
    4: 1142150,
    5: 1084650,
    "revenu brut": 3666000,
    trimf: 1500,
    1.5: 1324650,
    2.5: 1257983,
    3.5: 1180483,
    4.5: 1120066,
  },
  {
    1: 1350050,
    2: 1295883,
    3: 1212550,
    4: 1142550,
    5: 1085050,
    "revenu brut": 3667000,
    trimf: 1500,
    1.5: 1325050,
    2.5: 1258383,
    3.5: 1180883,
    4.5: 1120466,
  },
  {
    1: 1350450,
    2: 1296283,
    3: 1212950,
    4: 1142950,
    5: 1085450,
    "revenu brut": 3668000,
    trimf: 1500,
    1.5: 1325450,
    2.5: 1258783,
    3.5: 1181283,
    4.5: 1120866,
  },
  {
    1: 1350850,
    2: 1296683,
    3: 1213350,
    4: 1143350,
    5: 1085850,
    "revenu brut": 3669000,
    trimf: 1500,
    1.5: 1325850,
    2.5: 1259183,
    3.5: 1181683,
    4.5: 1121266,
  },
  {
    1: 1351250,
    2: 1297083,
    3: 1213750,
    4: 1143750,
    5: 1086250,
    "revenu brut": 3670000,
    trimf: 1500,
    1.5: 1326250,
    2.5: 1259583,
    3.5: 1182083,
    4.5: 1121666,
  },
  {
    1: 1351650,
    2: 1297483,
    3: 1214150,
    4: 1144150,
    5: 1086650,
    "revenu brut": 3671_000,
    trimf: 1500,
    1.5: 1326650,
    2.5: 1259983,
    3.5: 1182483,
    4.5: 1122066,
  },
  {
    1: 1352050,
    2: 1297883,
    3: 1214550,
    4: 1144550,
    5: 1087050,
    "revenu brut": 3672000,
    trimf: 1500,
    1.5: 1327050,
    2.5: 1260383,
    3.5: 1182883,
    4.5: 1122466,
  },
  {
    1: 1352450,
    2: 1298283,
    3: 1214950,
    4: 1144950,
    5: 1087450,
    "revenu brut": 3673000,
    trimf: 1500,
    1.5: 1327450,
    2.5: 1260783,
    3.5: 1183283,
    4.5: 1122866,
  },
  {
    1: 1352850,
    2: 1298683,
    3: 1215350,
    4: 1145350,
    5: 1087850,
    "revenu brut": 3674000,
    trimf: 1500,
    1.5: 1327850,
    2.5: 1261183,
    3.5: 1183683,
    4.5: 1123266,
  },
  {
    1: 1353250,
    2: 1299083,
    3: 1215750,
    4: 1145750,
    5: 1088250,
    "revenu brut": 3675000,
    trimf: 1500,
    1.5: 1328250,
    2.5: 1261583,
    3.5: 1184083,
    4.5: 1123666,
  },
  {
    1: 1353650,
    2: 1299483,
    3: 1216150,
    4: 1146150,
    5: 1088650,
    "revenu brut": 3676000,
    trimf: 1500,
    1.5: 1328650,
    2.5: 1261983,
    3.5: 1184483,
    4.5: 1124066,
  },
  {
    1: 1354050,
    2: 1299883,
    3: 1216550,
    4: 1146550,
    5: 1089050,
    "revenu brut": 3677000,
    trimf: 1500,
    1.5: 1329050,
    2.5: 1262383,
    3.5: 1184883,
    4.5: 1124466,
  },
  {
    1: 1354450,
    2: 1300283,
    3: 1216950,
    4: 1146950,
    5: 1089450,
    "revenu brut": 3678000,
    trimf: 1500,
    1.5: 1329450,
    2.5: 1262783,
    3.5: 1185283,
    4.5: 1124866,
  },
  {
    1: 1354850,
    2: 1300683,
    3: 1217350,
    4: 1147350,
    5: 1089850,
    "revenu brut": 3679000,
    trimf: 1500,
    1.5: 1329850,
    2.5: 1263183,
    3.5: 1185683,
    4.5: 1125266,
  },
  {
    1: 1355250,
    2: 1301083,
    3: 1217750,
    4: 1147750,
    5: 1090250,
    "revenu brut": 3680000,
    trimf: 1500,
    1.5: 1330250,
    2.5: 1263583,
    3.5: 1186083,
    4.5: 1125666,
  },
  {
    1: 1355650,
    2: 1301483,
    3: 1218150,
    4: 1148150,
    5: 1090650,
    "revenu brut": 3681_000,
    trimf: 1500,
    1.5: 1330650,
    2.5: 1263983,
    3.5: 1186483,
    4.5: 1126066,
  },
  {
    1: 1356050,
    2: 1301883,
    3: 1218550,
    4: 1148550,
    5: 1091050,
    "revenu brut": 3682000,
    trimf: 1500,
    1.5: 1331050,
    2.5: 1264383,
    3.5: 1186883,
    4.5: 1126466,
  },
  {
    1: 1356450,
    2: 1302283,
    3: 1218950,
    4: 1148950,
    5: 1091450,
    "revenu brut": 3683000,
    trimf: 1500,
    1.5: 1331450,
    2.5: 1264783,
    3.5: 1187283,
    4.5: 1126866,
  },
  {
    1: 1356850,
    2: 1302683,
    3: 1219350,
    4: 1149350,
    5: 1091850,
    "revenu brut": 3684000,
    trimf: 1500,
    1.5: 1331850,
    2.5: 1265183,
    3.5: 1187683,
    4.5: 1127266,
  },
  {
    1: 1357250,
    2: 1303083,
    3: 1219750,
    4: 1149750,
    5: 1092250,
    "revenu brut": 3685000,
    trimf: 1500,
    1.5: 1332250,
    2.5: 1265583,
    3.5: 1188083,
    4.5: 1127666,
  },
  {
    1: 1357650,
    2: 1303483,
    3: 1220150,
    4: 1150150,
    5: 1092650,
    "revenu brut": 3686000,
    trimf: 1500,
    1.5: 1332650,
    2.5: 1265983,
    3.5: 1188483,
    4.5: 1128066,
  },
  {
    1: 1358050,
    2: 1303883,
    3: 1220550,
    4: 1150550,
    5: 1093050,
    "revenu brut": 3687000,
    trimf: 1500,
    1.5: 1333050,
    2.5: 1266383,
    3.5: 1188883,
    4.5: 1128466,
  },
  {
    1: 1358450,
    2: 1304283,
    3: 1220950,
    4: 1150950,
    5: 1093450,
    "revenu brut": 3688000,
    trimf: 1500,
    1.5: 1333450,
    2.5: 1266783,
    3.5: 1189283,
    4.5: 1128866,
  },
  {
    1: 1358850,
    2: 1304683,
    3: 1221350,
    4: 1151350,
    5: 1093850,
    "revenu brut": 3689000,
    trimf: 1500,
    1.5: 1333850,
    2.5: 1267183,
    3.5: 1189683,
    4.5: 1129266,
  },
  {
    1: 1359250,
    2: 1305083,
    3: 1221750,
    4: 1151750,
    5: 1094250,
    "revenu brut": 3690000,
    trimf: 1500,
    1.5: 1334250,
    2.5: 1267583,
    3.5: 1190083,
    4.5: 1129666,
  },
  {
    1: 1359650,
    2: 1305483,
    3: 1222150,
    4: 1152150,
    5: 1094650,
    "revenu brut": 3691_000,
    trimf: 1500,
    1.5: 1334650,
    2.5: 1267983,
    3.5: 1190483,
    4.5: 1130066,
  },
  {
    1: 1360050,
    2: 1305883,
    3: 1222550,
    4: 1152550,
    5: 1095050,
    "revenu brut": 3692000,
    trimf: 1500,
    1.5: 1335050,
    2.5: 1268383,
    3.5: 1190883,
    4.5: 1130466,
  },
  {
    1: 1360450,
    2: 1306283,
    3: 1222950,
    4: 1152950,
    5: 1095450,
    "revenu brut": 3693000,
    trimf: 1500,
    1.5: 1335450,
    2.5: 1268783,
    3.5: 1191283,
    4.5: 1130866,
  },
  {
    1: 1360850,
    2: 1306683,
    3: 1223350,
    4: 1153350,
    5: 1095850,
    "revenu brut": 3694000,
    trimf: 1500,
    1.5: 1335850,
    2.5: 1269183,
    3.5: 1191683,
    4.5: 1131266,
  },
  {
    1: 1361250,
    2: 1307083,
    3: 1223750,
    4: 1153750,
    5: 1096250,
    "revenu brut": 3695000,
    trimf: 1500,
    1.5: 1336250,
    2.5: 1269583,
    3.5: 1192083,
    4.5: 1131666,
  },
  {
    1: 1361650,
    2: 1307483,
    3: 1224150,
    4: 1154150,
    5: 1096650,
    "revenu brut": 3696000,
    trimf: 1500,
    1.5: 1336650,
    2.5: 1269983,
    3.5: 1192483,
    4.5: 1132066,
  },
  {
    1: 1362050,
    2: 1307883,
    3: 1224550,
    4: 1154550,
    5: 1097050,
    "revenu brut": 3697000,
    trimf: 1500,
    1.5: 1337050,
    2.5: 1270383,
    3.5: 1192883,
    4.5: 1132466,
  },
  {
    1: 1362450,
    2: 1308283,
    3: 1224950,
    4: 1154950,
    5: 1097450,
    "revenu brut": 3698000,
    trimf: 1500,
    1.5: 1337450,
    2.5: 1270783,
    3.5: 1193283,
    4.5: 1132866,
  },
  {
    1: 1362850,
    2: 1308683,
    3: 1225350,
    4: 1155350,
    5: 1097850,
    "revenu brut": 3699000,
    trimf: 1500,
    1.5: 1337850,
    2.5: 1271183,
    3.5: 1193683,
    4.5: 1133266,
  },
  {
    1: 1363250,
    2: 1309083,
    3: 1225750,
    4: 1155750,
    5: 1098250,
    "revenu brut": 3700000,
    trimf: 1500,
    1.5: 1338250,
    2.5: 1271583,
    3.5: 1194083,
    4.5: 1133666,
  },
  {
    1: 1363650,
    2: 1309483,
    3: 1226150,
    4: 1156150,
    5: 1098650,
    "revenu brut": 3701_000,
    trimf: 1500,
    1.5: 1338650,
    2.5: 1271983,
    3.5: 1194483,
    4.5: 1134066,
  },
  {
    1: 1364050,
    2: 1309883,
    3: 1226550,
    4: 1156550,
    5: 1099050,
    "revenu brut": 3702000,
    trimf: 1500,
    1.5: 1339050,
    2.5: 1272383,
    3.5: 1194883,
    4.5: 1134466,
  },
  {
    1: 1364450,
    2: 1310283,
    3: 1226950,
    4: 1156950,
    5: 1099450,
    "revenu brut": 3703000,
    trimf: 1500,
    1.5: 1339450,
    2.5: 1272783,
    3.5: 1195283,
    4.5: 1134866,
  },
  {
    1: 1364850,
    2: 1310683,
    3: 1227350,
    4: 1157350,
    5: 1099850,
    "revenu brut": 3704000,
    trimf: 1500,
    1.5: 1339850,
    2.5: 1273183,
    3.5: 1195683,
    4.5: 1135266,
  },
  {
    1: 1365250,
    2: 1311083,
    3: 1227750,
    4: 1157750,
    5: 1100250,
    "revenu brut": 3705000,
    trimf: 1500,
    1.5: 1340250,
    2.5: 1273583,
    3.5: 1196083,
    4.5: 1135666,
  },
  {
    1: 1365650,
    2: 1311483,
    3: 1228150,
    4: 1158150,
    5: 1100650,
    "revenu brut": 3706000,
    trimf: 1500,
    1.5: 1340650,
    2.5: 1273983,
    3.5: 1196483,
    4.5: 1136066,
  },
  {
    1: 1366050,
    2: 1311883,
    3: 1228550,
    4: 1158550,
    5: 1101050,
    "revenu brut": 3707000,
    trimf: 1500,
    1.5: 1341050,
    2.5: 1274383,
    3.5: 1196883,
    4.5: 1136466,
  },
  {
    1: 1366450,
    2: 1312283,
    3: 1228950,
    4: 1158950,
    5: 1101450,
    "revenu brut": 3708000,
    trimf: 1500,
    1.5: 1341450,
    2.5: 1274783,
    3.5: 1197283,
    4.5: 1136866,
  },
  {
    1: 1366850,
    2: 1312683,
    3: 1229350,
    4: 1159350,
    5: 1101850,
    "revenu brut": 3709000,
    trimf: 1500,
    1.5: 1341850,
    2.5: 1275183,
    3.5: 1197683,
    4.5: 1137266,
  },
  {
    1: 1367250,
    2: 1313083,
    3: 1229750,
    4: 1159750,
    5: 1102250,
    "revenu brut": 371_0000,
    trimf: 1500,
    1.5: 1342250,
    2.5: 1275583,
    3.5: 1198083,
    4.5: 1137666,
  },
  {
    1: 1367650,
    2: 1313483,
    3: 1230150,
    4: 1160150,
    5: 1102650,
    "revenu brut": 3711_000,
    trimf: 1500,
    1.5: 1342650,
    2.5: 1275983,
    3.5: 1198483,
    4.5: 1138066,
  },
  {
    1: 1368050,
    2: 1313883,
    3: 1230550,
    4: 1160550,
    5: 1103050,
    "revenu brut": 3712000,
    trimf: 1500,
    1.5: 1343050,
    2.5: 1276383,
    3.5: 1198883,
    4.5: 1138466,
  },
  {
    1: 1368450,
    2: 1314283,
    3: 1230950,
    4: 1160950,
    5: 1103450,
    "revenu brut": 3713000,
    trimf: 1500,
    1.5: 1343450,
    2.5: 1276783,
    3.5: 1199283,
    4.5: 1138866,
  },
  {
    1: 1368850,
    2: 1314683,
    3: 1231350,
    4: 1161350,
    5: 1103850,
    "revenu brut": 3714000,
    trimf: 1500,
    1.5: 1343850,
    2.5: 1277183,
    3.5: 1199683,
    4.5: 1139266,
  },
  {
    1: 1369250,
    2: 1315083,
    3: 1231750,
    4: 1161750,
    5: 1104250,
    "revenu brut": 3715000,
    trimf: 1500,
    1.5: 1344250,
    2.5: 1277583,
    3.5: 1200083,
    4.5: 1139666,
  },
  {
    1: 1369650,
    2: 1315483,
    3: 1232150,
    4: 1162150,
    5: 1104650,
    "revenu brut": 3716000,
    trimf: 1500,
    1.5: 1344650,
    2.5: 1277983,
    3.5: 1200483,
    4.5: 1140066,
  },
  {
    1: 1370050,
    2: 1315883,
    3: 1232550,
    4: 1162550,
    5: 1105050,
    "revenu brut": 3717000,
    trimf: 1500,
    1.5: 1345050,
    2.5: 1278383,
    3.5: 1200883,
    4.5: 1140466,
  },
  {
    1: 1370450,
    2: 1316283,
    3: 1232950,
    4: 1162950,
    5: 1105450,
    "revenu brut": 3718000,
    trimf: 1500,
    1.5: 1345450,
    2.5: 1278783,
    3.5: 1201283,
    4.5: 1140866,
  },
  {
    1: 1370850,
    2: 1316683,
    3: 1233350,
    4: 1163350,
    5: 1105850,
    "revenu brut": 3719000,
    trimf: 1500,
    1.5: 1345850,
    2.5: 1279183,
    3.5: 1201683,
    4.5: 1141266,
  },
  {
    1: 1371250,
    2: 1317083,
    3: 1233750,
    4: 1163750,
    5: 1106250,
    "revenu brut": 3720000,
    trimf: 1500,
    1.5: 1346250,
    2.5: 1279583,
    3.5: 1202083,
    4.5: 1141666,
  },
  {
    1: 1371650,
    2: 1317483,
    3: 1234150,
    4: 1164150,
    5: 1106650,
    "revenu brut": 3721_000,
    trimf: 1500,
    1.5: 1346650,
    2.5: 1279983,
    3.5: 1202483,
    4.5: 1142066,
  },
  {
    1: 1372050,
    2: 1317883,
    3: 1234550,
    4: 1164550,
    5: 1107050,
    "revenu brut": 3722000,
    trimf: 1500,
    1.5: 1347050,
    2.5: 1280383,
    3.5: 1202883,
    4.5: 1142466,
  },
  {
    1: 1372450,
    2: 1318283,
    3: 1234950,
    4: 1164950,
    5: 1107450,
    "revenu brut": 3723000,
    trimf: 1500,
    1.5: 1347450,
    2.5: 1280783,
    3.5: 1203283,
    4.5: 1142866,
  },
  {
    1: 1372850,
    2: 1318683,
    3: 1235350,
    4: 1165350,
    5: 1107850,
    "revenu brut": 3724000,
    trimf: 1500,
    1.5: 1347850,
    2.5: 1281183,
    3.5: 1203683,
    4.5: 1143266,
  },
  {
    1: 1373250,
    2: 1319083,
    3: 1235750,
    4: 1165750,
    5: 1108250,
    "revenu brut": 3725000,
    trimf: 1500,
    1.5: 1348250,
    2.5: 1281583,
    3.5: 1204083,
    4.5: 1143666,
  },
  {
    1: 1373650,
    2: 1319483,
    3: 1236150,
    4: 1166150,
    5: 1108650,
    "revenu brut": 3726000,
    trimf: 1500,
    1.5: 1348650,
    2.5: 1281983,
    3.5: 1204483,
    4.5: 1144066,
  },
  {
    1: 1374050,
    2: 1319883,
    3: 1236550,
    4: 1166550,
    5: 1109050,
    "revenu brut": 3727000,
    trimf: 1500,
    1.5: 1349050,
    2.5: 1282383,
    3.5: 1204883,
    4.5: 1144466,
  },
  {
    1: 1374450,
    2: 1320283,
    3: 1236950,
    4: 1166950,
    5: 1109450,
    "revenu brut": 3728000,
    trimf: 1500,
    1.5: 1349450,
    2.5: 1282783,
    3.5: 1205283,
    4.5: 1144866,
  },
  {
    1: 1374850,
    2: 1320683,
    3: 1237350,
    4: 1167350,
    5: 1109850,
    "revenu brut": 3729000,
    trimf: 1500,
    1.5: 1349850,
    2.5: 1283183,
    3.5: 1205683,
    4.5: 1145266,
  },
  {
    1: 1375250,
    2: 1321083,
    3: 1237750,
    4: 1167750,
    5: 1110250,
    "revenu brut": 3730000,
    trimf: 1500,
    1.5: 1350250,
    2.5: 1283583,
    3.5: 1206083,
    4.5: 1145666,
  },
  {
    1: 1375650,
    2: 1321483,
    3: 1238150,
    4: 1168150,
    5: 1110650,
    "revenu brut": 3731_000,
    trimf: 1500,
    1.5: 1350650,
    2.5: 1283983,
    3.5: 1206483,
    4.5: 1146066,
  },
  {
    1: 1376050,
    2: 1321883,
    3: 1238550,
    4: 1168550,
    5: 1111050,
    "revenu brut": 3732000,
    trimf: 1500,
    1.5: 1351050,
    2.5: 1284383,
    3.5: 1206883,
    4.5: 1146466,
  },
  {
    1: 1376450,
    2: 1322283,
    3: 1238950,
    4: 1168950,
    5: 1111450,
    "revenu brut": 3733000,
    trimf: 1500,
    1.5: 1351450,
    2.5: 1284783,
    3.5: 1207283,
    4.5: 1146866,
  },
  {
    1: 1376850,
    2: 1322683,
    3: 1239350,
    4: 1169350,
    5: 1111850,
    "revenu brut": 3734000,
    trimf: 1500,
    1.5: 1351850,
    2.5: 1285183,
    3.5: 1207683,
    4.5: 1147266,
  },
  {
    1: 1377250,
    2: 1323083,
    3: 1239750,
    4: 1169750,
    5: 1112250,
    "revenu brut": 3735000,
    trimf: 1500,
    1.5: 1352250,
    2.5: 1285583,
    3.5: 1208083,
    4.5: 1147666,
  },
  {
    1: 1377650,
    2: 1323483,
    3: 1240150,
    4: 1170150,
    5: 1112650,
    "revenu brut": 3736000,
    trimf: 1500,
    1.5: 1352650,
    2.5: 1285983,
    3.5: 1208483,
    4.5: 1148066,
  },
  {
    1: 1378050,
    2: 1323883,
    3: 1240550,
    4: 1170550,
    5: 1113050,
    "revenu brut": 3737000,
    trimf: 1500,
    1.5: 1353050,
    2.5: 1286383,
    3.5: 1208883,
    4.5: 1148466,
  },
  {
    1: 1378450,
    2: 1324283,
    3: 1240950,
    4: 1170950,
    5: 1113450,
    "revenu brut": 3738000,
    trimf: 1500,
    1.5: 1353450,
    2.5: 1286783,
    3.5: 1209283,
    4.5: 1148866,
  },
  {
    1: 1378850,
    2: 1324683,
    3: 1241350,
    4: 1171350,
    5: 1113850,
    "revenu brut": 3739000,
    trimf: 1500,
    1.5: 1353850,
    2.5: 1287183,
    3.5: 1209683,
    4.5: 1149266,
  },
  {
    1: 1379250,
    2: 1325083,
    3: 1241750,
    4: 1171750,
    5: 1114250,
    "revenu brut": 3740000,
    trimf: 1500,
    1.5: 1354250,
    2.5: 1287583,
    3.5: 1210083,
    4.5: 1149666,
  },
  {
    1: 1379650,
    2: 1325483,
    3: 1242150,
    4: 1172150,
    5: 1114650,
    "revenu brut": 3741_000,
    trimf: 1500,
    1.5: 1354650,
    2.5: 1287983,
    3.5: 1210483,
    4.5: 1150066,
  },
  {
    1: 1380050,
    2: 1325883,
    3: 1242550,
    4: 1172550,
    5: 1115050,
    "revenu brut": 3742000,
    trimf: 1500,
    1.5: 1355050,
    2.5: 1288383,
    3.5: 1210883,
    4.5: 1150466,
  },
  {
    1: 1380450,
    2: 1326283,
    3: 1242950,
    4: 1172950,
    5: 1115450,
    "revenu brut": 3743000,
    trimf: 1500,
    1.5: 1355450,
    2.5: 1288783,
    3.5: 1211283,
    4.5: 1150866,
  },
  {
    1: 1380850,
    2: 1326683,
    3: 1243350,
    4: 1173350,
    5: 1115850,
    "revenu brut": 3744000,
    trimf: 1500,
    1.5: 1355850,
    2.5: 1289183,
    3.5: 1211683,
    4.5: 1151266,
  },
  {
    1: 1381250,
    2: 1327083,
    3: 1243750,
    4: 1173750,
    5: 1116250,
    "revenu brut": 3745000,
    trimf: 1500,
    1.5: 1356250,
    2.5: 1289583,
    3.5: 1212083,
    4.5: 1151666,
  },
  {
    1: 1381650,
    2: 1327483,
    3: 1244150,
    4: 1174150,
    5: 1116650,
    "revenu brut": 3746000,
    trimf: 1500,
    1.5: 1356650,
    2.5: 1289983,
    3.5: 1212483,
    4.5: 1152066,
  },
  {
    1: 1382050,
    2: 1327883,
    3: 1244550,
    4: 1174550,
    5: 1117050,
    "revenu brut": 3747000,
    trimf: 1500,
    1.5: 1357050,
    2.5: 1290383,
    3.5: 1212883,
    4.5: 1152466,
  },
  {
    1: 1382450,
    2: 1328283,
    3: 1244950,
    4: 1174950,
    5: 1117450,
    "revenu brut": 3748000,
    trimf: 1500,
    1.5: 1357450,
    2.5: 1290783,
    3.5: 1213283,
    4.5: 1152866,
  },
  {
    1: 1382850,
    2: 1328683,
    3: 1245350,
    4: 1175350,
    5: 1117850,
    "revenu brut": 3749000,
    trimf: 1500,
    1.5: 1357850,
    2.5: 1291183,
    3.5: 1213683,
    4.5: 1153266,
  },
  {
    1: 1383250,
    2: 1329083,
    3: 1245750,
    4: 1175750,
    5: 1118250,
    "revenu brut": 3750000,
    trimf: 1500,
    1.5: 1358250,
    2.5: 1291583,
    3.5: 1214083,
    4.5: 1153666,
  },
  {
    1: 1383650,
    2: 1329483,
    3: 1246150,
    4: 1176150,
    5: 1118650,
    "revenu brut": 3751_000,
    trimf: 1500,
    1.5: 1358650,
    2.5: 1291983,
    3.5: 1214483,
    4.5: 1154066,
  },
  {
    1: 1384050,
    2: 1329883,
    3: 1246550,
    4: 1176550,
    5: 1119050,
    "revenu brut": 3752000,
    trimf: 1500,
    1.5: 1359050,
    2.5: 1292383,
    3.5: 1214883,
    4.5: 1154466,
  },
  {
    1: 1384450,
    2: 1330283,
    3: 1246950,
    4: 1176950,
    5: 1119450,
    "revenu brut": 3753000,
    trimf: 1500,
    1.5: 1359450,
    2.5: 1292783,
    3.5: 1215283,
    4.5: 1154866,
  },
  {
    1: 1384850,
    2: 1330683,
    3: 1247350,
    4: 1177350,
    5: 1119850,
    "revenu brut": 3754000,
    trimf: 1500,
    1.5: 1359850,
    2.5: 1293183,
    3.5: 1215683,
    4.5: 1155266,
  },
  {
    1: 1385250,
    2: 1331083,
    3: 1247750,
    4: 1177750,
    5: 1120250,
    "revenu brut": 3755000,
    trimf: 1500,
    1.5: 1360250,
    2.5: 1293583,
    3.5: 1216083,
    4.5: 1155666,
  },
  {
    1: 1385650,
    2: 1331483,
    3: 1248150,
    4: 1178150,
    5: 1120650,
    "revenu brut": 3756000,
    trimf: 1500,
    1.5: 1360650,
    2.5: 1293983,
    3.5: 1216483,
    4.5: 1156066,
  },
  {
    1: 1386050,
    2: 1331883,
    3: 1248550,
    4: 1178550,
    5: 1121050,
    "revenu brut": 3757000,
    trimf: 1500,
    1.5: 1361050,
    2.5: 1294383,
    3.5: 1216883,
    4.5: 1156466,
  },
  {
    1: 1386450,
    2: 1332283,
    3: 1248950,
    4: 1178950,
    5: 1121450,
    "revenu brut": 3758000,
    trimf: 1500,
    1.5: 1361450,
    2.5: 1294783,
    3.5: 1217283,
    4.5: 1156866,
  },
  {
    1: 1386850,
    2: 1332683,
    3: 1249350,
    4: 1179350,
    5: 1121850,
    "revenu brut": 3759000,
    trimf: 1500,
    1.5: 1361850,
    2.5: 1295183,
    3.5: 1217683,
    4.5: 1157266,
  },
  {
    1: 1387250,
    2: 1333083,
    3: 1249750,
    4: 1179750,
    5: 1122250,
    "revenu brut": 3760000,
    trimf: 1500,
    1.5: 1362250,
    2.5: 1295583,
    3.5: 1218083,
    4.5: 1157666,
  },
  {
    1: 1387650,
    2: 1333483,
    3: 1250150,
    4: 1180150,
    5: 1122650,
    "revenu brut": 3761_000,
    trimf: 1500,
    1.5: 1362650,
    2.5: 1295983,
    3.5: 1218483,
    4.5: 1158066,
  },
  {
    1: 1388050,
    2: 1333883,
    3: 1250550,
    4: 1180550,
    5: 1123050,
    "revenu brut": 3762000,
    trimf: 1500,
    1.5: 1363050,
    2.5: 1296383,
    3.5: 1218883,
    4.5: 1158466,
  },
  {
    1: 1388450,
    2: 1334283,
    3: 1250950,
    4: 1180950,
    5: 1123450,
    "revenu brut": 3763000,
    trimf: 1500,
    1.5: 1363450,
    2.5: 1296783,
    3.5: 1219283,
    4.5: 1158866,
  },
  {
    1: 1388850,
    2: 1334683,
    3: 1251350,
    4: 1181350,
    5: 1123850,
    "revenu brut": 3764000,
    trimf: 1500,
    1.5: 1363850,
    2.5: 1297183,
    3.5: 1219683,
    4.5: 1159266,
  },
  {
    1: 1389250,
    2: 1335083,
    3: 1251750,
    4: 1181750,
    5: 1124250,
    "revenu brut": 3765000,
    trimf: 1500,
    1.5: 1364250,
    2.5: 1297583,
    3.5: 1220083,
    4.5: 1159666,
  },
  {
    1: 1389650,
    2: 1335483,
    3: 1252150,
    4: 1182150,
    5: 1124650,
    "revenu brut": 3766000,
    trimf: 1500,
    1.5: 1364650,
    2.5: 1297983,
    3.5: 1220483,
    4.5: 1160066,
  },
  {
    1: 1390050,
    2: 1335883,
    3: 1252550,
    4: 1182550,
    5: 1125050,
    "revenu brut": 3767000,
    trimf: 1500,
    1.5: 1365050,
    2.5: 1298383,
    3.5: 1220883,
    4.5: 1160466,
  },
  {
    1: 1390450,
    2: 1336283,
    3: 1252950,
    4: 1182950,
    5: 1125450,
    "revenu brut": 3768000,
    trimf: 1500,
    1.5: 1365450,
    2.5: 1298783,
    3.5: 1221283,
    4.5: 1160866,
  },
  {
    1: 1390850,
    2: 1336683,
    3: 1253350,
    4: 1183350,
    5: 1125850,
    "revenu brut": 3769000,
    trimf: 1500,
    1.5: 1365850,
    2.5: 1299183,
    3.5: 1221683,
    4.5: 1161266,
  },
  {
    1: 1391250,
    2: 1337083,
    3: 1253750,
    4: 1183750,
    5: 1126250,
    "revenu brut": 3770000,
    trimf: 1500,
    1.5: 1366250,
    2.5: 1299583,
    3.5: 1222083,
    4.5: 1161666,
  },
  {
    1: 1391650,
    2: 1337483,
    3: 1254150,
    4: 1184150,
    5: 1126650,
    "revenu brut": 3771_000,
    trimf: 1500,
    1.5: 1366650,
    2.5: 1299983,
    3.5: 1222483,
    4.5: 1162066,
  },
  {
    1: 1392050,
    2: 1337883,
    3: 1254550,
    4: 1184550,
    5: 1127050,
    "revenu brut": 3772000,
    trimf: 1500,
    1.5: 1367050,
    2.5: 1300383,
    3.5: 1222883,
    4.5: 1162466,
  },
  {
    1: 1392450,
    2: 1338283,
    3: 1254950,
    4: 1184950,
    5: 1127450,
    "revenu brut": 3773000,
    trimf: 1500,
    1.5: 1367450,
    2.5: 1300783,
    3.5: 1223283,
    4.5: 1162866,
  },
  {
    1: 1392850,
    2: 1338683,
    3: 1255350,
    4: 1185350,
    5: 1127850,
    "revenu brut": 3774000,
    trimf: 1500,
    1.5: 1367850,
    2.5: 1301183,
    3.5: 1223683,
    4.5: 1163266,
  },
  {
    1: 1393250,
    2: 1339083,
    3: 1255750,
    4: 1185750,
    5: 1128250,
    "revenu brut": 3775000,
    trimf: 1500,
    1.5: 1368250,
    2.5: 1301583,
    3.5: 1224083,
    4.5: 1163666,
  },
  {
    1: 1393650,
    2: 1339483,
    3: 1256150,
    4: 1186150,
    5: 1128650,
    "revenu brut": 3776000,
    trimf: 1500,
    1.5: 1368650,
    2.5: 1301983,
    3.5: 1224483,
    4.5: 1164066,
  },
  {
    1: 1394050,
    2: 1339883,
    3: 1256550,
    4: 1186550,
    5: 1129050,
    "revenu brut": 3777000,
    trimf: 1500,
    1.5: 1369050,
    2.5: 1302383,
    3.5: 1224883,
    4.5: 1164466,
  },
  {
    1: 1394450,
    2: 1340283,
    3: 1256950,
    4: 1186950,
    5: 1129450,
    "revenu brut": 3778000,
    trimf: 1500,
    1.5: 1369450,
    2.5: 1302783,
    3.5: 1225283,
    4.5: 1164866,
  },
  {
    1: 1394850,
    2: 1340683,
    3: 1257350,
    4: 1187350,
    5: 1129850,
    "revenu brut": 3779000,
    trimf: 1500,
    1.5: 1369850,
    2.5: 1303183,
    3.5: 1225683,
    4.5: 1165266,
  },
  {
    1: 1395250,
    2: 1341083,
    3: 1257750,
    4: 1187750,
    5: 1130250,
    "revenu brut": 3780000,
    trimf: 1500,
    1.5: 1370250,
    2.5: 1303583,
    3.5: 1226083,
    4.5: 1165666,
  },
  {
    1: 1395650,
    2: 1341483,
    3: 1258150,
    4: 1188150,
    5: 1130650,
    "revenu brut": 3781_000,
    trimf: 1500,
    1.5: 1370650,
    2.5: 1303983,
    3.5: 1226483,
    4.5: 1166066,
  },
  {
    1: 1396050,
    2: 1341883,
    3: 1258550,
    4: 1188550,
    5: 1131050,
    "revenu brut": 3782000,
    trimf: 1500,
    1.5: 1371050,
    2.5: 1304383,
    3.5: 1226883,
    4.5: 1166466,
  },
  {
    1: 1396450,
    2: 1342283,
    3: 1258950,
    4: 1188950,
    5: 1131450,
    "revenu brut": 3783000,
    trimf: 1500,
    1.5: 1371450,
    2.5: 1304783,
    3.5: 1227283,
    4.5: 1166866,
  },
  {
    1: 1396850,
    2: 1342683,
    3: 1259350,
    4: 1189350,
    5: 1131850,
    "revenu brut": 3784000,
    trimf: 1500,
    1.5: 1371850,
    2.5: 1305183,
    3.5: 1227683,
    4.5: 1167266,
  },
  {
    1: 1397250,
    2: 1343083,
    3: 1259750,
    4: 1189750,
    5: 1132250,
    "revenu brut": 3785000,
    trimf: 1500,
    1.5: 1372250,
    2.5: 1305583,
    3.5: 1228083,
    4.5: 1167666,
  },
  {
    1: 1397650,
    2: 1343483,
    3: 1260150,
    4: 1190150,
    5: 1132650,
    "revenu brut": 3786000,
    trimf: 1500,
    1.5: 1372650,
    2.5: 1305983,
    3.5: 1228483,
    4.5: 1168066,
  },
  {
    1: 1398050,
    2: 1343883,
    3: 1260550,
    4: 1190550,
    5: 1133050,
    "revenu brut": 3787000,
    trimf: 1500,
    1.5: 1373050,
    2.5: 1306383,
    3.5: 1228883,
    4.5: 1168466,
  },
  {
    1: 1398450,
    2: 1344283,
    3: 1260950,
    4: 1190950,
    5: 1133450,
    "revenu brut": 3788000,
    trimf: 1500,
    1.5: 1373450,
    2.5: 1306783,
    3.5: 1229283,
    4.5: 1168866,
  },
  {
    1: 1398850,
    2: 1344683,
    3: 1261350,
    4: 1191350,
    5: 1133850,
    "revenu brut": 3789000,
    trimf: 1500,
    1.5: 1373850,
    2.5: 1307183,
    3.5: 1229683,
    4.5: 1169266,
  },
  {
    1: 1399250,
    2: 1345083,
    3: 1261750,
    4: 1191750,
    5: 1134250,
    "revenu brut": 3790000,
    trimf: 1500,
    1.5: 1374250,
    2.5: 1307583,
    3.5: 1230083,
    4.5: 1169666,
  },
  {
    1: 1399650,
    2: 1345483,
    3: 1262150,
    4: 1192150,
    5: 1134650,
    "revenu brut": 3791_000,
    trimf: 1500,
    1.5: 1374650,
    2.5: 1307983,
    3.5: 1230483,
    4.5: 1170066,
  },
  {
    1: 1400050,
    2: 1345883,
    3: 1262550,
    4: 1192550,
    5: 1135050,
    "revenu brut": 3792000,
    trimf: 1500,
    1.5: 1375050,
    2.5: 1308383,
    3.5: 1230883,
    4.5: 1170466,
  },
  {
    1: 1400450,
    2: 1346283,
    3: 1262950,
    4: 1192950,
    5: 1135450,
    "revenu brut": 3793000,
    trimf: 1500,
    1.5: 1375450,
    2.5: 1308783,
    3.5: 1231283,
    4.5: 1170866,
  },
  {
    1: 1400850,
    2: 1346683,
    3: 1263350,
    4: 1193350,
    5: 1135850,
    "revenu brut": 3794000,
    trimf: 1500,
    1.5: 1375850,
    2.5: 1309183,
    3.5: 1231683,
    4.5: 1171266,
  },
  {
    1: 1401250,
    2: 1347083,
    3: 1263750,
    4: 1193750,
    5: 1136250,
    "revenu brut": 3795000,
    trimf: 1500,
    1.5: 1376250,
    2.5: 1309583,
    3.5: 1232083,
    4.5: 1171666,
  },
  {
    1: 1401650,
    2: 1347483,
    3: 1264150,
    4: 1194150,
    5: 1136650,
    "revenu brut": 3796000,
    trimf: 1500,
    1.5: 1376650,
    2.5: 1309983,
    3.5: 1232483,
    4.5: 1172066,
  },
  {
    1: 1402050,
    2: 1347883,
    3: 1264550,
    4: 1194550,
    5: 1137050,
    "revenu brut": 3797000,
    trimf: 1500,
    1.5: 1377050,
    2.5: 1310383,
    3.5: 1232883,
    4.5: 1172466,
  },
  {
    1: 1402450,
    2: 1348283,
    3: 1264950,
    4: 1194950,
    5: 1137450,
    "revenu brut": 3798000,
    trimf: 1500,
    1.5: 1377450,
    2.5: 1310783,
    3.5: 1233283,
    4.5: 1172866,
  },
  {
    1: 1402850,
    2: 1348683,
    3: 1265350,
    4: 1195350,
    5: 1137850,
    "revenu brut": 3799000,
    trimf: 1500,
    1.5: 1377850,
    2.5: 1311183,
    3.5: 1233683,
    4.5: 1173266,
  },
  {
    1: 1403250,
    2: 1349083,
    3: 1265750,
    4: 1195750,
    5: 1138250,
    "revenu brut": 3800000,
    trimf: 1500,
    1.5: 1378250,
    2.5: 1311583,
    3.5: 1234083,
    4.5: 1173666,
  },
  {
    1: 1403650,
    2: 1349483,
    3: 1266150,
    4: 1196150,
    5: 1138650,
    "revenu brut": 3801_000,
    trimf: 1500,
    1.5: 1378650,
    2.5: 1311983,
    3.5: 1234483,
    4.5: 1174066,
  },
  {
    1: 1404050,
    2: 1349883,
    3: 1266550,
    4: 1196550,
    5: 1139050,
    "revenu brut": 3802000,
    trimf: 1500,
    1.5: 1379050,
    2.5: 1312383,
    3.5: 1234883,
    4.5: 1174466,
  },
  {
    1: 1404450,
    2: 1350283,
    3: 1266950,
    4: 1196950,
    5: 1139450,
    "revenu brut": 3803000,
    trimf: 1500,
    1.5: 1379450,
    2.5: 1312783,
    3.5: 1235283,
    4.5: 1174866,
  },
  {
    1: 1404850,
    2: 1350683,
    3: 1267350,
    4: 1197350,
    5: 1139850,
    "revenu brut": 3804000,
    trimf: 1500,
    1.5: 1379850,
    2.5: 1313183,
    3.5: 1235683,
    4.5: 1175266,
  },
  {
    1: 1405250,
    2: 1351083,
    3: 1267750,
    4: 1197750,
    5: 1140250,
    "revenu brut": 3805000,
    trimf: 1500,
    1.5: 1380250,
    2.5: 1313583,
    3.5: 1236083,
    4.5: 1175666,
  },
  {
    1: 1405650,
    2: 1351483,
    3: 1268150,
    4: 1198150,
    5: 1140650,
    "revenu brut": 3806000,
    trimf: 1500,
    1.5: 1380650,
    2.5: 1313983,
    3.5: 1236483,
    4.5: 1176066,
  },
  {
    1: 1406050,
    2: 1351883,
    3: 1268550,
    4: 1198550,
    5: 1141050,
    "revenu brut": 3807000,
    trimf: 1500,
    1.5: 1381050,
    2.5: 1314383,
    3.5: 1236883,
    4.5: 1176466,
  },
  {
    1: 1406450,
    2: 1352283,
    3: 1268950,
    4: 1198950,
    5: 1141450,
    "revenu brut": 3808000,
    trimf: 1500,
    1.5: 1381450,
    2.5: 1314783,
    3.5: 1237283,
    4.5: 1176866,
  },
  {
    1: 1406850,
    2: 1352683,
    3: 1269350,
    4: 1199350,
    5: 1141850,
    "revenu brut": 3809000,
    trimf: 1500,
    1.5: 1381850,
    2.5: 1315183,
    3.5: 1237683,
    4.5: 1177266,
  },
  {
    1: 1407250,
    2: 1353083,
    3: 1269750,
    4: 1199750,
    5: 1142250,
    "revenu brut": 381_0000,
    trimf: 1500,
    1.5: 1382250,
    2.5: 1315583,
    3.5: 1238083,
    4.5: 1177666,
  },
  {
    1: 1407650,
    2: 1353483,
    3: 1270150,
    4: 1200150,
    5: 1142650,
    "revenu brut": 3811_000,
    trimf: 1500,
    1.5: 1382650,
    2.5: 1315983,
    3.5: 1238483,
    4.5: 1178066,
  },
  {
    1: 1408050,
    2: 1353883,
    3: 1270550,
    4: 1200550,
    5: 1143050,
    "revenu brut": 3812000,
    trimf: 1500,
    1.5: 1383050,
    2.5: 1316383,
    3.5: 1238883,
    4.5: 1178466,
  },
  {
    1: 1408450,
    2: 1354283,
    3: 1270950,
    4: 1200950,
    5: 1143450,
    "revenu brut": 3813000,
    trimf: 1500,
    1.5: 1383450,
    2.5: 1316783,
    3.5: 1239283,
    4.5: 1178866,
  },
  {
    1: 1408850,
    2: 1354683,
    3: 1271350,
    4: 1201350,
    5: 1143850,
    "revenu brut": 3814000,
    trimf: 1500,
    1.5: 1383850,
    2.5: 1317183,
    3.5: 1239683,
    4.5: 1179266,
  },
  {
    1: 1409250,
    2: 1355083,
    3: 1271750,
    4: 1201750,
    5: 1144250,
    "revenu brut": 3815000,
    trimf: 1500,
    1.5: 1384250,
    2.5: 1317583,
    3.5: 1240083,
    4.5: 1179666,
  },
  {
    1: 1409650,
    2: 1355483,
    3: 1272150,
    4: 1202150,
    5: 1144650,
    "revenu brut": 3816000,
    trimf: 1500,
    1.5: 1384650,
    2.5: 1317983,
    3.5: 1240483,
    4.5: 1180066,
  },
  {
    1: 1410050,
    2: 1355883,
    3: 1272550,
    4: 1202550,
    5: 1145050,
    "revenu brut": 3817000,
    trimf: 1500,
    1.5: 1385050,
    2.5: 1318383,
    3.5: 1240883,
    4.5: 1180466,
  },
  {
    1: 1410450,
    2: 1356283,
    3: 1272950,
    4: 1202950,
    5: 1145450,
    "revenu brut": 3818000,
    trimf: 1500,
    1.5: 1385450,
    2.5: 1318783,
    3.5: 1241283,
    4.5: 1180866,
  },
  {
    1: 1410850,
    2: 1356683,
    3: 1273350,
    4: 1203350,
    5: 1145850,
    "revenu brut": 3819000,
    trimf: 1500,
    1.5: 1385850,
    2.5: 1319183,
    3.5: 1241683,
    4.5: 1181266,
  },
  {
    1: 1411250,
    2: 1357083,
    3: 1273750,
    4: 1203750,
    5: 1146250,
    "revenu brut": 3820000,
    trimf: 1500,
    1.5: 1386250,
    2.5: 1319583,
    3.5: 1242083,
    4.5: 1181666,
  },
  {
    1: 1411650,
    2: 1357483,
    3: 1274150,
    4: 1204150,
    5: 1146650,
    "revenu brut": 3821_000,
    trimf: 1500,
    1.5: 1386650,
    2.5: 1319983,
    3.5: 1242483,
    4.5: 1182066,
  },
  {
    1: 1412050,
    2: 1357883,
    3: 1274550,
    4: 1204550,
    5: 1147050,
    "revenu brut": 3822000,
    trimf: 1500,
    1.5: 1387050,
    2.5: 1320383,
    3.5: 1242883,
    4.5: 1182466,
  },
  {
    1: 1412450,
    2: 1358283,
    3: 1274950,
    4: 1204950,
    5: 1147450,
    "revenu brut": 3823000,
    trimf: 1500,
    1.5: 1387450,
    2.5: 1320783,
    3.5: 1243283,
    4.5: 1182866,
  },
  {
    1: 1412850,
    2: 1358683,
    3: 1275350,
    4: 1205350,
    5: 1147850,
    "revenu brut": 3824000,
    trimf: 1500,
    1.5: 1387850,
    2.5: 1321183,
    3.5: 1243683,
    4.5: 1183266,
  },
  {
    1: 1413250,
    2: 1359083,
    3: 1275750,
    4: 1205750,
    5: 1148250,
    "revenu brut": 3825000,
    trimf: 1500,
    1.5: 1388250,
    2.5: 1321583,
    3.5: 1244083,
    4.5: 1183666,
  },
  {
    1: 1413650,
    2: 1359483,
    3: 1276150,
    4: 1206150,
    5: 1148650,
    "revenu brut": 3826000,
    trimf: 1500,
    1.5: 1388650,
    2.5: 1321983,
    3.5: 1244483,
    4.5: 1184066,
  },
  {
    1: 1414050,
    2: 1359883,
    3: 1276550,
    4: 1206550,
    5: 1149050,
    "revenu brut": 3827000,
    trimf: 1500,
    1.5: 1389050,
    2.5: 1322383,
    3.5: 1244883,
    4.5: 1184466,
  },
  {
    1: 1414450,
    2: 1360283,
    3: 1276950,
    4: 1206950,
    5: 1149450,
    "revenu brut": 3828000,
    trimf: 1500,
    1.5: 1389450,
    2.5: 1322783,
    3.5: 1245283,
    4.5: 1184866,
  },
  {
    1: 1414850,
    2: 1360683,
    3: 1277350,
    4: 1207350,
    5: 1149850,
    "revenu brut": 3829000,
    trimf: 1500,
    1.5: 1389850,
    2.5: 1323183,
    3.5: 1245683,
    4.5: 1185266,
  },
  {
    1: 1415250,
    2: 1361083,
    3: 1277750,
    4: 1207750,
    5: 1150250,
    "revenu brut": 3830000,
    trimf: 1500,
    1.5: 1390250,
    2.5: 1323583,
    3.5: 1246083,
    4.5: 1185666,
  },
  {
    1: 1415650,
    2: 1361483,
    3: 1278150,
    4: 1208150,
    5: 1150650,
    "revenu brut": 3831_000,
    trimf: 1500,
    1.5: 1390650,
    2.5: 1323983,
    3.5: 1246483,
    4.5: 1186066,
  },
  {
    1: 1416050,
    2: 1361883,
    3: 1278550,
    4: 1208550,
    5: 1151050,
    "revenu brut": 3832000,
    trimf: 1500,
    1.5: 1391050,
    2.5: 1324383,
    3.5: 1246883,
    4.5: 1186466,
  },
  {
    1: 1416450,
    2: 1362283,
    3: 1278950,
    4: 1208950,
    5: 1151450,
    "revenu brut": 3833000,
    trimf: 1500,
    1.5: 1391450,
    2.5: 1324783,
    3.5: 1247283,
    4.5: 1186866,
  },
  {
    1: 1416850,
    2: 1362683,
    3: 1279350,
    4: 1209350,
    5: 1151850,
    "revenu brut": 3834000,
    trimf: 1500,
    1.5: 1391850,
    2.5: 1325183,
    3.5: 1247683,
    4.5: 1187266,
  },
  {
    1: 1417250,
    2: 1363083,
    3: 1279750,
    4: 1209750,
    5: 1152250,
    "revenu brut": 3835000,
    trimf: 1500,
    1.5: 1392250,
    2.5: 1325583,
    3.5: 1248083,
    4.5: 1187666,
  },
  {
    1: 1417650,
    2: 1363483,
    3: 1280150,
    4: 1210150,
    5: 1152650,
    "revenu brut": 3836000,
    trimf: 1500,
    1.5: 1392650,
    2.5: 1325983,
    3.5: 1248483,
    4.5: 1188066,
  },
  {
    1: 1418050,
    2: 1363883,
    3: 1280550,
    4: 1210550,
    5: 1153050,
    "revenu brut": 3837000,
    trimf: 1500,
    1.5: 1393050,
    2.5: 1326383,
    3.5: 1248883,
    4.5: 1188466,
  },
  {
    1: 1418450,
    2: 1364283,
    3: 1280950,
    4: 1210950,
    5: 1153450,
    "revenu brut": 3838000,
    trimf: 1500,
    1.5: 1393450,
    2.5: 1326783,
    3.5: 1249283,
    4.5: 1188866,
  },
  {
    1: 1418850,
    2: 1364683,
    3: 1281350,
    4: 1211350,
    5: 1153850,
    "revenu brut": 3839000,
    trimf: 1500,
    1.5: 1393850,
    2.5: 1327183,
    3.5: 1249683,
    4.5: 1189266,
  },
  {
    1: 1419250,
    2: 1365083,
    3: 1281750,
    4: 1211750,
    5: 1154250,
    "revenu brut": 3840000,
    trimf: 1500,
    1.5: 1394250,
    2.5: 1327583,
    3.5: 1250083,
    4.5: 1189666,
  },
  {
    1: 1419650,
    2: 1365483,
    3: 1282150,
    4: 1212150,
    5: 1154650,
    "revenu brut": 3841_000,
    trimf: 1500,
    1.5: 1394650,
    2.5: 1327983,
    3.5: 1250483,
    4.5: 1190066,
  },
  {
    1: 1420050,
    2: 1365883,
    3: 1282550,
    4: 1212550,
    5: 1155050,
    "revenu brut": 3842000,
    trimf: 1500,
    1.5: 1395050,
    2.5: 1328383,
    3.5: 1250883,
    4.5: 1190466,
  },
  {
    1: 1420450,
    2: 1366283,
    3: 1282950,
    4: 1212950,
    5: 1155450,
    "revenu brut": 3843000,
    trimf: 1500,
    1.5: 1395450,
    2.5: 1328783,
    3.5: 1251283,
    4.5: 1190866,
  },
  {
    1: 1420850,
    2: 1366683,
    3: 1283350,
    4: 1213350,
    5: 1155850,
    "revenu brut": 3844000,
    trimf: 1500,
    1.5: 1395850,
    2.5: 1329183,
    3.5: 1251683,
    4.5: 1191266,
  },
  {
    1: 1421250,
    2: 1367083,
    3: 1283750,
    4: 1213750,
    5: 1156250,
    "revenu brut": 3845000,
    trimf: 1500,
    1.5: 1396250,
    2.5: 1329583,
    3.5: 1252083,
    4.5: 1191666,
  },
  {
    1: 1421650,
    2: 1367483,
    3: 1284150,
    4: 1214150,
    5: 1156650,
    "revenu brut": 3846000,
    trimf: 1500,
    1.5: 1396650,
    2.5: 1329983,
    3.5: 1252483,
    4.5: 1192066,
  },
  {
    1: 1422050,
    2: 1367883,
    3: 1284550,
    4: 1214550,
    5: 1157050,
    "revenu brut": 3847000,
    trimf: 1500,
    1.5: 1397050,
    2.5: 1330383,
    3.5: 1252883,
    4.5: 1192466,
  },
  {
    1: 1422450,
    2: 1368283,
    3: 1284950,
    4: 1214950,
    5: 1157450,
    "revenu brut": 3848000,
    trimf: 1500,
    1.5: 1397450,
    2.5: 1330783,
    3.5: 1253283,
    4.5: 1192866,
  },
  {
    1: 1422850,
    2: 1368683,
    3: 1285350,
    4: 1215350,
    5: 1157850,
    "revenu brut": 3849000,
    trimf: 1500,
    1.5: 1397850,
    2.5: 1331183,
    3.5: 1253683,
    4.5: 1193266,
  },
  {
    1: 1423250,
    2: 1369083,
    3: 1285750,
    4: 1215750,
    5: 1158250,
    "revenu brut": 3850000,
    trimf: 1500,
    1.5: 1398250,
    2.5: 1331583,
    3.5: 1254083,
    4.5: 1193666,
  },
  {
    1: 1423650,
    2: 1369483,
    3: 1286150,
    4: 1216150,
    5: 1158650,
    "revenu brut": 3851_000,
    trimf: 1500,
    1.5: 1398650,
    2.5: 1331983,
    3.5: 1254483,
    4.5: 1194066,
  },
  {
    1: 1424050,
    2: 1369883,
    3: 1286550,
    4: 1216550,
    5: 1159050,
    "revenu brut": 3852000,
    trimf: 1500,
    1.5: 1399050,
    2.5: 1332383,
    3.5: 1254883,
    4.5: 1194466,
  },
  {
    1: 1424450,
    2: 1370283,
    3: 1286950,
    4: 1216950,
    5: 1159450,
    "revenu brut": 3853000,
    trimf: 1500,
    1.5: 1399450,
    2.5: 1332783,
    3.5: 1255283,
    4.5: 1194866,
  },
  {
    1: 1424850,
    2: 1370683,
    3: 1287350,
    4: 1217350,
    5: 1159850,
    "revenu brut": 3854000,
    trimf: 1500,
    1.5: 1399850,
    2.5: 1333183,
    3.5: 1255683,
    4.5: 1195266,
  },
  {
    1: 1425250,
    2: 1371083,
    3: 1287750,
    4: 1217750,
    5: 1160250,
    "revenu brut": 3855000,
    trimf: 1500,
    1.5: 1400250,
    2.5: 1333583,
    3.5: 1256083,
    4.5: 1195666,
  },
  {
    1: 1425650,
    2: 1371483,
    3: 1288150,
    4: 1218150,
    5: 1160650,
    "revenu brut": 3856000,
    trimf: 1500,
    1.5: 1400650,
    2.5: 1333983,
    3.5: 1256483,
    4.5: 1196066,
  },
  {
    1: 1426050,
    2: 1371883,
    3: 1288550,
    4: 1218550,
    5: 1161050,
    "revenu brut": 3857000,
    trimf: 1500,
    1.5: 1401050,
    2.5: 1334383,
    3.5: 1256883,
    4.5: 1196466,
  },
  {
    1: 1426450,
    2: 1372283,
    3: 1288950,
    4: 1218950,
    5: 1161450,
    "revenu brut": 3858000,
    trimf: 1500,
    1.5: 1401450,
    2.5: 1334783,
    3.5: 1257283,
    4.5: 1196866,
  },
  {
    1: 1426850,
    2: 1372683,
    3: 1289350,
    4: 1219350,
    5: 1161850,
    "revenu brut": 3859000,
    trimf: 1500,
    1.5: 1401850,
    2.5: 1335183,
    3.5: 1257683,
    4.5: 1197266,
  },
  {
    1: 1427250,
    2: 1373083,
    3: 1289750,
    4: 1219750,
    5: 1162250,
    "revenu brut": 3860000,
    trimf: 1500,
    1.5: 1402250,
    2.5: 1335583,
    3.5: 1258083,
    4.5: 1197666,
  },
  {
    1: 1427650,
    2: 1373483,
    3: 1290150,
    4: 1220150,
    5: 1162650,
    "revenu brut": 3861_000,
    trimf: 1500,
    1.5: 1402650,
    2.5: 1335983,
    3.5: 1258483,
    4.5: 1198066,
  },
  {
    1: 1428050,
    2: 1373883,
    3: 1290550,
    4: 1220550,
    5: 1163050,
    "revenu brut": 3862000,
    trimf: 1500,
    1.5: 1403050,
    2.5: 1336383,
    3.5: 1258883,
    4.5: 1198466,
  },
  {
    1: 1428450,
    2: 1374283,
    3: 1290950,
    4: 1220950,
    5: 1163450,
    "revenu brut": 3863000,
    trimf: 1500,
    1.5: 1403450,
    2.5: 1336783,
    3.5: 1259283,
    4.5: 1198866,
  },
  {
    1: 1428850,
    2: 1374683,
    3: 1291350,
    4: 1221350,
    5: 1163850,
    "revenu brut": 3864000,
    trimf: 1500,
    1.5: 1403850,
    2.5: 1337183,
    3.5: 1259683,
    4.5: 1199266,
  },
  {
    1: 1429250,
    2: 1375083,
    3: 1291750,
    4: 1221750,
    5: 1164250,
    "revenu brut": 3865000,
    trimf: 1500,
    1.5: 1404250,
    2.5: 1337583,
    3.5: 1260083,
    4.5: 1199666,
  },
  {
    1: 1429650,
    2: 1375483,
    3: 1292150,
    4: 1222150,
    5: 1164650,
    "revenu brut": 3866000,
    trimf: 1500,
    1.5: 1404650,
    2.5: 1337983,
    3.5: 1260483,
    4.5: 1200066,
  },
  {
    1: 1430050,
    2: 1375883,
    3: 1292550,
    4: 1222550,
    5: 1165050,
    "revenu brut": 3867000,
    trimf: 1500,
    1.5: 1405050,
    2.5: 1338383,
    3.5: 1260883,
    4.5: 1200466,
  },
  {
    1: 1430450,
    2: 1376283,
    3: 1292950,
    4: 1222950,
    5: 1165450,
    "revenu brut": 3868000,
    trimf: 1500,
    1.5: 1405450,
    2.5: 1338783,
    3.5: 1261283,
    4.5: 1200866,
  },
  {
    1: 1430850,
    2: 1376683,
    3: 1293350,
    4: 1223350,
    5: 1165850,
    "revenu brut": 3869000,
    trimf: 1500,
    1.5: 1405850,
    2.5: 1339183,
    3.5: 1261683,
    4.5: 1201266,
  },
  {
    1: 1431250,
    2: 1377083,
    3: 1293750,
    4: 1223750,
    5: 1166250,
    "revenu brut": 3870000,
    trimf: 1500,
    1.5: 1406250,
    2.5: 1339583,
    3.5: 1262083,
    4.5: 1201666,
  },
  {
    1: 1431650,
    2: 1377483,
    3: 1294150,
    4: 1224150,
    5: 1166650,
    "revenu brut": 3871_000,
    trimf: 1500,
    1.5: 1406650,
    2.5: 1339983,
    3.5: 1262483,
    4.5: 1202066,
  },
  {
    1: 1432050,
    2: 1377883,
    3: 1294550,
    4: 1224550,
    5: 1167050,
    "revenu brut": 3872000,
    trimf: 1500,
    1.5: 1407050,
    2.5: 1340383,
    3.5: 1262883,
    4.5: 1202466,
  },
  {
    1: 1432450,
    2: 1378283,
    3: 1294950,
    4: 1224950,
    5: 1167450,
    "revenu brut": 3873000,
    trimf: 1500,
    1.5: 1407450,
    2.5: 1340783,
    3.5: 1263283,
    4.5: 1202866,
  },
  {
    1: 1432850,
    2: 1378683,
    3: 1295350,
    4: 1225350,
    5: 1167850,
    "revenu brut": 3874000,
    trimf: 1500,
    1.5: 1407850,
    2.5: 1341183,
    3.5: 1263683,
    4.5: 1203266,
  },
  {
    1: 1433250,
    2: 1379083,
    3: 1295750,
    4: 1225750,
    5: 1168250,
    "revenu brut": 3875000,
    trimf: 1500,
    1.5: 1408250,
    2.5: 1341583,
    3.5: 1264083,
    4.5: 1203666,
  },
  {
    1: 1433650,
    2: 1379483,
    3: 1296150,
    4: 1226150,
    5: 1168650,
    "revenu brut": 3876000,
    trimf: 1500,
    1.5: 1408650,
    2.5: 1341983,
    3.5: 1264483,
    4.5: 1204066,
  },
  {
    1: 1434050,
    2: 1379883,
    3: 1296550,
    4: 1226550,
    5: 1169050,
    "revenu brut": 3877000,
    trimf: 1500,
    1.5: 1409050,
    2.5: 1342383,
    3.5: 1264883,
    4.5: 1204466,
  },
  {
    1: 1434450,
    2: 1380283,
    3: 1296950,
    4: 1226950,
    5: 1169450,
    "revenu brut": 3878000,
    trimf: 1500,
    1.5: 1409450,
    2.5: 1342783,
    3.5: 1265283,
    4.5: 1204866,
  },
  {
    1: 1434850,
    2: 1380683,
    3: 1297350,
    4: 1227350,
    5: 1169850,
    "revenu brut": 3879000,
    trimf: 1500,
    1.5: 1409850,
    2.5: 1343183,
    3.5: 1265683,
    4.5: 1205266,
  },
  {
    1: 1435250,
    2: 1381083,
    3: 1297750,
    4: 1227750,
    5: 1170250,
    "revenu brut": 3880000,
    trimf: 1500,
    1.5: 1410250,
    2.5: 1343583,
    3.5: 1266083,
    4.5: 1205666,
  },
  {
    1: 1435650,
    2: 1381483,
    3: 1298150,
    4: 1228150,
    5: 1170650,
    "revenu brut": 3881_000,
    trimf: 1500,
    1.5: 1410650,
    2.5: 1343983,
    3.5: 1266483,
    4.5: 1206066,
  },
  {
    1: 1436050,
    2: 1381883,
    3: 1298550,
    4: 1228550,
    5: 1171050,
    "revenu brut": 3882000,
    trimf: 1500,
    1.5: 1411050,
    2.5: 1344383,
    3.5: 1266883,
    4.5: 1206466,
  },
  {
    1: 1436450,
    2: 1382283,
    3: 1298950,
    4: 1228950,
    5: 1171450,
    "revenu brut": 3883000,
    trimf: 1500,
    1.5: 1411450,
    2.5: 1344783,
    3.5: 1267283,
    4.5: 1206866,
  },
  {
    1: 1436850,
    2: 1382683,
    3: 1299350,
    4: 1229350,
    5: 1171850,
    "revenu brut": 3884000,
    trimf: 1500,
    1.5: 1411850,
    2.5: 1345183,
    3.5: 1267683,
    4.5: 1207266,
  },
  {
    1: 1437250,
    2: 1383083,
    3: 1299750,
    4: 1229750,
    5: 1172250,
    "revenu brut": 3885000,
    trimf: 1500,
    1.5: 1412250,
    2.5: 1345583,
    3.5: 1268083,
    4.5: 1207666,
  },
  {
    1: 1437650,
    2: 1383483,
    3: 1300150,
    4: 1230150,
    5: 1172650,
    "revenu brut": 3886000,
    trimf: 1500,
    1.5: 1412650,
    2.5: 1345983,
    3.5: 1268483,
    4.5: 1208066,
  },
  {
    1: 1438050,
    2: 1383883,
    3: 1300550,
    4: 1230550,
    5: 1173050,
    "revenu brut": 3887000,
    trimf: 1500,
    1.5: 1413050,
    2.5: 1346383,
    3.5: 1268883,
    4.5: 1208466,
  },
  {
    1: 1438450,
    2: 1384283,
    3: 1300950,
    4: 1230950,
    5: 1173450,
    "revenu brut": 3888000,
    trimf: 1500,
    1.5: 1413450,
    2.5: 1346783,
    3.5: 1269283,
    4.5: 1208866,
  },
  {
    1: 1438850,
    2: 1384683,
    3: 1301350,
    4: 1231350,
    5: 1173850,
    "revenu brut": 3889000,
    trimf: 1500,
    1.5: 1413850,
    2.5: 1347183,
    3.5: 1269683,
    4.5: 1209266,
  },
  {
    1: 1439250,
    2: 1385083,
    3: 1301750,
    4: 1231750,
    5: 1174250,
    "revenu brut": 3890000,
    trimf: 1500,
    1.5: 1414250,
    2.5: 1347583,
    3.5: 1270083,
    4.5: 1209666,
  },
  {
    1: 1439650,
    2: 1385483,
    3: 1302150,
    4: 1232150,
    5: 1174650,
    "revenu brut": 3891_000,
    trimf: 1500,
    1.5: 1414650,
    2.5: 1347983,
    3.5: 1270483,
    4.5: 1210066,
  },
  {
    1: 1440050,
    2: 1385883,
    3: 1302550,
    4: 1232550,
    5: 1175050,
    "revenu brut": 3892000,
    trimf: 1500,
    1.5: 1415050,
    2.5: 1348383,
    3.5: 1270883,
    4.5: 1210466,
  },
  {
    1: 1440450,
    2: 1386283,
    3: 1302950,
    4: 1232950,
    5: 1175450,
    "revenu brut": 3893000,
    trimf: 1500,
    1.5: 1415450,
    2.5: 1348783,
    3.5: 1271283,
    4.5: 1210866,
  },
  {
    1: 1440850,
    2: 1386683,
    3: 1303350,
    4: 1233350,
    5: 1175850,
    "revenu brut": 3894000,
    trimf: 1500,
    1.5: 1415850,
    2.5: 1349183,
    3.5: 1271683,
    4.5: 1211266,
  },
  {
    1: 1441250,
    2: 1387083,
    3: 1303750,
    4: 1233750,
    5: 1176250,
    "revenu brut": 3895000,
    trimf: 1500,
    1.5: 1416250,
    2.5: 1349583,
    3.5: 1272083,
    4.5: 1211666,
  },
  {
    1: 1441650,
    2: 1387483,
    3: 1304150,
    4: 1234150,
    5: 1176650,
    "revenu brut": 3896000,
    trimf: 1500,
    1.5: 1416650,
    2.5: 1349983,
    3.5: 1272483,
    4.5: 1212066,
  },
  {
    1: 1442050,
    2: 1387883,
    3: 1304550,
    4: 1234550,
    5: 1177050,
    "revenu brut": 3897000,
    trimf: 1500,
    1.5: 1417050,
    2.5: 1350383,
    3.5: 1272883,
    4.5: 1212466,
  },
  {
    1: 1442450,
    2: 1388283,
    3: 1304950,
    4: 1234950,
    5: 1177450,
    "revenu brut": 3898000,
    trimf: 1500,
    1.5: 1417450,
    2.5: 1350783,
    3.5: 1273283,
    4.5: 1212866,
  },
  {
    1: 1442850,
    2: 1388683,
    3: 1305350,
    4: 1235350,
    5: 1177850,
    "revenu brut": 3899000,
    trimf: 1500,
    1.5: 1417850,
    2.5: 1351183,
    3.5: 1273683,
    4.5: 1213266,
  },
  {
    1: 1443250,
    2: 1389083,
    3: 1305750,
    4: 1235750,
    5: 1178250,
    "revenu brut": 3900000,
    trimf: 1500,
    1.5: 1418250,
    2.5: 1351583,
    3.5: 1274083,
    4.5: 1213666,
  },
  {
    1: 1443650,
    2: 1389483,
    3: 1306150,
    4: 1236150,
    5: 1178650,
    "revenu brut": 3901_000,
    trimf: 1500,
    1.5: 1418650,
    2.5: 1351983,
    3.5: 1274483,
    4.5: 1214066,
  },
  {
    1: 1444050,
    2: 1389883,
    3: 1306550,
    4: 1236550,
    5: 1179050,
    "revenu brut": 3902000,
    trimf: 1500,
    1.5: 1419050,
    2.5: 1352383,
    3.5: 1274883,
    4.5: 1214466,
  },
  {
    1: 1444450,
    2: 1390283,
    3: 1306950,
    4: 1236950,
    5: 1179450,
    "revenu brut": 3903000,
    trimf: 1500,
    1.5: 1419450,
    2.5: 1352783,
    3.5: 1275283,
    4.5: 1214866,
  },
  {
    1: 1444850,
    2: 1390683,
    3: 1307350,
    4: 1237350,
    5: 1179850,
    "revenu brut": 3904000,
    trimf: 1500,
    1.5: 1419850,
    2.5: 1353183,
    3.5: 1275683,
    4.5: 1215266,
  },
  {
    1: 1445250,
    2: 1391083,
    3: 1307750,
    4: 1237750,
    5: 1180250,
    "revenu brut": 3905000,
    trimf: 1500,
    1.5: 1420250,
    2.5: 1353583,
    3.5: 1276083,
    4.5: 1215666,
  },
  {
    1: 1445650,
    2: 1391483,
    3: 1308150,
    4: 1238150,
    5: 1180650,
    "revenu brut": 3906000,
    trimf: 1500,
    1.5: 1420650,
    2.5: 1353983,
    3.5: 1276483,
    4.5: 1216066,
  },
  {
    1: 1446050,
    2: 1391883,
    3: 1308550,
    4: 1238550,
    5: 1181050,
    "revenu brut": 3907000,
    trimf: 1500,
    1.5: 1421050,
    2.5: 1354383,
    3.5: 1276883,
    4.5: 1216466,
  },
  {
    1: 1446450,
    2: 1392283,
    3: 1308950,
    4: 1238950,
    5: 1181450,
    "revenu brut": 3908000,
    trimf: 1500,
    1.5: 1421450,
    2.5: 1354783,
    3.5: 1277283,
    4.5: 1216866,
  },
  {
    1: 1446850,
    2: 1392683,
    3: 1309350,
    4: 1239350,
    5: 1181850,
    "revenu brut": 3909000,
    trimf: 1500,
    1.5: 1421850,
    2.5: 1355183,
    3.5: 1277683,
    4.5: 1217266,
  },
  {
    1: 1447250,
    2: 1393083,
    3: 1309750,
    4: 1239750,
    5: 1182250,
    "revenu brut": 391_0000,
    trimf: 1500,
    1.5: 1422250,
    2.5: 1355583,
    3.5: 1278083,
    4.5: 1217666,
  },
  {
    1: 1447650,
    2: 1393483,
    3: 1310150,
    4: 1240150,
    5: 1182650,
    "revenu brut": 3911_000,
    trimf: 1500,
    1.5: 1422650,
    2.5: 1355983,
    3.5: 1278483,
    4.5: 1218066,
  },
  {
    1: 1448050,
    2: 1393883,
    3: 1310550,
    4: 1240550,
    5: 1183050,
    "revenu brut": 3912000,
    trimf: 1500,
    1.5: 1423050,
    2.5: 1356383,
    3.5: 1278883,
    4.5: 1218466,
  },
  {
    1: 1448450,
    2: 1394283,
    3: 1310950,
    4: 1240950,
    5: 1183450,
    "revenu brut": 3913000,
    trimf: 1500,
    1.5: 1423450,
    2.5: 1356783,
    3.5: 1279283,
    4.5: 1218866,
  },
  {
    1: 1448850,
    2: 1394683,
    3: 1311350,
    4: 1241350,
    5: 1183850,
    "revenu brut": 3914000,
    trimf: 1500,
    1.5: 1423850,
    2.5: 1357183,
    3.5: 1279683,
    4.5: 1219266,
  },
  {
    1: 1449250,
    2: 1395083,
    3: 1311750,
    4: 1241750,
    5: 1184250,
    "revenu brut": 3915000,
    trimf: 1500,
    1.5: 1424250,
    2.5: 1357583,
    3.5: 1280083,
    4.5: 1219666,
  },
  {
    1: 1449650,
    2: 1395483,
    3: 1312150,
    4: 1242150,
    5: 1184650,
    "revenu brut": 3916000,
    trimf: 1500,
    1.5: 1424650,
    2.5: 1357983,
    3.5: 1280483,
    4.5: 1220066,
  },
  {
    1: 1450050,
    2: 1395883,
    3: 1312550,
    4: 1242550,
    5: 1185050,
    "revenu brut": 3917000,
    trimf: 1500,
    1.5: 1425050,
    2.5: 1358383,
    3.5: 1280883,
    4.5: 1220466,
  },
  {
    1: 1450450,
    2: 1396283,
    3: 1312950,
    4: 1242950,
    5: 1185450,
    "revenu brut": 3918000,
    trimf: 1500,
    1.5: 1425450,
    2.5: 1358783,
    3.5: 1281283,
    4.5: 1220866,
  },
  {
    1: 1450850,
    2: 1396683,
    3: 1313350,
    4: 1243350,
    5: 1185850,
    "revenu brut": 3919000,
    trimf: 1500,
    1.5: 1425850,
    2.5: 1359183,
    3.5: 1281683,
    4.5: 1221266,
  },
  {
    1: 1451250,
    2: 1397083,
    3: 1313750,
    4: 1243750,
    5: 1186250,
    "revenu brut": 3920000,
    trimf: 1500,
    1.5: 1426250,
    2.5: 1359583,
    3.5: 1282083,
    4.5: 1221666,
  },
  {
    1: 1451650,
    2: 1397483,
    3: 1314150,
    4: 1244150,
    5: 1186650,
    "revenu brut": 3921_000,
    trimf: 1500,
    1.5: 1426650,
    2.5: 1359983,
    3.5: 1282483,
    4.5: 1222066,
  },
  {
    1: 1452050,
    2: 1397883,
    3: 1314550,
    4: 1244550,
    5: 1187050,
    "revenu brut": 3922000,
    trimf: 1500,
    1.5: 1427050,
    2.5: 1360383,
    3.5: 1282883,
    4.5: 1222466,
  },
  {
    1: 1452450,
    2: 1398283,
    3: 1314950,
    4: 1244950,
    5: 1187450,
    "revenu brut": 3923000,
    trimf: 1500,
    1.5: 1427450,
    2.5: 1360783,
    3.5: 1283283,
    4.5: 1222866,
  },
  {
    1: 1452850,
    2: 1398683,
    3: 1315350,
    4: 1245350,
    5: 1187850,
    "revenu brut": 3924000,
    trimf: 1500,
    1.5: 1427850,
    2.5: 1361183,
    3.5: 1283683,
    4.5: 1223266,
  },
  {
    1: 1453250,
    2: 1399083,
    3: 1315750,
    4: 1245750,
    5: 1188250,
    "revenu brut": 3925000,
    trimf: 1500,
    1.5: 1428250,
    2.5: 1361583,
    3.5: 1284083,
    4.5: 1223666,
  },
  {
    1: 1453650,
    2: 1399483,
    3: 1316150,
    4: 1246150,
    5: 1188650,
    "revenu brut": 3926000,
    trimf: 1500,
    1.5: 1428650,
    2.5: 1361983,
    3.5: 1284483,
    4.5: 1224066,
  },
  {
    1: 1454050,
    2: 1399883,
    3: 1316550,
    4: 1246550,
    5: 1189050,
    "revenu brut": 3927000,
    trimf: 1500,
    1.5: 1429050,
    2.5: 1362383,
    3.5: 1284883,
    4.5: 1224466,
  },
  {
    1: 1454450,
    2: 1400283,
    3: 1316950,
    4: 1246950,
    5: 1189450,
    "revenu brut": 3928000,
    trimf: 1500,
    1.5: 1429450,
    2.5: 1362783,
    3.5: 1285283,
    4.5: 1224866,
  },
  {
    1: 1454850,
    2: 1400683,
    3: 1317350,
    4: 1247350,
    5: 1189850,
    "revenu brut": 3929000,
    trimf: 1500,
    1.5: 1429850,
    2.5: 1363183,
    3.5: 1285683,
    4.5: 1225266,
  },
  {
    1: 1455250,
    2: 1401083,
    3: 1317750,
    4: 1247750,
    5: 1190250,
    "revenu brut": 3930000,
    trimf: 1500,
    1.5: 1430250,
    2.5: 1363583,
    3.5: 1286083,
    4.5: 1225666,
  },
  {
    1: 1455650,
    2: 1401483,
    3: 1318150,
    4: 1248150,
    5: 1190650,
    "revenu brut": 3931_000,
    trimf: 1500,
    1.5: 1430650,
    2.5: 1363983,
    3.5: 1286483,
    4.5: 1226066,
  },
  {
    1: 1456050,
    2: 1401883,
    3: 1318550,
    4: 1248550,
    5: 1191050,
    "revenu brut": 3932000,
    trimf: 1500,
    1.5: 1431050,
    2.5: 1364383,
    3.5: 1286883,
    4.5: 1226466,
  },
  {
    1: 1456450,
    2: 1402283,
    3: 1318950,
    4: 1248950,
    5: 1191450,
    "revenu brut": 3933000,
    trimf: 1500,
    1.5: 1431450,
    2.5: 1364783,
    3.5: 1287283,
    4.5: 1226866,
  },
  {
    1: 1456850,
    2: 1402683,
    3: 1319350,
    4: 1249350,
    5: 1191850,
    "revenu brut": 3934000,
    trimf: 1500,
    1.5: 1431850,
    2.5: 1365183,
    3.5: 1287683,
    4.5: 1227266,
  },
  {
    1: 1457250,
    2: 1403083,
    3: 1319750,
    4: 1249750,
    5: 1192250,
    "revenu brut": 3935000,
    trimf: 1500,
    1.5: 1432250,
    2.5: 1365583,
    3.5: 1288083,
    4.5: 1227666,
  },
  {
    1: 1457650,
    2: 1403483,
    3: 1320150,
    4: 1250150,
    5: 1192650,
    "revenu brut": 3936000,
    trimf: 1500,
    1.5: 1432650,
    2.5: 1365983,
    3.5: 1288483,
    4.5: 1228066,
  },
  {
    1: 1458050,
    2: 1403883,
    3: 1320550,
    4: 1250550,
    5: 1193050,
    "revenu brut": 3937000,
    trimf: 1500,
    1.5: 1433050,
    2.5: 1366383,
    3.5: 1288883,
    4.5: 1228466,
  },
  {
    1: 1458450,
    2: 1404283,
    3: 1320950,
    4: 1250950,
    5: 1193450,
    "revenu brut": 3938000,
    trimf: 1500,
    1.5: 1433450,
    2.5: 1366783,
    3.5: 1289283,
    4.5: 1228866,
  },
  {
    1: 1458850,
    2: 1404683,
    3: 1321350,
    4: 1251350,
    5: 1193850,
    "revenu brut": 3939000,
    trimf: 1500,
    1.5: 1433850,
    2.5: 1367183,
    3.5: 1289683,
    4.5: 1229266,
  },
  {
    1: 1459250,
    2: 1405083,
    3: 1321750,
    4: 1251750,
    5: 1194250,
    "revenu brut": 3940000,
    trimf: 1500,
    1.5: 1434250,
    2.5: 1367583,
    3.5: 1290083,
    4.5: 1229666,
  },
  {
    1: 1459650,
    2: 1405483,
    3: 1322150,
    4: 1252150,
    5: 1194650,
    "revenu brut": 3941_000,
    trimf: 1500,
    1.5: 1434650,
    2.5: 1367983,
    3.5: 1290483,
    4.5: 1230066,
  },
  {
    1: 1460050,
    2: 1405883,
    3: 1322550,
    4: 1252550,
    5: 1195050,
    "revenu brut": 3942000,
    trimf: 1500,
    1.5: 1435050,
    2.5: 1368383,
    3.5: 1290883,
    4.5: 1230466,
  },
  {
    1: 1460450,
    2: 1406283,
    3: 1322950,
    4: 1252950,
    5: 1195450,
    "revenu brut": 3943000,
    trimf: 1500,
    1.5: 1435450,
    2.5: 1368783,
    3.5: 1291283,
    4.5: 1230866,
  },
  {
    1: 1460850,
    2: 1406683,
    3: 1323350,
    4: 1253350,
    5: 1195850,
    "revenu brut": 3944000,
    trimf: 1500,
    1.5: 1435850,
    2.5: 1369183,
    3.5: 1291683,
    4.5: 1231266,
  },
  {
    1: 1461250,
    2: 1407083,
    3: 1323750,
    4: 1253750,
    5: 1196250,
    "revenu brut": 3945000,
    trimf: 1500,
    1.5: 1436250,
    2.5: 1369583,
    3.5: 1292083,
    4.5: 1231666,
  },
  {
    1: 1461650,
    2: 1407483,
    3: 1324150,
    4: 1254150,
    5: 1196650,
    "revenu brut": 3946000,
    trimf: 1500,
    1.5: 1436650,
    2.5: 1369983,
    3.5: 1292483,
    4.5: 1232066,
  },
  {
    1: 1462050,
    2: 1407883,
    3: 1324550,
    4: 1254550,
    5: 1197050,
    "revenu brut": 3947000,
    trimf: 1500,
    1.5: 1437050,
    2.5: 1370383,
    3.5: 1292883,
    4.5: 1232466,
  },
  {
    1: 1462450,
    2: 1408283,
    3: 1324950,
    4: 1254950,
    5: 1197450,
    "revenu brut": 3948000,
    trimf: 1500,
    1.5: 1437450,
    2.5: 1370783,
    3.5: 1293283,
    4.5: 1232866,
  },
  {
    1: 1462850,
    2: 1408683,
    3: 1325350,
    4: 1255350,
    5: 1197850,
    "revenu brut": 3949000,
    trimf: 1500,
    1.5: 1437850,
    2.5: 1371183,
    3.5: 1293683,
    4.5: 1233266,
  },
  {
    1: 1463250,
    2: 1409083,
    3: 1325750,
    4: 1255750,
    5: 1198250,
    "revenu brut": 3950000,
    trimf: 1500,
    1.5: 1438250,
    2.5: 1371583,
    3.5: 1294083,
    4.5: 1233666,
  },
  {
    1: 1463650,
    2: 1409483,
    3: 1326150,
    4: 1256150,
    5: 1198650,
    "revenu brut": 3951_000,
    trimf: 1500,
    1.5: 1438650,
    2.5: 1371983,
    3.5: 1294483,
    4.5: 1234066,
  },
  {
    1: 1464050,
    2: 1409883,
    3: 1326550,
    4: 1256550,
    5: 1199050,
    "revenu brut": 3952000,
    trimf: 1500,
    1.5: 1439050,
    2.5: 1372383,
    3.5: 1294883,
    4.5: 1234466,
  },
  {
    1: 1464450,
    2: 1410283,
    3: 1326950,
    4: 1256950,
    5: 1199450,
    "revenu brut": 3953000,
    trimf: 1500,
    1.5: 1439450,
    2.5: 1372783,
    3.5: 1295283,
    4.5: 1234866,
  },
  {
    1: 1464850,
    2: 1410683,
    3: 1327350,
    4: 1257350,
    5: 1199850,
    "revenu brut": 3954000,
    trimf: 1500,
    1.5: 1439850,
    2.5: 1373183,
    3.5: 1295683,
    4.5: 1235266,
  },
  {
    1: 1465250,
    2: 1411083,
    3: 1327750,
    4: 1257750,
    5: 1200250,
    "revenu brut": 3955000,
    trimf: 1500,
    1.5: 1440250,
    2.5: 1373583,
    3.5: 1296083,
    4.5: 1235666,
  },
  {
    1: 1465650,
    2: 1411483,
    3: 1328150,
    4: 1258150,
    5: 1200650,
    "revenu brut": 3956000,
    trimf: 1500,
    1.5: 1440650,
    2.5: 1373983,
    3.5: 1296483,
    4.5: 1236066,
  },
  {
    1: 1466050,
    2: 1411883,
    3: 1328550,
    4: 1258550,
    5: 1201050,
    "revenu brut": 3957000,
    trimf: 1500,
    1.5: 1441050,
    2.5: 1374383,
    3.5: 1296883,
    4.5: 1236466,
  },
  {
    1: 1466450,
    2: 1412283,
    3: 1328950,
    4: 1258950,
    5: 1201450,
    "revenu brut": 3958000,
    trimf: 1500,
    1.5: 1441450,
    2.5: 1374783,
    3.5: 1297283,
    4.5: 1236866,
  },
  {
    1: 1466850,
    2: 1412683,
    3: 1329350,
    4: 1259350,
    5: 1201850,
    "revenu brut": 3959000,
    trimf: 1500,
    1.5: 1441850,
    2.5: 1375183,
    3.5: 1297683,
    4.5: 1237266,
  },
  {
    1: 1467250,
    2: 1413083,
    3: 1329750,
    4: 1259750,
    5: 1202250,
    "revenu brut": 3960000,
    trimf: 1500,
    1.5: 1442250,
    2.5: 1375583,
    3.5: 1298083,
    4.5: 1237666,
  },
  {
    1: 1467650,
    2: 1413483,
    3: 1330150,
    4: 1260150,
    5: 1202650,
    "revenu brut": 3961_000,
    trimf: 1500,
    1.5: 1442650,
    2.5: 1375983,
    3.5: 1298483,
    4.5: 1238066,
  },
  {
    1: 1468050,
    2: 1413883,
    3: 1330550,
    4: 1260550,
    5: 1203050,
    "revenu brut": 3962000,
    trimf: 1500,
    1.5: 1443050,
    2.5: 1376383,
    3.5: 1298883,
    4.5: 1238466,
  },
  {
    1: 1468450,
    2: 1414283,
    3: 1330950,
    4: 1260950,
    5: 1203450,
    "revenu brut": 3963000,
    trimf: 1500,
    1.5: 1443450,
    2.5: 1376783,
    3.5: 1299283,
    4.5: 1238866,
  },
  {
    1: 1468850,
    2: 1414683,
    3: 1331350,
    4: 1261350,
    5: 1203850,
    "revenu brut": 3964000,
    trimf: 1500,
    1.5: 1443850,
    2.5: 1377183,
    3.5: 1299683,
    4.5: 1239266,
  },
  {
    1: 1469250,
    2: 1415083,
    3: 1331750,
    4: 1261750,
    5: 1204250,
    "revenu brut": 3965000,
    trimf: 1500,
    1.5: 1444250,
    2.5: 1377583,
    3.5: 1300083,
    4.5: 1239666,
  },
  {
    1: 1469650,
    2: 1415483,
    3: 1332150,
    4: 1262150,
    5: 1204650,
    "revenu brut": 3966000,
    trimf: 1500,
    1.5: 1444650,
    2.5: 1377983,
    3.5: 1300483,
    4.5: 1240066,
  },
  {
    1: 1470050,
    2: 1415883,
    3: 1332550,
    4: 1262550,
    5: 1205050,
    "revenu brut": 3967000,
    trimf: 1500,
    1.5: 1445050,
    2.5: 1378383,
    3.5: 1300883,
    4.5: 1240466,
  },
  {
    1: 1470450,
    2: 1416283,
    3: 1332950,
    4: 1262950,
    5: 1205450,
    "revenu brut": 3968000,
    trimf: 1500,
    1.5: 1445450,
    2.5: 1378783,
    3.5: 1301283,
    4.5: 1240866,
  },
  {
    1: 1470850,
    2: 1416683,
    3: 1333350,
    4: 1263350,
    5: 1205850,
    "revenu brut": 3969000,
    trimf: 1500,
    1.5: 1445850,
    2.5: 1379183,
    3.5: 1301683,
    4.5: 1241266,
  },
  {
    1: 1471250,
    2: 1417083,
    3: 1333750,
    4: 1263750,
    5: 1206250,
    "revenu brut": 3970000,
    trimf: 1500,
    1.5: 1446250,
    2.5: 1379583,
    3.5: 1302083,
    4.5: 1241666,
  },
  {
    1: 1471650,
    2: 1417483,
    3: 1334150,
    4: 1264150,
    5: 1206650,
    "revenu brut": 3971_000,
    trimf: 1500,
    1.5: 1446650,
    2.5: 1379983,
    3.5: 1302483,
    4.5: 1242066,
  },
  {
    1: 1472050,
    2: 1417883,
    3: 1334550,
    4: 1264550,
    5: 1207050,
    "revenu brut": 3972000,
    trimf: 1500,
    1.5: 1447050,
    2.5: 1380383,
    3.5: 1302883,
    4.5: 1242466,
  },
  {
    1: 1472450,
    2: 1418283,
    3: 1334950,
    4: 1264950,
    5: 1207450,
    "revenu brut": 3973000,
    trimf: 1500,
    1.5: 1447450,
    2.5: 1380783,
    3.5: 1303283,
    4.5: 1242866,
  },
  {
    1: 1472850,
    2: 1418683,
    3: 1335350,
    4: 1265350,
    5: 1207850,
    "revenu brut": 3974000,
    trimf: 1500,
    1.5: 1447850,
    2.5: 1381183,
    3.5: 1303683,
    4.5: 1243266,
  },
  {
    1: 1473250,
    2: 1419083,
    3: 1335750,
    4: 1265750,
    5: 1208250,
    "revenu brut": 3975000,
    trimf: 1500,
    1.5: 1448250,
    2.5: 1381583,
    3.5: 1304083,
    4.5: 1243666,
  },
  {
    1: 1473650,
    2: 1419483,
    3: 1336150,
    4: 1266150,
    5: 1208650,
    "revenu brut": 3976000,
    trimf: 1500,
    1.5: 1448650,
    2.5: 1381983,
    3.5: 1304483,
    4.5: 1244066,
  },
  {
    1: 1474050,
    2: 1419883,
    3: 1336550,
    4: 1266550,
    5: 1209050,
    "revenu brut": 3977000,
    trimf: 1500,
    1.5: 1449050,
    2.5: 1382383,
    3.5: 1304883,
    4.5: 1244466,
  },
  {
    1: 1474450,
    2: 1420283,
    3: 1336950,
    4: 1266950,
    5: 1209450,
    "revenu brut": 3978000,
    trimf: 1500,
    1.5: 1449450,
    2.5: 1382783,
    3.5: 1305283,
    4.5: 1244866,
  },
  {
    1: 1474850,
    2: 1420683,
    3: 1337350,
    4: 1267350,
    5: 1209850,
    "revenu brut": 3979000,
    trimf: 1500,
    1.5: 1449850,
    2.5: 1383183,
    3.5: 1305683,
    4.5: 1245266,
  },
  {
    1: 1475250,
    2: 1421083,
    3: 1337750,
    4: 1267750,
    5: 1210250,
    "revenu brut": 3980000,
    trimf: 1500,
    1.5: 1450250,
    2.5: 1383583,
    3.5: 1306083,
    4.5: 1245666,
  },
  {
    1: 1475650,
    2: 1421483,
    3: 1338150,
    4: 1268150,
    5: 1210650,
    "revenu brut": 3981_000,
    trimf: 1500,
    1.5: 1450650,
    2.5: 1383983,
    3.5: 1306483,
    4.5: 1246066,
  },
  {
    1: 1476050,
    2: 1421883,
    3: 1338550,
    4: 1268550,
    5: 1211050,
    "revenu brut": 3982000,
    trimf: 1500,
    1.5: 1451050,
    2.5: 1384383,
    3.5: 1306883,
    4.5: 1246466,
  },
  {
    1: 1476450,
    2: 1422283,
    3: 1338950,
    4: 1268950,
    5: 1211450,
    "revenu brut": 3983000,
    trimf: 1500,
    1.5: 1451450,
    2.5: 1384783,
    3.5: 1307283,
    4.5: 1246866,
  },
  {
    1: 1476850,
    2: 1422683,
    3: 1339350,
    4: 1269350,
    5: 1211850,
    "revenu brut": 3984000,
    trimf: 1500,
    1.5: 1451850,
    2.5: 1385183,
    3.5: 1307683,
    4.5: 1247266,
  },
  {
    1: 1477250,
    2: 1423083,
    3: 1339750,
    4: 1269750,
    5: 1212250,
    "revenu brut": 3985000,
    trimf: 1500,
    1.5: 1452250,
    2.5: 1385583,
    3.5: 1308083,
    4.5: 1247666,
  },
  {
    1: 1477650,
    2: 1423483,
    3: 1340150,
    4: 1270150,
    5: 1212650,
    "revenu brut": 3986000,
    trimf: 1500,
    1.5: 1452650,
    2.5: 1385983,
    3.5: 1308483,
    4.5: 1248066,
  },
  {
    1: 1478050,
    2: 1423883,
    3: 1340550,
    4: 1270550,
    5: 1213050,
    "revenu brut": 3987000,
    trimf: 1500,
    1.5: 1453050,
    2.5: 1386383,
    3.5: 1308883,
    4.5: 1248466,
  },
  {
    1: 1478450,
    2: 1424283,
    3: 1340950,
    4: 1270950,
    5: 1213450,
    "revenu brut": 3988000,
    trimf: 1500,
    1.5: 1453450,
    2.5: 1386783,
    3.5: 1309283,
    4.5: 1248866,
  },
  {
    1: 1478850,
    2: 1424683,
    3: 1341350,
    4: 1271350,
    5: 1213850,
    "revenu brut": 3989000,
    trimf: 1500,
    1.5: 1453850,
    2.5: 1387183,
    3.5: 1309683,
    4.5: 1249266,
  },
  {
    1: 1479250,
    2: 1425083,
    3: 1341750,
    4: 1271750,
    5: 1214250,
    "revenu brut": 3990000,
    trimf: 1500,
    1.5: 1454250,
    2.5: 1387583,
    3.5: 1310083,
    4.5: 1249666,
  },
  {
    1: 1479650,
    2: 1425483,
    3: 1342150,
    4: 1272150,
    5: 1214650,
    "revenu brut": 3991_000,
    trimf: 1500,
    1.5: 1454650,
    2.5: 1387983,
    3.5: 1310483,
    4.5: 1250066,
  },
  {
    1: 1480050,
    2: 1425883,
    3: 1342550,
    4: 1272550,
    5: 1215050,
    "revenu brut": 3992000,
    trimf: 1500,
    1.5: 1455050,
    2.5: 1388383,
    3.5: 1310883,
    4.5: 1250466,
  },
  {
    1: 1480450,
    2: 1426283,
    3: 1342950,
    4: 1272950,
    5: 1215450,
    "revenu brut": 3993000,
    trimf: 1500,
    1.5: 1455450,
    2.5: 1388783,
    3.5: 1311283,
    4.5: 1250866,
  },
  {
    1: 1480850,
    2: 1426683,
    3: 1343350,
    4: 1273350,
    5: 1215850,
    "revenu brut": 3994000,
    trimf: 1500,
    1.5: 1455850,
    2.5: 1389183,
    3.5: 1311683,
    4.5: 1251266,
  },
  {
    1: 1481250,
    2: 1427083,
    3: 1343750,
    4: 1273750,
    5: 1216250,
    "revenu brut": 3995000,
    trimf: 1500,
    1.5: 1456250,
    2.5: 1389583,
    3.5: 1312083,
    4.5: 1251666,
  },
  {
    1: 1481650,
    2: 1427483,
    3: 1344150,
    4: 1274150,
    5: 1216650,
    "revenu brut": 3996000,
    trimf: 1500,
    1.5: 1456650,
    2.5: 1389983,
    3.5: 1312483,
    4.5: 1252066,
  },
  {
    1: 1482050,
    2: 1427883,
    3: 1344550,
    4: 1274550,
    5: 1217050,
    "revenu brut": 3997000,
    trimf: 1500,
    1.5: 1457050,
    2.5: 1390383,
    3.5: 1312883,
    4.5: 1252466,
  },
  {
    1: 1482450,
    2: 1428283,
    3: 1344950,
    4: 1274950,
    5: 1217450,
    "revenu brut": 3998000,
    trimf: 1500,
    1.5: 1457450,
    2.5: 1390783,
    3.5: 1313283,
    4.5: 1252866,
  },
  {
    1: 1482850,
    2: 1428683,
    3: 1345350,
    4: 1275350,
    5: 1217850,
    "revenu brut": 3999000,
    trimf: 1500,
    1.5: 1457850,
    2.5: 1391183,
    3.5: 1313683,
    4.5: 1253266,
  },
  {
    1: 1483250,
    2: 1429083,
    3: 1345750,
    4: 1275750,
    5: 1218250,
    "revenu brut": 4000000,
    trimf: 1500,
    1.5: 1458250,
    2.5: 1391583,
    3.5: 1314083,
    4.5: 1253666,
  },
  {
    1: 1483650,
    2: 1429483,
    3: 1346150,
    4: 1276150,
    5: 1218650,
    "revenu brut": 4001_000,
    trimf: 1500,
    1.5: 1458650,
    2.5: 1391983,
    3.5: 1314483,
    4.5: 1254066,
  },
  {
    1: 1484050,
    2: 1429883,
    3: 1346550,
    4: 1276550,
    5: 1219050,
    "revenu brut": 4002000,
    trimf: 1500,
    1.5: 1459050,
    2.5: 1392383,
    3.5: 1314883,
    4.5: 1254466,
  },
  {
    1: 1484450,
    2: 1430283,
    3: 1346950,
    4: 1276950,
    5: 1219450,
    "revenu brut": 4003000,
    trimf: 1500,
    1.5: 1459450,
    2.5: 1392783,
    3.5: 1315283,
    4.5: 1254866,
  },
  {
    1: 1484850,
    2: 1430683,
    3: 1347350,
    4: 1277350,
    5: 1219850,
    "revenu brut": 4004000,
    trimf: 1500,
    1.5: 1459850,
    2.5: 1393183,
    3.5: 1315683,
    4.5: 1255266,
  },
  {
    1: 1485250,
    2: 1431083,
    3: 1347750,
    4: 1277750,
    5: 1220250,
    "revenu brut": 4005000,
    trimf: 1500,
    1.5: 1460250,
    2.5: 1393583,
    3.5: 1316083,
    4.5: 1255666,
  },
  {
    1: 1485650,
    2: 1431483,
    3: 1348150,
    4: 1278150,
    5: 1220650,
    "revenu brut": 4006000,
    trimf: 1500,
    1.5: 1460650,
    2.5: 1393983,
    3.5: 1316483,
    4.5: 1256066,
  },
  {
    1: 1486050,
    2: 1431883,
    3: 1348550,
    4: 1278550,
    5: 1221050,
    "revenu brut": 4007000,
    trimf: 1500,
    1.5: 1461050,
    2.5: 1394383,
    3.5: 1316883,
    4.5: 1256466,
  },
  {
    1: 1486450,
    2: 1432283,
    3: 1348950,
    4: 1278950,
    5: 1221450,
    "revenu brut": 4008000,
    trimf: 1500,
    1.5: 1461450,
    2.5: 1394783,
    3.5: 1317283,
    4.5: 1256866,
  },
  {
    1: 1486850,
    2: 1432683,
    3: 1349350,
    4: 1279350,
    5: 1221850,
    "revenu brut": 4009000,
    trimf: 1500,
    1.5: 1461850,
    2.5: 1395183,
    3.5: 1317683,
    4.5: 1257266,
  },
  {
    1: 1487250,
    2: 1433083,
    3: 1349750,
    4: 1279750,
    5: 1222250,
    "revenu brut": 401_0000,
    trimf: 1500,
    1.5: 1462250,
    2.5: 1395583,
    3.5: 1318083,
    4.5: 1257666,
  },
  {
    1: 1487650,
    2: 1433483,
    3: 1350150,
    4: 1280150,
    5: 1222650,
    "revenu brut": 4011_000,
    trimf: 1500,
    1.5: 1462650,
    2.5: 1395983,
    3.5: 1318483,
    4.5: 1258066,
  },
  {
    1: 1488050,
    2: 1433883,
    3: 1350550,
    4: 1280550,
    5: 1223050,
    "revenu brut": 4012000,
    trimf: 1500,
    1.5: 1463050,
    2.5: 1396383,
    3.5: 1318883,
    4.5: 1258466,
  },
  {
    1: 1488450,
    2: 1434283,
    3: 1350950,
    4: 1280950,
    5: 1223450,
    "revenu brut": 4013000,
    trimf: 1500,
    1.5: 1463450,
    2.5: 1396783,
    3.5: 1319283,
    4.5: 1258866,
  },
  {
    1: 1488850,
    2: 1434683,
    3: 1351350,
    4: 1281350,
    5: 1223850,
    "revenu brut": 4014000,
    trimf: 1500,
    1.5: 1463850,
    2.5: 1397183,
    3.5: 1319683,
    4.5: 1259266,
  },
  {
    1: 1489250,
    2: 1435083,
    3: 1351750,
    4: 1281750,
    5: 1224250,
    "revenu brut": 4015000,
    trimf: 1500,
    1.5: 1464250,
    2.5: 1397583,
    3.5: 1320083,
    4.5: 1259666,
  },
  {
    1: 1489650,
    2: 1435483,
    3: 1352150,
    4: 1282150,
    5: 1224650,
    "revenu brut": 4016000,
    trimf: 1500,
    1.5: 1464650,
    2.5: 1397983,
    3.5: 1320483,
    4.5: 1260066,
  },
  {
    1: 1490050,
    2: 1435883,
    3: 1352550,
    4: 1282550,
    5: 1225050,
    "revenu brut": 4017000,
    trimf: 1500,
    1.5: 1465050,
    2.5: 1398383,
    3.5: 1320883,
    4.5: 1260466,
  },
  {
    1: 1490450,
    2: 1436283,
    3: 1352950,
    4: 1282950,
    5: 1225450,
    "revenu brut": 4018000,
    trimf: 1500,
    1.5: 1465450,
    2.5: 1398783,
    3.5: 1321283,
    4.5: 1260866,
  },
  {
    1: 1490850,
    2: 1436683,
    3: 1353350,
    4: 1283350,
    5: 1225850,
    "revenu brut": 4019000,
    trimf: 1500,
    1.5: 1465850,
    2.5: 1399183,
    3.5: 1321683,
    4.5: 1261266,
  },
  {
    1: 1491250,
    2: 1437083,
    3: 1353750,
    4: 1283750,
    5: 1226250,
    "revenu brut": 4020000,
    trimf: 1500,
    1.5: 1466250,
    2.5: 1399583,
    3.5: 1322083,
    4.5: 1261666,
  },
  {
    1: 1491650,
    2: 1437483,
    3: 1354150,
    4: 1284150,
    5: 1226650,
    "revenu brut": 4021_000,
    trimf: 1500,
    1.5: 1466650,
    2.5: 1399983,
    3.5: 1322483,
    4.5: 1262066,
  },
  {
    1: 1492050,
    2: 1437883,
    3: 1354550,
    4: 1284550,
    5: 1227050,
    "revenu brut": 4022000,
    trimf: 1500,
    1.5: 1467050,
    2.5: 1400383,
    3.5: 1322883,
    4.5: 1262466,
  },
  {
    1: 1492450,
    2: 1438283,
    3: 1354950,
    4: 1284950,
    5: 1227450,
    "revenu brut": 4023000,
    trimf: 1500,
    1.5: 1467450,
    2.5: 1400783,
    3.5: 1323283,
    4.5: 1262866,
  },
  {
    1: 1492850,
    2: 1438683,
    3: 1355350,
    4: 1285350,
    5: 1227850,
    "revenu brut": 4024000,
    trimf: 1500,
    1.5: 1467850,
    2.5: 1401183,
    3.5: 1323683,
    4.5: 1263266,
  },
  {
    1: 1493250,
    2: 1439083,
    3: 1355750,
    4: 1285750,
    5: 1228250,
    "revenu brut": 4025000,
    trimf: 1500,
    1.5: 1468250,
    2.5: 1401583,
    3.5: 1324083,
    4.5: 1263666,
  },
  {
    1: 1493650,
    2: 1439483,
    3: 1356150,
    4: 1286150,
    5: 1228650,
    "revenu brut": 4026000,
    trimf: 1500,
    1.5: 1468650,
    2.5: 1401983,
    3.5: 1324483,
    4.5: 1264066,
  },
  {
    1: 1494050,
    2: 1439883,
    3: 1356550,
    4: 1286550,
    5: 1229050,
    "revenu brut": 4027000,
    trimf: 1500,
    1.5: 1469050,
    2.5: 1402383,
    3.5: 1324883,
    4.5: 1264466,
  },
  {
    1: 1494450,
    2: 1440283,
    3: 1356950,
    4: 1286950,
    5: 1229450,
    "revenu brut": 4028000,
    trimf: 1500,
    1.5: 1469450,
    2.5: 1402783,
    3.5: 1325283,
    4.5: 1264866,
  },
  {
    1: 1494850,
    2: 1440683,
    3: 1357350,
    4: 1287350,
    5: 1229850,
    "revenu brut": 4029000,
    trimf: 1500,
    1.5: 1469850,
    2.5: 1403183,
    3.5: 1325683,
    4.5: 1265266,
  },
  {
    1: 1495250,
    2: 1441083,
    3: 1357750,
    4: 1287750,
    5: 1230250,
    "revenu brut": 4030000,
    trimf: 1500,
    1.5: 1470250,
    2.5: 1403583,
    3.5: 1326083,
    4.5: 1265666,
  },
  {
    1: 1495650,
    2: 1441483,
    3: 1358150,
    4: 1288150,
    5: 1230650,
    "revenu brut": 4031_000,
    trimf: 1500,
    1.5: 1470650,
    2.5: 1403983,
    3.5: 1326483,
    4.5: 1266066,
  },
  {
    1: 1496050,
    2: 1441883,
    3: 1358550,
    4: 1288550,
    5: 1231050,
    "revenu brut": 4032000,
    trimf: 1500,
    1.5: 1471050,
    2.5: 1404383,
    3.5: 1326883,
    4.5: 1266466,
  },
  {
    1: 1496450,
    2: 1442283,
    3: 1358950,
    4: 1288950,
    5: 1231450,
    "revenu brut": 4033000,
    trimf: 1500,
    1.5: 1471450,
    2.5: 1404783,
    3.5: 1327283,
    4.5: 1266866,
  },
  {
    1: 1496850,
    2: 1442683,
    3: 1359350,
    4: 1289350,
    5: 1231850,
    "revenu brut": 4034000,
    trimf: 1500,
    1.5: 1471850,
    2.5: 1405183,
    3.5: 1327683,
    4.5: 1267266,
  },
  {
    1: 1497250,
    2: 1443083,
    3: 1359750,
    4: 1289750,
    5: 1232250,
    "revenu brut": 4035000,
    trimf: 1500,
    1.5: 1472250,
    2.5: 1405583,
    3.5: 1328083,
    4.5: 1267666,
  },
  {
    1: 1497650,
    2: 1443483,
    3: 1360150,
    4: 1290150,
    5: 1232650,
    "revenu brut": 4036000,
    trimf: 1500,
    1.5: 1472650,
    2.5: 1405983,
    3.5: 1328483,
    4.5: 1268066,
  },
  {
    1: 1498050,
    2: 1443883,
    3: 1360550,
    4: 1290550,
    5: 1233050,
    "revenu brut": 4037000,
    trimf: 1500,
    1.5: 1473050,
    2.5: 1406383,
    3.5: 1328883,
    4.5: 1268466,
  },
  {
    1: 1498450,
    2: 1444283,
    3: 1360950,
    4: 1290950,
    5: 1233450,
    "revenu brut": 4038000,
    trimf: 1500,
    1.5: 1473450,
    2.5: 1406783,
    3.5: 1329283,
    4.5: 1268866,
  },
  {
    1: 1498850,
    2: 1444683,
    3: 1361350,
    4: 1291350,
    5: 1233850,
    "revenu brut": 4039000,
    trimf: 1500,
    1.5: 1473850,
    2.5: 1407183,
    3.5: 1329683,
    4.5: 1269266,
  },
  {
    1: 1499250,
    2: 1445083,
    3: 1361750,
    4: 1291750,
    5: 1234250,
    "revenu brut": 4040000,
    trimf: 1500,
    1.5: 1474250,
    2.5: 1407583,
    3.5: 1330083,
    4.5: 1269666,
  },
  {
    1: 1499650,
    2: 1445483,
    3: 1362150,
    4: 1292150,
    5: 1234650,
    "revenu brut": 4041_000,
    trimf: 1500,
    1.5: 1474650,
    2.5: 1407983,
    3.5: 1330483,
    4.5: 1270066,
  },
  {
    1: 1500050,
    2: 1445883,
    3: 1362550,
    4: 1292550,
    5: 1235050,
    "revenu brut": 4042000,
    trimf: 1500,
    1.5: 1475050,
    2.5: 1408383,
    3.5: 1330883,
    4.5: 1270466,
  },
  {
    1: 1500450,
    2: 1446283,
    3: 1362950,
    4: 1292950,
    5: 1235450,
    "revenu brut": 4043000,
    trimf: 1500,
    1.5: 1475450,
    2.5: 1408783,
    3.5: 1331283,
    4.5: 1270866,
  },
  {
    1: 1500850,
    2: 1446683,
    3: 1363350,
    4: 1293350,
    5: 1235850,
    "revenu brut": 4044000,
    trimf: 1500,
    1.5: 1475850,
    2.5: 1409183,
    3.5: 1331683,
    4.5: 1271266,
  },
  {
    1: 1501250,
    2: 1447083,
    3: 1363750,
    4: 1293750,
    5: 1236250,
    "revenu brut": 4045000,
    trimf: 1500,
    1.5: 1476250,
    2.5: 1409583,
    3.5: 1332083,
    4.5: 1271666,
  },
  {
    1: 1501650,
    2: 1447483,
    3: 1364150,
    4: 1294150,
    5: 1236650,
    "revenu brut": 4046000,
    trimf: 1500,
    1.5: 1476650,
    2.5: 1409983,
    3.5: 1332483,
    4.5: 1272066,
  },
  {
    1: 1502050,
    2: 1447883,
    3: 1364550,
    4: 1294550,
    5: 1237050,
    "revenu brut": 4047000,
    trimf: 1500,
    1.5: 1477050,
    2.5: 1410383,
    3.5: 1332883,
    4.5: 1272466,
  },
  {
    1: 1502450,
    2: 1448283,
    3: 1364950,
    4: 1294950,
    5: 1237450,
    "revenu brut": 4048000,
    trimf: 1500,
    1.5: 1477450,
    2.5: 1410783,
    3.5: 1333283,
    4.5: 1272866,
  },
  {
    1: 1502850,
    2: 1448683,
    3: 1365350,
    4: 1295350,
    5: 1237850,
    "revenu brut": 4049000,
    trimf: 1500,
    1.5: 1477850,
    2.5: 1411183,
    3.5: 1333683,
    4.5: 1273266,
  },
  {
    1: 1503250,
    2: 1449083,
    3: 1365750,
    4: 1295750,
    5: 1238250,
    "revenu brut": 4050000,
    trimf: 1500,
    1.5: 1478250,
    2.5: 1411583,
    3.5: 1334083,
    4.5: 1273666,
  },
  {
    1: 1503650,
    2: 1449483,
    3: 1366150,
    4: 1296150,
    5: 1238650,
    "revenu brut": 4051_000,
    trimf: 1500,
    1.5: 1478650,
    2.5: 1411983,
    3.5: 1334483,
    4.5: 1274066,
  },
  {
    1: 1504050,
    2: 1449883,
    3: 1366550,
    4: 1296550,
    5: 1239050,
    "revenu brut": 4052000,
    trimf: 1500,
    1.5: 1479050,
    2.5: 1412383,
    3.5: 1334883,
    4.5: 1274466,
  },
  {
    1: 1504450,
    2: 1450283,
    3: 1366950,
    4: 1296950,
    5: 1239450,
    "revenu brut": 4053000,
    trimf: 1500,
    1.5: 1479450,
    2.5: 1412783,
    3.5: 1335283,
    4.5: 1274866,
  },
  {
    1: 1504850,
    2: 1450683,
    3: 1367350,
    4: 1297350,
    5: 1239850,
    "revenu brut": 4054000,
    trimf: 1500,
    1.5: 1479850,
    2.5: 1413183,
    3.5: 1335683,
    4.5: 1275266,
  },
  {
    1: 1505250,
    2: 1451083,
    3: 1367750,
    4: 1297750,
    5: 1240250,
    "revenu brut": 4055000,
    trimf: 1500,
    1.5: 1480250,
    2.5: 1413583,
    3.5: 1336083,
    4.5: 1275666,
  },
  {
    1: 1505650,
    2: 1451483,
    3: 1368150,
    4: 1298150,
    5: 1240650,
    "revenu brut": 4056000,
    trimf: 1500,
    1.5: 1480650,
    2.5: 1413983,
    3.5: 1336483,
    4.5: 1276066,
  },
  {
    1: 1506050,
    2: 1451883,
    3: 1368550,
    4: 1298550,
    5: 1241050,
    "revenu brut": 4057000,
    trimf: 1500,
    1.5: 1481050,
    2.5: 1414383,
    3.5: 1336883,
    4.5: 1276466,
  },
  {
    1: 1506450,
    2: 1452283,
    3: 1368950,
    4: 1298950,
    5: 1241450,
    "revenu brut": 4058000,
    trimf: 1500,
    1.5: 1481450,
    2.5: 1414783,
    3.5: 1337283,
    4.5: 1276866,
  },
  {
    1: 1506850,
    2: 1452683,
    3: 1369350,
    4: 1299350,
    5: 1241850,
    "revenu brut": 4059000,
    trimf: 1500,
    1.5: 1481850,
    2.5: 1415183,
    3.5: 1337683,
    4.5: 1277266,
  },
  {
    1: 1507250,
    2: 1453083,
    3: 1369750,
    4: 1299750,
    5: 1242250,
    "revenu brut": 4060000,
    trimf: 1500,
    1.5: 1482250,
    2.5: 1415583,
    3.5: 1338083,
    4.5: 1277666,
  },
  {
    1: 1507650,
    2: 1453483,
    3: 1370150,
    4: 1300150,
    5: 1242650,
    "revenu brut": 4061_000,
    trimf: 1500,
    1.5: 1482650,
    2.5: 1415983,
    3.5: 1338483,
    4.5: 1278066,
  },
  {
    1: 1508050,
    2: 1453883,
    3: 1370550,
    4: 1300550,
    5: 1243050,
    "revenu brut": 4062000,
    trimf: 1500,
    1.5: 1483050,
    2.5: 1416383,
    3.5: 1338883,
    4.5: 1278466,
  },
  {
    1: 1508450,
    2: 1454283,
    3: 1370950,
    4: 1300950,
    5: 1243450,
    "revenu brut": 4063000,
    trimf: 1500,
    1.5: 1483450,
    2.5: 1416783,
    3.5: 1339283,
    4.5: 1278866,
  },
  {
    1: 1508850,
    2: 1454683,
    3: 1371350,
    4: 1301350,
    5: 1243850,
    "revenu brut": 4064000,
    trimf: 1500,
    1.5: 1483850,
    2.5: 1417183,
    3.5: 1339683,
    4.5: 1279266,
  },
  {
    1: 1509250,
    2: 1455083,
    3: 1371750,
    4: 1301750,
    5: 1244250,
    "revenu brut": 4065000,
    trimf: 1500,
    1.5: 1484250,
    2.5: 1417583,
    3.5: 1340083,
    4.5: 1279666,
  },
  {
    1: 1509650,
    2: 1455483,
    3: 1372150,
    4: 1302150,
    5: 1244650,
    "revenu brut": 4066000,
    trimf: 1500,
    1.5: 1484650,
    2.5: 1417983,
    3.5: 1340483,
    4.5: 1280066,
  },
  {
    1: 1510050,
    2: 1455883,
    3: 1372550,
    4: 1302550,
    5: 1245050,
    "revenu brut": 4067000,
    trimf: 1500,
    1.5: 1485050,
    2.5: 1418383,
    3.5: 1340883,
    4.5: 1280466,
  },
  {
    1: 1510450,
    2: 1456283,
    3: 1372950,
    4: 1302950,
    5: 1245450,
    "revenu brut": 4068000,
    trimf: 1500,
    1.5: 1485450,
    2.5: 1418783,
    3.5: 1341283,
    4.5: 1280866,
  },
  {
    1: 1510850,
    2: 1456683,
    3: 1373350,
    4: 1303350,
    5: 1245850,
    "revenu brut": 4069000,
    trimf: 1500,
    1.5: 1485850,
    2.5: 1419183,
    3.5: 1341683,
    4.5: 1281266,
  },
  {
    1: 1511250,
    2: 1457083,
    3: 1373750,
    4: 1303750,
    5: 1246250,
    "revenu brut": 4070000,
    trimf: 1500,
    1.5: 1486250,
    2.5: 1419583,
    3.5: 1342083,
    4.5: 1281666,
  },
  {
    1: 1511650,
    2: 1457483,
    3: 1374150,
    4: 1304150,
    5: 1246650,
    "revenu brut": 4071_000,
    trimf: 1500,
    1.5: 1486650,
    2.5: 1419983,
    3.5: 1342483,
    4.5: 1282066,
  },
  {
    1: 1512050,
    2: 1457883,
    3: 1374550,
    4: 1304550,
    5: 1247050,
    "revenu brut": 4072000,
    trimf: 1500,
    1.5: 1487050,
    2.5: 1420383,
    3.5: 1342883,
    4.5: 1282466,
  },
  {
    1: 1512450,
    2: 1458283,
    3: 1374950,
    4: 1304950,
    5: 1247450,
    "revenu brut": 4073000,
    trimf: 1500,
    1.5: 1487450,
    2.5: 1420783,
    3.5: 1343283,
    4.5: 1282866,
  },
  {
    1: 1512850,
    2: 1458683,
    3: 1375350,
    4: 1305350,
    5: 1247850,
    "revenu brut": 4074000,
    trimf: 1500,
    1.5: 1487850,
    2.5: 1421183,
    3.5: 1343683,
    4.5: 1283266,
  },
  {
    1: 1513250,
    2: 1459083,
    3: 1375750,
    4: 1305750,
    5: 1248250,
    "revenu brut": 4075000,
    trimf: 1500,
    1.5: 1488250,
    2.5: 1421583,
    3.5: 1344083,
    4.5: 1283666,
  },
  {
    1: 1513650,
    2: 1459483,
    3: 1376150,
    4: 1306150,
    5: 1248650,
    "revenu brut": 4076000,
    trimf: 1500,
    1.5: 1488650,
    2.5: 1421983,
    3.5: 1344483,
    4.5: 1284066,
  },
  {
    1: 1514050,
    2: 1459883,
    3: 1376550,
    4: 1306550,
    5: 1249050,
    "revenu brut": 4077000,
    trimf: 1500,
    1.5: 1489050,
    2.5: 1422383,
    3.5: 1344883,
    4.5: 1284466,
  },
  {
    1: 1514450,
    2: 1460283,
    3: 1376950,
    4: 1306950,
    5: 1249450,
    "revenu brut": 4078000,
    trimf: 1500,
    1.5: 1489450,
    2.5: 1422783,
    3.5: 1345283,
    4.5: 1284866,
  },
  {
    1: 1514850,
    2: 1460683,
    3: 1377350,
    4: 1307350,
    5: 1249850,
    "revenu brut": 4079000,
    trimf: 1500,
    1.5: 1489850,
    2.5: 1423183,
    3.5: 1345683,
    4.5: 1285266,
  },
  {
    1: 1515250,
    2: 1461083,
    3: 1377750,
    4: 1307750,
    5: 1250250,
    "revenu brut": 4080000,
    trimf: 1500,
    1.5: 1490250,
    2.5: 1423583,
    3.5: 1346083,
    4.5: 1285666,
  },
  {
    1: 1515650,
    2: 1461483,
    3: 1378150,
    4: 1308150,
    5: 1250650,
    "revenu brut": 4081_000,
    trimf: 1500,
    1.5: 1490650,
    2.5: 1423983,
    3.5: 1346483,
    4.5: 1286066,
  },
  {
    1: 1516050,
    2: 1461883,
    3: 1378550,
    4: 1308550,
    5: 1251050,
    "revenu brut": 4082000,
    trimf: 1500,
    1.5: 1491050,
    2.5: 1424383,
    3.5: 1346883,
    4.5: 1286466,
  },
  {
    1: 1516450,
    2: 1462283,
    3: 1378950,
    4: 1308950,
    5: 1251450,
    "revenu brut": 4083000,
    trimf: 1500,
    1.5: 1491450,
    2.5: 1424783,
    3.5: 1347283,
    4.5: 1286866,
  },
  {
    1: 1516850,
    2: 1462683,
    3: 1379350,
    4: 1309350,
    5: 1251850,
    "revenu brut": 4084000,
    trimf: 1500,
    1.5: 1491850,
    2.5: 1425183,
    3.5: 1347683,
    4.5: 1287266,
  },
  {
    1: 1517250,
    2: 1463083,
    3: 1379750,
    4: 1309750,
    5: 1252250,
    "revenu brut": 4085000,
    trimf: 1500,
    1.5: 1492250,
    2.5: 1425583,
    3.5: 1348083,
    4.5: 1287666,
  },
  {
    1: 1517650,
    2: 1463483,
    3: 1380150,
    4: 1310150,
    5: 1252650,
    "revenu brut": 4086000,
    trimf: 1500,
    1.5: 1492650,
    2.5: 1425983,
    3.5: 1348483,
    4.5: 1288066,
  },
  {
    1: 1518050,
    2: 1463883,
    3: 1380550,
    4: 1310550,
    5: 1253050,
    "revenu brut": 4087000,
    trimf: 1500,
    1.5: 1493050,
    2.5: 1426383,
    3.5: 1348883,
    4.5: 1288466,
  },
  {
    1: 1518450,
    2: 1464283,
    3: 1380950,
    4: 1310950,
    5: 1253450,
    "revenu brut": 4088000,
    trimf: 1500,
    1.5: 1493450,
    2.5: 1426783,
    3.5: 1349283,
    4.5: 1288866,
  },
  {
    1: 1518850,
    2: 1464683,
    3: 1381350,
    4: 1311350,
    5: 1253850,
    "revenu brut": 4089000,
    trimf: 1500,
    1.5: 1493850,
    2.5: 1427183,
    3.5: 1349683,
    4.5: 1289266,
  },
  {
    1: 1519250,
    2: 1465083,
    3: 1381750,
    4: 1311750,
    5: 1254250,
    "revenu brut": 4090000,
    trimf: 1500,
    1.5: 1494250,
    2.5: 1427583,
    3.5: 1350083,
    4.5: 1289666,
  },
  {
    1: 1519650,
    2: 1465483,
    3: 1382150,
    4: 1312150,
    5: 1254650,
    "revenu brut": 4091_000,
    trimf: 1500,
    1.5: 1494650,
    2.5: 1427983,
    3.5: 1350483,
    4.5: 1290066,
  },
  {
    1: 1520050,
    2: 1465883,
    3: 1382550,
    4: 1312550,
    5: 1255050,
    "revenu brut": 4092000,
    trimf: 1500,
    1.5: 1495050,
    2.5: 1428383,
    3.5: 1350883,
    4.5: 1290466,
  },
  {
    1: 1520450,
    2: 1466283,
    3: 1382950,
    4: 1312950,
    5: 1255450,
    "revenu brut": 4093000,
    trimf: 1500,
    1.5: 1495450,
    2.5: 1428783,
    3.5: 1351283,
    4.5: 1290866,
  },
  {
    1: 1520850,
    2: 1466683,
    3: 1383350,
    4: 1313350,
    5: 1255850,
    "revenu brut": 4094000,
    trimf: 1500,
    1.5: 1495850,
    2.5: 1429183,
    3.5: 1351683,
    4.5: 1291266,
  },
  {
    1: 1521250,
    2: 1467083,
    3: 1383750,
    4: 1313750,
    5: 1256250,
    "revenu brut": 4095000,
    trimf: 1500,
    1.5: 1496250,
    2.5: 1429583,
    3.5: 1352083,
    4.5: 1291666,
  },
  {
    1: 1521650,
    2: 1467483,
    3: 1384150,
    4: 1314150,
    5: 1256650,
    "revenu brut": 4096000,
    trimf: 1500,
    1.5: 1496650,
    2.5: 1429983,
    3.5: 1352483,
    4.5: 1292066,
  },
  {
    1: 1522050,
    2: 1467883,
    3: 1384550,
    4: 1314550,
    5: 1257050,
    "revenu brut": 4097000,
    trimf: 1500,
    1.5: 1497050,
    2.5: 1430383,
    3.5: 1352883,
    4.5: 1292466,
  },
  {
    1: 1522450,
    2: 1468283,
    3: 1384950,
    4: 1314950,
    5: 1257450,
    "revenu brut": 4098000,
    trimf: 1500,
    1.5: 1497450,
    2.5: 1430783,
    3.5: 1353283,
    4.5: 1292866,
  },
  {
    1: 1522850,
    2: 1468683,
    3: 1385350,
    4: 1315350,
    5: 1257850,
    "revenu brut": 4099000,
    trimf: 1500,
    1.5: 1497850,
    2.5: 1431183,
    3.5: 1353683,
    4.5: 1293266,
  },
  {
    1: 1523250,
    2: 1469083,
    3: 1385750,
    4: 1315750,
    5: 1258250,
    "revenu brut": 41_00000,
    trimf: 1500,
    1.5: 1498250,
    2.5: 1431583,
    3.5: 1354083,
    4.5: 1293666,
  },
  {
    1: 1523650,
    2: 1469483,
    3: 1386150,
    4: 1316150,
    5: 1258650,
    "revenu brut": 4101_000,
    trimf: 1500,
    1.5: 1498650,
    2.5: 1431983,
    3.5: 1354483,
    4.5: 1294066,
  },
  {
    1: 1524050,
    2: 1469883,
    3: 1386550,
    4: 1316550,
    5: 1259050,
    "revenu brut": 4102000,
    trimf: 1500,
    1.5: 1499050,
    2.5: 1432383,
    3.5: 1354883,
    4.5: 1294466,
  },
  {
    1: 1524450,
    2: 1470283,
    3: 1386950,
    4: 1316950,
    5: 1259450,
    "revenu brut": 4103000,
    trimf: 1500,
    1.5: 1499450,
    2.5: 1432783,
    3.5: 1355283,
    4.5: 1294866,
  },
  {
    1: 1524850,
    2: 1470683,
    3: 1387350,
    4: 1317350,
    5: 1259850,
    "revenu brut": 4104000,
    trimf: 1500,
    1.5: 1499850,
    2.5: 1433183,
    3.5: 1355683,
    4.5: 1295266,
  },
  {
    1: 1525250,
    2: 1471083,
    3: 1387750,
    4: 1317750,
    5: 1260250,
    "revenu brut": 4105000,
    trimf: 1500,
    1.5: 1500250,
    2.5: 1433583,
    3.5: 1356083,
    4.5: 1295666,
  },
  {
    1: 1525650,
    2: 1471483,
    3: 1388150,
    4: 1318150,
    5: 1260650,
    "revenu brut": 4106000,
    trimf: 1500,
    1.5: 1500650,
    2.5: 1433983,
    3.5: 1356483,
    4.5: 1296066,
  },
  {
    1: 1526050,
    2: 1471883,
    3: 1388550,
    4: 1318550,
    5: 1261050,
    "revenu brut": 4107000,
    trimf: 1500,
    1.5: 1501050,
    2.5: 1434383,
    3.5: 1356883,
    4.5: 1296466,
  },
  {
    1: 1526450,
    2: 1472283,
    3: 1388950,
    4: 1318950,
    5: 1261450,
    "revenu brut": 4108000,
    trimf: 1500,
    1.5: 1501450,
    2.5: 1434783,
    3.5: 1357283,
    4.5: 1296866,
  },
  {
    1: 1526850,
    2: 1472683,
    3: 1389350,
    4: 1319350,
    5: 1261850,
    "revenu brut": 4109000,
    trimf: 1500,
    1.5: 1501850,
    2.5: 1435183,
    3.5: 1357683,
    4.5: 1297266,
  },
  {
    1: 1527250,
    2: 1473083,
    3: 1389750,
    4: 1319750,
    5: 1262250,
    "revenu brut": 411_0000,
    trimf: 1500,
    1.5: 1502250,
    2.5: 1435583,
    3.5: 1358083,
    4.5: 1297666,
  },
  {
    1: 1527650,
    2: 1473483,
    3: 1390150,
    4: 1320150,
    5: 1262650,
    "revenu brut": 4111_000,
    trimf: 1500,
    1.5: 1502650,
    2.5: 1435983,
    3.5: 1358483,
    4.5: 1298066,
  },
  {
    1: 1528050,
    2: 1473883,
    3: 1390550,
    4: 1320550,
    5: 1263050,
    "revenu brut": 4112000,
    trimf: 1500,
    1.5: 1503050,
    2.5: 1436383,
    3.5: 1358883,
    4.5: 1298466,
  },
  {
    1: 1528450,
    2: 1474283,
    3: 1390950,
    4: 1320950,
    5: 1263450,
    "revenu brut": 4113000,
    trimf: 1500,
    1.5: 1503450,
    2.5: 1436783,
    3.5: 1359283,
    4.5: 1298866,
  },
  {
    1: 1528850,
    2: 1474683,
    3: 1391350,
    4: 1321350,
    5: 1263850,
    "revenu brut": 4114000,
    trimf: 1500,
    1.5: 1503850,
    2.5: 1437183,
    3.5: 1359683,
    4.5: 1299266,
  },
  {
    1: 1529250,
    2: 1475083,
    3: 1391750,
    4: 1321750,
    5: 1264250,
    "revenu brut": 4115000,
    trimf: 1500,
    1.5: 1504250,
    2.5: 1437583,
    3.5: 1360083,
    4.5: 1299666,
  },
  {
    1: 1529650,
    2: 1475483,
    3: 1392150,
    4: 1322150,
    5: 1264650,
    "revenu brut": 4116000,
    trimf: 1500,
    1.5: 1504650,
    2.5: 1437983,
    3.5: 1360483,
    4.5: 1300066,
  },
  {
    1: 1530050,
    2: 1475883,
    3: 1392550,
    4: 1322550,
    5: 1265050,
    "revenu brut": 4117000,
    trimf: 1500,
    1.5: 1505050,
    2.5: 1438383,
    3.5: 1360883,
    4.5: 1300466,
  },
  {
    1: 1530450,
    2: 1476283,
    3: 1392950,
    4: 1322950,
    5: 1265450,
    "revenu brut": 4118000,
    trimf: 1500,
    1.5: 1505450,
    2.5: 1438783,
    3.5: 1361283,
    4.5: 1300866,
  },
  {
    1: 1530850,
    2: 1476683,
    3: 1393350,
    4: 1323350,
    5: 1265850,
    "revenu brut": 4119000,
    trimf: 1500,
    1.5: 1505850,
    2.5: 1439183,
    3.5: 1361683,
    4.5: 1301266,
  },
  {
    1: 1531250,
    2: 1477083,
    3: 1393750,
    4: 1323750,
    5: 1266250,
    "revenu brut": 4120000,
    trimf: 1500,
    1.5: 1506250,
    2.5: 1439583,
    3.5: 1362083,
    4.5: 1301666,
  },
  {
    1: 1531650,
    2: 1477483,
    3: 1394150,
    4: 1324150,
    5: 1266650,
    "revenu brut": 4121_000,
    trimf: 1500,
    1.5: 1506650,
    2.5: 1439983,
    3.5: 1362483,
    4.5: 1302066,
  },
  {
    1: 1532050,
    2: 1477883,
    3: 1394550,
    4: 1324550,
    5: 1267050,
    "revenu brut": 4122000,
    trimf: 1500,
    1.5: 1507050,
    2.5: 1440383,
    3.5: 1362883,
    4.5: 1302466,
  },
  {
    1: 1532450,
    2: 1478283,
    3: 1394950,
    4: 1324950,
    5: 1267450,
    "revenu brut": 4123000,
    trimf: 1500,
    1.5: 1507450,
    2.5: 1440783,
    3.5: 1363283,
    4.5: 1302866,
  },
  {
    1: 1532850,
    2: 1478683,
    3: 1395350,
    4: 1325350,
    5: 1267850,
    "revenu brut": 4124000,
    trimf: 1500,
    1.5: 1507850,
    2.5: 1441183,
    3.5: 1363683,
    4.5: 1303266,
  },
  {
    1: 1533250,
    2: 1479083,
    3: 1395750,
    4: 1325750,
    5: 1268250,
    "revenu brut": 4125000,
    trimf: 1500,
    1.5: 1508250,
    2.5: 1441583,
    3.5: 1364083,
    4.5: 1303666,
  },
  {
    1: 1533650,
    2: 1479483,
    3: 1396150,
    4: 1326150,
    5: 1268650,
    "revenu brut": 4126000,
    trimf: 1500,
    1.5: 1508650,
    2.5: 1441983,
    3.5: 1364483,
    4.5: 1304066,
  },
  {
    1: 1534050,
    2: 1479883,
    3: 1396550,
    4: 1326550,
    5: 1269050,
    "revenu brut": 4127000,
    trimf: 1500,
    1.5: 1509050,
    2.5: 1442383,
    3.5: 1364883,
    4.5: 1304466,
  },
  {
    1: 1534450,
    2: 1480283,
    3: 1396950,
    4: 1326950,
    5: 1269450,
    "revenu brut": 4128000,
    trimf: 1500,
    1.5: 1509450,
    2.5: 1442783,
    3.5: 1365283,
    4.5: 1304866,
  },
  {
    1: 1534850,
    2: 1480683,
    3: 1397350,
    4: 1327350,
    5: 1269850,
    "revenu brut": 4129000,
    trimf: 1500,
    1.5: 1509850,
    2.5: 1443183,
    3.5: 1365683,
    4.5: 1305266,
  },
  {
    1: 1535250,
    2: 1481083,
    3: 1397750,
    4: 1327750,
    5: 1270250,
    "revenu brut": 4130000,
    trimf: 1500,
    1.5: 1510250,
    2.5: 1443583,
    3.5: 1366083,
    4.5: 1305666,
  },
  {
    1: 1535650,
    2: 1481483,
    3: 1398150,
    4: 1328150,
    5: 1270650,
    "revenu brut": 4131_000,
    trimf: 1500,
    1.5: 1510650,
    2.5: 1443983,
    3.5: 1366483,
    4.5: 1306066,
  },
  {
    1: 1536050,
    2: 1481883,
    3: 1398550,
    4: 1328550,
    5: 1271050,
    "revenu brut": 4132000,
    trimf: 1500,
    1.5: 1511050,
    2.5: 1444383,
    3.5: 1366883,
    4.5: 1306466,
  },
  {
    1: 1536450,
    2: 1482283,
    3: 1398950,
    4: 1328950,
    5: 1271450,
    "revenu brut": 4133000,
    trimf: 1500,
    1.5: 1511450,
    2.5: 1444783,
    3.5: 1367283,
    4.5: 1306866,
  },
  {
    1: 1536850,
    2: 1482683,
    3: 1399350,
    4: 1329350,
    5: 1271850,
    "revenu brut": 4134000,
    trimf: 1500,
    1.5: 1511850,
    2.5: 1445183,
    3.5: 1367683,
    4.5: 1307266,
  },
  {
    1: 1537250,
    2: 1483083,
    3: 1399750,
    4: 1329750,
    5: 1272250,
    "revenu brut": 4135000,
    trimf: 1500,
    1.5: 1512250,
    2.5: 1445583,
    3.5: 1368083,
    4.5: 1307666,
  },
  {
    1: 1537650,
    2: 1483483,
    3: 1400150,
    4: 1330150,
    5: 1272650,
    "revenu brut": 4136000,
    trimf: 1500,
    1.5: 1512650,
    2.5: 1445983,
    3.5: 1368483,
    4.5: 1308066,
  },
  {
    1: 1538050,
    2: 1483883,
    3: 1400550,
    4: 1330550,
    5: 1273050,
    "revenu brut": 4137000,
    trimf: 1500,
    1.5: 1513050,
    2.5: 1446383,
    3.5: 1368883,
    4.5: 1308466,
  },
  {
    1: 1538450,
    2: 1484283,
    3: 1400950,
    4: 1330950,
    5: 1273450,
    "revenu brut": 4138000,
    trimf: 1500,
    1.5: 1513450,
    2.5: 1446783,
    3.5: 1369283,
    4.5: 1308866,
  },
  {
    1: 1538850,
    2: 1484683,
    3: 1401350,
    4: 1331350,
    5: 1273850,
    "revenu brut": 4139000,
    trimf: 1500,
    1.5: 1513850,
    2.5: 1447183,
    3.5: 1369683,
    4.5: 1309266,
  },
  {
    1: 1539250,
    2: 1485083,
    3: 1401750,
    4: 1331750,
    5: 1274250,
    "revenu brut": 4140000,
    trimf: 1500,
    1.5: 1514250,
    2.5: 1447583,
    3.5: 1370083,
    4.5: 1309666,
  },
  {
    1: 1539650,
    2: 1485483,
    3: 1402150,
    4: 1332150,
    5: 1274650,
    "revenu brut": 4141_000,
    trimf: 1500,
    1.5: 1514650,
    2.5: 1447983,
    3.5: 1370483,
    4.5: 1310066,
  },
  {
    1: 1540050,
    2: 1485883,
    3: 1402550,
    4: 1332550,
    5: 1275050,
    "revenu brut": 4142000,
    trimf: 1500,
    1.5: 1515050,
    2.5: 1448383,
    3.5: 1370883,
    4.5: 1310466,
  },
  {
    1: 1540450,
    2: 1486283,
    3: 1402950,
    4: 1332950,
    5: 1275450,
    "revenu brut": 4143000,
    trimf: 1500,
    1.5: 1515450,
    2.5: 1448783,
    3.5: 1371283,
    4.5: 1310866,
  },
  {
    1: 1540850,
    2: 1486683,
    3: 1403350,
    4: 1333350,
    5: 1275850,
    "revenu brut": 4144000,
    trimf: 1500,
    1.5: 1515850,
    2.5: 1449183,
    3.5: 1371683,
    4.5: 1311266,
  },
  {
    1: 1541250,
    2: 1487083,
    3: 1403750,
    4: 1333750,
    5: 1276250,
    "revenu brut": 4145000,
    trimf: 1500,
    1.5: 1516250,
    2.5: 1449583,
    3.5: 1372083,
    4.5: 1311666,
  },
  {
    1: 1541650,
    2: 1487483,
    3: 1404150,
    4: 1334150,
    5: 1276650,
    "revenu brut": 4146000,
    trimf: 1500,
    1.5: 1516650,
    2.5: 1449983,
    3.5: 1372483,
    4.5: 1312066,
  },
  {
    1: 1542050,
    2: 1487883,
    3: 1404550,
    4: 1334550,
    5: 1277050,
    "revenu brut": 4147000,
    trimf: 1500,
    1.5: 1517050,
    2.5: 1450383,
    3.5: 1372883,
    4.5: 1312466,
  },
  {
    1: 1542450,
    2: 1488283,
    3: 1404950,
    4: 1334950,
    5: 1277450,
    "revenu brut": 4148000,
    trimf: 1500,
    1.5: 1517450,
    2.5: 1450783,
    3.5: 1373283,
    4.5: 1312866,
  },
  {
    1: 1542850,
    2: 1488683,
    3: 1405350,
    4: 1335350,
    5: 1277850,
    "revenu brut": 4149000,
    trimf: 1500,
    1.5: 1517850,
    2.5: 1451183,
    3.5: 1373683,
    4.5: 1313266,
  },
  {
    1: 1543250,
    2: 1489083,
    3: 1405750,
    4: 1335750,
    5: 1278250,
    "revenu brut": 4150000,
    trimf: 1500,
    1.5: 1518250,
    2.5: 1451583,
    3.5: 1374083,
    4.5: 1313666,
  },
  {
    1: 1543650,
    2: 1489483,
    3: 1406150,
    4: 1336150,
    5: 1278650,
    "revenu brut": 4151_000,
    trimf: 1500,
    1.5: 1518650,
    2.5: 1451983,
    3.5: 1374483,
    4.5: 1314066,
  },
  {
    1: 1544050,
    2: 1489883,
    3: 1406550,
    4: 1336550,
    5: 1279050,
    "revenu brut": 4152000,
    trimf: 1500,
    1.5: 1519050,
    2.5: 1452383,
    3.5: 1374883,
    4.5: 1314466,
  },
  {
    1: 1544450,
    2: 1490283,
    3: 1406950,
    4: 1336950,
    5: 1279450,
    "revenu brut": 4153000,
    trimf: 1500,
    1.5: 1519450,
    2.5: 1452783,
    3.5: 1375283,
    4.5: 1314866,
  },
  {
    1: 1544850,
    2: 1490683,
    3: 1407350,
    4: 1337350,
    5: 1279850,
    "revenu brut": 4154000,
    trimf: 1500,
    1.5: 1519850,
    2.5: 1453183,
    3.5: 1375683,
    4.5: 1315266,
  },
  {
    1: 1545250,
    2: 1491083,
    3: 1407750,
    4: 1337750,
    5: 1280250,
    "revenu brut": 4155000,
    trimf: 1500,
    1.5: 1520250,
    2.5: 1453583,
    3.5: 1376083,
    4.5: 1315666,
  },
  {
    1: 1545650,
    2: 1491483,
    3: 1408150,
    4: 1338150,
    5: 1280650,
    "revenu brut": 4156000,
    trimf: 1500,
    1.5: 1520650,
    2.5: 1453983,
    3.5: 1376483,
    4.5: 1316066,
  },
  {
    1: 1546050,
    2: 1491883,
    3: 1408550,
    4: 1338550,
    5: 1281050,
    "revenu brut": 4157000,
    trimf: 1500,
    1.5: 1521050,
    2.5: 1454383,
    3.5: 1376883,
    4.5: 1316466,
  },
  {
    1: 1546450,
    2: 1492283,
    3: 1408950,
    4: 1338950,
    5: 1281450,
    "revenu brut": 4158000,
    trimf: 1500,
    1.5: 1521450,
    2.5: 1454783,
    3.5: 1377283,
    4.5: 1316866,
  },
  {
    1: 1546850,
    2: 1492683,
    3: 1409350,
    4: 1339350,
    5: 1281850,
    "revenu brut": 4159000,
    trimf: 1500,
    1.5: 1521850,
    2.5: 1455183,
    3.5: 1377683,
    4.5: 1317266,
  },
  {
    1: 1547250,
    2: 1493083,
    3: 1409750,
    4: 1339750,
    5: 1282250,
    "revenu brut": 4160000,
    trimf: 1500,
    1.5: 1522250,
    2.5: 1455583,
    3.5: 1378083,
    4.5: 1317666,
  },
  {
    1: 1547650,
    2: 1493483,
    3: 1410150,
    4: 1340150,
    5: 1282650,
    "revenu brut": 4161_000,
    trimf: 1500,
    1.5: 1522650,
    2.5: 1455983,
    3.5: 1378483,
    4.5: 1318066,
  },
  {
    1: 1548050,
    2: 1493883,
    3: 1410550,
    4: 1340550,
    5: 1283050,
    "revenu brut": 4162000,
    trimf: 1500,
    1.5: 1523050,
    2.5: 1456383,
    3.5: 1378883,
    4.5: 1318466,
  },
  {
    1: 1548450,
    2: 1494283,
    3: 1410950,
    4: 1340950,
    5: 1283450,
    "revenu brut": 4163000,
    trimf: 1500,
    1.5: 1523450,
    2.5: 1456783,
    3.5: 1379283,
    4.5: 1318866,
  },
  {
    1: 1548850,
    2: 1494683,
    3: 1411350,
    4: 1341350,
    5: 1283850,
    "revenu brut": 4164000,
    trimf: 1500,
    1.5: 1523850,
    2.5: 1457183,
    3.5: 1379683,
    4.5: 1319266,
  },
  {
    1: 1549250,
    2: 1495083,
    3: 1411750,
    4: 1341750,
    5: 1284250,
    "revenu brut": 4165000,
    trimf: 1500,
    1.5: 1524250,
    2.5: 1457583,
    3.5: 1380083,
    4.5: 1319666,
  },
  {
    1: 1549650,
    2: 1495483,
    3: 1412150,
    4: 1342150,
    5: 1284650,
    "revenu brut": 4166000,
    trimf: 1500,
    1.5: 1524650,
    2.5: 1457983,
    3.5: 1380483,
    4.5: 1320066,
  },
  {
    1: 1550050,
    2: 1495883,
    3: 1412550,
    4: 1342550,
    5: 1285050,
    "revenu brut": 4167000,
    trimf: 1500,
    1.5: 1525050,
    2.5: 1458383,
    3.5: 1380883,
    4.5: 1320466,
  },
  {
    1: 1550450,
    2: 1496283,
    3: 1412950,
    4: 1342950,
    5: 1285450,
    "revenu brut": 4168000,
    trimf: 1500,
    1.5: 1525450,
    2.5: 1458783,
    3.5: 1381283,
    4.5: 1320866,
  },
  {
    1: 1550850,
    2: 1496683,
    3: 1413350,
    4: 1343350,
    5: 1285850,
    "revenu brut": 4169000,
    trimf: 1500,
    1.5: 1525850,
    2.5: 1459183,
    3.5: 1381683,
    4.5: 1321266,
  },
  {
    1: 1551250,
    2: 1497083,
    3: 1413750,
    4: 1343750,
    5: 1286250,
    "revenu brut": 4170000,
    trimf: 1500,
    1.5: 1526250,
    2.5: 1459583,
    3.5: 1382083,
    4.5: 1321666,
  },
  {
    1: 1551650,
    2: 1497483,
    3: 1414150,
    4: 1344150,
    5: 1286650,
    "revenu brut": 4171_000,
    trimf: 1500,
    1.5: 1526650,
    2.5: 1459983,
    3.5: 1382483,
    4.5: 1322066,
  },
  {
    1: 1552050,
    2: 1497883,
    3: 1414550,
    4: 1344550,
    5: 1287050,
    "revenu brut": 4172000,
    trimf: 1500,
    1.5: 1527050,
    2.5: 1460383,
    3.5: 1382883,
    4.5: 1322466,
  },
  {
    1: 1552450,
    2: 1498283,
    3: 1414950,
    4: 1344950,
    5: 1287450,
    "revenu brut": 4173000,
    trimf: 1500,
    1.5: 1527450,
    2.5: 1460783,
    3.5: 1383283,
    4.5: 1322866,
  },
  {
    1: 1552850,
    2: 1498683,
    3: 1415350,
    4: 1345350,
    5: 1287850,
    "revenu brut": 4174000,
    trimf: 1500,
    1.5: 1527850,
    2.5: 1461183,
    3.5: 1383683,
    4.5: 1323266,
  },
  {
    1: 1553250,
    2: 1499083,
    3: 1415750,
    4: 1345750,
    5: 1288250,
    "revenu brut": 4175000,
    trimf: 1500,
    1.5: 1528250,
    2.5: 1461583,
    3.5: 1384083,
    4.5: 1323666,
  },
  {
    1: 1553650,
    2: 1499483,
    3: 1416150,
    4: 1346150,
    5: 1288650,
    "revenu brut": 4176000,
    trimf: 1500,
    1.5: 1528650,
    2.5: 1461983,
    3.5: 1384483,
    4.5: 1324066,
  },
  {
    1: 1554050,
    2: 1499883,
    3: 1416550,
    4: 1346550,
    5: 1289050,
    "revenu brut": 4177000,
    trimf: 1500,
    1.5: 1529050,
    2.5: 1462383,
    3.5: 1384883,
    4.5: 1324466,
  },
  {
    1: 1554450,
    2: 1500283,
    3: 1416950,
    4: 1346950,
    5: 1289450,
    "revenu brut": 4178000,
    trimf: 1500,
    1.5: 1529450,
    2.5: 1462783,
    3.5: 1385283,
    4.5: 1324866,
  },
  {
    1: 1554850,
    2: 1500683,
    3: 1417350,
    4: 1347350,
    5: 1289850,
    "revenu brut": 4179000,
    trimf: 1500,
    1.5: 1529850,
    2.5: 1463183,
    3.5: 1385683,
    4.5: 1325266,
  },
  {
    1: 1555250,
    2: 1501083,
    3: 1417750,
    4: 1347750,
    5: 1290250,
    "revenu brut": 4180000,
    trimf: 1500,
    1.5: 1530250,
    2.5: 1463583,
    3.5: 1386083,
    4.5: 1325666,
  },
  {
    1: 1555650,
    2: 1501483,
    3: 1418150,
    4: 1348150,
    5: 1290650,
    "revenu brut": 4181_000,
    trimf: 1500,
    1.5: 1530650,
    2.5: 1463983,
    3.5: 1386483,
    4.5: 1326066,
  },
  {
    1: 1556050,
    2: 1501883,
    3: 1418550,
    4: 1348550,
    5: 1291050,
    "revenu brut": 4182000,
    trimf: 1500,
    1.5: 1531050,
    2.5: 1464383,
    3.5: 1386883,
    4.5: 1326466,
  },
  {
    1: 1556450,
    2: 1502283,
    3: 1418950,
    4: 1348950,
    5: 1291450,
    "revenu brut": 4183000,
    trimf: 1500,
    1.5: 1531450,
    2.5: 1464783,
    3.5: 1387283,
    4.5: 1326866,
  },
  {
    1: 1556850,
    2: 1502683,
    3: 1419350,
    4: 1349350,
    5: 1291850,
    "revenu brut": 4184000,
    trimf: 1500,
    1.5: 1531850,
    2.5: 1465183,
    3.5: 1387683,
    4.5: 1327266,
  },
  {
    1: 1557250,
    2: 1503083,
    3: 1419750,
    4: 1349750,
    5: 1292250,
    "revenu brut": 4185000,
    trimf: 1500,
    1.5: 1532250,
    2.5: 1465583,
    3.5: 1388083,
    4.5: 1327666,
  },
  {
    1: 1557650,
    2: 1503483,
    3: 1420150,
    4: 1350150,
    5: 1292650,
    "revenu brut": 4186000,
    trimf: 1500,
    1.5: 1532650,
    2.5: 1465983,
    3.5: 1388483,
    4.5: 1328066,
  },
  {
    1: 1558050,
    2: 1503883,
    3: 1420550,
    4: 1350550,
    5: 1293050,
    "revenu brut": 4187000,
    trimf: 1500,
    1.5: 1533050,
    2.5: 1466383,
    3.5: 1388883,
    4.5: 1328466,
  },
  {
    1: 1558450,
    2: 1504283,
    3: 1420950,
    4: 1350950,
    5: 1293450,
    "revenu brut": 4188000,
    trimf: 1500,
    1.5: 1533450,
    2.5: 1466783,
    3.5: 1389283,
    4.5: 1328866,
  },
  {
    1: 1558850,
    2: 1504683,
    3: 1421350,
    4: 1351350,
    5: 1293850,
    "revenu brut": 4189000,
    trimf: 1500,
    1.5: 1533850,
    2.5: 1467183,
    3.5: 1389683,
    4.5: 1329266,
  },
  {
    1: 1559250,
    2: 1505083,
    3: 1421750,
    4: 1351750,
    5: 1294250,
    "revenu brut": 4190000,
    trimf: 1500,
    1.5: 1534250,
    2.5: 1467583,
    3.5: 1390083,
    4.5: 1329666,
  },
  {
    1: 1559650,
    2: 1505483,
    3: 1422150,
    4: 1352150,
    5: 1294650,
    "revenu brut": 4191_000,
    trimf: 1500,
    1.5: 1534650,
    2.5: 1467983,
    3.5: 1390483,
    4.5: 1330066,
  },
  {
    1: 1560050,
    2: 1505883,
    3: 1422550,
    4: 1352550,
    5: 1295050,
    "revenu brut": 4192000,
    trimf: 1500,
    1.5: 1535050,
    2.5: 1468383,
    3.5: 1390883,
    4.5: 1330466,
  },
  {
    1: 1560450,
    2: 1506283,
    3: 1422950,
    4: 1352950,
    5: 1295450,
    "revenu brut": 4193000,
    trimf: 1500,
    1.5: 1535450,
    2.5: 1468783,
    3.5: 1391283,
    4.5: 1330866,
  },
  {
    1: 1560850,
    2: 1506683,
    3: 1423350,
    4: 1353350,
    5: 1295850,
    "revenu brut": 4194000,
    trimf: 1500,
    1.5: 1535850,
    2.5: 1469183,
    3.5: 1391683,
    4.5: 1331266,
  },
  {
    1: 1561250,
    2: 1507083,
    3: 1423750,
    4: 1353750,
    5: 1296250,
    "revenu brut": 4195000,
    trimf: 1500,
    1.5: 1536250,
    2.5: 1469583,
    3.5: 1392083,
    4.5: 1331666,
  },
  {
    1: 1561650,
    2: 1507483,
    3: 1424150,
    4: 1354150,
    5: 1296650,
    "revenu brut": 4196000,
    trimf: 1500,
    1.5: 1536650,
    2.5: 1469983,
    3.5: 1392483,
    4.5: 1332066,
  },
  {
    1: 1562050,
    2: 1507883,
    3: 1424550,
    4: 1354550,
    5: 1297050,
    "revenu brut": 4197000,
    trimf: 1500,
    1.5: 1537050,
    2.5: 1470383,
    3.5: 1392883,
    4.5: 1332466,
  },
  {
    1: 1562450,
    2: 1508283,
    3: 1424950,
    4: 1354950,
    5: 1297450,
    "revenu brut": 4198000,
    trimf: 1500,
    1.5: 1537450,
    2.5: 1470783,
    3.5: 1393283,
    4.5: 1332866,
  },
  {
    1: 1562850,
    2: 1508683,
    3: 1425350,
    4: 1355350,
    5: 1297850,
    "revenu brut": 4199000,
    trimf: 1500,
    1.5: 1537850,
    2.5: 1471183,
    3.5: 1393683,
    4.5: 1333266,
  },
  {
    1: 1563250,
    2: 1509083,
    3: 1425750,
    4: 1355750,
    5: 1298250,
    "revenu brut": 4200000,
    trimf: 1500,
    1.5: 1538250,
    2.5: 1471583,
    3.5: 1394083,
    4.5: 1333666,
  },
  {
    1: 1563650,
    2: 1509483,
    3: 1426150,
    4: 1356150,
    5: 1298650,
    "revenu brut": 4201_000,
    trimf: 1500,
    1.5: 1538650,
    2.5: 1471983,
    3.5: 1394483,
    4.5: 1334066,
  },
  {
    1: 1564050,
    2: 1509883,
    3: 1426550,
    4: 1356550,
    5: 1299050,
    "revenu brut": 4202000,
    trimf: 1500,
    1.5: 1539050,
    2.5: 1472383,
    3.5: 1394883,
    4.5: 1334466,
  },
  {
    1: 1564450,
    2: 1510283,
    3: 1426950,
    4: 1356950,
    5: 1299450,
    "revenu brut": 4203000,
    trimf: 1500,
    1.5: 1539450,
    2.5: 1472783,
    3.5: 1395283,
    4.5: 1334866,
  },
  {
    1: 1564850,
    2: 1510683,
    3: 1427350,
    4: 1357350,
    5: 1299850,
    "revenu brut": 4204000,
    trimf: 1500,
    1.5: 1539850,
    2.5: 1473183,
    3.5: 1395683,
    4.5: 1335266,
  },
  {
    1: 1565250,
    2: 1511083,
    3: 1427750,
    4: 1357750,
    5: 1300250,
    "revenu brut": 4205000,
    trimf: 1500,
    1.5: 1540250,
    2.5: 1473583,
    3.5: 1396083,
    4.5: 1335666,
  },
  {
    1: 1565650,
    2: 1511483,
    3: 1428150,
    4: 1358150,
    5: 1300650,
    "revenu brut": 4206000,
    trimf: 1500,
    1.5: 1540650,
    2.5: 1473983,
    3.5: 1396483,
    4.5: 1336066,
  },
  {
    1: 1566050,
    2: 1511883,
    3: 1428550,
    4: 1358550,
    5: 1301050,
    "revenu brut": 4207000,
    trimf: 1500,
    1.5: 1541050,
    2.5: 1474383,
    3.5: 1396883,
    4.5: 1336466,
  },
  {
    1: 1566450,
    2: 1512283,
    3: 1428950,
    4: 1358950,
    5: 1301450,
    "revenu brut": 4208000,
    trimf: 1500,
    1.5: 1541450,
    2.5: 1474783,
    3.5: 1397283,
    4.5: 1336866,
  },
  {
    1: 1566850,
    2: 1512683,
    3: 1429350,
    4: 1359350,
    5: 1301850,
    "revenu brut": 4209000,
    trimf: 1500,
    1.5: 1541850,
    2.5: 1475183,
    3.5: 1397683,
    4.5: 1337266,
  },
  {
    1: 1567250,
    2: 1513083,
    3: 1429750,
    4: 1359750,
    5: 1302250,
    "revenu brut": 421_0000,
    trimf: 1500,
    1.5: 1542250,
    2.5: 1475583,
    3.5: 1398083,
    4.5: 1337666,
  },
  {
    1: 1567650,
    2: 1513483,
    3: 1430150,
    4: 1360150,
    5: 1302650,
    "revenu brut": 4211_000,
    trimf: 1500,
    1.5: 1542650,
    2.5: 1475983,
    3.5: 1398483,
    4.5: 1338066,
  },
  {
    1: 1568050,
    2: 1513883,
    3: 1430550,
    4: 1360550,
    5: 1303050,
    "revenu brut": 4212000,
    trimf: 1500,
    1.5: 1543050,
    2.5: 1476383,
    3.5: 1398883,
    4.5: 1338466,
  },
  {
    1: 1568450,
    2: 1514283,
    3: 1430950,
    4: 1360950,
    5: 1303450,
    "revenu brut": 4213000,
    trimf: 1500,
    1.5: 1543450,
    2.5: 1476783,
    3.5: 1399283,
    4.5: 1338866,
  },
  {
    1: 1568850,
    2: 1514683,
    3: 1431350,
    4: 1361350,
    5: 1303850,
    "revenu brut": 4214000,
    trimf: 1500,
    1.5: 1543850,
    2.5: 1477183,
    3.5: 1399683,
    4.5: 1339266,
  },
  {
    1: 1569250,
    2: 1515083,
    3: 1431750,
    4: 1361750,
    5: 1304250,
    "revenu brut": 4215000,
    trimf: 1500,
    1.5: 1544250,
    2.5: 1477583,
    3.5: 1400083,
    4.5: 1339666,
  },
  {
    1: 1569650,
    2: 1515483,
    3: 1432150,
    4: 1362150,
    5: 1304650,
    "revenu brut": 4216000,
    trimf: 1500,
    1.5: 1544650,
    2.5: 1477983,
    3.5: 1400483,
    4.5: 1340066,
  },
  {
    1: 1570050,
    2: 1515883,
    3: 1432550,
    4: 1362550,
    5: 1305050,
    "revenu brut": 4217000,
    trimf: 1500,
    1.5: 1545050,
    2.5: 1478383,
    3.5: 1400883,
    4.5: 1340466,
  },
  {
    1: 1570450,
    2: 1516283,
    3: 1432950,
    4: 1362950,
    5: 1305450,
    "revenu brut": 4218000,
    trimf: 1500,
    1.5: 1545450,
    2.5: 1478783,
    3.5: 1401283,
    4.5: 1340866,
  },
  {
    1: 1570850,
    2: 1516683,
    3: 1433350,
    4: 1363350,
    5: 1305850,
    "revenu brut": 4219000,
    trimf: 1500,
    1.5: 1545850,
    2.5: 1479183,
    3.5: 1401683,
    4.5: 1341266,
  },
  {
    1: 1571250,
    2: 1517083,
    3: 1433750,
    4: 1363750,
    5: 1306250,
    "revenu brut": 4220000,
    trimf: 1500,
    1.5: 1546250,
    2.5: 1479583,
    3.5: 1402083,
    4.5: 1341666,
  },
  {
    1: 1571650,
    2: 1517483,
    3: 1434150,
    4: 1364150,
    5: 1306650,
    "revenu brut": 4221_000,
    trimf: 1500,
    1.5: 1546650,
    2.5: 1479983,
    3.5: 1402483,
    4.5: 1342066,
  },
  {
    1: 1572050,
    2: 1517883,
    3: 1434550,
    4: 1364550,
    5: 1307050,
    "revenu brut": 4222000,
    trimf: 1500,
    1.5: 1547050,
    2.5: 1480383,
    3.5: 1402883,
    4.5: 1342466,
  },
  {
    1: 1572450,
    2: 1518283,
    3: 1434950,
    4: 1364950,
    5: 1307450,
    "revenu brut": 4223000,
    trimf: 1500,
    1.5: 1547450,
    2.5: 1480783,
    3.5: 1403283,
    4.5: 1342866,
  },
  {
    1: 1572850,
    2: 1518683,
    3: 1435350,
    4: 1365350,
    5: 1307850,
    "revenu brut": 4224000,
    trimf: 1500,
    1.5: 1547850,
    2.5: 1481183,
    3.5: 1403683,
    4.5: 1343266,
  },
  {
    1: 1573250,
    2: 1519083,
    3: 1435750,
    4: 1365750,
    5: 1308250,
    "revenu brut": 4225000,
    trimf: 1500,
    1.5: 1548250,
    2.5: 1481583,
    3.5: 1404083,
    4.5: 1343666,
  },
  {
    1: 1573650,
    2: 1519483,
    3: 1436150,
    4: 1366150,
    5: 1308650,
    "revenu brut": 4226000,
    trimf: 1500,
    1.5: 1548650,
    2.5: 1481983,
    3.5: 1404483,
    4.5: 1344066,
  },
  {
    1: 1574050,
    2: 1519883,
    3: 1436550,
    4: 1366550,
    5: 1309050,
    "revenu brut": 4227000,
    trimf: 1500,
    1.5: 1549050,
    2.5: 1482383,
    3.5: 1404883,
    4.5: 1344466,
  },
  {
    1: 1574450,
    2: 1520283,
    3: 1436950,
    4: 1366950,
    5: 1309450,
    "revenu brut": 4228000,
    trimf: 1500,
    1.5: 1549450,
    2.5: 1482783,
    3.5: 1405283,
    4.5: 1344866,
  },
  {
    1: 1574850,
    2: 1520683,
    3: 1437350,
    4: 1367350,
    5: 1309850,
    "revenu brut": 4229000,
    trimf: 1500,
    1.5: 1549850,
    2.5: 1483183,
    3.5: 1405683,
    4.5: 1345266,
  },
  {
    1: 1575250,
    2: 1521083,
    3: 1437750,
    4: 1367750,
    5: 1310250,
    "revenu brut": 4230000,
    trimf: 1500,
    1.5: 1550250,
    2.5: 1483583,
    3.5: 1406083,
    4.5: 1345666,
  },
  {
    1: 1575650,
    2: 1521483,
    3: 1438150,
    4: 1368150,
    5: 1310650,
    "revenu brut": 4231_000,
    trimf: 1500,
    1.5: 1550650,
    2.5: 1483983,
    3.5: 1406483,
    4.5: 1346066,
  },
  {
    1: 1576050,
    2: 1521883,
    3: 1438550,
    4: 1368550,
    5: 1311050,
    "revenu brut": 4232000,
    trimf: 1500,
    1.5: 1551050,
    2.5: 1484383,
    3.5: 1406883,
    4.5: 1346466,
  },
  {
    1: 1576450,
    2: 1522283,
    3: 1438950,
    4: 1368950,
    5: 1311450,
    "revenu brut": 4233000,
    trimf: 1500,
    1.5: 1551450,
    2.5: 1484783,
    3.5: 1407283,
    4.5: 1346866,
  },
  {
    1: 1576850,
    2: 1522683,
    3: 1439350,
    4: 1369350,
    5: 1311850,
    "revenu brut": 4234000,
    trimf: 1500,
    1.5: 1551850,
    2.5: 1485183,
    3.5: 1407683,
    4.5: 1347266,
  },
  {
    1: 1577250,
    2: 1523083,
    3: 1439750,
    4: 1369750,
    5: 1312250,
    "revenu brut": 4235000,
    trimf: 1500,
    1.5: 1552250,
    2.5: 1485583,
    3.5: 1408083,
    4.5: 1347666,
  },
  {
    1: 1577650,
    2: 1523483,
    3: 1440150,
    4: 1370150,
    5: 1312650,
    "revenu brut": 4236000,
    trimf: 1500,
    1.5: 1552650,
    2.5: 1485983,
    3.5: 1408483,
    4.5: 1348066,
  },
  {
    1: 1578050,
    2: 1523883,
    3: 1440550,
    4: 1370550,
    5: 1313050,
    "revenu brut": 4237000,
    trimf: 1500,
    1.5: 1553050,
    2.5: 1486383,
    3.5: 1408883,
    4.5: 1348466,
  },
  {
    1: 1578450,
    2: 1524283,
    3: 1440950,
    4: 1370950,
    5: 1313450,
    "revenu brut": 4238000,
    trimf: 1500,
    1.5: 1553450,
    2.5: 1486783,
    3.5: 1409283,
    4.5: 1348866,
  },
  {
    1: 1578850,
    2: 1524683,
    3: 1441350,
    4: 1371350,
    5: 1313850,
    "revenu brut": 4239000,
    trimf: 1500,
    1.5: 1553850,
    2.5: 1487183,
    3.5: 1409683,
    4.5: 1349266,
  },
  {
    1: 1579250,
    2: 1525083,
    3: 1441750,
    4: 1371750,
    5: 1314250,
    "revenu brut": 4240000,
    trimf: 1500,
    1.5: 1554250,
    2.5: 1487583,
    3.5: 1410083,
    4.5: 1349666,
  },
  {
    1: 1579650,
    2: 1525483,
    3: 1442150,
    4: 1372150,
    5: 1314650,
    "revenu brut": 4241_000,
    trimf: 1500,
    1.5: 1554650,
    2.5: 1487983,
    3.5: 1410483,
    4.5: 1350066,
  },
  {
    1: 1580050,
    2: 1525883,
    3: 1442550,
    4: 1372550,
    5: 1315050,
    "revenu brut": 4242000,
    trimf: 1500,
    1.5: 1555050,
    2.5: 1488383,
    3.5: 1410883,
    4.5: 1350466,
  },
  {
    1: 1580450,
    2: 1526283,
    3: 1442950,
    4: 1372950,
    5: 1315450,
    "revenu brut": 4243000,
    trimf: 1500,
    1.5: 1555450,
    2.5: 1488783,
    3.5: 1411283,
    4.5: 1350866,
  },
  {
    1: 1580850,
    2: 1526683,
    3: 1443350,
    4: 1373350,
    5: 1315850,
    "revenu brut": 4244000,
    trimf: 1500,
    1.5: 1555850,
    2.5: 1489183,
    3.5: 1411683,
    4.5: 1351266,
  },
  {
    1: 1581250,
    2: 1527083,
    3: 1443750,
    4: 1373750,
    5: 1316250,
    "revenu brut": 4245000,
    trimf: 1500,
    1.5: 1556250,
    2.5: 1489583,
    3.5: 1412083,
    4.5: 1351666,
  },
  {
    1: 1581650,
    2: 1527483,
    3: 1444150,
    4: 1374150,
    5: 1316650,
    "revenu brut": 4246000,
    trimf: 1500,
    1.5: 1556650,
    2.5: 1489983,
    3.5: 1412483,
    4.5: 1352066,
  },
  {
    1: 1582050,
    2: 1527883,
    3: 1444550,
    4: 1374550,
    5: 1317050,
    "revenu brut": 4247000,
    trimf: 1500,
    1.5: 1557050,
    2.5: 1490383,
    3.5: 1412883,
    4.5: 1352466,
  },
  {
    1: 1582450,
    2: 1528283,
    3: 1444950,
    4: 1374950,
    5: 1317450,
    "revenu brut": 4248000,
    trimf: 1500,
    1.5: 1557450,
    2.5: 1490783,
    3.5: 1413283,
    4.5: 1352866,
  },
  {
    1: 1582850,
    2: 1528683,
    3: 1445350,
    4: 1375350,
    5: 1317850,
    "revenu brut": 4249000,
    trimf: 1500,
    1.5: 1557850,
    2.5: 1491183,
    3.5: 1413683,
    4.5: 1353266,
  },
  {
    1: 1583250,
    2: 1529083,
    3: 1445750,
    4: 1375750,
    5: 1318250,
    "revenu brut": 4250000,
    trimf: 1500,
    1.5: 1558250,
    2.5: 1491583,
    3.5: 1414083,
    4.5: 1353666,
  },
  {
    1: 1583650,
    2: 1529483,
    3: 1446150,
    4: 1376150,
    5: 1318650,
    "revenu brut": 4251_000,
    trimf: 1500,
    1.5: 1558650,
    2.5: 1491983,
    3.5: 1414483,
    4.5: 1354066,
  },
  {
    1: 1584050,
    2: 1529883,
    3: 1446550,
    4: 1376550,
    5: 1319050,
    "revenu brut": 4252000,
    trimf: 1500,
    1.5: 1559050,
    2.5: 1492383,
    3.5: 1414883,
    4.5: 1354466,
  },
  {
    1: 1584450,
    2: 1530283,
    3: 1446950,
    4: 1376950,
    5: 1319450,
    "revenu brut": 4253000,
    trimf: 1500,
    1.5: 1559450,
    2.5: 1492783,
    3.5: 1415283,
    4.5: 1354866,
  },
  {
    1: 1584850,
    2: 1530683,
    3: 1447350,
    4: 1377350,
    5: 1319850,
    "revenu brut": 4254000,
    trimf: 1500,
    1.5: 1559850,
    2.5: 1493183,
    3.5: 1415683,
    4.5: 1355266,
  },
  {
    1: 1585250,
    2: 1531083,
    3: 1447750,
    4: 1377750,
    5: 1320250,
    "revenu brut": 4255000,
    trimf: 1500,
    1.5: 1560250,
    2.5: 1493583,
    3.5: 1416083,
    4.5: 1355666,
  },
  {
    1: 1585650,
    2: 1531483,
    3: 1448150,
    4: 1378150,
    5: 1320650,
    "revenu brut": 4256000,
    trimf: 1500,
    1.5: 1560650,
    2.5: 1493983,
    3.5: 1416483,
    4.5: 1356066,
  },
  {
    1: 1586050,
    2: 1531883,
    3: 1448550,
    4: 1378550,
    5: 1321050,
    "revenu brut": 4257000,
    trimf: 1500,
    1.5: 1561050,
    2.5: 1494383,
    3.5: 1416883,
    4.5: 1356466,
  },
  {
    1: 1586450,
    2: 1532283,
    3: 1448950,
    4: 1378950,
    5: 1321450,
    "revenu brut": 4258000,
    trimf: 1500,
    1.5: 1561450,
    2.5: 1494783,
    3.5: 1417283,
    4.5: 1356866,
  },
  {
    1: 1586850,
    2: 1532683,
    3: 1449350,
    4: 1379350,
    5: 1321850,
    "revenu brut": 4259000,
    trimf: 1500,
    1.5: 1561850,
    2.5: 1495183,
    3.5: 1417683,
    4.5: 1357266,
  },
  {
    1: 1587250,
    2: 1533083,
    3: 1449750,
    4: 1379750,
    5: 1322250,
    "revenu brut": 4260000,
    trimf: 1500,
    1.5: 1562250,
    2.5: 1495583,
    3.5: 1418083,
    4.5: 1357666,
  },
  {
    1: 1587650,
    2: 1533483,
    3: 1450150,
    4: 1380150,
    5: 1322650,
    "revenu brut": 4261_000,
    trimf: 1500,
    1.5: 1562650,
    2.5: 1495983,
    3.5: 1418483,
    4.5: 1358066,
  },
  {
    1: 1588050,
    2: 1533883,
    3: 1450550,
    4: 1380550,
    5: 1323050,
    "revenu brut": 4262000,
    trimf: 1500,
    1.5: 1563050,
    2.5: 1496383,
    3.5: 1418883,
    4.5: 1358466,
  },
  {
    1: 1588450,
    2: 1534283,
    3: 1450950,
    4: 1380950,
    5: 1323450,
    "revenu brut": 4263000,
    trimf: 1500,
    1.5: 1563450,
    2.5: 1496783,
    3.5: 1419283,
    4.5: 1358866,
  },
  {
    1: 1588850,
    2: 1534683,
    3: 1451350,
    4: 1381350,
    5: 1323850,
    "revenu brut": 4264000,
    trimf: 1500,
    1.5: 1563850,
    2.5: 1497183,
    3.5: 1419683,
    4.5: 1359266,
  },
  {
    1: 1589250,
    2: 1535083,
    3: 1451750,
    4: 1381750,
    5: 1324250,
    "revenu brut": 4265000,
    trimf: 1500,
    1.5: 1564250,
    2.5: 1497583,
    3.5: 1420083,
    4.5: 1359666,
  },
  {
    1: 1589650,
    2: 1535483,
    3: 1452150,
    4: 1382150,
    5: 1324650,
    "revenu brut": 4266000,
    trimf: 1500,
    1.5: 1564650,
    2.5: 1497983,
    3.5: 1420483,
    4.5: 1360066,
  },
  {
    1: 1590050,
    2: 1535883,
    3: 1452550,
    4: 1382550,
    5: 1325050,
    "revenu brut": 4267000,
    trimf: 1500,
    1.5: 1565050,
    2.5: 1498383,
    3.5: 1420883,
    4.5: 1360466,
  },
  {
    1: 1590450,
    2: 1536283,
    3: 1452950,
    4: 1382950,
    5: 1325450,
    "revenu brut": 4268000,
    trimf: 1500,
    1.5: 1565450,
    2.5: 1498783,
    3.5: 1421283,
    4.5: 1360866,
  },
  {
    1: 1590850,
    2: 1536683,
    3: 1453350,
    4: 1383350,
    5: 1325850,
    "revenu brut": 4269000,
    trimf: 1500,
    1.5: 1565850,
    2.5: 1499183,
    3.5: 1421683,
    4.5: 1361266,
  },
  {
    1: 1591250,
    2: 1537083,
    3: 1453750,
    4: 1383750,
    5: 1326250,
    "revenu brut": 4270000,
    trimf: 1500,
    1.5: 1566250,
    2.5: 1499583,
    3.5: 1422083,
    4.5: 1361666,
  },
  {
    1: 1591650,
    2: 1537483,
    3: 1454150,
    4: 1384150,
    5: 1326650,
    "revenu brut": 4271_000,
    trimf: 1500,
    1.5: 1566650,
    2.5: 1499983,
    3.5: 1422483,
    4.5: 1362066,
  },
  {
    1: 1592050,
    2: 1537883,
    3: 1454550,
    4: 1384550,
    5: 1327050,
    "revenu brut": 4272000,
    trimf: 1500,
    1.5: 1567050,
    2.5: 1500383,
    3.5: 1422883,
    4.5: 1362466,
  },
  {
    1: 1592450,
    2: 1538283,
    3: 1454950,
    4: 1384950,
    5: 1327450,
    "revenu brut": 4273000,
    trimf: 1500,
    1.5: 1567450,
    2.5: 1500783,
    3.5: 1423283,
    4.5: 1362866,
  },
  {
    1: 1592850,
    2: 1538683,
    3: 1455350,
    4: 1385350,
    5: 1327850,
    "revenu brut": 4274000,
    trimf: 1500,
    1.5: 1567850,
    2.5: 1501183,
    3.5: 1423683,
    4.5: 1363266,
  },
  {
    1: 1593250,
    2: 1539083,
    3: 1455750,
    4: 1385750,
    5: 1328250,
    "revenu brut": 4275000,
    trimf: 1500,
    1.5: 1568250,
    2.5: 1501583,
    3.5: 1424083,
    4.5: 1363666,
  },
  {
    1: 1593650,
    2: 1539483,
    3: 1456150,
    4: 1386150,
    5: 1328650,
    "revenu brut": 4276000,
    trimf: 1500,
    1.5: 1568650,
    2.5: 1501983,
    3.5: 1424483,
    4.5: 1364066,
  },
  {
    1: 1594050,
    2: 1539883,
    3: 1456550,
    4: 1386550,
    5: 1329050,
    "revenu brut": 4277000,
    trimf: 1500,
    1.5: 1569050,
    2.5: 1502383,
    3.5: 1424883,
    4.5: 1364466,
  },
  {
    1: 1594450,
    2: 1540283,
    3: 1456950,
    4: 1386950,
    5: 1329450,
    "revenu brut": 4278000,
    trimf: 1500,
    1.5: 1569450,
    2.5: 1502783,
    3.5: 1425283,
    4.5: 1364866,
  },
  {
    1: 1594850,
    2: 1540683,
    3: 1457350,
    4: 1387350,
    5: 1329850,
    "revenu brut": 4279000,
    trimf: 1500,
    1.5: 1569850,
    2.5: 1503183,
    3.5: 1425683,
    4.5: 1365266,
  },
  {
    1: 1595250,
    2: 1541083,
    3: 1457750,
    4: 1387750,
    5: 1330250,
    "revenu brut": 4280000,
    trimf: 1500,
    1.5: 1570250,
    2.5: 1503583,
    3.5: 1426083,
    4.5: 1365666,
  },
  {
    1: 1595650,
    2: 1541483,
    3: 1458150,
    4: 1388150,
    5: 1330650,
    "revenu brut": 4281_000,
    trimf: 1500,
    1.5: 1570650,
    2.5: 1503983,
    3.5: 1426483,
    4.5: 1366066,
  },
  {
    1: 1596050,
    2: 1541883,
    3: 1458550,
    4: 1388550,
    5: 1331050,
    "revenu brut": 4282000,
    trimf: 1500,
    1.5: 1571050,
    2.5: 1504383,
    3.5: 1426883,
    4.5: 1366466,
  },
  {
    1: 1596450,
    2: 1542283,
    3: 1458950,
    4: 1388950,
    5: 1331450,
    "revenu brut": 4283000,
    trimf: 1500,
    1.5: 1571450,
    2.5: 1504783,
    3.5: 1427283,
    4.5: 1366866,
  },
  {
    1: 1596850,
    2: 1542683,
    3: 1459350,
    4: 1389350,
    5: 1331850,
    "revenu brut": 4284000,
    trimf: 1500,
    1.5: 1571850,
    2.5: 1505183,
    3.5: 1427683,
    4.5: 1367266,
  },
  {
    1: 1597250,
    2: 1543083,
    3: 1459750,
    4: 1389750,
    5: 1332250,
    "revenu brut": 4285000,
    trimf: 1500,
    1.5: 1572250,
    2.5: 1505583,
    3.5: 1428083,
    4.5: 1367666,
  },
  {
    1: 1597650,
    2: 1543483,
    3: 1460150,
    4: 1390150,
    5: 1332650,
    "revenu brut": 4286000,
    trimf: 1500,
    1.5: 1572650,
    2.5: 1505983,
    3.5: 1428483,
    4.5: 1368066,
  },
  {
    1: 1598050,
    2: 1543883,
    3: 1460550,
    4: 1390550,
    5: 1333050,
    "revenu brut": 4287000,
    trimf: 1500,
    1.5: 1573050,
    2.5: 1506383,
    3.5: 1428883,
    4.5: 1368466,
  },
  {
    1: 1598450,
    2: 1544283,
    3: 1460950,
    4: 1390950,
    5: 1333450,
    "revenu brut": 4288000,
    trimf: 1500,
    1.5: 1573450,
    2.5: 1506783,
    3.5: 1429283,
    4.5: 1368866,
  },
  {
    1: 1598850,
    2: 1544683,
    3: 1461350,
    4: 1391350,
    5: 1333850,
    "revenu brut": 4289000,
    trimf: 1500,
    1.5: 1573850,
    2.5: 1507183,
    3.5: 1429683,
    4.5: 1369266,
  },
  {
    1: 1599250,
    2: 1545083,
    3: 1461750,
    4: 1391750,
    5: 1334250,
    "revenu brut": 4290000,
    trimf: 1500,
    1.5: 1574250,
    2.5: 1507583,
    3.5: 1430083,
    4.5: 1369666,
  },
  {
    1: 1599650,
    2: 1545483,
    3: 1462150,
    4: 1392150,
    5: 1334650,
    "revenu brut": 4291_000,
    trimf: 1500,
    1.5: 1574650,
    2.5: 1507983,
    3.5: 1430483,
    4.5: 1370066,
  },
  {
    1: 1600050,
    2: 1545883,
    3: 1462550,
    4: 1392550,
    5: 1335050,
    "revenu brut": 4292000,
    trimf: 1500,
    1.5: 1575050,
    2.5: 1508383,
    3.5: 1430883,
    4.5: 1370466,
  },
  {
    1: 1600450,
    2: 1546283,
    3: 1462950,
    4: 1392950,
    5: 1335450,
    "revenu brut": 4293000,
    trimf: 1500,
    1.5: 1575450,
    2.5: 1508783,
    3.5: 1431283,
    4.5: 1370866,
  },
  {
    1: 1600850,
    2: 1546683,
    3: 1463350,
    4: 1393350,
    5: 1335850,
    "revenu brut": 4294000,
    trimf: 1500,
    1.5: 1575850,
    2.5: 1509183,
    3.5: 1431683,
    4.5: 1371266,
  },
  {
    1: 1601250,
    2: 1547083,
    3: 1463750,
    4: 1393750,
    5: 1336250,
    "revenu brut": 4295000,
    trimf: 1500,
    1.5: 1576250,
    2.5: 1509583,
    3.5: 1432083,
    4.5: 1371666,
  },
  {
    1: 1601650,
    2: 1547483,
    3: 1464150,
    4: 1394150,
    5: 1336650,
    "revenu brut": 4296000,
    trimf: 1500,
    1.5: 1576650,
    2.5: 1509983,
    3.5: 1432483,
    4.5: 1372066,
  },
  {
    1: 1602050,
    2: 1547883,
    3: 1464550,
    4: 1394550,
    5: 1337050,
    "revenu brut": 4297000,
    trimf: 1500,
    1.5: 1577050,
    2.5: 1510383,
    3.5: 1432883,
    4.5: 1372466,
  },
  {
    1: 1602450,
    2: 1548283,
    3: 1464950,
    4: 1394950,
    5: 1337450,
    "revenu brut": 4298000,
    trimf: 1500,
    1.5: 1577450,
    2.5: 1510783,
    3.5: 1433283,
    4.5: 1372866,
  },
  {
    1: 1602850,
    2: 1548683,
    3: 1465350,
    4: 1395350,
    5: 1337850,
    "revenu brut": 4299000,
    trimf: 1500,
    1.5: 1577850,
    2.5: 1511183,
    3.5: 1433683,
    4.5: 1373266,
  },
  {
    1: 1603250,
    2: 1549083,
    3: 1465750,
    4: 1395750,
    5: 1338250,
    "revenu brut": 4300000,
    trimf: 1500,
    1.5: 1578250,
    2.5: 1511583,
    3.5: 1434083,
    4.5: 1373666,
  },
  {
    1: 1603650,
    2: 1549483,
    3: 1466150,
    4: 1396150,
    5: 1338650,
    "revenu brut": 4301_000,
    trimf: 1500,
    1.5: 1578650,
    2.5: 1511983,
    3.5: 1434483,
    4.5: 1374066,
  },
  {
    1: 1604050,
    2: 1549883,
    3: 1466550,
    4: 1396550,
    5: 1339050,
    "revenu brut": 4302000,
    trimf: 1500,
    1.5: 1579050,
    2.5: 1512383,
    3.5: 1434883,
    4.5: 1374466,
  },
  {
    1: 1604450,
    2: 1550283,
    3: 1466950,
    4: 1396950,
    5: 1339450,
    "revenu brut": 4303000,
    trimf: 1500,
    1.5: 1579450,
    2.5: 1512783,
    3.5: 1435283,
    4.5: 1374866,
  },
  {
    1: 1604850,
    2: 1550683,
    3: 1467350,
    4: 1397350,
    5: 1339850,
    "revenu brut": 4304000,
    trimf: 1500,
    1.5: 1579850,
    2.5: 1513183,
    3.5: 1435683,
    4.5: 1375266,
  },
  {
    1: 1605250,
    2: 1551083,
    3: 1467750,
    4: 1397750,
    5: 1340250,
    "revenu brut": 4305000,
    trimf: 1500,
    1.5: 1580250,
    2.5: 1513583,
    3.5: 1436083,
    4.5: 1375666,
  },
  {
    1: 1605650,
    2: 1551483,
    3: 1468150,
    4: 1398150,
    5: 1340650,
    "revenu brut": 4306000,
    trimf: 1500,
    1.5: 1580650,
    2.5: 1513983,
    3.5: 1436483,
    4.5: 1376066,
  },
  {
    1: 1606050,
    2: 1551883,
    3: 1468550,
    4: 1398550,
    5: 1341050,
    "revenu brut": 4307000,
    trimf: 1500,
    1.5: 1581050,
    2.5: 1514383,
    3.5: 1436883,
    4.5: 1376466,
  },
  {
    1: 1606450,
    2: 1552283,
    3: 1468950,
    4: 1398950,
    5: 1341450,
    "revenu brut": 4308000,
    trimf: 1500,
    1.5: 1581450,
    2.5: 1514783,
    3.5: 1437283,
    4.5: 1376866,
  },
  {
    1: 1606850,
    2: 1552683,
    3: 1469350,
    4: 1399350,
    5: 1341850,
    "revenu brut": 4309000,
    trimf: 1500,
    1.5: 1581850,
    2.5: 1515183,
    3.5: 1437683,
    4.5: 1377266,
  },
  {
    1: 1607250,
    2: 1553083,
    3: 1469750,
    4: 1399750,
    5: 1342250,
    "revenu brut": 431_0000,
    trimf: 1500,
    1.5: 1582250,
    2.5: 1515583,
    3.5: 1438083,
    4.5: 1377666,
  },
  {
    1: 1607650,
    2: 1553483,
    3: 1470150,
    4: 1400150,
    5: 1342650,
    "revenu brut": 4311_000,
    trimf: 1500,
    1.5: 1582650,
    2.5: 1515983,
    3.5: 1438483,
    4.5: 1378066,
  },
  {
    1: 1608050,
    2: 1553883,
    3: 1470550,
    4: 1400550,
    5: 1343050,
    "revenu brut": 4312000,
    trimf: 1500,
    1.5: 1583050,
    2.5: 1516383,
    3.5: 1438883,
    4.5: 1378466,
  },
  {
    1: 1608450,
    2: 1554283,
    3: 1470950,
    4: 1400950,
    5: 1343450,
    "revenu brut": 4313000,
    trimf: 1500,
    1.5: 1583450,
    2.5: 1516783,
    3.5: 1439283,
    4.5: 1378866,
  },
  {
    1: 1608850,
    2: 1554683,
    3: 1471350,
    4: 1401350,
    5: 1343850,
    "revenu brut": 4314000,
    trimf: 1500,
    1.5: 1583850,
    2.5: 1517183,
    3.5: 1439683,
    4.5: 1379266,
  },
  {
    1: 1609250,
    2: 1555083,
    3: 1471750,
    4: 1401750,
    5: 1344250,
    "revenu brut": 4315000,
    trimf: 1500,
    1.5: 1584250,
    2.5: 1517583,
    3.5: 1440083,
    4.5: 1379666,
  },
  {
    1: 1609650,
    2: 1555483,
    3: 1472150,
    4: 1402150,
    5: 1344650,
    "revenu brut": 4316000,
    trimf: 1500,
    1.5: 1584650,
    2.5: 1517983,
    3.5: 1440483,
    4.5: 1380066,
  },
  {
    1: 1610050,
    2: 1555883,
    3: 1472550,
    4: 1402550,
    5: 1345050,
    "revenu brut": 4317000,
    trimf: 1500,
    1.5: 1585050,
    2.5: 1518383,
    3.5: 1440883,
    4.5: 1380466,
  },
  {
    1: 1610450,
    2: 1556283,
    3: 1472950,
    4: 1402950,
    5: 1345450,
    "revenu brut": 4318000,
    trimf: 1500,
    1.5: 1585450,
    2.5: 1518783,
    3.5: 1441283,
    4.5: 1380866,
  },
  {
    1: 1610850,
    2: 1556683,
    3: 1473350,
    4: 1403350,
    5: 1345850,
    "revenu brut": 4319000,
    trimf: 1500,
    1.5: 1585850,
    2.5: 1519183,
    3.5: 1441683,
    4.5: 1381266,
  },
  {
    1: 1611250,
    2: 1557083,
    3: 1473750,
    4: 1403750,
    5: 1346250,
    "revenu brut": 4320000,
    trimf: 1500,
    1.5: 1586250,
    2.5: 1519583,
    3.5: 1442083,
    4.5: 1381666,
  },
  {
    1: 1611650,
    2: 1557483,
    3: 1474150,
    4: 1404150,
    5: 1346650,
    "revenu brut": 4321_000,
    trimf: 1500,
    1.5: 1586650,
    2.5: 1519983,
    3.5: 1442483,
    4.5: 1382066,
  },
  {
    1: 1612050,
    2: 1557883,
    3: 1474550,
    4: 1404550,
    5: 1347050,
    "revenu brut": 4322000,
    trimf: 1500,
    1.5: 1587050,
    2.5: 1520383,
    3.5: 1442883,
    4.5: 1382466,
  },
  {
    1: 1612450,
    2: 1558283,
    3: 1474950,
    4: 1404950,
    5: 1347450,
    "revenu brut": 4323000,
    trimf: 1500,
    1.5: 1587450,
    2.5: 1520783,
    3.5: 1443283,
    4.5: 1382866,
  },
  {
    1: 1612850,
    2: 1558683,
    3: 1475350,
    4: 1405350,
    5: 1347850,
    "revenu brut": 4324000,
    trimf: 1500,
    1.5: 1587850,
    2.5: 1521183,
    3.5: 1443683,
    4.5: 1383266,
  },
  {
    1: 1613250,
    2: 1559083,
    3: 1475750,
    4: 1405750,
    5: 1348250,
    "revenu brut": 4325000,
    trimf: 1500,
    1.5: 1588250,
    2.5: 1521583,
    3.5: 1444083,
    4.5: 1383666,
  },
  {
    1: 1613650,
    2: 1559483,
    3: 1476150,
    4: 1406150,
    5: 1348650,
    "revenu brut": 4326000,
    trimf: 1500,
    1.5: 1588650,
    2.5: 1521983,
    3.5: 1444483,
    4.5: 1384066,
  },
  {
    1: 1614050,
    2: 1559883,
    3: 1476550,
    4: 1406550,
    5: 1349050,
    "revenu brut": 4327000,
    trimf: 1500,
    1.5: 1589050,
    2.5: 1522383,
    3.5: 1444883,
    4.5: 1384466,
  },
  {
    1: 1614450,
    2: 1560283,
    3: 1476950,
    4: 1406950,
    5: 1349450,
    "revenu brut": 4328000,
    trimf: 1500,
    1.5: 1589450,
    2.5: 1522783,
    3.5: 1445283,
    4.5: 1384866,
  },
  {
    1: 1614850,
    2: 1560683,
    3: 1477350,
    4: 1407350,
    5: 1349850,
    "revenu brut": 4329000,
    trimf: 1500,
    1.5: 1589850,
    2.5: 1523183,
    3.5: 1445683,
    4.5: 1385266,
  },
  {
    1: 1615250,
    2: 1561083,
    3: 1477750,
    4: 1407750,
    5: 1350250,
    "revenu brut": 4330000,
    trimf: 1500,
    1.5: 1590250,
    2.5: 1523583,
    3.5: 1446083,
    4.5: 1385666,
  },
  {
    1: 1615650,
    2: 1561483,
    3: 1478150,
    4: 1408150,
    5: 1350650,
    "revenu brut": 4331_000,
    trimf: 1500,
    1.5: 1590650,
    2.5: 1523983,
    3.5: 1446483,
    4.5: 1386066,
  },
  {
    1: 1616050,
    2: 1561883,
    3: 1478550,
    4: 1408550,
    5: 1351050,
    "revenu brut": 4332000,
    trimf: 1500,
    1.5: 1591050,
    2.5: 1524383,
    3.5: 1446883,
    4.5: 1386466,
  },
  {
    1: 1616450,
    2: 1562283,
    3: 1478950,
    4: 1408950,
    5: 1351450,
    "revenu brut": 4333000,
    trimf: 1500,
    1.5: 1591450,
    2.5: 1524783,
    3.5: 1447283,
    4.5: 1386866,
  },
  {
    1: 1616850,
    2: 1562683,
    3: 1479350,
    4: 1409350,
    5: 1351850,
    "revenu brut": 4334000,
    trimf: 1500,
    1.5: 1591850,
    2.5: 1525183,
    3.5: 1447683,
    4.5: 1387266,
  },
  {
    1: 1617250,
    2: 1563083,
    3: 1479750,
    4: 1409750,
    5: 1352250,
    "revenu brut": 4335000,
    trimf: 1500,
    1.5: 1592250,
    2.5: 1525583,
    3.5: 1448083,
    4.5: 1387666,
  },
  {
    1: 1617650,
    2: 1563483,
    3: 1480150,
    4: 1410150,
    5: 1352650,
    "revenu brut": 4336000,
    trimf: 1500,
    1.5: 1592650,
    2.5: 1525983,
    3.5: 1448483,
    4.5: 1388066,
  },
  {
    1: 1618050,
    2: 1563883,
    3: 1480550,
    4: 1410550,
    5: 1353050,
    "revenu brut": 4337000,
    trimf: 1500,
    1.5: 1593050,
    2.5: 1526383,
    3.5: 1448883,
    4.5: 1388466,
  },
  {
    1: 1618450,
    2: 1564283,
    3: 1480950,
    4: 1410950,
    5: 1353450,
    "revenu brut": 4338000,
    trimf: 1500,
    1.5: 1593450,
    2.5: 1526783,
    3.5: 1449283,
    4.5: 1388866,
  },
  {
    1: 1618850,
    2: 1564683,
    3: 1481350,
    4: 1411350,
    5: 1353850,
    "revenu brut": 4339000,
    trimf: 1500,
    1.5: 1593850,
    2.5: 1527183,
    3.5: 1449683,
    4.5: 1389266,
  },
  {
    1: 1619250,
    2: 1565083,
    3: 1481750,
    4: 1411750,
    5: 1354250,
    "revenu brut": 4340000,
    trimf: 1500,
    1.5: 1594250,
    2.5: 1527583,
    3.5: 1450083,
    4.5: 1389666,
  },
  {
    1: 1619650,
    2: 1565483,
    3: 1482150,
    4: 1412150,
    5: 1354650,
    "revenu brut": 4341_000,
    trimf: 1500,
    1.5: 1594650,
    2.5: 1527983,
    3.5: 1450483,
    4.5: 1390066,
  },
  {
    1: 1620050,
    2: 1565883,
    3: 1482550,
    4: 1412550,
    5: 1355050,
    "revenu brut": 4342000,
    trimf: 1500,
    1.5: 1595050,
    2.5: 1528383,
    3.5: 1450883,
    4.5: 1390466,
  },
  {
    1: 1620450,
    2: 1566283,
    3: 1482950,
    4: 1412950,
    5: 1355450,
    "revenu brut": 4343000,
    trimf: 1500,
    1.5: 1595450,
    2.5: 1528783,
    3.5: 1451283,
    4.5: 1390866,
  },
  {
    1: 1620850,
    2: 1566683,
    3: 1483350,
    4: 1413350,
    5: 1355850,
    "revenu brut": 4344000,
    trimf: 1500,
    1.5: 1595850,
    2.5: 1529183,
    3.5: 1451683,
    4.5: 1391266,
  },
  {
    1: 1621250,
    2: 1567083,
    3: 1483750,
    4: 1413750,
    5: 1356250,
    "revenu brut": 4345000,
    trimf: 1500,
    1.5: 1596250,
    2.5: 1529583,
    3.5: 1452083,
    4.5: 1391666,
  },
  {
    1: 1621650,
    2: 1567483,
    3: 1484150,
    4: 1414150,
    5: 1356650,
    "revenu brut": 4346000,
    trimf: 1500,
    1.5: 1596650,
    2.5: 1529983,
    3.5: 1452483,
    4.5: 1392066,
  },
  {
    1: 1622050,
    2: 1567883,
    3: 1484550,
    4: 1414550,
    5: 1357050,
    "revenu brut": 4347000,
    trimf: 1500,
    1.5: 1597050,
    2.5: 1530383,
    3.5: 1452883,
    4.5: 1392466,
  },
  {
    1: 1622450,
    2: 1568283,
    3: 1484950,
    4: 1414950,
    5: 1357450,
    "revenu brut": 4348000,
    trimf: 1500,
    1.5: 1597450,
    2.5: 1530783,
    3.5: 1453283,
    4.5: 1392866,
  },
  {
    1: 1622850,
    2: 1568683,
    3: 1485350,
    4: 1415350,
    5: 1357850,
    "revenu brut": 4349000,
    trimf: 1500,
    1.5: 1597850,
    2.5: 1531183,
    3.5: 1453683,
    4.5: 1393266,
  },
  {
    1: 1623250,
    2: 1569083,
    3: 1485750,
    4: 1415750,
    5: 1358250,
    "revenu brut": 4350000,
    trimf: 1500,
    1.5: 1598250,
    2.5: 1531583,
    3.5: 1454083,
    4.5: 1393666,
  },
  {
    1: 1623650,
    2: 1569483,
    3: 1486150,
    4: 1416150,
    5: 1358650,
    "revenu brut": 4351_000,
    trimf: 1500,
    1.5: 1598650,
    2.5: 1531983,
    3.5: 1454483,
    4.5: 1394066,
  },
  {
    1: 1624050,
    2: 1569883,
    3: 1486550,
    4: 1416550,
    5: 1359050,
    "revenu brut": 4352000,
    trimf: 1500,
    1.5: 1599050,
    2.5: 1532383,
    3.5: 1454883,
    4.5: 1394466,
  },
  {
    1: 1624450,
    2: 1570283,
    3: 1486950,
    4: 1416950,
    5: 1359450,
    "revenu brut": 4353000,
    trimf: 1500,
    1.5: 1599450,
    2.5: 1532783,
    3.5: 1455283,
    4.5: 1394866,
  },
  {
    1: 1624850,
    2: 1570683,
    3: 1487350,
    4: 1417350,
    5: 1359850,
    "revenu brut": 4354000,
    trimf: 1500,
    1.5: 1599850,
    2.5: 1533183,
    3.5: 1455683,
    4.5: 1395266,
  },
  {
    1: 1625250,
    2: 1571083,
    3: 1487750,
    4: 1417750,
    5: 1360250,
    "revenu brut": 4355000,
    trimf: 1500,
    1.5: 1600250,
    2.5: 1533583,
    3.5: 1456083,
    4.5: 1395666,
  },
  {
    1: 1625650,
    2: 1571483,
    3: 1488150,
    4: 1418150,
    5: 1360650,
    "revenu brut": 4356000,
    trimf: 1500,
    1.5: 1600650,
    2.5: 1533983,
    3.5: 1456483,
    4.5: 1396066,
  },
  {
    1: 1626050,
    2: 1571883,
    3: 1488550,
    4: 1418550,
    5: 1361050,
    "revenu brut": 4357000,
    trimf: 1500,
    1.5: 1601050,
    2.5: 1534383,
    3.5: 1456883,
    4.5: 1396466,
  },
  {
    1: 1626450,
    2: 1572283,
    3: 1488950,
    4: 1418950,
    5: 1361450,
    "revenu brut": 4358000,
    trimf: 1500,
    1.5: 1601450,
    2.5: 1534783,
    3.5: 1457283,
    4.5: 1396866,
  },
  {
    1: 1626850,
    2: 1572683,
    3: 1489350,
    4: 1419350,
    5: 1361850,
    "revenu brut": 4359000,
    trimf: 1500,
    1.5: 1601850,
    2.5: 1535183,
    3.5: 1457683,
    4.5: 1397266,
  },
  {
    1: 1627250,
    2: 1573083,
    3: 1489750,
    4: 1419750,
    5: 1362250,
    "revenu brut": 4360000,
    trimf: 1500,
    1.5: 1602250,
    2.5: 1535583,
    3.5: 1458083,
    4.5: 1397666,
  },
  {
    1: 1627650,
    2: 1573483,
    3: 1490150,
    4: 1420150,
    5: 1362650,
    "revenu brut": 4361_000,
    trimf: 1500,
    1.5: 1602650,
    2.5: 1535983,
    3.5: 1458483,
    4.5: 1398066,
  },
  {
    1: 1628050,
    2: 1573883,
    3: 1490550,
    4: 1420550,
    5: 1363050,
    "revenu brut": 4362000,
    trimf: 1500,
    1.5: 1603050,
    2.5: 1536383,
    3.5: 1458883,
    4.5: 1398466,
  },
  {
    1: 1628450,
    2: 1574283,
    3: 1490950,
    4: 1420950,
    5: 1363450,
    "revenu brut": 4363000,
    trimf: 1500,
    1.5: 1603450,
    2.5: 1536783,
    3.5: 1459283,
    4.5: 1398866,
  },
  {
    1: 1628850,
    2: 1574683,
    3: 1491350,
    4: 1421350,
    5: 1363850,
    "revenu brut": 4364000,
    trimf: 1500,
    1.5: 1603850,
    2.5: 1537183,
    3.5: 1459683,
    4.5: 1399266,
  },
  {
    1: 1629250,
    2: 1575083,
    3: 1491750,
    4: 1421750,
    5: 1364250,
    "revenu brut": 4365000,
    trimf: 1500,
    1.5: 1604250,
    2.5: 1537583,
    3.5: 1460083,
    4.5: 1399666,
  },
  {
    1: 1629650,
    2: 1575483,
    3: 1492150,
    4: 1422150,
    5: 1364650,
    "revenu brut": 4366000,
    trimf: 1500,
    1.5: 1604650,
    2.5: 1537983,
    3.5: 1460483,
    4.5: 1400066,
  },
  {
    1: 1630050,
    2: 1575883,
    3: 1492550,
    4: 1422550,
    5: 1365050,
    "revenu brut": 4367000,
    trimf: 1500,
    1.5: 1605050,
    2.5: 1538383,
    3.5: 1460883,
    4.5: 1400466,
  },
  {
    1: 1630450,
    2: 1576283,
    3: 1492950,
    4: 1422950,
    5: 1365450,
    "revenu brut": 4368000,
    trimf: 1500,
    1.5: 1605450,
    2.5: 1538783,
    3.5: 1461283,
    4.5: 1400866,
  },
  {
    1: 1630850,
    2: 1576683,
    3: 1493350,
    4: 1423350,
    5: 1365850,
    "revenu brut": 4369000,
    trimf: 1500,
    1.5: 1605850,
    2.5: 1539183,
    3.5: 1461683,
    4.5: 1401266,
  },
  {
    1: 1631250,
    2: 1577083,
    3: 1493750,
    4: 1423750,
    5: 1366250,
    "revenu brut": 4370000,
    trimf: 1500,
    1.5: 1606250,
    2.5: 1539583,
    3.5: 1462083,
    4.5: 1401666,
  },
  {
    1: 1631650,
    2: 1577483,
    3: 1494150,
    4: 1424150,
    5: 1366650,
    "revenu brut": 4371_000,
    trimf: 1500,
    1.5: 1606650,
    2.5: 1539983,
    3.5: 1462483,
    4.5: 1402066,
  },
  {
    1: 1632050,
    2: 1577883,
    3: 1494550,
    4: 1424550,
    5: 1367050,
    "revenu brut": 4372000,
    trimf: 1500,
    1.5: 1607050,
    2.5: 1540383,
    3.5: 1462883,
    4.5: 1402466,
  },
  {
    1: 1632450,
    2: 1578283,
    3: 1494950,
    4: 1424950,
    5: 1367450,
    "revenu brut": 4373000,
    trimf: 1500,
    1.5: 1607450,
    2.5: 1540783,
    3.5: 1463283,
    4.5: 1402866,
  },
  {
    1: 1632850,
    2: 1578683,
    3: 1495350,
    4: 1425350,
    5: 1367850,
    "revenu brut": 4374000,
    trimf: 1500,
    1.5: 1607850,
    2.5: 1541183,
    3.5: 1463683,
    4.5: 1403266,
  },
  {
    1: 1633250,
    2: 1579083,
    3: 1495750,
    4: 1425750,
    5: 1368250,
    "revenu brut": 4375000,
    trimf: 1500,
    1.5: 1608250,
    2.5: 1541583,
    3.5: 1464083,
    4.5: 1403666,
  },
  {
    1: 1633650,
    2: 1579483,
    3: 1496150,
    4: 1426150,
    5: 1368650,
    "revenu brut": 4376000,
    trimf: 1500,
    1.5: 1608650,
    2.5: 1541983,
    3.5: 1464483,
    4.5: 1404066,
  },
  {
    1: 1634050,
    2: 1579883,
    3: 1496550,
    4: 1426550,
    5: 1369050,
    "revenu brut": 4377000,
    trimf: 1500,
    1.5: 1609050,
    2.5: 1542383,
    3.5: 1464883,
    4.5: 1404466,
  },
  {
    1: 1634450,
    2: 1580283,
    3: 1496950,
    4: 1426950,
    5: 1369450,
    "revenu brut": 4378000,
    trimf: 1500,
    1.5: 1609450,
    2.5: 1542783,
    3.5: 1465283,
    4.5: 1404866,
  },
  {
    1: 1634850,
    2: 1580683,
    3: 1497350,
    4: 1427350,
    5: 1369850,
    "revenu brut": 4379000,
    trimf: 1500,
    1.5: 1609850,
    2.5: 1543183,
    3.5: 1465683,
    4.5: 1405266,
  },
  {
    1: 1635250,
    2: 1581083,
    3: 1497750,
    4: 1427750,
    5: 1370250,
    "revenu brut": 4380000,
    trimf: 1500,
    1.5: 1610250,
    2.5: 1543583,
    3.5: 1466083,
    4.5: 1405666,
  },
  {
    1: 1635650,
    2: 1581483,
    3: 1498150,
    4: 1428150,
    5: 1370650,
    "revenu brut": 4381_000,
    trimf: 1500,
    1.5: 1610650,
    2.5: 1543983,
    3.5: 1466483,
    4.5: 1406066,
  },
  {
    1: 1636050,
    2: 1581883,
    3: 1498550,
    4: 1428550,
    5: 1371050,
    "revenu brut": 4382000,
    trimf: 1500,
    1.5: 1611050,
    2.5: 1544383,
    3.5: 1466883,
    4.5: 1406466,
  },
  {
    1: 1636450,
    2: 1582283,
    3: 1498950,
    4: 1428950,
    5: 1371450,
    "revenu brut": 4383000,
    trimf: 1500,
    1.5: 1611450,
    2.5: 1544783,
    3.5: 1467283,
    4.5: 1406866,
  },
  {
    1: 1636850,
    2: 1582683,
    3: 1499350,
    4: 1429350,
    5: 1371850,
    "revenu brut": 4384000,
    trimf: 1500,
    1.5: 1611850,
    2.5: 1545183,
    3.5: 1467683,
    4.5: 1407266,
  },
  {
    1: 1637250,
    2: 1583083,
    3: 1499750,
    4: 1429750,
    5: 1372250,
    "revenu brut": 4385000,
    trimf: 1500,
    1.5: 1612250,
    2.5: 1545583,
    3.5: 1468083,
    4.5: 1407666,
  },
  {
    1: 1637650,
    2: 1583483,
    3: 1500150,
    4: 1430150,
    5: 1372650,
    "revenu brut": 4386000,
    trimf: 1500,
    1.5: 1612650,
    2.5: 1545983,
    3.5: 1468483,
    4.5: 1408066,
  },
  {
    1: 1638050,
    2: 1583883,
    3: 1500550,
    4: 1430550,
    5: 1373050,
    "revenu brut": 4387000,
    trimf: 1500,
    1.5: 1613050,
    2.5: 1546383,
    3.5: 1468883,
    4.5: 1408466,
  },
  {
    1: 1638450,
    2: 1584283,
    3: 1500950,
    4: 1430950,
    5: 1373450,
    "revenu brut": 4388000,
    trimf: 1500,
    1.5: 1613450,
    2.5: 1546783,
    3.5: 1469283,
    4.5: 1408866,
  },
  {
    1: 1638850,
    2: 1584683,
    3: 1501350,
    4: 1431350,
    5: 1373850,
    "revenu brut": 4389000,
    trimf: 1500,
    1.5: 1613850,
    2.5: 1547183,
    3.5: 1469683,
    4.5: 1409266,
  },
  {
    1: 1639250,
    2: 1585083,
    3: 1501750,
    4: 1431750,
    5: 1374250,
    "revenu brut": 4390000,
    trimf: 1500,
    1.5: 1614250,
    2.5: 1547583,
    3.5: 1470083,
    4.5: 1409666,
  },
  {
    1: 1639650,
    2: 1585483,
    3: 1502150,
    4: 1432150,
    5: 1374650,
    "revenu brut": 4391_000,
    trimf: 1500,
    1.5: 1614650,
    2.5: 1547983,
    3.5: 1470483,
    4.5: 1410066,
  },
  {
    1: 1640050,
    2: 1585883,
    3: 1502550,
    4: 1432550,
    5: 1375050,
    "revenu brut": 4392000,
    trimf: 1500,
    1.5: 1615050,
    2.5: 1548383,
    3.5: 1470883,
    4.5: 1410466,
  },
  {
    1: 1640450,
    2: 1586283,
    3: 1502950,
    4: 1432950,
    5: 1375450,
    "revenu brut": 4393000,
    trimf: 1500,
    1.5: 1615450,
    2.5: 1548783,
    3.5: 1471283,
    4.5: 1410866,
  },
  {
    1: 1640850,
    2: 1586683,
    3: 1503350,
    4: 1433350,
    5: 1375850,
    "revenu brut": 4394000,
    trimf: 1500,
    1.5: 1615850,
    2.5: 1549183,
    3.5: 1471683,
    4.5: 1411266,
  },
  {
    1: 1641250,
    2: 1587083,
    3: 1503750,
    4: 1433750,
    5: 1376250,
    "revenu brut": 4395000,
    trimf: 1500,
    1.5: 1616250,
    2.5: 1549583,
    3.5: 1472083,
    4.5: 1411666,
  },
  {
    1: 1641650,
    2: 1587483,
    3: 1504150,
    4: 1434150,
    5: 1376650,
    "revenu brut": 4396000,
    trimf: 1500,
    1.5: 1616650,
    2.5: 1549983,
    3.5: 1472483,
    4.5: 1412066,
  },
  {
    1: 1642050,
    2: 1587883,
    3: 1504550,
    4: 1434550,
    5: 1377050,
    "revenu brut": 4397000,
    trimf: 1500,
    1.5: 1617050,
    2.5: 1550383,
    3.5: 1472883,
    4.5: 1412466,
  },
  {
    1: 1642450,
    2: 1588283,
    3: 1504950,
    4: 1434950,
    5: 1377450,
    "revenu brut": 4398000,
    trimf: 1500,
    1.5: 1617450,
    2.5: 1550783,
    3.5: 1473283,
    4.5: 1412866,
  },
  {
    1: 1642850,
    2: 1588683,
    3: 1505350,
    4: 1435350,
    5: 1377850,
    "revenu brut": 4399000,
    trimf: 1500,
    1.5: 1617850,
    2.5: 1551183,
    3.5: 1473683,
    4.5: 1413266,
  },
  {
    1: 1643250,
    2: 1589083,
    3: 1505750,
    4: 1435750,
    5: 1378250,
    "revenu brut": 4400000,
    trimf: 1500,
    1.5: 1618250,
    2.5: 1551583,
    3.5: 1474083,
    4.5: 1413666,
  },
  {
    1: 1643650,
    2: 1589483,
    3: 1506150,
    4: 1436150,
    5: 1378650,
    "revenu brut": 4401_000,
    trimf: 1500,
    1.5: 1618650,
    2.5: 1551983,
    3.5: 1474483,
    4.5: 1414066,
  },
  {
    1: 1644050,
    2: 1589883,
    3: 1506550,
    4: 1436550,
    5: 1379050,
    "revenu brut": 4402000,
    trimf: 1500,
    1.5: 1619050,
    2.5: 1552383,
    3.5: 1474883,
    4.5: 1414466,
  },
  {
    1: 1644450,
    2: 1590283,
    3: 1506950,
    4: 1436950,
    5: 1379450,
    "revenu brut": 4403000,
    trimf: 1500,
    1.5: 1619450,
    2.5: 1552783,
    3.5: 1475283,
    4.5: 1414866,
  },
  {
    1: 1644850,
    2: 1590683,
    3: 1507350,
    4: 1437350,
    5: 1379850,
    "revenu brut": 4404000,
    trimf: 1500,
    1.5: 1619850,
    2.5: 1553183,
    3.5: 1475683,
    4.5: 1415266,
  },
  {
    1: 1645250,
    2: 1591083,
    3: 1507750,
    4: 1437750,
    5: 1380250,
    "revenu brut": 4405000,
    trimf: 1500,
    1.5: 1620250,
    2.5: 1553583,
    3.5: 1476083,
    4.5: 1415666,
  },
  {
    1: 1645650,
    2: 1591483,
    3: 1508150,
    4: 1438150,
    5: 1380650,
    "revenu brut": 4406000,
    trimf: 1500,
    1.5: 1620650,
    2.5: 1553983,
    3.5: 1476483,
    4.5: 1416066,
  },
  {
    1: 1646050,
    2: 1591883,
    3: 1508550,
    4: 1438550,
    5: 1381050,
    "revenu brut": 4407000,
    trimf: 1500,
    1.5: 1621050,
    2.5: 1554383,
    3.5: 1476883,
    4.5: 1416466,
  },
  {
    1: 1646450,
    2: 1592283,
    3: 1508950,
    4: 1438950,
    5: 1381450,
    "revenu brut": 4408000,
    trimf: 1500,
    1.5: 1621450,
    2.5: 1554783,
    3.5: 1477283,
    4.5: 1416866,
  },
  {
    1: 1646850,
    2: 1592683,
    3: 1509350,
    4: 1439350,
    5: 1381850,
    "revenu brut": 4409000,
    trimf: 1500,
    1.5: 1621850,
    2.5: 1555183,
    3.5: 1477683,
    4.5: 1417266,
  },
  {
    1: 1647250,
    2: 1593083,
    3: 1509750,
    4: 1439750,
    5: 1382250,
    "revenu brut": 441_0000,
    trimf: 1500,
    1.5: 1622250,
    2.5: 1555583,
    3.5: 1478083,
    4.5: 1417666,
  },
  {
    1: 1647650,
    2: 1593483,
    3: 1510150,
    4: 1440150,
    5: 1382650,
    "revenu brut": 4411_000,
    trimf: 1500,
    1.5: 1622650,
    2.5: 1555983,
    3.5: 1478483,
    4.5: 1418066,
  },
  {
    1: 1648050,
    2: 1593883,
    3: 1510550,
    4: 1440550,
    5: 1383050,
    "revenu brut": 4412000,
    trimf: 1500,
    1.5: 1623050,
    2.5: 1556383,
    3.5: 1478883,
    4.5: 1418466,
  },
  {
    1: 1648450,
    2: 1594283,
    3: 1510950,
    4: 1440950,
    5: 1383450,
    "revenu brut": 4413000,
    trimf: 1500,
    1.5: 1623450,
    2.5: 1556783,
    3.5: 1479283,
    4.5: 1418866,
  },
  {
    1: 1648850,
    2: 1594683,
    3: 1511350,
    4: 1441350,
    5: 1383850,
    "revenu brut": 4414000,
    trimf: 1500,
    1.5: 1623850,
    2.5: 1557183,
    3.5: 1479683,
    4.5: 1419266,
  },
  {
    1: 1649250,
    2: 1595083,
    3: 1511750,
    4: 1441750,
    5: 1384250,
    "revenu brut": 4415000,
    trimf: 1500,
    1.5: 1624250,
    2.5: 1557583,
    3.5: 1480083,
    4.5: 1419666,
  },
  {
    1: 1649650,
    2: 1595483,
    3: 1512150,
    4: 1442150,
    5: 1384650,
    "revenu brut": 4416000,
    trimf: 1500,
    1.5: 1624650,
    2.5: 1557983,
    3.5: 1480483,
    4.5: 1420066,
  },
  {
    1: 1650050,
    2: 1595883,
    3: 1512550,
    4: 1442550,
    5: 1385050,
    "revenu brut": 4417000,
    trimf: 1500,
    1.5: 1625050,
    2.5: 1558383,
    3.5: 1480883,
    4.5: 1420466,
  },
  {
    1: 1650450,
    2: 1596283,
    3: 1512950,
    4: 1442950,
    5: 1385450,
    "revenu brut": 4418000,
    trimf: 1500,
    1.5: 1625450,
    2.5: 1558783,
    3.5: 1481283,
    4.5: 1420866,
  },
  {
    1: 1650850,
    2: 1596683,
    3: 1513350,
    4: 1443350,
    5: 1385850,
    "revenu brut": 4419000,
    trimf: 1500,
    1.5: 1625850,
    2.5: 1559183,
    3.5: 1481683,
    4.5: 1421266,
  },
  {
    1: 1651250,
    2: 1597083,
    3: 1513750,
    4: 1443750,
    5: 1386250,
    "revenu brut": 4420000,
    trimf: 1500,
    1.5: 1626250,
    2.5: 1559583,
    3.5: 1482083,
    4.5: 1421666,
  },
  {
    1: 1651650,
    2: 1597483,
    3: 1514150,
    4: 1444150,
    5: 1386650,
    "revenu brut": 4421_000,
    trimf: 1500,
    1.5: 1626650,
    2.5: 1559983,
    3.5: 1482483,
    4.5: 1422066,
  },
  {
    1: 1652050,
    2: 1597883,
    3: 1514550,
    4: 1444550,
    5: 1387050,
    "revenu brut": 4422000,
    trimf: 1500,
    1.5: 1627050,
    2.5: 1560383,
    3.5: 1482883,
    4.5: 1422466,
  },
  {
    1: 1652450,
    2: 1598283,
    3: 1514950,
    4: 1444950,
    5: 1387450,
    "revenu brut": 4423000,
    trimf: 1500,
    1.5: 1627450,
    2.5: 1560783,
    3.5: 1483283,
    4.5: 1422866,
  },
  {
    1: 1652850,
    2: 1598683,
    3: 1515350,
    4: 1445350,
    5: 1387850,
    "revenu brut": 4424000,
    trimf: 1500,
    1.5: 1627850,
    2.5: 1561183,
    3.5: 1483683,
    4.5: 1423266,
  },
  {
    1: 1653250,
    2: 1599083,
    3: 1515750,
    4: 1445750,
    5: 1388250,
    "revenu brut": 4425000,
    trimf: 1500,
    1.5: 1628250,
    2.5: 1561583,
    3.5: 1484083,
    4.5: 1423666,
  },
  {
    1: 1653650,
    2: 1599483,
    3: 1516150,
    4: 1446150,
    5: 1388650,
    "revenu brut": 4426000,
    trimf: 1500,
    1.5: 1628650,
    2.5: 1561983,
    3.5: 1484483,
    4.5: 1424066,
  },
  {
    1: 1654050,
    2: 1599883,
    3: 1516550,
    4: 1446550,
    5: 1389050,
    "revenu brut": 4427000,
    trimf: 1500,
    1.5: 1629050,
    2.5: 1562383,
    3.5: 1484883,
    4.5: 1424466,
  },
  {
    1: 1654450,
    2: 1600283,
    3: 1516950,
    4: 1446950,
    5: 1389450,
    "revenu brut": 4428000,
    trimf: 1500,
    1.5: 1629450,
    2.5: 1562783,
    3.5: 1485283,
    4.5: 1424866,
  },
  {
    1: 1654850,
    2: 1600683,
    3: 1517350,
    4: 1447350,
    5: 1389850,
    "revenu brut": 4429000,
    trimf: 1500,
    1.5: 1629850,
    2.5: 1563183,
    3.5: 1485683,
    4.5: 1425266,
  },
  {
    1: 1655250,
    2: 1601083,
    3: 1517750,
    4: 1447750,
    5: 1390250,
    "revenu brut": 4430000,
    trimf: 1500,
    1.5: 1630250,
    2.5: 1563583,
    3.5: 1486083,
    4.5: 1425666,
  },
  {
    1: 1655650,
    2: 1601483,
    3: 1518150,
    4: 1448150,
    5: 1390650,
    "revenu brut": 4431_000,
    trimf: 1500,
    1.5: 1630650,
    2.5: 1563983,
    3.5: 1486483,
    4.5: 1426066,
  },
  {
    1: 1656050,
    2: 1601883,
    3: 1518550,
    4: 1448550,
    5: 1391050,
    "revenu brut": 4432000,
    trimf: 1500,
    1.5: 1631050,
    2.5: 1564383,
    3.5: 1486883,
    4.5: 1426466,
  },
  {
    1: 1656450,
    2: 1602283,
    3: 1518950,
    4: 1448950,
    5: 1391450,
    "revenu brut": 4433000,
    trimf: 1500,
    1.5: 1631450,
    2.5: 1564783,
    3.5: 1487283,
    4.5: 1426866,
  },
  {
    1: 1656850,
    2: 1602683,
    3: 1519350,
    4: 1449350,
    5: 1391850,
    "revenu brut": 4434000,
    trimf: 1500,
    1.5: 1631850,
    2.5: 1565183,
    3.5: 1487683,
    4.5: 1427266,
  },
  {
    1: 1657250,
    2: 1603083,
    3: 1519750,
    4: 1449750,
    5: 1392250,
    "revenu brut": 4435000,
    trimf: 1500,
    1.5: 1632250,
    2.5: 1565583,
    3.5: 1488083,
    4.5: 1427666,
  },
  {
    1: 1657650,
    2: 1603483,
    3: 1520150,
    4: 1450150,
    5: 1392650,
    "revenu brut": 4436000,
    trimf: 1500,
    1.5: 1632650,
    2.5: 1565983,
    3.5: 1488483,
    4.5: 1428066,
  },
  {
    1: 1658050,
    2: 1603883,
    3: 1520550,
    4: 1450550,
    5: 1393050,
    "revenu brut": 4437000,
    trimf: 1500,
    1.5: 1633050,
    2.5: 1566383,
    3.5: 1488883,
    4.5: 1428466,
  },
  {
    1: 1658450,
    2: 1604283,
    3: 1520950,
    4: 1450950,
    5: 1393450,
    "revenu brut": 4438000,
    trimf: 1500,
    1.5: 1633450,
    2.5: 1566783,
    3.5: 1489283,
    4.5: 1428866,
  },
  {
    1: 1658850,
    2: 1604683,
    3: 1521350,
    4: 1451350,
    5: 1393850,
    "revenu brut": 4439000,
    trimf: 1500,
    1.5: 1633850,
    2.5: 1567183,
    3.5: 1489683,
    4.5: 1429266,
  },
  {
    1: 1659250,
    2: 1605083,
    3: 1521750,
    4: 1451750,
    5: 1394250,
    "revenu brut": 4440000,
    trimf: 1500,
    1.5: 1634250,
    2.5: 1567583,
    3.5: 1490083,
    4.5: 1429666,
  },
  {
    1: 1659650,
    2: 1605483,
    3: 1522150,
    4: 1452150,
    5: 1394650,
    "revenu brut": 4441_000,
    trimf: 1500,
    1.5: 1634650,
    2.5: 1567983,
    3.5: 1490483,
    4.5: 1430066,
  },
  {
    1: 1660050,
    2: 1605883,
    3: 1522550,
    4: 1452550,
    5: 1395050,
    "revenu brut": 4442000,
    trimf: 1500,
    1.5: 1635050,
    2.5: 1568383,
    3.5: 1490883,
    4.5: 1430466,
  },
  {
    1: 1660450,
    2: 1606283,
    3: 1522950,
    4: 1452950,
    5: 1395450,
    "revenu brut": 4443000,
    trimf: 1500,
    1.5: 1635450,
    2.5: 1568783,
    3.5: 1491283,
    4.5: 1430866,
  },
  {
    1: 1660850,
    2: 1606683,
    3: 1523350,
    4: 1453350,
    5: 1395850,
    "revenu brut": 4444000,
    trimf: 1500,
    1.5: 1635850,
    2.5: 1569183,
    3.5: 1491683,
    4.5: 1431266,
  },
  {
    1: 1661250,
    2: 1607083,
    3: 1523750,
    4: 1453750,
    5: 1396250,
    "revenu brut": 4445000,
    trimf: 1500,
    1.5: 1636250,
    2.5: 1569583,
    3.5: 1492083,
    4.5: 1431666,
  },
  {
    1: 1661650,
    2: 1607483,
    3: 1524150,
    4: 1454150,
    5: 1396650,
    "revenu brut": 4446000,
    trimf: 1500,
    1.5: 1636650,
    2.5: 1569983,
    3.5: 1492483,
    4.5: 1432066,
  },
  {
    1: 1662050,
    2: 1607883,
    3: 1524550,
    4: 1454550,
    5: 1397050,
    "revenu brut": 4447000,
    trimf: 1500,
    1.5: 1637050,
    2.5: 1570383,
    3.5: 1492883,
    4.5: 1432466,
  },
  {
    1: 1662450,
    2: 1608283,
    3: 1524950,
    4: 1454950,
    5: 1397450,
    "revenu brut": 4448000,
    trimf: 1500,
    1.5: 1637450,
    2.5: 1570783,
    3.5: 1493283,
    4.5: 1432866,
  },
  {
    1: 1662850,
    2: 1608683,
    3: 1525350,
    4: 1455350,
    5: 1397850,
    "revenu brut": 4449000,
    trimf: 1500,
    1.5: 1637850,
    2.5: 1571183,
    3.5: 1493683,
    4.5: 1433266,
  },
  {
    1: 1663250,
    2: 1609083,
    3: 1525750,
    4: 1455750,
    5: 1398250,
    "revenu brut": 4450000,
    trimf: 1500,
    1.5: 1638250,
    2.5: 1571583,
    3.5: 1494083,
    4.5: 1433666,
  },
  {
    1: 1663650,
    2: 1609483,
    3: 1526150,
    4: 1456150,
    5: 1398650,
    "revenu brut": 4451_000,
    trimf: 1500,
    1.5: 1638650,
    2.5: 1571983,
    3.5: 1494483,
    4.5: 1434066,
  },
  {
    1: 1664050,
    2: 1609883,
    3: 1526550,
    4: 1456550,
    5: 1399050,
    "revenu brut": 4452000,
    trimf: 1500,
    1.5: 1639050,
    2.5: 1572383,
    3.5: 1494883,
    4.5: 1434466,
  },
  {
    1: 1664450,
    2: 1610283,
    3: 1526950,
    4: 1456950,
    5: 1399450,
    "revenu brut": 4453000,
    trimf: 1500,
    1.5: 1639450,
    2.5: 1572783,
    3.5: 1495283,
    4.5: 1434866,
  },
  {
    1: 1664850,
    2: 1610683,
    3: 1527350,
    4: 1457350,
    5: 1399850,
    "revenu brut": 4454000,
    trimf: 1500,
    1.5: 1639850,
    2.5: 1573183,
    3.5: 1495683,
    4.5: 1435266,
  },
  {
    1: 1665250,
    2: 1611083,
    3: 1527750,
    4: 1457750,
    5: 1400250,
    "revenu brut": 4455000,
    trimf: 1500,
    1.5: 1640250,
    2.5: 1573583,
    3.5: 1496083,
    4.5: 1435666,
  },
  {
    1: 1665650,
    2: 1611483,
    3: 1528150,
    4: 1458150,
    5: 1400650,
    "revenu brut": 4456000,
    trimf: 1500,
    1.5: 1640650,
    2.5: 1573983,
    3.5: 1496483,
    4.5: 1436066,
  },
  {
    1: 1666050,
    2: 1611883,
    3: 1528550,
    4: 1458550,
    5: 1401050,
    "revenu brut": 4457000,
    trimf: 1500,
    1.5: 1641050,
    2.5: 1574383,
    3.5: 1496883,
    4.5: 1436466,
  },
  {
    1: 1666450,
    2: 1612283,
    3: 1528950,
    4: 1458950,
    5: 1401450,
    "revenu brut": 4458000,
    trimf: 1500,
    1.5: 1641450,
    2.5: 1574783,
    3.5: 1497283,
    4.5: 1436866,
  },
  {
    1: 1666850,
    2: 1612683,
    3: 1529350,
    4: 1459350,
    5: 1401850,
    "revenu brut": 4459000,
    trimf: 1500,
    1.5: 1641850,
    2.5: 1575183,
    3.5: 1497683,
    4.5: 1437266,
  },
  {
    1: 1667250,
    2: 1613083,
    3: 1529750,
    4: 1459750,
    5: 1402250,
    "revenu brut": 4460000,
    trimf: 1500,
    1.5: 1642250,
    2.5: 1575583,
    3.5: 1498083,
    4.5: 1437666,
  },
  {
    1: 1667650,
    2: 1613483,
    3: 1530150,
    4: 1460150,
    5: 1402650,
    "revenu brut": 4461_000,
    trimf: 1500,
    1.5: 1642650,
    2.5: 1575983,
    3.5: 1498483,
    4.5: 1438066,
  },
  {
    1: 1668050,
    2: 1613883,
    3: 1530550,
    4: 1460550,
    5: 1403050,
    "revenu brut": 4462000,
    trimf: 1500,
    1.5: 1643050,
    2.5: 1576383,
    3.5: 1498883,
    4.5: 1438466,
  },
  {
    1: 1668450,
    2: 1614283,
    3: 1530950,
    4: 1460950,
    5: 1403450,
    "revenu brut": 4463000,
    trimf: 1500,
    1.5: 1643450,
    2.5: 1576783,
    3.5: 1499283,
    4.5: 1438866,
  },
  {
    1: 1668850,
    2: 1614683,
    3: 1531350,
    4: 1461350,
    5: 1403850,
    "revenu brut": 4464000,
    trimf: 1500,
    1.5: 1643850,
    2.5: 1577183,
    3.5: 1499683,
    4.5: 1439266,
  },
  {
    1: 1669250,
    2: 1615083,
    3: 1531750,
    4: 1461750,
    5: 1404250,
    "revenu brut": 4465000,
    trimf: 1500,
    1.5: 1644250,
    2.5: 1577583,
    3.5: 1500083,
    4.5: 1439666,
  },
  {
    1: 1669650,
    2: 1615483,
    3: 1532150,
    4: 1462150,
    5: 1404650,
    "revenu brut": 4466000,
    trimf: 1500,
    1.5: 1644650,
    2.5: 1577983,
    3.5: 1500483,
    4.5: 1440066,
  },
  {
    1: 1670050,
    2: 1615883,
    3: 1532550,
    4: 1462550,
    5: 1405050,
    "revenu brut": 4467000,
    trimf: 1500,
    1.5: 1645050,
    2.5: 1578383,
    3.5: 1500883,
    4.5: 1440466,
  },
  {
    1: 1670450,
    2: 1616283,
    3: 1532950,
    4: 1462950,
    5: 1405450,
    "revenu brut": 4468000,
    trimf: 1500,
    1.5: 1645450,
    2.5: 1578783,
    3.5: 1501283,
    4.5: 1440866,
  },
  {
    1: 1670850,
    2: 1616683,
    3: 1533350,
    4: 1463350,
    5: 1405850,
    "revenu brut": 4469000,
    trimf: 1500,
    1.5: 1645850,
    2.5: 1579183,
    3.5: 1501683,
    4.5: 1441266,
  },
  {
    1: 1671250,
    2: 1617083,
    3: 1533750,
    4: 1463750,
    5: 1406250,
    "revenu brut": 4470000,
    trimf: 1500,
    1.5: 1646250,
    2.5: 1579583,
    3.5: 1502083,
    4.5: 1441666,
  },
  {
    1: 1671650,
    2: 1617483,
    3: 1534150,
    4: 1464150,
    5: 1406650,
    "revenu brut": 4471_000,
    trimf: 1500,
    1.5: 1646650,
    2.5: 1579983,
    3.5: 1502483,
    4.5: 1442066,
  },
  {
    1: 1672050,
    2: 1617883,
    3: 1534550,
    4: 1464550,
    5: 1407050,
    "revenu brut": 4472000,
    trimf: 1500,
    1.5: 1647050,
    2.5: 1580383,
    3.5: 1502883,
    4.5: 1442466,
  },
  {
    1: 1672450,
    2: 1618283,
    3: 1534950,
    4: 1464950,
    5: 1407450,
    "revenu brut": 4473000,
    trimf: 1500,
    1.5: 1647450,
    2.5: 1580783,
    3.5: 1503283,
    4.5: 1442866,
  },
  {
    1: 1672850,
    2: 1618683,
    3: 1535350,
    4: 1465350,
    5: 1407850,
    "revenu brut": 4474000,
    trimf: 1500,
    1.5: 1647850,
    2.5: 1581183,
    3.5: 1503683,
    4.5: 1443266,
  },
  {
    1: 1673250,
    2: 1619083,
    3: 1535750,
    4: 1465750,
    5: 1408250,
    "revenu brut": 4475000,
    trimf: 1500,
    1.5: 1648250,
    2.5: 1581583,
    3.5: 1504083,
    4.5: 1443666,
  },
  {
    1: 1673650,
    2: 1619483,
    3: 1536150,
    4: 1466150,
    5: 1408650,
    "revenu brut": 4476000,
    trimf: 1500,
    1.5: 1648650,
    2.5: 1581983,
    3.5: 1504483,
    4.5: 1444066,
  },
  {
    1: 1674050,
    2: 1619883,
    3: 1536550,
    4: 1466550,
    5: 1409050,
    "revenu brut": 4477000,
    trimf: 1500,
    1.5: 1649050,
    2.5: 1582383,
    3.5: 1504883,
    4.5: 1444466,
  },
  {
    1: 1674450,
    2: 1620283,
    3: 1536950,
    4: 1466950,
    5: 1409450,
    "revenu brut": 4478000,
    trimf: 1500,
    1.5: 1649450,
    2.5: 1582783,
    3.5: 1505283,
    4.5: 1444866,
  },
  {
    1: 1674850,
    2: 1620683,
    3: 1537350,
    4: 1467350,
    5: 1409850,
    "revenu brut": 4479000,
    trimf: 1500,
    1.5: 1649850,
    2.5: 1583183,
    3.5: 1505683,
    4.5: 1445266,
  },
  {
    1: 1675250,
    2: 1621083,
    3: 1537750,
    4: 1467750,
    5: 1410250,
    "revenu brut": 4480000,
    trimf: 1500,
    1.5: 1650250,
    2.5: 1583583,
    3.5: 1506083,
    4.5: 1445666,
  },
  {
    1: 1675650,
    2: 1621483,
    3: 1538150,
    4: 1468150,
    5: 1410650,
    "revenu brut": 4481_000,
    trimf: 1500,
    1.5: 1650650,
    2.5: 1583983,
    3.5: 1506483,
    4.5: 1446066,
  },
  {
    1: 1676050,
    2: 1621883,
    3: 1538550,
    4: 1468550,
    5: 1411050,
    "revenu brut": 4482000,
    trimf: 1500,
    1.5: 1651050,
    2.5: 1584383,
    3.5: 1506883,
    4.5: 1446466,
  },
  {
    1: 1676450,
    2: 1622283,
    3: 1538950,
    4: 1468950,
    5: 1411450,
    "revenu brut": 4483000,
    trimf: 1500,
    1.5: 1651450,
    2.5: 1584783,
    3.5: 1507283,
    4.5: 1446866,
  },
  {
    1: 1676850,
    2: 1622683,
    3: 1539350,
    4: 1469350,
    5: 1411850,
    "revenu brut": 4484000,
    trimf: 1500,
    1.5: 1651850,
    2.5: 1585183,
    3.5: 1507683,
    4.5: 1447266,
  },
  {
    1: 1677250,
    2: 1623083,
    3: 1539750,
    4: 1469750,
    5: 1412250,
    "revenu brut": 4485000,
    trimf: 1500,
    1.5: 1652250,
    2.5: 1585583,
    3.5: 1508083,
    4.5: 1447666,
  },
  {
    1: 1677650,
    2: 1623483,
    3: 1540150,
    4: 1470150,
    5: 1412650,
    "revenu brut": 4486000,
    trimf: 1500,
    1.5: 1652650,
    2.5: 1585983,
    3.5: 1508483,
    4.5: 1448066,
  },
  {
    1: 1678050,
    2: 1623883,
    3: 1540550,
    4: 1470550,
    5: 1413050,
    "revenu brut": 4487000,
    trimf: 1500,
    1.5: 1653050,
    2.5: 1586383,
    3.5: 1508883,
    4.5: 1448466,
  },
  {
    1: 1678450,
    2: 1624283,
    3: 1540950,
    4: 1470950,
    5: 1413450,
    "revenu brut": 4488000,
    trimf: 1500,
    1.5: 1653450,
    2.5: 1586783,
    3.5: 1509283,
    4.5: 1448866,
  },
  {
    1: 1678850,
    2: 1624683,
    3: 1541350,
    4: 1471350,
    5: 1413850,
    "revenu brut": 4489000,
    trimf: 1500,
    1.5: 1653850,
    2.5: 1587183,
    3.5: 1509683,
    4.5: 1449266,
  },
  {
    1: 1679250,
    2: 1625083,
    3: 1541750,
    4: 1471750,
    5: 1414250,
    "revenu brut": 4490000,
    trimf: 1500,
    1.5: 1654250,
    2.5: 1587583,
    3.5: 1510083,
    4.5: 1449666,
  },
  {
    1: 1679650,
    2: 1625483,
    3: 1542150,
    4: 1472150,
    5: 1414650,
    "revenu brut": 4491_000,
    trimf: 1500,
    1.5: 1654650,
    2.5: 1587983,
    3.5: 1510483,
    4.5: 1450066,
  },
  {
    1: 1680050,
    2: 1625883,
    3: 1542550,
    4: 1472550,
    5: 1415050,
    "revenu brut": 4492000,
    trimf: 1500,
    1.5: 1655050,
    2.5: 1588383,
    3.5: 1510883,
    4.5: 1450466,
  },
  {
    1: 1680450,
    2: 1626283,
    3: 1542950,
    4: 1472950,
    5: 1415450,
    "revenu brut": 4493000,
    trimf: 1500,
    1.5: 1655450,
    2.5: 1588783,
    3.5: 1511283,
    4.5: 1450866,
  },
  {
    1: 1680850,
    2: 1626683,
    3: 1543350,
    4: 1473350,
    5: 1415850,
    "revenu brut": 4494000,
    trimf: 1500,
    1.5: 1655850,
    2.5: 1589183,
    3.5: 1511683,
    4.5: 1451266,
  },
  {
    1: 1681250,
    2: 1627083,
    3: 1543750,
    4: 1473750,
    5: 1416250,
    "revenu brut": 4495000,
    trimf: 1500,
    1.5: 1656250,
    2.5: 1589583,
    3.5: 1512083,
    4.5: 1451666,
  },
  {
    1: 1681650,
    2: 1627483,
    3: 1544150,
    4: 1474150,
    5: 1416650,
    "revenu brut": 4496000,
    trimf: 1500,
    1.5: 1656650,
    2.5: 1589983,
    3.5: 1512483,
    4.5: 1452066,
  },
  {
    1: 1682050,
    2: 1627883,
    3: 1544550,
    4: 1474550,
    5: 1417050,
    "revenu brut": 4497000,
    trimf: 1500,
    1.5: 1657050,
    2.5: 1590383,
    3.5: 1512883,
    4.5: 1452466,
  },
  {
    1: 1682450,
    2: 1628283,
    3: 1544950,
    4: 1474950,
    5: 1417450,
    "revenu brut": 4498000,
    trimf: 1500,
    1.5: 1657450,
    2.5: 1590783,
    3.5: 1513283,
    4.5: 1452866,
  },
  {
    1: 1682850,
    2: 1628683,
    3: 1545350,
    4: 1475350,
    5: 1417850,
    "revenu brut": 4499000,
    trimf: 1500,
    1.5: 1657850,
    2.5: 1591183,
    3.5: 1513683,
    4.5: 1453266,
  },
  {
    1: 1683250,
    2: 1629083,
    3: 1545750,
    4: 1475750,
    5: 1418250,
    "revenu brut": 4500000,
    trimf: 1500,
    1.5: 1658250,
    2.5: 1591583,
    3.5: 1514083,
    4.5: 1453666,
  },
  {
    1: 1683650,
    2: 1629483,
    3: 1546150,
    4: 1476150,
    5: 1418650,
    "revenu brut": 4501_000,
    trimf: 1500,
    1.5: 1658650,
    2.5: 1591983,
    3.5: 1514483,
    4.5: 1454066,
  },
  {
    1: 1684050,
    2: 1629883,
    3: 1546550,
    4: 1476550,
    5: 1419050,
    "revenu brut": 4502000,
    trimf: 1500,
    1.5: 1659050,
    2.5: 1592383,
    3.5: 1514883,
    4.5: 1454466,
  },
  {
    1: 1684450,
    2: 1630283,
    3: 1546950,
    4: 1476950,
    5: 1419450,
    "revenu brut": 4503000,
    trimf: 1500,
    1.5: 1659450,
    2.5: 1592783,
    3.5: 1515283,
    4.5: 1454866,
  },
  {
    1: 1684850,
    2: 1630683,
    3: 1547350,
    4: 1477350,
    5: 1419850,
    "revenu brut": 4504000,
    trimf: 1500,
    1.5: 1659850,
    2.5: 1593183,
    3.5: 1515683,
    4.5: 1455266,
  },
  {
    1: 1685250,
    2: 1631083,
    3: 1547750,
    4: 1477750,
    5: 1420250,
    "revenu brut": 4505000,
    trimf: 1500,
    1.5: 1660250,
    2.5: 1593583,
    3.5: 1516083,
    4.5: 1455666,
  },
  {
    1: 1685650,
    2: 1631483,
    3: 1548150,
    4: 1478150,
    5: 1420650,
    "revenu brut": 4506000,
    trimf: 1500,
    1.5: 1660650,
    2.5: 1593983,
    3.5: 1516483,
    4.5: 1456066,
  },
  {
    1: 1686050,
    2: 1631883,
    3: 1548550,
    4: 1478550,
    5: 1421050,
    "revenu brut": 4507000,
    trimf: 1500,
    1.5: 1661050,
    2.5: 1594383,
    3.5: 1516883,
    4.5: 1456466,
  },
  {
    1: 1686450,
    2: 1632283,
    3: 1548950,
    4: 1478950,
    5: 1421450,
    "revenu brut": 4508000,
    trimf: 1500,
    1.5: 1661450,
    2.5: 1594783,
    3.5: 1517283,
    4.5: 1456866,
  },
  {
    1: 1686850,
    2: 1632683,
    3: 1549350,
    4: 1479350,
    5: 1421850,
    "revenu brut": 4509000,
    trimf: 1500,
    1.5: 1661850,
    2.5: 1595183,
    3.5: 1517683,
    4.5: 1457266,
  },
  {
    1: 1687250,
    2: 1633083,
    3: 1549750,
    4: 1479750,
    5: 1422250,
    "revenu brut": 451_0000,
    trimf: 1500,
    1.5: 1662250,
    2.5: 1595583,
    3.5: 1518083,
    4.5: 1457666,
  },
  {
    1: 1687650,
    2: 1633483,
    3: 1550150,
    4: 1480150,
    5: 1422650,
    "revenu brut": 4511_000,
    trimf: 1500,
    1.5: 1662650,
    2.5: 1595983,
    3.5: 1518483,
    4.5: 1458066,
  },
  {
    1: 1688050,
    2: 1633883,
    3: 1550550,
    4: 1480550,
    5: 1423050,
    "revenu brut": 4512000,
    trimf: 1500,
    1.5: 1663050,
    2.5: 1596383,
    3.5: 1518883,
    4.5: 1458466,
  },
  {
    1: 1688450,
    2: 1634283,
    3: 1550950,
    4: 1480950,
    5: 1423450,
    "revenu brut": 4513000,
    trimf: 1500,
    1.5: 1663450,
    2.5: 1596783,
    3.5: 1519283,
    4.5: 1458866,
  },
  {
    1: 1688850,
    2: 1634683,
    3: 1551350,
    4: 1481350,
    5: 1423850,
    "revenu brut": 4514000,
    trimf: 1500,
    1.5: 1663850,
    2.5: 1597183,
    3.5: 1519683,
    4.5: 1459266,
  },
  {
    1: 1689250,
    2: 1635083,
    3: 1551750,
    4: 1481750,
    5: 1424250,
    "revenu brut": 4515000,
    trimf: 1500,
    1.5: 1664250,
    2.5: 1597583,
    3.5: 1520083,
    4.5: 1459666,
  },
  {
    1: 1689650,
    2: 1635483,
    3: 1552150,
    4: 1482150,
    5: 1424650,
    "revenu brut": 4516000,
    trimf: 1500,
    1.5: 1664650,
    2.5: 1597983,
    3.5: 1520483,
    4.5: 1460066,
  },
  {
    1: 1690050,
    2: 1635883,
    3: 1552550,
    4: 1482550,
    5: 1425050,
    "revenu brut": 4517000,
    trimf: 1500,
    1.5: 1665050,
    2.5: 1598383,
    3.5: 1520883,
    4.5: 1460466,
  },
  {
    1: 1690450,
    2: 1636283,
    3: 1552950,
    4: 1482950,
    5: 1425450,
    "revenu brut": 4518000,
    trimf: 1500,
    1.5: 1665450,
    2.5: 1598783,
    3.5: 1521283,
    4.5: 1460866,
  },
  {
    1: 1690850,
    2: 1636683,
    3: 1553350,
    4: 1483350,
    5: 1425850,
    "revenu brut": 4519000,
    trimf: 1500,
    1.5: 1665850,
    2.5: 1599183,
    3.5: 1521683,
    4.5: 1461266,
  },
  {
    1: 1691250,
    2: 1637083,
    3: 1553750,
    4: 1483750,
    5: 1426250,
    "revenu brut": 4520000,
    trimf: 1500,
    1.5: 1666250,
    2.5: 1599583,
    3.5: 1522083,
    4.5: 1461666,
  },
  {
    1: 1691650,
    2: 1637483,
    3: 1554150,
    4: 1484150,
    5: 1426650,
    "revenu brut": 4521_000,
    trimf: 1500,
    1.5: 1666650,
    2.5: 1599983,
    3.5: 1522483,
    4.5: 1462066,
  },
  {
    1: 1692050,
    2: 1637883,
    3: 1554550,
    4: 1484550,
    5: 1427050,
    "revenu brut": 4522000,
    trimf: 1500,
    1.5: 1667050,
    2.5: 1600383,
    3.5: 1522883,
    4.5: 1462466,
  },
  {
    1: 1692450,
    2: 1638283,
    3: 1554950,
    4: 1484950,
    5: 1427450,
    "revenu brut": 4523000,
    trimf: 1500,
    1.5: 1667450,
    2.5: 1600783,
    3.5: 1523283,
    4.5: 1462866,
  },
  {
    1: 1692850,
    2: 1638683,
    3: 1555350,
    4: 1485350,
    5: 1427850,
    "revenu brut": 4524000,
    trimf: 1500,
    1.5: 1667850,
    2.5: 1601183,
    3.5: 1523683,
    4.5: 1463266,
  },
  {
    1: 1693250,
    2: 1639083,
    3: 1555750,
    4: 1485750,
    5: 1428250,
    "revenu brut": 4525000,
    trimf: 1500,
    1.5: 1668250,
    2.5: 1601583,
    3.5: 1524083,
    4.5: 1463666,
  },
  {
    1: 1693650,
    2: 1639483,
    3: 1556150,
    4: 1486150,
    5: 1428650,
    "revenu brut": 4526000,
    trimf: 1500,
    1.5: 1668650,
    2.5: 1601983,
    3.5: 1524483,
    4.5: 1464066,
  },
  {
    1: 1694050,
    2: 1639883,
    3: 1556550,
    4: 1486550,
    5: 1429050,
    "revenu brut": 4527000,
    trimf: 1500,
    1.5: 1669050,
    2.5: 1602383,
    3.5: 1524883,
    4.5: 1464466,
  },
  {
    1: 1694450,
    2: 1640283,
    3: 1556950,
    4: 1486950,
    5: 1429450,
    "revenu brut": 4528000,
    trimf: 1500,
    1.5: 1669450,
    2.5: 1602783,
    3.5: 1525283,
    4.5: 1464866,
  },
  {
    1: 1694850,
    2: 1640683,
    3: 1557350,
    4: 1487350,
    5: 1429850,
    "revenu brut": 4529000,
    trimf: 1500,
    1.5: 1669850,
    2.5: 1603183,
    3.5: 1525683,
    4.5: 1465266,
  },
  {
    1: 1695250,
    2: 1641083,
    3: 1557750,
    4: 1487750,
    5: 1430250,
    "revenu brut": 4530000,
    trimf: 1500,
    1.5: 1670250,
    2.5: 1603583,
    3.5: 1526083,
    4.5: 1465666,
  },
  {
    1: 1695650,
    2: 1641483,
    3: 1558150,
    4: 1488150,
    5: 1430650,
    "revenu brut": 4531_000,
    trimf: 1500,
    1.5: 1670650,
    2.5: 1603983,
    3.5: 1526483,
    4.5: 1466066,
  },
  {
    1: 1696050,
    2: 1641883,
    3: 1558550,
    4: 1488550,
    5: 1431050,
    "revenu brut": 4532000,
    trimf: 1500,
    1.5: 1671050,
    2.5: 1604383,
    3.5: 1526883,
    4.5: 1466466,
  },
  {
    1: 1696450,
    2: 1642283,
    3: 1558950,
    4: 1488950,
    5: 1431450,
    "revenu brut": 4533000,
    trimf: 1500,
    1.5: 1671450,
    2.5: 1604783,
    3.5: 1527283,
    4.5: 1466866,
  },
  {
    1: 1696850,
    2: 1642683,
    3: 1559350,
    4: 1489350,
    5: 1431850,
    "revenu brut": 4534000,
    trimf: 1500,
    1.5: 1671850,
    2.5: 1605183,
    3.5: 1527683,
    4.5: 1467266,
  },
  {
    1: 1697250,
    2: 1643083,
    3: 1559750,
    4: 1489750,
    5: 1432250,
    "revenu brut": 4535000,
    trimf: 1500,
    1.5: 1672250,
    2.5: 1605583,
    3.5: 1528083,
    4.5: 1467666,
  },
  {
    1: 1697650,
    2: 1643483,
    3: 1560150,
    4: 1490150,
    5: 1432650,
    "revenu brut": 4536000,
    trimf: 1500,
    1.5: 1672650,
    2.5: 1605983,
    3.5: 1528483,
    4.5: 1468066,
  },
  {
    1: 1698050,
    2: 1643883,
    3: 1560550,
    4: 1490550,
    5: 1433050,
    "revenu brut": 4537000,
    trimf: 1500,
    1.5: 1673050,
    2.5: 1606383,
    3.5: 1528883,
    4.5: 1468466,
  },
  {
    1: 1698450,
    2: 1644283,
    3: 1560950,
    4: 1490950,
    5: 1433450,
    "revenu brut": 4538000,
    trimf: 1500,
    1.5: 1673450,
    2.5: 1606783,
    3.5: 1529283,
    4.5: 1468866,
  },
  {
    1: 1698850,
    2: 1644683,
    3: 1561350,
    4: 1491350,
    5: 1433850,
    "revenu brut": 4539000,
    trimf: 1500,
    1.5: 1673850,
    2.5: 1607183,
    3.5: 1529683,
    4.5: 1469266,
  },
  {
    1: 1699250,
    2: 1645083,
    3: 1561750,
    4: 1491750,
    5: 1434250,
    "revenu brut": 4540000,
    trimf: 1500,
    1.5: 1674250,
    2.5: 1607583,
    3.5: 1530083,
    4.5: 1469666,
  },
  {
    1: 1699650,
    2: 1645483,
    3: 1562150,
    4: 1492150,
    5: 1434650,
    "revenu brut": 4541_000,
    trimf: 1500,
    1.5: 1674650,
    2.5: 1607983,
    3.5: 1530483,
    4.5: 1470066,
  },
  {
    1: 1700050,
    2: 1645883,
    3: 1562550,
    4: 1492550,
    5: 1435050,
    "revenu brut": 4542000,
    trimf: 1500,
    1.5: 1675050,
    2.5: 1608383,
    3.5: 1530883,
    4.5: 1470466,
  },
  {
    1: 1700450,
    2: 1646283,
    3: 1562950,
    4: 1492950,
    5: 1435450,
    "revenu brut": 4543000,
    trimf: 1500,
    1.5: 1675450,
    2.5: 1608783,
    3.5: 1531283,
    4.5: 1470866,
  },
  {
    1: 1700850,
    2: 1646683,
    3: 1563350,
    4: 1493350,
    5: 1435850,
    "revenu brut": 4544000,
    trimf: 1500,
    1.5: 1675850,
    2.5: 1609183,
    3.5: 1531683,
    4.5: 1471266,
  },
  {
    1: 1701250,
    2: 1647083,
    3: 1563750,
    4: 1493750,
    5: 1436250,
    "revenu brut": 4545000,
    trimf: 1500,
    1.5: 1676250,
    2.5: 1609583,
    3.5: 1532083,
    4.5: 1471666,
  },
  {
    1: 1701650,
    2: 1647483,
    3: 1564150,
    4: 1494150,
    5: 1436650,
    "revenu brut": 4546000,
    trimf: 1500,
    1.5: 1676650,
    2.5: 1609983,
    3.5: 1532483,
    4.5: 1472066,
  },
  {
    1: 1702050,
    2: 1647883,
    3: 1564550,
    4: 1494550,
    5: 1437050,
    "revenu brut": 4547000,
    trimf: 1500,
    1.5: 1677050,
    2.5: 1610383,
    3.5: 1532883,
    4.5: 1472466,
  },
  {
    1: 1702450,
    2: 1648283,
    3: 1564950,
    4: 1494950,
    5: 1437450,
    "revenu brut": 4548000,
    trimf: 1500,
    1.5: 1677450,
    2.5: 1610783,
    3.5: 1533283,
    4.5: 1472866,
  },
  {
    1: 1702850,
    2: 1648683,
    3: 1565350,
    4: 1495350,
    5: 1437850,
    "revenu brut": 4549000,
    trimf: 1500,
    1.5: 1677850,
    2.5: 1611183,
    3.5: 1533683,
    4.5: 1473266,
  },
  {
    1: 1703250,
    2: 1649083,
    3: 1565750,
    4: 1495750,
    5: 1438250,
    "revenu brut": 4550000,
    trimf: 1500,
    1.5: 1678250,
    2.5: 1611583,
    3.5: 1534083,
    4.5: 1473666,
  },
  {
    1: 1703650,
    2: 1649483,
    3: 1566150,
    4: 1496150,
    5: 1438650,
    "revenu brut": 4551_000,
    trimf: 1500,
    1.5: 1678650,
    2.5: 1611983,
    3.5: 1534483,
    4.5: 1474066,
  },
  {
    1: 1704050,
    2: 1649883,
    3: 1566550,
    4: 1496550,
    5: 1439050,
    "revenu brut": 4552000,
    trimf: 1500,
    1.5: 1679050,
    2.5: 1612383,
    3.5: 1534883,
    4.5: 1474466,
  },
  {
    1: 1704450,
    2: 1650283,
    3: 1566950,
    4: 1496950,
    5: 1439450,
    "revenu brut": 4553000,
    trimf: 1500,
    1.5: 1679450,
    2.5: 1612783,
    3.5: 1535283,
    4.5: 1474866,
  },
  {
    1: 1704850,
    2: 1650683,
    3: 1567350,
    4: 1497350,
    5: 1439850,
    "revenu brut": 4554000,
    trimf: 1500,
    1.5: 1679850,
    2.5: 1613183,
    3.5: 1535683,
    4.5: 1475266,
  },
  {
    1: 1705250,
    2: 1651083,
    3: 1567750,
    4: 1497750,
    5: 1440250,
    "revenu brut": 4555000,
    trimf: 1500,
    1.5: 1680250,
    2.5: 1613583,
    3.5: 1536083,
    4.5: 1475666,
  },
  {
    1: 1705650,
    2: 1651483,
    3: 1568150,
    4: 1498150,
    5: 1440650,
    "revenu brut": 4556000,
    trimf: 1500,
    1.5: 1680650,
    2.5: 1613983,
    3.5: 1536483,
    4.5: 1476066,
  },
  {
    1: 1706050,
    2: 1651883,
    3: 1568550,
    4: 1498550,
    5: 1441050,
    "revenu brut": 4557000,
    trimf: 1500,
    1.5: 1681050,
    2.5: 1614383,
    3.5: 1536883,
    4.5: 1476466,
  },
  {
    1: 1706450,
    2: 1652283,
    3: 1568950,
    4: 1498950,
    5: 1441450,
    "revenu brut": 4558000,
    trimf: 1500,
    1.5: 1681450,
    2.5: 1614783,
    3.5: 1537283,
    4.5: 1476866,
  },
  {
    1: 1706850,
    2: 1652683,
    3: 1569350,
    4: 1499350,
    5: 1441850,
    "revenu brut": 4559000,
    trimf: 1500,
    1.5: 1681850,
    2.5: 1615183,
    3.5: 1537683,
    4.5: 1477266,
  },
  {
    1: 1707250,
    2: 1653083,
    3: 1569750,
    4: 1499750,
    5: 1442250,
    "revenu brut": 4560000,
    trimf: 1500,
    1.5: 1682250,
    2.5: 1615583,
    3.5: 1538083,
    4.5: 1477666,
  },
  {
    1: 1707650,
    2: 1653483,
    3: 1570150,
    4: 1500150,
    5: 1442650,
    "revenu brut": 4561_000,
    trimf: 1500,
    1.5: 1682650,
    2.5: 1615983,
    3.5: 1538483,
    4.5: 1478066,
  },
  {
    1: 1708050,
    2: 1653883,
    3: 1570550,
    4: 1500550,
    5: 1443050,
    "revenu brut": 4562000,
    trimf: 1500,
    1.5: 1683050,
    2.5: 1616383,
    3.5: 1538883,
    4.5: 1478466,
  },
  {
    1: 1708450,
    2: 1654283,
    3: 1570950,
    4: 1500950,
    5: 1443450,
    "revenu brut": 4563000,
    trimf: 1500,
    1.5: 1683450,
    2.5: 1616783,
    3.5: 1539283,
    4.5: 1478866,
  },
  {
    1: 1708850,
    2: 1654683,
    3: 1571350,
    4: 1501350,
    5: 1443850,
    "revenu brut": 4564000,
    trimf: 1500,
    1.5: 1683850,
    2.5: 1617183,
    3.5: 1539683,
    4.5: 1479266,
  },
  {
    1: 1709250,
    2: 1655083,
    3: 1571750,
    4: 1501750,
    5: 1444250,
    "revenu brut": 4565000,
    trimf: 1500,
    1.5: 1684250,
    2.5: 1617583,
    3.5: 1540083,
    4.5: 1479666,
  },
  {
    1: 1709650,
    2: 1655483,
    3: 1572150,
    4: 1502150,
    5: 1444650,
    "revenu brut": 4566000,
    trimf: 1500,
    1.5: 1684650,
    2.5: 1617983,
    3.5: 1540483,
    4.5: 1480066,
  },
  {
    1: 1710050,
    2: 1655883,
    3: 1572550,
    4: 1502550,
    5: 1445050,
    "revenu brut": 4567000,
    trimf: 1500,
    1.5: 1685050,
    2.5: 1618383,
    3.5: 1540883,
    4.5: 1480466,
  },
  {
    1: 1710450,
    2: 1656283,
    3: 1572950,
    4: 1502950,
    5: 1445450,
    "revenu brut": 4568000,
    trimf: 1500,
    1.5: 1685450,
    2.5: 1618783,
    3.5: 1541283,
    4.5: 1480866,
  },
  {
    1: 1710850,
    2: 1656683,
    3: 1573350,
    4: 1503350,
    5: 1445850,
    "revenu brut": 4569000,
    trimf: 1500,
    1.5: 1685850,
    2.5: 1619183,
    3.5: 1541683,
    4.5: 1481266,
  },
  {
    1: 1711250,
    2: 1657083,
    3: 1573750,
    4: 1503750,
    5: 1446250,
    "revenu brut": 4570000,
    trimf: 1500,
    1.5: 1686250,
    2.5: 1619583,
    3.5: 1542083,
    4.5: 1481666,
  },
  {
    1: 1711650,
    2: 1657483,
    3: 1574150,
    4: 1504150,
    5: 1446650,
    "revenu brut": 4571_000,
    trimf: 1500,
    1.5: 1686650,
    2.5: 1619983,
    3.5: 1542483,
    4.5: 1482066,
  },
  {
    1: 1712050,
    2: 1657883,
    3: 1574550,
    4: 1504550,
    5: 1447050,
    "revenu brut": 4572000,
    trimf: 1500,
    1.5: 1687050,
    2.5: 1620383,
    3.5: 1542883,
    4.5: 1482466,
  },
  {
    1: 1712450,
    2: 1658283,
    3: 1574950,
    4: 1504950,
    5: 1447450,
    "revenu brut": 4573000,
    trimf: 1500,
    1.5: 1687450,
    2.5: 1620783,
    3.5: 1543283,
    4.5: 1482866,
  },
  {
    1: 1712850,
    2: 1658683,
    3: 1575350,
    4: 1505350,
    5: 1447850,
    "revenu brut": 4574000,
    trimf: 1500,
    1.5: 1687850,
    2.5: 1621183,
    3.5: 1543683,
    4.5: 1483266,
  },
  {
    1: 1713250,
    2: 1659083,
    3: 1575750,
    4: 1505750,
    5: 1448250,
    "revenu brut": 4575000,
    trimf: 1500,
    1.5: 1688250,
    2.5: 1621583,
    3.5: 1544083,
    4.5: 1483666,
  },
  {
    1: 1713650,
    2: 1659483,
    3: 1576150,
    4: 1506150,
    5: 1448650,
    "revenu brut": 4576000,
    trimf: 1500,
    1.5: 1688650,
    2.5: 1621983,
    3.5: 1544483,
    4.5: 1484066,
  },
  {
    1: 1714050,
    2: 1659883,
    3: 1576550,
    4: 1506550,
    5: 1449050,
    "revenu brut": 4577000,
    trimf: 1500,
    1.5: 1689050,
    2.5: 1622383,
    3.5: 1544883,
    4.5: 1484466,
  },
  {
    1: 1714450,
    2: 1660283,
    3: 1576950,
    4: 1506950,
    5: 1449450,
    "revenu brut": 4578000,
    trimf: 1500,
    1.5: 1689450,
    2.5: 1622783,
    3.5: 1545283,
    4.5: 1484866,
  },
  {
    1: 1714850,
    2: 1660683,
    3: 1577350,
    4: 1507350,
    5: 1449850,
    "revenu brut": 4579000,
    trimf: 1500,
    1.5: 1689850,
    2.5: 1623183,
    3.5: 1545683,
    4.5: 1485266,
  },
  {
    1: 1715250,
    2: 1661083,
    3: 1577750,
    4: 1507750,
    5: 1450250,
    "revenu brut": 4580000,
    trimf: 1500,
    1.5: 1690250,
    2.5: 1623583,
    3.5: 1546083,
    4.5: 1485666,
  },
  {
    1: 1715650,
    2: 1661483,
    3: 1578150,
    4: 1508150,
    5: 1450650,
    "revenu brut": 4581_000,
    trimf: 1500,
    1.5: 1690650,
    2.5: 1623983,
    3.5: 1546483,
    4.5: 1486066,
  },
  {
    1: 1716050,
    2: 1661883,
    3: 1578550,
    4: 1508550,
    5: 1451050,
    "revenu brut": 4582000,
    trimf: 1500,
    1.5: 1691050,
    2.5: 1624383,
    3.5: 1546883,
    4.5: 1486466,
  },
  {
    1: 1716450,
    2: 1662283,
    3: 1578950,
    4: 1508950,
    5: 1451450,
    "revenu brut": 4583000,
    trimf: 1500,
    1.5: 1691450,
    2.5: 1624783,
    3.5: 1547283,
    4.5: 1486866,
  },
  {
    1: 1716850,
    2: 1662683,
    3: 1579350,
    4: 1509350,
    5: 1451850,
    "revenu brut": 4584000,
    trimf: 1500,
    1.5: 1691850,
    2.5: 1625183,
    3.5: 1547683,
    4.5: 1487266,
  },
  {
    1: 1717250,
    2: 1663083,
    3: 1579750,
    4: 1509750,
    5: 1452250,
    "revenu brut": 4585000,
    trimf: 1500,
    1.5: 1692250,
    2.5: 1625583,
    3.5: 1548083,
    4.5: 1487666,
  },
  {
    1: 1717650,
    2: 1663483,
    3: 1580150,
    4: 1510150,
    5: 1452650,
    "revenu brut": 4586000,
    trimf: 1500,
    1.5: 1692650,
    2.5: 1625983,
    3.5: 1548483,
    4.5: 1488066,
  },
  {
    1: 1718050,
    2: 1663883,
    3: 1580550,
    4: 1510550,
    5: 1453050,
    "revenu brut": 4587000,
    trimf: 1500,
    1.5: 1693050,
    2.5: 1626383,
    3.5: 1548883,
    4.5: 1488466,
  },
  {
    1: 1718450,
    2: 1664283,
    3: 1580950,
    4: 1510950,
    5: 1453450,
    "revenu brut": 4588000,
    trimf: 1500,
    1.5: 1693450,
    2.5: 1626783,
    3.5: 1549283,
    4.5: 1488866,
  },
  {
    1: 1718850,
    2: 1664683,
    3: 1581350,
    4: 1511350,
    5: 1453850,
    "revenu brut": 4589000,
    trimf: 1500,
    1.5: 1693850,
    2.5: 1627183,
    3.5: 1549683,
    4.5: 1489266,
  },
  {
    1: 1719250,
    2: 1665083,
    3: 1581750,
    4: 1511750,
    5: 1454250,
    "revenu brut": 4590000,
    trimf: 1500,
    1.5: 1694250,
    2.5: 1627583,
    3.5: 1550083,
    4.5: 1489666,
  },
  {
    1: 1719650,
    2: 1665483,
    3: 1582150,
    4: 1512150,
    5: 1454650,
    "revenu brut": 4591_000,
    trimf: 1500,
    1.5: 1694650,
    2.5: 1627983,
    3.5: 1550483,
    4.5: 1490066,
  },
  {
    1: 1720050,
    2: 1665883,
    3: 1582550,
    4: 1512550,
    5: 1455050,
    "revenu brut": 4592000,
    trimf: 1500,
    1.5: 1695050,
    2.5: 1628383,
    3.5: 1550883,
    4.5: 1490466,
  },
  {
    1: 1720450,
    2: 1666283,
    3: 1582950,
    4: 1512950,
    5: 1455450,
    "revenu brut": 4593000,
    trimf: 1500,
    1.5: 1695450,
    2.5: 1628783,
    3.5: 1551283,
    4.5: 1490866,
  },
  {
    1: 1720850,
    2: 1666683,
    3: 1583350,
    4: 1513350,
    5: 1455850,
    "revenu brut": 4594000,
    trimf: 1500,
    1.5: 1695850,
    2.5: 1629183,
    3.5: 1551683,
    4.5: 1491266,
  },
  {
    1: 1721250,
    2: 1667083,
    3: 1583750,
    4: 1513750,
    5: 1456250,
    "revenu brut": 4595000,
    trimf: 1500,
    1.5: 1696250,
    2.5: 1629583,
    3.5: 1552083,
    4.5: 1491666,
  },
  {
    1: 1721650,
    2: 1667483,
    3: 1584150,
    4: 1514150,
    5: 1456650,
    "revenu brut": 4596000,
    trimf: 1500,
    1.5: 1696650,
    2.5: 1629983,
    3.5: 1552483,
    4.5: 1492066,
  },
  {
    1: 1722050,
    2: 1667883,
    3: 1584550,
    4: 1514550,
    5: 1457050,
    "revenu brut": 4597000,
    trimf: 1500,
    1.5: 1697050,
    2.5: 1630383,
    3.5: 1552883,
    4.5: 1492466,
  },
  {
    1: 1722450,
    2: 1668283,
    3: 1584950,
    4: 1514950,
    5: 1457450,
    "revenu brut": 4598000,
    trimf: 1500,
    1.5: 1697450,
    2.5: 1630783,
    3.5: 1553283,
    4.5: 1492866,
  },
  {
    1: 1722850,
    2: 1668683,
    3: 1585350,
    4: 1515350,
    5: 1457850,
    "revenu brut": 4599000,
    trimf: 1500,
    1.5: 1697850,
    2.5: 1631183,
    3.5: 1553683,
    4.5: 1493266,
  },
  {
    1: 1723250,
    2: 1669083,
    3: 1585750,
    4: 1515750,
    5: 1458250,
    "revenu brut": 4600000,
    trimf: 1500,
    1.5: 1698250,
    2.5: 1631583,
    3.5: 1554083,
    4.5: 1493666,
  },
  {
    1: 1723650,
    2: 1669483,
    3: 1586150,
    4: 1516150,
    5: 1458650,
    "revenu brut": 4601_000,
    trimf: 1500,
    1.5: 1698650,
    2.5: 1631983,
    3.5: 1554483,
    4.5: 1494066,
  },
  {
    1: 1724050,
    2: 1669883,
    3: 1586550,
    4: 1516550,
    5: 1459050,
    "revenu brut": 4602000,
    trimf: 1500,
    1.5: 1699050,
    2.5: 1632383,
    3.5: 1554883,
    4.5: 1494466,
  },
  {
    1: 1724450,
    2: 1670283,
    3: 1586950,
    4: 1516950,
    5: 1459450,
    "revenu brut": 4603000,
    trimf: 1500,
    1.5: 1699450,
    2.5: 1632783,
    3.5: 1555283,
    4.5: 1494866,
  },
  {
    1: 1724850,
    2: 1670683,
    3: 1587350,
    4: 1517350,
    5: 1459850,
    "revenu brut": 4604000,
    trimf: 1500,
    1.5: 1699850,
    2.5: 1633183,
    3.5: 1555683,
    4.5: 1495266,
  },
  {
    1: 1725250,
    2: 1671083,
    3: 1587750,
    4: 1517750,
    5: 1460250,
    "revenu brut": 4605000,
    trimf: 1500,
    1.5: 1700250,
    2.5: 1633583,
    3.5: 1556083,
    4.5: 1495666,
  },
  {
    1: 1725650,
    2: 1671483,
    3: 1588150,
    4: 1518150,
    5: 1460650,
    "revenu brut": 4606000,
    trimf: 1500,
    1.5: 1700650,
    2.5: 1633983,
    3.5: 1556483,
    4.5: 1496066,
  },
  {
    1: 1726050,
    2: 1671883,
    3: 1588550,
    4: 1518550,
    5: 1461050,
    "revenu brut": 4607000,
    trimf: 1500,
    1.5: 1701050,
    2.5: 1634383,
    3.5: 1556883,
    4.5: 1496466,
  },
  {
    1: 1726450,
    2: 1672283,
    3: 1588950,
    4: 1518950,
    5: 1461450,
    "revenu brut": 4608000,
    trimf: 1500,
    1.5: 1701450,
    2.5: 1634783,
    3.5: 1557283,
    4.5: 1496866,
  },
  {
    1: 1726850,
    2: 1672683,
    3: 1589350,
    4: 1519350,
    5: 1461850,
    "revenu brut": 4609000,
    trimf: 1500,
    1.5: 1701850,
    2.5: 1635183,
    3.5: 1557683,
    4.5: 1497266,
  },
  {
    1: 1727250,
    2: 1673083,
    3: 1589750,
    4: 1519750,
    5: 1462250,
    "revenu brut": 461_0000,
    trimf: 1500,
    1.5: 1702250,
    2.5: 1635583,
    3.5: 1558083,
    4.5: 1497666,
  },
  {
    1: 1727650,
    2: 1673483,
    3: 1590150,
    4: 1520150,
    5: 1462650,
    "revenu brut": 4611_000,
    trimf: 1500,
    1.5: 1702650,
    2.5: 1635983,
    3.5: 1558483,
    4.5: 1498066,
  },
  {
    1: 1728050,
    2: 1673883,
    3: 1590550,
    4: 1520550,
    5: 1463050,
    "revenu brut": 4612000,
    trimf: 1500,
    1.5: 1703050,
    2.5: 1636383,
    3.5: 1558883,
    4.5: 1498466,
  },
  {
    1: 1728450,
    2: 1674283,
    3: 1590950,
    4: 1520950,
    5: 1463450,
    "revenu brut": 4613000,
    trimf: 1500,
    1.5: 1703450,
    2.5: 1636783,
    3.5: 1559283,
    4.5: 1498866,
  },
  {
    1: 1728850,
    2: 1674683,
    3: 1591350,
    4: 1521350,
    5: 1463850,
    "revenu brut": 4614000,
    trimf: 1500,
    1.5: 1703850,
    2.5: 1637183,
    3.5: 1559683,
    4.5: 1499266,
  },
  {
    1: 1729250,
    2: 1675083,
    3: 1591750,
    4: 1521750,
    5: 1464250,
    "revenu brut": 4615000,
    trimf: 1500,
    1.5: 1704250,
    2.5: 1637583,
    3.5: 1560083,
    4.5: 1499666,
  },
  {
    1: 1729650,
    2: 1675483,
    3: 1592150,
    4: 1522150,
    5: 1464650,
    "revenu brut": 4616000,
    trimf: 1500,
    1.5: 1704650,
    2.5: 1637983,
    3.5: 1560483,
    4.5: 1500066,
  },
  {
    1: 1730050,
    2: 1675883,
    3: 1592550,
    4: 1522550,
    5: 1465050,
    "revenu brut": 4617000,
    trimf: 1500,
    1.5: 1705050,
    2.5: 1638383,
    3.5: 1560883,
    4.5: 1500466,
  },
  {
    1: 1730450,
    2: 1676283,
    3: 1592950,
    4: 1522950,
    5: 1465450,
    "revenu brut": 4618000,
    trimf: 1500,
    1.5: 1705450,
    2.5: 1638783,
    3.5: 1561283,
    4.5: 1500866,
  },
  {
    1: 1730850,
    2: 1676683,
    3: 1593350,
    4: 1523350,
    5: 1465850,
    "revenu brut": 4619000,
    trimf: 1500,
    1.5: 1705850,
    2.5: 1639183,
    3.5: 1561683,
    4.5: 1501266,
  },
  {
    1: 1731250,
    2: 1677083,
    3: 1593750,
    4: 1523750,
    5: 1466250,
    "revenu brut": 4620000,
    trimf: 1500,
    1.5: 1706250,
    2.5: 1639583,
    3.5: 1562083,
    4.5: 1501666,
  },
  {
    1: 1731650,
    2: 1677483,
    3: 1594150,
    4: 1524150,
    5: 1466650,
    "revenu brut": 4621_000,
    trimf: 1500,
    1.5: 1706650,
    2.5: 1639983,
    3.5: 1562483,
    4.5: 1502066,
  },
  {
    1: 1732050,
    2: 1677883,
    3: 1594550,
    4: 1524550,
    5: 1467050,
    "revenu brut": 4622000,
    trimf: 1500,
    1.5: 1707050,
    2.5: 1640383,
    3.5: 1562883,
    4.5: 1502466,
  },
  {
    1: 1732450,
    2: 1678283,
    3: 1594950,
    4: 1524950,
    5: 1467450,
    "revenu brut": 4623000,
    trimf: 1500,
    1.5: 1707450,
    2.5: 1640783,
    3.5: 1563283,
    4.5: 1502866,
  },
  {
    1: 1732850,
    2: 1678683,
    3: 1595350,
    4: 1525350,
    5: 1467850,
    "revenu brut": 4624000,
    trimf: 1500,
    1.5: 1707850,
    2.5: 1641183,
    3.5: 1563683,
    4.5: 1503266,
  },
  {
    1: 1733250,
    2: 1679083,
    3: 1595750,
    4: 1525750,
    5: 1468250,
    "revenu brut": 4625000,
    trimf: 1500,
    1.5: 1708250,
    2.5: 1641583,
    3.5: 1564083,
    4.5: 1503666,
  },
  {
    1: 1733650,
    2: 1679483,
    3: 1596150,
    4: 1526150,
    5: 1468650,
    "revenu brut": 4626000,
    trimf: 1500,
    1.5: 1708650,
    2.5: 1641983,
    3.5: 1564483,
    4.5: 1504066,
  },
  {
    1: 1734050,
    2: 1679883,
    3: 1596550,
    4: 1526550,
    5: 1469050,
    "revenu brut": 4627000,
    trimf: 1500,
    1.5: 1709050,
    2.5: 1642383,
    3.5: 1564883,
    4.5: 1504466,
  },
  {
    1: 1734450,
    2: 1680283,
    3: 1596950,
    4: 1526950,
    5: 1469450,
    "revenu brut": 4628000,
    trimf: 1500,
    1.5: 1709450,
    2.5: 1642783,
    3.5: 1565283,
    4.5: 1504866,
  },
  {
    1: 1734850,
    2: 1680683,
    3: 1597350,
    4: 1527350,
    5: 1469850,
    "revenu brut": 4629000,
    trimf: 1500,
    1.5: 1709850,
    2.5: 1643183,
    3.5: 1565683,
    4.5: 1505266,
  },
  {
    1: 1735250,
    2: 1681083,
    3: 1597750,
    4: 1527750,
    5: 1470250,
    "revenu brut": 4630000,
    trimf: 1500,
    1.5: 1710250,
    2.5: 1643583,
    3.5: 1566083,
    4.5: 1505666,
  },
  {
    1: 1735650,
    2: 1681483,
    3: 1598150,
    4: 1528150,
    5: 1470650,
    "revenu brut": 4631_000,
    trimf: 1500,
    1.5: 1710650,
    2.5: 1643983,
    3.5: 1566483,
    4.5: 1506066,
  },
  {
    1: 1736050,
    2: 1681883,
    3: 1598550,
    4: 1528550,
    5: 1471050,
    "revenu brut": 4632000,
    trimf: 1500,
    1.5: 1711050,
    2.5: 1644383,
    3.5: 1566883,
    4.5: 1506466,
  },
  {
    1: 1736450,
    2: 1682283,
    3: 1598950,
    4: 1528950,
    5: 1471450,
    "revenu brut": 4633000,
    trimf: 1500,
    1.5: 1711450,
    2.5: 1644783,
    3.5: 1567283,
    4.5: 1506866,
  },
  {
    1: 1736850,
    2: 1682683,
    3: 1599350,
    4: 1529350,
    5: 1471850,
    "revenu brut": 4634000,
    trimf: 1500,
    1.5: 1711850,
    2.5: 1645183,
    3.5: 1567683,
    4.5: 1507266,
  },
  {
    1: 1737250,
    2: 1683083,
    3: 1599750,
    4: 1529750,
    5: 1472250,
    "revenu brut": 4635000,
    trimf: 1500,
    1.5: 1712250,
    2.5: 1645583,
    3.5: 1568083,
    4.5: 1507666,
  },
  {
    1: 1737650,
    2: 1683483,
    3: 1600150,
    4: 1530150,
    5: 1472650,
    "revenu brut": 4636000,
    trimf: 1500,
    1.5: 1712650,
    2.5: 1645983,
    3.5: 1568483,
    4.5: 1508066,
  },
  {
    1: 1738050,
    2: 1683883,
    3: 1600550,
    4: 1530550,
    5: 1473050,
    "revenu brut": 4637000,
    trimf: 1500,
    1.5: 1713050,
    2.5: 1646383,
    3.5: 1568883,
    4.5: 1508466,
  },
  {
    1: 1738450,
    2: 1684283,
    3: 1600950,
    4: 1530950,
    5: 1473450,
    "revenu brut": 4638000,
    trimf: 1500,
    1.5: 1713450,
    2.5: 1646783,
    3.5: 1569283,
    4.5: 1508866,
  },
  {
    1: 1738850,
    2: 1684683,
    3: 1601350,
    4: 1531350,
    5: 1473850,
    "revenu brut": 4639000,
    trimf: 1500,
    1.5: 1713850,
    2.5: 1647183,
    3.5: 1569683,
    4.5: 1509266,
  },
  {
    1: 1739250,
    2: 1685083,
    3: 1601750,
    4: 1531750,
    5: 1474250,
    "revenu brut": 4640000,
    trimf: 1500,
    1.5: 1714250,
    2.5: 1647583,
    3.5: 1570083,
    4.5: 1509666,
  },
  {
    1: 1739650,
    2: 1685483,
    3: 1602150,
    4: 1532150,
    5: 1474650,
    "revenu brut": 4641_000,
    trimf: 1500,
    1.5: 1714650,
    2.5: 1647983,
    3.5: 1570483,
    4.5: 1510066,
  },
  {
    1: 1740050,
    2: 1685883,
    3: 1602550,
    4: 1532550,
    5: 1475050,
    "revenu brut": 4642000,
    trimf: 1500,
    1.5: 1715050,
    2.5: 1648383,
    3.5: 1570883,
    4.5: 1510466,
  },
  {
    1: 1740450,
    2: 1686283,
    3: 1602950,
    4: 1532950,
    5: 1475450,
    "revenu brut": 4643000,
    trimf: 1500,
    1.5: 1715450,
    2.5: 1648783,
    3.5: 1571283,
    4.5: 1510866,
  },
  {
    1: 1740850,
    2: 1686683,
    3: 1603350,
    4: 1533350,
    5: 1475850,
    "revenu brut": 4644000,
    trimf: 1500,
    1.5: 1715850,
    2.5: 1649183,
    3.5: 1571683,
    4.5: 1511266,
  },
  {
    1: 1741250,
    2: 1687083,
    3: 1603750,
    4: 1533750,
    5: 1476250,
    "revenu brut": 4645000,
    trimf: 1500,
    1.5: 1716250,
    2.5: 1649583,
    3.5: 1572083,
    4.5: 1511666,
  },
  {
    1: 1741650,
    2: 1687483,
    3: 1604150,
    4: 1534150,
    5: 1476650,
    "revenu brut": 4646000,
    trimf: 1500,
    1.5: 1716650,
    2.5: 1649983,
    3.5: 1572483,
    4.5: 1512066,
  },
  {
    1: 1742050,
    2: 1687883,
    3: 1604550,
    4: 1534550,
    5: 1477050,
    "revenu brut": 4647000,
    trimf: 1500,
    1.5: 1717050,
    2.5: 1650383,
    3.5: 1572883,
    4.5: 1512466,
  },
  {
    1: 1742450,
    2: 1688283,
    3: 1604950,
    4: 1534950,
    5: 1477450,
    "revenu brut": 4648000,
    trimf: 1500,
    1.5: 1717450,
    2.5: 1650783,
    3.5: 1573283,
    4.5: 1512866,
  },
  {
    1: 1742850,
    2: 1688683,
    3: 1605350,
    4: 1535350,
    5: 1477850,
    "revenu brut": 4649000,
    trimf: 1500,
    1.5: 1717850,
    2.5: 1651183,
    3.5: 1573683,
    4.5: 1513266,
  },
  {
    1: 1743250,
    2: 1689083,
    3: 1605750,
    4: 1535750,
    5: 1478250,
    "revenu brut": 4650000,
    trimf: 1500,
    1.5: 1718250,
    2.5: 1651583,
    3.5: 1574083,
    4.5: 1513666,
  },
  {
    1: 1743650,
    2: 1689483,
    3: 1606150,
    4: 1536150,
    5: 1478650,
    "revenu brut": 4651_000,
    trimf: 1500,
    1.5: 1718650,
    2.5: 1651983,
    3.5: 1574483,
    4.5: 1514066,
  },
  {
    1: 1744050,
    2: 1689883,
    3: 1606550,
    4: 1536550,
    5: 1479050,
    "revenu brut": 4652000,
    trimf: 1500,
    1.5: 1719050,
    2.5: 1652383,
    3.5: 1574883,
    4.5: 1514466,
  },
  {
    1: 1744450,
    2: 1690283,
    3: 1606950,
    4: 1536950,
    5: 1479450,
    "revenu brut": 4653000,
    trimf: 1500,
    1.5: 1719450,
    2.5: 1652783,
    3.5: 1575283,
    4.5: 1514866,
  },
  {
    1: 1744850,
    2: 1690683,
    3: 1607350,
    4: 1537350,
    5: 1479850,
    "revenu brut": 4654000,
    trimf: 1500,
    1.5: 1719850,
    2.5: 1653183,
    3.5: 1575683,
    4.5: 1515266,
  },
  {
    1: 1745250,
    2: 1691083,
    3: 1607750,
    4: 1537750,
    5: 1480250,
    "revenu brut": 4655000,
    trimf: 1500,
    1.5: 1720250,
    2.5: 1653583,
    3.5: 1576083,
    4.5: 1515666,
  },
  {
    1: 1745650,
    2: 1691483,
    3: 1608150,
    4: 1538150,
    5: 1480650,
    "revenu brut": 4656000,
    trimf: 1500,
    1.5: 1720650,
    2.5: 1653983,
    3.5: 1576483,
    4.5: 1516066,
  },
  {
    1: 1746050,
    2: 1691883,
    3: 1608550,
    4: 1538550,
    5: 1481050,
    "revenu brut": 4657000,
    trimf: 1500,
    1.5: 1721050,
    2.5: 1654383,
    3.5: 1576883,
    4.5: 1516466,
  },
  {
    1: 1746450,
    2: 1692283,
    3: 1608950,
    4: 1538950,
    5: 1481450,
    "revenu brut": 4658000,
    trimf: 1500,
    1.5: 1721450,
    2.5: 1654783,
    3.5: 1577283,
    4.5: 1516866,
  },
  {
    1: 1746850,
    2: 1692683,
    3: 1609350,
    4: 1539350,
    5: 1481850,
    "revenu brut": 4659000,
    trimf: 1500,
    1.5: 1721850,
    2.5: 1655183,
    3.5: 1577683,
    4.5: 1517266,
  },
  {
    1: 1747250,
    2: 1693083,
    3: 1609750,
    4: 1539750,
    5: 1482250,
    "revenu brut": 4660000,
    trimf: 1500,
    1.5: 1722250,
    2.5: 1655583,
    3.5: 1578083,
    4.5: 1517666,
  },
  {
    1: 1747650,
    2: 1693483,
    3: 1610150,
    4: 1540150,
    5: 1482650,
    "revenu brut": 4661_000,
    trimf: 1500,
    1.5: 1722650,
    2.5: 1655983,
    3.5: 1578483,
    4.5: 1518066,
  },
  {
    1: 1748050,
    2: 1693883,
    3: 1610550,
    4: 1540550,
    5: 1483050,
    "revenu brut": 4662000,
    trimf: 1500,
    1.5: 1723050,
    2.5: 1656383,
    3.5: 1578883,
    4.5: 1518466,
  },
  {
    1: 1748450,
    2: 1694283,
    3: 1610950,
    4: 1540950,
    5: 1483450,
    "revenu brut": 4663000,
    trimf: 1500,
    1.5: 1723450,
    2.5: 1656783,
    3.5: 1579283,
    4.5: 1518866,
  },
  {
    1: 1748850,
    2: 1694683,
    3: 1611350,
    4: 1541350,
    5: 1483850,
    "revenu brut": 4664000,
    trimf: 1500,
    1.5: 1723850,
    2.5: 1657183,
    3.5: 1579683,
    4.5: 1519266,
  },
  {
    1: 1749250,
    2: 1695083,
    3: 1611750,
    4: 1541750,
    5: 1484250,
    "revenu brut": 4665000,
    trimf: 1500,
    1.5: 1724250,
    2.5: 1657583,
    3.5: 1580083,
    4.5: 1519666,
  },
  {
    1: 1749650,
    2: 1695483,
    3: 1612150,
    4: 1542150,
    5: 1484650,
    "revenu brut": 4666000,
    trimf: 1500,
    1.5: 1724650,
    2.5: 1657983,
    3.5: 1580483,
    4.5: 1520066,
  },
  {
    1: 1750050,
    2: 1695883,
    3: 1612550,
    4: 1542550,
    5: 1485050,
    "revenu brut": 4667000,
    trimf: 1500,
    1.5: 1725050,
    2.5: 1658383,
    3.5: 1580883,
    4.5: 1520466,
  },
  {
    1: 1750450,
    2: 1696283,
    3: 1612950,
    4: 1542950,
    5: 1485450,
    "revenu brut": 4668000,
    trimf: 1500,
    1.5: 1725450,
    2.5: 1658783,
    3.5: 1581283,
    4.5: 1520866,
  },
  {
    1: 1750850,
    2: 1696683,
    3: 1613350,
    4: 1543350,
    5: 1485850,
    "revenu brut": 4669000,
    trimf: 1500,
    1.5: 1725850,
    2.5: 1659183,
    3.5: 1581683,
    4.5: 1521266,
  },
  {
    1: 1751250,
    2: 1697083,
    3: 1613750,
    4: 1543750,
    5: 1486250,
    "revenu brut": 4670000,
    trimf: 1500,
    1.5: 1726250,
    2.5: 1659583,
    3.5: 1582083,
    4.5: 1521666,
  },
  {
    1: 1751650,
    2: 1697483,
    3: 1614150,
    4: 1544150,
    5: 1486650,
    "revenu brut": 4671_000,
    trimf: 1500,
    1.5: 1726650,
    2.5: 1659983,
    3.5: 1582483,
    4.5: 1522066,
  },
  {
    1: 1752050,
    2: 1697883,
    3: 1614550,
    4: 1544550,
    5: 1487050,
    "revenu brut": 4672000,
    trimf: 1500,
    1.5: 1727050,
    2.5: 1660383,
    3.5: 1582883,
    4.5: 1522466,
  },
  {
    1: 1752450,
    2: 1698283,
    3: 1614950,
    4: 1544950,
    5: 1487450,
    "revenu brut": 4673000,
    trimf: 1500,
    1.5: 1727450,
    2.5: 1660783,
    3.5: 1583283,
    4.5: 1522866,
  },
  {
    1: 1752850,
    2: 1698683,
    3: 1615350,
    4: 1545350,
    5: 1487850,
    "revenu brut": 4674000,
    trimf: 1500,
    1.5: 1727850,
    2.5: 1661183,
    3.5: 1583683,
    4.5: 1523266,
  },
  {
    1: 1753250,
    2: 1699083,
    3: 1615750,
    4: 1545750,
    5: 1488250,
    "revenu brut": 4675000,
    trimf: 1500,
    1.5: 1728250,
    2.5: 1661583,
    3.5: 1584083,
    4.5: 1523666,
  },
  {
    1: 1753650,
    2: 1699483,
    3: 1616150,
    4: 1546150,
    5: 1488650,
    "revenu brut": 4676000,
    trimf: 1500,
    1.5: 1728650,
    2.5: 1661983,
    3.5: 1584483,
    4.5: 1524066,
  },
  {
    1: 1754050,
    2: 1699883,
    3: 1616550,
    4: 1546550,
    5: 1489050,
    "revenu brut": 4677000,
    trimf: 1500,
    1.5: 1729050,
    2.5: 1662383,
    3.5: 1584883,
    4.5: 1524466,
  },
  {
    1: 1754450,
    2: 1700283,
    3: 1616950,
    4: 1546950,
    5: 1489450,
    "revenu brut": 4678000,
    trimf: 1500,
    1.5: 1729450,
    2.5: 1662783,
    3.5: 1585283,
    4.5: 1524866,
  },
  {
    1: 1754850,
    2: 1700683,
    3: 1617350,
    4: 1547350,
    5: 1489850,
    "revenu brut": 4679000,
    trimf: 1500,
    1.5: 1729850,
    2.5: 1663183,
    3.5: 1585683,
    4.5: 1525266,
  },
  {
    1: 1755250,
    2: 1701083,
    3: 1617750,
    4: 1547750,
    5: 1490250,
    "revenu brut": 4680000,
    trimf: 1500,
    1.5: 1730250,
    2.5: 1663583,
    3.5: 1586083,
    4.5: 1525666,
  },
  {
    1: 1755650,
    2: 1701483,
    3: 1618150,
    4: 1548150,
    5: 1490650,
    "revenu brut": 4681_000,
    trimf: 1500,
    1.5: 1730650,
    2.5: 1663983,
    3.5: 1586483,
    4.5: 1526066,
  },
  {
    1: 1756050,
    2: 1701883,
    3: 1618550,
    4: 1548550,
    5: 1491050,
    "revenu brut": 4682000,
    trimf: 1500,
    1.5: 1731050,
    2.5: 1664383,
    3.5: 1586883,
    4.5: 1526466,
  },
  {
    1: 1756450,
    2: 1702283,
    3: 1618950,
    4: 1548950,
    5: 1491450,
    "revenu brut": 4683000,
    trimf: 1500,
    1.5: 1731450,
    2.5: 1664783,
    3.5: 1587283,
    4.5: 1526866,
  },
  {
    1: 1756850,
    2: 1702683,
    3: 1619350,
    4: 1549350,
    5: 1491850,
    "revenu brut": 4684000,
    trimf: 1500,
    1.5: 1731850,
    2.5: 1665183,
    3.5: 1587683,
    4.5: 1527266,
  },
  {
    1: 1757250,
    2: 1703083,
    3: 1619750,
    4: 1549750,
    5: 1492250,
    "revenu brut": 4685000,
    trimf: 1500,
    1.5: 1732250,
    2.5: 1665583,
    3.5: 1588083,
    4.5: 1527666,
  },
  {
    1: 1757650,
    2: 1703483,
    3: 1620150,
    4: 1550150,
    5: 1492650,
    "revenu brut": 4686000,
    trimf: 1500,
    1.5: 1732650,
    2.5: 1665983,
    3.5: 1588483,
    4.5: 1528066,
  },
  {
    1: 1758050,
    2: 1703883,
    3: 1620550,
    4: 1550550,
    5: 1493050,
    "revenu brut": 4687000,
    trimf: 1500,
    1.5: 1733050,
    2.5: 1666383,
    3.5: 1588883,
    4.5: 1528466,
  },
  {
    1: 1758450,
    2: 1704283,
    3: 1620950,
    4: 1550950,
    5: 1493450,
    "revenu brut": 4688000,
    trimf: 1500,
    1.5: 1733450,
    2.5: 1666783,
    3.5: 1589283,
    4.5: 1528866,
  },
  {
    1: 1758850,
    2: 1704683,
    3: 1621350,
    4: 1551350,
    5: 1493850,
    "revenu brut": 4689000,
    trimf: 1500,
    1.5: 1733850,
    2.5: 1667183,
    3.5: 1589683,
    4.5: 1529266,
  },
  {
    1: 1759250,
    2: 1705083,
    3: 1621750,
    4: 1551750,
    5: 1494250,
    "revenu brut": 4690000,
    trimf: 1500,
    1.5: 1734250,
    2.5: 1667583,
    3.5: 1590083,
    4.5: 1529666,
  },
  {
    1: 1759650,
    2: 1705483,
    3: 1622150,
    4: 1552150,
    5: 1494650,
    "revenu brut": 4691_000,
    trimf: 1500,
    1.5: 1734650,
    2.5: 1667983,
    3.5: 1590483,
    4.5: 1530066,
  },
  {
    1: 1760050,
    2: 1705883,
    3: 1622550,
    4: 1552550,
    5: 1495050,
    "revenu brut": 4692000,
    trimf: 1500,
    1.5: 1735050,
    2.5: 1668383,
    3.5: 1590883,
    4.5: 1530466,
  },
  {
    1: 1760450,
    2: 1706283,
    3: 1622950,
    4: 1552950,
    5: 1495450,
    "revenu brut": 4693000,
    trimf: 1500,
    1.5: 1735450,
    2.5: 1668783,
    3.5: 1591283,
    4.5: 1530866,
  },
  {
    1: 1760850,
    2: 1706683,
    3: 1623350,
    4: 1553350,
    5: 1495850,
    "revenu brut": 4694000,
    trimf: 1500,
    1.5: 1735850,
    2.5: 1669183,
    3.5: 1591683,
    4.5: 1531266,
  },
  {
    1: 1761250,
    2: 1707083,
    3: 1623750,
    4: 1553750,
    5: 1496250,
    "revenu brut": 4695000,
    trimf: 1500,
    1.5: 1736250,
    2.5: 1669583,
    3.5: 1592083,
    4.5: 1531666,
  },
  {
    1: 1761650,
    2: 1707483,
    3: 1624150,
    4: 1554150,
    5: 1496650,
    "revenu brut": 4696000,
    trimf: 1500,
    1.5: 1736650,
    2.5: 1669983,
    3.5: 1592483,
    4.5: 1532066,
  },
  {
    1: 1762050,
    2: 1707883,
    3: 1624550,
    4: 1554550,
    5: 1497050,
    "revenu brut": 4697000,
    trimf: 1500,
    1.5: 1737050,
    2.5: 1670383,
    3.5: 1592883,
    4.5: 1532466,
  },
  {
    1: 1762450,
    2: 1708283,
    3: 1624950,
    4: 1554950,
    5: 1497450,
    "revenu brut": 4698000,
    trimf: 1500,
    1.5: 1737450,
    2.5: 1670783,
    3.5: 1593283,
    4.5: 1532866,
  },
  {
    1: 1762850,
    2: 1708683,
    3: 1625350,
    4: 1555350,
    5: 1497850,
    "revenu brut": 4699000,
    trimf: 1500,
    1.5: 1737850,
    2.5: 1671183,
    3.5: 1593683,
    4.5: 1533266,
  },
  {
    1: 1763250,
    2: 1709083,
    3: 1625750,
    4: 1555750,
    5: 1498250,
    "revenu brut": 4700000,
    trimf: 1500,
    1.5: 1738250,
    2.5: 1671583,
    3.5: 1594083,
    4.5: 1533666,
  },
  {
    1: 1763650,
    2: 1709483,
    3: 1626150,
    4: 1556150,
    5: 1498650,
    "revenu brut": 4701_000,
    trimf: 1500,
    1.5: 1738650,
    2.5: 1671983,
    3.5: 1594483,
    4.5: 1534066,
  },
  {
    1: 1764050,
    2: 1709883,
    3: 1626550,
    4: 1556550,
    5: 1499050,
    "revenu brut": 4702000,
    trimf: 1500,
    1.5: 1739050,
    2.5: 1672383,
    3.5: 1594883,
    4.5: 1534466,
  },
  {
    1: 1764450,
    2: 1710283,
    3: 1626950,
    4: 1556950,
    5: 1499450,
    "revenu brut": 4703000,
    trimf: 1500,
    1.5: 1739450,
    2.5: 1672783,
    3.5: 1595283,
    4.5: 1534866,
  },
  {
    1: 1764850,
    2: 1710683,
    3: 1627350,
    4: 1557350,
    5: 1499850,
    "revenu brut": 4704000,
    trimf: 1500,
    1.5: 1739850,
    2.5: 1673183,
    3.5: 1595683,
    4.5: 1535266,
  },
  {
    1: 1765250,
    2: 1711083,
    3: 1627750,
    4: 1557750,
    5: 1500250,
    "revenu brut": 4705000,
    trimf: 1500,
    1.5: 1740250,
    2.5: 1673583,
    3.5: 1596083,
    4.5: 1535666,
  },
  {
    1: 1765650,
    2: 1711483,
    3: 1628150,
    4: 1558150,
    5: 1500650,
    "revenu brut": 4706000,
    trimf: 1500,
    1.5: 1740650,
    2.5: 1673983,
    3.5: 1596483,
    4.5: 1536066,
  },
  {
    1: 1766050,
    2: 1711883,
    3: 1628550,
    4: 1558550,
    5: 1501050,
    "revenu brut": 4707000,
    trimf: 1500,
    1.5: 1741050,
    2.5: 1674383,
    3.5: 1596883,
    4.5: 1536466,
  },
  {
    1: 1766450,
    2: 1712283,
    3: 1628950,
    4: 1558950,
    5: 1501450,
    "revenu brut": 4708000,
    trimf: 1500,
    1.5: 1741450,
    2.5: 1674783,
    3.5: 1597283,
    4.5: 1536866,
  },
  {
    1: 1766850,
    2: 1712683,
    3: 1629350,
    4: 1559350,
    5: 1501850,
    "revenu brut": 4709000,
    trimf: 1500,
    1.5: 1741850,
    2.5: 1675183,
    3.5: 1597683,
    4.5: 1537266,
  },
  {
    1: 1767250,
    2: 1713083,
    3: 1629750,
    4: 1559750,
    5: 1502250,
    "revenu brut": 471_0000,
    trimf: 1500,
    1.5: 1742250,
    2.5: 1675583,
    3.5: 1598083,
    4.5: 1537666,
  },
  {
    1: 1767650,
    2: 1713483,
    3: 1630150,
    4: 1560150,
    5: 1502650,
    "revenu brut": 4711_000,
    trimf: 1500,
    1.5: 1742650,
    2.5: 1675983,
    3.5: 1598483,
    4.5: 1538066,
  },
  {
    1: 1768050,
    2: 1713883,
    3: 1630550,
    4: 1560550,
    5: 1503050,
    "revenu brut": 4712000,
    trimf: 1500,
    1.5: 1743050,
    2.5: 1676383,
    3.5: 1598883,
    4.5: 1538466,
  },
  {
    1: 1768450,
    2: 1714283,
    3: 1630950,
    4: 1560950,
    5: 1503450,
    "revenu brut": 4713000,
    trimf: 1500,
    1.5: 1743450,
    2.5: 1676783,
    3.5: 1599283,
    4.5: 1538866,
  },
  {
    1: 1768850,
    2: 1714683,
    3: 1631350,
    4: 1561350,
    5: 1503850,
    "revenu brut": 4714000,
    trimf: 1500,
    1.5: 1743850,
    2.5: 1677183,
    3.5: 1599683,
    4.5: 1539266,
  },
  {
    1: 1769250,
    2: 1715083,
    3: 1631750,
    4: 1561750,
    5: 1504250,
    "revenu brut": 4715000,
    trimf: 1500,
    1.5: 1744250,
    2.5: 1677583,
    3.5: 1600083,
    4.5: 1539666,
  },
  {
    1: 1769650,
    2: 1715483,
    3: 1632150,
    4: 1562150,
    5: 1504650,
    "revenu brut": 4716000,
    trimf: 1500,
    1.5: 1744650,
    2.5: 1677983,
    3.5: 1600483,
    4.5: 1540066,
  },
  {
    1: 1770050,
    2: 1715883,
    3: 1632550,
    4: 1562550,
    5: 1505050,
    "revenu brut": 4717000,
    trimf: 1500,
    1.5: 1745050,
    2.5: 1678383,
    3.5: 1600883,
    4.5: 1540466,
  },
  {
    1: 1770450,
    2: 1716283,
    3: 1632950,
    4: 1562950,
    5: 1505450,
    "revenu brut": 4718000,
    trimf: 1500,
    1.5: 1745450,
    2.5: 1678783,
    3.5: 1601283,
    4.5: 1540866,
  },
  {
    1: 1770850,
    2: 1716683,
    3: 1633350,
    4: 1563350,
    5: 1505850,
    "revenu brut": 4719000,
    trimf: 1500,
    1.5: 1745850,
    2.5: 1679183,
    3.5: 1601683,
    4.5: 1541266,
  },
  {
    1: 1771250,
    2: 1717083,
    3: 1633750,
    4: 1563750,
    5: 1506250,
    "revenu brut": 4720000,
    trimf: 1500,
    1.5: 1746250,
    2.5: 1679583,
    3.5: 1602083,
    4.5: 1541666,
  },
  {
    1: 1771650,
    2: 1717483,
    3: 1634150,
    4: 1564150,
    5: 1506650,
    "revenu brut": 4721_000,
    trimf: 1500,
    1.5: 1746650,
    2.5: 1679983,
    3.5: 1602483,
    4.5: 1542066,
  },
  {
    1: 1772050,
    2: 1717883,
    3: 1634550,
    4: 1564550,
    5: 1507050,
    "revenu brut": 4722000,
    trimf: 1500,
    1.5: 1747050,
    2.5: 1680383,
    3.5: 1602883,
    4.5: 1542466,
  },
  {
    1: 1772450,
    2: 1718283,
    3: 1634950,
    4: 1564950,
    5: 1507450,
    "revenu brut": 4723000,
    trimf: 1500,
    1.5: 1747450,
    2.5: 1680783,
    3.5: 1603283,
    4.5: 1542866,
  },
  {
    1: 1772850,
    2: 1718683,
    3: 1635350,
    4: 1565350,
    5: 1507850,
    "revenu brut": 4724000,
    trimf: 1500,
    1.5: 1747850,
    2.5: 1681183,
    3.5: 1603683,
    4.5: 1543266,
  },
  {
    1: 1773250,
    2: 1719083,
    3: 1635750,
    4: 1565750,
    5: 1508250,
    "revenu brut": 4725000,
    trimf: 1500,
    1.5: 1748250,
    2.5: 1681583,
    3.5: 1604083,
    4.5: 1543666,
  },
  {
    1: 1773650,
    2: 1719483,
    3: 1636150,
    4: 1566150,
    5: 1508650,
    "revenu brut": 4726000,
    trimf: 1500,
    1.5: 1748650,
    2.5: 1681983,
    3.5: 1604483,
    4.5: 1544066,
  },
  {
    1: 1774050,
    2: 1719883,
    3: 1636550,
    4: 1566550,
    5: 1509050,
    "revenu brut": 4727000,
    trimf: 1500,
    1.5: 1749050,
    2.5: 1682383,
    3.5: 1604883,
    4.5: 1544466,
  },
  {
    1: 1774450,
    2: 1720283,
    3: 1636950,
    4: 1566950,
    5: 1509450,
    "revenu brut": 4728000,
    trimf: 1500,
    1.5: 1749450,
    2.5: 1682783,
    3.5: 1605283,
    4.5: 1544866,
  },
  {
    1: 1774850,
    2: 1720683,
    3: 1637350,
    4: 1567350,
    5: 1509850,
    "revenu brut": 4729000,
    trimf: 1500,
    1.5: 1749850,
    2.5: 1683183,
    3.5: 1605683,
    4.5: 1545266,
  },
  {
    1: 1775250,
    2: 1721083,
    3: 1637750,
    4: 1567750,
    5: 1510250,
    "revenu brut": 4730000,
    trimf: 1500,
    1.5: 1750250,
    2.5: 1683583,
    3.5: 1606083,
    4.5: 1545666,
  },
  {
    1: 1775650,
    2: 1721483,
    3: 1638150,
    4: 1568150,
    5: 1510650,
    "revenu brut": 4731_000,
    trimf: 1500,
    1.5: 1750650,
    2.5: 1683983,
    3.5: 1606483,
    4.5: 1546066,
  },
  {
    1: 1776050,
    2: 1721883,
    3: 1638550,
    4: 1568550,
    5: 1511050,
    "revenu brut": 4732000,
    trimf: 1500,
    1.5: 1751050,
    2.5: 1684383,
    3.5: 1606883,
    4.5: 1546466,
  },
  {
    1: 1776450,
    2: 1722283,
    3: 1638950,
    4: 1568950,
    5: 1511450,
    "revenu brut": 4733000,
    trimf: 1500,
    1.5: 1751450,
    2.5: 1684783,
    3.5: 1607283,
    4.5: 1546866,
  },
  {
    1: 1776850,
    2: 1722683,
    3: 1639350,
    4: 1569350,
    5: 1511850,
    "revenu brut": 4734000,
    trimf: 1500,
    1.5: 1751850,
    2.5: 1685183,
    3.5: 1607683,
    4.5: 1547266,
  },
  {
    1: 1777250,
    2: 1723083,
    3: 1639750,
    4: 1569750,
    5: 1512250,
    "revenu brut": 4735000,
    trimf: 1500,
    1.5: 1752250,
    2.5: 1685583,
    3.5: 1608083,
    4.5: 1547666,
  },
  {
    1: 1777650,
    2: 1723483,
    3: 1640150,
    4: 1570150,
    5: 1512650,
    "revenu brut": 4736000,
    trimf: 1500,
    1.5: 1752650,
    2.5: 1685983,
    3.5: 1608483,
    4.5: 1548066,
  },
  {
    1: 1778050,
    2: 1723883,
    3: 1640550,
    4: 1570550,
    5: 1513050,
    "revenu brut": 4737000,
    trimf: 1500,
    1.5: 1753050,
    2.5: 1686383,
    3.5: 1608883,
    4.5: 1548466,
  },
  {
    1: 1778450,
    2: 1724283,
    3: 1640950,
    4: 1570950,
    5: 1513450,
    "revenu brut": 4738000,
    trimf: 1500,
    1.5: 1753450,
    2.5: 1686783,
    3.5: 1609283,
    4.5: 1548866,
  },
  {
    1: 1778850,
    2: 1724683,
    3: 1641350,
    4: 1571350,
    5: 1513850,
    "revenu brut": 4739000,
    trimf: 1500,
    1.5: 1753850,
    2.5: 1687183,
    3.5: 1609683,
    4.5: 1549266,
  },
  {
    1: 1779250,
    2: 1725083,
    3: 1641750,
    4: 1571750,
    5: 1514250,
    "revenu brut": 4740000,
    trimf: 1500,
    1.5: 1754250,
    2.5: 1687583,
    3.5: 1610083,
    4.5: 1549666,
  },
  {
    1: 1779650,
    2: 1725483,
    3: 1642150,
    4: 1572150,
    5: 1514650,
    "revenu brut": 4741_000,
    trimf: 1500,
    1.5: 1754650,
    2.5: 1687983,
    3.5: 1610483,
    4.5: 1550066,
  },
  {
    1: 1780050,
    2: 1725883,
    3: 1642550,
    4: 1572550,
    5: 1515050,
    "revenu brut": 4742000,
    trimf: 1500,
    1.5: 1755050,
    2.5: 1688383,
    3.5: 1610883,
    4.5: 1550466,
  },
  {
    1: 1780450,
    2: 1726283,
    3: 1642950,
    4: 1572950,
    5: 1515450,
    "revenu brut": 4743000,
    trimf: 1500,
    1.5: 1755450,
    2.5: 1688783,
    3.5: 1611283,
    4.5: 1550866,
  },
  {
    1: 1780850,
    2: 1726683,
    3: 1643350,
    4: 1573350,
    5: 1515850,
    "revenu brut": 4744000,
    trimf: 1500,
    1.5: 1755850,
    2.5: 1689183,
    3.5: 1611683,
    4.5: 1551266,
  },
  {
    1: 1781250,
    2: 1727083,
    3: 1643750,
    4: 1573750,
    5: 1516250,
    "revenu brut": 4745000,
    trimf: 1500,
    1.5: 1756250,
    2.5: 1689583,
    3.5: 1612083,
    4.5: 1551666,
  },
  {
    1: 1781650,
    2: 1727483,
    3: 1644150,
    4: 1574150,
    5: 1516650,
    "revenu brut": 4746000,
    trimf: 1500,
    1.5: 1756650,
    2.5: 1689983,
    3.5: 1612483,
    4.5: 1552066,
  },
  {
    1: 1782050,
    2: 1727883,
    3: 1644550,
    4: 1574550,
    5: 1517050,
    "revenu brut": 4747000,
    trimf: 1500,
    1.5: 1757050,
    2.5: 1690383,
    3.5: 1612883,
    4.5: 1552466,
  },
  {
    1: 1782450,
    2: 1728283,
    3: 1644950,
    4: 1574950,
    5: 1517450,
    "revenu brut": 4748000,
    trimf: 1500,
    1.5: 1757450,
    2.5: 1690783,
    3.5: 1613283,
    4.5: 1552866,
  },
  {
    1: 1782850,
    2: 1728683,
    3: 1645350,
    4: 1575350,
    5: 1517850,
    "revenu brut": 4749000,
    trimf: 1500,
    1.5: 1757850,
    2.5: 1691183,
    3.5: 1613683,
    4.5: 1553266,
  },
  {
    1: 1783250,
    2: 1729083,
    3: 1645750,
    4: 1575750,
    5: 1518250,
    "revenu brut": 4750000,
    trimf: 1500,
    1.5: 1758250,
    2.5: 1691583,
    3.5: 1614083,
    4.5: 1553666,
  },
  {
    1: 1783650,
    2: 1729483,
    3: 1646150,
    4: 1576150,
    5: 1518650,
    "revenu brut": 4751_000,
    trimf: 1500,
    1.5: 1758650,
    2.5: 1691983,
    3.5: 1614483,
    4.5: 1554066,
  },
  {
    1: 1784050,
    2: 1729883,
    3: 1646550,
    4: 1576550,
    5: 1519050,
    "revenu brut": 4752000,
    trimf: 1500,
    1.5: 1759050,
    2.5: 1692383,
    3.5: 1614883,
    4.5: 1554466,
  },
  {
    1: 1784450,
    2: 1730283,
    3: 1646950,
    4: 1576950,
    5: 1519450,
    "revenu brut": 4753000,
    trimf: 1500,
    1.5: 1759450,
    2.5: 1692783,
    3.5: 1615283,
    4.5: 1554866,
  },
  {
    1: 1784850,
    2: 1730683,
    3: 1647350,
    4: 1577350,
    5: 1519850,
    "revenu brut": 4754000,
    trimf: 1500,
    1.5: 1759850,
    2.5: 1693183,
    3.5: 1615683,
    4.5: 1555266,
  },
  {
    1: 1785250,
    2: 1731083,
    3: 1647750,
    4: 1577750,
    5: 1520250,
    "revenu brut": 4755000,
    trimf: 1500,
    1.5: 1760250,
    2.5: 1693583,
    3.5: 1616083,
    4.5: 1555666,
  },
  {
    1: 1785650,
    2: 1731483,
    3: 1648150,
    4: 1578150,
    5: 1520650,
    "revenu brut": 4756000,
    trimf: 1500,
    1.5: 1760650,
    2.5: 1693983,
    3.5: 1616483,
    4.5: 1556066,
  },
  {
    1: 1786050,
    2: 1731883,
    3: 1648550,
    4: 1578550,
    5: 1521050,
    "revenu brut": 4757000,
    trimf: 1500,
    1.5: 1761050,
    2.5: 1694383,
    3.5: 1616883,
    4.5: 1556466,
  },
  {
    1: 1786450,
    2: 1732283,
    3: 1648950,
    4: 1578950,
    5: 1521450,
    "revenu brut": 4758000,
    trimf: 1500,
    1.5: 1761450,
    2.5: 1694783,
    3.5: 1617283,
    4.5: 1556866,
  },
  {
    1: 1786850,
    2: 1732683,
    3: 1649350,
    4: 1579350,
    5: 1521850,
    "revenu brut": 4759000,
    trimf: 1500,
    1.5: 1761850,
    2.5: 1695183,
    3.5: 1617683,
    4.5: 1557266,
  },
  {
    1: 1787250,
    2: 1733083,
    3: 1649750,
    4: 1579750,
    5: 1522250,
    "revenu brut": 4760000,
    trimf: 1500,
    1.5: 1762250,
    2.5: 1695583,
    3.5: 1618083,
    4.5: 1557666,
  },
  {
    1: 1787650,
    2: 1733483,
    3: 1650150,
    4: 1580150,
    5: 1522650,
    "revenu brut": 4761_000,
    trimf: 1500,
    1.5: 1762650,
    2.5: 1695983,
    3.5: 1618483,
    4.5: 1558066,
  },
  {
    1: 1788050,
    2: 1733883,
    3: 1650550,
    4: 1580550,
    5: 1523050,
    "revenu brut": 4762000,
    trimf: 1500,
    1.5: 1763050,
    2.5: 1696383,
    3.5: 1618883,
    4.5: 1558466,
  },
  {
    1: 1788450,
    2: 1734283,
    3: 1650950,
    4: 1580950,
    5: 1523450,
    "revenu brut": 4763000,
    trimf: 1500,
    1.5: 1763450,
    2.5: 1696783,
    3.5: 1619283,
    4.5: 1558866,
  },
  {
    1: 1788850,
    2: 1734683,
    3: 1651350,
    4: 1581350,
    5: 1523850,
    "revenu brut": 4764000,
    trimf: 1500,
    1.5: 1763850,
    2.5: 1697183,
    3.5: 1619683,
    4.5: 1559266,
  },
  {
    1: 1789250,
    2: 1735083,
    3: 1651750,
    4: 1581750,
    5: 1524250,
    "revenu brut": 4765000,
    trimf: 1500,
    1.5: 1764250,
    2.5: 1697583,
    3.5: 1620083,
    4.5: 1559666,
  },
  {
    1: 1789650,
    2: 1735483,
    3: 1652150,
    4: 1582150,
    5: 1524650,
    "revenu brut": 4766000,
    trimf: 1500,
    1.5: 1764650,
    2.5: 1697983,
    3.5: 1620483,
    4.5: 1560066,
  },
  {
    1: 1790050,
    2: 1735883,
    3: 1652550,
    4: 1582550,
    5: 1525050,
    "revenu brut": 4767000,
    trimf: 1500,
    1.5: 1765050,
    2.5: 1698383,
    3.5: 1620883,
    4.5: 1560466,
  },
  {
    1: 1790450,
    2: 1736283,
    3: 1652950,
    4: 1582950,
    5: 1525450,
    "revenu brut": 4768000,
    trimf: 1500,
    1.5: 1765450,
    2.5: 1698783,
    3.5: 1621283,
    4.5: 1560866,
  },
  {
    1: 1790850,
    2: 1736683,
    3: 1653350,
    4: 1583350,
    5: 1525850,
    "revenu brut": 4769000,
    trimf: 1500,
    1.5: 1765850,
    2.5: 1699183,
    3.5: 1621683,
    4.5: 1561266,
  },
  {
    1: 1791250,
    2: 1737083,
    3: 1653750,
    4: 1583750,
    5: 1526250,
    "revenu brut": 4770000,
    trimf: 1500,
    1.5: 1766250,
    2.5: 1699583,
    3.5: 1622083,
    4.5: 1561666,
  },
  {
    1: 1791650,
    2: 1737483,
    3: 1654150,
    4: 1584150,
    5: 1526650,
    "revenu brut": 4771_000,
    trimf: 1500,
    1.5: 1766650,
    2.5: 1699983,
    3.5: 1622483,
    4.5: 1562066,
  },
  {
    1: 1792050,
    2: 1737883,
    3: 1654550,
    4: 1584550,
    5: 1527050,
    "revenu brut": 4772000,
    trimf: 1500,
    1.5: 1767050,
    2.5: 1700383,
    3.5: 1622883,
    4.5: 1562466,
  },
  {
    1: 1792450,
    2: 1738283,
    3: 1654950,
    4: 1584950,
    5: 1527450,
    "revenu brut": 4773000,
    trimf: 1500,
    1.5: 1767450,
    2.5: 1700783,
    3.5: 1623283,
    4.5: 1562866,
  },
  {
    1: 1792850,
    2: 1738683,
    3: 1655350,
    4: 1585350,
    5: 1527850,
    "revenu brut": 4774000,
    trimf: 1500,
    1.5: 1767850,
    2.5: 1701183,
    3.5: 1623683,
    4.5: 1563266,
  },
  {
    1: 1793250,
    2: 1739083,
    3: 1655750,
    4: 1585750,
    5: 1528250,
    "revenu brut": 4775000,
    trimf: 1500,
    1.5: 1768250,
    2.5: 1701583,
    3.5: 1624083,
    4.5: 1563666,
  },
  {
    1: 1793650,
    2: 1739483,
    3: 1656150,
    4: 1586150,
    5: 1528650,
    "revenu brut": 4776000,
    trimf: 1500,
    1.5: 1768650,
    2.5: 1701983,
    3.5: 1624483,
    4.5: 1564066,
  },
  {
    1: 1794050,
    2: 1739883,
    3: 1656550,
    4: 1586550,
    5: 1529050,
    "revenu brut": 4777000,
    trimf: 1500,
    1.5: 1769050,
    2.5: 1702383,
    3.5: 1624883,
    4.5: 1564466,
  },
  {
    1: 1794450,
    2: 1740283,
    3: 1656950,
    4: 1586950,
    5: 1529450,
    "revenu brut": 4778000,
    trimf: 1500,
    1.5: 1769450,
    2.5: 1702783,
    3.5: 1625283,
    4.5: 1564866,
  },
  {
    1: 1794850,
    2: 1740683,
    3: 1657350,
    4: 1587350,
    5: 1529850,
    "revenu brut": 4779000,
    trimf: 1500,
    1.5: 1769850,
    2.5: 1703183,
    3.5: 1625683,
    4.5: 1565266,
  },
  {
    1: 1795250,
    2: 1741083,
    3: 1657750,
    4: 1587750,
    5: 1530250,
    "revenu brut": 4780000,
    trimf: 1500,
    1.5: 1770250,
    2.5: 1703583,
    3.5: 1626083,
    4.5: 1565666,
  },
  {
    1: 1795650,
    2: 1741483,
    3: 1658150,
    4: 1588150,
    5: 1530650,
    "revenu brut": 4781_000,
    trimf: 1500,
    1.5: 1770650,
    2.5: 1703983,
    3.5: 1626483,
    4.5: 1566066,
  },
  {
    1: 1796050,
    2: 1741883,
    3: 1658550,
    4: 1588550,
    5: 1531050,
    "revenu brut": 4782000,
    trimf: 1500,
    1.5: 1771050,
    2.5: 1704383,
    3.5: 1626883,
    4.5: 1566466,
  },
  {
    1: 1796450,
    2: 1742283,
    3: 1658950,
    4: 1588950,
    5: 1531450,
    "revenu brut": 4783000,
    trimf: 1500,
    1.5: 1771450,
    2.5: 1704783,
    3.5: 1627283,
    4.5: 1566866,
  },
  {
    1: 1796850,
    2: 1742683,
    3: 1659350,
    4: 1589350,
    5: 1531850,
    "revenu brut": 4784000,
    trimf: 1500,
    1.5: 1771850,
    2.5: 1705183,
    3.5: 1627683,
    4.5: 1567266,
  },
  {
    1: 1797250,
    2: 1743083,
    3: 1659750,
    4: 1589750,
    5: 1532250,
    "revenu brut": 4785000,
    trimf: 1500,
    1.5: 1772250,
    2.5: 1705583,
    3.5: 1628083,
    4.5: 1567666,
  },
  {
    1: 1797650,
    2: 1743483,
    3: 1660150,
    4: 1590150,
    5: 1532650,
    "revenu brut": 4786000,
    trimf: 1500,
    1.5: 1772650,
    2.5: 1705983,
    3.5: 1628483,
    4.5: 1568066,
  },
  {
    1: 1798050,
    2: 1743883,
    3: 1660550,
    4: 1590550,
    5: 1533050,
    "revenu brut": 4787000,
    trimf: 1500,
    1.5: 1773050,
    2.5: 1706383,
    3.5: 1628883,
    4.5: 1568466,
  },
  {
    1: 1798450,
    2: 1744283,
    3: 1660950,
    4: 1590950,
    5: 1533450,
    "revenu brut": 4788000,
    trimf: 1500,
    1.5: 1773450,
    2.5: 1706783,
    3.5: 1629283,
    4.5: 1568866,
  },
  {
    1: 1798850,
    2: 1744683,
    3: 1661350,
    4: 1591350,
    5: 1533850,
    "revenu brut": 4789000,
    trimf: 1500,
    1.5: 1773850,
    2.5: 1707183,
    3.5: 1629683,
    4.5: 1569266,
  },
  {
    1: 1799250,
    2: 1745083,
    3: 1661750,
    4: 1591750,
    5: 1534250,
    "revenu brut": 4790000,
    trimf: 1500,
    1.5: 1774250,
    2.5: 1707583,
    3.5: 1630083,
    4.5: 1569666,
  },
  {
    1: 1799650,
    2: 1745483,
    3: 1662150,
    4: 1592150,
    5: 1534650,
    "revenu brut": 4791_000,
    trimf: 1500,
    1.5: 1774650,
    2.5: 1707983,
    3.5: 1630483,
    4.5: 1570066,
  },
  {
    1: 1800050,
    2: 1745883,
    3: 1662550,
    4: 1592550,
    5: 1535050,
    "revenu brut": 4792000,
    trimf: 1500,
    1.5: 1775050,
    2.5: 1708383,
    3.5: 1630883,
    4.5: 1570466,
  },
  {
    1: 1800450,
    2: 1746283,
    3: 1662950,
    4: 1592950,
    5: 1535450,
    "revenu brut": 4793000,
    trimf: 1500,
    1.5: 1775450,
    2.5: 1708783,
    3.5: 1631283,
    4.5: 1570866,
  },
  {
    1: 1800850,
    2: 1746683,
    3: 1663350,
    4: 1593350,
    5: 1535850,
    "revenu brut": 4794000,
    trimf: 1500,
    1.5: 1775850,
    2.5: 1709183,
    3.5: 1631683,
    4.5: 1571266,
  },
  {
    1: 1801250,
    2: 1747083,
    3: 1663750,
    4: 1593750,
    5: 1536250,
    "revenu brut": 4795000,
    trimf: 1500,
    1.5: 1776250,
    2.5: 1709583,
    3.5: 1632083,
    4.5: 1571666,
  },
  {
    1: 1801650,
    2: 1747483,
    3: 1664150,
    4: 1594150,
    5: 1536650,
    "revenu brut": 4796000,
    trimf: 1500,
    1.5: 1776650,
    2.5: 1709983,
    3.5: 1632483,
    4.5: 1572066,
  },
  {
    1: 1802050,
    2: 1747883,
    3: 1664550,
    4: 1594550,
    5: 1537050,
    "revenu brut": 4797000,
    trimf: 1500,
    1.5: 1777050,
    2.5: 1710383,
    3.5: 1632883,
    4.5: 1572466,
  },
  {
    1: 1802450,
    2: 1748283,
    3: 1664950,
    4: 1594950,
    5: 1537450,
    "revenu brut": 4798000,
    trimf: 1500,
    1.5: 1777450,
    2.5: 1710783,
    3.5: 1633283,
    4.5: 1572866,
  },
  {
    1: 1802850,
    2: 1748683,
    3: 1665350,
    4: 1595350,
    5: 1537850,
    "revenu brut": 4799000,
    trimf: 1500,
    1.5: 1777850,
    2.5: 1711183,
    3.5: 1633683,
    4.5: 1573266,
  },
  {
    1: 1803250,
    2: 1749083,
    3: 1665750,
    4: 1595750,
    5: 1538250,
    "revenu brut": 4800000,
    trimf: 1500,
    1.5: 1778250,
    2.5: 1711583,
    3.5: 1634083,
    4.5: 1573666,
  },
  {
    1: 1803650,
    2: 1749483,
    3: 1666150,
    4: 1596150,
    5: 1538650,
    "revenu brut": 4801_000,
    trimf: 1500,
    1.5: 1778650,
    2.5: 1711983,
    3.5: 1634483,
    4.5: 1574066,
  },
  {
    1: 1804050,
    2: 1749883,
    3: 1666550,
    4: 1596550,
    5: 1539050,
    "revenu brut": 4802000,
    trimf: 1500,
    1.5: 1779050,
    2.5: 1712383,
    3.5: 1634883,
    4.5: 1574466,
  },
  {
    1: 1804450,
    2: 1750283,
    3: 1666950,
    4: 1596950,
    5: 1539450,
    "revenu brut": 4803000,
    trimf: 1500,
    1.5: 1779450,
    2.5: 1712783,
    3.5: 1635283,
    4.5: 1574866,
  },
  {
    1: 1804850,
    2: 1750683,
    3: 1667350,
    4: 1597350,
    5: 1539850,
    "revenu brut": 4804000,
    trimf: 1500,
    1.5: 1779850,
    2.5: 1713183,
    3.5: 1635683,
    4.5: 1575266,
  },
  {
    1: 1805250,
    2: 1751083,
    3: 1667750,
    4: 1597750,
    5: 1540250,
    "revenu brut": 4805000,
    trimf: 1500,
    1.5: 1780250,
    2.5: 1713583,
    3.5: 1636083,
    4.5: 1575666,
  },
  {
    1: 1805650,
    2: 1751483,
    3: 1668150,
    4: 1598150,
    5: 1540650,
    "revenu brut": 4806000,
    trimf: 1500,
    1.5: 1780650,
    2.5: 1713983,
    3.5: 1636483,
    4.5: 1576066,
  },
  {
    1: 1806050,
    2: 1751883,
    3: 1668550,
    4: 1598550,
    5: 1541050,
    "revenu brut": 4807000,
    trimf: 1500,
    1.5: 1781050,
    2.5: 1714383,
    3.5: 1636883,
    4.5: 1576466,
  },
  {
    1: 1806450,
    2: 1752283,
    3: 1668950,
    4: 1598950,
    5: 1541450,
    "revenu brut": 4808000,
    trimf: 1500,
    1.5: 1781450,
    2.5: 1714783,
    3.5: 1637283,
    4.5: 1576866,
  },
  {
    1: 1806850,
    2: 1752683,
    3: 1669350,
    4: 1599350,
    5: 1541850,
    "revenu brut": 4809000,
    trimf: 1500,
    1.5: 1781850,
    2.5: 1715183,
    3.5: 1637683,
    4.5: 1577266,
  },
  {
    1: 1807250,
    2: 1753083,
    3: 1669750,
    4: 1599750,
    5: 1542250,
    "revenu brut": 481_0000,
    trimf: 1500,
    1.5: 1782250,
    2.5: 1715583,
    3.5: 1638083,
    4.5: 1577666,
  },
  {
    1: 1807650,
    2: 1753483,
    3: 1670150,
    4: 1600150,
    5: 1542650,
    "revenu brut": 4811_000,
    trimf: 1500,
    1.5: 1782650,
    2.5: 1715983,
    3.5: 1638483,
    4.5: 1578066,
  },
  {
    1: 1808050,
    2: 1753883,
    3: 1670550,
    4: 1600550,
    5: 1543050,
    "revenu brut": 4812000,
    trimf: 1500,
    1.5: 1783050,
    2.5: 1716383,
    3.5: 1638883,
    4.5: 1578466,
  },
  {
    1: 1808450,
    2: 1754283,
    3: 1670950,
    4: 1600950,
    5: 1543450,
    "revenu brut": 4813000,
    trimf: 1500,
    1.5: 1783450,
    2.5: 1716783,
    3.5: 1639283,
    4.5: 1578866,
  },
  {
    1: 1808850,
    2: 1754683,
    3: 1671350,
    4: 1601350,
    5: 1543850,
    "revenu brut": 4814000,
    trimf: 1500,
    1.5: 1783850,
    2.5: 1717183,
    3.5: 1639683,
    4.5: 1579266,
  },
  {
    1: 1809250,
    2: 1755083,
    3: 1671750,
    4: 1601750,
    5: 1544250,
    "revenu brut": 4815000,
    trimf: 1500,
    1.5: 1784250,
    2.5: 1717583,
    3.5: 1640083,
    4.5: 1579666,
  },
  {
    1: 1809650,
    2: 1755483,
    3: 1672150,
    4: 1602150,
    5: 1544650,
    "revenu brut": 4816000,
    trimf: 1500,
    1.5: 1784650,
    2.5: 1717983,
    3.5: 1640483,
    4.5: 1580066,
  },
  {
    1: 1810050,
    2: 1755883,
    3: 1672550,
    4: 1602550,
    5: 1545050,
    "revenu brut": 4817000,
    trimf: 1500,
    1.5: 1785050,
    2.5: 1718383,
    3.5: 1640883,
    4.5: 1580466,
  },
  {
    1: 1810450,
    2: 1756283,
    3: 1672950,
    4: 1602950,
    5: 1545450,
    "revenu brut": 4818000,
    trimf: 1500,
    1.5: 1785450,
    2.5: 1718783,
    3.5: 1641283,
    4.5: 1580866,
  },
  {
    1: 1810850,
    2: 1756683,
    3: 1673350,
    4: 1603350,
    5: 1545850,
    "revenu brut": 4819000,
    trimf: 1500,
    1.5: 1785850,
    2.5: 1719183,
    3.5: 1641683,
    4.5: 1581266,
  },
  {
    1: 1811250,
    2: 1757083,
    3: 1673750,
    4: 1603750,
    5: 1546250,
    "revenu brut": 4820000,
    trimf: 1500,
    1.5: 1786250,
    2.5: 1719583,
    3.5: 1642083,
    4.5: 1581666,
  },
  {
    1: 1811650,
    2: 1757483,
    3: 1674150,
    4: 1604150,
    5: 1546650,
    "revenu brut": 4821_000,
    trimf: 1500,
    1.5: 1786650,
    2.5: 1719983,
    3.5: 1642483,
    4.5: 1582066,
  },
  {
    1: 1812050,
    2: 1757883,
    3: 1674550,
    4: 1604550,
    5: 1547050,
    "revenu brut": 4822000,
    trimf: 1500,
    1.5: 1787050,
    2.5: 1720383,
    3.5: 1642883,
    4.5: 1582466,
  },
  {
    1: 1812450,
    2: 1758283,
    3: 1674950,
    4: 1604950,
    5: 1547450,
    "revenu brut": 4823000,
    trimf: 1500,
    1.5: 1787450,
    2.5: 1720783,
    3.5: 1643283,
    4.5: 1582866,
  },
  {
    1: 1812850,
    2: 1758683,
    3: 1675350,
    4: 1605350,
    5: 1547850,
    "revenu brut": 4824000,
    trimf: 1500,
    1.5: 1787850,
    2.5: 1721183,
    3.5: 1643683,
    4.5: 1583266,
  },
  {
    1: 1813250,
    2: 1759083,
    3: 1675750,
    4: 1605750,
    5: 1548250,
    "revenu brut": 4825000,
    trimf: 1500,
    1.5: 1788250,
    2.5: 1721583,
    3.5: 1644083,
    4.5: 1583666,
  },
  {
    1: 1813650,
    2: 1759483,
    3: 1676150,
    4: 1606150,
    5: 1548650,
    "revenu brut": 4826000,
    trimf: 1500,
    1.5: 1788650,
    2.5: 1721983,
    3.5: 1644483,
    4.5: 1584066,
  },
  {
    1: 1814050,
    2: 1759883,
    3: 1676550,
    4: 1606550,
    5: 1549050,
    "revenu brut": 4827000,
    trimf: 1500,
    1.5: 1789050,
    2.5: 1722383,
    3.5: 1644883,
    4.5: 1584466,
  },
  {
    1: 1814450,
    2: 1760283,
    3: 1676950,
    4: 1606950,
    5: 1549450,
    "revenu brut": 4828000,
    trimf: 1500,
    1.5: 1789450,
    2.5: 1722783,
    3.5: 1645283,
    4.5: 1584866,
  },
  {
    1: 1814850,
    2: 1760683,
    3: 1677350,
    4: 1607350,
    5: 1549850,
    "revenu brut": 4829000,
    trimf: 1500,
    1.5: 1789850,
    2.5: 1723183,
    3.5: 1645683,
    4.5: 1585266,
  },
  {
    1: 1815250,
    2: 1761083,
    3: 1677750,
    4: 1607750,
    5: 1550250,
    "revenu brut": 4830000,
    trimf: 1500,
    1.5: 1790250,
    2.5: 1723583,
    3.5: 1646083,
    4.5: 1585666,
  },
  {
    1: 1815650,
    2: 1761483,
    3: 1678150,
    4: 1608150,
    5: 1550650,
    "revenu brut": 4831_000,
    trimf: 1500,
    1.5: 1790650,
    2.5: 1723983,
    3.5: 1646483,
    4.5: 1586066,
  },
  {
    1: 1816050,
    2: 1761883,
    3: 1678550,
    4: 1608550,
    5: 1551050,
    "revenu brut": 4832000,
    trimf: 1500,
    1.5: 1791050,
    2.5: 1724383,
    3.5: 1646883,
    4.5: 1586466,
  },
  {
    1: 1816450,
    2: 1762283,
    3: 1678950,
    4: 1608950,
    5: 1551450,
    "revenu brut": 4833000,
    trimf: 1500,
    1.5: 1791450,
    2.5: 1724783,
    3.5: 1647283,
    4.5: 1586866,
  },
  {
    1: 1816850,
    2: 1762683,
    3: 1679350,
    4: 1609350,
    5: 1551850,
    "revenu brut": 4834000,
    trimf: 1500,
    1.5: 1791850,
    2.5: 1725183,
    3.5: 1647683,
    4.5: 1587266,
  },
  {
    1: 1817250,
    2: 1763083,
    3: 1679750,
    4: 1609750,
    5: 1552250,
    "revenu brut": 4835000,
    trimf: 1500,
    1.5: 1792250,
    2.5: 1725583,
    3.5: 1648083,
    4.5: 1587666,
  },
  {
    1: 1817650,
    2: 1763483,
    3: 1680150,
    4: 1610150,
    5: 1552650,
    "revenu brut": 4836000,
    trimf: 1500,
    1.5: 1792650,
    2.5: 1725983,
    3.5: 1648483,
    4.5: 1588066,
  },
  {
    1: 1818050,
    2: 1763883,
    3: 1680550,
    4: 1610550,
    5: 1553050,
    "revenu brut": 4837000,
    trimf: 1500,
    1.5: 1793050,
    2.5: 1726383,
    3.5: 1648883,
    4.5: 1588466,
  },
  {
    1: 1818450,
    2: 1764283,
    3: 1680950,
    4: 1610950,
    5: 1553450,
    "revenu brut": 4838000,
    trimf: 1500,
    1.5: 1793450,
    2.5: 1726783,
    3.5: 1649283,
    4.5: 1588866,
  },
  {
    1: 1818850,
    2: 1764683,
    3: 1681350,
    4: 1611350,
    5: 1553850,
    "revenu brut": 4839000,
    trimf: 1500,
    1.5: 1793850,
    2.5: 1727183,
    3.5: 1649683,
    4.5: 1589266,
  },
  {
    1: 1819250,
    2: 1765083,
    3: 1681750,
    4: 1611750,
    5: 1554250,
    "revenu brut": 4840000,
    trimf: 1500,
    1.5: 1794250,
    2.5: 1727583,
    3.5: 1650083,
    4.5: 1589666,
  },
  {
    1: 1819650,
    2: 1765483,
    3: 1682150,
    4: 1612150,
    5: 1554650,
    "revenu brut": 4841_000,
    trimf: 1500,
    1.5: 1794650,
    2.5: 1727983,
    3.5: 1650483,
    4.5: 1590066,
  },
  {
    1: 1820050,
    2: 1765883,
    3: 1682550,
    4: 1612550,
    5: 1555050,
    "revenu brut": 4842000,
    trimf: 1500,
    1.5: 1795050,
    2.5: 1728383,
    3.5: 1650883,
    4.5: 1590466,
  },
  {
    1: 1820450,
    2: 1766283,
    3: 1682950,
    4: 1612950,
    5: 1555450,
    "revenu brut": 4843000,
    trimf: 1500,
    1.5: 1795450,
    2.5: 1728783,
    3.5: 1651283,
    4.5: 1590866,
  },
  {
    1: 1820850,
    2: 1766683,
    3: 1683350,
    4: 1613350,
    5: 1555850,
    "revenu brut": 4844000,
    trimf: 1500,
    1.5: 1795850,
    2.5: 1729183,
    3.5: 1651683,
    4.5: 1591266,
  },
  {
    1: 1821250,
    2: 1767083,
    3: 1683750,
    4: 1613750,
    5: 1556250,
    "revenu brut": 4845000,
    trimf: 1500,
    1.5: 1796250,
    2.5: 1729583,
    3.5: 1652083,
    4.5: 1591666,
  },
  {
    1: 1821650,
    2: 1767483,
    3: 1684150,
    4: 1614150,
    5: 1556650,
    "revenu brut": 4846000,
    trimf: 1500,
    1.5: 1796650,
    2.5: 1729983,
    3.5: 1652483,
    4.5: 1592066,
  },
  {
    1: 1822050,
    2: 1767883,
    3: 1684550,
    4: 1614550,
    5: 1557050,
    "revenu brut": 4847000,
    trimf: 1500,
    1.5: 1797050,
    2.5: 1730383,
    3.5: 1652883,
    4.5: 1592466,
  },
  {
    1: 1822450,
    2: 1768283,
    3: 1684950,
    4: 1614950,
    5: 1557450,
    "revenu brut": 4848000,
    trimf: 1500,
    1.5: 1797450,
    2.5: 1730783,
    3.5: 1653283,
    4.5: 1592866,
  },
  {
    1: 1822850,
    2: 1768683,
    3: 1685350,
    4: 1615350,
    5: 1557850,
    "revenu brut": 4849000,
    trimf: 1500,
    1.5: 1797850,
    2.5: 1731183,
    3.5: 1653683,
    4.5: 1593266,
  },
  {
    1: 1823250,
    2: 1769083,
    3: 1685750,
    4: 1615750,
    5: 1558250,
    "revenu brut": 4850000,
    trimf: 1500,
    1.5: 1798250,
    2.5: 1731583,
    3.5: 1654083,
    4.5: 1593666,
  },
  {
    1: 1823650,
    2: 1769483,
    3: 1686150,
    4: 1616150,
    5: 1558650,
    "revenu brut": 4851_000,
    trimf: 1500,
    1.5: 1798650,
    2.5: 1731983,
    3.5: 1654483,
    4.5: 1594066,
  },
  {
    1: 1824050,
    2: 1769883,
    3: 1686550,
    4: 1616550,
    5: 1559050,
    "revenu brut": 4852000,
    trimf: 1500,
    1.5: 1799050,
    2.5: 1732383,
    3.5: 1654883,
    4.5: 1594466,
  },
  {
    1: 1824450,
    2: 1770283,
    3: 1686950,
    4: 1616950,
    5: 1559450,
    "revenu brut": 4853000,
    trimf: 1500,
    1.5: 1799450,
    2.5: 1732783,
    3.5: 1655283,
    4.5: 1594866,
  },
  {
    1: 1824850,
    2: 1770683,
    3: 1687350,
    4: 1617350,
    5: 1559850,
    "revenu brut": 4854000,
    trimf: 1500,
    1.5: 1799850,
    2.5: 1733183,
    3.5: 1655683,
    4.5: 1595266,
  },
  {
    1: 1825250,
    2: 1771083,
    3: 1687750,
    4: 1617750,
    5: 1560250,
    "revenu brut": 4855000,
    trimf: 1500,
    1.5: 1800250,
    2.5: 1733583,
    3.5: 1656083,
    4.5: 1595666,
  },
  {
    1: 1825650,
    2: 1771483,
    3: 1688150,
    4: 1618150,
    5: 1560650,
    "revenu brut": 4856000,
    trimf: 1500,
    1.5: 1800650,
    2.5: 1733983,
    3.5: 1656483,
    4.5: 1596066,
  },
  {
    1: 1826050,
    2: 1771883,
    3: 1688550,
    4: 1618550,
    5: 1561050,
    "revenu brut": 4857000,
    trimf: 1500,
    1.5: 1801050,
    2.5: 1734383,
    3.5: 1656883,
    4.5: 1596466,
  },
  {
    1: 1826450,
    2: 1772283,
    3: 1688950,
    4: 1618950,
    5: 1561450,
    "revenu brut": 4858000,
    trimf: 1500,
    1.5: 1801450,
    2.5: 1734783,
    3.5: 1657283,
    4.5: 1596866,
  },
  {
    1: 1826850,
    2: 1772683,
    3: 1689350,
    4: 1619350,
    5: 1561850,
    "revenu brut": 4859000,
    trimf: 1500,
    1.5: 1801850,
    2.5: 1735183,
    3.5: 1657683,
    4.5: 1597266,
  },
  {
    1: 1827250,
    2: 1773083,
    3: 1689750,
    4: 1619750,
    5: 1562250,
    "revenu brut": 4860000,
    trimf: 1500,
    1.5: 1802250,
    2.5: 1735583,
    3.5: 1658083,
    4.5: 1597666,
  },
  {
    1: 1827650,
    2: 1773483,
    3: 1690150,
    4: 1620150,
    5: 1562650,
    "revenu brut": 4861_000,
    trimf: 1500,
    1.5: 1802650,
    2.5: 1735983,
    3.5: 1658483,
    4.5: 1598066,
  },
  {
    1: 1828050,
    2: 1773883,
    3: 1690550,
    4: 1620550,
    5: 1563050,
    "revenu brut": 4862000,
    trimf: 1500,
    1.5: 1803050,
    2.5: 1736383,
    3.5: 1658883,
    4.5: 1598466,
  },
  {
    1: 1828450,
    2: 1774283,
    3: 1690950,
    4: 1620950,
    5: 1563450,
    "revenu brut": 4863000,
    trimf: 1500,
    1.5: 1803450,
    2.5: 1736783,
    3.5: 1659283,
    4.5: 1598866,
  },
  {
    1: 1828850,
    2: 1774683,
    3: 1691350,
    4: 1621350,
    5: 1563850,
    "revenu brut": 4864000,
    trimf: 1500,
    1.5: 1803850,
    2.5: 1737183,
    3.5: 1659683,
    4.5: 1599266,
  },
  {
    1: 1829250,
    2: 1775083,
    3: 1691750,
    4: 1621750,
    5: 1564250,
    "revenu brut": 4865000,
    trimf: 1500,
    1.5: 1804250,
    2.5: 1737583,
    3.5: 1660083,
    4.5: 1599666,
  },
  {
    1: 1829650,
    2: 1775483,
    3: 1692150,
    4: 1622150,
    5: 1564650,
    "revenu brut": 4866000,
    trimf: 1500,
    1.5: 1804650,
    2.5: 1737983,
    3.5: 1660483,
    4.5: 1600066,
  },
  {
    1: 1830050,
    2: 1775883,
    3: 1692550,
    4: 1622550,
    5: 1565050,
    "revenu brut": 4867000,
    trimf: 1500,
    1.5: 1805050,
    2.5: 1738383,
    3.5: 1660883,
    4.5: 1600466,
  },
  {
    1: 1830450,
    2: 1776283,
    3: 1692950,
    4: 1622950,
    5: 1565450,
    "revenu brut": 4868000,
    trimf: 1500,
    1.5: 1805450,
    2.5: 1738783,
    3.5: 1661283,
    4.5: 1600866,
  },
  {
    1: 1830850,
    2: 1776683,
    3: 1693350,
    4: 1623350,
    5: 1565850,
    "revenu brut": 4869000,
    trimf: 1500,
    1.5: 1805850,
    2.5: 1739183,
    3.5: 1661683,
    4.5: 1601266,
  },
  {
    1: 1831250,
    2: 1777083,
    3: 1693750,
    4: 1623750,
    5: 1566250,
    "revenu brut": 4870000,
    trimf: 1500,
    1.5: 1806250,
    2.5: 1739583,
    3.5: 1662083,
    4.5: 1601666,
  },
  {
    1: 1831650,
    2: 1777483,
    3: 1694150,
    4: 1624150,
    5: 1566650,
    "revenu brut": 4871_000,
    trimf: 1500,
    1.5: 1806650,
    2.5: 1739983,
    3.5: 1662483,
    4.5: 1602066,
  },
  {
    1: 1832050,
    2: 1777883,
    3: 1694550,
    4: 1624550,
    5: 1567050,
    "revenu brut": 4872000,
    trimf: 1500,
    1.5: 1807050,
    2.5: 1740383,
    3.5: 1662883,
    4.5: 1602466,
  },
  {
    1: 1832450,
    2: 1778283,
    3: 1694950,
    4: 1624950,
    5: 1567450,
    "revenu brut": 4873000,
    trimf: 1500,
    1.5: 1807450,
    2.5: 1740783,
    3.5: 1663283,
    4.5: 1602866,
  },
  {
    1: 1832850,
    2: 1778683,
    3: 1695350,
    4: 1625350,
    5: 1567850,
    "revenu brut": 4874000,
    trimf: 1500,
    1.5: 1807850,
    2.5: 1741183,
    3.5: 1663683,
    4.5: 1603266,
  },
  {
    1: 1833250,
    2: 1779083,
    3: 1695750,
    4: 1625750,
    5: 1568250,
    "revenu brut": 4875000,
    trimf: 1500,
    1.5: 1808250,
    2.5: 1741583,
    3.5: 1664083,
    4.5: 1603666,
  },
  {
    1: 1833650,
    2: 1779483,
    3: 1696150,
    4: 1626150,
    5: 1568650,
    "revenu brut": 4876000,
    trimf: 1500,
    1.5: 1808650,
    2.5: 1741983,
    3.5: 1664483,
    4.5: 1604066,
  },
  {
    1: 1834050,
    2: 1779883,
    3: 1696550,
    4: 1626550,
    5: 1569050,
    "revenu brut": 4877000,
    trimf: 1500,
    1.5: 1809050,
    2.5: 1742383,
    3.5: 1664883,
    4.5: 1604466,
  },
  {
    1: 1834450,
    2: 1780283,
    3: 1696950,
    4: 1626950,
    5: 1569450,
    "revenu brut": 4878000,
    trimf: 1500,
    1.5: 1809450,
    2.5: 1742783,
    3.5: 1665283,
    4.5: 1604866,
  },
  {
    1: 1834850,
    2: 1780683,
    3: 1697350,
    4: 1627350,
    5: 1569850,
    "revenu brut": 4879000,
    trimf: 1500,
    1.5: 1809850,
    2.5: 1743183,
    3.5: 1665683,
    4.5: 1605266,
  },
  {
    1: 1835250,
    2: 1781083,
    3: 1697750,
    4: 1627750,
    5: 1570250,
    "revenu brut": 4880000,
    trimf: 1500,
    1.5: 1810250,
    2.5: 1743583,
    3.5: 1666083,
    4.5: 1605666,
  },
  {
    1: 1835650,
    2: 1781483,
    3: 1698150,
    4: 1628150,
    5: 1570650,
    "revenu brut": 4881_000,
    trimf: 1500,
    1.5: 1810650,
    2.5: 1743983,
    3.5: 1666483,
    4.5: 1606066,
  },
  {
    1: 1836050,
    2: 1781883,
    3: 1698550,
    4: 1628550,
    5: 1571050,
    "revenu brut": 4882000,
    trimf: 1500,
    1.5: 1811050,
    2.5: 1744383,
    3.5: 1666883,
    4.5: 1606466,
  },
  {
    1: 1836450,
    2: 1782283,
    3: 1698950,
    4: 1628950,
    5: 1571450,
    "revenu brut": 4883000,
    trimf: 1500,
    1.5: 1811450,
    2.5: 1744783,
    3.5: 1667283,
    4.5: 1606866,
  },
  {
    1: 1836850,
    2: 1782683,
    3: 1699350,
    4: 1629350,
    5: 1571850,
    "revenu brut": 4884000,
    trimf: 1500,
    1.5: 1811850,
    2.5: 1745183,
    3.5: 1667683,
    4.5: 1607266,
  },
  {
    1: 1837250,
    2: 1783083,
    3: 1699750,
    4: 1629750,
    5: 1572250,
    "revenu brut": 4885000,
    trimf: 1500,
    1.5: 1812250,
    2.5: 1745583,
    3.5: 1668083,
    4.5: 1607666,
  },
  {
    1: 1837650,
    2: 1783483,
    3: 1700150,
    4: 1630150,
    5: 1572650,
    "revenu brut": 4886000,
    trimf: 1500,
    1.5: 1812650,
    2.5: 1745983,
    3.5: 1668483,
    4.5: 1608066,
  },
  {
    1: 1838050,
    2: 1783883,
    3: 1700550,
    4: 1630550,
    5: 1573050,
    "revenu brut": 4887000,
    trimf: 1500,
    1.5: 1813050,
    2.5: 1746383,
    3.5: 1668883,
    4.5: 1608466,
  },
  {
    1: 1838450,
    2: 1784283,
    3: 1700950,
    4: 1630950,
    5: 1573450,
    "revenu brut": 4888000,
    trimf: 1500,
    1.5: 1813450,
    2.5: 1746783,
    3.5: 1669283,
    4.5: 1608866,
  },
  {
    1: 1838850,
    2: 1784683,
    3: 1701350,
    4: 1631350,
    5: 1573850,
    "revenu brut": 4889000,
    trimf: 1500,
    1.5: 1813850,
    2.5: 1747183,
    3.5: 1669683,
    4.5: 1609266,
  },
  {
    1: 1839250,
    2: 1785083,
    3: 1701750,
    4: 1631750,
    5: 1574250,
    "revenu brut": 4890000,
    trimf: 1500,
    1.5: 1814250,
    2.5: 1747583,
    3.5: 1670083,
    4.5: 1609666,
  },
  {
    1: 1839650,
    2: 1785483,
    3: 1702150,
    4: 1632150,
    5: 1574650,
    "revenu brut": 4891_000,
    trimf: 1500,
    1.5: 1814650,
    2.5: 1747983,
    3.5: 1670483,
    4.5: 1610066,
  },
  {
    1: 1840050,
    2: 1785883,
    3: 1702550,
    4: 1632550,
    5: 1575050,
    "revenu brut": 4892000,
    trimf: 1500,
    1.5: 1815050,
    2.5: 1748383,
    3.5: 1670883,
    4.5: 1610466,
  },
  {
    1: 1840450,
    2: 1786283,
    3: 1702950,
    4: 1632950,
    5: 1575450,
    "revenu brut": 4893000,
    trimf: 1500,
    1.5: 1815450,
    2.5: 1748783,
    3.5: 1671283,
    4.5: 1610866,
  },
  {
    1: 1840850,
    2: 1786683,
    3: 1703350,
    4: 1633350,
    5: 1575850,
    "revenu brut": 4894000,
    trimf: 1500,
    1.5: 1815850,
    2.5: 1749183,
    3.5: 1671683,
    4.5: 1611266,
  },
  {
    1: 1841250,
    2: 1787083,
    3: 1703750,
    4: 1633750,
    5: 1576250,
    "revenu brut": 4895000,
    trimf: 1500,
    1.5: 1816250,
    2.5: 1749583,
    3.5: 1672083,
    4.5: 1611666,
  },
  {
    1: 1841650,
    2: 1787483,
    3: 1704150,
    4: 1634150,
    5: 1576650,
    "revenu brut": 4896000,
    trimf: 1500,
    1.5: 1816650,
    2.5: 1749983,
    3.5: 1672483,
    4.5: 1612066,
  },
  {
    1: 1842050,
    2: 1787883,
    3: 1704550,
    4: 1634550,
    5: 1577050,
    "revenu brut": 4897000,
    trimf: 1500,
    1.5: 1817050,
    2.5: 1750383,
    3.5: 1672883,
    4.5: 1612466,
  },
  {
    1: 1842450,
    2: 1788283,
    3: 1704950,
    4: 1634950,
    5: 1577450,
    "revenu brut": 4898000,
    trimf: 1500,
    1.5: 1817450,
    2.5: 1750783,
    3.5: 1673283,
    4.5: 1612866,
  },
  {
    1: 1842850,
    2: 1788683,
    3: 1705350,
    4: 1635350,
    5: 1577850,
    "revenu brut": 4899000,
    trimf: 1500,
    1.5: 1817850,
    2.5: 1751183,
    3.5: 1673683,
    4.5: 1613266,
  },
  {
    1: 1843250,
    2: 1789083,
    3: 1705750,
    4: 1635750,
    5: 1578250,
    "revenu brut": 4900000,
    trimf: 1500,
    1.5: 1818250,
    2.5: 1751583,
    3.5: 1674083,
    4.5: 1613666,
  },
  {
    1: 1843650,
    2: 1789483,
    3: 1706150,
    4: 1636150,
    5: 1578650,
    "revenu brut": 4901_000,
    trimf: 1500,
    1.5: 1818650,
    2.5: 1751983,
    3.5: 1674483,
    4.5: 1614066,
  },
  {
    1: 1844050,
    2: 1789883,
    3: 1706550,
    4: 1636550,
    5: 1579050,
    "revenu brut": 4902000,
    trimf: 1500,
    1.5: 1819050,
    2.5: 1752383,
    3.5: 1674883,
    4.5: 1614466,
  },
  {
    1: 1844450,
    2: 1790283,
    3: 1706950,
    4: 1636950,
    5: 1579450,
    "revenu brut": 4903000,
    trimf: 1500,
    1.5: 1819450,
    2.5: 1752783,
    3.5: 1675283,
    4.5: 1614866,
  },
  {
    1: 1844850,
    2: 1790683,
    3: 1707350,
    4: 1637350,
    5: 1579850,
    "revenu brut": 4904000,
    trimf: 1500,
    1.5: 1819850,
    2.5: 1753183,
    3.5: 1675683,
    4.5: 1615266,
  },
  {
    1: 1845250,
    2: 1791083,
    3: 1707750,
    4: 1637750,
    5: 1580250,
    "revenu brut": 4905000,
    trimf: 1500,
    1.5: 1820250,
    2.5: 1753583,
    3.5: 1676083,
    4.5: 1615666,
  },
  {
    1: 1845650,
    2: 1791483,
    3: 1708150,
    4: 1638150,
    5: 1580650,
    "revenu brut": 4906000,
    trimf: 1500,
    1.5: 1820650,
    2.5: 1753983,
    3.5: 1676483,
    4.5: 1616066,
  },
  {
    1: 1846050,
    2: 1791883,
    3: 1708550,
    4: 1638550,
    5: 1581050,
    "revenu brut": 4907000,
    trimf: 1500,
    1.5: 1821050,
    2.5: 1754383,
    3.5: 1676883,
    4.5: 1616466,
  },
  {
    1: 1846450,
    2: 1792283,
    3: 1708950,
    4: 1638950,
    5: 1581450,
    "revenu brut": 4908000,
    trimf: 1500,
    1.5: 1821450,
    2.5: 1754783,
    3.5: 1677283,
    4.5: 1616866,
  },
  {
    1: 1846850,
    2: 1792683,
    3: 1709350,
    4: 1639350,
    5: 1581850,
    "revenu brut": 4909000,
    trimf: 1500,
    1.5: 1821850,
    2.5: 1755183,
    3.5: 1677683,
    4.5: 1617266,
  },
  {
    1: 1847250,
    2: 1793083,
    3: 1709750,
    4: 1639750,
    5: 1582250,
    "revenu brut": 491_0000,
    trimf: 1500,
    1.5: 1822250,
    2.5: 1755583,
    3.5: 1678083,
    4.5: 1617666,
  },
  {
    1: 1847650,
    2: 1793483,
    3: 1710150,
    4: 1640150,
    5: 1582650,
    "revenu brut": 4911_000,
    trimf: 1500,
    1.5: 1822650,
    2.5: 1755983,
    3.5: 1678483,
    4.5: 1618066,
  },
  {
    1: 1848050,
    2: 1793883,
    3: 1710550,
    4: 1640550,
    5: 1583050,
    "revenu brut": 4912000,
    trimf: 1500,
    1.5: 1823050,
    2.5: 1756383,
    3.5: 1678883,
    4.5: 1618466,
  },
  {
    1: 1848450,
    2: 1794283,
    3: 1710950,
    4: 1640950,
    5: 1583450,
    "revenu brut": 4913000,
    trimf: 1500,
    1.5: 1823450,
    2.5: 1756783,
    3.5: 1679283,
    4.5: 1618866,
  },
  {
    1: 1848850,
    2: 1794683,
    3: 1711350,
    4: 1641350,
    5: 1583850,
    "revenu brut": 4914000,
    trimf: 1500,
    1.5: 1823850,
    2.5: 1757183,
    3.5: 1679683,
    4.5: 1619266,
  },
  {
    1: 1849250,
    2: 1795083,
    3: 1711750,
    4: 1641750,
    5: 1584250,
    "revenu brut": 4915000,
    trimf: 1500,
    1.5: 1824250,
    2.5: 1757583,
    3.5: 1680083,
    4.5: 1619666,
  },
  {
    1: 1849650,
    2: 1795483,
    3: 1712150,
    4: 1642150,
    5: 1584650,
    "revenu brut": 4916000,
    trimf: 1500,
    1.5: 1824650,
    2.5: 1757983,
    3.5: 1680483,
    4.5: 1620066,
  },
  {
    1: 1850050,
    2: 1795883,
    3: 1712550,
    4: 1642550,
    5: 1585050,
    "revenu brut": 4917000,
    trimf: 1500,
    1.5: 1825050,
    2.5: 1758383,
    3.5: 1680883,
    4.5: 1620466,
  },
  {
    1: 1850450,
    2: 1796283,
    3: 1712950,
    4: 1642950,
    5: 1585450,
    "revenu brut": 4918000,
    trimf: 1500,
    1.5: 1825450,
    2.5: 1758783,
    3.5: 1681283,
    4.5: 1620866,
  },
  {
    1: 1850850,
    2: 1796683,
    3: 1713350,
    4: 1643350,
    5: 1585850,
    "revenu brut": 4919000,
    trimf: 1500,
    1.5: 1825850,
    2.5: 1759183,
    3.5: 1681683,
    4.5: 1621266,
  },
  {
    1: 1851250,
    2: 1797083,
    3: 1713750,
    4: 1643750,
    5: 1586250,
    "revenu brut": 4920000,
    trimf: 1500,
    1.5: 1826250,
    2.5: 1759583,
    3.5: 1682083,
    4.5: 1621666,
  },
  {
    1: 1851650,
    2: 1797483,
    3: 1714150,
    4: 1644150,
    5: 1586650,
    "revenu brut": 4921_000,
    trimf: 1500,
    1.5: 1826650,
    2.5: 1759983,
    3.5: 1682483,
    4.5: 1622066,
  },
  {
    1: 1852050,
    2: 1797883,
    3: 1714550,
    4: 1644550,
    5: 1587050,
    "revenu brut": 4922000,
    trimf: 1500,
    1.5: 1827050,
    2.5: 1760383,
    3.5: 1682883,
    4.5: 1622466,
  },
  {
    1: 1852450,
    2: 1798283,
    3: 1714950,
    4: 1644950,
    5: 1587450,
    "revenu brut": 4923000,
    trimf: 1500,
    1.5: 1827450,
    2.5: 1760783,
    3.5: 1683283,
    4.5: 1622866,
  },
  {
    1: 1852850,
    2: 1798683,
    3: 1715350,
    4: 1645350,
    5: 1587850,
    "revenu brut": 4924000,
    trimf: 1500,
    1.5: 1827850,
    2.5: 1761183,
    3.5: 1683683,
    4.5: 1623266,
  },
  {
    1: 1853250,
    2: 1799083,
    3: 1715750,
    4: 1645750,
    5: 1588250,
    "revenu brut": 4925000,
    trimf: 1500,
    1.5: 1828250,
    2.5: 1761583,
    3.5: 1684083,
    4.5: 1623666,
  },
  {
    1: 1853650,
    2: 1799483,
    3: 1716150,
    4: 1646150,
    5: 1588650,
    "revenu brut": 4926000,
    trimf: 1500,
    1.5: 1828650,
    2.5: 1761983,
    3.5: 1684483,
    4.5: 1624066,
  },
  {
    1: 1854050,
    2: 1799883,
    3: 1716550,
    4: 1646550,
    5: 1589050,
    "revenu brut": 4927000,
    trimf: 1500,
    1.5: 1829050,
    2.5: 1762383,
    3.5: 1684883,
    4.5: 1624466,
  },
  {
    1: 1854450,
    2: 1800283,
    3: 1716950,
    4: 1646950,
    5: 1589450,
    "revenu brut": 4928000,
    trimf: 1500,
    1.5: 1829450,
    2.5: 1762783,
    3.5: 1685283,
    4.5: 1624866,
  },
  {
    1: 1854850,
    2: 1800683,
    3: 1717350,
    4: 1647350,
    5: 1589850,
    "revenu brut": 4929000,
    trimf: 1500,
    1.5: 1829850,
    2.5: 1763183,
    3.5: 1685683,
    4.5: 1625266,
  },
  {
    1: 1855250,
    2: 1801083,
    3: 1717750,
    4: 1647750,
    5: 1590250,
    "revenu brut": 4930000,
    trimf: 1500,
    1.5: 1830250,
    2.5: 1763583,
    3.5: 1686083,
    4.5: 1625666,
  },
  {
    1: 1855650,
    2: 1801483,
    3: 1718150,
    4: 1648150,
    5: 1590650,
    "revenu brut": 4931_000,
    trimf: 1500,
    1.5: 1830650,
    2.5: 1763983,
    3.5: 1686483,
    4.5: 1626066,
  },
  {
    1: 1856050,
    2: 1801883,
    3: 1718550,
    4: 1648550,
    5: 1591050,
    "revenu brut": 4932000,
    trimf: 1500,
    1.5: 1831050,
    2.5: 1764383,
    3.5: 1686883,
    4.5: 1626466,
  },
  {
    1: 1856450,
    2: 1802283,
    3: 1718950,
    4: 1648950,
    5: 1591450,
    "revenu brut": 4933000,
    trimf: 1500,
    1.5: 1831450,
    2.5: 1764783,
    3.5: 1687283,
    4.5: 1626866,
  },
  {
    1: 1856850,
    2: 1802683,
    3: 1719350,
    4: 1649350,
    5: 1591850,
    "revenu brut": 4934000,
    trimf: 1500,
    1.5: 1831850,
    2.5: 1765183,
    3.5: 1687683,
    4.5: 1627266,
  },
  {
    1: 1857250,
    2: 1803083,
    3: 1719750,
    4: 1649750,
    5: 1592250,
    "revenu brut": 4935000,
    trimf: 1500,
    1.5: 1832250,
    2.5: 1765583,
    3.5: 1688083,
    4.5: 1627666,
  },
  {
    1: 1857650,
    2: 1803483,
    3: 1720150,
    4: 1650150,
    5: 1592650,
    "revenu brut": 4936000,
    trimf: 1500,
    1.5: 1832650,
    2.5: 1765983,
    3.5: 1688483,
    4.5: 1628066,
  },
  {
    1: 1858050,
    2: 1803883,
    3: 1720550,
    4: 1650550,
    5: 1593050,
    "revenu brut": 4937000,
    trimf: 1500,
    1.5: 1833050,
    2.5: 1766383,
    3.5: 1688883,
    4.5: 1628466,
  },
  {
    1: 1858450,
    2: 1804283,
    3: 1720950,
    4: 1650950,
    5: 1593450,
    "revenu brut": 4938000,
    trimf: 1500,
    1.5: 1833450,
    2.5: 1766783,
    3.5: 1689283,
    4.5: 1628866,
  },
  {
    1: 1858850,
    2: 1804683,
    3: 1721350,
    4: 1651350,
    5: 1593850,
    "revenu brut": 4939000,
    trimf: 1500,
    1.5: 1833850,
    2.5: 1767183,
    3.5: 1689683,
    4.5: 1629266,
  },
  {
    1: 1859250,
    2: 1805083,
    3: 1721750,
    4: 1651750,
    5: 1594250,
    "revenu brut": 4940000,
    trimf: 1500,
    1.5: 1834250,
    2.5: 1767583,
    3.5: 1690083,
    4.5: 1629666,
  },
  {
    1: 1859650,
    2: 1805483,
    3: 1722150,
    4: 1652150,
    5: 1594650,
    "revenu brut": 4941_000,
    trimf: 1500,
    1.5: 1834650,
    2.5: 1767983,
    3.5: 1690483,
    4.5: 1630066,
  },
  {
    1: 1860050,
    2: 1805883,
    3: 1722550,
    4: 1652550,
    5: 1595050,
    "revenu brut": 4942000,
    trimf: 1500,
    1.5: 1835050,
    2.5: 1768383,
    3.5: 1690883,
    4.5: 1630466,
  },
  {
    1: 1860450,
    2: 1806283,
    3: 1722950,
    4: 1652950,
    5: 1595450,
    "revenu brut": 4943000,
    trimf: 1500,
    1.5: 1835450,
    2.5: 1768783,
    3.5: 1691283,
    4.5: 1630866,
  },
  {
    1: 1860850,
    2: 1806683,
    3: 1723350,
    4: 1653350,
    5: 1595850,
    "revenu brut": 4944000,
    trimf: 1500,
    1.5: 1835850,
    2.5: 1769183,
    3.5: 1691683,
    4.5: 1631266,
  },
  {
    1: 1861250,
    2: 1807083,
    3: 1723750,
    4: 1653750,
    5: 1596250,
    "revenu brut": 4945000,
    trimf: 1500,
    1.5: 1836250,
    2.5: 1769583,
    3.5: 1692083,
    4.5: 1631666,
  },
  {
    1: 1861650,
    2: 1807483,
    3: 1724150,
    4: 1654150,
    5: 1596650,
    "revenu brut": 4946000,
    trimf: 1500,
    1.5: 1836650,
    2.5: 1769983,
    3.5: 1692483,
    4.5: 1632066,
  },
  {
    1: 1862050,
    2: 1807883,
    3: 1724550,
    4: 1654550,
    5: 1597050,
    "revenu brut": 4947000,
    trimf: 1500,
    1.5: 1837050,
    2.5: 1770383,
    3.5: 1692883,
    4.5: 1632466,
  },
  {
    1: 1862450,
    2: 1808283,
    3: 1724950,
    4: 1654950,
    5: 1597450,
    "revenu brut": 4948000,
    trimf: 1500,
    1.5: 1837450,
    2.5: 1770783,
    3.5: 1693283,
    4.5: 1632866,
  },
  {
    1: 1862850,
    2: 1808683,
    3: 1725350,
    4: 1655350,
    5: 1597850,
    "revenu brut": 4949000,
    trimf: 1500,
    1.5: 1837850,
    2.5: 1771183,
    3.5: 1693683,
    4.5: 1633266,
  },
  {
    1: 1863250,
    2: 1809083,
    3: 1725750,
    4: 1655750,
    5: 1598250,
    "revenu brut": 4950000,
    trimf: 1500,
    1.5: 1838250,
    2.5: 1771583,
    3.5: 1694083,
    4.5: 1633666,
  },
  {
    1: 1863650,
    2: 1809483,
    3: 1726150,
    4: 1656150,
    5: 1598650,
    "revenu brut": 4951_000,
    trimf: 1500,
    1.5: 1838650,
    2.5: 1771983,
    3.5: 1694483,
    4.5: 1634066,
  },
  {
    1: 1864050,
    2: 1809883,
    3: 1726550,
    4: 1656550,
    5: 1599050,
    "revenu brut": 4952000,
    trimf: 1500,
    1.5: 1839050,
    2.5: 1772383,
    3.5: 1694883,
    4.5: 1634466,
  },
  {
    1: 1864450,
    2: 1810283,
    3: 1726950,
    4: 1656950,
    5: 1599450,
    "revenu brut": 4953000,
    trimf: 1500,
    1.5: 1839450,
    2.5: 1772783,
    3.5: 1695283,
    4.5: 1634866,
  },
  {
    1: 1864850,
    2: 1810683,
    3: 1727350,
    4: 1657350,
    5: 1599850,
    "revenu brut": 4954000,
    trimf: 1500,
    1.5: 1839850,
    2.5: 1773183,
    3.5: 1695683,
    4.5: 1635266,
  },
  {
    1: 1865250,
    2: 1811083,
    3: 1727750,
    4: 1657750,
    5: 1600250,
    "revenu brut": 4955000,
    trimf: 1500,
    1.5: 1840250,
    2.5: 1773583,
    3.5: 1696083,
    4.5: 1635666,
  },
  {
    1: 1865650,
    2: 1811483,
    3: 1728150,
    4: 1658150,
    5: 1600650,
    "revenu brut": 4956000,
    trimf: 1500,
    1.5: 1840650,
    2.5: 1773983,
    3.5: 1696483,
    4.5: 1636066,
  },
  {
    1: 1866050,
    2: 1811883,
    3: 1728550,
    4: 1658550,
    5: 1601050,
    "revenu brut": 4957000,
    trimf: 1500,
    1.5: 1841050,
    2.5: 1774383,
    3.5: 1696883,
    4.5: 1636466,
  },
  {
    1: 1866450,
    2: 1812283,
    3: 1728950,
    4: 1658950,
    5: 1601450,
    "revenu brut": 4958000,
    trimf: 1500,
    1.5: 1841450,
    2.5: 1774783,
    3.5: 1697283,
    4.5: 1636866,
  },
  {
    1: 1866850,
    2: 1812683,
    3: 1729350,
    4: 1659350,
    5: 1601850,
    "revenu brut": 4959000,
    trimf: 1500,
    1.5: 1841850,
    2.5: 1775183,
    3.5: 1697683,
    4.5: 1637266,
  },
  {
    1: 1867250,
    2: 1813083,
    3: 1729750,
    4: 1659750,
    5: 1602250,
    "revenu brut": 4960000,
    trimf: 1500,
    1.5: 1842250,
    2.5: 1775583,
    3.5: 1698083,
    4.5: 1637666,
  },
  {
    1: 1867650,
    2: 1813483,
    3: 1730150,
    4: 1660150,
    5: 1602650,
    "revenu brut": 4961_000,
    trimf: 1500,
    1.5: 1842650,
    2.5: 1775983,
    3.5: 1698483,
    4.5: 1638066,
  },
  {
    1: 1868050,
    2: 1813883,
    3: 1730550,
    4: 1660550,
    5: 1603050,
    "revenu brut": 4962000,
    trimf: 1500,
    1.5: 1843050,
    2.5: 1776383,
    3.5: 1698883,
    4.5: 1638466,
  },
  {
    1: 1868450,
    2: 1814283,
    3: 1730950,
    4: 1660950,
    5: 1603450,
    "revenu brut": 4963000,
    trimf: 1500,
    1.5: 1843450,
    2.5: 1776783,
    3.5: 1699283,
    4.5: 1638866,
  },
  {
    1: 1868850,
    2: 1814683,
    3: 1731350,
    4: 1661350,
    5: 1603850,
    "revenu brut": 4964000,
    trimf: 1500,
    1.5: 1843850,
    2.5: 1777183,
    3.5: 1699683,
    4.5: 1639266,
  },
  {
    1: 1869250,
    2: 1815083,
    3: 1731750,
    4: 1661750,
    5: 1604250,
    "revenu brut": 4965000,
    trimf: 1500,
    1.5: 1844250,
    2.5: 1777583,
    3.5: 1700083,
    4.5: 1639666,
  },
  {
    1: 1869650,
    2: 1815483,
    3: 1732150,
    4: 1662150,
    5: 1604650,
    "revenu brut": 4966000,
    trimf: 1500,
    1.5: 1844650,
    2.5: 1777983,
    3.5: 1700483,
    4.5: 1640066,
  },
  {
    1: 1870050,
    2: 1815883,
    3: 1732550,
    4: 1662550,
    5: 1605050,
    "revenu brut": 4967000,
    trimf: 1500,
    1.5: 1845050,
    2.5: 1778383,
    3.5: 1700883,
    4.5: 1640466,
  },
  {
    1: 1870450,
    2: 1816283,
    3: 1732950,
    4: 1662950,
    5: 1605450,
    "revenu brut": 4968000,
    trimf: 1500,
    1.5: 1845450,
    2.5: 1778783,
    3.5: 1701283,
    4.5: 1640866,
  },
  {
    1: 1870850,
    2: 1816683,
    3: 1733350,
    4: 1663350,
    5: 1605850,
    "revenu brut": 4969000,
    trimf: 1500,
    1.5: 1845850,
    2.5: 1779183,
    3.5: 1701683,
    4.5: 1641266,
  },
  {
    1: 1871250,
    2: 1817083,
    3: 1733750,
    4: 1663750,
    5: 1606250,
    "revenu brut": 4970000,
    trimf: 1500,
    1.5: 1846250,
    2.5: 1779583,
    3.5: 1702083,
    4.5: 1641666,
  },
  {
    1: 1871650,
    2: 1817483,
    3: 1734150,
    4: 1664150,
    5: 1606650,
    "revenu brut": 4971_000,
    trimf: 1500,
    1.5: 1846650,
    2.5: 1779983,
    3.5: 1702483,
    4.5: 1642066,
  },
  {
    1: 1872050,
    2: 1817883,
    3: 1734550,
    4: 1664550,
    5: 1607050,
    "revenu brut": 4972000,
    trimf: 1500,
    1.5: 1847050,
    2.5: 1780383,
    3.5: 1702883,
    4.5: 1642466,
  },
  {
    1: 1872450,
    2: 1818283,
    3: 1734950,
    4: 1664950,
    5: 1607450,
    "revenu brut": 4973000,
    trimf: 1500,
    1.5: 1847450,
    2.5: 1780783,
    3.5: 1703283,
    4.5: 1642866,
  },
  {
    1: 1872850,
    2: 1818683,
    3: 1735350,
    4: 1665350,
    5: 1607850,
    "revenu brut": 4974000,
    trimf: 1500,
    1.5: 1847850,
    2.5: 1781183,
    3.5: 1703683,
    4.5: 1643266,
  },
  {
    1: 1873250,
    2: 1819083,
    3: 1735750,
    4: 1665750,
    5: 1608250,
    "revenu brut": 4975000,
    trimf: 1500,
    1.5: 1848250,
    2.5: 1781583,
    3.5: 1704083,
    4.5: 1643666,
  },
  {
    1: 1873650,
    2: 1819483,
    3: 1736150,
    4: 1666150,
    5: 1608650,
    "revenu brut": 4976000,
    trimf: 1500,
    1.5: 1848650,
    2.5: 1781983,
    3.5: 1704483,
    4.5: 1644066,
  },
  {
    1: 1874050,
    2: 1819883,
    3: 1736550,
    4: 1666550,
    5: 1609050,
    "revenu brut": 4977000,
    trimf: 1500,
    1.5: 1849050,
    2.5: 1782383,
    3.5: 1704883,
    4.5: 1644466,
  },
  {
    1: 1874450,
    2: 1820283,
    3: 1736950,
    4: 1666950,
    5: 1609450,
    "revenu brut": 4978000,
    trimf: 1500,
    1.5: 1849450,
    2.5: 1782783,
    3.5: 1705283,
    4.5: 1644866,
  },
  {
    1: 1874850,
    2: 1820683,
    3: 1737350,
    4: 1667350,
    5: 1609850,
    "revenu brut": 4979000,
    trimf: 1500,
    1.5: 1849850,
    2.5: 1783183,
    3.5: 1705683,
    4.5: 1645266,
  },
  {
    1: 1875250,
    2: 1821083,
    3: 1737750,
    4: 1667750,
    5: 1610250,
    "revenu brut": 4980000,
    trimf: 1500,
    1.5: 1850250,
    2.5: 1783583,
    3.5: 1706083,
    4.5: 1645666,
  },
  {
    1: 1875650,
    2: 1821483,
    3: 1738150,
    4: 1668150,
    5: 1610650,
    "revenu brut": 4981_000,
    trimf: 1500,
    1.5: 1850650,
    2.5: 1783983,
    3.5: 1706483,
    4.5: 1646066,
  },
  {
    1: 1876050,
    2: 1821883,
    3: 1738550,
    4: 1668550,
    5: 1611050,
    "revenu brut": 4982000,
    trimf: 1500,
    1.5: 1851050,
    2.5: 1784383,
    3.5: 1706883,
    4.5: 1646466,
  },
  {
    1: 1876450,
    2: 1822283,
    3: 1738950,
    4: 1668950,
    5: 1611450,
    "revenu brut": 4983000,
    trimf: 1500,
    1.5: 1851450,
    2.5: 1784783,
    3.5: 1707283,
    4.5: 1646866,
  },
  {
    1: 1876850,
    2: 1822683,
    3: 1739350,
    4: 1669350,
    5: 1611850,
    "revenu brut": 4984000,
    trimf: 1500,
    1.5: 1851850,
    2.5: 1785183,
    3.5: 1707683,
    4.5: 1647266,
  },
  {
    1: 1877250,
    2: 1823083,
    3: 1739750,
    4: 1669750,
    5: 1612250,
    "revenu brut": 4985000,
    trimf: 1500,
    1.5: 1852250,
    2.5: 1785583,
    3.5: 1708083,
    4.5: 1647666,
  },
  {
    1: 1877650,
    2: 1823483,
    3: 1740150,
    4: 1670150,
    5: 1612650,
    "revenu brut": 4986000,
    trimf: 1500,
    1.5: 1852650,
    2.5: 1785983,
    3.5: 1708483,
    4.5: 1648066,
  },
  {
    1: 1878050,
    2: 1823883,
    3: 1740550,
    4: 1670550,
    5: 1613050,
    "revenu brut": 4987000,
    trimf: 1500,
    1.5: 1853050,
    2.5: 1786383,
    3.5: 1708883,
    4.5: 1648466,
  },
  {
    1: 1878450,
    2: 1824283,
    3: 1740950,
    4: 1670950,
    5: 1613450,
    "revenu brut": 4988000,
    trimf: 1500,
    1.5: 1853450,
    2.5: 1786783,
    3.5: 1709283,
    4.5: 1648866,
  },
  {
    1: 1878850,
    2: 1824683,
    3: 1741350,
    4: 1671350,
    5: 1613850,
    "revenu brut": 4989000,
    trimf: 1500,
    1.5: 1853850,
    2.5: 1787183,
    3.5: 1709683,
    4.5: 1649266,
  },
  {
    1: 1879250,
    2: 1825083,
    3: 1741750,
    4: 1671750,
    5: 1614250,
    "revenu brut": 4990000,
    trimf: 1500,
    1.5: 1854250,
    2.5: 1787583,
    3.5: 1710083,
    4.5: 1649666,
  },
  {
    1: 1879650,
    2: 1825483,
    3: 1742150,
    4: 1672150,
    5: 1614650,
    "revenu brut": 4991_000,
    trimf: 1500,
    1.5: 1854650,
    2.5: 1787983,
    3.5: 1710483,
    4.5: 1650066,
  },
  {
    1: 1880050,
    2: 1825883,
    3: 1742550,
    4: 1672550,
    5: 1615050,
    "revenu brut": 4992000,
    trimf: 1500,
    1.5: 1855050,
    2.5: 1788383,
    3.5: 1710883,
    4.5: 1650466,
  },
  {
    1: 1880450,
    2: 1826283,
    3: 1742950,
    4: 1672950,
    5: 1615450,
    "revenu brut": 4993000,
    trimf: 1500,
    1.5: 1855450,
    2.5: 1788783,
    3.5: 1711283,
    4.5: 1650866,
  },
  {
    1: 1880850,
    2: 1826683,
    3: 1743350,
    4: 1673350,
    5: 1615850,
    "revenu brut": 4994000,
    trimf: 1500,
    1.5: 1855850,
    2.5: 1789183,
    3.5: 1711683,
    4.5: 1651266,
  },
  {
    1: 1881250,
    2: 1827083,
    3: 1743750,
    4: 1673750,
    5: 1616250,
    "revenu brut": 4995000,
    trimf: 1500,
    1.5: 1856250,
    2.5: 1789583,
    3.5: 1712083,
    4.5: 1651666,
  },
  {
    1: 1881650,
    2: 1827483,
    3: 1744150,
    4: 1674150,
    5: 1616650,
    "revenu brut": 4996000,
    trimf: 1500,
    1.5: 1856650,
    2.5: 1789983,
    3.5: 1712483,
    4.5: 1652066,
  },
  {
    1: 1882050,
    2: 1827883,
    3: 1744550,
    4: 1674550,
    5: 1617050,
    "revenu brut": 4997000,
    trimf: 1500,
    1.5: 1857050,
    2.5: 1790383,
    3.5: 1712883,
    4.5: 1652466,
  },
  {
    1: 1882450,
    2: 1828283,
    3: 1744950,
    4: 1674950,
    5: 1617450,
    "revenu brut": 4998000,
    trimf: 1500,
    1.5: 1857450,
    2.5: 1790783,
    3.5: 1713283,
    4.5: 1652866,
  },
  {
    1: 1882850,
    2: 1828683,
    3: 1745350,
    4: 1675350,
    5: 1617850,
    "revenu brut": 4999000,
    trimf: 1500,
    1.5: 1857850,
    2.5: 1791183,
    3.5: 1713683,
    4.5: 1653266,
  },
  {
    1: 1883250,
    2: 1829083,
    3: 1745750,
    4: 1675750,
    5: 1618250,
    "revenu brut": 5000000,
    trimf: 1500,
    1.5: 1858250,
    2.5: 1791583,
    3.5: 1714083,
    4.5: 1653666,
  },
];
