import Banner from "../../components/Banner";
import SectionBloc from "../../components/Section";
import FileCard from "./components/FileCard";
import FileList from "./components/FileList";

const DownloadPage = () => {
  const files = [
    {
      image: "url_de_l_image_1",
      title: "Fichier de données des employés",
      description:
        "Contient les informations de base sur les employés (nom, prénom, numéro d'identification, poste, département, etc.",
      price: "3.000,0",
    },
    {
      image: "url_de_l_image_2",
      title: "Fichier des heures travaillées",
      description:
        "Liste des heures travaillées par chaque employé sur une période donnée, y compris les heures normales, l...",
      price: "3.000,0",
    },
    {
      image: "url_de_l_image_3",
      title: "Fichier des absences et congés",
      description:
        "Détaille les absences et congés pris par les employés pendant la période de paie, incluant les types de congés (mal...",
      price: "3.000,0",
    },
    {
      image: "url_de_l_image_4",
      title: "Fichier des avantages sociaux",
      description:
        "Informations sur les avantages sociaux accordés aux employés tels que les assurances, les plans de retraite, les allocations familiales, etc.",
      price: "3.000,0",
    },
    {
      image: "url_de_l_image_5",
      title: "Fichier des cotisations et déductions",
      description:
        "Récapitulatif des cotisations sociales et des déductions salariales (impôts, sécurité sociale, mutuelle, etc.)",
      price: "3.000,0",
    },
  ];

  return (
    <>
      <Banner text="Téléchargement" />
      <SectionBloc
        title="Téléchargement de fichiers"
        description="Optimisez votre compréhension salariale avec notre guide complet et pratique en téléchargement immédiat."
      />
      <div className="max-w-5xl mx-auto">
        <FileList />
      </div>

      {/* <div className="p-6 bg-gray-100">
        <h2 className="text-2xl font-semibold mb-6">Fichiers</h2>
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6">
          {files.map((file, index) => (
            <FileCard
              key={index}
              image={file.image}
              title={file.title}
              description={file.description}
              price={file.price}
            />
          ))}
        </div>
      </div> */}
    </>
  );
};

export default DownloadPage;
