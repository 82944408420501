import { RouterProvider, createBrowserRouter } from "react-router-dom";
// import HomePage from "../pages/home";
import AppShell from "../layouts/appshell/AppShell";
import CalculateurPage from "../pages/calculateur";
import HomePage from "../pages/home";
import GuidePage from "../pages/astuces";
import ContactSection from "../pages/contact";
import DownloadPage from "../pages/downloads";
import PaymentPage from "../pages/downloads/components/Payment";

const Roots = () => {
  const all_routes = [
    {
      path: "/",
      element: <AppShell />,
      children: [
        {
          path: "",
          element: <HomePage />,
        },
        {
          path: "/calculateur",
          element: <CalculateurPage />,
        },
        {
          path: "/astuces",
          element: <GuidePage />,
        },
        {
          path: "/contacts",
          element: <ContactSection />,
        },
        {
          path: "/downloads",
          element: <DownloadPage />,
        },
        {
          path: "/payment",
          element: <PaymentPage />,
        },
      ],
    },
    {
      path: "/test",
      element: <div>Hello test</div>,
    },
  ];

  const routes = createBrowserRouter(all_routes);
  return (
    <>
      <RouterProvider router={routes} />
    </>
  );
};

export default Roots;
