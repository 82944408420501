import Hero from "../../components/Hero";
import AstuceSection from "./components/AstuceSection";

const HomePage = () => {
  return (
    <>
      <Hero />
      <AstuceSection />
    </>
  );
};

export default HomePage;
