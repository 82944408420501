import { createContext, useEffect, useState } from "react";
import "./App.css";
import Roots from "./routes/Roots";
import { createTheme, MantineProvider } from "@mantine/core";
import "@mantine/notifications/styles.css";
import { notifications, Notifications } from "@mantine/notifications";

const theme = createTheme({
  /** Put your mantine theme override here */
});

export const PanierContext = createContext();
function App() {
  const [panier, setPanier] = useState([]);

  const [cartItems, setCartItems] = useState(
    sessionStorage.getItem("cartItems")
      ? JSON.parse(sessionStorage.getItem("cartItems"))
      : []
  );

  const addToCart = (item) => {
    const isItemInCart = cartItems.find((cartItem) => cartItem.id === item.id);

    if (isItemInCart) {
      setCartItems(
        cartItems.map((cartItem) =>
          cartItem.id === item.id ? { ...cartItem } : cartItem
        )
      );
    } else {
      setCartItems([...cartItems, { ...item }]);
      notifications.show({
        title: "Ajout réussi",
        message: `${item?.name} ajouté dans le panier`,
        position: "bottom-left",
      });
    }
  };

  const removeFromCart = (item) => {
    const isItemInCart = cartItems.find((cartItem) => cartItem.id === item.id);

    if (isItemInCart) {
      setCartItems(cartItems.filter((cartItem) => cartItem.id !== item.id));
      notifications.show({
        title: "Suppression réussi",
        message: `${item?.name} retiré du panier`,
        position: "bottom-left",
        color: "red",
      });
    } else {
      setCartItems(
        cartItems.map((cartItem) =>
          cartItem.id === item.id ? { ...cartItem } : cartItem
        )
      );
    }
  };

  const clearCart = () => {
    setCartItems([]);
  };

  const getCartTotal = () => {
    return cartItems.reduce((total, item) => total + item.prix_unitaire, 0);
  };

  useEffect(() => {
    const data = sessionStorage.getItem("cartItems");
    if (data) {
      setCartItems(JSON.parse(data));
    }
  }, []);

  useEffect(() => {
    sessionStorage.setItem("cartItems", JSON.stringify(cartItems));
  }, [cartItems]); // Include cartItems as a dependency here

  return (
    <MantineProvider theme={theme}>
      <Notifications />
      {/* Your app here */}
      <PanierContext.Provider
        value={{
          panier,
          setPanier,
          cartItems,
          addToCart,
          removeFromCart,
          clearCart,
          getCartTotal,
        }}
      >
        <Roots />
      </PanierContext.Provider>
    </MantineProvider>
  );
}

export default App;
