const Banner = ({ text }) => {
  return (
    <div>
      <div className="bg-[#F0553F] text-white font-bold">
        <div className="mx-auto max-w-5xl py-3 px-3 sm:px-6 lg:px-8">
          <h1 className="text-xl">{text}</h1>
        </div>
      </div>
    </div>
  );
};

export default Banner;
