import map from "../../assets/icons/Vector(3).png";
import phone from "../../assets/icons/Vector(1).png";
import mail from "../../assets/icons/Vector(2).png";
import Banner from "../../components/Banner";
import SectionBloc from "../../components/Section";
import { AddressMap } from "./components/AdresseMap";

const ContactSection = () => {
  const astuces_data = [
    {
      img: map,
      title: "Address",
      description: "Dakar, Ngor, Almadies",
      //   link: "test",
    },
    {
      img: mail,
      title: "Email",
      description: "contact@afyarh.com.",
      //   link: "test",
    },
    {
      img: phone,
      title: "Phone",
      description: "+221 76 838 65 45.",
      //   link: "test",
    },
  ];
  return (
    <>
      <Banner text="Contacts" />
      <AddressMap />
      <SectionBloc
        card_data={astuces_data}
        title="Contacts"
        description="Envoyez-nous vos questions ou commentaires grâce à notre formulaire de contact."
      />
    </>
  );
};

export default ContactSection;
