import { createContext, useEffect, useState } from "react";
import Banner from "../../components/Banner";
import InputField from "./components/input";
import SelectedField from "./components/SelectedField";
import Sommaire from "./components/somaire";
export const SommaireContext = createContext();
const CalculateurPage = () => {
  const [salaireBrut, setSalaireBrut] = useState("");

  const [situationMatrimoniale, setSituationMatrimoniale] = useState("");

  const [status, setStatus] = useState();
  const [typeIndemnite, setTypeIndemnite] = useState("");

  const [netSalary, setNetSalary] = useState(0);
  const [conjointRevenu, setConjointRevenu] = useState(false);
  const [showResult, setShowResult] = useState(false);
  const [enfants, setEnfants] = useState(0);

  const handleSalaireBrutChange = (e) => setSalaireBrut(e.target.value);
  const calculateNetSalary = () => {
    const salaireBrutNumber = parseFloat(salaireBrut);

    const totalRetenuesFiscales = 300; // Montant fixe des retenues fiscales
    const ipresCadre = status === "4000" ? 1200 : 0; // IPRES pour les cadres
    const ipresGeneral = 2800; // IPRES régime général

    // Calcul du salaire net mensuel de base
    let salaireNetMensuel =
      salaireBrutNumber - ipresCadre - ipresGeneral - totalRetenuesFiscales;

    // Ajouter les indemnités
    if (typeIndemnite) {
      salaireNetMensuel += parseInt(typeIndemnite);
    }

    // Calculer le pourcentage total à ajouter en fonction de la situation matrimoniale et du nombre d'enfants
    let pourcentageTotal = situationMatrimoniale + enfants * 0.5;

    if (conjointRevenu) {
      pourcentageTotal += 1.5;
    }

    // Ajuster le salaire net en fonction du pourcentage total
    salaireNetMensuel += (salaireNetMensuel * pourcentageTotal) / 100;

    setNetSalary(salaireNetMensuel);
    console.log(salaireNetMensuel);
  };

  useEffect(() => {
    if (situationMatrimoniale === "Veuf(ve) avec enfant(s)") {
      setEnfants(1);
      console.log("enfants", enfants);
    }
  }, [situationMatrimoniale]);

  return (
    <>
      <SommaireContext.Provider
        value={{
          typeIndemnite,
          status,
          situationMatrimoniale,
          conjointRevenu,
          showResult,
          netSalary,
          salaireBrut,
          enfants,
        }}
      >
        <Banner text="Calculateur" />
        <div className="mx-auto max-w-7xl px-3 py-8 sm:px-6 lg:px-8 grid grid-cols-12  gap-4 text-[#999999]">
          <div className="col-span-full lg:col-span-7 md:col-span-12 sm:col-span-12">
            <form className="bg-white p-5 border">
              <div className="border-b-2 border-dotted pb-2">
                <h2 className="text-lg mb-4 text-black">
                  Calcul du salaire brut en net
                </h2>
                <span className="text-sm">
                  Entrez votre salaire brut et d'autres détails pour obtenir
                  votre salaire net instantanément.
                </span>
              </div>
              <div className="mt-10 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                <InputField
                  salaireBrut={salaireBrut}
                  handleSalaireBrutChange={handleSalaireBrutChange}
                />

                <SelectedField
                  label="Votre Type d'indemnité de transport"
                  value={typeIndemnite}
                  onChange={(e) => setTypeIndemnite(e.target.value)}
                  options={[
                    {
                      value: "Prime de transport (26.000 FCFA)",
                      label: "Prime de transport (26.000 FCFA)",
                    },
                    {
                      value: "Indemnité kilométrique (50.000 FCFA)",
                      label: "Indemnité kilométrique (50.000 FCFA)",
                    },
                  ]}
                />
              </div>

              <div className="mt-10 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                <SelectedField
                  label="Sélectionnez votre statut"
                  value={status}
                  onChange={(e) => {
                    setStatus(e.target.value);
                  }}
                  options={[
                    { value: "cadre", label: "Salaire cadre" },
                    { value: "non cadre", label: "Salaire non cadre" },
                  ]}
                />

                <SelectedField
                  label=" Votre Situation Matrimoniale"
                  onChange={(e) => {
                    setSituationMatrimoniale(e.target.value);
                    if (situationMatrimoniale !== "Marié(e)") {
                      setConjointRevenu(false);
                    }
                  }}
                  options={[
                    { value: "Célibataire", label: "Célibataire" },
                    { value: "Marié(e)", label: "Marié(e)" },
                    { value: "Divorcé(e)", label: "Divorcé(e)" },
                    {
                      value: "Veuf(ve) sans enfant",
                      label: "Veuf(ve) sans enfant",
                    },
                    {
                      value: "Veuf(ve) avec enfant(s)",
                      label: "Veuf(ve) avec enfant(s)",
                    },
                  ]}
                />
              </div>
              <div className="mt-10 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                <div className="sm:col-span-full ">
                  {/* conjoint  */}
                  {situationMatrimoniale === "Marié(e)" ? (
                    <>
                      <label
                        htmlFor="situation-matrimoniale"
                        className="block text-sm font-medium leading-6"
                      >
                        Votre conjoint dispose t’il/elle d’un revenu a titre
                        d'indépendant ou salarié ?
                      </label>

                      <div className="mt-2 relative">
                        <label className="relative inline-flex cursor-pointer items-center">
                          <input
                            id="switch"
                            type="checkbox"
                            className="peer sr-only"
                            onChange={(e) =>
                              setConjointRevenu(
                                (conjointRevenu) => !conjointRevenu
                              )
                            }
                          />
                          <label forHtml="switch" className="hidden"></label>
                          <div className="peer h-6 w-11 rounded-full border bg-slate-200 after:absolute after:left-[2px] after:top-0.5 after:h-5 after:w-5 after:rounded-full after:border after:border-none after:bg-white after:transition-all after:content-[''] peer-checked:bg-orange peer-checked:after:translate-x-full peer-checked:after:border-0 peer-focus:ring-green-300 mr-2"></div>
                          <span>{conjointRevenu ? "Oui " : "Non"}</span>
                        </label>{" "}
                      </div>
                    </>
                  ) : null}
                  {/* nombre d'enfatns  */}
                  {situationMatrimoniale !== "Veuf(ve) sans enfant" && (
                    <div className="sm:col-span-3">
                      <label
                        htmlFor="nombre-enfants"
                        className="block text-sm font-medium leading-6"
                      >
                        Nombre d'enfant en charge
                      </label>
                      <input
                        min={
                          situationMatrimoniale === "Veuf(ve) avec enfant(s)"
                            ? 1
                            : 0
                        }
                        type="number"
                        name="salaire-brut"
                        id="salaire-brut"
                        placeholder={0}
                        defaultValue={enfants || ""}
                        onChange={(e) => setEnfants(Number(e.target.value))}
                        className="block w-1/3 border-0 my-3 py-1.5 pl-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 placeholder:p-2 focus:ring-0 focus:ring-inset sm:text-sm sm:leading-6"
                      />
                    </div>
                  )}
                </div>
              </div>
              <button
                onClick={(e) => {
                  e.preventDefault();
                  calculateNetSalary();
                  setShowResult(true);
                }}
                className="bg-[#F0553F] h-10 px-10 py-2 text-start text-white rounded"
              >
                Soumettre
              </button>
            </form>
          </div>
          <Sommaire />
        </div>
      </SommaireContext.Provider>
    </>
  );
};

export default CalculateurPage;
