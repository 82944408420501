import { HiOutlineShoppingCart } from "react-icons/hi";
import { formatAmount } from "../../../utils/formattage";
import { useContext } from "react";
import { PanierContext } from "../../../App";

const FileCard = ({ item, image, title, description, price }) => {
  const { addToCart, cartItems } = useContext(PanierContext);
  return (
    <div className="bg-white shadow-md rounded-lg overflow-hidden flex flex-col h-full">
      <img src={image} alt={title} className="w-full h-56 object-cover" />
      <div className="p-4 flex flex-col flex-grow">
        <h3 className="text-lg font-semibold">{title}</h3>
        <p className="text-sm text-gray-600 mt-2 flex-grow">{description}</p>
        <div className="flex items-center justify-between mt-4">
          <span className="text-lg font-semibold text-[#00ACB6]">
            {formatAmount(price)}
          </span>
          <button
            className="bg-[#082E53] text-white p-3 rounded disabled:opacity-70"
            disabled={cartItems.find((cartItem) => cartItem.id === item.id)}
            onClick={() => {
              addToCart(item);
            }}
          >
            <HiOutlineShoppingCart size={22} />
          </button>
        </div>
      </div>
    </div>
  );
};

export default FileCard;
