import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Accordion from "../../components/Accordion";
import Banner from "../../components/Banner";

const GuidePage = () => {
  const [accordionOpen, setAccordionOpen] = useState();
  const anchor = window.location.hash.split("#")[1];

  const guide = [
    {
      title: "Pourquoi Calculer Son Salaire Brut en Net ?",
      text: "Calculer son salaire brut en net est crucial pour comprendre le montant réel que l'on perçoit après déductions des charges sociales et fiscales. Ce calcul permet de mieux gérer son budget, de prévoir ses dépenses et de négocier efficacement son salaire. Connaître son salaire net aide à avoir une vision claire de ses finances et à planifier en conséquence.",
      active: false,
      id: 1,
    },
    {
      title: "Les Avantages d'Utiliser Afya RH",
      text: "Utiliser des calculateurs en ligne fiables, comme celui proposé par Afya RH, facilite cette tâche en fournissant des résultats précis rapidement. Afya RH vous aide à comprendre les différentes cotisations et prélèvements spécifiques à votre pays, car ceux-ci varient et influencent le montant final. Notre calculateur intègre également des éléments tels que les primes, les heures supplémentaires et les avantages en nature, vous donnant une image complète de votre revenu net.",
      active: false,
      id: 2,
    },
    {
      title: "Conseils Pratiques pour Optimiser Vos Finances 1.3",
      text: "En utilisant notre calculateur et en vérifiant régulièrement vos bulletins de paie, vous vous assurez de l'exactitude des déductions. Il est conseillé de consulter un expert en cas de doute pour garantir que vous recevez le montant dû. Afya RH fournit également des guides et des conseils pratiques pour mieux comprendre et gérer vos finances personnelles. Une bonne compréhension de ces aspects grâce à Afya RH vous permet de gérer vos finances personnelles de manière éclairée et efficace.",
      active: false,
      id: 3,
    },
  ];

  const astuces = [
    {
      title: "Utilisez des Outils Fiables",
      text: "Optez pour des calculateurs en ligne réputés et fiables, tels que celui proposé par Afya RH. Ces outils simplifient le processus de calcul en fournissant des résultats précis et rapides, vous évitant ainsi les erreurs de calcul.",
      active: false,
      id: 4,
    },
    {
      title: "Soyez Informé des Cotisations et Prélèvements",
      text: "Renseignez-vous sur les différentes cotisations sociales et fiscales applicables dans votre pays. Ces éléments varient en fonction de divers facteurs et peuvent avoir un impact significatif sur le montant de votre salaire net. Afya RH vous aide à comprendre ces déductions et à anticiper leur impact sur votre revenu final.",
      active: false,
      id: 5,
    },
    {
      title: "Gardez un Œil sur les Éléments Variables",
      text: "N'oubliez pas d'inclure dans vos calculs les éléments variables tels que les primes, les heures supplémentaires et les avantages en nature. Ces éléments peuvent avoir un impact considérable sur votre salaire net. Afya RH intègre ces aspects dans ses calculs pour vous fournir une image complète et précise de votre revenu net.",
      active: false,
      id: 6,
    },
  ];

  const conseils = [
    {
      title: "Pourquoi Calculer Son Salaire Brut en Net ?",
      text: "Calculer son salaire brut en net est crucial pour comprendre le montant réel que l'on perçoit après déductions des charges sociales et fiscales. Ce calcul permet de mieux gérer son budget, de prévoir ses dépenses et de négocier efficacement son salaire. Connaître son salaire net aide à avoir une vision claire de ses finances et à planifier en conséquence.",
      active: false,
      id: 7,
    },
    {
      title: "Les Avantages d'Utiliser Afya RH",
      text: "Utiliser des calculateurs en ligne fiables, comme celui proposé par Afya RH, facilite cette tâche en fournissant des résultats précis rapidement. Afya RH vous aide à comprendre les différentes cotisations et prélèvements spécifiques à votre pays, car ceux-ci varient et influencent le montant final. Notre calculateur intègre également des éléments tels que les primes, les heures supplémentaires et les avantages en nature, vous donnant une image complète de votre revenu net.",
      active: false,
      id: 8,
    },
    {
      title: "Conseils Pratiques pour Optimiser Vos Finances 1.3",
      text: "En utilisant notre calculateur et en vérifiant régulièrement vos bulletins de paie, vous vous assurez de l'exactitude des déductions. Il est conseillé de consulter un expert en cas de doute pour garantir que vous recevez le montant dû. Afya RH fournit également des guides et des conseils pratiques pour mieux comprendre et gérer vos finances personnelles. Une bonne compréhension de ces aspects grâce à Afya RH vous permet de gérer vos finances personnelles de manière éclairée et efficace.",
      active: false,
      id: 9,
    },
  ];

  const toggleAccordion = (id) => {
    setAccordionOpen(accordionOpen === id ? null : id);
  };

  const openSpecificAccordion = (id) => {
    setAccordionOpen(id);
  };

  useEffect(() => {
    if (anchor) {
      setAccordionOpen(anchor);
    }
  }, [anchor]);

  return (
    <>
      <Banner text="Astuces et conseils" />
      <div className="mx-auto max-w-5xl px-3 py-8 sm:px-6 lg:px-8 grid grid-cols-12 gap-4">
        <div className="col-span-full lg:col-span-3 md:col-span-6 sm:col-span-full py-16">
          <div className="grid grid-cols-1 divide-y bg-white p-2 text-sm text-[#999999] border">
            <Link
              to="#accordion-text-faqs-1"
              className={`p-2 flex items-center justify-between cursor-pointer ${
                anchor === "accordion-text-faqs-1" ? "text-[#00ACB6]" : ""
              }`}
              onClick={() => openSpecificAccordion("accordion-text-faqs-1")}
            >
              <span>Guide complet</span>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={1.5}
                stroke="currentColor"
                className="size-4"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M17.25 8.25 21 12m0 0-3.75 3.75M21 12H3"
                />
              </svg>
            </Link>
            <Link
              to="#accordion-text-faqs-4"
              className={`p-2 flex items-center justify-between cursor-pointer ${
                anchor === "accordion-text-faqs-4" ? "text-[#00ACB6]" : ""
              }`}
              onClick={() => openSpecificAccordion("accordion-text-faqs-4")}
            >
              <span>Astuces</span>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={1.5}
                stroke="currentColor"
                className="size-4"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M17.25 8.25 21 12m0 0-3.75 3.75M21 12H3"
                />
              </svg>
            </Link>
            <Link
              to="#accordion-text-faqs-7"
              className={`p-2 flex items-center justify-between cursor-pointer ${
                anchor === "accordion-text-faqs-7" ? "text-[#00ACB6]" : ""
              }`}
              onClick={() => openSpecificAccordion("accordion-text-faqs-7")}
            >
              <span>Conseils</span>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={1.5}
                stroke="currentColor"
                className="size-4"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M17.25 8.25 21 12m0 0-3.75 3.75M21 12H3"
                />
              </svg>
            </Link>
          </div>
        </div>
        <div className="col-span-full lg:col-span-9 md:col-span-12 sm:col-span-12">
          <div className="mb-7">
            <h2>
              Guide Complet pour Calculer Son Salaire Brut en Net avec Afya RH
            </h2>
            {guide.map((item, index) => (
              <Accordion
                key={index}
                title={item.title}
                id={`accordion-text-faqs-${item.id}`}
                active={`accordion-text-faqs-${item.id}` === accordionOpen}
                toggleAccordion={toggleAccordion}
              >
                {item.text}
              </Accordion>
            ))}
          </div>

          <div className="mb-7">
            <h2>Astuces pour Calculer Son Salaire Brut en Net avec Succès </h2>
            {astuces.map((item, index) => (
              <Accordion
                key={index}
                title={item.title}
                id={`accordion-text-faqs-${item.id}`}
                active={`accordion-text-faqs-${item.id}` === accordionOpen}
                toggleAccordion={toggleAccordion}
              >
                {item.text}
              </Accordion>
            ))}
          </div>

          <div className="mb-7">
            <h2>
              Guide Complet pour Calculer Son Salaire Brut en Net avec Afya RH
            </h2>
            {conseils.map((item, index) => (
              <Accordion
                key={index}
                title={item.title}
                id={`accordion-text-faqs-${item.id}`}
                active={`accordion-text-faqs-${item.id}` === accordionOpen}
                toggleAccordion={toggleAccordion}
              >
                {item.text}
              </Accordion>
            ))}
          </div>
        </div>
      </div>
    </>
  );
};

export default GuidePage;
