import div from "../assets/images/divider.png";

const Divider = () => {
  return (
    <div className="w-50 mx-auto flex justify-center py-7">
      <img src={div} alt="divider" />
    </div>
  );
};

export default Divider;
