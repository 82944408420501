import { Outlet } from "react-router-dom";
import Footer from "../../components/Footer";
import Header from "../../components/Header";
import ScrollToTop from "../../components/ScrollToTop";
// import Hero from "../../components/Hero";

const AppShell = () => {
  return (
    <div className="flex flex-col min-h-screen">
      <ScrollToTop />
      <Header />
      {/* <Hero /> */}
      <div className=" flex-grow bg-gray-100 ">
        <Outlet />
      </div>
      <Footer />
    </div>
  );
};

export default AppShell;
