import React from "react";

const AddressMap = () => {
  return (
    <div className="google-map-code">
      <iframe
        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d15431.70593774257!2d-17.51181286484856!3d14.727427490799464!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0xec1728c3298a991%3A0xa5e71bfc219a7c1!2sNgor%20Almadies%2C%20Dakar%2C%20Senegal!5e0!3m2!1sen!2ssn!4v1625509062450!5m2!1sen!2ssn"
        width="100%"
        height="450"
        frameBorder="0"
        style={{ border: 0 }}
        allowFullScreen=""
        aria-hidden="false"
        tabIndex="0"
      />
    </div>
  );
};

export { AddressMap };
