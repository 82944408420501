import { Dialog, DialogPanel } from "@headlessui/react";
import { Bars3Icon, XMarkIcon } from "@heroicons/react/24/outline";
import { useContext, useState } from "react";
import { NavLink } from "react-router-dom";
import logo from "../assets/images/logo.png";
import { HiOutlineShoppingCart } from "react-icons/hi";
import { PanierContext } from "../App";

const navigation = [
  { name: "Accueil", href: "/" },
  { name: "Calculateur", href: "calculateur" },
  { name: "Astuces et conseils", href: "astuces" },
  { name: "Contacts", href: "contacts" },
  { name: "Téléchargement", href: "downloads" },
  { name: "Panier", href: "payment" },
];

export default function Header() {
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
  const closeMobileMenu = () => setMobileMenuOpen(false);
  const { cartItems } = useContext(PanierContext);
  return (
    <header className="bg-white sticky top-0">
      <nav
        className="mx-auto flex max-w-5xl items-center justify-between p-2 lg:px-8"
        aria-label="Global"
      >
        <div className="flex lg:flex-1">
          <NavLink to="/" className="-m-1.5 p-1.5">
            <span className="sr-only">Ayfa RH</span>
            <img className="h-8 w-auto" src={logo} alt="" />
          </NavLink>
        </div>
        <div className="flex lg:hidden gap-x-6">
          <NavLink to="/payment" className="relative">
            <HiOutlineShoppingCart size={18} />
            {cartItems.length > 0 && (
              <span className="absolute -top-2 -right-4 inline-flex items-center justify-center h-4 w-4 text-xs font-bold leading-none text-white bg-red-600 rounded-full">
                {cartItems.length}
              </span>
            )}
          </NavLink>
          <button
            type="button"
            className="-m-2.5 inline-flex items-center justify-center rounded-md p-2.5 text-gray-700"
            onClick={() => setMobileMenuOpen(true)}
          >
            <span className="sr-only">Open main menu</span>
            <Bars3Icon className="h-6 w-6" aria-hidden="true" />
          </button>
        </div>
        <div className="hidden lg:flex lg:gap-x-12">
          {navigation.map((item) => (
            <NavLink
              to={item.href}
              key={item.name}
              className={`-mx-1 block rounded-lg p-1 text-md font-semibold leading-7 text-gray-900`}
            >
              <div>
                {item.name === "Panier" ? (
                  // <>
                  //   <div className=" flex flex-row w-32 items-center gap-x-2">
                  //     {/* <p> */}
                  //     <HiOutlineShoppingCart
                  //       size={18}
                  //       className="text-gray-400"
                  //     />
                  //     {/* </p> */}
                  //     <sup>2</sup>
                  //     <p>{item.name}</p>
                  //   </div>
                  // </>
                  <div className="flex items-center">
                    <div className="relative">
                      <HiOutlineShoppingCart size={18} />
                      {cartItems.length > 0 && (
                        <span className="absolute -top-2 -right-4 inline-flex items-center justify-center h-4 w-4 text-xs font-bold leading-none text-white bg-red-600 rounded-full">
                          {cartItems.length}
                        </span>
                      )}
                    </div>
                    <p className="ml-4 text-lg">Panier</p>
                  </div>
                ) : (
                  <>{item.name}</>
                )}
              </div>
            </NavLink>
          ))}
        </div>
      </nav>
      {/* Mobile */}
      <Dialog
        className="lg:hidden"
        open={mobileMenuOpen}
        onClose={setMobileMenuOpen}
      >
        <div className="fixed inset-0 z-10" />
        <DialogPanel className="fixed inset-y-0 right-0 z-10 w-full overflow-y-auto bg-white px-6 py-6 sm:max-w-sm sm:ring-1 sm:ring-gray-900/10">
          <div className="flex items-center justify-between">
            <a href="/#" className="-m-1.5 p-1.5">
              <span className="sr-only">Ayfa RH</span>
              <img className="h-8 w-auto" src={logo} alt="" />
            </a>
            <button
              type="button"
              className="-m-2.5 rounded-md p-2.5 text-gray-700"
              onClick={() => setMobileMenuOpen(false)}
            >
              <span className="sr-only">Close menu</span>
              <XMarkIcon className="h-6 w-6" aria-hidden="true" />
            </button>
          </div>
          <div className="mt-6 flow-root">
            <div className="-my-6 divide-y divide-gray-500/10">
              <div className="space-y-2 py-6">
                {navigation.map((item) => (
                  <NavLink
                    to={item.href}
                    key={item.name}
                    onClick={closeMobileMenu}
                    className={`-mx-1 block rounded-lg p-1 text-base font-semibold leading-7 text-gray-900`}
                  >
                    {item.name}
                  </NavLink>
                ))}
              </div>
            </div>
          </div>
        </DialogPanel>
      </Dialog>
    </header>
  );
}
