import iconChat from "../../../assets/icons/icon-chat.png";
import iconEp from "../../../assets/icons/icon-ep.png";
import iconTips from "../../../assets/icons/icon-tips.png";
import SectionBloc from "../../../components/Section";

const AstuceSection = () => {
  const astuces_data = [
    {
      img: iconChat,
      title: "Guide complet",
      description: "Une de vos prestations expliquant les calculs de prix.",
      to: "astuces#accordion-text-faqs-1",
    },
    {
      img: iconTips,
      title: "Astuces",
      description: "Une de vos prestations proposant des conseils pratiques.",
      to: "astuces#accordion-text-faqs-4",
    },
    {
      img: iconEp,
      title: "Conseils",
      description:
        "Une de vos prestations offrant des recommandations avisées.",
      to: "astuces#accordion-text-faqs-7",
    },
  ];
  return (
    <>
      <SectionBloc
        card_data={astuces_data}
        title="Astuces et Conseils"
        description=" Calculer votre salaire brut en net : astuces, conseils pratiques et
guide complet pour mieux comprendre votre paie."
      />
    </>
  );
};

export default AstuceSection;
