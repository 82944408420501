const InputField = ({ salaireBrut, handleSalaireBrutChange }) => {
  return (
    <div className="sm:col-span-3">
      <label
        htmlFor="salaire-brut"
        className="block text-sm font-medium leading-6"
      >
        Indiquez votre Salaire brut
      </label>
      <div className="mt-2 relative">
        <span className="absolute inset-y-0 right-0 flex items-center pr-2">
          FCFA
        </span>
        <input
          type="text"
          name="salaire-brut"
          id="salaire-brut"
          value={salaireBrut}
          onChange={handleSalaireBrutChange}
          className="block w-full border-0 py-1.5 pl-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 placeholder:p-2 focus:ring-0 focus:ring-inset sm:text-sm sm:leading-6"
        />
      </div>
    </div>
  );
};

export default InputField;
