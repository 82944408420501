const SommaireItem = ({ title, value }) => {
  return (
    <div className="flex justify-between">
      <p>{title}</p>
      <div className="flex-grow  border-dotted border-b border-black"></div>
      <p className="text-[#F0553F] font-bold">{value}</p>
    </div>
  );
};

export default SommaireItem;
