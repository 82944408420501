import { NavLink } from "react-router-dom";

const Footer = () => {
  const navigation = [
    { name: "> Accueil", href: "/" },
    { name: "> Calculateur", href: "calculateur" },
    { name: "> Astuces et conseils", href: "astuces" },
    { name: "> Contacts", href: "contacts" },
  ];
  return (
    //   New footer
    <section className="py-10 bg-white sm:pt-16 lg:pt-24 border-t border-[#F8F8F8]">
      <div className="px-4 mx-auto sm:px-6 lg:px-8 max-w-5xl">
        <div className="align-center grid grid-cols-2 md:grid-cols-3 lg:grid-cols-12 gap-y-12 gap-x-8 xl:gap-x-12">
          <div className="lg:col-span-4">
            <p className="text-base font-semibold text-gray-900">
              Liens vers les menus
            </p>

            <ul className="mt-6 space-y-3">
              {navigation.map((item, index) => (
                <li key={index}>
                  <NavLink
                    key={index}
                    to={item.href}
                    title={item.name}
                    className="flex text-sm text-gray-800 transition-all duration-200 hover:text-orange-600 focus:text-orange-600"
                  >
                    {item.name}
                  </NavLink>
                </li>
              ))}
            </ul>
          </div>

          <div className="lg:col-span-4">
            <p className="text-base font-semibold text-gray-900">Contacts </p>

            <ul className="mt-6 space-y-3">
              <li className="flex space-x-2">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth={1.5}
                  stroke="currentColor"
                  className="size-5 text-[#F0553F]"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M15 10.5a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z"
                  />
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M19.5 10.5c0 7.142-7.5 11.25-7.5 11.25S4.5 17.642 4.5 10.5a7.5 7.5 0 1 1 15 0Z"
                  />
                </svg>

                <p className="flex text-sm text-gray-800 transition-all duration-200 hover:text-orange-600 focus:text-orange-600">
                  Dakar, Ngor, Almadies
                </p>
              </li>

              <li className="flex space-x-2">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth={1.5}
                  stroke="currentColor"
                  className="size-5 text-[#F0553F]"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M21.75 6.75v10.5a2.25 2.25 0 0 1-2.25 2.25h-15a2.25 2.25 0 0 1-2.25-2.25V6.75m19.5 0A2.25 2.25 0 0 0 19.5 4.5h-15a2.25 2.25 0 0 0-2.25 2.25m19.5 0v.243a2.25 2.25 0 0 1-1.07 1.916l-7.5 4.615a2.25 2.25 0 0 1-2.36 0L3.32 8.91a2.25 2.25 0 0 1-1.07-1.916V6.75"
                  />
                </svg>

                <p className="flex text-sm text-gray-800 transition-all duration-200 hover:text-orange-600 focus:text-orange-600">
                  contact.afyarh.com
                </p>
              </li>

              <li className="flex space-x-2">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth={1.5}
                  stroke="currentColor"
                  className="size-5 text-[#F0553F]"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M2.25 6.75c0 8.284 6.716 15 15 15h2.25a2.25 2.25 0 0 0 2.25-2.25v-1.372c0-.516-.351-.966-.852-1.091l-4.423-1.106c-.44-.11-.902.055-1.173.417l-.97 1.293c-.282.376-.769.542-1.21.38a12.035 12.035 0 0 1-7.143-7.143c-.162-.441.004-.928.38-1.21l1.293-.97c.363-.271.527-.734.417-1.173L6.963 3.102a1.125 1.125 0 0 0-1.091-.852H4.5A2.25 2.25 0 0 0 2.25 4.5v2.25Z"
                  />
                </svg>

                <p className="flex text-sm text-gray-800 transition-all duration-200 hover:text-orange-600 focus:text-orange-600">
                  +221 76 838 65 45
                </p>
              </li>
            </ul>
          </div>

          <div className="lg:col-span-4">
            <p className="text-base font-semibold text-gray-900">Follow Us</p>

            <ul className="flex items-center pt-5 md:order-3 sm:mt-0">
              <li>
                <a
                  href="https://www.facebook.com/profile.php?id=61558518719966&mibextid=ZbWKwL"
                  target="_blank"
                  rel="noreferrer"
                  title=""
                  className="flex items-center justify-center text-gray-800 transition-all duration-200 bg-transparent borde-0 rounded-full w-7 h-7 focus:bg-orange-600 hover:text-white focus:text-white hover:bg-orange-600 hover:border-orange-600 focus:border-orange-600"
                >
                  <svg
                    className="w-10 h-10 text-[#999999]"
                    aria-hidden="true"
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    fill="currentColor"
                    viewBox="0 0 24 24"
                  >
                    <path
                      fillRule="evenodd"
                      d="M13.135 6H15V3h-1.865a4.147 4.147 0 0 0-4.142 4.142V9H7v3h2v9.938h3V12h2.021l.592-3H12V6.591A.6.6 0 0 1 12.592 6h.543Z"
                      clipRule="evenodd"
                    />
                  </svg>
                </a>
              </li>

              <li>
                <a
                  href="https://www.instagram.com/afyarh7?igsh=MTU3aWl6ZWl5OTVzYw=="
                  title=""
                  target="_blank"
                  rel="noreferrer"
                  className="flex items-center mx-3 justify-center text-gray-800 transition-all duration-200 bg-transparent borde-0 rounded-full w-7 h-7 focus:bg-orange-600 hover:text-white focus:text-white hover:bg-orange-600 hover:border-orange-600 focus:border-orange-600"
                >
                  <svg
                    className="w-10 h-10 text-[#999999]"
                    aria-hidden="true"
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    fill="none"
                    viewBox="0 0 24 24"
                  >
                    <path
                      fill="currentColor"
                      fillRule="evenodd"
                      d="M3 8a5 5 0 0 1 5-5h8a5 5 0 0 1 5 5v8a5 5 0 0 1-5 5H8a5 5 0 0 1-5-5V8Zm5-3a3 3 0 0 0-3 3v8a3 3 0 0 0 3 3h8a3 3 0 0 0 3-3V8a3 3 0 0 0-3-3H8Zm7.597 2.214a1 1 0 0 1 1-1h.01a1 1 0 1 1 0 2h-.01a1 1 0 0 1-1-1ZM12 9a3 3 0 1 0 0 6 3 3 0 0 0 0-6Zm-5 3a5 5 0 1 1 10 0 5 5 0 0 1-10 0Z"
                      clipRule="evenodd"
                    />
                  </svg>
                </a>
              </li>

              <li>
                <a
                  href="https://www.linkedin.com/company/afyarh/"
                  title=""
                  target="_blank"
                  rel="noreferrer"
                  className="flex items-center justify-center text-gray-800 transition-all duration-200 bg-transparent borde-0 rounded-full w-7 h-7 focus:bg-orange-600 hover:text-white focus:text-white hover:bg-orange-600 hover:border-orange-600 focus:border-orange-600"
                >
                  <svg
                    className="w-10 h-10 text-[#999999]"
                    aria-hidden="true"
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    fill="currentColor"
                    viewBox="0 0 24 24"
                  >
                    <path
                      fillRule="evenodd"
                      d="M12.51 8.796v1.697a3.738 3.738 0 0 1 3.288-1.684c3.455 0 4.202 2.16 4.202 4.97V19.5h-3.2v-5.072c0-1.21-.244-2.766-2.128-2.766-1.827 0-2.139 1.317-2.139 2.676V19.5h-3.19V8.796h3.168ZM7.2 6.106a1.61 1.61 0 0 1-.988 1.483 1.595 1.595 0 0 1-1.743-.348A1.607 1.607 0 0 1 5.6 4.5a1.601 1.601 0 0 1 1.6 1.606Z"
                      clipRule="evenodd"
                    />
                    <path d="M7.2 8.809H4V19.5h3.2V8.809Z" />
                  </svg>
                </a>
              </li>
            </ul>
          </div>
        </div>

        <hr className="mt-5 mb-5 border-[#EDEDED]" />

        <div className="sm:flex sm:items-center sm:justify-between text-[#999999]">
          <p className="text-sm ">
            Termes et conditions | Tous droits réservés
          </p>

          <p className="mt-5 sm:mt-0 text-sm">© 2024 Afya RH</p>
        </div>
      </div>
    </section>
  );
};

export default Footer;
